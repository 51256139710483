import { BusCategory, Line, PlanVersion } from "@/types";

export const v1_metroBusse: Line[] = [
  {
    category: BusCategory.METRO,
    id: "Y5Mzc",
    name: "M1 Schelmengraben <> Bierstadt-Nord",
    planVersion: PlanVersion.V1,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.183581, 50.07133],
              [8.186311, 50.072232],
              [8.18652, 50.072325],
              [8.186649, 50.072528],
              [8.186628, 50.072617],
              [8.18615, 50.073323],
              [8.185914, 50.073499],
              [8.185657, 50.073571],
              [8.185415, 50.073581],
              [8.185083, 50.073526],
              [8.183092, 50.072872],
              [8.183066, 50.072827],
              [8.183146, 50.0727],
              [8.183109, 50.072538],
              [8.183023, 50.07219],
              [8.183114, 50.071922],
              [8.18342, 50.071405],
              [8.183715, 50.071041],
              [8.18408, 50.070806],
              [8.184895, 50.070355],
              [8.186161, 50.069625],
              [8.186987, 50.069157],
              [8.187582, 50.068813],
              [8.187931, 50.068689],
              [8.188564, 50.068644],
              [8.191225, 50.068527],
              [8.191804, 50.068493],
              [8.192217, 50.068365],
              [8.192475, 50.068227],
              [8.192807, 50.068014],
              [8.192931, 50.06788],
              [8.192963, 50.067842],
              [8.193693, 50.067966],
              [8.193918, 50.068045],
              [8.194336, 50.068238],
              [8.194605, 50.06842],
              [8.194857, 50.06874],
              [8.195044, 50.069099],
              [8.195018, 50.069539],
              [8.194883, 50.070008],
              [8.19476, 50.070565],
              [8.194771, 50.070758],
              [8.194814, 50.070992],
              [8.1949, 50.071251],
              [8.195141, 50.071626],
              [8.195361, 50.071881],
              [8.195248, 50.072025],
              [8.194626, 50.072483],
              [8.19454, 50.072573],
              [8.194486, 50.072703],
              [8.194422, 50.07292],
              [8.194315, 50.073044],
              [8.194368, 50.073155],
              [8.19506, 50.073785],
              [8.195457, 50.074139],
              [8.195656, 50.074452],
              [8.195865, 50.074693],
              [8.196005, 50.074807],
              [8.196133, 50.07491],
              [8.196428, 50.074993],
              [8.19667, 50.075021],
              [8.199073, 50.075279],
              [8.203112, 50.075744],
              [8.204378, 50.075878],
              [8.205945, 50.076012],
              [8.206771, 50.07604],
              [8.208069, 50.076201],
              [8.215671, 50.076935],
              [8.21912, 50.077265],
              [8.223127, 50.077613],
              [8.226609, 50.077947],
              [8.231694, 50.078436],
              [8.235438, 50.078814],
              [8.236656, 50.078935],
              [8.24062, 50.079138],
              [8.243276, 50.079276],
              [8.245068, 50.079365],
              [8.245566, 50.079389],
              [8.245899, 50.079413],
              [8.246087, 50.079486],
              [8.246382, 50.079596],
              [8.246725, 50.079816],
              [8.246886, 50.07995],
              [8.247229, 50.080057],
              [8.250394, 50.080897],
              [8.253688, 50.081765],
              [8.254659, 50.082002],
              [8.255024, 50.082078],
              [8.256215, 50.082274],
              [8.258076, 50.082615],
              [8.260174, 50.083097],
              [8.261987, 50.083565],
              [8.263773, 50.083999],
              [8.264594, 50.084226],
              [8.26534, 50.084381],
              [8.266155, 50.08447],
              [8.268167, 50.084594],
              [8.270114, 50.084732],
              [8.271756, 50.08499],
              [8.273461, 50.085248],
              [8.274266, 50.085382],
              [8.274958, 50.085437],
              [8.275253, 50.085389],
              [8.276584, 50.085083],
              [8.279126, 50.083999],
              [8.280757, 50.083241],
              [8.282576, 50.082446],
              [8.283032, 50.082246],
              [8.283117, 50.08215],
              [8.283209, 50.082095],
              [8.283659, 50.082157],
              [8.284351, 50.082312],
              [8.284882, 50.082415],
              [8.285322, 50.082553],
              [8.285666, 50.082701],
              [8.286197, 50.082852],
              [8.28639, 50.082914],
              [8.286706, 50.082181],
              [8.287602, 50.082233],
              [8.287683, 50.082343],
              [8.287967, 50.082549],
              [8.288616, 50.082894],
              [8.288997, 50.083097],
              [8.28905, 50.083169],
              [8.289029, 50.083269],
              [8.288659, 50.083861],
              [8.288594, 50.084002],
              [8.288299, 50.084329],
              [8.287795, 50.08489],
              [8.287747, 50.08498],
              [8.28757, 50.08521],
              [8.287425, 50.085558],
              [8.287039, 50.086618],
              [8.286824, 50.087148],
              [8.285376, 50.086855],
              [8.285328, 50.086959],
              [8.285301, 50.087289],
              [8.285263, 50.087823],
              [8.285151, 50.088074],
              [8.285429, 50.088187],
              [8.286116, 50.088439],
              [8.286679, 50.088662],
              [8.286889, 50.088797],
              [8.286964, 50.088927],
              [8.286969, 50.089368],
              [8.286942, 50.089919],
              [8.286932, 50.090204],
              [8.286754, 50.090356],
              [8.286438, 50.090445],
              [8.285934, 50.090628],
              [8.285338, 50.090745],
              [8.285129, 50.090783],
              [8.28522, 50.09134],
              [8.285295, 50.091874],
              [8.285065, 50.09197],
              [8.284888, 50.092056],
              [8.284823, 50.09217],
              [8.284802, 50.092603],
              [8.284732, 50.093092],
              [8.282742, 50.092965],
              [8.282978, 50.091791],
              [8.282999, 50.091708],
              [8.283042, 50.09155],
              [8.283069, 50.091433],
              [8.283053, 50.091189],
              [8.283005, 50.090982],
              [8.282817, 50.090666],
              [8.283343, 50.090497],
              [8.283514, 50.090332],
              [8.283589, 50.090187],
              [8.283681, 50.08976],
              [8.283724, 50.089361],
              [8.283783, 50.088986],
              [8.283874, 50.088779],
              [8.284094, 50.088573],
              [8.28463, 50.088366],
              [8.285049, 50.088167],
              [8.285108, 50.088088],
            ],
            [
              [8.286363, 50.082956],
              [8.286245, 50.083362],
              [8.286041, 50.083548],
              [8.285708, 50.083733],
              [8.285408, 50.083802],
              [8.284003, 50.083747],
              [8.283026, 50.083623],
              [8.280773, 50.083334],
            ],
            [
              [8.246784, 50.079954],
              [8.246055, 50.080394],
              [8.245443, 50.080601],
              [8.244907, 50.080642],
              [8.236463, 50.080181],
              [8.236334, 50.08078],
              [8.23612, 50.080856],
              [8.235712, 50.080856],
              [8.229468, 50.080525],
              [8.229736, 50.078295],
            ],
            [
              [8.197807, 50.075107],
              [8.197968, 50.074528],
              [8.194723, 50.072449],
            ],
          ],
        },
        id: "Y5Mzc",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "M3NzU",
    name: "M2 (Bierstadt Fliednerschule <> Schierstein Oderstraße)",

    planVersion: PlanVersion.V1,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.280773, 50.080202],
              [8.281224, 50.079885],
              [8.281063, 50.079603],
              [8.283734, 50.079217],
              [8.28456, 50.079121],
              [8.286191, 50.078969],
              [8.287854, 50.078969],
              [8.287157, 50.079768],
              [8.286813, 50.080367],
              [8.286685, 50.080925],
              [8.286738, 50.082116],
              [8.286417, 50.082983],
              [8.286116, 50.083506],
              [8.28558, 50.083768],
              [8.285151, 50.08383],
              [8.283745, 50.083727],
              [8.282146, 50.083541],
              [8.280655, 50.0833],
              [8.277544, 50.084711],
              [8.276396, 50.085207],
              [8.274819, 50.085455],
              [8.272845, 50.0852],
              [8.270184, 50.084766],
              [8.269229, 50.084697],
              [8.266375, 50.084498],
              [8.265356, 50.084415],
              [8.262845, 50.083802],
              [8.258489, 50.082756],
              [8.258575, 50.082529],
              [8.258446, 50.081338],
              [8.258339, 50.079437],
              [8.258253, 50.078439],
              [8.25791, 50.07689],
              [8.257813, 50.076422],
              [8.257867, 50.075768],
              [8.258168, 50.074983],
              [8.258715, 50.073509],
              [8.257524, 50.072738],
              [8.256279, 50.072339],
              [8.250668, 50.071994],
              [8.241774, 50.071567],
              [8.240143, 50.071492],
              [8.237675, 50.071705],
              [8.235004, 50.072401],
              [8.232719, 50.073537],
              [8.231764, 50.074322],
              [8.231238, 50.074081],
              [8.230069, 50.073124],
              [8.226475, 50.069901],
              [8.224136, 50.068207],
              [8.222537, 50.067084],
              [8.220649, 50.0657],
              [8.218857, 50.064219],
              [8.216722, 50.062435],
              [8.214909, 50.06092],
              [8.213664, 50.060045],
              [8.21169, 50.058875],
              [8.210703, 50.057786],
              [8.210102, 50.056905],
              [8.209448, 50.056099],
              [8.207721, 50.05417],
              [8.204684, 50.050781],
              [8.20242, 50.048121],
              [8.200446, 50.045965],
              [8.200318, 50.045958],
              [8.200028, 50.04593],
              [8.195114, 50.045572],
              [8.194824, 50.045565],
              [8.194342, 50.045676],
              [8.192475, 50.045359],
              [8.190426, 50.045007],
              [8.190082, 50.045434],
              [8.189267, 50.046544],
              [8.189042, 50.046606],
              [8.186671, 50.046799],
              [8.186402, 50.046771],
              [8.186198, 50.046668],
              [8.186209, 50.046475],
              [8.186413, 50.046082],
              [8.18666, 50.045689],
              [8.187121, 50.045421],
              [8.187379, 50.045365],
              [8.187979, 50.04529],
              [8.188548, 50.04531],
              [8.189964, 50.045434],
            ],
            [
              [8.194578, 50.045586],
              [8.194814, 50.045338],
              [8.194996, 50.045062],
              [8.195425, 50.044952],
              [8.195983, 50.044794],
              [8.196198, 50.044614],
              [8.196734, 50.044546],
              [8.199255, 50.044559],
              [8.199373, 50.044663],
              [8.19977, 50.045221],
              [8.200393, 50.04591],
            ],
            [
              [8.280698, 50.083265],
              [8.283005, 50.082226],
              [8.283166, 50.082088],
              [8.283809, 50.082191],
              [8.28514, 50.082487],
              [8.286395, 50.082914],
            ],
          ],
        },
        id: "M3NzU",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "k3OTU",
    name: "M4 (Nordfriedhof <> Biebrich Rheinufer)",
    planVersion: PlanVersion.V1,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.220263, 50.09697],
              [8.220971, 50.096213],
              [8.221389, 50.095635],
              [8.2217, 50.095009],
              [8.222065, 50.094465],
              [8.222709, 50.093997],
              [8.22317, 50.093667],
              [8.224039, 50.093433],
              [8.225015, 50.092937],
              [8.22699, 50.091946],
              [8.227826, 50.090989],
              [8.228846, 50.089846],
              [8.229522, 50.088979],
              [8.230788, 50.087953],
              [8.231678, 50.087293],
              [8.231893, 50.087327],
              [8.235701, 50.086687],
              [8.235648, 50.086391],
              [8.235948, 50.084319],
              [8.235937, 50.08383],
              [8.235959, 50.083045],
              [8.236045, 50.082246],
              [8.236248, 50.080601],
              [8.236495, 50.07888],
              [8.242246, 50.079183],
              [8.24245, 50.077152],
              [8.242686, 50.075416],
              [8.242954, 50.073165],
              [8.243169, 50.071677],
              [8.240765, 50.071567],
              [8.240508, 50.071306],
              [8.240197, 50.07072],
              [8.2401, 50.069749],
              [8.240368, 50.068406],
              [8.241044, 50.065858],
              [8.241763, 50.063104],
              [8.242246, 50.060962],
              [8.24289, 50.058516],
              [8.24349, 50.056285],
              [8.243651, 50.055534],
              [8.243651, 50.055017],
              [8.243201, 50.053943],
              [8.242257, 50.051518],
              [8.241688, 50.050112],
              [8.241109, 50.048721],
              [8.241023, 50.048156],
              [8.241034, 50.047191],
              [8.241066, 50.045145],
              [8.24113, 50.044766],
              [8.240036, 50.04252],
              [8.238866, 50.039901],
              [8.237665, 50.0374],
              [8.237257, 50.03689],
              [8.236871, 50.036387],
              [8.237911, 50.035994],
              [8.238062, 50.036167],
              [8.238426, 50.03658],
              [8.238662, 50.036856],
              [8.239059, 50.036849],
              [8.239177, 50.036911],
              [8.240047, 50.038668],
              [8.240948, 50.040494],
              [8.241205, 50.041066],
              [8.241227, 50.041473],
              [8.241162, 50.044718],
            ],
            [
              [8.242203, 50.079258],
              [8.242053, 50.080446],
              [8.236329, 50.08014],
            ],
          ],
        },
        id: "k3OTU",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "g1MTI",
    name: "M5 (Äppelallee-Center <> Wallau/Delkenheim Bf)",
    planVersion: PlanVersion.V1,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.214372, 50.045841],
              [8.214244, 50.046881],
              [8.21434, 50.047005],
              [8.214694, 50.047033],
              [8.219587, 50.047343],
              [8.220016, 50.047239],
              [8.220187, 50.047081],
              [8.220305, 50.04675],
              [8.220574, 50.045324],
              [8.220488, 50.044683],
              [8.22023, 50.044242],
              [8.221925, 50.043808],
              [8.221357, 50.042733],
              [8.217033, 50.042926],
              [8.216883, 50.042926],
              [8.216733, 50.042616],
              [8.216282, 50.041376],
              [8.208611, 50.043078],
              [8.204759, 50.043925],
              [8.201616, 50.044594],
              [8.201262, 50.044614],
              [8.199395, 50.04458],
              [8.200006, 50.045359],
              [8.202174, 50.047701],
              [8.204116, 50.050002],
              [8.206594, 50.052758],
              [8.209072, 50.055603],
              [8.210006, 50.056588],
              [8.210274, 50.057001],
              [8.210682, 50.057621],
              [8.211658, 50.058764],
              [8.212731, 50.059377],
              [8.214179, 50.06028],
              [8.215016, 50.060906],
              [8.21698, 50.062553],
              [8.219318, 50.064481],
              [8.221518, 50.066265],
              [8.223503, 50.067677],
              [8.226153, 50.069591],
              [8.227043, 50.07028],
              [8.227741, 50.070927],
              [8.229425, 50.072456],
              [8.230433, 50.073371],
              [8.231399, 50.074081],
              [8.231549, 50.074514],
              [8.231024, 50.07512],
              [8.230444, 50.076071],
              [8.230197, 50.076697],
              [8.230112, 50.07722],
              [8.229951, 50.078322],
              [8.232225, 50.078542],
              [8.234382, 50.078756],
              [8.23597, 50.078928],
              [8.236753, 50.078983],
              [8.238223, 50.079079],
              [8.242053, 50.079258],
              [8.245465, 50.079444],
              [8.245872, 50.079465],
              [8.246366, 50.079665],
              [8.246731, 50.079919],
              [8.247149, 50.07972],
              [8.249702, 50.077737],
              [8.253726, 50.074749],
              [8.257921, 50.071753],
              [8.259809, 50.070266],
              [8.26144, 50.069088],
              [8.262448, 50.068468],
              [8.263596, 50.067766],
              [8.264734, 50.067373],
              [8.2666, 50.066919],
              [8.268639, 50.066196],
              [8.274186, 50.064178],
              [8.27999, 50.062064],
              [8.282018, 50.061244],
              [8.282919, 50.060652],
              [8.28441, 50.059612],
              [8.284464, 50.059432],
              [8.284496, 50.058916],
              [8.284453, 50.058337],
              [8.284378, 50.057952],
              [8.284367, 50.057786],
              [8.284528, 50.057531],
              [8.284786, 50.057118],
              [8.285193, 50.056884],
              [8.285644, 50.056636],
              [8.286159, 50.05656],
              [8.28691, 50.056478],
              [8.287565, 50.056588],
              [8.288058, 50.056781],
              [8.288605, 50.057104],
              [8.289109, 50.057449],
              [8.289936, 50.058337],
              [8.294871, 50.056546],
              [8.298926, 50.055072],
              [8.299956, 50.054452],
              [8.300997, 50.053846],
              [8.301716, 50.053605],
              [8.302521, 50.053323],
              [8.303915, 50.05315],
              [8.30442, 50.053143],
              [8.304666, 50.053185],
              [8.304784, 50.053433],
              [8.305396, 50.054342],
              [8.305364, 50.055258],
              [8.305728, 50.055375],
              [8.307166, 50.055582],
              [8.307542, 50.055651],
              [8.307381, 50.056505],
              [8.307499, 50.056801],
              [8.308185, 50.057841],
              [8.309569, 50.058985],
              [8.310685, 50.059646],
              [8.312316, 50.060693],
              [8.314934, 50.062236],
              [8.316779, 50.064164],
              [8.318796, 50.063489],
              [8.319612, 50.06331],
              [8.320127, 50.063269],
              [8.321414, 50.063172],
              [8.322101, 50.06309],
              [8.329268, 50.061699],
              [8.332508, 50.061037],
              [8.335855, 50.060445],
              [8.336306, 50.061072],
              [8.336434, 50.061375],
              [8.336627, 50.061864],
              [8.336724, 50.062491],
              [8.336724, 50.062938],
              [8.336971, 50.063234],
              [8.338097, 50.062897],
              [8.338902, 50.062587],
              [8.340071, 50.062022],
              [8.340898, 50.061657],
              [8.340908, 50.061513],
              [8.340908, 50.061065],
              [8.340898, 50.060631],
              [8.3427, 50.060321],
              [8.342507, 50.060087],
              [8.342421, 50.059818],
              [8.342657, 50.059591],
              [8.343397, 50.060128],
              [8.344095, 50.060603],
              [8.345071, 50.061196],
              [8.345994, 50.061829],
              [8.347174, 50.062897],
              [8.348472, 50.064178],
              [8.350725, 50.063992],
              [8.35212, 50.063875],
              [8.353461, 50.063661],
              [8.353965, 50.063661],
              [8.355414, 50.063496],
              [8.3586, 50.063028],
              [8.36081, 50.062711],
              [8.361164, 50.062621],
              [8.361518, 50.062608],
              [8.364995, 50.062126],
              [8.365349, 50.063152],
              [8.367591, 50.064164],
              [8.369179, 50.064853],
              [8.369565, 50.064199],
              [8.36978, 50.063854],
              [8.36978, 50.063579],
              [8.369737, 50.063324],
              [8.369737, 50.063186],
              [8.369908, 50.062904],
              [8.370166, 50.062435],
              [8.370295, 50.062043],
              [8.370359, 50.061788],
              [8.370509, 50.061533],
              [8.370799, 50.061196],
              [8.370949, 50.061092],
              [8.368996, 50.059887],
              [8.369318, 50.059556],
              [8.36979, 50.059219],
              [8.370155, 50.058985],
              [8.369941, 50.058785],
              [8.369619, 50.058317],
              [8.369522, 50.058048],
              [8.369586, 50.057359],
              [8.369919, 50.056264],
              [8.370112, 50.055637],
              [8.370209, 50.055355],
              [8.37037, 50.055031],
              [8.370584, 50.054804],
              [8.370917, 50.054556],
              [8.371196, 50.054321],
              [8.37081, 50.054177],
              [8.369468, 50.054129],
            ],
            [
              [8.289807, 50.058392],
              [8.286481, 50.059577],
              [8.284646, 50.060266],
              [8.284217, 50.06039],
              [8.282983, 50.060686],
            ],
            [
              [8.246645, 50.079961],
              [8.245711, 50.080394],
              [8.245282, 50.080553],
              [8.245025, 50.080567],
              [8.244585, 50.080677],
              [8.23656, 50.080229],
              [8.236431, 50.080766],
              [8.236334, 50.080849],
              [8.236002, 50.080945],
              [8.229768, 50.080594],
              [8.229886, 50.079816],
              [8.230053, 50.078384],
            ],
          ],
        },
        id: "g1MTI",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "AxMDY",
    name: "M5 Klarenthal <> Äppelallee-Center",
    planVersion: PlanVersion.V1,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.19895, 50.090063],
              [8.199089, 50.090418],
              [8.199245, 50.091244],
              [8.19932, 50.092104],
              [8.198708, 50.092056],
              [8.197603, 50.091932],
              [8.19653, 50.091505],
              [8.195565, 50.09114],
              [8.195264, 50.090941],
              [8.195146, 50.090679],
              [8.195232, 50.090438],
              [8.195543, 50.090273],
              [8.196015, 50.090184],
              [8.19667, 50.090177],
              [8.198934, 50.090005],
              [8.198676, 50.089468],
              [8.198569, 50.089007],
              [8.198633, 50.088408],
              [8.198944, 50.087334],
              [8.199009, 50.087182],
              [8.199384, 50.087182],
              [8.200006, 50.087079],
              [8.200929, 50.086859],
              [8.202002, 50.086645],
              [8.204062, 50.086143],
              [8.204706, 50.085874],
              [8.205864, 50.085468],
              [8.207163, 50.084787],
              [8.208525, 50.084215],
              [8.209716, 50.083761],
              [8.211207, 50.083183],
              [8.211926, 50.082949],
              [8.212924, 50.08268],
              [8.214244, 50.082412],
              [8.21493, 50.082302],
              [8.215467, 50.082308],
              [8.215563, 50.082315],
              [8.215853, 50.082102],
              [8.216261, 50.081964],
              [8.217398, 50.081785],
              [8.218514, 50.081517],
              [8.218943, 50.0814],
              [8.219694, 50.081124],
              [8.221335, 50.080381],
              [8.223524, 50.079396],
              [8.224018, 50.079183],
              [8.224318, 50.07974],
              [8.2245, 50.080277],
              [8.22464, 50.08058],
              [8.225176, 50.080463],
              [8.225852, 50.080394],
              [8.22641, 50.080367],
              [8.227655, 50.080429],
              [8.229157, 50.080484],
              [8.229393, 50.080546],
              [8.229554, 50.080477],
              [8.229661, 50.08016],
              [8.229876, 50.078308],
              [8.229758, 50.078164],
              [8.229918, 50.07731],
              [8.230036, 50.076711],
              [8.230144, 50.07627],
              [8.230326, 50.075892],
              [8.230702, 50.07521],
              [8.231109, 50.074735],
              [8.23141, 50.074377],
              [8.231925, 50.073902],
              [8.232676, 50.073296],
              [8.233448, 50.072945],
              [8.234317, 50.072483],
              [8.235272, 50.072125],
              [8.236291, 50.071822],
              [8.237085, 50.071691],
              [8.238115, 50.071492],
              [8.240036, 50.071374],
              [8.241044, 50.071381],
              [8.242375, 50.071436],
              [8.243694, 50.071533],
              [8.246001, 50.071684],
              [8.247299, 50.071739],
              [8.248222, 50.07176],
              [8.248394, 50.071161],
              [8.248683, 50.070149],
              [8.24922, 50.068462],
              [8.249563, 50.067525],
              [8.250024, 50.066705],
              [8.252546, 50.06725],
              [8.254745, 50.067704],
              [8.256644, 50.068131],
              [8.256902, 50.067828],
              [8.257213, 50.067497],
              [8.25821, 50.066637],
              [8.259047, 50.065886],
              [8.259251, 50.065631],
              [8.259552, 50.065046],
              [8.260421, 50.063579],
              [8.257481, 50.062945],
              [8.260077, 50.058792],
              [8.257846, 50.058303],
              [8.257384, 50.058255],
              [8.256934, 50.058206],
              [8.256065, 50.05822],
              [8.255357, 50.058303],
              [8.254884, 50.058389],
              [8.255582, 50.056987],
              [8.256344, 50.055926],
              [8.256816, 50.055293],
              [8.257148, 50.054831],
              [8.257288, 50.054335],
              [8.257148, 50.053378],
              [8.256955, 50.053006],
              [8.256698, 50.052331],
              [8.256547, 50.051835],
              [8.256526, 50.051132],
              [8.256483, 50.049685],
              [8.256354, 50.048914],
              [8.256161, 50.047701],
              [8.255947, 50.046433],
              [8.255796, 50.045937],
              [8.255646, 50.045427],
              [8.255646, 50.045069],
              [8.255582, 50.044766],
              [8.254659, 50.044787],
              [8.253436, 50.044745],
              [8.252857, 50.044628],
              [8.252245, 50.044298],
              [8.251762, 50.043836],
              [8.251365, 50.043464],
              [8.249155, 50.042975],
              [8.248158, 50.042541],
              [8.247482, 50.042299],
              [8.244038, 50.040997],
              [8.240873, 50.039784],
              [8.240583, 50.03966],
              [8.240261, 50.039695],
              [8.239017, 50.039943],
              [8.238684, 50.040026],
              [8.238051, 50.038592],
              [8.237461, 50.0374],
              [8.236699, 50.036442],
              [8.235283, 50.036897],
              [8.234425, 50.037042],
              [8.231292, 50.037579],
              [8.230197, 50.037855],
              [8.229747, 50.038055],
              [8.229157, 50.038282],
              [8.22861, 50.03853],
              [8.228052, 50.038709],
              [8.226367, 50.039137],
              [8.226914, 50.040094],
              [8.227097, 50.04057],
              [8.227504, 50.041473],
              [8.227687, 50.041858],
              [8.228223, 50.042534],
              [8.229157, 50.043739],
              [8.230187, 50.044966],
              [8.23082, 50.045703],
              [8.231077, 50.046234],
              [8.231184, 50.047074],
              [8.231152, 50.047267],
              [8.230262, 50.047143],
              [8.220638, 50.0454],
              [8.220391, 50.046771],
              [8.220263, 50.047143],
              [8.220134, 50.047295],
              [8.219608, 50.047412],
              [8.214351, 50.047081],
              [8.214169, 50.046971],
              [8.214136, 50.046854],
              [8.214254, 50.04582],
            ],
            [
              [8.236678, 50.036346],
              [8.237236, 50.036105],
              [8.237804, 50.035891],
              [8.237997, 50.035891],
              [8.238233, 50.036056],
              [8.23878, 50.036766],
              [8.239306, 50.036808],
              [8.240379, 50.03884],
              [8.240733, 50.039647],
            ],
          ],
        },
        id: "AxMDY",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "gxNzE",
    name: "M6 Klarenthal <> Mz-Gonsenheim",
    planVersion: PlanVersion.V1,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.199218, 50.092125],
              [8.197641, 50.091966],
              [8.196595, 50.091598],
              [8.195516, 50.091175],
              [8.195216, 50.090986],
              [8.195103, 50.090776],
              [8.195087, 50.090597],
              [8.195205, 50.090401],
              [8.19542, 50.090266],
              [8.195645, 50.090197],
              [8.196219, 50.090142],
              [8.198869, 50.089977],
              [8.198993, 50.090001],
              [8.199164, 50.090449],
              [8.199282, 50.091103],
              [8.199373, 50.092066],
              [8.199347, 50.092204],
              [8.199379, 50.092751],
              [8.199432, 50.093002],
              [8.199411, 50.093119],
              [8.199282, 50.093247],
              [8.20138, 50.093288],
              [8.201691, 50.093202],
              [8.202024, 50.09304],
              [8.202276, 50.092858],
              [8.203145, 50.091836],
              [8.204325, 50.090462],
              [8.204615, 50.090074],
              [8.204834, 50.089495],
              [8.205054, 50.088728],
              [8.205231, 50.088177],
              [8.205323, 50.087778],
              [8.205344, 50.087482],
              [8.205408, 50.087213],
              [8.20551, 50.086763],
              [8.205634, 50.086349],
              [8.205859, 50.086112],
              [8.206197, 50.085799],
              [8.206267, 50.085558],
              [8.206218, 50.085386],
              [8.206277, 50.085341],
              [8.207066, 50.084897],
              [8.208418, 50.084301],
              [8.210124, 50.083658],
              [8.210639, 50.083451],
              [8.211497, 50.083121],
              [8.212323, 50.082887],
              [8.213203, 50.082666],
              [8.213761, 50.08257],
              [8.214812, 50.08237],
              [8.215113, 50.082357],
              [8.215553, 50.082363],
              [8.215778, 50.082184],
              [8.216062, 50.082067],
              [8.216406, 50.081981],
              [8.21728, 50.08183],
              [8.21779, 50.081734],
              [8.218288, 50.081603],
              [8.219324, 50.08131],
              [8.219833, 50.081114],
              [8.221062, 50.080539],
              [8.222671, 50.07982],
              [8.22398, 50.079234],
              [8.22413, 50.079462],
              [8.224302, 50.079816],
              [8.224458, 50.080277],
              [8.224608, 50.080604],
              [8.224758, 50.080591],
              [8.225064, 50.080518],
              [8.225477, 50.080456],
              [8.225884, 50.080429],
              [8.226249, 50.080398],
              [8.2267, 50.080401],
              [8.227059, 50.080436],
              [8.228014, 50.080474],
              [8.228803, 50.080508],
              [8.229377, 50.080563],
              [8.229602, 50.080491],
              [8.229699, 50.080374],
              [8.229892, 50.078807],
              [8.229945, 50.078446],
              [8.230015, 50.078253],
              [8.231394, 50.078367],
              [8.236367, 50.078863],
              [8.236431, 50.078856],
              [8.236983, 50.078869],
              [8.239033, 50.078966],
              [8.242187, 50.079152],
              [8.242289, 50.078043],
              [8.242332, 50.077441],
              [8.24245, 50.076546],
              [8.242605, 50.075544],
              [8.242734, 50.074449],
              [8.242879, 50.073327],
              [8.24297, 50.072697],
              [8.243104, 50.071795],
              [8.240712, 50.071677],
              [8.240497, 50.071554],
              [8.240358, 50.071337],
              [8.24069, 50.071309],
              [8.241398, 50.071333],
              [8.243576, 50.071471],
              [8.245304, 50.071602],
              [8.246666, 50.071674],
              [8.248158, 50.071719],
              [8.248495, 50.070576],
              [8.24886, 50.069381],
              [8.249257, 50.068152],
              [8.249681, 50.06715],
              [8.250073, 50.066551],
              [8.250663, 50.065741],
              [8.251655, 50.064133],
              [8.252846, 50.062256],
              [8.253517, 50.061058],
              [8.254155, 50.059767],
              [8.254675, 50.058737],
              [8.254933, 50.058124],
              [8.255507, 50.056987],
              [8.255807, 50.056567],
              [8.256451, 50.055647],
              [8.256966, 50.055038],
              [8.257116, 50.054752],
              [8.257234, 50.054301],
              [8.25717, 50.053812],
              [8.256998, 50.053247],
              [8.256569, 50.052275],
              [8.25644, 50.051476],
              [8.256376, 50.049465],
              [8.256204, 50.048266],
              [8.255582, 50.045372],
              [8.255475, 50.044849],
              [8.254348, 50.044814],
              [8.253436, 50.04478],
              [8.252771, 50.044628],
              [8.25188, 50.044091],
              [8.251365, 50.043533],
              [8.254176, 50.041597],
              [8.256075, 50.040274],
              [8.256515, 50.039901],
              [8.257298, 50.038889],
              [8.258135, 50.03749],
              [8.260453, 50.034168],
              [8.262352, 50.031425],
              [8.262405, 50.031177],
              [8.263521, 50.029489],
              [8.264304, 50.028407],
              [8.265506, 50.026807],
              [8.26616, 50.02607],
              [8.266772, 50.025353],
              [8.268242, 50.023664],
              [8.269529, 50.022231],
              [8.270903, 50.020852],
              [8.272212, 50.01959],
              [8.273327, 50.018529],
              [8.275023, 50.016943],
              [8.276407, 50.015551],
              [8.27734, 50.014537],
              [8.27822, 50.013572],
              [8.279046, 50.012593],
              [8.279185, 50.012386],
              [8.27911, 50.011786],
              [8.279067, 50.011469],
              [8.279067, 50.011042],
              [8.279196, 50.010449],
              [8.27969, 50.009532],
              [8.280033, 50.008822],
              [8.280119, 50.008325],
              [8.280022, 50.008111],
              [8.279529, 50.007829],
              [8.273435, 50.005353],
              [8.273156, 50.005374],
              [8.272834, 50.005498],
              [8.272587, 50.005781],
              [8.272287, 50.006105],
              [8.271825, 50.00627],
              [8.271343, 50.006243],
              [8.270838, 50.005884],
              [8.270398, 50.005464],
              [8.266654, 50.002436],
              [8.264219, 50.000464],
              [8.263897, 50.000257],
              [8.261697, 50.001119],
              [8.26129, 50.001292],
              [8.260775, 50.00134],
              [8.260109, 50.001326],
              [8.259948, 50.001126],
              [8.260163, 50.000754],
              [8.260581, 50.000257],
              [8.260517, 50.000133],
              [8.260206, 49.99994],
              [8.258275, 49.999367],
              [8.257567, 49.999043],
              [8.25718, 49.998747],
              [8.256569, 49.997954],
              [8.256172, 49.997595],
              [8.255947, 49.99754],
              [8.254852, 49.997443],
              [8.253897, 49.997402],
              [8.252535, 49.99734],
              [8.251183, 49.997333],
              [8.250625, 49.997298],
              [8.249499, 49.997195],
              [8.248587, 49.997119],
              [8.247203, 49.996905],
              [8.246087, 49.996685],
              [8.245422, 49.996554],
              [8.244874, 49.996485],
              [8.244231, 49.996485],
              [8.243823, 49.996595],
              [8.242675, 49.996885],
              [8.238115, 49.998319],
              [8.237826, 49.997912],
              [8.237697, 49.997581],
              [8.237686, 49.997264],
              [8.237847, 49.996926],
              [8.237847, 49.996698],
              [8.237804, 49.996574],
              [8.23745, 49.996471],
              [8.235508, 49.99605],
              [8.232397, 49.995388],
              [8.232011, 49.995354],
              [8.231624, 49.995554],
              [8.230476, 49.996471],
              [8.230187, 49.996457],
              [8.229532, 49.996492],
              [8.228502, 49.996498],
              [8.227268, 49.996278],
              [8.22626, 49.996257],
              [8.225434, 49.996119],
              [8.224758, 49.995788],
              [8.223449, 49.996581],
              [8.222258, 49.997236],
              [8.220831, 49.998112],
              [8.220284, 49.998602],
              [8.219157, 49.999223],
              [8.218428, 49.999671],
              [8.217924, 50.000119],
              [8.217795, 50.000685],
              [8.217816, 50.001174],
              [8.217988, 50.001954],
              [8.218192, 50.002457],
              [8.218095, 50.002919],
              [8.217548, 50.003809],
              [8.216894, 50.004677],
              [8.21654, 50.005188],
              [8.216411, 50.005526],
              [8.216357, 50.005932],
              [8.216357, 50.006222],
              [8.216164, 50.006753],
              [8.215799, 50.007284],
              [8.215445, 50.007815],
              [8.215381, 50.008491],
              [8.215477, 50.008932],
              [8.215494, 50.009073],
              [8.215483, 50.009184],
              [8.215247, 50.009208],
              [8.214995, 50.009211],
              [8.214335, 50.009039],
              [8.213825, 50.008846],
              [8.212506, 50.008487],
              [8.212307, 50.008449],
              [8.211787, 50.008301],
              [8.211465, 50.008749],
              [8.211234, 50.009077],
              [8.211095, 50.009166],
              [8.210827, 50.009235],
              [8.210478, 50.009204],
              [8.207849, 50.008432],
              [8.207388, 50.008325],
              [8.207216, 50.008353],
              [8.206964, 50.00848],
              [8.206755, 50.00866],
              [8.206578, 50.008801],
              [8.206385, 50.008866],
              [8.206149, 50.008853],
              [8.205811, 50.008797],
              [8.205392, 50.008584],
              [8.205312, 50.008349],
              [8.205403, 50.008142],
              [8.205618, 50.007825],
              [8.205779, 50.007742],
              [8.206015, 50.007377],
              [8.206481, 50.006725],
              [8.207699, 50.007077],
              [8.208021, 50.007198],
              [8.208461, 50.007332],
              [8.209652, 50.007701],
              [8.210735, 50.00796],
              [8.211738, 50.008291],
            ],
            [
              [8.271418, 50.006146],
              [8.271761, 50.005939],
              [8.272437, 50.005236],
              [8.27322, 50.004429],
              [8.273928, 50.003664],
              [8.274164, 50.00334],
              [8.274518, 50.00334],
              [8.274583, 50.003471],
              [8.274218, 50.003864],
              [8.273467, 50.004553],
              [8.273242, 50.004864],
              [8.273263, 50.005195],
              [8.273338, 50.005319],
            ],
          ],
        },
        id: "gxNzE",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "Y3Mjk",
    name: "M7 Sonnenberg <> Gustavsburg",
    planVersion: PlanVersion.V1,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.264208, 50.101891],
              [8.264369, 50.101382],
              [8.264422, 50.101155],
              [8.26439, 50.100955],
              [8.264337, 50.100508],
              [8.264219, 50.099978],
              [8.264122, 50.099737],
              [8.263865, 50.099475],
              [8.261354, 50.098278],
              [8.26115, 50.098099],
              [8.260936, 50.097831],
              [8.260088, 50.096895],
              [8.25909, 50.095945],
              [8.257653, 50.094561],
              [8.257384, 50.094286],
              [8.257363, 50.094107],
              [8.257073, 50.092806],
              [8.256998, 50.0924],
              [8.25703, 50.092125],
              [8.257105, 50.09134],
              [8.25718, 50.090831],
              [8.257438, 50.089943],
              [8.257567, 50.08942],
              [8.257599, 50.089323],
              [8.257545, 50.089144],
              [8.256944, 50.087017],
              [8.256773, 50.086721],
              [8.256472, 50.086432],
              [8.256183, 50.086191],
              [8.255635, 50.085978],
              [8.254294, 50.085634],
              [8.251548, 50.085214],
              [8.250904, 50.085248],
              [8.248769, 50.085448],
              [8.248115, 50.085496],
              [8.245465, 50.085895],
              [8.244799, 50.085991],
              [8.244338, 50.085985],
              [8.244478, 50.084511],
              [8.244574, 50.083513],
              [8.244714, 50.082419],
              [8.244907, 50.080587],
              [8.242171, 50.080443],
              [8.242407, 50.07848],
              [8.242675, 50.076305],
              [8.2429, 50.074397],
              [8.243244, 50.071774],
              [8.240733, 50.071629],
              [8.240486, 50.071443],
              [8.240315, 50.071216],
              [8.240068, 50.070686],
              [8.239961, 50.069928],
              [8.240122, 50.06893],
              [8.240851, 50.066161],
              [8.241613, 50.063303],
              [8.242214, 50.060576],
              [8.242675, 50.059012],
              [8.243093, 50.057166],
              [8.243566, 50.05543],
              [8.243544, 50.055189],
              [8.243523, 50.054955],
              [8.24245, 50.052303],
              [8.241152, 50.049141],
              [8.240948, 50.048507],
              [8.240894, 50.047625],
              [8.240958, 50.046564],
              [8.24098, 50.045586],
              [8.241023, 50.04478],
              [8.24039, 50.043443],
              [8.239263, 50.041052],
              [8.238147, 50.03862],
              [8.237568, 50.037428],
              [8.236742, 50.03638],
              [8.237858, 50.035939],
              [8.238051, 50.035967],
              [8.238695, 50.035753],
              [8.241034, 50.034871],
              [8.241806, 50.034644],
              [8.243533, 50.034313],
              [8.244231, 50.034092],
              [8.247707, 50.032928],
              [8.25025, 50.032025],
              [8.251537, 50.031322],
              [8.25467, 50.029613],
              [8.256741, 50.028551],
              [8.257159, 50.028861],
              [8.257642, 50.029116],
              [8.258843, 50.029661],
              [8.262448, 50.031253],
              [8.263586, 50.029558],
              [8.265216, 50.027318],
              [8.266021, 50.026359],
              [8.267877, 50.024216],
              [8.269154, 50.022775],
              [8.27087, 50.021017],
              [8.272705, 50.01928],
              [8.27439, 50.017695],
              [8.275216, 50.016902],
              [8.275731, 50.01635],
              [8.276857, 50.015261],
              [8.277662, 50.014365],
              [8.279303, 50.012497],
              [8.279282, 50.012062],
              [8.279207, 50.01158],
              [8.279239, 50.010897],
              [8.279432, 50.010235],
              [8.27969, 50.009704],
              [8.280097, 50.009063],
              [8.280526, 50.008725],
              [8.281331, 50.008504],
              [8.281567, 50.00827],
              [8.281986, 50.007877],
              [8.282586, 50.007449],
              [8.283863, 50.006787],
              [8.284485, 50.006512],
              [8.284957, 50.00627],
              [8.285612, 50.006153],
              [8.286449, 50.006174],
              [8.287543, 50.006346],
              [8.28882, 50.006677],
              [8.289517, 50.006987],
              [8.290193, 50.007222],
              [8.291126, 50.007511],
              [8.292049, 50.007629],
              [8.293798, 50.007649],
              [8.30031, 50.007663],
              [8.300911, 50.006753],
              [8.300976, 50.006491],
              [8.301169, 50.00605],
              [8.301394, 50.005574],
              [8.30163, 50.005043],
              [8.301651, 50.004622],
              [8.301641, 50.004043],
              [8.301576, 50.00365],
              [8.301136, 50.002843],
              [8.300235, 50.001367],
              [8.301609, 50.000933],
              [8.302917, 50.000492],
              [8.304076, 50.000229],
              [8.304945, 50.000161],
              [8.305825, 50.000195],
              [8.306866, 50.000271],
              [8.308325, 50.000602],
              [8.308743, 50.000602],
              [8.309033, 50.000478],
              [8.310267, 49.99943],
              [8.311726, 49.998181],
              [8.312337, 49.997719],
              [8.312874, 49.997347],
              [8.313121, 49.997209],
              [8.3137, 49.99714],
              [8.313872, 49.996954],
              [8.31385, 49.996643],
              [8.314033, 49.995478],
              [8.314118, 49.994747],
              [8.314183, 49.994622],
              [8.314794, 49.994581],
              [8.315341, 49.994581],
              [8.316586, 49.994622],
              [8.316554, 49.995429],
              [8.315835, 49.995485],
              [8.315309, 49.995505],
              [8.314161, 49.995464],
            ],
            [
              [8.238072, 50.036022],
              [8.238716, 50.036808],
              [8.23922, 50.036842],
              [8.239338, 50.037028],
              [8.240637, 50.039598],
              [8.241173, 50.040839],
              [8.241291, 50.04108],
              [8.241323, 50.04161],
              [8.241237, 50.044704],
              [8.241087, 50.044849],
            ],
            [
              [8.242332, 50.079334],
              [8.245078, 50.079486],
              [8.244917, 50.080546],
            ],
          ],
        },
        id: "Y3Mjk",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "MwNjU",
    name: "M8 (Helios HSK <> Mainz-Münchfeld)",
    planVersion: PlanVersion.V1,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.191316, 50.067621],
              [8.18976, 50.067491],
              [8.190029, 50.066217],
              [8.190426, 50.063978],
              [8.190608, 50.063689],
              [8.191026, 50.063675],
              [8.193204, 50.063971],
              [8.192754, 50.06643],
              [8.192067, 50.066375],
              [8.191853, 50.066361],
              [8.19167, 50.066402],
              [8.191423, 50.067621],
              [8.193022, 50.067828],
              [8.193934, 50.068],
              [8.19446, 50.068289],
              [8.194846, 50.068641],
              [8.194996, 50.068889],
              [8.19506, 50.069419],
              [8.194921, 50.069922],
              [8.194814, 50.070417],
              [8.194803, 50.07083],
              [8.195018, 50.071347],
              [8.195415, 50.07187],
              [8.194696, 50.072407],
              [8.194492, 50.072607],
              [8.194374, 50.072938],
              [8.194277, 50.073062],
              [8.194964, 50.073681],
              [8.195511, 50.074115],
              [8.195608, 50.074342],
              [8.195876, 50.074749],
              [8.196176, 50.074941],
              [8.196595, 50.075031],
              [8.197882, 50.075169],
              [8.20286, 50.07574],
              [8.206122, 50.076043],
              [8.209941, 50.076415],
              [8.215982, 50.076993],
              [8.220584, 50.077379],
              [8.223943, 50.077668],
              [8.226957, 50.077957],
              [8.229886, 50.078233],
              [8.230047, 50.076945],
              [8.230348, 50.076043],
              [8.23097, 50.075003],
              [8.231463, 50.074473],
              [8.232354, 50.073688],
              [8.233438, 50.073055],
              [8.234714, 50.072449],
              [8.236442, 50.071877],
              [8.238308, 50.071574],
              [8.240132, 50.071436],
              [8.242128, 50.071498],
              [8.245722, 50.071726],
              [8.250089, 50.071925],
              [8.254949, 50.072215],
              [8.256172, 50.072256],
              [8.256987, 50.072228],
              [8.257889, 50.071939],
              [8.259079, 50.070927],
              [8.260893, 50.069543],
              [8.262169, 50.068744],
              [8.263285, 50.068076],
              [8.263586, 50.067849],
              [8.263897, 50.067683],
              [8.264787, 50.067415],
              [8.266858, 50.06694],
              [8.269379, 50.065975],
              [8.273188, 50.064591],
              [8.277758, 50.062966],
              [8.281127, 50.061678],
              [8.28235, 50.061099],
              [8.28382, 50.060163],
              [8.284507, 50.059605],
              [8.284582, 50.05915],
              [8.28455, 50.058399],
              [8.284453, 50.057903],
              [8.284721, 50.057318],
              [8.285075, 50.057022],
              [8.28559, 50.056739],
              [8.286191, 50.056615],
              [8.28676, 50.056553],
              [8.287575, 50.056643],
              [8.28823, 50.056925],
              [8.288949, 50.057442],
              [8.289903, 50.058406],
              [8.293573, 50.057104],
              [8.297811, 50.055554],
              [8.299087, 50.055065],
              [8.300182, 50.054397],
              [8.301126, 50.053853],
              [8.301136, 50.053639],
              [8.301222, 50.053364],
              [8.301437, 50.052744],
              [8.301523, 50.052351],
              [8.301555, 50.052027],
              [8.301426, 50.051773],
              [8.301222, 50.051545],
              [8.300772, 50.051339],
              [8.300203, 50.051208],
              [8.299785, 50.051001],
              [8.299592, 50.050891],
              [8.299602, 50.050705],
              [8.299634, 50.05034],
              [8.299656, 50.05005],
              [8.299806, 50.049733],
              [8.300128, 50.049513],
              [8.3006, 50.049368],
              [8.30134, 50.049265],
              [8.301727, 50.049162],
              [8.301802, 50.048989],
              [8.301759, 50.048652],
              [8.301641, 50.048114],
              [8.301405, 50.047467],
              [8.301072, 50.046888],
              [8.300675, 50.046247],
              [8.300579, 50.045924],
              [8.300396, 50.045551],
              [8.300289, 50.045221],
              [8.300246, 50.044973],
              [8.299366, 50.044752],
              [8.298519, 50.044428],
              [8.297853, 50.044167],
              [8.296813, 50.043677],
              [8.296405, 50.043478],
              [8.296705, 50.043133],
              [8.297038, 50.042892],
              [8.297853, 50.042547],
              [8.298433, 50.042306],
              [8.298647, 50.042003],
              [8.298647, 50.041672],
              [8.298508, 50.040329],
              [8.298229, 50.03915],
              [8.297843, 50.038131],
              [8.29706, 50.036553],
              [8.296459, 50.035429],
              [8.295944, 50.034423],
              [8.295622, 50.0337],
              [8.295214, 50.032556],
              [8.294495, 50.03126],
              [8.293465, 50.02973],
              [8.292671, 50.028324],
              [8.291073, 50.025856],
              [8.289646, 50.02374],
              [8.289442, 50.023382],
              [8.289356, 50.023003],
              [8.288841, 50.021341],
              [8.288573, 50.020693],
              [8.288305, 50.020349],
              [8.287693, 50.019425],
              [8.286556, 50.018074],
              [8.285644, 50.016888],
              [8.285054, 50.016364],
              [8.284346, 50.015792],
              [8.283563, 50.015061],
              [8.283294, 50.014813],
              [8.283069, 50.014406],
              [8.282758, 50.013758],
              [8.282157, 50.012414],
              [8.28191, 50.011752],
              [8.281664, 50.011455],
              [8.281589, 50.011276],
              [8.281621, 50.011097],
              [8.281846, 50.010952],
              [8.283305, 50.010373],
              [8.284636, 50.00989],
              [8.286577, 50.009146],
              [8.28691, 50.008953],
              [8.287264, 50.00858],
              [8.287704, 50.008104],
              [8.286148, 50.007484],
              [8.285848, 50.007374],
              [8.285483, 50.007436],
              [8.285279, 50.007387],
              [8.284442, 50.006953],
              [8.284099, 50.006767],
              [8.28279, 50.007449],
              [8.282093, 50.007884],
              [8.281846, 50.008077],
              [8.281642, 50.008346],
              [8.281428, 50.008449],
              [8.281095, 50.008449],
              [8.280355, 50.008187],
              [8.279378, 50.00767],
              [8.273885, 50.00545],
              [8.273435, 50.00525],
              [8.273005, 50.005243],
              [8.272587, 50.005477],
              [8.272126, 50.006077],
              [8.27175, 50.006532],
              [8.270988, 50.007353],
              [8.269765, 50.008511],
              [8.268961, 50.009001],
              [8.268414, 50.009208],
              [8.268199, 50.00907],
              [8.267212, 50.008036],
              [8.266718, 50.007911],
              [8.266268, 50.007746],
              [8.265924, 50.007408],
              [8.265474, 50.006946],
              [8.265399, 50.006684],
              [8.265227, 50.006325],
              [8.264165, 50.005298],
              [8.262448, 50.003602],
              [8.262212, 50.003402],
              [8.26174, 50.003119],
              [8.261279, 50.002664],
              [8.261032, 50.002436],
              [8.261107, 50.002195],
              [8.261365, 50.00196],
              [8.261504, 50.001857],
              [8.261247, 50.001333],
              [8.260378, 50.001402],
              [8.260034, 50.001319],
              [8.259884, 50.001133],
              [8.26012, 50.000692],
              [8.260431, 50.000264],
              [8.260313, 50.000126],
              [8.259133, 49.999671],
              [8.258404, 49.999519],
              [8.257835, 49.999333],
              [8.257159, 49.99883],
              [8.256537, 49.998071],
              [8.256172, 49.997685],
              [8.255796, 49.997581],
              [8.254402, 49.997471],
              [8.25349, 49.997478],
              [8.252481, 49.99743],
              [8.251033, 49.997416],
              [8.248404, 49.997147],
              [8.246913, 49.996933],
              [8.246173, 49.996747],
              [8.244896, 49.99654],
              [8.244059, 49.996609],
              [8.24275, 49.996954],
              [8.238062, 49.998409],
              [8.237708, 49.997926],
              [8.237579, 49.997588],
              [8.237557, 49.997333],
              [8.237622, 49.997029],
              [8.237772, 49.996685],
              [8.237697, 49.996623],
              [8.234317, 49.995878],
              [8.232622, 49.995498],
              [8.232204, 49.995443],
              [8.231871, 49.995505],
              [8.230498, 49.996574],
              [8.230155, 49.996836],
              [8.229983, 49.996774],
              [8.229929, 49.996588],
              [8.22994, 49.996505],
              [8.230176, 49.996498],
              [8.230348, 49.99654],
            ],
            [
              [8.261719, 50.001919],
              [8.262277, 50.002471],
              [8.262641, 50.002912],
              [8.262877, 50.003298],
              [8.264079, 50.004457],
              [8.265688, 50.006181],
              [8.266418, 50.00665],
              [8.267148, 50.007229],
              [8.267276, 50.007546],
              [8.267276, 50.007918],
            ],
            [
              [8.28985, 50.058448],
              [8.284303, 50.060486],
              [8.283713, 50.060597],
              [8.28293, 50.060782],
            ],
            [
              [8.276267, 50.063565],
              [8.27601, 50.063779],
              [8.275838, 50.064137],
              [8.276042, 50.064564],
              [8.276364, 50.065018],
              [8.276117, 50.065273],
              [8.275312, 50.065548],
              [8.273842, 50.065872],
              [8.271493, 50.066451],
              [8.270634, 50.066492],
              [8.26925, 50.066533],
              [8.267888, 50.066843],
              [8.267083, 50.067146],
              [8.26394, 50.068283],
              [8.263414, 50.068434],
              [8.26233, 50.068716],
            ],
            [
              [8.19785, 50.075127],
              [8.197989, 50.074535],
              [8.19476, 50.072456],
            ],
            [
              [8.29869, 50.042341],
              [8.298604, 50.042988],
              [8.298926, 50.043553],
              [8.299334, 50.04387],
              [8.299913, 50.044298],
              [8.300278, 50.044876],
            ],
            [
              [8.272126, 50.005863],
              [8.272233, 50.005215],
              [8.272812, 50.004657],
              [8.273499, 50.003946],
              [8.273799, 50.003498],
              [8.274239, 50.003209],
              [8.274668, 50.003264],
              [8.274754, 50.003505],
              [8.274486, 50.003691],
              [8.273638, 50.004498],
              [8.273392, 50.004843],
              [8.273392, 50.00505],
              [8.273413, 50.005167],
            ],
          ],
        },
        id: "MwNjU",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "M4OTU",
    name: "M9 Nordfriedhof <> Erbenheim",
    planVersion: PlanVersion.V1,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.220134, 50.096881],
              [8.219168, 50.096165],
              [8.218246, 50.09527],
              [8.217623, 50.094802],
              [8.217001, 50.094747],
              [8.2164, 50.094775],
              [8.216207, 50.094651],
              [8.216357, 50.094458],
              [8.216701, 50.094348],
              [8.217387, 50.094293],
              [8.218074, 50.093921],
              [8.218718, 50.093618],
              [8.219125, 50.093178],
              [8.220735, 50.091085],
              [8.221292, 50.090727],
              [8.221443, 50.090466],
              [8.221271, 50.08997],
              [8.22391, 50.08774],
              [8.224769, 50.086969],
              [8.226056, 50.086033],
              [8.226764, 50.085647],
              [8.226357, 50.085565],
              [8.225605, 50.085578],
              [8.225155, 50.085262],
              [8.224854, 50.084381],
              [8.224844, 50.083665],
              [8.224688, 50.082983],
              [8.224576, 50.082801],
              [8.223739, 50.082591],
              [8.222408, 50.082591],
              [8.220799, 50.082522],
              [8.21979, 50.08226],
              [8.219018, 50.081641],
              [8.218632, 50.081131],
              [8.219039, 50.079961],
              [8.220177, 50.077909],
              [8.220842, 50.077055],
              [8.222194, 50.075926],
              [8.223288, 50.07499],
              [8.223717, 50.07459],
              [8.224726, 50.073392],
              [8.226142, 50.071836],
              [8.22715, 50.070748],
              [8.228073, 50.070101],
              [8.230841, 50.068117],
              [8.232193, 50.067332],
              [8.232515, 50.066767],
              [8.234425, 50.064467],
              [8.235991, 50.063503],
              [8.2376, 50.062553],
              [8.238716, 50.061974],
              [8.239553, 50.061519],
              [8.240068, 50.061313],
              [8.241012, 50.061409],
              [8.242707, 50.061643],
              [8.243973, 50.061878],
              [8.245132, 50.062167],
              [8.245926, 50.062263],
              [8.246849, 50.062112],
              [8.247535, 50.061767],
              [8.2479, 50.061395],
              [8.248436, 50.060789],
              [8.249145, 50.059839],
              [8.249381, 50.059329],
              [8.249638, 50.058585],
              [8.249681, 50.058227],
              [8.250024, 50.057979],
              [8.250539, 50.057814],
              [8.251162, 50.057745],
              [8.251698, 50.057869],
              [8.2529, 50.058255],
              [8.253694, 50.058475],
              [8.254359, 50.058406],
              [8.25526, 50.058186],
              [8.256161, 50.058089],
              [8.257148, 50.058076],
              [8.258114, 50.058213],
              [8.260281, 50.058682],
              [8.260732, 50.058861],
              [8.263822, 50.059674],
              [8.268006, 50.060776],
              [8.270838, 50.061519],
              [8.271782, 50.06185],
              [8.272297, 50.062112],
              [8.273177, 50.062642],
              [8.273671, 50.063269],
              [8.274422, 50.063186],
              [8.275838, 50.063117],
              [8.276825, 50.063062],
              [8.278241, 50.062594],
              [8.280451, 50.061781],
              [8.281889, 50.06112],
              [8.283091, 50.060362],
              [8.284185, 50.059605],
              [8.284292, 50.05915],
              [8.284249, 50.058627],
              [8.284163, 50.058213],
              [8.284185, 50.057786],
              [8.284314, 50.057373],
              [8.284507, 50.057056],
              [8.285022, 50.056753],
              [8.28558, 50.056505],
              [8.286374, 50.056381],
              [8.287253, 50.05634],
              [8.288047, 50.056588],
              [8.288884, 50.05696],
              [8.289571, 50.057648],
              [8.289893, 50.058089],
              [8.290107, 50.058682],
              [8.290279, 50.059205],
              [8.290622, 50.060321],
              [8.291073, 50.061395],
              [8.291245, 50.061712],
              [8.291953, 50.061919],
              [8.292983, 50.062084],
              [8.29354, 50.062098],
              [8.294549, 50.061933],
              [8.29545, 50.061533],
              [8.296072, 50.061423],
              [8.296008, 50.058296],
              [8.295965, 50.057814],
              [8.296351, 50.056973],
              [8.296523, 50.056174],
              [8.29809, 50.055554],
              [8.299055, 50.055196],
              [8.299549, 50.054907],
              [8.300042, 50.054631],
              [8.301136, 50.05397],
              [8.301437, 50.053819],
              [8.302038, 50.053598],
              [8.302853, 50.053378],
              [8.303797, 50.053281],
              [8.304484, 50.05324],
              [8.304677, 50.053488],
              [8.304999, 50.054025],
              [8.305235, 50.054439],
              [8.305213, 50.054921],
              [8.305235, 50.055306],
              [8.305449, 50.055486],
              [8.306093, 50.055554],
              [8.307402, 50.05572],
              [8.307381, 50.05594],
              [8.30723, 50.056326],
              [8.307209, 50.056519],
              [8.307338, 50.056877],
              [8.307681, 50.057387],
              [8.308024, 50.057896],
              [8.30723, 50.058571],
              [8.306522, 50.05904],
              [8.306115, 50.05926],
              [8.305428, 50.059343],
              [8.30442, 50.059247],
              [8.303475, 50.061836],
              [8.303196, 50.061933],
              [8.302917, 50.061754],
              [8.30193, 50.061327],
              [8.301952, 50.059949],
              [8.301888, 50.058888],
              [8.304248, 50.059219],
            ],
            [
              [8.289957, 50.058516],
              [8.284357, 50.060569],
              [8.283412, 50.060789],
              [8.282275, 50.060941],
            ],
            [
              [8.276857, 50.063117],
              [8.275999, 50.063634],
              [8.275752, 50.063909],
              [8.275698, 50.064212],
              [8.275892, 50.064536],
              [8.276181, 50.064977],
              [8.276085, 50.065204],
              [8.275774, 50.065328],
              [8.275334, 50.065376],
              [8.273724, 50.063351],
            ],
            [
              [8.225198, 50.085482],
              [8.224597, 50.085868],
              [8.224597, 50.086212],
              [8.223825, 50.086735],
              [8.220541, 50.088704],
              [8.220434, 50.089227],
              [8.22052, 50.089571],
              [8.221099, 50.089915],
            ],
          ],
        },
        id: "M4OTU",
      },
    ],
  },
];
