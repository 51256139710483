import { BusCategory, Line } from "@/types";

export const v1_stadtBusse: Line[] = [
  {
    category: BusCategory.STADT,
    id: "A3NzM",
    name: "10 Dürerplatz <> Kostheim",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.225262, 50.085413],
              [8.225101, 50.085276],
              [8.224989, 50.085007],
              [8.224774, 50.08448],
              [8.224795, 50.083813],
              [8.224731, 50.083565],
              [8.224645, 50.083155],
              [8.224474, 50.082935],
              [8.224109, 50.082752],
              [8.223658, 50.082656],
              [8.222821, 50.082639],
              [8.221534, 50.082632],
              [8.220729, 50.08257],
              [8.219849, 50.082343],
              [8.219517, 50.082109],
              [8.218889, 50.081627],
              [8.218637, 50.081238],
              [8.218616, 50.080966],
              [8.218701, 50.080622],
              [8.21883, 50.080298],
              [8.21919, 50.079537],
              [8.219597, 50.078821],
              [8.220139, 50.077847],
              [8.220364, 50.077506],
              [8.220509, 50.07731],
              [8.2164, 50.076959],
              [8.209727, 50.076305],
              [8.20786, 50.076119],
              [8.207195, 50.076016],
              [8.206798, 50.075926],
              [8.205736, 50.075231],
              [8.204566, 50.074356],
              [8.203794, 50.07397],
              [8.202571, 50.073495],
              [8.200328, 50.07313],
              [8.198043, 50.072896],
              [8.196949, 50.072662],
              [8.195822, 50.072194],
              [8.195586, 50.072029],
              [8.1952, 50.071815],
              [8.194921, 50.071457],
              [8.194792, 50.071216],
              [8.194685, 50.070934],
              [8.194664, 50.07061],
              [8.194749, 50.070128],
              [8.194867, 50.069674],
              [8.194953, 50.069254],
              [8.194814, 50.068799],
              [8.194481, 50.068448],
              [8.194138, 50.068234],
              [8.193483, 50.068],
              [8.192818, 50.067883],
              [8.191595, 50.067711],
              [8.189707, 50.067539],
              [8.189224, 50.067408],
              [8.187336, 50.067263],
              [8.185555, 50.067132],
              [8.184718, 50.067146],
              [8.183205, 50.067325],
              [8.181628, 50.067608],
              [8.181553, 50.067394],
              [8.181521, 50.067215],
              [8.182057, 50.066705],
              [8.184149, 50.064529],
              [8.18519, 50.063558],
              [8.185909, 50.062938],
              [8.187196, 50.061967],
              [8.18872, 50.060748],
              [8.190114, 50.059701],
              [8.190683, 50.059219],
              [8.191144, 50.05873],
              [8.191359, 50.058282],
              [8.191499, 50.057862],
              [8.191649, 50.056705],
              [8.191788, 50.055589],
              [8.191788, 50.055196],
              [8.191574, 50.054528],
              [8.191316, 50.053515],
              [8.191209, 50.052978],
              [8.191284, 50.052599],
              [8.191499, 50.051401],
              [8.191702, 50.050753],
              [8.192217, 50.049713],
              [8.1924, 50.049237],
              [8.192689, 50.048803],
              [8.19417, 50.048976],
              [8.197056, 50.049341],
              [8.199921, 50.049713],
              [8.201004, 50.049968],
              [8.201948, 50.050202],
              [8.202463, 50.050319],
              [8.203998, 50.05034],
              [8.204073, 50.050167],
              [8.202367, 50.048204],
              [8.201026, 50.04673],
              [8.200221, 50.045875],
              [8.199406, 50.044925],
              [8.199255, 50.04469],
              [8.199266, 50.044518],
              [8.199846, 50.044532],
              [8.201391, 50.044559],
              [8.202378, 50.04438],
              [8.205189, 50.04376],
              [8.209534, 50.042809],
              [8.209941, 50.043078],
              [8.210081, 50.043512],
              [8.210585, 50.043705],
              [8.211529, 50.043836],
              [8.216239, 50.04458],
              [8.22037, 50.045331],
              [8.221121, 50.045427],
              [8.227022, 50.046495],
              [8.231045, 50.047191],
              [8.232472, 50.047529],
              [8.233845, 50.04777],
              [8.234832, 50.047922],
              [8.236173, 50.048046],
              [8.237268, 50.04808],
              [8.238555, 50.048052],
              [8.239231, 50.048011],
              [8.240476, 50.047894],
              [8.240755, 50.047853],
              [8.240905, 50.045868],
              [8.240916, 50.044869],
              [8.240701, 50.044249],
              [8.239939, 50.042671],
              [8.23878, 50.040177],
              [8.238587, 50.04006],
              [8.237429, 50.037497],
              [8.236688, 50.036539],
              [8.236592, 50.036408],
              [8.236624, 50.036304],
              [8.237, 50.036118],
              [8.237965, 50.035808],
              [8.238126, 50.03587],
              [8.240047, 50.035174],
              [8.240916, 50.03483],
              [8.24157, 50.03463],
              [8.242654, 50.034416],
              [8.243222, 50.034327],
              [8.244081, 50.034085],
              [8.246516, 50.033245],
              [8.248876, 50.032452],
              [8.250046, 50.032018],
              [8.251762, 50.031088],
              [8.255292, 50.029192],
              [8.256665, 50.02851],
              [8.256794, 50.028482],
              [8.256966, 50.028627],
              [8.257545, 50.028985],
              [8.262341, 50.03115],
              [8.263382, 50.029537],
              [8.264326, 50.02822],
              [8.265882, 50.026229],
              [8.267791, 50.024057],
              [8.268961, 50.024188],
              [8.270999, 50.024485],
              [8.272512, 50.024581],
              [8.275001, 50.024409],
              [8.277383, 50.023926],
              [8.280526, 50.022823],
              [8.284013, 50.021362],
              [8.287747, 50.019749],
              [8.290472, 50.018412],
              [8.292199, 50.01744],
              [8.293884, 50.01624],
              [8.296051, 50.014606],
              [8.29706, 50.013717],
              [8.29765, 50.01291],
              [8.298272, 50.011759],
              [8.300192, 50.007808],
              [8.300407, 50.007691],
              [8.300997, 50.006781],
              [8.301469, 50.005622],
              [8.301716, 50.005098],
              [8.30178, 50.004257],
              [8.301662, 50.003519],
              [8.300375, 50.001388],
              [8.301619, 50.001002],
              [8.302574, 50.000643],
              [8.303196, 50.000492],
              [8.304055, 50.000305],
              [8.304935, 50.000223],
              [8.305868, 50.00025],
              [8.305809, 50.000785],
              [8.305809, 50.00136],
              [8.305814, 50.001923],
              [8.305836, 50.002757],
              [8.30583, 50.003467],
              [8.302665, 50.003433],
              [8.302102, 50.002191],
              [8.301571, 50.001067],
            ],
            [
              [8.238212, 50.035932],
              [8.238555, 50.036318],
              [8.238802, 50.036697],
              [8.23936, 50.036766],
              [8.239532, 50.036987],
              [8.240744, 50.03946],
              [8.240958, 50.04017],
              [8.241355, 50.041032],
              [8.241398, 50.041176],
              [8.241366, 50.043161],
              [8.241313, 50.044752],
              [8.240958, 50.045021],
            ],
            [
              [8.189664, 50.067463],
              [8.19034, 50.063999],
              [8.19049, 50.063682],
              [8.190662, 50.06362],
              [8.19093, 50.063613],
              [8.193258, 50.06393],
              [8.19329, 50.064026],
              [8.19284, 50.066464],
              [8.192657, 50.066499],
              [8.191842, 50.066423],
              [8.191756, 50.066444],
              [8.191509, 50.06767],
            ],
          ],
        },
        id: "A3NzM",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "MzNDc",
    name: "11 Nerotal <> Gräselberg",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.225477, 50.094899],
              [8.225842, 50.095312],
              [8.226292, 50.095243],
              [8.226721, 50.095146],
              [8.227558, 50.094967],
              [8.228545, 50.094802],
              [8.22921, 50.094802],
              [8.230584, 50.094844],
              [8.231699, 50.094857],
              [8.232365, 50.09461],
              [8.233416, 50.094018],
              [8.234961, 50.093164],
              [8.235261, 50.092751],
              [8.235648, 50.092008],
              [8.236334, 50.091127],
              [8.236656, 50.090865],
              [8.239295, 50.089172],
              [8.242273, 50.087282],
            ],
            [
              [8.226078, 50.069715],
              [8.221647, 50.066561],
              [8.220187, 50.065418],
              [8.216593, 50.062442],
              [8.21846, 50.061361],
              [8.220295, 50.060411],
              [8.219833, 50.060094],
              [8.219082, 50.059632],
              [8.2186, 50.059233],
              [8.218417, 50.058923],
              [8.218353, 50.058585],
              [8.218449, 50.058158],
              [8.21905, 50.057504],
              [8.219522, 50.057125],
              [8.219855, 50.056843],
              [8.220059, 50.056595],
              [8.220037, 50.056312],
              [8.220069, 50.055954],
              [8.220177, 50.055885],
              [8.220788, 50.055885],
              [8.2214, 50.055899],
              [8.221925, 50.05594],
              [8.222333, 50.056044],
              [8.222687, 50.056257],
              [8.222977, 50.056422],
              [8.22331, 50.056174],
              [8.223653, 50.056009],
              [8.22405, 50.055885],
              [8.224468, 50.055871],
              [8.224854, 50.055885],
            ],
          ],
        },
        id: "MzNDc",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "ExNDk",
    name: "12 + 22 + 32",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.306608, 50.094417],
              [8.30663, 50.094155],
              [8.306437, 50.093825],
              [8.305857, 50.093481],
              [8.305063, 50.093481],
              [8.304827, 50.093577],
              [8.30075, 50.092435],
              [8.296974, 50.091361],
              [8.29648, 50.090975],
              [8.295321, 50.08986],
              [8.294678, 50.089227],
              [8.29397, 50.08869],
              [8.293498, 50.088552],
              [8.289614, 50.087768],
              [8.287135, 50.087169],
              [8.285837, 50.08688],
              [8.282018, 50.086033],
              [8.282104, 50.085551],
              [8.282704, 50.084725],
              [8.283048, 50.084202],
              [8.283294, 50.08374],
              [8.281235, 50.083465],
              [8.280655, 50.083396],
              [8.276535, 50.085241],
              [8.275119, 50.085496],
              [8.274475, 50.085482],
              [8.272512, 50.085193],
              [8.269744, 50.084787],
              [8.267448, 50.084642],
              [8.265302, 50.08447],
              [8.262599, 50.083789],
              [8.258479, 50.082825],
              [8.255196, 50.082178],
              [8.254058, 50.081916],
              [8.24702, 50.080071],
              [8.246012, 50.079589],
              [8.245679, 50.079534],
              [8.245389, 50.079472],
              [8.245196, 50.07941],
              [8.245046, 50.079217],
              [8.245325, 50.077055],
              [8.245432, 50.075775],
              [8.245368, 50.074411],
              [8.245153, 50.073199],
              [8.244724, 50.072153],
              [8.244617, 50.071946],
              [8.24363, 50.071822],
              [8.242857, 50.071932],
              [8.240798, 50.071836],
              [8.240454, 50.071781],
              [8.240132, 50.071216],
              [8.239875, 50.070555],
              [8.239853, 50.069784],
              [8.239961, 50.068682],
              [8.240497, 50.066602],
              [8.24187, 50.06163],
              [8.240368, 50.061451],
              [8.23981, 50.061575],
              [8.239295, 50.061313],
              [8.23848, 50.060803],
              [8.236012, 50.059853],
            ],
            [
              [8.280687, 50.083334],
              [8.281792, 50.08288],
              [8.282028, 50.082763],
              [8.282007, 50.082949],
              [8.281031, 50.085572],
              [8.28191, 50.08604],
            ],
          ],
        },
        id: "ExNDk",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "U3MDQ",
    name: "12 Heßloch <> Frauenstein",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.306479, 50.094527],
              [8.306072, 50.094885],
              [8.305364, 50.095188],
              [8.303668, 50.09578],
              [8.304226, 50.096399],
              [8.299935, 50.098147],
              [8.297789, 50.099427],
              [8.297167, 50.099881],
              [8.296845, 50.100198],
              [8.296566, 50.100983],
              [8.296909, 50.101437],
              [8.29766, 50.10251],
              [8.297703, 50.102882],
              [8.29766, 50.104726],
              [8.297746, 50.105896],
              [8.297682, 50.106446],
              [8.297575, 50.107121],
              [8.297424, 50.107644],
              [8.297768, 50.108194],
              [8.298025, 50.108703],
              [8.29751, 50.10946],
              [8.296373, 50.110713],
              [8.296351, 50.11096],
            ],
            [
              [8.235905, 50.059825],
              [8.235154, 50.059894],
              [8.234146, 50.060459],
              [8.230541, 50.062497],
              [8.226571, 50.064784],
              [8.223438, 50.067002],
              [8.222719, 50.067435],
              [8.221657, 50.066699],
              [8.220617, 50.065879],
              [8.218278, 50.063944],
              [8.216497, 50.062518],
              [8.213482, 50.064233],
              [8.210027, 50.066223],
              [8.208343, 50.067208],
              [8.20815, 50.067243],
              [8.20683, 50.066237],
              [8.206476, 50.065858],
              [8.206433, 50.0655],
              [8.205553, 50.064536],
              [8.205178, 50.064109],
              [8.204234, 50.063117],
              [8.203247, 50.063496],
              [8.202903, 50.063586],
              [8.20256, 50.063599],
              [8.201165, 50.063475],
              [8.199202, 50.063524],
              [8.19755, 50.063496],
              [8.196069, 50.063489],
              [8.195136, 50.063393],
              [8.193076, 50.063117],
              [8.191863, 50.062904],
              [8.190962, 50.062649],
              [8.190072, 50.062394],
              [8.189439, 50.062015],
              [8.188119, 50.061313],
              [8.186971, 50.062229],
              [8.185351, 50.06351],
              [8.184289, 50.064515],
              [8.182175, 50.066671],
              [8.181607, 50.067229],
              [8.181649, 50.067553],
              [8.181467, 50.067649],
              [8.180737, 50.067966],
              [8.178871, 50.069804],
              [8.178484, 50.070066],
              [8.178173, 50.070163],
              [8.177733, 50.070183],
              [8.177336, 50.070183],
              [8.173774, 50.069901],
              [8.173474, 50.069763],
              [8.172777, 50.069495],
              [8.172004, 50.068971],
              [8.169569, 50.067546],
              [8.168989, 50.067325],
              [8.168002, 50.067091],
              [8.166972, 50.066871],
              [8.165975, 50.066657],
              [8.164977, 50.066513],
              [8.164526, 50.066458],
              [8.164151, 50.066526],
              [8.16282, 50.066719],
              [8.162241, 50.066816],
              [8.160954, 50.06674],
              [8.159966, 50.066595],
              [8.159162, 50.066464],
              [8.158507, 50.066437],
              [8.158025, 50.066437],
              [8.157295, 50.066347],
              [8.155761, 50.066051],
              [8.155321, 50.0659],
              [8.154666, 50.065755],
              [8.154216, 50.065597],
              [8.153894, 50.065411],
              [8.153883, 50.065073],
              [8.153991, 50.064763],
              [8.154205, 50.064529],
              [8.154173, 50.064144],
              [8.154012, 50.063717],
              [8.154044, 50.063331],
              [8.154012, 50.063028],
              [8.154066, 50.062773],
              [8.154581, 50.06196],
              [8.155053, 50.061561],
              [8.155761, 50.06112],
              [8.157402, 50.060052],
            ],
          ],
        },
        id: "U3MDQ",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "k3Nzg",
    name: "13 + 23",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.244499, 50.089901],
              [8.245003, 50.089932],
              [8.24518, 50.089967],
              [8.245234, 50.090046],
              [8.245207, 50.09016],
              [8.245159, 50.09028],
              [8.24503, 50.090524],
              [8.244928, 50.090886],
              [8.244939, 50.091061],
              [8.244547, 50.091048],
              [8.244488, 50.091017],
              [8.244081, 50.091048],
              [8.242911, 50.091175],
              [8.242772, 50.091127],
              [8.242831, 50.090903],
              [8.243051, 50.090579],
              [8.243297, 50.090308],
              [8.243748, 50.090087],
              [8.244279, 50.089919],
              [8.244333, 50.089836],
              [8.244161, 50.089457],
              [8.244016, 50.089161],
              [8.243925, 50.088855],
              [8.243834, 50.088397],
              [8.243823, 50.088287],
              [8.242294, 50.087337],
              [8.242283, 50.087248],
              [8.243324, 50.086559],
              [8.241924, 50.085782],
              [8.241382, 50.085417],
              [8.241162, 50.085272],
              [8.241007, 50.085114],
              [8.240706, 50.085286],
              [8.240443, 50.085355],
              [8.24025, 50.085355],
              [8.23973, 50.08519],
              [8.239188, 50.085014],
              [8.239027, 50.084818],
              [8.238861, 50.084536],
              [8.238754, 50.084357],
              [8.238705, 50.084209],
              [8.238544, 50.083864],
              [8.238255, 50.083548],
              [8.23781, 50.083296],
              [8.237498, 50.08321],
              [8.237101, 50.083169],
              [8.236796, 50.083186],
              [8.236624, 50.083203],
              [8.236281, 50.083221],
              [8.235953, 50.083241],
              [8.235873, 50.08319],
              [8.235868, 50.082856],
              [8.236029, 50.08161],
              [8.236147, 50.080697],
              [8.236308, 50.079493],
              [8.23642, 50.078838],
              [8.236581, 50.078257],
              [8.236747, 50.077696],
              [8.236871, 50.077186],
              [8.237064, 50.075416],
              [8.237252, 50.073988],
              [8.237455, 50.072342],
              [8.237498, 50.071839],
              [8.237461, 50.071516],
              [8.237289, 50.071154],
              [8.237219, 50.07093],
              [8.235637, 50.070672],
              [8.235685, 50.070438],
              [8.235739, 50.070021],
              [8.235857, 50.06977],
              [8.235894, 50.069594],
              [8.236077, 50.069422],
              [8.236088, 50.069271],
              [8.235986, 50.067673],
              [8.235202, 50.067456],
              [8.233561, 50.067077],
              [8.232542, 50.066826],
              [8.232445, 50.066754],
              [8.233507, 50.065435],
              [8.234242, 50.064543],
              [8.234902, 50.064044],
              [8.236656, 50.063048],
              [8.237734, 50.062432],
              [8.239515, 50.061492],
              [8.238614, 50.060944],
              [8.23819, 50.060776],
              [8.235959, 50.059897],
            ],
            [
              [8.232375, 50.066795],
              [8.232236, 50.067091],
              [8.232161, 50.067601],
              [8.232611, 50.068971],
              [8.233083, 50.070624],
              [8.235497, 50.070651],
            ],
            [
              [8.236774, 50.077737],
              [8.238426, 50.077758],
              [8.239124, 50.071712],
              [8.239145, 50.071368],
              [8.238931, 50.071368],
              [8.237622, 50.071533],
            ],
          ],
        },
        id: "k3Nzg",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "I2ODM",
    name: "13 Neroberg <> Schierstein Hafen",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.233491, 50.099331],
              [8.233073, 50.097913],
              [8.231163, 50.09814],
              [8.231002, 50.098264],
              [8.231034, 50.09856],
              [8.231227, 50.098856],
              [8.233609, 50.099413],
              [8.235379, 50.09984],
              [8.236527, 50.100129],
              [8.238491, 50.098058],
              [8.241581, 50.098581],
              [8.242214, 50.098354],
              [8.242246, 50.098133],
              [8.24216, 50.097824],
              [8.242064, 50.097555],
              [8.241506, 50.097046],
              [8.240047, 50.096179],
              [8.239628, 50.09589],
              [8.239381, 50.095614],
              [8.239295, 50.095167],
              [8.239349, 50.094761],
              [8.239553, 50.094293],
              [8.239435, 50.093797],
              [8.239478, 50.093426],
              [8.23966, 50.092717],
              [8.239671, 50.092441],
              [8.239542, 50.092283],
              [8.240229, 50.091911],
              [8.241023, 50.091767],
              [8.241452, 50.091553],
              [8.241774, 50.091333],
              [8.2423, 50.091216],
              [8.242745, 50.091144],
            ],
            [
              [8.235862, 50.059908],
              [8.235261, 50.059949],
              [8.228502, 50.063792],
              [8.226657, 50.064853],
              [8.223202, 50.067263],
              [8.22273, 50.067525],
              [8.222451, 50.067442],
              [8.216529, 50.062663],
              [8.214812, 50.063599],
              [8.20859, 50.067208],
              [8.208182, 50.067318],
              [8.207774, 50.067139],
              [8.206336, 50.0659],
              [8.206294, 50.0655],
              [8.204191, 50.063214],
              [8.203011, 50.063655],
              [8.20256, 50.063655],
              [8.19932, 50.063613],
              [8.197646, 50.063599],
              [8.195844, 50.063586],
              [8.194513, 50.063379],
              [8.192518, 50.063145],
              [8.190715, 50.062718],
              [8.189986, 50.062539],
              [8.188162, 50.061409],
              [8.188215, 50.061244],
              [8.189921, 50.059928],
              [8.190629, 50.059426],
              [8.191166, 50.058847],
              [8.191456, 50.058262],
              [8.191692, 50.057146],
              [8.191874, 50.055692],
              [8.191853, 50.055086],
              [8.191595, 50.054259],
              [8.191338, 50.053205],
              [8.191295, 50.052765],
              [8.191595, 50.051277],
              [8.192432, 50.049368],
              [8.192818, 50.048548],
              [8.193741, 50.046957],
              [8.194438, 50.045944],
              [8.194524, 50.045538],
              [8.194792, 50.045152],
              [8.195264, 50.044918],
              [8.195908, 50.044766],
              [8.196123, 50.044601],
              [8.196402, 50.044532],
              [8.197292, 50.044497],
              [8.199041, 50.044511],
              [8.199427, 50.044463],
              [8.200264, 50.044504],
              [8.20138, 50.04449],
              [8.201948, 50.044415],
              [8.203161, 50.044167],
              [8.204706, 50.043822],
              [8.204373, 50.043202],
              [8.204191, 50.042995],
              [8.203955, 50.04292],
              [8.203493, 50.04292],
              [8.20168, 50.042906],
              [8.201197, 50.042802],
              [8.200371, 50.042671],
              [8.200082, 50.042665],
              [8.199921, 50.042823],
              [8.199309, 50.044428],
              [8.199127, 50.044614],
              [8.199266, 50.044828],
              [8.200146, 50.045903],
              [8.200039, 50.045979],
              [8.197657, 50.045834],
              [8.197335, 50.047612],
              [8.193719, 50.047219],
            ],
          ],
        },
        id: "I2ODM",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "YxMTY",
    name: "14 Waldfriedhof <> Bf. WI-Ost",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.240583, 50.047804],
              [8.240626, 50.047205],
              [8.240755, 50.044952],
              [8.24068, 50.044752],
              [8.240036, 50.04325],
              [8.238963, 50.040935],
              [8.238459, 50.040081],
              [8.238083, 50.039488],
              [8.236581, 50.036635],
              [8.236495, 50.036373],
              [8.23656, 50.036201],
              [8.237193, 50.035981],
              [8.237879, 50.035781],
              [8.238147, 50.035767],
              [8.238373, 50.035946],
              [8.240411, 50.035195],
              [8.241366, 50.034844],
              [8.241881, 50.034699],
              [8.242493, 50.034589],
              [8.243673, 50.034375],
              [8.243834, 50.034781],
              [8.244424, 50.035484],
              [8.245389, 50.036897],
              [8.246655, 50.038889],
              [8.248039, 50.041066],
              [8.248329, 50.041493],
              [8.248426, 50.041789],
              [8.248351, 50.042086],
              [8.247986, 50.042327],
              [8.248276, 50.042547],
              [8.249338, 50.042933],
              [8.25129, 50.043367],
              [8.25246, 50.042541],
              [8.255239, 50.040639],
            ],
            [
              [8.199974, 50.070211],
              [8.197925, 50.06946],
              [8.196938, 50.069109],
              [8.195972, 50.068737],
              [8.195232, 50.068475],
              [8.194857, 50.068489],
              [8.194508, 50.068537],
              [8.194245, 50.068358],
              [8.194057, 50.068248],
              [8.193494, 50.068069],
              [8.192947, 50.067945],
              [8.192673, 50.068148],
              [8.192496, 50.068265],
              [8.192255, 50.068403],
              [8.191917, 50.068499],
              [8.191579, 50.068544],
              [8.18821, 50.068696],
              [8.187937, 50.068744],
              [8.187647, 50.068823],
              [8.187448, 50.068954],
              [8.186156, 50.069667],
              [8.184401, 50.070679],
              [8.183913, 50.070979],
              [8.183699, 50.071109],
              [8.18357, 50.071285],
              [8.185426, 50.071891],
              [8.186477, 50.072246],
              [8.186622, 50.072376],
              [8.186703, 50.072521],
              [8.186687, 50.072655],
              [8.186215, 50.073306],
              [8.186027, 50.073447],
              [8.185941, 50.073526],
              [8.185673, 50.073606],
              [8.18548, 50.073623],
              [8.185287, 50.073592],
              [8.184809, 50.073485],
              [8.18305, 50.072886],
              [8.182942, 50.072814],
              [8.182712, 50.072759],
              [8.182636, 50.072814],
              [8.182481, 50.072969],
              [8.182245, 50.073175],
              [8.181826, 50.073433],
              [8.181655, 50.073492],
              [8.181526, 50.073537],
              [8.181574, 50.073747],
              [8.181993, 50.074267],
              [8.182368, 50.074621],
              [8.182604, 50.074766],
            ],
            [
              [8.183012, 50.072824],
              [8.183092, 50.07269],
              [8.183028, 50.072469],
              [8.182969, 50.072187],
              [8.183039, 50.071974],
              [8.183377, 50.071295],
              [8.183527, 50.071264],
            ],
            [
              [8.238469, 50.036015],
              [8.238641, 50.036366],
              [8.238813, 50.036656],
              [8.239403, 50.036718],
              [8.239607, 50.036911],
              [8.239821, 50.037379],
              [8.240765, 50.03933],
              [8.240894, 50.039591],
              [8.240926, 50.039888],
              [8.241248, 50.040646],
              [8.241463, 50.041135],
              [8.241484, 50.041383],
              [8.24142, 50.044084],
              [8.241388, 50.044787],
              [8.240765, 50.045303],
            ],
          ],
        },
        id: "YxMTY",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "Q5ODg",
    name: "15 + 25",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.194492, 50.083568],
              [8.195447, 50.082687],
              [8.196841, 50.081978],
              [8.199245, 50.080876],
              [8.200189, 50.080381],
              [8.200296, 50.080098],
              [8.200382, 50.079809],
              [8.202442, 50.080119],
              [8.203976, 50.080271],
              [8.204952, 50.080353],
              [8.205864, 50.080546],
              [8.208182, 50.080959],
              [8.208203, 50.081159],
              [8.20757, 50.083727],
              [8.208042, 50.084305],
              [8.208965, 50.083968],
              [8.210961, 50.08319],
              [8.211905, 50.082887],
              [8.212527, 50.082735],
              [8.213793, 50.082467],
              [8.214566, 50.082302],
              [8.215284, 50.082246],
              [8.215585, 50.082219],
              [8.215746, 50.082054],
              [8.216014, 50.081944],
              [8.217044, 50.081765],
              [8.217988, 50.081572],
              [8.218653, 50.081413],
              [8.218803, 50.081586],
              [8.219007, 50.081778],
              [8.219243, 50.081985],
              [8.219694, 50.082322],
              [8.219962, 50.082432],
              [8.220627, 50.082611],
              [8.220992, 50.082701],
              [8.221861, 50.082701],
              [8.222934, 50.082694],
              [8.223685, 50.082715],
              [8.224114, 50.082859],
              [8.224447, 50.08299],
              [8.224511, 50.083183],
              [8.224672, 50.083596],
              [8.224694, 50.083926],
              [8.224694, 50.084449],
              [8.224779, 50.084745],
              [8.224962, 50.085159],
              [8.225026, 50.085324],
              [8.225402, 50.085324],
              [8.225713, 50.085062],
              [8.226786, 50.084236],
              [8.227408, 50.083795],
              [8.227719, 50.083623],
              [8.22847, 50.083348],
              [8.228792, 50.083162],
              [8.229167, 50.082873],
              [8.229339, 50.082419],
              [8.229425, 50.081703],
              [8.229468, 50.080883],
              [8.229865, 50.080436],
              [8.230144, 50.078418],
              [8.235015, 50.07888],
              [8.236034, 50.078997],
              [8.236624, 50.079024],
              [8.242085, 50.0793],
              [8.242375, 50.079396],
              [8.244917, 50.07952],
              [8.245207, 50.07952],
              [8.245658, 50.079589],
              [8.246194, 50.079761],
              [8.246902, 50.080085],
              [8.249391, 50.080752],
              [8.253801, 50.081916],
              [8.254884, 50.082205],
              [8.256043, 50.082398],
              [8.258414, 50.08288],
              [8.258446, 50.083135],
              [8.258822, 50.083396],
              [8.259594, 50.084202],
              [8.259991, 50.084773],
              [8.260496, 50.085482],
              [8.260689, 50.086019],
              [8.260764, 50.086508],
              [8.260528, 50.086811],
              [8.260292, 50.087072],
            ],
            [
              [8.24657, 50.08025],
              [8.245754, 50.08058],
              [8.245111, 50.080691],
              [8.243351, 50.080608],
              [8.23627, 50.080167],
              [8.236184, 50.080883],
              [8.23009, 50.08058],
            ],
          ],
        },
        id: "Q5ODg",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "EzMzk",
    name: "15 Kohlheck <> Medenbach",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.194438, 50.083589],
              [8.192625, 50.08522],
              [8.190694, 50.086845],
              [8.19079, 50.087823],
              [8.189224, 50.088112],
              [8.188419, 50.088353],
              [8.186853, 50.088938],
              [8.185973, 50.089475],
              [8.185909, 50.090108],
              [8.185791, 50.090473],
            ],
            [
              [8.260238, 50.087148],
              [8.260131, 50.087396],
              [8.260088, 50.088373],
              [8.260431, 50.088525],
              [8.261311, 50.088552],
              [8.261976, 50.088731],
              [8.262191, 50.088952],
              [8.262491, 50.089585],
              [8.262663, 50.089778],
              [8.263714, 50.09048],
              [8.265731, 50.091774],
              [8.265806, 50.091966],
              [8.265828, 50.092331],
              [8.265731, 50.092868],
              [8.266118, 50.093075],
              [8.267534, 50.094162],
              [8.267941, 50.094513],
              [8.268585, 50.095174],
              [8.268961, 50.095552],
              [8.266085, 50.096681],
              [8.265345, 50.096895],
              [8.264744, 50.097142],
              [8.264465, 50.097218],
              [8.264369, 50.097397],
              [8.26439, 50.097597],
              [8.264476, 50.097796],
              [8.264648, 50.097934],
              [8.265045, 50.098085],
              [8.265785, 50.098388],
              [8.266418, 50.098794],
              [8.266933, 50.09909],
              [8.267115, 50.099338],
              [8.267341, 50.099737],
              [8.267384, 50.100033],
              [8.267276, 50.100274],
              [8.266847, 50.100714],
              [8.2666, 50.101024],
              [8.266193, 50.101506],
              [8.265978, 50.101664],
              [8.265892, 50.101705],
              [8.265517, 50.101815],
              [8.265152, 50.101863],
              [8.264776, 50.101974],
              [8.264326, 50.102077],
              [8.264197, 50.102132],
              [8.264219, 50.102455],
              [8.264122, 50.102675],
              [8.264047, 50.102896],
              [8.264315, 50.103254],
              [8.264487, 50.103391],
              [8.265871, 50.104217],
              [8.266826, 50.104871],
              [8.268285, 50.105407],
              [8.268553, 50.105676],
              [8.268714, 50.106068],
              [8.268746, 50.106323],
              [8.268628, 50.106618],
              [8.268381, 50.107293],
              [8.268188, 50.108029],
              [8.26822, 50.108924],
              [8.268499, 50.109206],
              [8.269154, 50.109619],
              [8.270055, 50.110444],
              [8.270946, 50.11116],
              [8.272018, 50.112095],
              [8.272544, 50.112556],
              [8.272072, 50.113238],
              [8.270237, 50.115652],
              [8.270012, 50.116003],
              [8.269991, 50.116251],
              [8.270034, 50.116395],
              [8.270173, 50.116609],
              [8.270484, 50.116829],
              [8.270774, 50.11687],
              [8.271461, 50.116911],
              [8.272566, 50.116898],
              [8.272834, 50.116905],
              [8.273478, 50.11709],
              [8.273885, 50.11731],
              [8.274175, 50.117476],
              [8.274293, 50.117723],
              [8.274475, 50.118067],
              [8.274786, 50.118349],
              [8.275398, 50.118872],
              [8.27587, 50.11914],
              [8.276042, 50.119381],
              [8.276203, 50.119986],
              [8.276364, 50.120447],
              [8.276621, 50.120922],
              [8.276868, 50.12128],
              [8.277254, 50.121603],
              [8.277683, 50.121823],
              [8.278005, 50.122091],
              [8.27808, 50.122408],
              [8.277909, 50.122683],
              [8.277823, 50.122931],
              [8.277887, 50.123137],
              [8.277941, 50.123398],
              [8.278295, 50.123605],
              [8.278767, 50.123784],
              [8.279389, 50.123907],
              [8.279572, 50.124004],
              [8.279808, 50.124244],
              [8.27984, 50.124513],
              [8.279883, 50.124877],
              [8.280001, 50.125063],
              [8.280194, 50.125263],
              [8.280441, 50.125434],
              [8.280827, 50.125634],
              [8.282436, 50.126253],
              [8.282822, 50.126356],
              [8.283359, 50.126418],
              [8.286921, 50.126845],
              [8.28721, 50.126961],
              [8.287758, 50.127243],
              [8.288498, 50.127635],
              [8.289485, 50.128248],
              [8.290451, 50.128818],
              [8.291159, 50.129169],
              [8.292017, 50.129527],
              [8.293433, 50.130015],
              [8.294045, 50.130173],
              [8.294055, 50.130373],
              [8.293873, 50.130861],
              [8.293487, 50.131425],
              [8.29295, 50.131824],
              [8.292875, 50.132147],
              [8.292886, 50.132395],
              [8.293208, 50.132642],
              [8.293583, 50.132759],
              [8.294281, 50.132801],
              [8.294624, 50.132739],
              [8.295053, 50.132484],
              [8.295279, 50.132388],
              [8.295836, 50.132436],
              [8.296534, 50.132629],
              [8.297124, 50.132711],
              [8.298433, 50.13344],
              [8.299506, 50.134039],
              [8.300761, 50.134809],
              [8.30148, 50.135201],
              [8.302778, 50.134396],
              [8.30399, 50.133633],
              [8.305471, 50.132684],
              [8.306351, 50.132278],
              [8.307531, 50.131948],
              [8.30943, 50.131356],
              [8.310728, 50.131033],
              [8.311361, 50.130786],
              [8.31194, 50.130449],
              [8.312294, 50.13049],
              [8.312573, 50.130442],
              [8.313957, 50.130036],
              [8.315449, 50.129596],
              [8.315974, 50.129142],
              [8.316801, 50.128282],
              [8.317477, 50.127814],
              [8.318528, 50.127085],
              [8.319343, 50.126686],
              [8.320191, 50.126191],
              [8.320599, 50.126026],
              [8.32092, 50.125675],
              [8.321114, 50.125421],
              [8.321382, 50.125283],
              [8.321725, 50.125159],
              [8.32209, 50.125111],
              [8.322476, 50.125146],
              [8.323045, 50.125201],
              [8.323517, 50.125022],
              [8.323764, 50.125159],
              [8.324203, 50.125269],
              [8.324568, 50.125373],
              [8.325427, 50.125579],
              [8.326156, 50.124726],
              [8.326564, 50.124059],
              [8.326693, 50.123687],
              [8.326478, 50.12302],
              [8.327079, 50.12291],
              [8.327444, 50.122635],
              [8.327723, 50.122539],
              [8.329139, 50.122057],
              [8.329589, 50.121782],
              [8.329858, 50.121514],
              [8.330619, 50.121053],
              [8.331102, 50.120778],
              [8.33166, 50.120564],
              [8.332711, 50.120289],
              [8.333441, 50.119986],
              [8.333591, 50.119773],
              [8.333709, 50.11967],
              [8.333902, 50.119285],
              [8.334171, 50.119072],
              [8.334106, 50.118673],
              [8.333505, 50.116705],
              [8.332969, 50.114944],
              [8.33299, 50.114682],
              [8.333623, 50.114029],
              [8.334364, 50.113052],
              [8.33505, 50.112068],
              [8.335158, 50.111772],
              [8.335737, 50.109295],
              [8.336252, 50.109137],
              [8.336391, 50.108855],
              [8.33637, 50.10858],
              [8.336864, 50.108029],
              [8.337336, 50.107589],
              [8.337818, 50.107217],
              [8.338215, 50.106963],
              [8.338355, 50.107203],
              [8.338484, 50.107644],
              [8.339245, 50.10743],
              [8.339825, 50.107375],
              [8.340136, 50.10752],
              [8.340769, 50.107933],
              [8.340919, 50.108091],
              [8.340887, 50.108229],
              [8.33873, 50.108641],
              [8.338559, 50.108724],
              [8.33843, 50.108924],
              [8.338773, 50.110196],
              [8.338763, 50.110451],
              [8.33858, 50.111167],
              [8.338537, 50.111593],
              [8.338569, 50.11255],
              [8.338344, 50.112894],
              [8.337797, 50.113767],
              [8.337797, 50.114125],
              [8.337829, 50.114875],
              [8.337797, 50.115274],
            ],
            [
              [8.33872, 50.110231],
              [8.338076, 50.11019],
              [8.337711, 50.109151],
              [8.337775, 50.108641],
              [8.338054, 50.10827],
              [8.338441, 50.107685],
            ],
            [
              [8.323533, 50.124984],
              [8.32356, 50.124781],
              [8.323731, 50.124499],
              [8.324021, 50.124214],
              [8.324359, 50.124018],
              [8.324633, 50.123815],
              [8.32489, 50.123622],
              [8.32504, 50.123629],
              [8.325185, 50.123653],
              [8.325341, 50.123615],
              [8.325475, 50.123533],
              [8.325641, 50.123388],
              [8.325829, 50.123223],
              [8.325952, 50.12313],
              [8.326129, 50.123072],
              [8.326414, 50.12303],
            ],
            [
              [8.294291, 50.130249],
              [8.295064, 50.130235],
              [8.295343, 50.13007],
              [8.295386, 50.129713],
              [8.294957, 50.129231],
              [8.294785, 50.128887],
              [8.294935, 50.128475],
              [8.296566, 50.127746],
              [8.29603, 50.12725],
              [8.296008, 50.126604],
              [8.296952, 50.126067],
              [8.298604, 50.126095],
              [8.298519, 50.126755],
              [8.296695, 50.127691],
            ],
          ],
        },
        id: "EzMzk",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "I1Mjg",
    name: "16 Bierstadt-Nord <> Carl-Bosch-Str",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.285043, 50.088015],
              [8.284914, 50.088139],
              [8.284035, 50.088518],
              [8.283745, 50.088759],
              [8.283638, 50.089241],
              [8.283488, 50.090129],
              [8.28338, 50.090314],
              [8.283176, 50.090473],
              [8.28294, 50.090555],
              [8.282694, 50.090631],
              [8.282833, 50.090879],
              [8.28293, 50.091196],
              [8.282962, 50.091526],
              [8.282865, 50.091808],
              [8.282651, 50.093026],
              [8.284796, 50.093143],
              [8.284904, 50.092262],
              [8.284936, 50.092145],
              [8.285269, 50.091946],
              [8.285397, 50.09187],
              [8.285226, 50.090824],
              [8.28618, 50.090631],
              [8.286663, 50.090452],
              [8.286899, 50.090335],
              [8.28705, 50.09017],
              [8.287039, 50.089764],
              [8.28705, 50.088903],
              [8.286932, 50.088697],
              [8.285987, 50.088291],
              [8.285354, 50.088064],
              [8.285086, 50.08794],
              [8.285193, 50.087368],
              [8.285258, 50.086818],
              [8.282018, 50.086088],
              [8.281932, 50.085902],
              [8.282018, 50.085599],
              [8.282855, 50.084374],
              [8.283198, 50.083761],
              [8.280698, 50.083444],
              [8.280183, 50.083355],
              [8.276728, 50.08489],
              [8.276546, 50.084966],
              [8.276149, 50.08352],
              [8.275774, 50.081689],
              [8.275409, 50.081014],
              [8.273628, 50.079403],
              [8.272276, 50.078467],
              [8.272104, 50.077957],
              [8.271943, 50.076931],
              [8.272061, 50.076133],
              [8.272673, 50.074335],
              [8.273081, 50.072848],
              [8.273499, 50.072139],
              [8.274068, 50.071457],
              [8.27513, 50.070514],
              [8.275634, 50.06988],
              [8.275741, 50.069563],
              [8.273392, 50.069026],
              [8.273757, 50.068021],
              [8.273928, 50.067773],
              [8.273917, 50.067387],
              [8.273563, 50.066885],
              [8.272941, 50.066148],
              [8.272984, 50.06601],
              [8.275301, 50.065466],
              [8.273971, 50.063799],
              [8.273413, 50.06311],
              [8.272973, 50.062649],
              [8.272372, 50.06227],
              [8.271793, 50.06194],
              [8.267909, 50.060838],
              [8.260925, 50.058985],
              [8.260303, 50.058764],
              [8.259948, 50.058551],
              [8.258307, 50.058158],
              [8.25717, 50.057986],
              [8.256494, 50.05802],
              [8.255668, 50.058076],
              [8.25511, 50.058096],
              [8.254402, 50.058275],
              [8.254058, 50.058351],
              [8.253736, 50.058372],
              [8.253436, 50.058296],
              [8.252127, 50.057945],
              [8.251269, 50.057697],
              [8.2507, 50.057662],
              [8.250078, 50.057814],
              [8.249756, 50.058027],
              [8.249584, 50.058206],
              [8.249466, 50.058647],
              [8.249187, 50.059515],
              [8.248844, 50.060142],
              [8.248168, 50.060962],
              [8.247514, 50.061637],
              [8.246752, 50.062022],
              [8.246527, 50.062112],
              [8.245883, 50.062208],
              [8.245336, 50.062126],
              [8.24451, 50.061919],
              [8.242246, 50.061513],
              [8.241538, 50.061409],
              [8.24054, 50.061265],
              [8.240143, 50.061265],
              [8.239896, 50.061271],
              [8.239628, 50.061409],
              [8.238512, 50.060769],
              [8.236066, 50.059804],
              [8.235744, 50.059763],
              [8.235197, 50.059853],
              [8.235122, 50.058888],
              [8.234832, 50.058144],
              [8.236141, 50.056484],
              [8.237257, 50.0551],
              [8.237997, 50.053977],
              [8.238727, 50.052131],
              [8.23877, 50.051959],
              [8.239456, 50.051903],
              [8.239982, 50.051766],
              [8.240486, 50.051593],
              [8.241001, 50.05138],
              [8.241699, 50.051166],
              [8.241012, 50.049527],
              [8.240744, 50.048783],
              [8.240604, 50.04799],
              [8.238652, 50.04817],
              [8.237568, 50.048183],
              [8.236721, 50.048183],
              [8.234811, 50.048039],
              [8.233684, 50.047839],
              [8.231356, 50.047412],
              [8.230315, 50.047288],
              [8.230036, 50.047935],
              [8.229908, 50.048356],
              [8.229908, 50.048438],
              [8.227944, 50.04861],
              [8.225123, 50.048796],
              [8.221904, 50.049017],
              [8.219941, 50.048893],
              [8.220091, 50.047667],
              [8.220048, 50.046991],
              [8.220091, 50.046716],
              [8.220316, 50.045744],
              [8.220338, 50.045503],
              [8.220305, 50.045262],
              [8.220305, 50.044732],
              [8.220134, 50.044511],
              [8.220005, 50.044353],
              [8.219715, 50.044332],
              [8.2186, 50.044125],
              [8.218482, 50.043877],
              [8.218106, 50.042954],
              [8.221282, 50.042837],
              [8.221689, 50.043781],
              [8.220155, 50.044105],
              [8.219973, 50.044284],
            ],
            [
              [8.280247, 50.083338],
              [8.281996, 50.08258],
              [8.282093, 50.082783],
              [8.282066, 50.082956],
              [8.281594, 50.084219],
              [8.2811, 50.085565],
              [8.281916, 50.085981],
            ],
          ],
        },
        id: "I1Mjg",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "I1MDc",
    name: "17 + 27",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.235798, 50.083231],
              [8.235798, 50.082763],
              [8.235916, 50.081654],
              [8.236077, 50.08058],
              [8.236281, 50.079107],
              [8.236356, 50.078597],
              [8.23656, 50.07795],
              [8.236742, 50.077455],
              [8.236871, 50.076511],
              [8.237193, 50.073785],
              [8.237375, 50.072407],
              [8.237386, 50.071918],
              [8.237364, 50.071567],
              [8.237557, 50.071505],
              [8.238469, 50.071395],
              [8.239489, 50.071326],
              [8.2401, 50.071313],
              [8.24069, 50.071251],
              [8.241409, 50.071264],
              [8.243297, 50.071409],
              [8.244885, 50.071512],
              [8.246419, 50.071595],
              [8.248018, 50.071671],
              [8.248394, 50.071788],
              [8.249949, 50.071836],
              [8.252031, 50.07198],
              [8.253468, 50.072077],
              [8.255582, 50.072173],
              [8.256912, 50.072132],
              [8.257288, 50.07207],
              [8.257931, 50.07165],
              [8.260506, 50.069646],
              [8.261397, 50.069012],
              [8.262813, 50.068165],
              [8.263414, 50.067766],
              [8.263854, 50.067504],
              [8.264004, 50.066981],
              [8.264433, 50.066272],
              [8.266268, 50.063331],
              [8.26748, 50.06132],
              [8.267652, 50.060817],
              [8.267738, 50.060638],
            ],
            [
              [8.236721, 50.077661],
              [8.238373, 50.077709],
              [8.239102, 50.071423],
            ],
          ],
        },
        id: "I1MDc",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "k4MTY",
    name: "17 Nerotal <> Schierstein",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.267727, 50.060603],
              [8.261021, 50.058868],
              [8.260592, 50.058695],
              [8.260174, 50.058565],
              [8.259906, 50.058468],
              [8.258146, 50.058069],
              [8.257685, 50.057972],
              [8.257084, 50.057958],
              [8.25658, 50.057965],
              [8.255807, 50.05802],
              [8.254895, 50.058048],
              [8.255335, 50.057063],
              [8.255893, 50.056243],
              [8.256558, 50.055293],
              [8.256955, 50.054804],
              [8.257138, 50.054191],
              [8.25688, 50.053164],
              [8.256462, 50.052289],
              [8.256311, 50.051621],
              [8.256258, 50.050657],
              [8.256247, 50.04943],
              [8.256032, 50.048018],
              [8.255646, 50.046702],
              [8.255367, 50.044897],
              [8.253833, 50.044835],
              [8.253458, 50.044849],
              [8.252739, 50.044711],
              [8.252181, 50.044394],
              [8.25173, 50.044125],
              [8.251333, 50.043602],
              [8.249788, 50.044456],
              [8.248082, 50.04529],
              [8.244649, 50.046916],
              [8.243973, 50.047177],
              [8.24245, 50.047605],
              [8.241366, 50.047825],
              [8.240486, 50.048052],
              [8.239371, 50.04817],
              [8.237643, 50.048218],
              [8.236635, 50.048245],
              [8.234779, 50.048073],
              [8.231217, 50.047432],
              [8.23127, 50.047136],
              [8.231281, 50.046688],
              [8.23112, 50.045986],
              [8.230573, 50.045241],
              [8.229082, 50.043505],
              [8.228148, 50.042299],
              [8.227762, 50.041783],
              [8.227065, 50.040081],
              [8.226507, 50.039206],
              [8.22582, 50.039302],
              [8.223567, 50.039839],
              [8.219726, 50.040687],
              [8.216572, 50.041376],
              [8.215638, 50.041569],
              [8.209791, 50.042885],
              [8.207581, 50.043354],
              [8.203322, 50.044311],
              [8.201809, 50.044628],
              [8.201509, 50.04467],
              [8.199567, 50.044635],
              [8.199095, 50.044676],
              [8.199277, 50.045],
              [8.199492, 50.045221],
              [8.200017, 50.045882],
              [8.195028, 50.045503],
              [8.194846, 50.045524],
              [8.194395, 50.045607],
              [8.193934, 50.045538],
              [8.190565, 50.044966],
              [8.189664, 50.046213],
              [8.189342, 50.046571],
              [8.189224, 50.046654],
              [8.188677, 50.04673],
              [8.186531, 50.04684],
              [8.186263, 50.046805],
              [8.186038, 50.046661],
              [8.186102, 50.046364],
              [8.186359, 50.045848],
              [8.186853, 50.045407],
              [8.187207, 50.045317],
              [8.187754, 50.045248],
              [8.188312, 50.045228],
              [8.189256, 50.045269],
              [8.190168, 50.045427],
            ],
            [
              [8.194427, 50.045558],
              [8.19461, 50.045235],
              [8.194824, 50.045028],
              [8.19535, 50.044821],
              [8.195865, 50.044725],
              [8.196101, 50.044546],
              [8.196423, 50.04447],
              [8.196938, 50.044456],
              [8.198966, 50.044428],
              [8.19903, 50.044621],
            ],
          ],
        },
        id: "k4MTY",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "AwNzU",
    name: "18 + 28",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.226035, 50.069811],
              [8.227054, 50.070707],
              [8.228073, 50.071581],
              [8.230112, 50.073406],
              [8.231088, 50.074211],
              [8.231699, 50.074521],
              [8.23244, 50.075237],
              [8.233041, 50.075706],
              [8.233577, 50.075761],
              [8.234478, 50.075802],
              [8.234993, 50.075878],
              [8.235219, 50.075988],
              [8.235379, 50.076208],
              [8.235261, 50.077393],
              [8.235497, 50.07753],
              [8.236624, 50.077585],
              [8.236688, 50.077207],
              [8.236914, 50.075492],
              [8.237182, 50.073241],
              [8.2373, 50.07196],
              [8.237278, 50.071547],
              [8.237482, 50.071464],
              [8.238105, 50.071374],
              [8.239059, 50.071299],
              [8.240068, 50.071251],
              [8.24069, 50.071195],
              [8.241323, 50.071195],
              [8.242729, 50.071326],
              [8.245207, 50.071478],
              [8.247964, 50.071609],
              [8.248436, 50.071712],
              [8.25069, 50.071836],
              [8.252771, 50.07198],
              [8.255421, 50.072118],
              [8.256526, 50.072318],
              [8.257213, 50.072545],
              [8.257663, 50.07271],
              [8.258479, 50.073199],
              [8.258833, 50.073475],
              [8.258715, 50.073936],
              [8.258114, 50.075382],
              [8.257964, 50.075823],
              [8.257899, 50.076422],
              [8.257985, 50.07689],
              [8.258264, 50.077909],
              [8.258382, 50.078618],
              [8.258457, 50.079561],
              [8.258532, 50.081324],
              [8.258618, 50.081985],
              [8.258672, 50.082584],
              [8.258522, 50.08299],
              [8.258736, 50.083231],
              [8.259079, 50.083548],
              [8.259487, 50.083995],
              [8.259691, 50.08425],
              [8.260238, 50.085028],
              [8.260667, 50.085654],
              [8.260807, 50.086122],
              [8.26086, 50.086453],
              [8.260828, 50.086604],
              [8.260335, 50.087107],
            ],
            [
              [8.235315, 50.077503],
              [8.23274, 50.077393],
              [8.232708, 50.077262],
              [8.232837, 50.075685],
            ],
            [
              [8.236763, 50.077606],
              [8.238341, 50.077675],
              [8.239049, 50.071347],
            ],
          ],
        },
        id: "AwNzU",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "IwNTI",
    name: "18 Friedhof Sonnenberg <> Frauenstein",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.260324, 50.087127],
              [8.260227, 50.08732],
              [8.260185, 50.087547],
              [8.260206, 50.088318],
              [8.260431, 50.088477],
              [8.260989, 50.088504],
              [8.261461, 50.088545],
              [8.262041, 50.088697],
              [8.262309, 50.088903],
              [8.262588, 50.089523],
              [8.262663, 50.089688],
              [8.263006, 50.089936],
              [8.264873, 50.09114],
              [8.265785, 50.091712],
              [8.26586, 50.091836],
              [8.265914, 50.092256],
              [8.265871, 50.0926],
              [8.265796, 50.092834],
              [8.266675, 50.093419],
              [8.267555, 50.094107],
              [8.268285, 50.09474],
              [8.269025, 50.095539],
              [8.268993, 50.095635],
              [8.265967, 50.096784],
              [8.266525, 50.097018],
              [8.268253, 50.097638],
              [8.268864, 50.097879],
            ],
            [
              [8.194728, 50.07229],
              [8.195833, 50.072993],
              [8.198118, 50.074501],
              [8.198043, 50.074996],
              [8.197979, 50.075251],
            ],
          ],
        },
        id: "IwNTI",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "g2MDc",
    name: "18 Friedhof Sonnenberg <> Frauenstein",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.225949, 50.069811],
              [8.224382, 50.070789],
              [8.22273, 50.071781],
              [8.220466, 50.073117],
              [8.21861, 50.072401],
              [8.217194, 50.071857],
              [8.216947, 50.071671],
              [8.216658, 50.071189],
              [8.216604, 50.070796],
              [8.215241, 50.070879],
              [8.21463, 50.071037],
              [8.214126, 50.070982],
              [8.213933, 50.070741],
              [8.21375, 50.070899],
              [8.213149, 50.071195],
              [8.212377, 50.071664],
              [8.212076, 50.071974],
              [8.211797, 50.072731],
              [8.211733, 50.072917],
              [8.210574, 50.073874],
              [8.209866, 50.074404],
              [8.207914, 50.076064],
              [8.207313, 50.076243],
              [8.202646, 50.075816],
              [8.202378, 50.075761],
              [8.197861, 50.075237],
              [8.19653, 50.075107],
              [8.19609, 50.07501],
              [8.195833, 50.074893],
              [8.195651, 50.074645],
              [8.195425, 50.074349],
              [8.194191, 50.07311],
              [8.194149, 50.072993],
              [8.194374, 50.072483],
              [8.194599, 50.072311],
              [8.195146, 50.071884],
              [8.194889, 50.071602],
              [8.194685, 50.071133],
              [8.194578, 50.070741],
              [8.19461, 50.070355],
              [8.194878, 50.069295],
              [8.194631, 50.068709],
              [8.194535, 50.068592],
              [8.194288, 50.068482],
              [8.193022, 50.068],
              [8.192625, 50.067911],
              [8.191531, 50.067752],
              [8.189728, 50.067594],
              [8.189267, 50.067449],
              [8.187325, 50.067318],
              [8.185405, 50.067181],
              [8.184643, 50.067215],
              [8.182905, 50.067435],
              [8.181682, 50.067663],
              [8.180748, 50.068055],
              [8.180115, 50.068765],
              [8.178828, 50.069935],
              [8.178463, 50.070114],
              [8.178087, 50.070218],
              [8.177626, 50.070252],
              [8.173807, 50.069949],
              [8.173581, 50.069915],
              [8.17239, 50.069357],
              [8.171285, 50.068634],
              [8.169612, 50.067656],
              [8.168904, 50.06738],
              [8.167788, 50.067091],
              [8.164558, 50.066513],
              [8.16237, 50.066843],
              [8.162037, 50.06685],
              [8.160975, 50.066823],
              [8.159398, 50.066554],
              [8.157928, 50.066471],
              [8.157456, 50.06643],
              [8.15575, 50.066113],
              [8.155224, 50.065934],
              [8.154162, 50.0657],
              [8.153937, 50.065487],
              [8.153754, 50.065349],
              [8.15384, 50.06477],
              [8.154055, 50.06455],
              [8.154087, 50.064295],
              [8.153958, 50.063909],
              [8.153926, 50.063469],
              [8.153915, 50.062911],
              [8.154323, 50.06216],
              [8.154516, 50.061871],
              [8.155074, 50.061444],
              [8.156179, 50.060769],
              [8.15737, 50.059997],
            ],
            [
              [8.1896, 50.067522],
              [8.189787, 50.06644],
              [8.189927, 50.065535],
              [8.190265, 50.064168],
              [8.19034, 50.063885],
              [8.190469, 50.063661],
              [8.190705, 50.063589],
              [8.191059, 50.063572],
              [8.19329, 50.063909],
              [8.193355, 50.064026],
              [8.192974, 50.066027],
              [8.192888, 50.066447],
              [8.192813, 50.06652],
              [8.192684, 50.06652],
              [8.192368, 50.066499],
              [8.191863, 50.066458],
              [8.191799, 50.066502],
              [8.191563, 50.067738],
            ],
          ],
        },
        id: "g2MDc",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "M4ODc",
    name: "19 Waldfriedhof <> Mz-Weisenau",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.19292, 50.0678],
              [8.192759, 50.068],
              [8.192427, 50.068214],
              [8.192003, 50.068403],
              [8.191777, 50.068448],
              [8.191246, 50.068486],
              [8.189825, 50.068558],
              [8.188908, 50.068603],
              [8.188248, 50.06863],
              [8.187781, 50.068689],
              [8.187438, 50.068827],
              [8.185485, 50.069952],
              [8.184299, 50.07061],
              [8.183795, 50.070906],
              [8.183581, 50.071109],
              [8.1835, 50.071223],
              [8.183634, 50.071278],
              [8.184208, 50.071436],
              [8.186461, 50.072204],
              [8.186644, 50.072332],
              [8.186746, 50.072514],
              [8.186762, 50.072635],
              [8.186386, 50.073148],
              [8.186166, 50.073427],
              [8.185995, 50.073547],
              [8.185823, 50.073602],
              [8.185624, 50.073637],
              [8.185383, 50.073647],
              [8.184814, 50.07354],
              [8.183436, 50.073072],
              [8.183071, 50.072927],
              [8.18284, 50.072831],
              [8.18269, 50.072817],
              [8.182594, 50.072938],
              [8.18239, 50.073137],
              [8.182213, 50.073254],
              [8.181843, 50.073461],
              [8.181585, 50.07355],
              [8.181655, 50.073778],
              [8.181966, 50.07417],
              [8.182363, 50.074597],
              [8.182653, 50.074745],
            ],
            [
              [8.182948, 50.072838],
              [8.183033, 50.07269],
              [8.182964, 50.072418],
              [8.182905, 50.072163],
              [8.183044, 50.071812],
              [8.183296, 50.071316],
              [8.183436, 50.071233],
            ],
          ],
        },
        id: "M4ODc",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "EyNDU",
    name: "20 + 30 + 230",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.307413, 50.139863],
              [8.305793, 50.139808],
              [8.305299, 50.139671],
              [8.304838, 50.13934],
              [8.304763, 50.138859],
              [8.304677, 50.138247],
              [8.30457, 50.137786],
              [8.304055, 50.137305],
              [8.30192, 50.135558],
              [8.301566, 50.135249],
              [8.301308, 50.135194],
              [8.297542, 50.133014],
              [8.297167, 50.132801],
              [8.296877, 50.132739],
              [8.296319, 50.132649],
              [8.295568, 50.132464],
              [8.295246, 50.132505],
              [8.294957, 50.132629],
              [8.294603, 50.132801],
              [8.294377, 50.132849],
              [8.294034, 50.132835],
              [8.293197, 50.132759],
              [8.292972, 50.132581],
              [8.292768, 50.132223],
              [8.292897, 50.131707],
              [8.293283, 50.13148],
              [8.293755, 50.130916],
              [8.293841, 50.130662],
              [8.293937, 50.130201],
              [8.292929, 50.129891],
              [8.29309, 50.129376],
              [8.29338, 50.128378],
              [8.293422, 50.127594],
              [8.293101, 50.126611],
              [8.292285, 50.125517],
              [8.291384, 50.124313],
              [8.290654, 50.123254],
              [8.290225, 50.122484],
              [8.289828, 50.121307],
              [8.289067, 50.119464],
              [8.288605, 50.118418],
              [8.288004, 50.117421],
              [8.286921, 50.116065],
              [8.28558, 50.114504],
              [8.284453, 50.113458],
              [8.283638, 50.112384],
              [8.282275, 50.110953],
              [8.281288, 50.109667],
              [8.280194, 50.108917],
              [8.279475, 50.108153],
              [8.279099, 50.107561],
              [8.278424, 50.106391],
              [8.278381, 50.105552],
              [8.278284, 50.104079],
              [8.278509, 50.102545],
              [8.278584, 50.101609],
              [8.278574, 50.100583],
              [8.278445, 50.098044],
              [8.278348, 50.095676],
              [8.278284, 50.095263],
              [8.278552, 50.094596],
              [8.279132, 50.093316],
              [8.279561, 50.092503],
              [8.27985, 50.091705],
              [8.279872, 50.091154],
              [8.2797, 50.090741],
              [8.280065, 50.090748],
              [8.280451, 50.089702],
              [8.281878, 50.086115],
              [8.281889, 50.085689],
              [8.282393, 50.08489],
              [8.283091, 50.083782],
              [8.280644, 50.083486],
              [8.276696, 50.085248],
              [8.276428, 50.084876],
              [8.276192, 50.084057],
              [8.275795, 50.082515],
              [8.275559, 50.08151],
              [8.275205, 50.080904],
              [8.274218, 50.080016],
              [8.272115, 50.078494],
              [8.271203, 50.077799],
              [8.267705, 50.076332],
              [8.266289, 50.075775],
              [8.265152, 50.075396],
              [8.263865, 50.075217],
              [8.262362, 50.074983],
              [8.26129, 50.0747],
              [8.260463, 50.074439],
              [8.259004, 50.073626],
              [8.258607, 50.073502],
              [8.257492, 50.072827],
              [8.256934, 50.072642],
            ],
            [
              [8.280752, 50.083437],
              [8.281975, 50.082852],
              [8.280966, 50.085572],
              [8.281052, 50.085661],
              [8.281771, 50.086067],
              [8.28176, 50.086102],
            ],
          ],
        },
        id: "EyNDU",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "k2NTA",
    name: "20 + 30 + 230",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.229811, 50.078322],
              [8.229554, 50.080298],
              [8.229516, 50.080622],
              [8.229414, 50.08088],
              [8.229344, 50.081758],
              [8.229302, 50.08235],
              [8.229135, 50.082832],
              [8.228583, 50.083234],
              [8.227912, 50.083496],
              [8.227032, 50.083974],
              [8.225911, 50.084787],
              [8.225396, 50.085241],
              [8.225235, 50.085541],
              [8.224699, 50.08593],
              [8.224635, 50.086229],
              [8.223428, 50.087041],
              [8.221824, 50.088002],
              [8.220794, 50.088693],
              [8.220574, 50.088965],
              [8.220499, 50.089192],
              [8.22059, 50.089499],
              [8.220815, 50.089671],
              [8.221212, 50.089908],
              [8.2214, 50.090053],
              [8.221518, 50.090352],
              [8.221496, 50.090528],
              [8.221373, 50.090755],
              [8.220949, 50.091003],
              [8.220509, 50.091443],
              [8.219967, 50.092238],
              [8.218975, 50.093439],
              [8.218519, 50.09379],
              [8.21765, 50.094217],
              [8.217387, 50.094334],
              [8.21714, 50.094376],
              [8.21692, 50.094382],
              [8.216636, 50.09441],
              [8.2164, 50.094482],
              [8.216304, 50.094627],
              [8.216363, 50.09474],
              [8.216524, 50.094761],
              [8.216904, 50.094716],
              [8.217157, 50.09473],
              [8.217559, 50.094757],
              [8.217731, 50.094847],
              [8.218675, 50.09557],
              [8.219356, 50.096268],
              [8.219871, 50.096592],
              [8.220059, 50.096771],
              [8.220209, 50.096908],
            ],
            [
              [8.2214, 50.089963],
              [8.224061, 50.087678],
              [8.224983, 50.0869],
              [8.22597, 50.086219],
              [8.226807, 50.085682],
              [8.22677, 50.085599],
              [8.226566, 50.085561],
              [8.226394, 50.08553],
              [8.226131, 50.08553],
              [8.225638, 50.085534],
              [8.225321, 50.085482],
            ],
            [
              [8.226829, 50.085616],
              [8.227188, 50.085523],
              [8.227569, 50.085344],
              [8.228787, 50.08477],
              [8.229318, 50.084494],
              [8.229221, 50.084363],
              [8.229135, 50.084188],
              [8.229184, 50.08342],
              [8.229114, 50.082894],
            ],
          ],
        },
        id: "k2NTA",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "UwNDM",
    name: "21 Bahnholz <> Kohlheck",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.242364, 50.087355],
              [8.243845, 50.088277],
              [8.243952, 50.088773],
              [8.244102, 50.089254],
              [8.244445, 50.089929],
              [8.245153, 50.089998],
              [8.245153, 50.090204],
              [8.244917, 50.090617],
              [8.244853, 50.091361],
              [8.244874, 50.092077],
              [8.245497, 50.093082],
              [8.246527, 50.095022],
              [8.246934, 50.09622],
              [8.247385, 50.097693],
              [8.247514, 50.098257],
              [8.246849, 50.099331],
              [8.244724, 50.102786],
              [8.243051, 50.10613],
            ],
            [
              [8.22597, 50.069784],
              [8.22258, 50.071781],
              [8.220434, 50.073062],
              [8.21861, 50.072345],
              [8.217258, 50.071808],
              [8.216958, 50.071505],
              [8.216679, 50.071065],
              [8.216701, 50.070762],
              [8.215628, 50.070817],
              [8.21522, 50.070844],
              [8.214726, 50.070968],
              [8.214469, 50.070996],
              [8.213868, 50.070624],
              [8.213739, 50.070803],
              [8.212388, 50.07156],
              [8.211958, 50.071905],
              [8.211615, 50.072896],
              [8.211014, 50.073475],
              [8.209491, 50.07459],
              [8.207946, 50.075967],
              [8.207667, 50.07616],
              [8.206186, 50.076009],
              [8.202581, 50.075692],
              [8.202066, 50.076656],
              [8.201444, 50.077551],
              [8.200543, 50.079052],
              [8.200221, 50.079823],
              [8.200135, 50.080333],
              [8.199191, 50.080814],
              [8.19726, 50.081709],
              [8.195436, 50.082549],
              [8.194878, 50.083114],
              [8.191895, 50.085785],
              [8.190608, 50.086845],
              [8.190715, 50.087795],
              [8.189342, 50.088043],
              [8.18872, 50.088181],
              [8.187711, 50.088552],
              [8.186789, 50.088896],
              [8.185866, 50.089475],
              [8.185866, 50.089805],
              [8.185737, 50.090466],
            ],
            [
              [8.244799, 50.091017],
              [8.244606, 50.09103],
              [8.244499, 50.090968],
              [8.243909, 50.09103],
              [8.242879, 50.091127],
              [8.242825, 50.091089],
              [8.242922, 50.090858],
              [8.243147, 50.090566],
              [8.243324, 50.090387],
              [8.243501, 50.090228],
              [8.243721, 50.090125],
              [8.244359, 50.089926],
            ],
          ],
        },
        id: "UwNDM",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "Q4NTE",
    name: "22 + 32",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.236055, 50.059715],
              [8.239617, 50.057497],
              [8.240733, 50.056725],
              [8.241377, 50.056725],
              [8.242342, 50.056739],
              [8.243136, 50.056601],
              [8.243501, 50.056498],
              [8.243608, 50.05605],
              [8.243791, 50.055251],
              [8.24363, 50.054714],
              [8.243093, 50.053419],
              [8.241431, 50.049155],
              [8.241227, 50.048714],
              [8.241162, 50.048397],
              [8.241173, 50.048108],
              [8.241152, 50.047798],
              [8.24231, 50.047584],
              [8.244038, 50.047115],
            ],
            [
              [8.30663, 50.094554],
              [8.307295, 50.094967],
              [8.307595, 50.095312],
              [8.307552, 50.095656],
              [8.307552, 50.096082],
              [8.307896, 50.096826],
              [8.308496, 50.097954],
              [8.308797, 50.099813],
              [8.308754, 50.100143],
              [8.309634, 50.101189],
              [8.3109, 50.102524],
              [8.311758, 50.103226],
              [8.311973, 50.103611],
              [8.312488, 50.103777],
              [8.313453, 50.103832],
              [8.313861, 50.104052],
              [8.314526, 50.104052],
              [8.315492, 50.104148],
              [8.315749, 50.10441],
              [8.315706, 50.104754],
              [8.316028, 50.105043],
              [8.316264, 50.105304],
              [8.316522, 50.106157],
              [8.317144, 50.106694],
              [8.31841, 50.109419],
              [8.318646, 50.109887],
              [8.319225, 50.110217],
              [8.320148, 50.110657],
              [8.321457, 50.110905],
              [8.322744, 50.111304],
              [8.323581, 50.111772],
              [8.323882, 50.112047],
              [8.324375, 50.112309],
              [8.324997, 50.112625],
              [8.325706, 50.113011],
              [8.326135, 50.113203],
              [8.326628, 50.113822],
              [8.327186, 50.114428],
              [8.327186, 50.114937],
              [8.326972, 50.115515],
              [8.326671, 50.115818],
              [8.326328, 50.11623],
              [8.326049, 50.116739],
              [8.326006, 50.117028],
              [8.325963, 50.11762],
              [8.326199, 50.118418],
              [8.326221, 50.118886],
              [8.326156, 50.11934],
              [8.325727, 50.119931],
              [8.325856, 50.120145],
              [8.325748, 50.120578],
              [8.325341, 50.122318],
              [8.325325, 50.122587],
              [8.325233, 50.12269],
              [8.32504, 50.12313],
              [8.324933, 50.123467],
              [8.324928, 50.123584],
              [8.32518, 50.123632],
              [8.325394, 50.123543],
              [8.325797, 50.123213],
              [8.325952, 50.123089],
              [8.326328, 50.123024],
              [8.326939, 50.122879],
              [8.327293, 50.122714],
              [8.327411, 50.122587],
              [8.328077, 50.122391],
              [8.328919, 50.122064],
              [8.329391, 50.121861],
              [8.329589, 50.121644],
              [8.329879, 50.121462],
              [8.330297, 50.121152],
              [8.330727, 50.120939],
              [8.331381, 50.120602],
              [8.331698, 50.120485],
              [8.332288, 50.120337],
              [8.332738, 50.120196],
              [8.333232, 50.120014],
              [8.333532, 50.119804],
              [8.333559, 50.119612],
              [8.333602, 50.119532],
              [8.333774, 50.119354],
              [8.333827, 50.119216],
              [8.333945, 50.119085],
              [8.334138, 50.119017],
            ],
          ],
        },
        id: "Q4NTE",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "UyNjk",
    name: "22 Eppstein <> Friedhof Biebrich/Bf WI-Ost",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.244145, 50.047067],
              [8.246151, 50.046158],
              [8.247889, 50.045283],
              [8.24967, 50.044477],
              [8.251258, 50.043547],
              [8.251644, 50.043223],
              [8.253458, 50.041969],
              [8.255324, 50.040659],
            ],
            [
              [8.244123, 50.047115],
              [8.244306, 50.04737],
              [8.244832, 50.047432],
              [8.245668, 50.047584],
              [8.246462, 50.04766],
              [8.247246, 50.047584],
              [8.247685, 50.047536],
              [8.247793, 50.047412],
              [8.247879, 50.047329],
              [8.248265, 50.047226],
              [8.248683, 50.047143],
              [8.249037, 50.047143],
              [8.249305, 50.047088],
              [8.249381, 50.046964],
              [8.249359, 50.04664],
              [8.249295, 50.046592],
              [8.248565, 50.046544],
              [8.247943, 50.046592],
              [8.247449, 50.046681],
              [8.247052, 50.046847],
              [8.246355, 50.047129],
              [8.245926, 50.047308],
              [8.245443, 50.047481],
            ],
            [
              [8.334203, 50.119078],
              [8.334482, 50.119436],
              [8.334739, 50.119821],
              [8.334954, 50.120509],
              [8.335404, 50.12205],
              [8.33445, 50.122105],
              [8.334589, 50.122938],
              [8.334761, 50.123901],
              [8.334557, 50.125696],
              [8.335233, 50.125792],
              [8.33519, 50.126528],
              [8.334879, 50.130118],
              [8.334868, 50.130517],
              [8.334932, 50.130737],
              [8.335308, 50.130951],
              [8.336992, 50.131845],
              [8.338033, 50.132415],
              [8.338194, 50.132801],
              [8.338355, 50.133516],
              [8.338376, 50.135524],
              [8.338473, 50.139004],
              [8.338473, 50.139169],
              [8.339256, 50.139052],
              [8.340007, 50.139045],
              [8.341155, 50.139203],
              [8.342003, 50.139327],
              [8.342164, 50.139237],
              [8.342764, 50.139162],
              [8.343505, 50.139169],
              [8.346519, 50.139217],
              [8.346562, 50.139334],
              [8.346401, 50.139719],
              [8.346348, 50.140138],
              [8.346552, 50.140571],
              [8.346884, 50.140798],
              [8.347893, 50.141396],
              [8.348097, 50.141623],
              [8.348032, 50.142091],
              [8.348, 50.142552],
              [8.347968, 50.142724],
              [8.348118, 50.143019],
              [8.348311, 50.143225],
              [8.34859, 50.143363],
              [8.349491, 50.14359],
              [8.35021, 50.143734],
              [8.35079, 50.143769],
              [8.351197, 50.143755],
              [8.351616, 50.143583],
              [8.35198, 50.143466],
              [8.352624, 50.143143],
              [8.352764, 50.142847],
              [8.352989, 50.14249],
              [8.353901, 50.14095],
              [8.354008, 50.140633],
              [8.353933, 50.140303],
              [8.353515, 50.139925],
              [8.353429, 50.13976],
              [8.353504, 50.139691],
              [8.352281, 50.139375],
              [8.352506, 50.138949],
              [8.353246, 50.137855],
              [8.353611, 50.137326],
              [8.354502, 50.136473],
              [8.355027, 50.135978],
              [8.355317, 50.135613],
              [8.355553, 50.135104],
              [8.355703, 50.134829],
              [8.356615, 50.134953],
              [8.35727, 50.135008],
              [8.358214, 50.135263],
              [8.361304, 50.136246],
              [8.362023, 50.136555],
              [8.36242, 50.136755],
              [8.362699, 50.137044],
              [8.363074, 50.137621],
              [8.363332, 50.138034],
              [8.363825, 50.138495],
              [8.364673, 50.139265],
              [8.364855, 50.139375],
              [8.365188, 50.139354],
              [8.365349, 50.139189],
              [8.365306, 50.13899],
              [8.365316, 50.138838],
              [8.365574, 50.138811],
              [8.365746, 50.138914],
              [8.365939, 50.139059],
              [8.36596, 50.13934],
              [8.365992, 50.13943],
              [8.365735, 50.139767],
              [8.364308, 50.141046],
              [8.363889, 50.141135],
              [8.363546, 50.141183],
              [8.362967, 50.14095],
              [8.362151, 50.140626],
              [8.361948, 50.140647],
              [8.361626, 50.140558],
              [8.360714, 50.140468],
              [8.360274, 50.140434],
              [8.359394, 50.140338],
              [8.358847, 50.140289],
              [8.358064, 50.140303],
              [8.357302, 50.140372],
              [8.357173, 50.140681],
              [8.356884, 50.141018],
              [8.356411, 50.141307],
              [8.356014, 50.141843],
              [8.355875, 50.142153],
              [8.355918, 50.142476],
              [8.356047, 50.142799],
              [8.355993, 50.143122],
              [8.355725, 50.143356],
              [8.353976, 50.144518],
              [8.353461, 50.145061],
              [8.353236, 50.145501],
              [8.353086, 50.146175],
              [8.353128, 50.146615],
              [8.352925, 50.147124],
              [8.350629, 50.149888],
              [8.350146, 50.15041],
              [8.34962, 50.150857],
              [8.348794, 50.151379],
              [8.348773, 50.151483],
              [8.348762, 50.151737],
              [8.348933, 50.152081],
              [8.349427, 50.152589],
              [8.349921, 50.152892],
              [8.350398, 50.153081],
              [8.350586, 50.153236],
              [8.350714, 50.153352],
              [8.350875, 50.15361],
              [8.351374, 50.153596],
              [8.351664, 50.153528],
              [8.351964, 50.153318],
              [8.352308, 50.15317],
              [8.352651, 50.153029],
              [8.352968, 50.152919],
              [8.353252, 50.152806],
              [8.353536, 50.152768],
              [8.353735, 50.152789],
              [8.354024, 50.152857],
              [8.353847, 50.153098],
              [8.35352, 50.153483],
              [8.353413, 50.153844],
              [8.353327, 50.154143],
              [8.353268, 50.154624],
              [8.353166, 50.155343],
              [8.352817, 50.156161],
              [8.352383, 50.157078],
              [8.352039, 50.157559],
              [8.351744, 50.157896],
              [8.351503, 50.157999],
              [8.351278, 50.158051],
              [8.350961, 50.158054],
              [8.350554, 50.157972],
              [8.350414, 50.157824],
              [8.350269, 50.157525],
              [8.350216, 50.157315],
              [8.35035, 50.156989],
              [8.350521, 50.156817],
              [8.3508, 50.156528],
              [8.350988, 50.156202],
              [8.351063, 50.155999],
              [8.351036, 50.155724],
              [8.350945, 50.155425],
              [8.350747, 50.155263],
              [8.350457, 50.155181],
              [8.350205, 50.155116],
              [8.350672, 50.154476],
              [8.350849, 50.154167],
              [8.350908, 50.15393],
              [8.350897, 50.153741],
              [8.350886, 50.153641],
            ],
          ],
        },
        id: "UyNjk",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "QxMzA",
    name: "23 Eigenheim <> Mz-Mühldreieck",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.23509, 50.059825],
              [8.235047, 50.059012],
              [8.23494, 50.058695],
              [8.234768, 50.058117],
              [8.235304, 50.057387],
              [8.237064, 50.055238],
              [8.237708, 50.054273],
              [8.238212, 50.053295],
              [8.238673, 50.052076],
              [8.239403, 50.052027],
              [8.240433, 50.051793],
              [8.241345, 50.051401],
              [8.241892, 50.051235],
              [8.24113, 50.049417],
              [8.240905, 50.048686],
              [8.240755, 50.048197],
              [8.240744, 50.04797],
              [8.24068, 50.047798],
              [8.240744, 50.046633],
              [8.24083, 50.04489],
              [8.240422, 50.04387],
              [8.238748, 50.04028],
              [8.238491, 50.04006],
              [8.236624, 50.036539],
              [8.235369, 50.036925],
              [8.231292, 50.037655],
              [8.230305, 50.037876],
              [8.22876, 50.038544],
              [8.227805, 50.038827],
              [8.226496, 50.03915],
              [8.225842, 50.039233],
              [8.221411, 50.040267],
              [8.216389, 50.041355],
              [8.216937, 50.042878],
              [8.221411, 50.042685],
              [8.222022, 50.043808],
              [8.221947, 50.043877],
              [8.220166, 50.044311],
              [8.220413, 50.044704],
              [8.220477, 50.0452],
              [8.220434, 50.045414],
              [8.212935, 50.044098],
              [8.212645, 50.041321],
              [8.212023, 50.035188],
              [8.211465, 50.030474],
              [8.211465, 50.029771],
              [8.214705, 50.029523],
              [8.217173, 50.029399],
              [8.218997, 50.028972],
              [8.220413, 50.028434],
              [8.220756, 50.027993],
              [8.220391, 50.027193],
              [8.220048, 50.026104],
              [8.221121, 50.025774],
              [8.221164, 50.024753],
              [8.220992, 50.023802],
              [8.22037, 50.022837],
              [8.220284, 50.022424],
              [8.220541, 50.021596],
              [8.22082, 50.020301],
              [8.220778, 50.018949],
              [8.219812, 50.017226],
              [8.218074, 50.013407],
              [8.217258, 50.011697],
              [8.216314, 50.010028],
              [8.215735, 50.009352],
              [8.215595, 50.009028],
              [8.215467, 50.008222],
              [8.215563, 50.007773],
              [8.215982, 50.007167],
              [8.216314, 50.006656],
              [8.216454, 50.005912],
              [8.217376, 50.00605],
              [8.2189, 50.006346],
              [8.220445, 50.006615],
              [8.220831, 50.005457],
              [8.219726, 50.005326],
              [8.218471, 50.005015],
              [8.217055, 50.004636],
              [8.21743, 50.004064],
              [8.217956, 50.00334],
              [8.218224, 50.002885],
              [8.21831, 50.002436],
              [8.21816, 50.00205],
              [8.217988, 50.001457],
              [8.217924, 50.000816],
              [8.217956, 50.000333],
              [8.218063, 50.000071],
              [8.218514, 49.99974],
              [8.219984, 49.998836],
              [8.220681, 49.998423],
              [8.221153, 49.998023],
              [8.22199, 49.997498],
              [8.223932, 49.996409],
              [8.224736, 49.995892],
              [8.224854, 49.995609],
              [8.225616, 49.994836],
              [8.226432, 49.993712],
              [8.227097, 49.992402],
              [8.228556, 49.989539],
              [8.230026, 49.986711],
              [8.231184, 49.984876],
              [8.231764, 49.983951],
              [8.233502, 49.981806],
              [8.233749, 49.981454],
              [8.234392, 49.980454],
              [8.234746, 49.979653],
              [8.234897, 49.979267],
              [8.235637, 49.979377],
              [8.236645, 49.979633],
              [8.237225, 49.979681],
              [8.237793, 49.979467],
              [8.237965, 49.979094],
              [8.238276, 49.978273],
              [8.238459, 49.977949],
              [8.239135, 49.97659],
              [8.23921, 49.976135],
              [8.239242, 49.975721],
              [8.240465, 49.975997],
              [8.244252, 49.976956],
              [8.247557, 49.977784],
              [8.248715, 49.978066],
              [8.249048, 49.978087],
              [8.249391, 49.977777],
              [8.249627, 49.977425],
              [8.250121, 49.977114],
              [8.250357, 49.976935],
              [8.251537, 49.976652],
              [8.253415, 49.976266],
              [8.250132, 49.973533],
              [8.24466, 49.968986],
              [8.239017, 49.964335],
              [8.241591, 49.964376],
              [8.24569, 49.965052],
              [8.249338, 49.965963],
              [8.2532, 49.967109],
              [8.254895, 49.967606],
              [8.255625, 49.967619],
              [8.255775, 49.967509],
              [8.255839, 49.96704],
              [8.255668, 49.966557],
              [8.254788, 49.965549],
              [8.253329, 49.964252],
              [8.251934, 49.963037],
              [8.251119, 49.96225],
              [8.250775, 49.961546],
              [8.251226, 49.961512],
              [8.251998, 49.961643],
              [8.253189, 49.961884],
              [8.255421, 49.96234],
              [8.255646, 49.961988],
              [8.257459, 49.958485],
              [8.258087, 49.958571],
              [8.259804, 49.958934],
              [8.261558, 49.959241],
              [8.262405, 49.95932],
              [8.263521, 49.959379],
              [8.264562, 49.959372],
              [8.265801, 49.959313],
              [8.26741, 49.959213],
              [8.267512, 49.959634],
              [8.267738, 49.960004],
            ],
            [
              [8.245014, 50.090934],
              [8.245636, 50.090803],
              [8.246098, 50.090769],
              [8.246655, 50.09081],
              [8.247224, 50.090762],
              [8.248318, 50.0907],
              [8.250024, 50.090645],
              [8.251323, 50.09061],
              [8.252181, 50.090638],
              [8.253275, 50.090666],
              [8.254166, 50.090693],
              [8.25497, 50.090624],
              [8.254799, 50.091368],
              [8.254713, 50.091836],
              [8.25482, 50.09262],
              [8.25482, 50.093164],
              [8.254702, 50.093371],
              [8.254262, 50.093515],
              [8.251601, 50.09419],
              [8.251323, 50.094279],
              [8.25026, 50.094417],
              [8.249745, 50.094499],
              [8.249627, 50.094671],
              [8.249509, 50.095222],
              [8.249241, 50.09536],
              [8.247857, 50.095786],
              [8.247267, 50.095952],
              [8.246934, 50.096027],
              [8.247235, 50.096943],
              [8.247417, 50.097569],
              [8.247524, 50.09812],
              [8.247589, 50.098305],
              [8.248093, 50.098347],
              [8.249509, 50.09834],
              [8.249649, 50.098065],
              [8.24967, 50.097769],
            ],
            [
              [8.236914, 50.036442],
              [8.237987, 50.036111],
              [8.238373, 50.036277],
              [8.23863, 50.036773],
              [8.239145, 50.036966],
              [8.239532, 50.037793],
              [8.240733, 50.040136],
              [8.241205, 50.041045],
              [8.241248, 50.041845],
              [8.241162, 50.043691],
              [8.240991, 50.044711],
            ],
          ],
        },
        id: "QxMzA",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "MwMDY",
    name: "24 Fasanerie <> Carl-Bosch-Str.",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.19167, 50.10196],
              [8.190984, 50.101636],
              [8.190222, 50.101217],
              [8.187647, 50.099627],
              [8.186027, 50.09865],
              [8.185812, 50.098443],
              [8.185716, 50.09686],
              [8.186327, 50.096661],
              [8.187647, 50.096282],
              [8.189235, 50.095773],
              [8.190318, 50.095353],
              [8.192056, 50.094692],
              [8.192883, 50.094437],
              [8.193537, 50.094341],
              [8.195726, 50.094369],
              [8.198107, 50.094355],
              [8.198376, 50.094031],
              [8.199019, 50.093481],
              [8.199223, 50.093302],
              [8.199229, 50.093171],
              [8.199406, 50.093057],
              [8.199363, 50.092899],
              [8.199336, 50.09272],
              [8.199288, 50.092152],
              [8.198767, 50.092111],
              [8.197657, 50.092015],
              [8.19733, 50.091905],
              [8.195656, 50.091282],
              [8.195221, 50.091072],
              [8.195087, 50.0909],
              [8.195007, 50.0906],
              [8.19506, 50.090459],
              [8.195221, 50.090297],
              [8.1955, 50.090173],
              [8.195763, 50.090139],
              [8.197056, 50.090049],
              [8.197855, 50.089991],
              [8.198816, 50.08995],
              [8.198639, 50.089475],
              [8.198499, 50.088983],
              [8.198563, 50.088473],
              [8.198687, 50.088039],
              [8.198848, 50.087399],
              [8.198944, 50.087189],
              [8.199073, 50.087141],
              [8.199331, 50.087165],
              [8.199728, 50.087093],
              [8.200269, 50.086979],
              [8.200516, 50.086917],
              [8.200361, 50.086535],
              [8.200253, 50.086267],
              [8.200194, 50.085936],
              [8.200237, 50.085813],
              [8.200548, 50.085448],
              [8.202024, 50.083912],
              [8.202898, 50.083007],
              [8.20389, 50.081981],
              [8.204191, 50.081651],
              [8.204277, 50.081506],
              [8.20448, 50.081049],
              [8.204641, 50.080381],
              [8.205441, 50.080518],
              [8.205907, 50.079048],
              [8.206342, 50.077503],
              [8.206481, 50.077334],
              [8.207495, 50.076349],
              [8.207404, 50.076205],
              [8.206004, 50.076074],
              [8.204523, 50.075936],
              [8.202914, 50.075788],
              [8.202447, 50.075726],
              [8.197786, 50.075186],
              [8.196386, 50.075048],
              [8.195999, 50.074914],
              [8.195795, 50.074742],
              [8.195522, 50.074315],
              [8.194229, 50.073079],
              [8.194427, 50.072686],
              [8.194481, 50.072504],
              [8.195238, 50.071925],
              [8.196225, 50.071061],
              [8.196514, 50.070848],
              [8.196793, 50.070848],
              [8.196917, 50.070992],
              [8.197228, 50.071357],
              [8.198622, 50.070899],
              [8.199974, 50.070252],
            ],
            [
              [8.197185, 50.071371],
              [8.195479, 50.071891],
            ],
            [
              [8.194701, 50.07237],
              [8.195366, 50.0728],
              [8.197453, 50.074125],
              [8.198032, 50.074521],
              [8.198032, 50.074642],
              [8.197888, 50.075186],
            ],
            [
              [8.207517, 50.076212],
              [8.209625, 50.076418],
              [8.20919, 50.077785],
              [8.207603, 50.07762],
              [8.206691, 50.080759],
              [8.205489, 50.080536],
            ],
            [
              [8.198853, 50.08995],
              [8.199036, 50.089981],
              [8.199207, 50.090373],
              [8.199261, 50.09061],
              [8.199373, 50.091443],
              [8.199443, 50.092039],
              [8.199341, 50.092139],
            ],
            [
              [8.240594, 50.047942],
              [8.238405, 50.048121],
              [8.237214, 50.048121],
              [8.236624, 50.048108],
              [8.234886, 50.048004],
              [8.231206, 50.047336],
              [8.23023, 50.047219],
              [8.230026, 50.047687],
              [8.229908, 50.048018],
              [8.229843, 50.048383],
              [8.228298, 50.048528],
              [8.22184, 50.048948],
              [8.219994, 50.048838],
              [8.220198, 50.047515],
              [8.220134, 50.046957],
              [8.220423, 50.045682],
              [8.220391, 50.045221],
              [8.220359, 50.044697],
              [8.220145, 50.044387],
              [8.220112, 50.044229],
              [8.220348, 50.044125],
              [8.221765, 50.043795],
              [8.221335, 50.042775],
              [8.218921, 50.042892],
              [8.218042, 50.042926],
              [8.218085, 50.043133],
              [8.218417, 50.043863],
              [8.218524, 50.044132],
              [8.21876, 50.044242],
              [8.219769, 50.04438],
              [8.220069, 50.044401],
            ],
          ],
        },
        id: "MwMDY",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "A2NTI",
    name: "25 Kohlheck <> Breckenheim",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.194299, 50.083493],
              [8.192217, 50.082646],
              [8.192142, 50.082322],
              [8.192099, 50.081386],
              [8.192046, 50.080842],
              [8.19152, 50.078687],
              [8.191273, 50.078074],
              [8.191134, 50.077819],
              [8.191316, 50.077565],
              [8.191563, 50.077461],
              [8.192271, 50.077351],
              [8.192743, 50.077482],
              [8.193204, 50.077682],
              [8.193526, 50.077744],
              [8.19417, 50.077689],
              [8.194653, 50.077682],
              [8.195511, 50.07784],
              [8.196187, 50.077978],
              [8.196691, 50.077992],
              [8.197378, 50.077778],
              [8.198612, 50.077379],
              [8.199191, 50.07731],
              [8.199942, 50.077303],
              [8.201069, 50.077358],
              [8.201476, 50.077372],
              [8.201605, 50.07742],
              [8.200639, 50.07899],
              [8.200382, 50.079668],
              [8.200361, 50.079778],
            ],
            [
              [8.260335, 50.087196],
              [8.260614, 50.087361],
              [8.261107, 50.087554],
              [8.261697, 50.087733],
              [8.262137, 50.087898],
              [8.262631, 50.087988],
              [8.263253, 50.088091],
              [8.264079, 50.088105],
              [8.264433, 50.088098],
              [8.265281, 50.08794],
              [8.265828, 50.087712],
              [8.266397, 50.087465],
              [8.266761, 50.087306],
              [8.267652, 50.087052],
              [8.268574, 50.087003],
              [8.269798, 50.087114],
              [8.271053, 50.087286],
              [8.272405, 50.08752],
              [8.273714, 50.087685],
              [8.274304, 50.087768],
              [8.27498, 50.087829],
              [8.275323, 50.087823],
              [8.275859, 50.087602],
              [8.276095, 50.087416],
              [8.276278, 50.086962],
              [8.276192, 50.086707],
              [8.27587, 50.086322],
              [8.275484, 50.085971],
              [8.275141, 50.085744],
              [8.274947, 50.085386],
              [8.275752, 50.08522],
              [8.276503, 50.085055],
              [8.277136, 50.084759],
              [8.279765, 50.083603],
              [8.282404, 50.082425],
              [8.28294, 50.082157],
              [8.283069, 50.082026],
              [8.283445, 50.082067],
              [8.284646, 50.08226],
              [8.285558, 50.082563],
              [8.286309, 50.082832],
              [8.286642, 50.08215],
              [8.286899, 50.082129],
              [8.287543, 50.082171],
              [8.287768, 50.082233],
              [8.289217, 50.082054],
              [8.290375, 50.082178],
              [8.294463, 50.082226],
              [8.295547, 50.082164],
              [8.297414, 50.081806],
              [8.298519, 50.081696],
              [8.299903, 50.081737],
              [8.303143, 50.081854],
              [8.306791, 50.082019],
              [8.309966, 50.082102],
              [8.312209, 50.081992],
              [8.317219, 50.081517],
              [8.321071, 50.080966],
              [8.32327, 50.080718],
              [8.32474, 50.080697],
              [8.326049, 50.080594],
              [8.326231, 50.080525],
              [8.326714, 50.080484],
              [8.326821, 50.08056],
              [8.327368, 50.080491],
              [8.327916, 50.080567],
              [8.328313, 50.080635],
              [8.32842, 50.080773],
              [8.328828, 50.081517],
              [8.328935, 50.081765],
              [8.329246, 50.081909],
              [8.329697, 50.082054],
              [8.330297, 50.082219],
              [8.330512, 50.082315],
              [8.331424, 50.082439],
              [8.332454, 50.082549],
              [8.333312, 50.082405],
              [8.336756, 50.08184],
              [8.337389, 50.081744],
              [8.338205, 50.081661],
              [8.340329, 50.081441],
              [8.34197, 50.081234],
              [8.343784, 50.081021],
              [8.34741, 50.080629],
              [8.34859, 50.080525],
              [8.349813, 50.080491],
              [8.350779, 50.080532],
              [8.351777, 50.080532],
              [8.352023, 50.080649],
              [8.352442, 50.081172],
              [8.352495, 50.081413],
              [8.352764, 50.081592],
              [8.353901, 50.08153],
              [8.355188, 50.081331],
              [8.356497, 50.080842],
              [8.357431, 50.080525],
              [8.358482, 50.080443],
              [8.35977, 50.080436],
              [8.3614, 50.080429],
              [8.363847, 50.080408],
              [8.366926, 50.080422],
              [8.368042, 50.080319],
              [8.367537, 50.081579],
              [8.367258, 50.081882],
              [8.366625, 50.082549],
              [8.36669, 50.082653],
              [8.367205, 50.082797],
              [8.367795, 50.08299],
              [8.36935, 50.082563],
              [8.370219, 50.082267],
              [8.370681, 50.08182],
              [8.371303, 50.081117],
              [8.371764, 50.080759],
              [8.372194, 50.080608],
              [8.372794, 50.080539],
              [8.373277, 50.080635],
              [8.373932, 50.080649],
              [8.374361, 50.080085],
              [8.374468, 50.079823],
              [8.375037, 50.07952],
              [8.375015, 50.079059],
              [8.374747, 50.078329],
              [8.374747, 50.077916],
              [8.374554, 50.077799],
              [8.372644, 50.077517],
              [8.372387, 50.077358],
              [8.371882, 50.076966],
              [8.371292, 50.076828],
              [8.370584, 50.076697],
              [8.370177, 50.076649],
              [8.370016, 50.077014],
              [8.369683, 50.07764],
              [8.369468, 50.078184],
              [8.369104, 50.07877],
              [8.368589, 50.079437],
              [8.368063, 50.080243],
            ],
            [
              [8.286245, 50.082962],
              [8.286138, 50.08332],
              [8.285708, 50.083706],
              [8.284764, 50.083789],
              [8.282919, 50.083623],
              [8.280559, 50.083293],
            ],
          ],
        },
        id: "A2NTI",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "IxMDg",
    name: "26 + 36",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.17636, 50.044263],
              [8.177058, 50.044229],
              [8.17856, 50.044311],
              [8.184536, 50.044628],
              [8.187207, 50.044732],
              [8.188741, 50.044821],
              [8.190297, 50.044993],
              [8.190447, 50.044938],
              [8.190812, 50.044966],
              [8.194277, 50.045517],
              [8.194438, 50.045248],
              [8.194664, 50.044959],
              [8.195028, 50.044828],
              [8.195715, 50.04467],
              [8.195897, 50.044559],
              [8.196487, 50.044346],
              [8.19682, 50.044353],
              [8.198633, 50.044366],
              [8.199298, 50.044339],
              [8.199663, 50.044683],
              [8.200146, 50.045241],
              [8.2008, 50.046006],
              [8.20227, 50.047563],
              [8.202581, 50.047556],
              [8.203751, 50.047694],
              [8.205521, 50.047818],
              [8.210757, 50.048259],
              [8.213203, 50.048418],
              [8.219801, 50.048824],
              [8.220037, 50.047556],
              [8.219984, 50.047074],
              [8.22008, 50.046192],
              [8.220273, 50.045565],
              [8.220252, 50.044973],
              [8.220134, 50.044635],
              [8.220005, 50.044442],
              [8.219876, 50.044249],
              [8.220059, 50.044111],
              [8.220338, 50.044029],
              [8.221582, 50.043739],
              [8.22126, 50.042899],
              [8.218213, 50.042988],
              [8.217913, 50.042968],
              [8.21699, 50.042995],
              [8.216743, 50.042926],
              [8.216465, 50.042155],
              [8.216261, 50.041514],
              [8.216368, 50.041286],
              [8.216819, 50.041197],
              [8.222204, 50.040012],
              [8.225037, 50.039371],
              [8.228245, 50.038585],
              [8.229007, 50.038255],
              [8.230498, 50.037669],
              [8.231324, 50.037524],
              [8.233255, 50.037166],
              [8.236442, 50.036442],
              [8.23642, 50.036229],
              [8.23657, 50.036118],
              [8.237118, 50.035925],
              [8.237675, 50.035774],
              [8.238201, 50.035677],
              [8.238276, 50.03576],
              [8.238566, 50.036008],
              [8.238877, 50.036628],
              [8.239499, 50.03667],
              [8.239682, 50.036897],
              [8.240368, 50.038303],
              [8.24098, 50.039578],
              [8.241034, 50.039922],
              [8.241227, 50.040432],
              [8.241527, 50.041107],
              [8.241506, 50.04305],
              [8.241495, 50.044766],
              [8.241184, 50.045049],
              [8.24113, 50.047729],
              [8.244038, 50.047074],
              [8.246344, 50.045972],
              [8.24967, 50.044401],
              [8.250775, 50.043753],
              [8.25114, 50.043533],
              [8.251709, 50.043422],
              [8.25556, 50.040728],
              [8.256547, 50.040005],
              [8.257438, 50.038882],
              [8.258618, 50.036945],
              [8.260549, 50.034168],
              [8.262405, 50.031522],
              [8.262674, 50.031143],
              [8.263564, 50.029771],
              [8.264744, 50.02811],
              [8.26556, 50.027056],
              [8.267566, 50.024733],
              [8.268059, 50.024188],
              [8.269529, 50.024326],
              [8.270441, 50.024478],
              [8.271503, 50.024588],
              [8.272297, 50.024643],
              [8.273478, 50.024588],
              [8.274422, 50.024519],
              [8.276761, 50.024133],
              [8.27778, 50.023892],
              [8.278552, 50.023575],
              [8.280998, 50.022713],
              [8.284131, 50.021362],
              [8.287854, 50.019804],
              [8.288391, 50.020618],
              [8.288723, 50.021376],
              [8.289099, 50.022541],
              [8.289367, 50.023465],
              [8.28986, 50.024243],
              [8.291137, 50.026153],
              [8.292156, 50.027738],
              [8.2928, 50.028737],
              [8.293498, 50.030054],
              [8.29457, 50.03157],
              [8.295064, 50.032514],
              [8.29545, 50.033672],
              [8.296244, 50.035326],
              [8.296995, 50.036739],
              [8.297532, 50.037752],
              [8.297971, 50.038792],
              [8.298197, 50.039467],
              [8.298379, 50.040398],
              [8.298529, 50.041659],
              [8.298551, 50.042547],
              [8.298519, 50.043099],
              [8.298765, 50.043471],
              [8.298969, 50.043767],
              [8.299602, 50.04418],
              [8.300042, 50.044642],
              [8.300107, 50.04478],
              [8.300149, 50.045131],
              [8.300332, 50.045586],
              [8.30045, 50.045992],
              [8.301115, 50.047136],
              [8.301373, 50.047625],
              [8.301598, 50.048321],
              [8.301705, 50.049038],
              [8.301523, 50.049162],
              [8.301051, 50.049237],
              [8.300729, 50.049279],
              [8.300074, 50.049437],
              [8.299935, 50.049534],
              [8.299656, 50.049768],
              [8.299538, 50.050216],
              [8.299452, 50.050815],
              [8.299602, 50.051049],
              [8.300096, 50.051208],
              [8.300568, 50.051352],
              [8.301104, 50.05158],
              [8.301362, 50.051897],
              [8.301415, 50.052213],
              [8.301244, 50.052895],
              [8.301061, 50.053674],
              [8.300933, 50.053791],
              [8.299516, 50.054673],
              [8.298808, 50.055072],
              [8.297317, 50.055596],
              [8.29501, 50.056395],
              [8.291813, 50.057573],
              [8.290483, 50.056057],
              [8.289678, 50.055072],
              [8.289775, 50.054852],
              [8.290075, 50.054432],
              [8.290826, 50.053495],
              [8.291191, 50.053109],
              [8.291706, 50.052723],
              [8.292135, 50.052475],
              [8.292714, 50.052275],
              [8.293111, 50.052213],
              [8.294216, 50.052172],
              [8.295418, 50.052179],
              [8.2981, 50.052207],
              [8.298165, 50.052007],
              [8.298186, 50.051773],
              [8.29824, 50.051552],
              [8.298583, 50.051325],
              [8.299237, 50.051022],
              [8.299409, 50.050925],
            ],
            [
              [8.194363, 50.045648],
              [8.194921, 50.045634],
              [8.19593, 50.045676],
              [8.20065, 50.046089],
            ],
            [
              [8.240991, 50.044821],
              [8.240304, 50.04325],
              [8.237858, 50.037572],
              [8.236699, 50.03658],
            ],
          ],
        },
        id: "IxMDg",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "I0OTE",
    name: "27 Fasanerie <> Raiffeisenplatz",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.191788, 50.101939],
              [8.190072, 50.101051],
              [8.18637, 50.098746],
              [8.185952, 50.098498],
              [8.185844, 50.097934],
              [8.185844, 50.096908],
              [8.188205, 50.096193],
              [8.189181, 50.095883],
              [8.190061, 50.095559],
              [8.191166, 50.095084],
              [8.192389, 50.094665],
              [8.193001, 50.094486],
              [8.194009, 50.094431],
              [8.198118, 50.094417],
              [8.199116, 50.094382],
              [8.200811, 50.094451],
              [8.201423, 50.094382],
              [8.202678, 50.093963],
              [8.20418, 50.093446],
              [8.204534, 50.093254],
              [8.205467, 50.093178],
              [8.206776, 50.092999],
              [8.207731, 50.092613],
              [8.208911, 50.091987],
              [8.21139, 50.090783],
              [8.21228, 50.090349],
              [8.212656, 50.090067],
              [8.213804, 50.088869],
              [8.214458, 50.088201],
              [8.21508, 50.087871],
              [8.215832, 50.087499],
              [8.21728, 50.087127],
              [8.223374, 50.085833],
              [8.224221, 50.085682],
              [8.224618, 50.085627],
              [8.225048, 50.085544],
              [8.225487, 50.085558],
              [8.225949, 50.085654],
              [8.226646, 50.085627],
              [8.227172, 50.085489],
              [8.228009, 50.085103],
              [8.229393, 50.084422],
              [8.230004, 50.084229],
              [8.231131, 50.084043],
              [8.23333, 50.083706],
              [8.235691, 50.083293],
            ],
            [
              [8.267802, 50.060645],
              [8.269712, 50.061113],
              [8.271332, 50.061595],
              [8.272083, 50.06185],
              [8.273156, 50.062484],
              [8.273617, 50.062918],
              [8.27396, 50.063434],
              [8.274808, 50.064564],
              [8.275419, 50.065301],
              [8.275505, 50.065548],
              [8.275087, 50.065672],
              [8.273038, 50.066141],
              [8.273199, 50.06634],
              [8.273488, 50.066712],
            ],
          ],
        },
        id: "I0OTE",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "U2NTY",
    name: "28 GRäselberg <> Hochheim Bf",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.260313, 50.087265],
              [8.2607, 50.087451],
              [8.261569, 50.087761],
              [8.262126, 50.087933],
              [8.262813, 50.08807],
              [8.26351, 50.08816],
              [8.264208, 50.088146],
              [8.264776, 50.088119],
              [8.265206, 50.088022],
              [8.26586, 50.087774],
              [8.266536, 50.087465],
              [8.267448, 50.087162],
              [8.268199, 50.087072],
              [8.269143, 50.087086],
              [8.269991, 50.087189],
              [8.271815, 50.087451],
              [8.272866, 50.087637],
              [8.274314, 50.087836],
              [8.275312, 50.087891],
              [8.275741, 50.087768],
              [8.276117, 50.087527],
              [8.276289, 50.087286],
              [8.276385, 50.086983],
              [8.276278, 50.086652],
              [8.276117, 50.086432],
              [8.275441, 50.085819],
              [8.275216, 50.085675],
              [8.275023, 50.085324],
              [8.276353, 50.085014],
              [8.276643, 50.084856],
              [8.280076, 50.083327],
              [8.282694, 50.082253],
              [8.282897, 50.082095],
              [8.283037, 50.081964],
              [8.283638, 50.082019],
              [8.284689, 50.082171],
              [8.286256, 50.082735],
              [8.286567, 50.082136],
              [8.286921, 50.082054],
              [8.287758, 50.082157],
              [8.288423, 50.082109],
              [8.289099, 50.082005],
              [8.28956, 50.082033],
              [8.290365, 50.082129],
              [8.29456, 50.082164],
              [8.295525, 50.082102],
              [8.296437, 50.08195],
              [8.297263, 50.081778],
              [8.2981, 50.081689],
              [8.298798, 50.081654],
              [8.305031, 50.081875],
              [8.307831, 50.082005],
              [8.309526, 50.082054],
              [8.311297, 50.081985],
              [8.313271, 50.081854],
              [8.316178, 50.081572],
              [8.319236, 50.081172],
              [8.322176, 50.080794],
              [8.323174, 50.080691],
              [8.324289, 50.080684],
              [8.325158, 50.080656],
              [8.326167, 50.080498],
              [8.326735, 50.080443],
              [8.327304, 50.080443],
              [8.327776, 50.079795],
              [8.328645, 50.079396],
              [8.329128, 50.079004],
              [8.329557, 50.078494],
              [8.32975, 50.078095],
              [8.329868, 50.077751],
              [8.330652, 50.077234],
              [8.334417, 50.075182],
              [8.334793, 50.074742],
              [8.335372, 50.073922],
              [8.336499, 50.072993],
              [8.338634, 50.071175],
              [8.339621, 50.070266],
              [8.340222, 50.069584],
              [8.341069, 50.068455],
              [8.342389, 50.067022],
              [8.342861, 50.066354],
              [8.343108, 50.066079],
              [8.342764, 50.066031],
              [8.342367, 50.066079],
              [8.339846, 50.066409],
              [8.340393, 50.067387],
              [8.33858, 50.06789],
              [8.337379, 50.068186],
              [8.334911, 50.068861],
              [8.333988, 50.06769],
              [8.332669, 50.065824],
              [8.330276, 50.062201],
              [8.329643, 50.061692],
              [8.329729, 50.061519],
              [8.335844, 50.060362],
              [8.336005, 50.060452],
              [8.336338, 50.060975],
              [8.336627, 50.061602],
              [8.336735, 50.062015],
              [8.336842, 50.062594],
              [8.33681, 50.062945],
              [8.337046, 50.063166],
              [8.338119, 50.062801],
              [8.338859, 50.062511],
              [8.340061, 50.061905],
              [8.340715, 50.061623],
              [8.340844, 50.06134],
              [8.34079, 50.060603],
              [8.340994, 50.060555],
              [8.342528, 50.060287],
              [8.342335, 50.059873],
              [8.342421, 50.059646],
              [8.342518, 50.059563],
              [8.342142, 50.059205],
              [8.342024, 50.058868],
              [8.341627, 50.058089],
              [8.341058, 50.05718],
              [8.340436, 50.056457],
              [8.339943, 50.05605],
              [8.339792, 50.055541],
              [8.339889, 50.055155],
              [8.340307, 50.054866],
              [8.341219, 50.054687],
              [8.341799, 50.054749],
              [8.342421, 50.0551],
              [8.34299, 50.055465],
              [8.343623, 50.055534],
              [8.345683, 50.055513],
              [8.350403, 50.055492],
              [8.351305, 50.055251],
              [8.352034, 50.054852],
              [8.356293, 50.052985],
              [8.357195, 50.05222],
              [8.357463, 50.051793],
              [8.357828, 50.050905],
              [8.357806, 50.050195],
              [8.357549, 50.049458],
              [8.357399, 50.049107],
              [8.357356, 50.048555],
              [8.357409, 50.047887],
              [8.357688, 50.047239],
              [8.357999, 50.046399],
              [8.358321, 50.045545],
              [8.359051, 50.045517],
              [8.359673, 50.045565],
              [8.359684, 50.045393],
              [8.359641, 50.045207],
              [8.359383, 50.045042],
              [8.358729, 50.044504],
              [8.358793, 50.044318],
              [8.359201, 50.043884],
              [8.359694, 50.043264],
              [8.360177, 50.042658],
              [8.360596, 50.042299],
              [8.361411, 50.041934],
              [8.362516, 50.041362],
              [8.361486, 50.039736],
              [8.361121, 50.039261],
              [8.360488, 50.039412],
              [8.360252, 50.039157],
              [8.360016, 50.038951],
              [8.357688, 50.038062],
              [8.357484, 50.038041],
              [8.357259, 50.038206],
              [8.35565, 50.038716],
              [8.354727, 50.036766],
              [8.354352, 50.036484],
              [8.351734, 50.035395],
              [8.348633, 50.034223],
              [8.348322, 50.033941],
              [8.348129, 50.033569],
              [8.34815, 50.033162],
              [8.349727, 50.030502],
              [8.350478, 50.029227],
              [8.351026, 50.028096],
              [8.351583, 50.027035],
              [8.351991, 50.025925],
              [8.352174, 50.02496],
              [8.352249, 50.024278],
              [8.352292, 50.023382],
              [8.352281, 50.022527],
              [8.352195, 50.0211],
              [8.352184, 50.020962],
              [8.352968, 50.020969],
              [8.353965, 50.020687],
              [8.354716, 50.020535],
              [8.355532, 50.020556],
              [8.356379, 50.020238],
              [8.358257, 50.019797],
              [8.357441, 50.018749],
              [8.356326, 50.017288],
              [8.357291, 50.016461],
              [8.358901, 50.01471],
              [8.359909, 50.013586],
              [8.3558, 50.012083],
              [8.354266, 50.011483],
              [8.353311, 50.011145],
              [8.353064, 50.011076],
              [8.35242, 50.01098],
              [8.351626, 50.011007],
              [8.35095, 50.011124],
              [8.350414, 50.011159],
              [8.349856, 50.011166],
              [8.349116, 50.011111],
              [8.348494, 50.010814],
              [8.347818, 50.010731],
              [8.347174, 50.010731],
              [8.342968, 50.011056],
              [8.341187, 50.011007],
              [8.339921, 50.010856],
              [8.340104, 50.010373],
              [8.340522, 50.010063],
              [8.341262, 50.009766],
              [8.341767, 50.009221],
              [8.342121, 50.008298],
              [8.3427, 50.007105],
              [8.342979, 50.006567],
              [8.343505, 50.006415],
              [8.343859, 50.00625],
              [8.34417, 50.005691],
              [8.344449, 50.005126],
              [8.344921, 50.005167],
              [8.346305, 50.005029],
              [8.348633, 50.004815],
              [8.350468, 50.004664],
            ],
            [
              [8.345264, 50.010931],
              [8.345479, 50.012138],
              [8.346047, 50.012221],
              [8.346863, 50.012345],
              [8.34741, 50.012434],
              [8.348579, 50.012641],
              [8.349159, 50.012703],
              [8.35021, 50.012469],
              [8.352463, 50.012007],
              [8.353359, 50.01179],
              [8.353381, 50.011673],
              [8.353311, 50.01138],
              [8.353284, 50.011286],
              [8.353279, 50.011193],
            ],
            [
              [8.357592, 50.037958],
              [8.357838, 50.037648],
              [8.358257, 50.037221],
              [8.359662, 50.037731],
              [8.360285, 50.037979],
              [8.36081, 50.038007],
              [8.361679, 50.038007],
              [8.362205, 50.038055],
              [8.362248, 50.038199],
              [8.362184, 50.038289],
              [8.361883, 50.038578],
              [8.361679, 50.038716],
              [8.361272, 50.03893],
              [8.3611, 50.039123],
              [8.361132, 50.039212],
            ],
            [
              [8.334042, 50.067615],
              [8.338237, 50.066492],
              [8.339674, 50.066086],
              [8.339825, 50.06634],
            ],
            [
              [8.286256, 50.082797],
              [8.286191, 50.083114],
              [8.286084, 50.083369],
              [8.285784, 50.083596],
              [8.285494, 50.083713],
              [8.285279, 50.08374],
              [8.284292, 50.08372],
              [8.283284, 50.083596],
              [8.281385, 50.083327],
              [8.280462, 50.08321],
            ],
          ],
        },
        id: "U2NTY",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "k4ODk",
    name: "29 + 39 (Klarenthal/Tsst-Hahn <> Schierstein Hafen)",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.200645, 50.045986],
              [8.200033, 50.04602],
              [8.197705, 50.045875],
              [8.197367, 50.047653],
              [8.193639, 50.04725],
              [8.193516, 50.047243],
              [8.192899, 50.048287],
              [8.192561, 50.048945],
              [8.192341, 50.049248],
              [8.191922, 50.050254],
              [8.191558, 50.051011],
              [8.192089, 50.051201],
              [8.192866, 50.051445],
              [8.193623, 50.051628],
              [8.194191, 50.051717],
              [8.19491, 50.051793],
              [8.195232, 50.051883],
              [8.195275, 50.052021],
              [8.195425, 50.052441],
              [8.195876, 50.054535],
              [8.195962, 50.054707],
              [8.196144, 50.054755],
              [8.198043, 50.054735],
            ],
            [
              [8.193532, 50.047215],
              [8.194358, 50.045986],
              [8.194444, 50.045782],
              [8.194379, 50.045555],
              [8.194594, 50.045138],
              [8.194835, 50.044959],
              [8.195109, 50.044866],
              [8.195506, 50.044745],
              [8.195795, 50.044704],
              [8.19593, 50.044611],
              [8.196096, 50.044518],
              [8.196493, 50.044425],
              [8.19777, 50.044394],
              [8.199111, 50.044397],
              [8.199368, 50.044401],
              [8.199808, 50.04325],
              [8.19998, 50.042826],
              [8.200189, 50.042713],
              [8.200484, 50.042733],
              [8.201138, 50.04284],
              [8.2016, 50.042926],
              [8.201846, 50.042951],
              [8.203869, 50.042957],
              [8.20411, 50.042995],
              [8.204196, 50.043064],
              [8.204652, 50.043805],
            ],
          ],
        },
        id: "k4ODk",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "M1NDY",
    name: "30 + 230",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.256826, 50.072662],
              [8.254563, 50.07426],
              [8.247707, 50.079403],
              [8.247063, 50.079913],
              [8.24657, 50.080236],
              [8.245958, 50.080532],
              [8.245239, 50.080718],
              [8.244467, 50.080766],
              [8.236645, 50.080298],
              [8.236527, 50.080787],
              [8.236409, 50.08089],
              [8.235959, 50.081021],
              [8.229618, 50.080635],
            ],
            [
              [8.230058, 50.078171],
              [8.236248, 50.07879],
              [8.236806, 50.078797],
              [8.242117, 50.079107],
              [8.242471, 50.079169],
              [8.24495, 50.079314],
              [8.245143, 50.079265],
              [8.245711, 50.079286],
              [8.246301, 50.079362],
              [8.24658, 50.079561],
              [8.247106, 50.07983],
            ],
          ],
        },
        id: "M1NDY",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "E2Nzc",
    name: "32 Hofheim-Lorsbach <> ELW",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.334074, 50.118913],
              [8.333259, 50.116189],
              [8.333108, 50.115707],
              [8.332937, 50.115157],
              [8.332851, 50.114703],
              [8.333623, 50.113932],
              [8.334224, 50.113079],
              [8.335061, 50.11191],
              [8.335469, 50.110162],
              [8.33564, 50.109254],
              [8.335994, 50.109171],
              [8.336284, 50.108979],
              [8.336284, 50.108662],
              [8.336413, 50.108421],
              [8.337046, 50.107761],
              [8.3374, 50.10743],
              [8.338119, 50.106935],
              [8.338505, 50.106467],
              [8.338301, 50.106206],
              [8.338312, 50.105979],
              [8.33873, 50.105414],
              [8.339138, 50.104953],
              [8.339696, 50.104547],
              [8.340297, 50.104114],
              [8.34078, 50.103715],
              [8.341316, 50.10357],
              [8.341552, 50.10335],
              [8.341724, 50.103178],
              [8.341842, 50.102999],
              [8.343226, 50.103295],
              [8.343397, 50.103013],
              [8.343655, 50.102689],
              [8.344073, 50.102737],
              [8.34564, 50.102792],
              [8.346434, 50.102958],
              [8.34962, 50.103315],
              [8.352849, 50.103715],
              [8.35521, 50.103955],
              [8.356336, 50.103907],
              [8.35712, 50.1039],
              [8.357259, 50.104093],
              [8.357399, 50.104368],
              [8.357506, 50.105297],
              [8.357463, 50.106543],
              [8.357785, 50.106763],
              [8.357999, 50.107231],
              [8.358858, 50.107079],
              [8.360403, 50.106777],
              [8.361132, 50.10679],
              [8.36287, 50.106846],
              [8.364007, 50.107052],
              [8.36551, 50.107451],
              [8.367741, 50.10796],
              [8.368578, 50.108167],
              [8.370402, 50.108153],
              [8.372312, 50.108139],
              [8.373942, 50.107713],
              [8.376775, 50.107093],
              [8.377419, 50.106763],
              [8.378062, 50.106268],
              [8.378727, 50.105676],
              [8.379779, 50.10529],
              [8.380573, 50.105139],
              [8.384135, 50.10518],
              [8.388426, 50.10518],
              [8.39349, 50.10529],
              [8.394971, 50.105359],
              [8.396344, 50.105345],
              [8.397331, 50.105593],
              [8.39834, 50.105634],
              [8.398565, 50.10571],
              [8.398624, 50.105851],
              [8.39893, 50.106044],
              [8.399638, 50.106412],
              [8.40026, 50.106639],
              [8.402535, 50.106956],
              [8.408478, 50.107664],
              [8.411407, 50.108091],
              [8.411676, 50.108325],
              [8.413832, 50.109846],
              [8.416675, 50.111944],
              [8.417759, 50.112715],
              [8.418446, 50.113327],
              [8.419197, 50.113898],
              [8.4194, 50.114215],
              [8.419518, 50.1144],
              [8.419465, 50.114744],
              [8.419218, 50.11493],
              [8.41881, 50.115075],
              [8.418489, 50.115157],
              [8.418188, 50.115178],
              [8.417759, 50.115191],
              [8.417652, 50.115329],
              [8.417802, 50.115467],
              [8.418081, 50.115804],
              [8.418489, 50.116051],
              [8.419003, 50.116309],
              [8.41939, 50.116389],
              [8.419932, 50.116385],
              [8.420275, 50.116416],
              [8.420484, 50.116619],
              [8.420624, 50.116936],
              [8.420688, 50.117118],
              [8.420833, 50.117052],
              [8.421144, 50.116898],
              [8.42212, 50.116358],
              [8.422533, 50.116086],
              [8.422555, 50.115959],
              [8.422662, 50.115725],
              [8.422957, 50.115388],
              [8.423536, 50.11482],
            ],
            [
              [8.251462, 50.043436],
              [8.251655, 50.04365],
              [8.251875, 50.043888],
              [8.25231, 50.044277],
              [8.252572, 50.044446],
              [8.252985, 50.044611],
              [8.253447, 50.044711],
              [8.253919, 50.044735],
              [8.254799, 50.044745],
              [8.25534, 50.044742],
              [8.255657, 50.044718],
              [8.255866, 50.044787],
              [8.256204, 50.044787],
              [8.256403, 50.044742],
              [8.256472, 50.04467],
              [8.256435, 50.044466],
              [8.256403, 50.04428],
              [8.256392, 50.044136],
              [8.256521, 50.043922],
              [8.256623, 50.043822],
              [8.257626, 50.042623],
              [8.258087, 50.042041],
              [8.258345, 50.041827],
              [8.258629, 50.041714],
              [8.258854, 50.041676],
              [8.259246, 50.041707],
              [8.259482, 50.041783],
              [8.260614, 50.04233],
              [8.261134, 50.04251],
              [8.261778, 50.042668],
              [8.262888, 50.042761],
            ],
            [
              [8.259332, 50.041696],
              [8.259632, 50.041286],
              [8.259932, 50.041004],
              [8.260657, 50.040429],
              [8.261155, 50.040019],
              [8.261467, 50.03976],
              [8.261847, 50.039939],
            ],
          ],
        },
        id: "E2Nzc",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "k2MDc",
    name: "36 Erbenheim <> Schlangenbad",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.176296, 50.044366],
              [8.176317, 50.044807],
              [8.176146, 50.045414],
              [8.175631, 50.046406],
              [8.173356, 50.050236],
              [8.172562, 50.050939],
              [8.171425, 50.051779],
              [8.170223, 50.052344],
              [8.169601, 50.052661],
              [8.169022, 50.053061],
              [8.168314, 50.053708],
              [8.167391, 50.054507],
              [8.166425, 50.0551],
              [8.164923, 50.055775],
              [8.162971, 50.056354],
              [8.162434, 50.056574],
              [8.162434, 50.057029],
              [8.162627, 50.057676],
              [8.162584, 50.058296],
              [8.162327, 50.058668],
              [8.161554, 50.059109],
              [8.160439, 50.059329],
              [8.15796, 50.059832],
              [8.157359, 50.059984],
              [8.156512, 50.060417],
              [8.154956, 50.06143],
              [8.154473, 50.061781],
              [8.154044, 50.062511],
              [8.154076, 50.061878],
              [8.154141, 50.06134],
              [8.154055, 50.0609],
              [8.153991, 50.06072],
              [8.153744, 50.060597],
              [8.153486, 50.060645],
              [8.153272, 50.060817],
              [8.15295, 50.061127],
              [8.151877, 50.062484],
              [8.151319, 50.063255],
              [8.150579, 50.064509],
              [8.149549, 50.066272],
              [8.149087, 50.066995],
              [8.14839, 50.067952],
              [8.146974, 50.069791],
              [8.145837, 50.071278],
              [8.145418, 50.071863],
              [8.14383, 50.073275],
              [8.142436, 50.074129],
              [8.140762, 50.075134],
              [8.140022, 50.075857],
              [8.138938, 50.077124],
              [8.138176, 50.077895],
              [8.136814, 50.079458],
              [8.13573, 50.080608],
              [8.134432, 50.082067],
              [8.134303, 50.082818],
              [8.134131, 50.083651],
              [8.133466, 50.084484],
              [8.133241, 50.085035],
              [8.133026, 50.08564],
              [8.132629, 50.086053],
              [8.132114, 50.08637],
              [8.131632, 50.086343],
              [8.130838, 50.086122],
              [8.130548, 50.086074],
              [8.130097, 50.086143],
              [8.125784, 50.087217],
              [8.125548, 50.087348],
              [8.125387, 50.08752],
              [8.125098, 50.087423],
              [8.123574, 50.08774],
              [8.120463, 50.088642],
              [8.119079, 50.088821],
              [8.118736, 50.088848],
              [8.117716, 50.088683],
              [8.116901, 50.088442],
              [8.116, 50.088201],
              [8.115206, 50.088077],
              [8.114862, 50.087995],
              [8.114004, 50.087974],
              [8.113382, 50.087967],
              [8.11291, 50.088105],
              [8.112352, 50.088353],
              [8.11188, 50.088752],
              [8.111429, 50.089062],
              [8.110646, 50.08942],
              [8.11011, 50.089791],
              [8.109627, 50.090335],
              [8.10923, 50.090989],
              [8.108211, 50.091698],
              [8.10666, 50.092827],
              [8.106312, 50.093164],
              [8.106124, 50.093391],
              [8.10607, 50.093883],
              [8.106038, 50.094162],
              [8.105931, 50.09442],
              [8.105695, 50.094954],
              [8.105319, 50.095663],
              [8.105094, 50.095852],
              [8.104772, 50.095928],
              [8.104327, 50.095934],
              [8.104096, 50.095921],
              [8.103957, 50.09568],
              [8.10378, 50.095315],
              [8.10327, 50.094334],
              [8.103034, 50.094042],
              [8.10283, 50.094024],
              [8.101242, 50.094011],
              [8.10129, 50.093945],
              [8.102304, 50.093925],
              [8.103147, 50.093694],
              [8.103409, 50.093515],
              [8.103919, 50.093319],
              [8.104134, 50.093047],
              [8.104708, 50.09209],
              [8.104708, 50.091953],
              [8.10482, 50.091719],
              [8.104756, 50.091567],
              [8.104949, 50.091292],
              [8.105308, 50.090893],
              [8.105512, 50.090583],
            ],
            [
              [8.103645, 50.093467],
              [8.103645, 50.093846],
              [8.103629, 50.09408],
              [8.103731, 50.094434],
              [8.103844, 50.094709],
              [8.10401, 50.095112],
              [8.104048, 50.095229],
              [8.104064, 50.09546],
              [8.104016, 50.095676],
            ],
          ],
        },
        id: "k2MDc",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "I5NTY",
    name: "X26 HSRM <> Bad Homburg",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.218567, 50.081393],
              [8.219318, 50.0812],
              [8.221765, 50.080016],
              [8.223932, 50.079107],
              [8.224254, 50.079079],
              [8.224747, 50.080429],
              [8.225455, 50.080319],
              [8.227172, 50.080305],
              [8.229318, 50.080415],
              [8.229597, 50.07806],
              [8.230326, 50.078102],
              [8.236184, 50.078708],
              [8.236506, 50.077716],
              [8.237751, 50.077634],
              [8.242106, 50.077854],
              [8.245068, 50.078005],
              [8.245196, 50.076931],
              [8.245218, 50.074934],
              [8.245068, 50.073475],
              [8.244445, 50.072056],
              [8.24363, 50.071987],
              [8.242643, 50.072098],
              [8.24039, 50.071918],
              [8.239961, 50.071175],
              [8.240712, 50.071133],
              [8.241935, 50.071133],
              [8.242922, 50.071202],
              [8.243802, 50.071244],
              [8.24805, 50.071505],
              [8.248973, 50.068186],
              [8.249681, 50.066671],
              [8.25026, 50.065817],
              [8.251194, 50.064529],
              [8.252567, 50.062277],
              [8.253812, 50.060045],
              [8.254766, 50.058027],
              [8.255335, 50.056677],
              [8.256161, 50.055561],
              [8.256655, 50.054886],
              [8.256826, 50.054521],
              [8.256977, 50.054122],
              [8.256816, 50.053819],
              [8.256483, 50.053157],
              [8.256483, 50.052937],
              [8.256537, 50.052827],
              [8.25718, 50.052682],
              [8.257878, 50.052682],
              [8.26129, 50.052689],
              [8.264133, 50.052654],
              [8.265055, 50.052661],
              [8.268156, 50.052406],
              [8.27292, 50.052021],
              [8.275945, 50.051745],
              [8.279679, 50.051283],
              [8.282436, 50.050911],
              [8.286685, 50.050174],
              [8.288165, 50.050099],
              [8.292307, 50.049878],
              [8.296652, 50.049733],
              [8.298347, 50.04983],
              [8.300031, 50.050037],
              [8.301866, 50.050512],
              [8.304001, 50.051228],
              [8.307005, 50.05273],
              [8.310117, 50.054135],
              [8.312016, 50.054948],
              [8.314365, 50.055603],
              [8.315932, 50.055858],
              [8.31709, 50.05594],
              [8.318893, 50.056009],
              [8.321961, 50.055899],
              [8.323817, 50.055802],
              [8.325201, 50.055816],
              [8.329976, 50.055775],
              [8.334374, 50.055775],
              [8.339492, 50.055651],
              [8.339857, 50.055072],
              [8.339986, 50.054983],
              [8.340415, 50.054769],
              [8.341058, 50.054638],
              [8.341616, 50.054645],
              [8.342013, 50.05479],
              [8.342625, 50.055141],
              [8.343076, 50.05543],
              [8.343719, 50.055541],
              [8.346359, 50.055479],
              [8.350414, 50.055403],
              [8.354909, 50.055306],
              [8.356304, 50.0547],
              [8.358901, 50.053543],
              [8.360188, 50.053143],
              [8.36184, 50.053708],
              [8.363214, 50.054287],
              [8.364673, 50.054246],
              [8.366668, 50.054273],
              [8.368406, 50.054163],
              [8.369415, 50.054039],
              [8.370981, 50.054122],
              [8.371475, 50.054356],
              [8.370702, 50.054893],
              [8.37023, 50.055727],
              [8.373095, 50.05572],
              [8.380015, 50.055727],
              [8.386892, 50.055699],
              [8.39334, 50.055623],
              [8.400185, 50.055596],
              [8.403779, 50.055527],
              [8.404369, 50.055658],
              [8.405013, 50.055871],
              [8.409101, 50.057504],
              [8.412148, 50.058689],
              [8.414422, 50.059529],
              [8.417856, 50.060879],
              [8.419443, 50.061395],
              [8.420109, 50.06165],
              [8.421439, 50.062208],
              [8.42205, 50.062449],
              [8.423123, 50.062807],
              [8.423917, 50.063145],
              [8.424293, 50.063345],
              [8.424743, 50.063799],
              [8.425151, 50.064515],
              [8.425516, 50.064812],
              [8.425848, 50.065073],
              [8.427447, 50.065838],
              [8.428895, 50.066754],
              [8.429528, 50.067291],
              [8.430333, 50.067759],
              [8.431256, 50.067993],
              [8.431959, 50.068017],
              [8.432618, 50.067983],
              [8.43352, 50.067776],
              [8.434711, 50.067515],
              [8.43625, 50.067174],
              [8.436336, 50.067508],
              [8.436175, 50.068345],
              [8.435869, 50.069508],
              [8.435821, 50.069829],
              [8.435917, 50.070014],
              [8.436384, 50.070448],
              [8.436947, 50.070951],
              [8.437414, 50.071302],
              [8.437698, 50.071667],
              [8.437688, 50.072036],
              [8.437275, 50.073189],
              [8.437178, 50.073809],
              [8.437157, 50.074477],
              [8.437237, 50.074776],
              [8.437371, 50.075255],
              [8.437698, 50.076367],
              [8.438122, 50.078019],
              [8.438192, 50.078718],
              [8.438396, 50.079737],
              [8.438637, 50.080508],
              [8.439077, 50.081035],
              [8.43985, 50.081768],
              [8.440729, 50.082577],
              [8.440815, 50.082852],
              [8.441169, 50.083417],
              [8.441287, 50.08403],
              [8.441384, 50.084436],
              [8.441577, 50.08469],
              [8.44191, 50.084986],
              [8.442253, 50.085041],
              [8.443025, 50.084945],
              [8.443487, 50.084938],
              [8.444216, 50.08478],
              [8.444742, 50.08458],
              [8.4453, 50.084429],
              [8.445836, 50.084332],
              [8.446287, 50.084546],
              [8.446738, 50.08498],
              [8.447435, 50.085909],
              [8.448304, 50.086969],
              [8.44972, 50.08902],
              [8.449312, 50.089227],
              [8.449441, 50.089433],
              [8.450385, 50.089881],
              [8.451748, 50.090528],
              [8.454205, 50.092015],
              [8.45605, 50.092978],
              [8.458153, 50.094203],
              [8.461082, 50.095821],
              [8.463013, 50.096874],
              [8.464644, 50.097734],
              [8.465374, 50.098588],
              [8.467069, 50.100563],
              [8.468227, 50.102166],
              [8.469139, 50.103811],
              [8.470287, 50.106137],
              [8.471146, 50.107809],
              [8.472712, 50.111194],
              [8.473613, 50.112969],
              [8.474493, 50.114084],
              [8.475459, 50.115308],
              [8.476403, 50.116272],
              [8.47724, 50.117579],
              [8.477497, 50.118487],
              [8.477304, 50.11978],
              [8.476231, 50.121858],
              [8.475029, 50.123453],
              [8.474107, 50.125008],
              [8.473248, 50.126659],
              [8.47239, 50.129135],
              [8.472004, 50.13062],
              [8.471618, 50.131941],
              [8.470545, 50.132794],
              [8.466811, 50.135545],
              [8.462863, 50.138955],
              [8.461361, 50.140331],
              [8.460588, 50.141596],
              [8.459988, 50.144099],
              [8.459816, 50.146079],
              [8.459816, 50.148939],
              [8.460202, 50.151331],
              [8.460288, 50.152431],
              [8.460889, 50.154741],
              [8.460889, 50.157353],
              [8.461018, 50.15991],
              [8.461275, 50.161972],
              [8.461962, 50.163951],
              [8.462176, 50.165766],
              [8.462777, 50.167498],
              [8.463936, 50.168735],
              [8.464966, 50.169394],
              [8.467669, 50.170137],
              [8.469343, 50.170302],
              [8.471532, 50.170769],
              [8.472476, 50.171456],
              [8.473935, 50.172693],
              [8.474364, 50.175194],
              [8.475266, 50.177874],
              [8.475287, 50.178891],
              [8.474901, 50.180086],
              [8.473967, 50.180196],
              [8.473023, 50.180478],
              [8.471371, 50.181233],
              [8.469815, 50.181941],
              [8.468442, 50.182134],
              [8.468399, 50.182367],
              [8.468764, 50.182752],
              [8.469107, 50.183116],
              [8.467573, 50.183576],
              [8.466554, 50.182395],
              [8.467948, 50.18212],
              [8.468367, 50.182154],
            ],
            [
              [8.24451, 50.072042],
              [8.244907, 50.071319],
            ],
            [
              [8.475029, 50.180093],
              [8.475813, 50.180265],
              [8.477272, 50.180588],
              [8.478838, 50.181014],
            ],
          ],
        },
        id: "I5NTY",
      },
    ],
  },
];
