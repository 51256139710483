import { BusCategory, Line } from "@/types";

export const v2_regionalBusse: Line[] = [
  {
    category: BusCategory.REGIONAL,
    id: "Q4ODI",
    name: "818 Wallau <> Floersheim",
    lineName: "818",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.430044, 50.017108],
              [8.42965, 50.01678],
              [8.42934, 50.01653],
              [8.42841, 50.01577],
              [8.4281, 50.0156],
              [8.42709, 50.01523],
              [8.42589, 50.01575],
              [8.42553, 50.01583],
              [8.4255, 50.01576],
              [8.42524, 50.01573],
              [8.42455, 50.01542],
              [8.424022, 50.015252],
              [8.42345, 50.01507],
              [8.4227, 50.01504],
              [8.42209, 50.01512],
              [8.42195, 50.01526],
              [8.42185, 50.01525],
              [8.42177, 50.01563],
              [8.42196, 50.0161],
              [8.42244, 50.01664],
              [8.42315, 50.01703],
              [8.422577, 50.017487],
              [8.42216, 50.01782],
              [8.422, 50.01794],
              [8.42068, 50.01894],
              [8.42045, 50.01911],
              [8.41972, 50.01968],
              [8.41939, 50.01993],
              [8.41906, 50.02018],
              [8.41772, 50.02146],
              [8.41756, 50.02163],
              [8.41606, 50.02359],
              [8.41557, 50.02425],
              [8.41434, 50.02593],
              [8.41396, 50.02626],
              [8.41323, 50.02654],
              [8.41195, 50.02685],
              [8.411, 50.02708],
              [8.41077, 50.02713],
              [8.40931, 50.02759],
              [8.40897, 50.02774],
              [8.40733, 50.02856],
              [8.40678, 50.02877],
              [8.40633, 50.02819],
              [8.40589, 50.02757],
              [8.40564, 50.02725],
              [8.4055, 50.0271],
              [8.40535, 50.02692],
              [8.40515, 50.02677],
              [8.40417, 50.02642],
              [8.40349, 50.02635],
              [8.40335, 50.02635],
              [8.40281, 50.02726],
              [8.40225, 50.0281],
              [8.40216, 50.02824],
              [8.40212, 50.0283],
              [8.40195, 50.02854],
              [8.40173, 50.02891],
              [8.4017, 50.02897],
              [8.40258, 50.02924],
              [8.40399, 50.02926],
              [8.4042, 50.02938],
              [8.40404, 50.02945],
              [8.40347, 50.0297],
              [8.40276, 50.03004],
              [8.40231, 50.03023],
              [8.40155, 50.03055],
              [8.40111, 50.03067],
              [8.40073, 50.03077],
              [8.39962, 50.03113],
              [8.39843, 50.03171],
              [8.39831, 50.03178],
              [8.39692, 50.0329],
              [8.39558, 50.03446],
              [8.39427, 50.03541],
              [8.39348, 50.03646],
              [8.39272, 50.03765],
              [8.39185, 50.03949],
              [8.39146, 50.03995],
              [8.39045, 50.04049],
              [8.38945, 50.04105],
              [8.38935, 50.04111],
              [8.3884, 50.04165],
              [8.38769, 50.04203],
              [8.38759, 50.04208],
              [8.38747, 50.04211],
              [8.38638, 50.04272],
              [8.38562, 50.0433],
              [8.3843, 50.04422],
              [8.38283, 50.04556],
              [8.38142, 50.04688],
              [8.3788, 50.04892],
              [8.37829, 50.04929],
              [8.37791, 50.04954],
              [8.3769, 50.05021],
              [8.37565, 50.0511],
              [8.3754, 50.05129],
              [8.37513, 50.05148],
              [8.37435, 50.05204],
              [8.3742, 50.05214],
              [8.3739, 50.05236],
              [8.37381, 50.05243],
              [8.3734, 50.05272],
              [8.3731, 50.05294],
              [8.37299, 50.05302],
              [8.372276, 50.053541],
              [8.371776, 50.053906],
              [8.37177, 50.05391],
              [8.37124, 50.05431],
              [8.37106, 50.05444],
              [8.37075, 50.054669],
              [8.3703, 50.05514],
              [8.3698, 50.0566],
              [8.36977, 50.05668],
              [8.36971, 50.05691],
              [8.36956, 50.05737],
              [8.36947, 50.05784],
              [8.36965, 50.05843],
              [8.37014, 50.05892],
              [8.37048, 50.05875],
              [8.37087, 50.05842],
              [8.36979, 50.05784],
              [8.36976, 50.05776],
              [8.37001, 50.0573],
              [8.370128, 50.057227],
            ],
          ],
        },
        id: "Q4ODI",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.370128, 50.057227],
              [8.37038, 50.05707],
              [8.37172, 50.05633],
              [8.37226, 50.05619],
              [8.37329, 50.05613],
              [8.37533, 50.05625],
              [8.37556, 50.05638],
              [8.37574, 50.05704],
              [8.37549, 50.05724],
              [8.37374, 50.05854],
              [8.3725, 50.05954],
              [8.3713, 50.05932],
              [8.37069, 50.05887],
              [8.37014, 50.05892],
              [8.36965, 50.05843],
              [8.36947, 50.05784],
              [8.36956, 50.05737],
              [8.36971, 50.05691],
              [8.36977, 50.05668],
              [8.3698, 50.0566],
              [8.3703, 50.05514],
              [8.37075, 50.054669],
              [8.37106, 50.05444],
              [8.37124, 50.05431],
              [8.37177, 50.05391],
              [8.371776, 50.053906],
              [8.372276, 50.053541],
              [8.37299, 50.05302],
              [8.3731, 50.05294],
              [8.3734, 50.05272],
              [8.37381, 50.05243],
              [8.3739, 50.05236],
              [8.3742, 50.05214],
              [8.37435, 50.05204],
              [8.37513, 50.05148],
              [8.3754, 50.05129],
              [8.37565, 50.0511],
              [8.3769, 50.05021],
              [8.37791, 50.04954],
              [8.37829, 50.04929],
              [8.3788, 50.04892],
              [8.38142, 50.04688],
              [8.38283, 50.04556],
              [8.3843, 50.04422],
              [8.38562, 50.0433],
              [8.38638, 50.04272],
              [8.38747, 50.04211],
              [8.38759, 50.04208],
              [8.38769, 50.04203],
              [8.3884, 50.04165],
              [8.38935, 50.04111],
              [8.38945, 50.04105],
              [8.39045, 50.04049],
              [8.39146, 50.03995],
              [8.39185, 50.03949],
              [8.39272, 50.03765],
              [8.39348, 50.03646],
              [8.39427, 50.03541],
              [8.39558, 50.03446],
              [8.39692, 50.0329],
              [8.39831, 50.03178],
              [8.39843, 50.03171],
              [8.39962, 50.03113],
              [8.40073, 50.03077],
              [8.40111, 50.03067],
              [8.40155, 50.03055],
              [8.40231, 50.03023],
              [8.40276, 50.03004],
              [8.40347, 50.0297],
              [8.40404, 50.02945],
              [8.4042, 50.02938],
              [8.40399, 50.02926],
              [8.40258, 50.02924],
              [8.4017, 50.02897],
              [8.40173, 50.02891],
              [8.40195, 50.02854],
              [8.40212, 50.0283],
              [8.40216, 50.02824],
              [8.40225, 50.0281],
              [8.40281, 50.02726],
              [8.40335, 50.02635],
              [8.40349, 50.02635],
              [8.40417, 50.02642],
              [8.40515, 50.02677],
              [8.40535, 50.02692],
              [8.4055, 50.0271],
              [8.40564, 50.02725],
              [8.40589, 50.02757],
              [8.40633, 50.02819],
              [8.40678, 50.02877],
              [8.40733, 50.02856],
              [8.40897, 50.02774],
              [8.40931, 50.02759],
              [8.41077, 50.02713],
              [8.411, 50.02708],
              [8.41195, 50.02685],
              [8.41323, 50.02654],
              [8.41396, 50.02626],
              [8.41434, 50.02593],
              [8.41557, 50.02425],
              [8.41606, 50.02359],
              [8.41756, 50.02163],
              [8.41772, 50.02146],
              [8.41906, 50.02018],
              [8.41939, 50.01993],
              [8.41972, 50.01968],
              [8.42045, 50.01911],
              [8.42068, 50.01894],
              [8.422, 50.01794],
              [8.42216, 50.01782],
              [8.422577, 50.017487],
              [8.42315, 50.01703],
              [8.42244, 50.01664],
              [8.42196, 50.0161],
              [8.42177, 50.01563],
              [8.42185, 50.01525],
              [8.42195, 50.01526],
              [8.42209, 50.01512],
              [8.4227, 50.01504],
              [8.42345, 50.01507],
              [8.424022, 50.015252],
              [8.42455, 50.01542],
              [8.42524, 50.01573],
              [8.4255, 50.01576],
              [8.42553, 50.01583],
              [8.42589, 50.01575],
              [8.42709, 50.01523],
              [8.4281, 50.0156],
              [8.42841, 50.01577],
              [8.42934, 50.01653],
              [8.42965, 50.01678],
              [8.430044, 50.017108],
            ],
          ],
        },
        id: "UxMTk",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "EyMTA",
    name: "802 Hochheim <> Wallau_Ikea",
    lineName: "802",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.370128, 50.057227],
              [8.37038, 50.05707],
              [8.37172, 50.05633],
              [8.37226, 50.05619],
              [8.37329, 50.05613],
              [8.37533, 50.05625],
              [8.37556, 50.05638],
              [8.37574, 50.05704],
              [8.37549, 50.05724],
              [8.37374, 50.05854],
              [8.3725, 50.05954],
              [8.3713, 50.05932],
              [8.37069, 50.05887],
              [8.37014, 50.05892],
              [8.36965, 50.05843],
              [8.36947, 50.05784],
              [8.36956, 50.05737],
              [8.36971, 50.05691],
              [8.36977, 50.05668],
              [8.3698, 50.0566],
              [8.3703, 50.05514],
              [8.37075, 50.054669],
              [8.37106, 50.05444],
              [8.37124, 50.05431],
              [8.37177, 50.05391],
              [8.371776, 50.053906],
              [8.372276, 50.053541],
              [8.37299, 50.05302],
              [8.3731, 50.05294],
              [8.3734, 50.05272],
              [8.37381, 50.05243],
              [8.3739, 50.05236],
              [8.3742, 50.05214],
              [8.37435, 50.05204],
              [8.37513, 50.05148],
              [8.3754, 50.05129],
              [8.37565, 50.0511],
              [8.3769, 50.05021],
              [8.37791, 50.04954],
              [8.37829, 50.04929],
              [8.3788, 50.04892],
              [8.38142, 50.04688],
              [8.38283, 50.04556],
              [8.3843, 50.04422],
              [8.38562, 50.0433],
              [8.38638, 50.04272],
              [8.38747, 50.04211],
              [8.38759, 50.04208],
              [8.38743, 50.04193],
              [8.38663, 50.04121],
              [8.38642, 50.04101],
              [8.3862, 50.0408],
              [8.38538, 50.04018],
              [8.38495, 50.03985],
              [8.38392, 50.03909],
              [8.38318, 50.03876],
              [8.38212, 50.03787],
              [8.38171, 50.03732],
              [8.38154, 50.03696],
              [8.38107, 50.03605],
              [8.38072, 50.03571],
              [8.37916, 50.03478],
              [8.37696, 50.03359],
              [8.37569, 50.03312],
              [8.37531, 50.03299],
              [8.37327, 50.03223],
              [8.37093, 50.03107],
              [8.36557, 50.02795],
              [8.36519, 50.02768],
              [8.36487, 50.02735],
              [8.36457, 50.02701],
              [8.36191, 50.02404],
              [8.3602, 50.02198],
              [8.35999, 50.02173],
              [8.359709, 50.021414],
              [8.359506, 50.021184],
              [8.35921, 50.02085],
              [8.35898, 50.02061],
              [8.358307, 50.019769],
              [8.3575, 50.01876],
              [8.35757, 50.01864],
              [8.35711, 50.01803],
              [8.35679, 50.01758],
              [8.35659, 50.01756],
              [8.35648, 50.01741],
              [8.35636, 50.01727],
              [8.3566, 50.01709],
              [8.35678, 50.01696],
              [8.357216, 50.01653],
              [8.35746, 50.01629],
              [8.35768, 50.01605],
              [8.35781, 50.01592],
              [8.35896, 50.01463],
              [8.35905, 50.01452],
              [8.35944, 50.01408],
              [8.35992, 50.01359],
              [8.36265, 50.01461],
              [8.363663, 50.014991],
              [8.36478, 50.01541],
              [8.36535, 50.01562],
              [8.36754, 50.01644],
              [8.36819, 50.01669],
              [8.36916, 50.01705],
              [8.36958, 50.01721],
              [8.371564, 50.01795],
              [8.37159, 50.01796],
              [8.37205, 50.01813],
              [8.37249, 50.01829],
              [8.371614, 50.019249],
              [8.37045, 50.020177],
              [8.367517, 50.018986],
              [8.36851, 50.01796],
              [8.36804, 50.01778],
              [8.36739, 50.01753],
              [8.3668, 50.01731],
              [8.3645, 50.01656],
              [8.36231, 50.01575],
              [8.36169, 50.01552],
              [8.35905, 50.01452],
              [8.35828, 50.01424],
              [8.35755, 50.01397],
              [8.35717, 50.01399],
              [8.35688, 50.01399],
              [8.35679, 50.01363],
              [8.35674, 50.01339],
              [8.35663, 50.01286],
              [8.3569, 50.01248],
              [8.35581, 50.01205],
              [8.35468, 50.01162],
              [8.3543, 50.01148],
              [8.35336, 50.01115],
              [8.35265, 50.01099],
              [8.35217, 50.01099],
              [8.35208, 50.01057],
              [8.35211, 50.00997],
              [8.35205, 50.00971],
              [8.35186, 50.00931],
              [8.3523, 50.00905],
              [8.35273, 50.00851],
              [8.35279, 50.00824],
              [8.35287, 50.00784],
              [8.35272, 50.00721],
              [8.35243, 50.00683],
              [8.35208, 50.00626],
              [8.3514, 50.00462],
              [8.350616, 50.004676],
            ],
          ],
        },
        id: "EyMTA",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.350616, 50.004676],
              [8.34718, 50.00492],
              [8.34442, 50.00515],
              [8.34392, 50.00614],
              [8.34356, 50.00637],
              [8.34303, 50.00657],
              [8.34283, 50.00678],
              [8.3416, 50.00948],
              [8.3412, 50.0098],
              [8.34043, 50.01013],
              [8.34011, 50.01037],
              [8.34002, 50.01079],
              [8.34136, 50.01097],
              [8.34195, 50.01099],
              [8.34321, 50.01101],
              [8.34343, 50.011],
              [8.34485, 50.01091],
              [8.344906, 50.010919],
              [8.34525, 50.01097],
              [8.34544, 50.01212],
              [8.34706, 50.01236],
              [8.34783, 50.01249],
              [8.34845, 50.01259],
              [8.34943, 50.01267],
              [8.35004, 50.01252],
              [8.35118, 50.01228],
              [8.35148, 50.01222],
              [8.35276, 50.01195],
              [8.3534, 50.0118],
              [8.35434, 50.01167],
              [8.35468, 50.01162],
              [8.35581, 50.01205],
              [8.3569, 50.01248],
              [8.35704, 50.01254],
              [8.35778, 50.0128],
              [8.35992, 50.01359],
              [8.36037, 50.01309],
              [8.36082, 50.01255],
              [8.36089, 50.01244],
              [8.36116, 50.01162],
              [8.36112, 50.01102],
              [8.36085, 50.01019],
              [8.36089, 50.01004],
              [8.36166, 50.0099],
              [8.36203, 50.00985],
              [8.3646, 50.00948],
              [8.36701, 50.00866],
              [8.3684, 50.0083],
              [8.36858, 50.00873],
              [8.36869, 50.00982],
              [8.36833, 50.01027],
              [8.36702, 50.00977],
              [8.36587, 50.01104],
              [8.36568, 50.01125],
              [8.36557, 50.01137],
              [8.36531, 50.01166],
              [8.36495, 50.01207],
              [8.36359, 50.01359],
              [8.363262, 50.013946],
              [8.36265, 50.01461],
              [8.363663, 50.014991],
              [8.36478, 50.01541],
              [8.36535, 50.01562],
              [8.36754, 50.01644],
              [8.36819, 50.01669],
              [8.36916, 50.01705],
              [8.36958, 50.01721],
              [8.371564, 50.01795],
              [8.37159, 50.01796],
              [8.37205, 50.01813],
              [8.37249, 50.01829],
              [8.371614, 50.019249],
              [8.37045, 50.020177],
              [8.367517, 50.018986],
              [8.36851, 50.01796],
              [8.36804, 50.01778],
              [8.36739, 50.01753],
              [8.3668, 50.01731],
              [8.36754, 50.01644],
              [8.36535, 50.01562],
              [8.36478, 50.01541],
              [8.363663, 50.014991],
              [8.36265, 50.01461],
              [8.35992, 50.01359],
              [8.35944, 50.01408],
              [8.35905, 50.01452],
              [8.35896, 50.01463],
              [8.35781, 50.01592],
              [8.35768, 50.01605],
              [8.35746, 50.01629],
              [8.357216, 50.01653],
              [8.35678, 50.01696],
              [8.3566, 50.01709],
              [8.35636, 50.01727],
              [8.35648, 50.01741],
              [8.35659, 50.01756],
              [8.35679, 50.01758],
              [8.35711, 50.01803],
              [8.35757, 50.01864],
              [8.3575, 50.01876],
              [8.358307, 50.019769],
              [8.35898, 50.02061],
              [8.35921, 50.02085],
              [8.359506, 50.021184],
              [8.359709, 50.021414],
              [8.35999, 50.02173],
              [8.3602, 50.02198],
              [8.36191, 50.02404],
              [8.36457, 50.02701],
              [8.36487, 50.02735],
              [8.36519, 50.02768],
              [8.36557, 50.02795],
              [8.37093, 50.03107],
              [8.37327, 50.03223],
              [8.37531, 50.03299],
              [8.37569, 50.03312],
              [8.37696, 50.03359],
              [8.37916, 50.03478],
              [8.38072, 50.03571],
              [8.38107, 50.03605],
              [8.38154, 50.03696],
              [8.38171, 50.03732],
              [8.38212, 50.03787],
              [8.38318, 50.03876],
              [8.38392, 50.03909],
              [8.38495, 50.03985],
              [8.38538, 50.04018],
              [8.3862, 50.0408],
              [8.38642, 50.04101],
              [8.38663, 50.04121],
              [8.38743, 50.04193],
              [8.38759, 50.04208],
              [8.38747, 50.04211],
              [8.38638, 50.04272],
              [8.38562, 50.0433],
              [8.3843, 50.04422],
              [8.38283, 50.04556],
              [8.38142, 50.04688],
              [8.3788, 50.04892],
              [8.37829, 50.04929],
              [8.37791, 50.04954],
              [8.3769, 50.05021],
              [8.37565, 50.0511],
              [8.3754, 50.05129],
              [8.37513, 50.05148],
              [8.37435, 50.05204],
              [8.3742, 50.05214],
              [8.3739, 50.05236],
              [8.37381, 50.05243],
              [8.3734, 50.05272],
              [8.3731, 50.05294],
              [8.37299, 50.05302],
              [8.372276, 50.053541],
              [8.371776, 50.053906],
              [8.37177, 50.05391],
              [8.37124, 50.05431],
              [8.37106, 50.05444],
              [8.37075, 50.054669],
              [8.3703, 50.05514],
              [8.3698, 50.0566],
              [8.36977, 50.05668],
              [8.36971, 50.05691],
              [8.36956, 50.05737],
              [8.36947, 50.05784],
              [8.36965, 50.05843],
              [8.37014, 50.05892],
              [8.37048, 50.05875],
              [8.37087, 50.05842],
              [8.36979, 50.05784],
              [8.36976, 50.05776],
              [8.37001, 50.0573],
              [8.370128, 50.057227],
            ],
          ],
        },
        id: "c0MTE",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "IxNDk",
    name: "275 Wiesbaden <> Katzenelnbogen",
    lineName: "275",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [7.972367, 50.264485],
              [7.972481, 50.264567],
              [7.97308, 50.265],
              [7.97389, 50.26547],
              [7.9763, 50.26622],
              [7.97642, 50.26632],
              [7.97666, 50.26717],
              [7.97664, 50.26742],
              [7.97665, 50.26752],
              [7.97687, 50.26768],
              [7.97698, 50.26771],
              [7.97704, 50.26762],
              [7.97734, 50.2675],
              [7.97811, 50.26715],
              [7.97884, 50.26674],
              [7.978922, 50.26652],
              [7.97946, 50.26508],
              [7.97973, 50.26482],
              [7.98043, 50.26458],
              [7.98252, 50.26451],
              [7.98425, 50.26436],
              [7.98565, 50.26401],
              [7.98602, 50.26385],
              [7.98676, 50.26353],
              [7.98736, 50.26321],
              [7.98922, 50.26381],
              [7.99038, 50.2645],
              [7.99235, 50.26565],
              [7.99293, 50.26595],
              [7.99356, 50.26625],
              [7.99416, 50.26649],
              [7.99542, 50.26696],
              [7.99637, 50.26731],
              [7.997114, 50.26761],
              [7.99714, 50.26762],
              [7.99739, 50.26783],
              [7.99776, 50.26796],
              [7.99829, 50.26793],
              [7.99895, 50.26804],
              [7.99976, 50.26836],
              [8.00009, 50.26856],
              [8.00153, 50.26933],
              [8.00164, 50.26897],
              [8.00175, 50.26868],
              [8.00224, 50.26746],
              [8.00226, 50.26741],
              [8.00254, 50.26679],
              [8.00265, 50.26651],
              [8.0027, 50.26641],
              [8.00278, 50.2662],
              [8.00281, 50.26613],
              [8.0029, 50.26591],
              [8.003, 50.26566],
              [8.00311, 50.26457],
              [8.00316, 50.26444],
              [8.00472, 50.26191],
              [8.00483, 50.26174],
              [8.00505, 50.2612],
              [8.00503, 50.26094],
              [8.00498, 50.26071],
              [8.00486, 50.26003],
              [8.00534, 50.25964],
              [8.00582, 50.2594],
              [8.00609, 50.259],
              [8.00624, 50.25886],
              [8.00633, 50.25865],
              [8.00664, 50.25848],
              [8.00703, 50.25828],
              [8.00761, 50.25755],
              [8.00775, 50.25721],
              [8.00819, 50.25681],
              [8.00832, 50.25645],
              [8.00812, 50.25552],
              [8.0082, 50.25504],
              [8.00918, 50.25288],
              [8.00925, 50.2524],
              [8.0095, 50.25191],
              [8.00954, 50.25165],
              [8.00982, 50.25101],
              [8.01121, 50.25029],
              [8.01169, 50.24989],
              [8.01223, 50.24941],
              [8.01213, 50.24931],
              [8.01214, 50.24899],
              [8.01197, 50.24876],
              [8.01167, 50.24859],
              [8.01131, 50.24841],
              [8.01062, 50.2478],
              [8.01018, 50.24741],
              [8.01003, 50.24728],
              [8.00989, 50.24713],
              [8.01002, 50.24707],
              [8.01039, 50.24689],
              [8.01091, 50.24637],
              [8.01111, 50.24628],
              [8.01153, 50.24587],
              [8.01302, 50.24489],
              [8.01323, 50.2447],
              [8.01454, 50.24333],
              [8.01515, 50.24253],
              [8.01585, 50.2416],
              [8.01659, 50.24088],
              [8.01763, 50.24006],
              [8.01813, 50.23976],
              [8.01824, 50.2397],
              [8.01834, 50.23954],
              [8.01828, 50.23873],
              [8.01818, 50.23806],
              [8.01813, 50.23761],
              [8.0178, 50.23655],
              [8.01773, 50.2361],
              [8.01817, 50.23413],
              [8.01849, 50.23326],
              [8.01981, 50.23192],
              [8.0204, 50.23132],
              [8.02047, 50.23119],
              [8.02062, 50.23058],
              [8.02041, 50.22999],
              [8.02074, 50.22977],
              [8.02077, 50.22969],
              [8.0208, 50.22963],
              [8.02069, 50.22935],
              [8.02064, 50.2293],
              [8.02042, 50.22907],
              [8.02031, 50.22853],
              [8.02051, 50.22827],
              [8.02091, 50.22827],
              [8.02091, 50.22827],
              [8.02051, 50.22827],
              [8.02031, 50.22853],
              [8.02042, 50.22907],
              [8.02064, 50.2293],
              [8.02069, 50.22935],
              [8.0208, 50.22963],
              [8.02077, 50.22969],
              [8.02074, 50.22977],
              [8.02041, 50.22999],
              [8.01922, 50.229],
              [8.01892, 50.22851],
              [8.01885, 50.22764],
              [8.01865, 50.22717],
              [8.01807, 50.22663],
              [8.01504, 50.22542],
              [8.01135, 50.22289],
              [8.01057, 50.22239],
              [8.01018, 50.22212],
              [8.00894, 50.22069],
              [8.00825, 50.2203],
              [8.00729, 50.22001],
              [8.00684, 50.21996],
              [8.00575, 50.21938],
              [8.00499, 50.21895],
              [8.00359, 50.21809],
              [8.00278, 50.21759],
              [8.00211, 50.21718],
              [8.00174, 50.21696],
              [8.00136, 50.21673],
              [8.00092, 50.21645],
              [8.00037, 50.2161],
              [7.99982, 50.21573],
              [7.99807, 50.21467],
              [7.99767, 50.21444],
              [7.99729, 50.21418],
              [7.99711, 50.21396],
              [7.99663, 50.21318],
              [7.99628, 50.21274],
              [7.99629, 50.21266],
              [7.9964, 50.21233],
              [7.99617, 50.2117],
              [7.9963, 50.21151],
              [7.99649, 50.21139],
              [7.99745, 50.21146],
              [7.99768, 50.21148],
              [7.99792, 50.21148],
              [7.99844, 50.21147],
              [7.99936, 50.21122],
              [7.99979, 50.21079],
              [8.00052, 50.20973],
              [8.00055, 50.20887],
              [8.00066, 50.20853],
              [8.00137, 50.2083],
              [8.00254, 50.20797],
              [8.00407, 50.20703],
              [8.00549, 50.20671],
              [8.00604, 50.20646],
              [8.00634, 50.20584],
              [8.00659, 50.20507],
              [8.00707, 50.20449],
              [8.00783, 50.20371],
              [8.00808, 50.20344],
              [8.00822, 50.20312],
              [8.00812, 50.20264],
              [8.00789, 50.20229],
              [8.00739, 50.20134],
              [8.00739, 50.20083],
              [8.00789, 50.20007],
              [8.00833, 50.1995],
              [8.00862, 50.19912],
              [8.00937, 50.19767],
              [8.00992, 50.19606],
              [8.01006, 50.19534],
              [8.01057, 50.19345],
              [8.0105, 50.19221],
              [8.01051, 50.19055],
              [8.0106, 50.18975],
              [8.00965, 50.18993],
              [8.00918, 50.19003],
              [8.00853, 50.19016],
              [8.00701, 50.19045],
              [8.00788, 50.19123],
              [8.00824, 50.19155],
              [8.0084, 50.1916],
              [8.00911, 50.19085],
              [8.00918, 50.19003],
              [8.00965, 50.18993],
              [8.0106, 50.18975],
              [8.0106, 50.18939],
              [8.01005, 50.18848],
              [8.00995, 50.18809],
              [8.01033, 50.18648],
              [8.01064, 50.18582],
              [8.01085, 50.18543],
              [8.01112, 50.18503],
              [8.0117, 50.18415],
              [8.01181, 50.18343],
              [8.01177, 50.18253],
              [8.01154, 50.18096],
              [8.01136, 50.17997],
              [8.01099, 50.17922],
              [8.01045, 50.17789],
              [8.01034, 50.17624],
              [8.011, 50.1749],
              [8.01149, 50.17381],
              [8.01172, 50.17316],
              [8.01209, 50.17218],
              [8.01225, 50.17169],
              [8.01279, 50.17038],
              [8.01302, 50.16974],
              [8.01314, 50.16947],
              [8.01354, 50.16903],
              [8.0135, 50.16897],
              [8.01356, 50.1687],
              [8.01363, 50.16857],
              [8.0137, 50.16846],
              [8.01388, 50.16818],
              [8.01429, 50.16754],
              [8.01461, 50.16712],
              [8.01546, 50.16622],
              [8.01559, 50.16605],
              [8.01616, 50.16545],
              [8.01663, 50.16519],
              [8.01687, 50.16525],
              [8.01745, 50.16528],
              [8.01826, 50.16528],
              [8.01883, 50.16495],
              [8.01888, 50.16485],
              [8.01893, 50.16408],
              [8.01895, 50.1639],
              [8.0192, 50.16323],
              [8.01959, 50.16268],
              [8.01987, 50.16232],
              [8.02008, 50.16206],
              [8.020468, 50.161597],
              [8.02081, 50.16119],
              [8.02092, 50.16106],
              [8.02114, 50.1608],
              [8.02163, 50.16019],
              [8.02217, 50.15985],
              [8.02264, 50.15986],
              [8.02217, 50.15985],
              [8.02163, 50.16019],
              [8.02125, 50.15998],
              [8.02127, 50.15988],
              [8.021496, 50.15966],
              [8.02127, 50.15988],
              [8.02125, 50.15998],
              [8.02163, 50.16019],
              [8.02217, 50.15985],
              [8.02264, 50.15986],
              [8.02282, 50.15935],
              [8.023, 50.15897],
              [8.02364, 50.15806],
              [8.02571, 50.15603],
              [8.02743, 50.1545],
              [8.0292, 50.15301],
              [8.03061, 50.15201],
              [8.03278, 50.15079],
              [8.03365, 50.1504],
              [8.03771, 50.14867],
              [8.03797, 50.14906],
              [8.04335, 50.14854],
              [8.0438, 50.14823],
              [8.04388, 50.14783],
              [8.04383, 50.14742],
              [8.043037, 50.146376],
              [8.04203, 50.14505],
              [8.04174, 50.14476],
              [8.04201, 50.14441],
              [8.04259, 50.14357],
              [8.04297, 50.1433],
              [8.04529, 50.14293],
              [8.046262, 50.142977],
              [8.04675, 50.143],
              [8.04918, 50.14252],
              [8.04989, 50.14213],
              [8.04965, 50.14171],
              [8.05509, 50.14087],
              [8.05581, 50.14078],
              [8.0572, 50.14087],
              [8.0572, 50.14099],
              [8.05727, 50.14104],
              [8.05712, 50.14121],
              [8.05658, 50.14123],
              [8.05712, 50.14121],
              [8.05727, 50.14104],
              [8.05737, 50.14106],
              [8.05757, 50.14093],
              [8.058361, 50.140974],
              [8.05902, 50.14101],
              [8.0603, 50.14115],
              [8.06152, 50.14149],
              [8.06258, 50.14182],
              [8.0633, 50.14202],
              [8.06447, 50.14236],
              [8.06459, 50.1424],
              [8.06787, 50.14338],
              [8.06852, 50.14324],
              [8.06861, 50.14319],
              [8.06871, 50.14312],
              [8.06882, 50.14295],
              [8.06882, 50.14241],
              [8.0688, 50.14226],
              [8.06867, 50.14152],
              [8.06856, 50.14135],
              [8.0682, 50.14112],
              [8.06754, 50.14087],
              [8.06715, 50.14073],
              [8.06664, 50.14041],
              [8.06699, 50.14028],
              [8.06721, 50.14014],
              [8.06804, 50.13952],
              [8.06816, 50.13942],
              [8.06821, 50.13938],
              [8.06836, 50.13925],
              [8.06882, 50.13887],
              [8.06912, 50.13861],
              [8.06915, 50.13854],
              [8.06918, 50.13843],
              [8.06861, 50.1374],
              [8.06857, 50.13722],
              [8.06855, 50.13707],
              [8.06821, 50.13661],
              [8.06779, 50.13621],
              [8.06702, 50.13557],
              [8.06537, 50.13447],
              [8.06513, 50.13426],
              [8.0649, 50.13407],
              [8.06437, 50.13362],
              [8.06382, 50.13305],
              [8.06383, 50.13286],
              [8.06414, 50.13275],
              [8.06514, 50.13298],
              [8.06636, 50.1331],
              [8.06671, 50.13306],
              [8.06707, 50.13299],
              [8.06741, 50.13291],
              [8.06798, 50.13279],
              [8.06874, 50.13263],
              [8.07051, 50.13223],
              [8.07359, 50.13197],
              [8.07554, 50.13127],
              [8.07705, 50.12991],
              [8.08117, 50.12598],
              [8.08251, 50.12464],
              [8.08279, 50.12397],
              [8.08284, 50.12336],
              [8.08286, 50.123],
              [8.08305, 50.12282],
              [8.08334, 50.1228],
              [8.08338, 50.12277],
              [8.08336, 50.12261],
              [8.08306, 50.12257],
              [8.08268, 50.12221],
              [8.08232, 50.12186],
              [8.0819, 50.12132],
              [8.08166, 50.1203],
              [8.08088, 50.1192],
              [8.08081, 50.11889],
              [8.081, 50.11881],
              [8.08221, 50.1187],
              [8.08269, 50.11869],
              [8.08359, 50.11861],
              [8.08526, 50.11834],
              [8.08626, 50.11819],
              [8.0905, 50.11795],
              [8.0925, 50.1178],
              [8.09381, 50.11749],
              [8.09488, 50.117],
              [8.09563, 50.11641],
              [8.09591, 50.11609],
              [8.09604, 50.11592],
              [8.09632, 50.11533],
              [8.09637, 50.1151],
              [8.09639, 50.11465],
              [8.09608, 50.11227],
              [8.09604, 50.11186],
              [8.09626, 50.11111],
              [8.09662, 50.11023],
              [8.096958, 50.109527],
              [8.09712, 50.10919],
              [8.09742, 50.10893],
              [8.09801, 50.10853],
              [8.09854, 50.10812],
              [8.09925, 50.10763],
              [8.09961, 50.10738],
              [8.10039, 50.10683],
              [8.10131, 50.10618],
              [8.101684, 50.105869],
              [8.102216, 50.104795],
              [8.10237, 50.10444],
              [8.10246, 50.10428],
              [8.102758, 50.103982],
              [8.10308, 50.10366],
              [8.10328, 50.10351],
              [8.10371, 50.1032],
              [8.10518, 50.10207],
              [8.10564, 50.10138],
              [8.10536, 50.10118],
              [8.10491, 50.10061],
              [8.10391, 50.09887],
              [8.10392, 50.0983],
              [8.10392, 50.09732],
              [8.10393, 50.09675],
              [8.10406, 50.09593],
              [8.10395, 50.09561],
              [8.10377, 50.09529],
              [8.10332, 50.09448],
              [8.10298, 50.09408],
              [8.10332, 50.09448],
              [8.10377, 50.09529],
              [8.10395, 50.09561],
              [8.10406, 50.09593],
              [8.10471, 50.09595],
              [8.10521, 50.09576],
              [8.10597, 50.09435],
              [8.10606, 50.09353],
              [8.10627, 50.09311],
              [8.1074, 50.09225],
              [8.10796, 50.09184],
              [8.1093, 50.09087],
              [8.10982, 50.09016],
              [8.11033, 50.08964],
              [8.11135, 50.08911],
              [8.11267, 50.08822],
              [8.1133, 50.08799],
              [8.11422, 50.08801],
              [8.11565, 50.08813],
              [8.11607, 50.08821],
              [8.11617, 50.08823],
              [8.11678, 50.08837],
              [8.11768, 50.08861],
              [8.11869, 50.08883],
              [8.11891, 50.08883],
              [8.11928, 50.0888],
              [8.12049, 50.08861],
              [8.12084, 50.08851],
              [8.121362, 50.088359],
              [8.12239, 50.08806],
              [8.12334, 50.08783],
              [8.12392, 50.08771],
              [8.12404, 50.08767],
              [8.12491, 50.08744],
              [8.12544, 50.08748],
              [8.12572, 50.08766],
              [8.12596, 50.08796],
              [8.12775, 50.08964],
              [8.12877, 50.0903],
              [8.12984, 50.09077],
              [8.13167, 50.09122],
              [8.13211, 50.0913],
              [8.13888, 50.09258],
              [8.14078, 50.09323],
              [8.14197, 50.09404],
              [8.14293, 50.09437],
              [8.14346, 50.0944],
              [8.14403, 50.09434],
              [8.14631, 50.09353],
              [8.1476, 50.09339],
              [8.14886, 50.09354],
              [8.15128, 50.09397],
              [8.15427, 50.0942],
              [8.1565, 50.09484],
              [8.158, 50.09539],
              [8.15994, 50.09642],
              [8.16206, 50.09695],
              [8.16485, 50.09749],
              [8.16538, 50.09749],
              [8.16624, 50.09739],
              [8.16751, 50.09736],
              [8.16793, 50.09744],
              [8.16821, 50.09748],
              [8.16859, 50.09735],
              [8.16884, 50.09726],
              [8.16956, 50.09712],
              [8.16975, 50.09714],
              [8.16996, 50.09718],
              [8.17061, 50.09734],
              [8.17069, 50.09736],
              [8.17237, 50.09776],
              [8.17343, 50.09798],
              [8.17475, 50.09808],
              [8.17865, 50.09775],
              [8.18369, 50.09723],
              [8.18571, 50.09686],
              [8.18606, 50.09676],
              [8.18784, 50.09623],
              [8.19045, 50.09532],
              [8.19069, 50.09523],
              [8.19275, 50.09449],
              [8.19415, 50.09438],
              [8.19563, 50.09438],
              [8.19678, 50.09438],
              [8.19814, 50.09438],
              [8.19832, 50.09439],
              [8.20092, 50.09446],
              [8.20189, 50.09425],
              [8.20235, 50.09409],
              [8.20429, 50.09337],
              [8.20731, 50.09091],
              [8.20814, 50.08999],
              [8.20899, 50.08856],
              [8.21016, 50.08631],
              [8.20899, 50.08856],
              [8.20814, 50.08999],
              [8.20731, 50.09091],
              [8.20429, 50.09337],
              [8.20731, 50.09091],
              [8.20814, 50.08999],
              [8.20899, 50.08856],
              [8.21016, 50.08631],
              [8.2108, 50.08524],
              [8.21091, 50.0851],
              [8.21117, 50.0848],
              [8.21175, 50.08428],
              [8.21206, 50.08406],
              [8.21374, 50.08322],
              [8.21522, 50.08265],
              [8.21553, 50.08232],
              [8.21592, 50.08205],
              [8.21659, 50.08186],
              [8.21771, 50.08167],
              [8.21822, 50.08156],
              [8.21862, 50.08145],
              [8.21878, 50.08139],
              [8.21894, 50.08134],
              [8.21922, 50.08124],
              [8.22054, 50.08072],
              [8.22219, 50.07995],
              [8.22227, 50.08002],
              [8.22394, 50.07924],
              [8.22422, 50.07969],
              [8.22433, 50.07999],
              [8.22461, 50.08059],
              [8.224955, 50.080535],
              [8.22604, 50.08036],
              [8.22687, 50.08041],
              [8.22757, 50.08043],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22983, 50.07742],
              [8.22986, 50.07721],
              [8.23019, 50.07716],
              [8.23267, 50.07728],
              [8.234781, 50.07738],
              [8.23519, 50.07739],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
            ],
          ],
        },
        id: "IxNDk",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.243761, 50.071537],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.236676, 50.077684],
              [8.235433, 50.077624],
              [8.235155, 50.07761],
              [8.233622, 50.07753],
              [8.232675, 50.077486],
              [8.23015, 50.07735],
              [8.22997, 50.07829],
              [8.22976, 50.07946],
              [8.22963, 50.08055],
              [8.22949, 50.08052],
              [8.22757, 50.08043],
              [8.22687, 50.08041],
              [8.22604, 50.08036],
              [8.224955, 50.080535],
              [8.22461, 50.08059],
              [8.22433, 50.07999],
              [8.22422, 50.07969],
              [8.22394, 50.07924],
              [8.22227, 50.08002],
              [8.22219, 50.07995],
              [8.22054, 50.08072],
              [8.21922, 50.08124],
              [8.21894, 50.08134],
              [8.21878, 50.08139],
              [8.21862, 50.08145],
              [8.21822, 50.08156],
              [8.21771, 50.08167],
              [8.21659, 50.08186],
              [8.21592, 50.08205],
              [8.21553, 50.08232],
              [8.21522, 50.08265],
              [8.21374, 50.08322],
              [8.21206, 50.08406],
              [8.21175, 50.08428],
              [8.21117, 50.0848],
              [8.21091, 50.0851],
              [8.2108, 50.08524],
              [8.21016, 50.08631],
              [8.20899, 50.08856],
              [8.20814, 50.08999],
              [8.20731, 50.09091],
              [8.20429, 50.09337],
              [8.20235, 50.09409],
              [8.20189, 50.09425],
              [8.20092, 50.09446],
              [8.19832, 50.09439],
              [8.19814, 50.09438],
              [8.19678, 50.09438],
              [8.19563, 50.09438],
              [8.19415, 50.09438],
              [8.19275, 50.09449],
              [8.19069, 50.09523],
              [8.19045, 50.09532],
              [8.18784, 50.09623],
              [8.18606, 50.09676],
              [8.18571, 50.09686],
              [8.18369, 50.09723],
              [8.17865, 50.09775],
              [8.17475, 50.09808],
              [8.17343, 50.09798],
              [8.17237, 50.09776],
              [8.17069, 50.09736],
              [8.17061, 50.09734],
              [8.16996, 50.09718],
              [8.16975, 50.09714],
              [8.16956, 50.09712],
              [8.16884, 50.09726],
              [8.16859, 50.09735],
              [8.16821, 50.09748],
              [8.16793, 50.09744],
              [8.16751, 50.09736],
              [8.16624, 50.09739],
              [8.16538, 50.09749],
              [8.16485, 50.09749],
              [8.16206, 50.09695],
              [8.15994, 50.09642],
              [8.158, 50.09539],
              [8.1565, 50.09484],
              [8.15427, 50.0942],
              [8.15128, 50.09397],
              [8.14886, 50.09354],
              [8.1476, 50.09339],
              [8.14631, 50.09353],
              [8.14403, 50.09434],
              [8.14346, 50.0944],
              [8.14293, 50.09437],
              [8.14197, 50.09404],
              [8.14078, 50.09323],
              [8.13888, 50.09258],
              [8.13211, 50.0913],
              [8.13167, 50.09122],
              [8.12984, 50.09077],
              [8.12877, 50.0903],
              [8.12775, 50.08964],
              [8.12596, 50.08796],
              [8.12572, 50.08766],
              [8.12544, 50.08748],
              [8.12491, 50.08744],
              [8.12404, 50.08767],
              [8.12392, 50.08771],
              [8.12334, 50.08783],
              [8.12239, 50.08806],
              [8.121362, 50.088359],
              [8.12084, 50.08851],
              [8.12049, 50.08861],
              [8.11928, 50.0888],
              [8.11891, 50.08883],
              [8.11869, 50.08883],
              [8.11768, 50.08861],
              [8.11678, 50.08837],
              [8.11617, 50.08823],
              [8.11607, 50.08821],
              [8.11565, 50.08813],
              [8.11422, 50.08801],
              [8.1133, 50.08799],
              [8.11267, 50.08822],
              [8.11135, 50.08911],
              [8.11033, 50.08964],
              [8.10982, 50.09016],
              [8.1093, 50.09087],
              [8.10796, 50.09184],
              [8.1074, 50.09225],
              [8.10627, 50.09311],
              [8.10606, 50.09353],
              [8.10597, 50.09435],
              [8.10521, 50.09576],
              [8.10471, 50.09595],
              [8.10406, 50.09593],
              [8.10395, 50.09561],
              [8.10377, 50.09529],
              [8.10332, 50.09448],
              [8.10298, 50.09408],
              [8.10332, 50.09448],
              [8.10377, 50.09529],
              [8.10395, 50.09561],
              [8.10406, 50.09593],
              [8.10393, 50.09675],
              [8.10392, 50.09732],
              [8.10392, 50.0983],
              [8.10391, 50.09887],
              [8.10491, 50.10061],
              [8.10536, 50.10118],
              [8.10564, 50.10138],
              [8.10518, 50.10207],
              [8.10371, 50.1032],
              [8.10328, 50.10351],
              [8.10308, 50.10366],
              [8.102758, 50.103982],
              [8.10246, 50.10428],
              [8.10237, 50.10444],
              [8.102216, 50.104795],
              [8.101684, 50.105869],
              [8.10131, 50.10618],
              [8.10039, 50.10683],
              [8.09961, 50.10738],
              [8.09925, 50.10763],
              [8.09854, 50.10812],
              [8.09801, 50.10853],
              [8.09742, 50.10893],
              [8.09712, 50.10919],
              [8.096958, 50.109527],
              [8.09662, 50.11023],
              [8.09626, 50.11111],
              [8.09604, 50.11186],
              [8.09608, 50.11227],
              [8.09639, 50.11465],
              [8.09637, 50.1151],
              [8.09632, 50.11533],
              [8.09604, 50.11592],
              [8.09591, 50.11609],
              [8.09563, 50.11641],
              [8.09488, 50.117],
              [8.09381, 50.11749],
              [8.0925, 50.1178],
              [8.0905, 50.11795],
              [8.08626, 50.11819],
              [8.08526, 50.11834],
              [8.08359, 50.11861],
              [8.08269, 50.11869],
              [8.08221, 50.1187],
              [8.08084, 50.11867],
              [8.08081, 50.11889],
              [8.08088, 50.1192],
              [8.08166, 50.1203],
              [8.0819, 50.12132],
              [8.08232, 50.12186],
              [8.08268, 50.12221],
              [8.08306, 50.12257],
              [8.08295, 50.12274],
              [8.08305, 50.12282],
              [8.08286, 50.123],
              [8.08284, 50.12336],
              [8.08279, 50.12397],
              [8.08251, 50.12464],
              [8.08117, 50.12598],
              [8.07705, 50.12991],
              [8.07554, 50.13127],
              [8.07359, 50.13197],
              [8.07051, 50.13223],
              [8.06874, 50.13263],
              [8.06798, 50.13279],
              [8.06741, 50.13291],
              [8.06707, 50.13299],
              [8.06671, 50.13306],
              [8.06636, 50.1331],
              [8.06514, 50.13298],
              [8.06414, 50.13275],
              [8.06383, 50.13286],
              [8.06382, 50.13305],
              [8.06437, 50.13362],
              [8.0649, 50.13407],
              [8.06513, 50.13426],
              [8.06537, 50.13447],
              [8.06702, 50.13557],
              [8.06779, 50.13621],
              [8.06821, 50.13661],
              [8.06855, 50.13707],
              [8.06857, 50.13722],
              [8.06861, 50.1374],
              [8.06918, 50.13843],
              [8.06915, 50.13854],
              [8.06912, 50.13861],
              [8.06882, 50.13887],
              [8.06836, 50.13925],
              [8.06821, 50.13938],
              [8.06816, 50.13942],
              [8.06804, 50.13952],
              [8.06721, 50.14014],
              [8.06699, 50.14028],
              [8.06664, 50.14041],
              [8.06715, 50.14073],
              [8.06754, 50.14087],
              [8.0682, 50.14112],
              [8.06856, 50.14135],
              [8.06867, 50.14152],
              [8.0688, 50.14226],
              [8.06867, 50.14152],
              [8.0688, 50.14226],
              [8.06882, 50.14241],
              [8.06882, 50.14295],
              [8.06871, 50.14312],
              [8.06861, 50.14319],
              [8.06852, 50.14324],
              [8.06787, 50.14338],
              [8.06459, 50.1424],
              [8.06447, 50.14236],
              [8.0633, 50.14202],
              [8.06258, 50.14182],
              [8.06152, 50.14149],
              [8.0603, 50.14115],
              [8.05902, 50.14101],
              [8.058361, 50.140974],
              [8.05757, 50.14093],
              [8.05737, 50.14106],
              [8.05727, 50.14104],
              [8.05712, 50.14121],
              [8.05658, 50.14123],
              [8.05712, 50.14121],
              [8.05727, 50.14104],
              [8.0572, 50.14099],
              [8.0572, 50.14087],
              [8.05581, 50.14078],
              [8.05509, 50.14087],
              [8.04965, 50.14171],
              [8.04989, 50.14213],
              [8.04918, 50.14252],
              [8.04675, 50.143],
              [8.046262, 50.142977],
              [8.04529, 50.14293],
              [8.04297, 50.1433],
              [8.04259, 50.14357],
              [8.04201, 50.14441],
              [8.04174, 50.14476],
              [8.04203, 50.14505],
              [8.043037, 50.146376],
              [8.04383, 50.14742],
              [8.04388, 50.14783],
              [8.0438, 50.14823],
              [8.04335, 50.14854],
              [8.03797, 50.14906],
              [8.03771, 50.14867],
              [8.03365, 50.1504],
              [8.03278, 50.15079],
              [8.03061, 50.15201],
              [8.0292, 50.15301],
              [8.02743, 50.1545],
              [8.02571, 50.15603],
              [8.02364, 50.15806],
              [8.023, 50.15897],
              [8.02282, 50.15935],
              [8.02264, 50.15986],
              [8.02217, 50.15985],
              [8.02163, 50.16019],
              [8.02114, 50.1608],
              [8.02092, 50.16106],
              [8.02081, 50.16119],
              [8.020468, 50.161597],
              [8.02008, 50.16206],
              [8.01987, 50.16232],
              [8.01959, 50.16268],
              [8.0192, 50.16323],
              [8.01895, 50.1639],
              [8.01893, 50.16408],
              [8.01888, 50.16485],
              [8.01883, 50.16495],
              [8.01826, 50.16528],
              [8.01745, 50.16528],
              [8.01687, 50.16525],
              [8.01663, 50.16519],
              [8.01616, 50.16545],
              [8.01559, 50.16605],
              [8.01546, 50.16622],
              [8.01461, 50.16712],
              [8.01429, 50.16754],
              [8.01388, 50.16818],
              [8.0137, 50.16846],
              [8.01363, 50.16857],
              [8.01356, 50.1687],
              [8.0135, 50.16897],
              [8.01354, 50.16903],
              [8.01314, 50.16947],
              [8.01302, 50.16974],
              [8.01279, 50.17038],
              [8.01225, 50.17169],
              [8.01209, 50.17218],
              [8.01172, 50.17316],
              [8.01149, 50.17381],
              [8.011, 50.1749],
              [8.01034, 50.17624],
              [8.01045, 50.17789],
              [8.01099, 50.17922],
              [8.01136, 50.17997],
              [8.01154, 50.18096],
              [8.01177, 50.18253],
              [8.01181, 50.18343],
              [8.0117, 50.18415],
              [8.01112, 50.18503],
              [8.01085, 50.18543],
              [8.01064, 50.18582],
              [8.01033, 50.18648],
              [8.00995, 50.18809],
              [8.01005, 50.18848],
              [8.0106, 50.18939],
              [8.0106, 50.18975],
              [8.00965, 50.18993],
              [8.00918, 50.19003],
              [8.00853, 50.19016],
              [8.00701, 50.19045],
              [8.00788, 50.19123],
              [8.00824, 50.19155],
              [8.0084, 50.1916],
              [8.00911, 50.19085],
              [8.00918, 50.19003],
              [8.00965, 50.18993],
              [8.0106, 50.18975],
              [8.01051, 50.19055],
              [8.0105, 50.19221],
              [8.01057, 50.19345],
              [8.01006, 50.19534],
              [8.00992, 50.19606],
              [8.00937, 50.19767],
              [8.00862, 50.19912],
              [8.00833, 50.1995],
              [8.00789, 50.20007],
              [8.00739, 50.20083],
              [8.00739, 50.20134],
              [8.00789, 50.20229],
              [8.00812, 50.20264],
              [8.00822, 50.20312],
              [8.00808, 50.20344],
              [8.00783, 50.20371],
              [8.00707, 50.20449],
              [8.00659, 50.20507],
              [8.00634, 50.20584],
              [8.00604, 50.20646],
              [8.00549, 50.20671],
              [8.00407, 50.20703],
              [8.00254, 50.20797],
              [8.00137, 50.2083],
              [8.00066, 50.20853],
              [8.00055, 50.20887],
              [8.00052, 50.20973],
              [7.99979, 50.21079],
              [7.99936, 50.21122],
              [7.99844, 50.21147],
              [7.99792, 50.21148],
              [7.99768, 50.21148],
              [7.99745, 50.21146],
              [7.99649, 50.21139],
              [7.9963, 50.21151],
              [7.99617, 50.2117],
              [7.9964, 50.21233],
              [7.99629, 50.21266],
              [7.99628, 50.21274],
              [7.99663, 50.21318],
              [7.99711, 50.21396],
              [7.99729, 50.21418],
              [7.99767, 50.21444],
              [7.99807, 50.21467],
              [7.99982, 50.21573],
              [8.00037, 50.2161],
              [8.00092, 50.21645],
              [8.00136, 50.21673],
              [8.00174, 50.21696],
              [8.00211, 50.21718],
              [8.00278, 50.21759],
              [8.00359, 50.21809],
              [8.00499, 50.21895],
              [8.00575, 50.21938],
              [8.00684, 50.21996],
              [8.00729, 50.22001],
              [8.00825, 50.2203],
              [8.00894, 50.22069],
              [8.01018, 50.22212],
              [8.01057, 50.22239],
              [8.01135, 50.22289],
              [8.01504, 50.22542],
              [8.01807, 50.22663],
              [8.01865, 50.22717],
              [8.01885, 50.22764],
              [8.01892, 50.22851],
              [8.01922, 50.229],
              [8.02041, 50.22999],
              [8.02074, 50.22977],
              [8.02077, 50.22969],
              [8.0208, 50.22963],
              [8.02069, 50.22935],
              [8.02064, 50.2293],
              [8.02042, 50.22907],
              [8.02031, 50.22853],
              [8.02051, 50.22827],
              [8.02091, 50.22827],
              [8.02091, 50.22827],
              [8.02051, 50.22827],
              [8.02031, 50.22853],
              [8.02042, 50.22907],
              [8.02064, 50.2293],
              [8.02069, 50.22935],
              [8.0208, 50.22963],
              [8.02077, 50.22969],
              [8.02074, 50.22977],
              [8.02041, 50.22999],
              [8.02062, 50.23058],
              [8.02047, 50.23119],
              [8.0204, 50.23132],
              [8.01981, 50.23192],
              [8.01849, 50.23326],
              [8.01817, 50.23413],
              [8.01773, 50.2361],
              [8.0178, 50.23655],
              [8.01813, 50.23761],
              [8.01818, 50.23806],
              [8.01828, 50.23873],
              [8.01834, 50.23954],
              [8.01824, 50.2397],
              [8.01813, 50.23976],
              [8.01763, 50.24006],
              [8.01659, 50.24088],
              [8.01585, 50.2416],
              [8.01515, 50.24253],
              [8.01454, 50.24333],
              [8.01323, 50.2447],
              [8.01302, 50.24489],
              [8.01153, 50.24587],
              [8.01111, 50.24628],
              [8.01091, 50.24637],
              [8.01039, 50.24689],
              [8.01002, 50.24707],
              [8.00989, 50.24713],
              [8.01003, 50.24728],
              [8.01018, 50.24741],
              [8.01062, 50.2478],
              [8.01131, 50.24841],
              [8.01167, 50.24859],
              [8.01197, 50.24876],
              [8.01214, 50.24899],
              [8.01213, 50.24931],
              [8.01223, 50.24941],
              [8.01169, 50.24989],
              [8.01121, 50.25029],
              [8.00982, 50.25101],
              [8.00954, 50.25165],
              [8.0095, 50.25191],
              [8.00925, 50.2524],
              [8.00918, 50.25288],
              [8.0082, 50.25504],
              [8.00812, 50.25552],
              [8.00832, 50.25645],
              [8.00819, 50.25681],
              [8.00775, 50.25721],
              [8.00761, 50.25755],
              [8.00703, 50.25828],
              [8.00664, 50.25848],
              [8.00633, 50.25865],
              [8.00624, 50.25886],
              [8.00609, 50.259],
              [8.00582, 50.2594],
              [8.00534, 50.25964],
              [8.00486, 50.26003],
              [8.00498, 50.26071],
              [8.00503, 50.26094],
              [8.00505, 50.2612],
              [8.00483, 50.26174],
              [8.00472, 50.26191],
              [8.00316, 50.26444],
              [8.00311, 50.26457],
              [8.003, 50.26566],
              [8.0029, 50.26591],
              [8.00281, 50.26613],
              [8.00278, 50.2662],
              [8.0027, 50.26641],
              [8.00265, 50.26651],
              [8.00254, 50.26679],
              [8.00226, 50.26741],
              [8.00224, 50.26746],
              [8.00175, 50.26868],
              [8.00164, 50.26897],
              [8.00153, 50.26933],
              [8.00009, 50.26856],
              [7.99976, 50.26836],
              [7.99895, 50.26804],
              [7.99829, 50.26793],
              [7.99776, 50.26796],
              [7.99739, 50.26783],
              [7.99714, 50.26762],
              [7.997114, 50.26761],
              [7.99637, 50.26731],
              [7.99542, 50.26696],
              [7.99416, 50.26649],
              [7.99356, 50.26625],
              [7.99293, 50.26595],
              [7.99235, 50.26565],
              [7.99038, 50.2645],
              [7.98922, 50.26381],
              [7.98736, 50.26321],
              [7.98676, 50.26353],
              [7.98602, 50.26385],
              [7.98565, 50.26401],
              [7.98425, 50.26436],
              [7.98252, 50.26451],
              [7.98043, 50.26458],
              [7.97973, 50.26482],
              [7.97946, 50.26508],
              [7.978922, 50.26652],
              [7.97884, 50.26674],
              [7.97811, 50.26715],
              [7.97734, 50.2675],
              [7.97704, 50.26762],
              [7.97698, 50.26771],
              [7.97687, 50.26768],
              [7.97665, 50.26752],
              [7.97664, 50.26742],
              [7.97666, 50.26717],
              [7.97642, 50.26632],
              [7.9763, 50.26622],
              [7.97389, 50.26547],
              [7.97308, 50.265],
              [7.972481, 50.264567],
              [7.972367, 50.264485],
            ],
          ],
        },
        id: "M5NTE",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "U1NDA",
    name: "274 Wiesbaden <> Heimbach",
    lineName: "274",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.06699, 50.14028],
              [8.06721, 50.14014],
              [8.06804, 50.13952],
              [8.06816, 50.13942],
              [8.06821, 50.13938],
              [8.06836, 50.13925],
              [8.06882, 50.13887],
              [8.06912, 50.13861],
              [8.06915, 50.13854],
              [8.06918, 50.13843],
              [8.06861, 50.1374],
              [8.06857, 50.13722],
              [8.06855, 50.13707],
              [8.06821, 50.13661],
              [8.06779, 50.13621],
              [8.06702, 50.13557],
              [8.06537, 50.13447],
              [8.06513, 50.13426],
              [8.0649, 50.13407],
              [8.06437, 50.13362],
              [8.06382, 50.13305],
              [8.06383, 50.13286],
              [8.06414, 50.13275],
              [8.06514, 50.13298],
              [8.06636, 50.1331],
              [8.06671, 50.13306],
              [8.06707, 50.13299],
              [8.06741, 50.13291],
              [8.06798, 50.13279],
              [8.06874, 50.13263],
              [8.07051, 50.13223],
              [8.07359, 50.13197],
              [8.07554, 50.13127],
              [8.07705, 50.12991],
              [8.08117, 50.12598],
              [8.08251, 50.12464],
              [8.08279, 50.12397],
              [8.08284, 50.12336],
              [8.08286, 50.123],
              [8.08305, 50.12282],
              [8.08334, 50.1228],
              [8.08353, 50.12315],
              [8.08418, 50.12518],
              [8.08469, 50.12558],
              [8.08539, 50.12609],
              [8.08613, 50.12724],
              [8.08696, 50.12872],
              [8.08784, 50.12994],
              [8.08871, 50.13106],
              [8.08885, 50.13116],
              [8.08916, 50.13136],
              [8.08966, 50.13181],
              [8.08992, 50.13252],
              [8.08995, 50.1326],
              [8.09012, 50.13292],
              [8.09034, 50.13341],
              [8.09031, 50.13345],
              [8.09042, 50.13373],
              [8.09085, 50.13396],
              [8.09042, 50.13373],
              [8.09031, 50.13345],
              [8.09034, 50.13341],
              [8.09012, 50.13292],
              [8.08995, 50.1326],
              [8.08992, 50.13252],
              [8.08966, 50.13181],
              [8.08916, 50.13136],
              [8.08885, 50.13116],
              [8.08871, 50.13106],
              [8.08784, 50.12994],
              [8.08696, 50.12872],
              [8.08613, 50.12724],
              [8.08539, 50.12609],
              [8.08469, 50.12558],
              [8.08418, 50.12518],
              [8.08353, 50.12315],
              [8.08334, 50.1228],
              [8.08338, 50.12277],
              [8.08336, 50.12261],
              [8.08306, 50.12257],
              [8.08336, 50.12261],
              [8.08385, 50.12239],
              [8.08511, 50.12205],
              [8.08593, 50.12182],
              [8.08846, 50.12073],
              [8.09024, 50.11996],
              [8.09135, 50.11985],
              [8.09625, 50.11975],
              [8.10155, 50.11962],
              [8.10286, 50.1195],
              [8.10402, 50.11913],
              [8.1071, 50.11762],
              [8.10879, 50.11698],
              [8.10944, 50.11681],
              [8.11058, 50.11659],
              [8.11051, 50.11646],
              [8.11048, 50.11624],
              [8.11051, 50.11646],
              [8.11058, 50.11659],
              [8.11135, 50.11647],
              [8.11409, 50.11591],
              [8.11503, 50.11567],
              [8.11775, 50.11487],
              [8.11816, 50.11469],
              [8.11898, 50.11427],
              [8.11949, 50.11483],
              [8.12095, 50.11932],
              [8.12105, 50.11963],
              [8.12115, 50.11993],
              [8.12123, 50.1204],
              [8.1211, 50.12073],
              [8.12098, 50.12086],
              [8.11989, 50.12148],
              [8.11935, 50.12168],
              [8.1192, 50.12174],
              [8.11897, 50.12181],
              [8.11787, 50.12231],
              [8.11767, 50.12255],
              [8.11768, 50.12286],
              [8.11795, 50.1232],
              [8.11865, 50.12343],
              [8.1196, 50.12356],
              [8.121, 50.12373],
              [8.12186, 50.12432],
              [8.122, 50.12444],
              [8.12223, 50.12458],
              [8.12251, 50.12475],
              [8.12276, 50.12499],
              [8.12278, 50.12532],
              [8.12288, 50.12569],
              [8.12325, 50.12634],
              [8.12327, 50.12644],
              [8.12347, 50.12706],
              [8.12357, 50.1272],
              [8.12377, 50.12741],
              [8.1252, 50.12797],
              [8.12585, 50.12828],
              [8.12696, 50.12923],
              [8.127018, 50.129456],
              [8.1271, 50.12978],
              [8.12712, 50.12998],
              [8.12724, 50.13056],
              [8.12778, 50.13089],
              [8.12942, 50.13138],
              [8.13017, 50.13181],
              [8.13149, 50.13289],
              [8.13151, 50.13305],
              [8.13272, 50.13338],
              [8.13307, 50.13382],
              [8.13311, 50.1341],
              [8.13265, 50.1357],
              [8.1326, 50.13611],
              [8.13265, 50.13616],
              [8.13297, 50.1363],
              [8.13325, 50.13638],
              [8.13502, 50.1367],
              [8.13681, 50.13706],
              [8.13694, 50.13709],
              [8.13711, 50.13715],
              [8.13756, 50.13733],
              [8.13806, 50.13703],
              [8.13837, 50.136963],
              [8.13973, 50.13667],
              [8.14264, 50.13561],
              [8.14297, 50.13549],
              [8.14411, 50.13507],
              [8.14535, 50.1347],
              [8.14577, 50.13461],
              [8.14767, 50.13421],
              [8.14869, 50.13561],
              [8.14937, 50.13662],
              [8.14951, 50.13695],
              [8.1504, 50.13684],
              [8.15219, 50.1366],
              [8.15312, 50.13648],
              [8.15444, 50.13644],
              [8.15454, 50.13599],
              [8.15488, 50.13522],
              [8.15533, 50.13471],
              [8.15635, 50.13397],
              [8.15778, 50.13345],
              [8.15917, 50.13325],
              [8.16065, 50.13312],
              [8.16176, 50.13291],
              [8.16227, 50.13275],
              [8.16289, 50.13246],
              [8.16327, 50.13223],
              [8.16412, 50.13153],
              [8.16446, 50.1311],
              [8.16482, 50.13066],
              [8.166, 50.12911],
              [8.16616, 50.12872],
              [8.16687, 50.12597],
              [8.167335, 50.124359],
              [8.16745, 50.12396],
              [8.16765, 50.12362],
              [8.16779, 50.12343],
              [8.16796, 50.12324],
              [8.16853, 50.12284],
              [8.16872, 50.12275],
              [8.1694, 50.12254],
              [8.17106, 50.1225],
              [8.17137, 50.12236],
              [8.17297, 50.12241],
              [8.17309, 50.12249],
              [8.17421, 50.12216],
              [8.17598, 50.12085],
              [8.17803, 50.12025],
              [8.17926, 50.11962],
              [8.17974, 50.11895],
              [8.17974, 50.11835],
              [8.17947, 50.11765],
              [8.1794, 50.11753],
              [8.1789, 50.11628],
              [8.17898, 50.11527],
              [8.17922, 50.1142],
              [8.17971, 50.11337],
              [8.18004, 50.11299],
              [8.18084, 50.11214],
              [8.18167, 50.11178],
              [8.18425, 50.11163],
              [8.18489, 50.11143],
              [8.18512, 50.11132],
              [8.18696, 50.11029],
              [8.18776, 50.10998],
              [8.19164, 50.10885],
              [8.19222, 50.10868],
              [8.1929, 50.10849],
              [8.19355, 50.10831],
              [8.19407, 50.10816],
              [8.19541, 50.10802],
              [8.19846, 50.10786],
              [8.19956, 50.10759],
              [8.20032, 50.10719],
              [8.20099, 50.10658],
              [8.20153, 50.10574],
              [8.20233, 50.10437],
              [8.20268, 50.10373],
              [8.2029, 50.10334],
              [8.20388, 50.10174],
              [8.20401, 50.10154],
              [8.20995, 50.09476],
              [8.21069, 50.09406],
              [8.20995, 50.09476],
              [8.20401, 50.10154],
              [8.20388, 50.10174],
              [8.20401, 50.10154],
              [8.20995, 50.09476],
              [8.21069, 50.09406],
              [8.21175, 50.09322],
              [8.21363, 50.09204],
              [8.21392, 50.09187],
              [8.21428, 50.09165],
              [8.21533, 50.09101],
              [8.21585, 50.0907],
              [8.21739, 50.08979],
              [8.21838, 50.08917],
              [8.21858, 50.08905],
              [8.21977, 50.08834],
              [8.22085, 50.08767],
              [8.22177, 50.0871],
              [8.22192, 50.08701],
              [8.22362, 50.08612],
              [8.22192, 50.08701],
              [8.22362, 50.08612],
              [8.223978, 50.085986],
              [8.22461, 50.08575],
              [8.22529, 50.08538],
              [8.2254, 50.0853],
              [8.22575, 50.08506],
              [8.22693, 50.08414],
              [8.22766, 50.08366],
              [8.22837, 50.08335],
              [8.22882, 50.08312],
              [8.22888, 50.08279],
              [8.228921, 50.082748],
              [8.22908, 50.08258],
              [8.22915, 50.08253],
              [8.2293, 50.08186],
              [8.22934, 50.08152],
              [8.2294, 50.08117],
              [8.22949, 50.08052],
              [8.22963, 50.08055],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22983, 50.07742],
              [8.22986, 50.07721],
              [8.23019, 50.07716],
              [8.23267, 50.07728],
              [8.234781, 50.07738],
              [8.23519, 50.07739],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23836, 50.07167],
              [8.23907, 50.07161],
              [8.24041, 50.07158],
              [8.24084, 50.07159],
              [8.24196, 50.07164],
              [8.2424, 50.07166],
              [8.24311, 50.07169],
              [8.24328, 50.0717],
            ],
          ],
        },
        id: "U1NDA",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.236676, 50.077684],
              [8.235433, 50.077624],
              [8.235155, 50.07761],
              [8.233622, 50.07753],
              [8.232675, 50.077486],
              [8.23015, 50.07735],
              [8.22997, 50.07829],
              [8.22976, 50.07946],
              [8.22963, 50.08055],
              [8.22955, 50.08119],
              [8.22952, 50.08153],
              [8.22934, 50.08152],
              [8.2293, 50.08186],
              [8.22915, 50.08253],
              [8.22908, 50.08258],
              [8.228921, 50.082748],
              [8.22888, 50.08279],
              [8.22882, 50.08312],
              [8.22837, 50.08335],
              [8.22766, 50.08366],
              [8.22693, 50.08414],
              [8.22575, 50.08506],
              [8.2254, 50.0853],
              [8.22529, 50.08538],
              [8.22461, 50.08575],
              [8.223978, 50.085986],
              [8.22362, 50.08612],
              [8.22192, 50.08701],
              [8.22177, 50.0871],
              [8.22085, 50.08767],
              [8.21977, 50.08834],
              [8.21858, 50.08905],
              [8.21838, 50.08917],
              [8.21739, 50.08979],
              [8.21585, 50.0907],
              [8.21533, 50.09101],
              [8.21428, 50.09165],
              [8.21392, 50.09187],
              [8.21363, 50.09204],
              [8.21175, 50.09322],
              [8.21069, 50.09406],
              [8.20995, 50.09476],
              [8.20401, 50.10154],
              [8.20388, 50.10174],
              [8.2029, 50.10334],
              [8.20268, 50.10373],
              [8.20233, 50.10437],
              [8.20153, 50.10574],
              [8.20099, 50.10658],
              [8.20032, 50.10719],
              [8.19956, 50.10759],
              [8.19846, 50.10786],
              [8.19541, 50.10802],
              [8.19407, 50.10816],
              [8.19355, 50.10831],
              [8.1929, 50.10849],
              [8.19222, 50.10868],
              [8.19164, 50.10885],
              [8.18776, 50.10998],
              [8.18696, 50.11029],
              [8.18512, 50.11132],
              [8.18489, 50.11143],
              [8.18425, 50.11163],
              [8.18167, 50.11178],
              [8.18084, 50.11214],
              [8.18004, 50.11299],
              [8.17971, 50.11337],
              [8.17922, 50.1142],
              [8.17898, 50.11527],
              [8.1789, 50.11628],
              [8.1794, 50.11753],
              [8.17947, 50.11765],
              [8.17974, 50.11835],
              [8.17974, 50.11895],
              [8.17926, 50.11962],
              [8.17803, 50.12025],
              [8.17598, 50.12085],
              [8.17421, 50.12216],
              [8.17309, 50.12249],
              [8.17297, 50.12241],
              [8.17137, 50.12236],
              [8.17106, 50.1225],
              [8.1694, 50.12254],
              [8.16872, 50.12275],
              [8.16853, 50.12284],
              [8.16796, 50.12324],
              [8.16779, 50.12343],
              [8.16765, 50.12362],
              [8.16745, 50.12396],
              [8.167335, 50.124359],
              [8.16687, 50.12597],
              [8.16616, 50.12872],
              [8.166, 50.12911],
              [8.16482, 50.13066],
              [8.16446, 50.1311],
              [8.16412, 50.13153],
              [8.16327, 50.13223],
              [8.16289, 50.13246],
              [8.16227, 50.13275],
              [8.16176, 50.13291],
              [8.16065, 50.13312],
              [8.15917, 50.13325],
              [8.15778, 50.13345],
              [8.15635, 50.13397],
              [8.15533, 50.13471],
              [8.15488, 50.13522],
              [8.15454, 50.13599],
              [8.15444, 50.13644],
              [8.15312, 50.13648],
              [8.15219, 50.1366],
              [8.1504, 50.13684],
              [8.14951, 50.13695],
              [8.14937, 50.13662],
              [8.14869, 50.13561],
              [8.14767, 50.13421],
              [8.14577, 50.13461],
              [8.14535, 50.1347],
              [8.14411, 50.13507],
              [8.14297, 50.13549],
              [8.14264, 50.13561],
              [8.13973, 50.13667],
              [8.13837, 50.136963],
              [8.13806, 50.13703],
              [8.13756, 50.13733],
              [8.13711, 50.13715],
              [8.13694, 50.13709],
              [8.13681, 50.13706],
              [8.13502, 50.1367],
              [8.13325, 50.13638],
              [8.13297, 50.1363],
              [8.13265, 50.13616],
              [8.1326, 50.13611],
              [8.13265, 50.1357],
              [8.13311, 50.1341],
              [8.13307, 50.13382],
              [8.13272, 50.13338],
              [8.13151, 50.13305],
              [8.13149, 50.13289],
              [8.13017, 50.13181],
              [8.12942, 50.13138],
              [8.12778, 50.13089],
              [8.12724, 50.13056],
              [8.12712, 50.12998],
              [8.1271, 50.12978],
              [8.12712, 50.12998],
              [8.12724, 50.13056],
              [8.12778, 50.13089],
              [8.12942, 50.13138],
              [8.13017, 50.13181],
              [8.13149, 50.13289],
              [8.13017, 50.13181],
              [8.12942, 50.13138],
              [8.12778, 50.13089],
              [8.12724, 50.13056],
              [8.12712, 50.12998],
              [8.1271, 50.12978],
              [8.127018, 50.129456],
              [8.12696, 50.12923],
              [8.12585, 50.12828],
              [8.1252, 50.12797],
              [8.12377, 50.12741],
              [8.12357, 50.1272],
              [8.12347, 50.12706],
              [8.12327, 50.12644],
              [8.12325, 50.12634],
              [8.12288, 50.12569],
              [8.12278, 50.12532],
              [8.12276, 50.12499],
              [8.12251, 50.12475],
              [8.12223, 50.12458],
              [8.122, 50.12444],
              [8.12186, 50.12432],
              [8.121, 50.12373],
              [8.1196, 50.12356],
              [8.11865, 50.12343],
              [8.11795, 50.1232],
              [8.11768, 50.12286],
              [8.11767, 50.12255],
              [8.11787, 50.12231],
              [8.11897, 50.12181],
              [8.1192, 50.12174],
              [8.11935, 50.12168],
              [8.11989, 50.12148],
              [8.12098, 50.12086],
              [8.1211, 50.12073],
              [8.12123, 50.1204],
              [8.12115, 50.11993],
              [8.12105, 50.11963],
              [8.12095, 50.11932],
              [8.11949, 50.11483],
              [8.11898, 50.11427],
              [8.11816, 50.11469],
              [8.11775, 50.11487],
              [8.11503, 50.11567],
              [8.11409, 50.11591],
              [8.11135, 50.11647],
              [8.11058, 50.11659],
              [8.11051, 50.11646],
              [8.11048, 50.11624],
              [8.11051, 50.11646],
              [8.11058, 50.11659],
              [8.10944, 50.11681],
              [8.10879, 50.11698],
              [8.1071, 50.11762],
              [8.10402, 50.11913],
              [8.10286, 50.1195],
              [8.10155, 50.11962],
              [8.09625, 50.11975],
              [8.09135, 50.11985],
              [8.09024, 50.11996],
              [8.08846, 50.12073],
              [8.08593, 50.12182],
              [8.08511, 50.12205],
              [8.08385, 50.12239],
              [8.08336, 50.12261],
              [8.08306, 50.12257],
              [8.08336, 50.12261],
              [8.08338, 50.12277],
              [8.08334, 50.1228],
              [8.08353, 50.12315],
              [8.08418, 50.12518],
              [8.08469, 50.12558],
              [8.08539, 50.12609],
              [8.08613, 50.12724],
              [8.08696, 50.12872],
              [8.08784, 50.12994],
              [8.08871, 50.13106],
              [8.08885, 50.13116],
              [8.08916, 50.13136],
              [8.08966, 50.13181],
              [8.08992, 50.13252],
              [8.08995, 50.1326],
              [8.09012, 50.13292],
              [8.09034, 50.13341],
              [8.09031, 50.13345],
              [8.09042, 50.13373],
              [8.09085, 50.13396],
              [8.09042, 50.13373],
              [8.09031, 50.13345],
              [8.09034, 50.13341],
              [8.09012, 50.13292],
              [8.08995, 50.1326],
              [8.08992, 50.13252],
              [8.08966, 50.13181],
              [8.08916, 50.13136],
              [8.08885, 50.13116],
              [8.08871, 50.13106],
              [8.08784, 50.12994],
              [8.08696, 50.12872],
              [8.08613, 50.12724],
              [8.08539, 50.12609],
              [8.08469, 50.12558],
              [8.08418, 50.12518],
              [8.08353, 50.12315],
              [8.08334, 50.1228],
              [8.08305, 50.12282],
              [8.08286, 50.123],
              [8.08284, 50.12336],
              [8.08279, 50.12397],
              [8.08251, 50.12464],
              [8.08117, 50.12598],
              [8.07705, 50.12991],
              [8.07554, 50.13127],
              [8.07359, 50.13197],
              [8.07051, 50.13223],
              [8.06874, 50.13263],
              [8.06798, 50.13279],
              [8.06741, 50.13291],
              [8.06707, 50.13299],
              [8.06671, 50.13306],
              [8.06636, 50.1331],
              [8.06514, 50.13298],
              [8.06414, 50.13275],
              [8.06383, 50.13286],
              [8.06382, 50.13305],
              [8.06437, 50.13362],
              [8.0649, 50.13407],
              [8.06513, 50.13426],
              [8.06537, 50.13447],
              [8.06702, 50.13557],
              [8.06779, 50.13621],
              [8.06821, 50.13661],
              [8.06855, 50.13707],
              [8.06857, 50.13722],
              [8.06861, 50.1374],
              [8.06918, 50.13843],
              [8.06915, 50.13854],
              [8.06912, 50.13861],
              [8.06882, 50.13887],
              [8.06836, 50.13925],
              [8.06821, 50.13938],
              [8.06816, 50.13942],
              [8.06804, 50.13952],
              [8.06721, 50.14014],
              [8.06699, 50.14028],
            ],
          ],
        },
        id: "c0MTk",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "YxMzc",
    name: "270 Idstein <> Wiesbaden",
    lineName: "270",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.23674, 50.07786],
              [8.23658, 50.07894],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.2363, 50.08098],
              [8.23627, 50.08116],
              [8.23625, 50.08138],
              [8.23623, 50.08182],
              [8.23617, 50.08226],
              [8.23615, 50.08241],
              [8.23611, 50.08276],
              [8.23607, 50.08306],
              [8.23606, 50.08328],
              [8.23603, 50.08364],
              [8.23593, 50.08379],
              [8.23501, 50.08459],
              [8.23366, 50.08578],
              [8.23314, 50.08623],
              [8.2325, 50.0867],
              [8.23185, 50.08717],
              [8.23169, 50.08729],
              [8.23096, 50.08782],
              [8.23082, 50.08792],
              [8.2305, 50.08815],
              [8.23004, 50.08851],
              [8.22935, 50.08924],
              [8.22887, 50.08983],
              [8.22843, 50.09032],
              [8.22811, 50.09071],
              [8.227802, 50.091053],
              [8.22697, 50.09198],
              [8.22632, 50.09232],
              [8.22537, 50.09277],
              [8.22482, 50.09304],
              [8.22421, 50.09335],
              [8.22324, 50.09368],
              [8.22308, 50.09377],
              [8.22261, 50.094135],
              [8.22196, 50.09464],
              [8.22164, 50.09517],
              [8.22112, 50.09604],
              [8.221, 50.0962],
              [8.22025, 50.09698],
              [8.21978, 50.09757],
              [8.21933, 50.09819],
              [8.21908, 50.09855],
              [8.21839, 50.09958],
              [8.21829, 50.09974],
              [8.21718, 50.10136],
              [8.21682, 50.10187],
              [8.21461, 50.10464],
              [8.21116, 50.1089],
              [8.21051, 50.10985],
              [8.21031, 50.11047],
              [8.21022, 50.11076],
              [8.20966, 50.11298],
              [8.20934, 50.11423],
              [8.20908, 50.1148],
              [8.20884, 50.11505],
              [8.20862, 50.11522],
              [8.2079, 50.11568],
              [8.20626, 50.11658],
              [8.20564, 50.11723],
              [8.20555, 50.11747],
              [8.2054, 50.11825],
              [8.20556, 50.11886],
              [8.20595, 50.11965],
              [8.20639, 50.12082],
              [8.2064, 50.12219],
              [8.20602, 50.12432],
              [8.20627, 50.12539],
              [8.20667, 50.12625],
              [8.20696, 50.12672],
              [8.20828, 50.12785],
              [8.20934, 50.12848],
              [8.21485, 50.13173],
              [8.21639, 50.133],
              [8.2167, 50.13332],
              [8.21705, 50.13369],
              [8.21757, 50.13433],
              [8.2179, 50.13479],
              [8.218069, 50.135119],
              [8.21809, 50.13516],
              [8.21822, 50.1354],
              [8.21832, 50.1356],
              [8.21862, 50.13629],
              [8.21879, 50.13686],
              [8.21884, 50.1371],
              [8.21893, 50.13766],
              [8.21897, 50.1387],
              [8.21887, 50.13977],
              [8.21829, 50.14154],
              [8.21743, 50.14327],
              [8.21673, 50.14464],
              [8.21644, 50.1454],
              [8.21628, 50.14581],
              [8.21599, 50.14652],
              [8.21519, 50.14654],
              [8.21445, 50.14681],
              [8.2065, 50.15015],
              [8.20565, 50.1505],
              [8.20505, 50.15075],
              [8.20427, 50.15106],
              [8.20335, 50.15131],
              [8.20211, 50.15164],
              [8.20162, 50.15176],
              [8.20029, 50.15194],
              [8.19866, 50.1521],
              [8.19754, 50.15223],
              [8.19744, 50.15224],
              [8.19606, 50.15231],
              [8.194928, 50.152367],
              [8.19446, 50.15239],
              [8.19359, 50.15244],
              [8.19323, 50.15247],
              [8.19192, 50.15265],
              [8.19168, 50.15269],
              [8.19121, 50.15274],
              [8.18872, 50.15312],
              [8.18836, 50.15317],
              [8.18754, 50.15328],
              [8.18669, 50.15344],
              [8.18607, 50.15374],
              [8.18606, 50.15378],
              [8.18633, 50.15414],
              [8.18615, 50.15455],
              [8.18604, 50.1547],
              [8.18567, 50.15521],
              [8.18542, 50.15588],
              [8.18515, 50.15602],
              [8.18491, 50.1556],
              [8.18377, 50.15523],
              [8.18349, 50.15589],
              [8.1834, 50.156],
              [8.18312, 50.15636],
              [8.18301, 50.15651],
              [8.18287, 50.15662],
              [8.18196, 50.15678],
              [8.18045, 50.1564],
              [8.17984, 50.15689],
              [8.17931, 50.15749],
              [8.17906, 50.15792],
              [8.17876, 50.1584],
              [8.17838, 50.15895],
              [8.17761, 50.15956],
              [8.17753, 50.15997],
              [8.17778, 50.16045],
              [8.17816, 50.16079],
              [8.17882, 50.16139],
              [8.17905, 50.16195],
              [8.17918, 50.16295],
              [8.17946, 50.16349],
              [8.18098, 50.16498],
              [8.18197, 50.16575],
              [8.18265, 50.16646],
              [8.18407, 50.16708],
              [8.18484, 50.1678],
              [8.18649, 50.16966],
              [8.1867, 50.17022],
              [8.18678, 50.171],
              [8.18743, 50.17204],
              [8.18743, 50.17284],
              [8.18743, 50.17399],
              [8.18748, 50.1747],
              [8.18688, 50.17587],
              [8.1868, 50.17839],
              [8.18712, 50.1793],
              [8.18712, 50.17953],
              [8.18732, 50.1802],
              [8.1874, 50.18033],
              [8.1876, 50.18059],
              [8.18805, 50.18112],
              [8.18794, 50.18115],
              [8.18766, 50.1812],
              [8.1869, 50.18116],
              [8.18679, 50.18115],
              [8.18629, 50.18117],
              [8.18594, 50.1812],
              [8.18554, 50.18129],
              [8.18552, 50.18154],
              [8.18545, 50.18178],
              [8.18527, 50.18241],
              [8.18515, 50.18275],
              [8.18512, 50.18284],
              [8.18493, 50.18342],
              [8.18484, 50.1837],
              [8.1848, 50.184],
              [8.18483, 50.18429],
              [8.18487, 50.1846],
              [8.18492, 50.18493],
              [8.18496, 50.18568],
              [8.18451, 50.18677],
              [8.18407, 50.18799],
              [8.18384, 50.18904],
              [8.18367, 50.18998],
              [8.18305, 50.19121],
              [8.18305, 50.19166],
              [8.18343, 50.19229],
              [8.18339, 50.19256],
              [8.18287, 50.19337],
              [8.18221, 50.19429],
              [8.18169, 50.19472],
              [8.18089, 50.19494],
              [8.18067, 50.19496],
              [8.18025, 50.19494],
              [8.17919, 50.19486],
              [8.17829, 50.19496],
              [8.1776, 50.19533],
              [8.17729, 50.19563],
              [8.17722, 50.19602],
              [8.17735, 50.19736],
              [8.17698, 50.19872],
              [8.17689, 50.19921],
              [8.17652, 50.19965],
              [8.17469, 50.20077],
              [8.17365, 50.20176],
              [8.1725, 50.20232],
              [8.17224, 50.20275],
              [8.17233, 50.20349],
              [8.17215, 50.20394],
              [8.17115, 50.20474],
              [8.17031, 50.20547],
              [8.1702, 50.2059],
              [8.1704, 50.20711],
              [8.17095, 50.20737],
              [8.17145, 50.20757],
              [8.17188, 50.20799],
              [8.17208, 50.20843],
              [8.17259, 50.2086],
              [8.17288, 50.20883],
              [8.17386, 50.2092],
              [8.17541, 50.20965],
              [8.17604, 50.20976],
              [8.17854, 50.21029],
              [8.17958, 50.21043],
              [8.18102, 50.21052],
              [8.18119, 50.2105],
              [8.18191, 50.21023],
              [8.18233, 50.20986],
              [8.18287, 50.20905],
              [8.18344, 50.20842],
              [8.18418, 50.20808],
              [8.18607, 50.20815],
              [8.18713, 50.20799],
              [8.18865, 50.20756],
              [8.19048, 50.20722],
              [8.19058, 50.2072],
              [8.19192, 50.20695],
              [8.19224, 50.2069],
              [8.19338, 50.20692],
              [8.19352, 50.20696],
              [8.19366, 50.207],
              [8.19451, 50.20734],
              [8.1963, 50.2079],
              [8.19678, 50.20801],
              [8.19759, 50.20812],
              [8.19824, 50.20808],
              [8.19905, 50.20784],
              [8.20009, 50.20711],
              [8.20015, 50.20707],
              [8.2006, 50.20698],
              [8.20059, 50.20714],
              [8.20067, 50.20835],
              [8.20075, 50.20878],
              [8.20073, 50.2093],
              [8.20061, 50.21134],
              [8.20041, 50.21199],
              [8.1998, 50.2128],
              [8.19929, 50.21339],
              [8.19838, 50.21459],
              [8.19817, 50.21495],
              [8.1974, 50.21646],
              [8.19727, 50.21675],
              [8.19717, 50.21719],
              [8.19719, 50.2175],
              [8.19738, 50.21807],
              [8.19856, 50.22057],
              [8.1988, 50.22052],
              [8.1995, 50.22037],
              [8.19954, 50.22028],
              [8.19923, 50.21965],
              [8.19863, 50.2184],
              [8.19843, 50.21779],
              [8.19829, 50.21774],
              [8.19835, 50.2176],
              [8.19858, 50.21766],
              [8.1995, 50.21774],
              [8.19975, 50.21777],
              [8.20012, 50.21781],
              [8.20102, 50.21796],
              [8.20145, 50.21807],
              [8.202324, 50.2183],
              [8.20301, 50.21848],
              [8.2049, 50.21918],
              [8.20495, 50.21948],
              [8.20488, 50.21978],
              [8.20485, 50.21986],
              [8.20451, 50.22064],
              [8.20436, 50.22093],
              [8.2041, 50.22158],
              [8.2041, 50.22178],
              [8.20432, 50.22277],
              [8.20461, 50.22317],
              [8.20523, 50.22353],
              [8.20565, 50.22369],
              [8.20656, 50.22402],
              [8.20708, 50.22419],
              [8.20772, 50.22442],
              [8.20807, 50.22454],
              [8.207849, 50.225046],
              [8.20807, 50.22454],
              [8.20852, 50.22468],
              [8.20871, 50.22474],
              [8.20923, 50.22494],
              [8.21024, 50.22526],
              [8.21161, 50.22578],
              [8.21183, 50.22577],
              [8.21257, 50.22597],
              [8.21301, 50.22618],
              [8.21319, 50.22623],
              [8.21304, 50.22643],
              [8.21333, 50.22661],
              [8.21373, 50.2271],
              [8.21465, 50.22772],
              [8.21476, 50.22772],
              [8.21472, 50.22796],
              [8.21466, 50.22853],
              [8.21484, 50.2289],
              [8.21503, 50.22936],
              [8.21551, 50.22993],
              [8.21632, 50.23034],
              [8.21784, 50.23064],
              [8.21883, 50.23073],
              [8.21983, 50.23083],
              [8.22048, 50.23089],
              [8.2221, 50.23103],
              [8.22304, 50.23094],
              [8.22373, 50.23064],
              [8.22509, 50.22924],
              [8.22559, 50.22859],
              [8.22556, 50.22789],
              [8.22549, 50.22716],
              [8.22552, 50.22702],
              [8.22591, 50.22627],
              [8.22609, 50.22608],
              [8.22793, 50.22464],
              [8.22879, 50.22376],
              [8.2293, 50.22281],
              [8.22879, 50.22376],
              [8.22793, 50.22464],
              [8.22609, 50.22608],
              [8.22591, 50.22627],
              [8.22552, 50.22702],
              [8.22549, 50.22716],
              [8.22556, 50.22789],
              [8.22549, 50.22716],
              [8.22552, 50.22702],
              [8.22591, 50.22627],
              [8.22609, 50.22608],
              [8.22793, 50.22464],
              [8.22879, 50.22376],
              [8.2293, 50.22281],
              [8.22974, 50.22173],
              [8.23019, 50.22127],
              [8.23045, 50.2211],
              [8.23076, 50.22092],
              [8.23194, 50.22034],
              [8.23306, 50.21992],
              [8.23326, 50.21984],
              [8.23347, 50.21977],
              [8.23412, 50.21933],
              [8.23421, 50.21929],
              [8.23433, 50.21923],
              [8.23516, 50.21898],
              [8.23531, 50.21893],
              [8.23605, 50.21851],
              [8.23645, 50.21788],
              [8.23669, 50.21743],
              [8.23684, 50.21702],
              [8.23687, 50.2162],
              [8.23702, 50.21561],
              [8.23737, 50.21511],
              [8.23781, 50.21475],
              [8.2383, 50.21447],
              [8.23914, 50.21421],
              [8.24009, 50.2141],
              [8.24029, 50.2141],
              [8.24146, 50.21414],
              [8.24191, 50.21416],
              [8.24307, 50.21406],
              [8.24479, 50.21346],
              [8.2465, 50.213],
              [8.24836, 50.21285],
              [8.24951, 50.21293],
              [8.25061, 50.21328],
              [8.25121, 50.21363],
              [8.25238, 50.21496],
              [8.25302, 50.21566],
              [8.25383, 50.21634],
              [8.25421, 50.21656],
              [8.25458, 50.21678],
              [8.2553, 50.21738],
              [8.25546, 50.21761],
              [8.25582, 50.2181],
              [8.25589, 50.21817],
              [8.25611, 50.21815],
              [8.25618, 50.21831],
              [8.25629, 50.21833],
              [8.25674, 50.21836],
              [8.25759, 50.21837],
              [8.25896, 50.21757],
              [8.25896, 50.21747],
              [8.25791, 50.2169],
              [8.25783, 50.21672],
              [8.2577, 50.21666],
              [8.257779, 50.216326],
              [8.2577, 50.21666],
              [8.25783, 50.21672],
              [8.25791, 50.2169],
              [8.25896, 50.21747],
              [8.25896, 50.21757],
              [8.25914, 50.21752],
              [8.25928, 50.21753],
              [8.25943, 50.21754],
              [8.25976, 50.21756],
              [8.26012, 50.21759],
              [8.26028, 50.2176],
              [8.26175, 50.21768],
              [8.262442, 50.217716],
              [8.26386, 50.21779],
              [8.26362, 50.21868],
              [8.2645, 50.21924],
              [8.26584, 50.21962],
              [8.26717, 50.21984],
              [8.26726, 50.21989],
              [8.26727, 50.21998],
              [8.26741, 50.22054],
              [8.26759, 50.22076],
              [8.26757, 50.22087],
              [8.26734, 50.2211],
              [8.267131, 50.221318],
              [8.26686, 50.2216],
              [8.26682, 50.22167],
              [8.26678, 50.22181],
              [8.26685, 50.22189],
              [8.26701, 50.22195],
              [8.267298, 50.222062],
            ],
          ],
        },
        id: "YxMzc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.267298, 50.222062],
              [8.26701, 50.22195],
              [8.26685, 50.22189],
              [8.26678, 50.22181],
              [8.26682, 50.22167],
              [8.26686, 50.2216],
              [8.267131, 50.221318],
              [8.26734, 50.2211],
              [8.26757, 50.22087],
              [8.26759, 50.22076],
              [8.26741, 50.22054],
              [8.26727, 50.21998],
              [8.26726, 50.21989],
              [8.26717, 50.21984],
              [8.26584, 50.21962],
              [8.2645, 50.21924],
              [8.26362, 50.21868],
              [8.26386, 50.21779],
              [8.262442, 50.217716],
              [8.26175, 50.21768],
              [8.26028, 50.2176],
              [8.26012, 50.21759],
              [8.25976, 50.21756],
              [8.25943, 50.21754],
              [8.25928, 50.21753],
              [8.25914, 50.21752],
              [8.25896, 50.21747],
              [8.25791, 50.2169],
              [8.25783, 50.21672],
              [8.2577, 50.21666],
              [8.257779, 50.216326],
              [8.2577, 50.21666],
              [8.25783, 50.21672],
              [8.25791, 50.2169],
              [8.25896, 50.21747],
              [8.25896, 50.21757],
              [8.25759, 50.21837],
              [8.25674, 50.21836],
              [8.25629, 50.21833],
              [8.25618, 50.21831],
              [8.25611, 50.21815],
              [8.25589, 50.21817],
              [8.25582, 50.2181],
              [8.25546, 50.21761],
              [8.2553, 50.21738],
              [8.25458, 50.21678],
              [8.25421, 50.21656],
              [8.25383, 50.21634],
              [8.25302, 50.21566],
              [8.25238, 50.21496],
              [8.25121, 50.21363],
              [8.25061, 50.21328],
              [8.24951, 50.21293],
              [8.24836, 50.21285],
              [8.2465, 50.213],
              [8.24479, 50.21346],
              [8.24307, 50.21406],
              [8.24191, 50.21416],
              [8.24146, 50.21414],
              [8.24029, 50.2141],
              [8.24009, 50.2141],
              [8.23914, 50.21421],
              [8.2383, 50.21447],
              [8.23781, 50.21475],
              [8.23737, 50.21511],
              [8.23702, 50.21561],
              [8.23687, 50.2162],
              [8.23684, 50.21702],
              [8.23669, 50.21743],
              [8.23645, 50.21788],
              [8.23605, 50.21851],
              [8.23531, 50.21893],
              [8.23516, 50.21898],
              [8.23433, 50.21923],
              [8.23421, 50.21929],
              [8.23412, 50.21933],
              [8.23347, 50.21977],
              [8.23326, 50.21984],
              [8.23306, 50.21992],
              [8.23194, 50.22034],
              [8.23076, 50.22092],
              [8.23045, 50.2211],
              [8.23019, 50.22127],
              [8.22974, 50.22173],
              [8.2293, 50.22281],
              [8.22879, 50.22376],
              [8.22793, 50.22464],
              [8.22609, 50.22608],
              [8.22591, 50.22627],
              [8.22552, 50.22702],
              [8.22549, 50.22716],
              [8.22556, 50.22789],
              [8.22559, 50.22859],
              [8.22509, 50.22924],
              [8.22373, 50.23064],
              [8.22304, 50.23094],
              [8.2221, 50.23103],
              [8.22048, 50.23089],
              [8.21983, 50.23083],
              [8.21883, 50.23073],
              [8.21784, 50.23064],
              [8.21632, 50.23034],
              [8.21551, 50.22993],
              [8.21503, 50.22936],
              [8.21484, 50.2289],
              [8.21466, 50.22853],
              [8.21472, 50.22796],
              [8.21476, 50.22772],
              [8.21465, 50.22772],
              [8.21373, 50.2271],
              [8.21333, 50.22661],
              [8.21304, 50.22643],
              [8.21319, 50.22623],
              [8.21301, 50.22618],
              [8.21257, 50.22597],
              [8.21183, 50.22577],
              [8.21161, 50.22578],
              [8.21123, 50.22587],
              [8.20865, 50.22639],
              [8.20854, 50.22641],
              [8.2079, 50.2266],
              [8.20736, 50.22666],
              [8.20724, 50.22644],
              [8.207849, 50.225046],
              [8.20807, 50.22454],
              [8.20772, 50.22442],
              [8.20708, 50.22419],
              [8.20656, 50.22402],
              [8.20565, 50.22369],
              [8.20523, 50.22353],
              [8.20461, 50.22317],
              [8.20432, 50.22277],
              [8.2041, 50.22178],
              [8.2041, 50.22158],
              [8.20436, 50.22093],
              [8.20451, 50.22064],
              [8.20485, 50.21986],
              [8.20488, 50.21978],
              [8.20495, 50.21948],
              [8.2049, 50.21918],
              [8.20301, 50.21848],
              [8.202324, 50.2183],
              [8.20145, 50.21807],
              [8.20102, 50.21796],
              [8.20012, 50.21781],
              [8.19975, 50.21777],
              [8.1995, 50.21774],
              [8.19858, 50.21766],
              [8.19835, 50.2176],
              [8.19829, 50.21774],
              [8.19843, 50.21779],
              [8.19863, 50.2184],
              [8.19923, 50.21965],
              [8.19954, 50.22028],
              [8.1995, 50.22037],
              [8.1988, 50.22052],
              [8.19856, 50.22057],
              [8.19738, 50.21807],
              [8.19719, 50.2175],
              [8.19717, 50.21719],
              [8.19727, 50.21675],
              [8.1974, 50.21646],
              [8.19817, 50.21495],
              [8.19838, 50.21459],
              [8.19929, 50.21339],
              [8.1998, 50.2128],
              [8.20041, 50.21199],
              [8.20061, 50.21134],
              [8.20073, 50.2093],
              [8.20075, 50.20878],
              [8.20067, 50.20835],
              [8.20059, 50.20714],
              [8.2006, 50.20698],
              [8.20015, 50.20707],
              [8.20009, 50.20711],
              [8.19905, 50.20784],
              [8.19824, 50.20808],
              [8.19759, 50.20812],
              [8.19678, 50.20801],
              [8.1963, 50.2079],
              [8.19451, 50.20734],
              [8.19366, 50.207],
              [8.19352, 50.20696],
              [8.19338, 50.20692],
              [8.19224, 50.2069],
              [8.19192, 50.20695],
              [8.19058, 50.2072],
              [8.19048, 50.20722],
              [8.18865, 50.20756],
              [8.18713, 50.20799],
              [8.18607, 50.20815],
              [8.18418, 50.20808],
              [8.18344, 50.20842],
              [8.18287, 50.20905],
              [8.18233, 50.20986],
              [8.18191, 50.21023],
              [8.18119, 50.2105],
              [8.18102, 50.21052],
              [8.17958, 50.21043],
              [8.17854, 50.21029],
              [8.17604, 50.20976],
              [8.17541, 50.20965],
              [8.17386, 50.2092],
              [8.17288, 50.20883],
              [8.17259, 50.2086],
              [8.17208, 50.20843],
              [8.17188, 50.20799],
              [8.17145, 50.20757],
              [8.17095, 50.20737],
              [8.1704, 50.20711],
              [8.1702, 50.2059],
              [8.17031, 50.20547],
              [8.17115, 50.20474],
              [8.17215, 50.20394],
              [8.17233, 50.20349],
              [8.17224, 50.20275],
              [8.1725, 50.20232],
              [8.17365, 50.20176],
              [8.17469, 50.20077],
              [8.17652, 50.19965],
              [8.17689, 50.19921],
              [8.17698, 50.19872],
              [8.17735, 50.19736],
              [8.17722, 50.19602],
              [8.17729, 50.19563],
              [8.1776, 50.19533],
              [8.17829, 50.19496],
              [8.17919, 50.19486],
              [8.18025, 50.19494],
              [8.18067, 50.19496],
              [8.18089, 50.19494],
              [8.18169, 50.19472],
              [8.18221, 50.19429],
              [8.18287, 50.19337],
              [8.18339, 50.19256],
              [8.18343, 50.19229],
              [8.18305, 50.19166],
              [8.18305, 50.19121],
              [8.18367, 50.18998],
              [8.18384, 50.18904],
              [8.18407, 50.18799],
              [8.18451, 50.18677],
              [8.18496, 50.18568],
              [8.18492, 50.18493],
              [8.18487, 50.1846],
              [8.18483, 50.18429],
              [8.1848, 50.184],
              [8.18484, 50.1837],
              [8.18493, 50.18342],
              [8.18512, 50.18284],
              [8.18515, 50.18275],
              [8.18527, 50.18241],
              [8.18545, 50.18178],
              [8.18552, 50.18154],
              [8.18554, 50.18129],
              [8.18594, 50.1812],
              [8.18629, 50.18117],
              [8.18679, 50.18115],
              [8.18657, 50.18073],
              [8.18732, 50.18063],
              [8.1876, 50.18059],
              [8.1874, 50.18033],
              [8.18732, 50.1802],
              [8.18712, 50.17953],
              [8.18712, 50.1793],
              [8.1868, 50.17839],
              [8.18688, 50.17587],
              [8.18748, 50.1747],
              [8.18743, 50.17399],
              [8.18743, 50.17284],
              [8.18743, 50.17204],
              [8.18678, 50.171],
              [8.1867, 50.17022],
              [8.18649, 50.16966],
              [8.18484, 50.1678],
              [8.18407, 50.16708],
              [8.18265, 50.16646],
              [8.18197, 50.16575],
              [8.18098, 50.16498],
              [8.17946, 50.16349],
              [8.17918, 50.16295],
              [8.17905, 50.16195],
              [8.17882, 50.16139],
              [8.17816, 50.16079],
              [8.17778, 50.16045],
              [8.17753, 50.15997],
              [8.17761, 50.15956],
              [8.17838, 50.15895],
              [8.17876, 50.1584],
              [8.17906, 50.15792],
              [8.17931, 50.15749],
              [8.17984, 50.15689],
              [8.18045, 50.1564],
              [8.18196, 50.15678],
              [8.18287, 50.15662],
              [8.18301, 50.15651],
              [8.18312, 50.15636],
              [8.1834, 50.156],
              [8.18349, 50.15589],
              [8.18377, 50.15523],
              [8.18491, 50.1556],
              [8.18515, 50.15602],
              [8.18542, 50.15588],
              [8.18567, 50.15521],
              [8.18604, 50.1547],
              [8.18615, 50.15455],
              [8.18633, 50.15414],
              [8.18606, 50.15378],
              [8.18607, 50.15374],
              [8.18669, 50.15344],
              [8.18754, 50.15328],
              [8.18836, 50.15317],
              [8.18872, 50.15312],
              [8.19121, 50.15274],
              [8.19168, 50.15269],
              [8.19192, 50.15265],
              [8.19323, 50.15247],
              [8.19359, 50.15244],
              [8.19446, 50.15239],
              [8.194928, 50.152367],
              [8.19606, 50.15231],
              [8.19744, 50.15224],
              [8.19754, 50.15223],
              [8.19866, 50.1521],
              [8.20029, 50.15194],
              [8.20162, 50.15176],
              [8.20211, 50.15164],
              [8.20335, 50.15131],
              [8.20427, 50.15106],
              [8.20505, 50.15075],
              [8.20565, 50.1505],
              [8.2065, 50.15015],
              [8.21445, 50.14681],
              [8.21519, 50.14654],
              [8.21599, 50.14652],
              [8.21628, 50.14581],
              [8.21644, 50.1454],
              [8.21673, 50.14464],
              [8.21743, 50.14327],
              [8.21829, 50.14154],
              [8.21887, 50.13977],
              [8.21897, 50.1387],
              [8.21893, 50.13766],
              [8.21884, 50.1371],
              [8.21879, 50.13686],
              [8.21862, 50.13629],
              [8.21832, 50.1356],
              [8.21822, 50.1354],
              [8.21809, 50.13516],
              [8.218069, 50.135119],
              [8.2179, 50.13479],
              [8.21757, 50.13433],
              [8.21705, 50.13369],
              [8.2167, 50.13332],
              [8.21639, 50.133],
              [8.21485, 50.13173],
              [8.20934, 50.12848],
              [8.20828, 50.12785],
              [8.20696, 50.12672],
              [8.20667, 50.12625],
              [8.20627, 50.12539],
              [8.20602, 50.12432],
              [8.2064, 50.12219],
              [8.20639, 50.12082],
              [8.20595, 50.11965],
              [8.20556, 50.11886],
              [8.2054, 50.11825],
              [8.20555, 50.11747],
              [8.20564, 50.11723],
              [8.20626, 50.11658],
              [8.2079, 50.11568],
              [8.20862, 50.11522],
              [8.20884, 50.11505],
              [8.20908, 50.1148],
              [8.20934, 50.11423],
              [8.20966, 50.11298],
              [8.21022, 50.11076],
              [8.21031, 50.11047],
              [8.21051, 50.10985],
              [8.21116, 50.1089],
              [8.21461, 50.10464],
              [8.21682, 50.10187],
              [8.21718, 50.10136],
              [8.21829, 50.09974],
              [8.21839, 50.09958],
              [8.21908, 50.09855],
              [8.21933, 50.09819],
              [8.21978, 50.09757],
              [8.22025, 50.09698],
              [8.221, 50.0962],
              [8.22112, 50.09604],
              [8.22164, 50.09517],
              [8.22196, 50.09464],
              [8.22261, 50.094135],
              [8.22308, 50.09377],
              [8.22324, 50.09368],
              [8.22421, 50.09335],
              [8.22482, 50.09304],
              [8.22537, 50.09277],
              [8.22632, 50.09232],
              [8.22697, 50.09198],
              [8.227802, 50.091053],
              [8.22811, 50.09071],
              [8.22843, 50.09032],
              [8.22887, 50.08983],
              [8.22935, 50.08924],
              [8.23004, 50.08851],
              [8.2305, 50.08815],
              [8.23082, 50.08792],
              [8.23096, 50.08782],
              [8.23169, 50.08729],
              [8.23185, 50.08717],
              [8.2325, 50.0867],
              [8.23314, 50.08623],
              [8.23366, 50.08578],
              [8.23501, 50.08459],
              [8.23593, 50.08379],
              [8.23603, 50.08364],
              [8.23606, 50.08328],
              [8.23607, 50.08306],
              [8.23611, 50.08276],
              [8.23615, 50.08241],
              [8.23617, 50.08226],
              [8.23623, 50.08182],
              [8.23625, 50.08138],
              [8.23627, 50.08116],
              [8.2363, 50.08098],
              [8.23634, 50.08078],
              [8.23639, 50.08043],
              [8.23643, 50.08018],
              [8.23639, 50.07893],
              [8.23644, 50.07835],
              [8.236676, 50.077684],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
            ],
          ],
        },
        id: "g3MDc",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "U1Mzc",
    name: "269 Idstein <> Wiesbaden",
    lineName: "269",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.23674, 50.07786],
              [8.23658, 50.07894],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.2363, 50.08098],
              [8.23627, 50.08116],
              [8.23625, 50.08138],
              [8.23623, 50.08182],
              [8.23617, 50.08226],
              [8.23615, 50.08241],
              [8.23611, 50.08276],
              [8.23607, 50.08306],
              [8.23606, 50.08328],
              [8.23603, 50.08364],
              [8.23593, 50.08379],
              [8.23501, 50.08459],
              [8.23366, 50.08578],
              [8.23314, 50.08623],
              [8.2325, 50.0867],
              [8.23185, 50.08717],
              [8.23169, 50.08729],
              [8.23096, 50.08782],
              [8.23082, 50.08792],
              [8.2305, 50.08815],
              [8.23004, 50.08851],
              [8.22935, 50.08924],
              [8.22887, 50.08983],
              [8.22843, 50.09032],
              [8.22811, 50.09071],
              [8.227802, 50.091053],
              [8.22697, 50.09198],
              [8.22632, 50.09232],
              [8.22537, 50.09277],
              [8.22482, 50.09304],
              [8.22421, 50.09335],
              [8.22324, 50.09368],
              [8.22308, 50.09377],
              [8.22261, 50.094135],
              [8.22196, 50.09464],
              [8.22164, 50.09517],
              [8.22112, 50.09604],
              [8.221, 50.0962],
              [8.22114, 50.09634],
              [8.22116, 50.09698],
              [8.22089, 50.09707],
              [8.22025, 50.09698],
              [8.21978, 50.09757],
              [8.21933, 50.09819],
              [8.21908, 50.09855],
              [8.21839, 50.09958],
              [8.21829, 50.09974],
              [8.21718, 50.10136],
              [8.21682, 50.10187],
              [8.21461, 50.10464],
              [8.21116, 50.1089],
              [8.21051, 50.10985],
              [8.21031, 50.11047],
              [8.21022, 50.11076],
              [8.20966, 50.11298],
              [8.20934, 50.11423],
              [8.20908, 50.1148],
              [8.20884, 50.11505],
              [8.20862, 50.11522],
              [8.2079, 50.11568],
              [8.20626, 50.11658],
              [8.20564, 50.11723],
              [8.20555, 50.11747],
              [8.2054, 50.11825],
              [8.20556, 50.11886],
              [8.20595, 50.11965],
              [8.20639, 50.12082],
              [8.2064, 50.12219],
              [8.20602, 50.12432],
              [8.20627, 50.12539],
              [8.20667, 50.12625],
              [8.20696, 50.12672],
              [8.20828, 50.12785],
              [8.20934, 50.12848],
              [8.21485, 50.13173],
              [8.21639, 50.133],
              [8.2167, 50.13332],
              [8.21705, 50.13369],
              [8.21757, 50.13433],
              [8.2179, 50.13479],
              [8.218069, 50.135119],
              [8.21809, 50.13516],
              [8.21822, 50.1354],
              [8.21832, 50.1356],
              [8.21862, 50.13629],
              [8.21879, 50.13686],
              [8.21884, 50.1371],
              [8.21893, 50.13766],
              [8.21897, 50.1387],
              [8.21887, 50.13977],
              [8.21829, 50.14154],
              [8.21743, 50.14327],
              [8.21673, 50.14464],
              [8.21644, 50.1454],
              [8.21628, 50.14581],
              [8.21599, 50.14652],
              [8.21578, 50.14695],
              [8.21554, 50.14735],
              [8.2144, 50.14897],
              [8.21378, 50.14977],
              [8.21329, 50.15051],
              [8.21294, 50.15153],
              [8.21238, 50.15333],
              [8.21206, 50.15515],
              [8.212029, 50.156666],
              [8.21202, 50.15708],
              [8.21202, 50.15717],
              [8.21202, 50.15773],
              [8.21214, 50.15905],
              [8.21233, 50.15953],
              [8.21277, 50.16031],
              [8.21309, 50.16071],
              [8.21277, 50.16031],
              [8.21233, 50.15953],
              [8.21214, 50.15905],
              [8.21202, 50.15773],
              [8.21214, 50.15905],
              [8.21233, 50.15953],
              [8.21277, 50.16031],
              [8.21309, 50.16071],
              [8.21266, 50.16084],
              [8.21243, 50.16125],
              [8.21235, 50.16307],
              [8.21233, 50.1637],
              [8.21236, 50.16427],
              [8.21246, 50.16481],
              [8.21276, 50.16616],
              [8.21285, 50.16646],
              [8.21321, 50.16724],
              [8.21343, 50.16775],
              [8.21348, 50.16796],
              [8.21344, 50.16889],
              [8.21338, 50.16916],
              [8.21318, 50.16999],
              [8.21314, 50.17005],
              [8.21306, 50.17017],
              [8.21251, 50.17048],
              [8.21192, 50.17061],
              [8.21151, 50.17065],
              [8.21144, 50.17078],
              [8.2114, 50.17079],
              [8.211664, 50.170985],
              [8.21224, 50.17141],
              [8.21243, 50.17149],
              [8.21328, 50.17183],
              [8.21388, 50.17207],
              [8.21442, 50.17228],
              [8.21517, 50.17257],
              [8.21548, 50.1727],
              [8.21751, 50.17349],
              [8.21848, 50.17384],
              [8.22, 50.17443],
              [8.21934, 50.17509],
              [8.219188, 50.17515],
              [8.21865, 50.17536],
              [8.21867, 50.1755],
              [8.21928, 50.17611],
              [8.2203, 50.17742],
              [8.22278, 50.17721],
              [8.2203, 50.17742],
              [8.21928, 50.17611],
              [8.21867, 50.1755],
              [8.21865, 50.17536],
              [8.219188, 50.17515],
              [8.21934, 50.17509],
              [8.22, 50.17443],
              [8.22165, 50.17506],
              [8.22229, 50.1753],
              [8.22274, 50.17548],
              [8.22431, 50.17629],
              [8.22523, 50.17703],
              [8.22604, 50.17788],
              [8.22631, 50.17818],
              [8.22727, 50.17943],
              [8.22762, 50.18012],
              [8.22759, 50.18056],
              [8.22667, 50.18185],
              [8.22657, 50.1823],
              [8.22665, 50.18259],
              [8.22697, 50.18307],
              [8.22727, 50.18367],
              [8.22745, 50.18497],
              [8.22753, 50.18509],
              [8.22792, 50.18541],
              [8.22853, 50.18562],
              [8.2295, 50.18594],
              [8.22957, 50.18596],
              [8.23025, 50.18639],
              [8.23046, 50.18664],
              [8.23056, 50.18688],
              [8.23053, 50.1874],
              [8.23024, 50.18813],
              [8.23022, 50.18841],
              [8.23035, 50.18883],
              [8.23053, 50.18908],
              [8.23069, 50.18926],
              [8.23119, 50.18957],
              [8.23162, 50.18964],
              [8.23207, 50.18957],
              [8.2325, 50.18932],
              [8.23266, 50.18908],
              [8.23274, 50.18871],
              [8.23285, 50.18848],
              [8.23308, 50.18829],
              [8.23379, 50.18806],
              [8.23482, 50.1881],
              [8.23514, 50.18819],
              [8.2355, 50.18837],
              [8.23583, 50.18862],
              [8.23715, 50.18946],
              [8.23753, 50.18971],
              [8.23802, 50.18981],
              [8.23824, 50.18987],
              [8.23893, 50.19013],
              [8.23975, 50.19034],
              [8.24046, 50.19044],
              [8.24159, 50.19061],
              [8.24251, 50.19096],
              [8.24285, 50.19128],
              [8.24299, 50.19176],
              [8.24299, 50.19262],
              [8.24299, 50.19294],
              [8.24303, 50.19388],
              [8.24342, 50.19509],
              [8.24385, 50.19581],
              [8.24407, 50.19618],
              [8.24453, 50.1972],
              [8.24457, 50.19779],
              [8.24446, 50.19871],
              [8.24451, 50.19948],
              [8.24463, 50.19996],
              [8.24487, 50.20088],
              [8.24486, 50.20145],
              [8.24484, 50.202],
              [8.24507, 50.20283],
              [8.24562, 50.20327],
              [8.24669, 50.20358],
              [8.24771, 50.20374],
              [8.24858, 50.20392],
              [8.2497, 50.20424],
              [8.25099, 50.20479],
              [8.25142, 50.205],
              [8.25159, 50.2051],
              [8.25213, 50.20546],
              [8.25255, 50.20572],
              [8.25309, 50.20608],
              [8.25326, 50.2062],
              [8.25344, 50.20639],
              [8.25378, 50.2069],
              [8.25388, 50.20718],
              [8.25392, 50.20748],
              [8.25381, 50.20806],
              [8.25368, 50.20833],
              [8.25356, 50.20847],
              [8.2537, 50.2086],
              [8.25367, 50.20867],
              [8.25383, 50.20872],
              [8.25404, 50.20872],
              [8.25462, 50.20859],
              [8.25496, 50.20825],
              [8.25512, 50.20762],
              [8.25535, 50.2066],
              [8.25556, 50.20638],
              [8.25588, 50.20629],
              [8.25652, 50.20637],
              [8.2573, 50.20657],
              [8.25887, 50.20695],
              [8.25931, 50.20703],
              [8.25945, 50.20705],
              [8.25965, 50.20717],
              [8.25965, 50.20726],
              [8.25972, 50.20785],
              [8.25977, 50.20792],
              [8.2601, 50.20808],
              [8.26047, 50.20812],
              [8.26081, 50.20805],
              [8.26093, 50.20799],
              [8.26126, 50.20758],
              [8.26172, 50.2073],
              [8.26283, 50.2074],
              [8.26289, 50.20753],
              [8.26296, 50.20755],
              [8.26271, 50.20813],
              [8.262561, 50.208336],
              [8.26271, 50.20813],
              [8.26296, 50.20755],
              [8.26316, 50.20747],
              [8.26753, 50.20835],
              [8.2678, 50.20828],
              [8.26789, 50.20842],
              [8.2681, 50.20846],
              [8.27005, 50.20907],
              [8.27119, 50.20954],
              [8.27134, 50.20948],
              [8.27149, 50.20954],
              [8.27167, 50.20952],
              [8.27201, 50.20946],
              [8.27288, 50.20922],
              [8.27435, 50.20836],
              [8.27507, 50.20796],
              [8.27585, 50.20782],
              [8.27653, 50.20789],
              [8.27714, 50.20813],
              [8.27833, 50.20859],
              [8.27872, 50.20863],
              [8.27894, 50.20863],
              [8.27913, 50.20876],
              [8.27904, 50.20938],
              [8.27881, 50.20989],
              [8.27784, 50.21087],
              [8.27621, 50.21235],
              [8.27585, 50.21298],
              [8.27578, 50.21325],
              [8.27531, 50.21328],
              [8.27513, 50.21333],
              [8.274305, 50.214191],
              [8.27378, 50.21474],
              [8.27298, 50.21558],
              [8.27222, 50.21646],
              [8.27211, 50.21672],
              [8.27208, 50.21684],
              [8.2726, 50.21704],
              [8.27297, 50.2173],
              [8.27311, 50.21757],
              [8.27368, 50.21805],
              [8.27438, 50.21863],
              [8.27489, 50.21903],
              [8.27529, 50.21918],
              [8.27474, 50.21957],
              [8.27463, 50.21965],
              [8.27465, 50.21979],
              [8.27474, 50.21983],
              [8.27521, 50.22012],
              [8.27528, 50.22041],
              [8.27498, 50.22072],
              [8.27471, 50.22086],
              [8.27461, 50.22091],
              [8.27431, 50.22108],
              [8.27284, 50.22182],
              [8.271394, 50.222602],
              [8.27125, 50.22268],
              [8.27119, 50.22303],
              [8.27121, 50.22309],
              [8.27111, 50.22319],
              [8.27101, 50.22321],
              [8.27051, 50.22335],
              [8.26949, 50.22319],
              [8.26918, 50.22292],
              [8.26917, 50.22283],
              [8.26911, 50.22282],
              [8.26906, 50.22271],
              [8.26897, 50.22265],
              [8.2687, 50.22254],
              [8.26837, 50.22243],
              [8.268, 50.22231],
              [8.26732, 50.22207],
              [8.267298, 50.222062],
              [8.26701, 50.22195],
              [8.26685, 50.22189],
              [8.26678, 50.22181],
              [8.26682, 50.22167],
              [8.26686, 50.2216],
              [8.267131, 50.221318],
              [8.26734, 50.2211],
              [8.26757, 50.22087],
              [8.26759, 50.22076],
              [8.26741, 50.22054],
              [8.26727, 50.21998],
              [8.26726, 50.21989],
              [8.26717, 50.21984],
              [8.26726, 50.21977],
              [8.26722, 50.21968],
              [8.26714, 50.21944],
              [8.26699, 50.21879],
              [8.26677, 50.21835],
              [8.26626, 50.21799],
              [8.2662, 50.21797],
              [8.26614, 50.21795],
              [8.2648, 50.21785],
              [8.26386, 50.21779],
              [8.262442, 50.217716],
              [8.26175, 50.21768],
              [8.26028, 50.2176],
              [8.26012, 50.21759],
              [8.25976, 50.21756],
              [8.25943, 50.21754],
              [8.25928, 50.21753],
              [8.25914, 50.21752],
              [8.25896, 50.21747],
              [8.25791, 50.2169],
              [8.25783, 50.21672],
              [8.2577, 50.21666],
              [8.257779, 50.216326],
            ],
          ],
        },
        id: "U1Mzc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.257779, 50.216326],
              [8.2577, 50.21666],
              [8.25783, 50.21672],
              [8.25851, 50.21494],
              [8.258578, 50.213899],
              [8.2586, 50.21356],
              [8.25856, 50.21307],
              [8.25781, 50.21294],
              [8.25792, 50.21261],
              [8.25945, 50.20825],
              [8.25958, 50.20803],
              [8.25977, 50.20792],
              [8.25972, 50.20785],
              [8.25965, 50.20726],
              [8.25965, 50.20717],
              [8.25945, 50.20705],
              [8.25931, 50.20703],
              [8.25887, 50.20695],
              [8.2573, 50.20657],
              [8.25652, 50.20637],
              [8.25588, 50.20629],
              [8.25556, 50.20638],
              [8.25535, 50.2066],
              [8.25512, 50.20762],
              [8.25496, 50.20825],
              [8.25462, 50.20859],
              [8.25404, 50.20872],
              [8.25383, 50.20872],
              [8.25367, 50.20867],
              [8.2537, 50.2086],
              [8.25356, 50.20847],
              [8.25368, 50.20833],
              [8.25381, 50.20806],
              [8.25392, 50.20748],
              [8.25388, 50.20718],
              [8.25378, 50.2069],
              [8.25344, 50.20639],
              [8.25326, 50.2062],
              [8.25309, 50.20608],
              [8.25255, 50.20572],
              [8.25213, 50.20546],
              [8.25159, 50.2051],
              [8.25142, 50.205],
              [8.25099, 50.20479],
              [8.2497, 50.20424],
              [8.24858, 50.20392],
              [8.24771, 50.20374],
              [8.24669, 50.20358],
              [8.24562, 50.20327],
              [8.24507, 50.20283],
              [8.24484, 50.202],
              [8.24486, 50.20145],
              [8.24487, 50.20088],
              [8.24463, 50.19996],
              [8.24451, 50.19948],
              [8.24446, 50.19871],
              [8.24457, 50.19779],
              [8.24453, 50.1972],
              [8.24407, 50.19618],
              [8.24385, 50.19581],
              [8.24342, 50.19509],
              [8.24303, 50.19388],
              [8.24299, 50.19294],
              [8.24299, 50.19262],
              [8.24299, 50.19176],
              [8.24285, 50.19128],
              [8.24251, 50.19096],
              [8.24159, 50.19061],
              [8.24046, 50.19044],
              [8.23975, 50.19034],
              [8.23893, 50.19013],
              [8.23824, 50.18987],
              [8.23802, 50.18981],
              [8.23753, 50.18971],
              [8.23715, 50.18946],
              [8.23583, 50.18862],
              [8.2355, 50.18837],
              [8.23514, 50.18819],
              [8.23482, 50.1881],
              [8.23379, 50.18806],
              [8.23308, 50.18829],
              [8.23285, 50.18848],
              [8.23274, 50.18871],
              [8.23266, 50.18908],
              [8.2325, 50.18932],
              [8.23207, 50.18957],
              [8.23162, 50.18964],
              [8.23119, 50.18957],
              [8.23069, 50.18926],
              [8.23053, 50.18908],
              [8.23035, 50.18883],
              [8.23022, 50.18841],
              [8.23024, 50.18813],
              [8.23053, 50.1874],
              [8.23056, 50.18688],
              [8.23046, 50.18664],
              [8.23025, 50.18639],
              [8.22957, 50.18596],
              [8.2295, 50.18594],
              [8.22853, 50.18562],
              [8.22792, 50.18541],
              [8.22753, 50.18509],
              [8.22745, 50.18497],
              [8.22727, 50.18367],
              [8.22697, 50.18307],
              [8.22665, 50.18259],
              [8.22657, 50.1823],
              [8.22667, 50.18185],
              [8.22759, 50.18056],
              [8.22762, 50.18012],
              [8.22727, 50.17943],
              [8.22631, 50.17818],
              [8.22604, 50.17788],
              [8.22523, 50.17703],
              [8.22431, 50.17629],
              [8.22274, 50.17548],
              [8.22229, 50.1753],
              [8.22165, 50.17506],
              [8.22, 50.17443],
              [8.21934, 50.17509],
              [8.219188, 50.17515],
              [8.21865, 50.17536],
              [8.21867, 50.1755],
              [8.21928, 50.17611],
              [8.2203, 50.17742],
              [8.22278, 50.17721],
              [8.2203, 50.17742],
              [8.21928, 50.17611],
              [8.21867, 50.1755],
              [8.21865, 50.17536],
              [8.219188, 50.17515],
              [8.21934, 50.17509],
              [8.22, 50.17443],
              [8.21848, 50.17384],
              [8.21751, 50.17349],
              [8.21548, 50.1727],
              [8.21517, 50.17257],
              [8.21442, 50.17228],
              [8.21388, 50.17207],
              [8.21328, 50.17183],
              [8.21243, 50.17149],
              [8.21224, 50.17141],
              [8.211664, 50.170985],
              [8.2114, 50.17079],
              [8.21144, 50.17078],
              [8.21151, 50.17065],
              [8.21192, 50.17061],
              [8.21251, 50.17048],
              [8.21306, 50.17017],
              [8.21314, 50.17005],
              [8.21318, 50.16999],
              [8.21338, 50.16916],
              [8.21344, 50.16889],
              [8.21348, 50.16796],
              [8.21343, 50.16775],
              [8.21321, 50.16724],
              [8.21285, 50.16646],
              [8.21276, 50.16616],
              [8.21246, 50.16481],
              [8.21236, 50.16427],
              [8.21233, 50.1637],
              [8.21235, 50.16307],
              [8.21243, 50.16125],
              [8.21266, 50.16084],
              [8.21309, 50.16071],
              [8.21277, 50.16031],
              [8.21233, 50.15953],
              [8.21214, 50.15905],
              [8.21202, 50.15773],
              [8.21202, 50.15717],
              [8.21202, 50.15708],
              [8.212029, 50.156666],
              [8.21206, 50.15515],
              [8.21238, 50.15333],
              [8.21294, 50.15153],
              [8.21329, 50.15051],
              [8.21378, 50.14977],
              [8.2144, 50.14897],
              [8.21554, 50.14735],
              [8.21578, 50.14695],
              [8.21599, 50.14652],
              [8.21628, 50.14581],
              [8.21644, 50.1454],
              [8.21673, 50.14464],
              [8.21743, 50.14327],
              [8.21829, 50.14154],
              [8.21887, 50.13977],
              [8.21897, 50.1387],
              [8.21893, 50.13766],
              [8.21884, 50.1371],
              [8.21879, 50.13686],
              [8.21862, 50.13629],
              [8.21832, 50.1356],
              [8.21822, 50.1354],
              [8.21809, 50.13516],
              [8.218069, 50.135119],
              [8.2179, 50.13479],
              [8.21757, 50.13433],
              [8.21705, 50.13369],
              [8.2167, 50.13332],
              [8.21639, 50.133],
              [8.21485, 50.13173],
              [8.20934, 50.12848],
              [8.20828, 50.12785],
              [8.20696, 50.12672],
              [8.20667, 50.12625],
              [8.20627, 50.12539],
              [8.20602, 50.12432],
              [8.2064, 50.12219],
              [8.20639, 50.12082],
              [8.20595, 50.11965],
              [8.20556, 50.11886],
              [8.2054, 50.11825],
              [8.20555, 50.11747],
              [8.20564, 50.11723],
              [8.20626, 50.11658],
              [8.2079, 50.11568],
              [8.20862, 50.11522],
              [8.20884, 50.11505],
              [8.20908, 50.1148],
              [8.20934, 50.11423],
              [8.20966, 50.11298],
              [8.21022, 50.11076],
              [8.21031, 50.11047],
              [8.21051, 50.10985],
              [8.21116, 50.1089],
              [8.21461, 50.10464],
              [8.21682, 50.10187],
              [8.21718, 50.10136],
              [8.21829, 50.09974],
              [8.21839, 50.09958],
              [8.21908, 50.09855],
              [8.21933, 50.09819],
              [8.21978, 50.09757],
              [8.22025, 50.09698],
              [8.22089, 50.09707],
              [8.22116, 50.09698],
              [8.22114, 50.09634],
              [8.221, 50.0962],
              [8.22112, 50.09604],
              [8.22164, 50.09517],
              [8.22196, 50.09464],
              [8.22261, 50.094135],
              [8.22308, 50.09377],
              [8.22324, 50.09368],
              [8.22421, 50.09335],
              [8.22482, 50.09304],
              [8.22537, 50.09277],
              [8.22632, 50.09232],
              [8.22697, 50.09198],
              [8.227802, 50.091053],
              [8.22811, 50.09071],
              [8.22843, 50.09032],
              [8.22887, 50.08983],
              [8.22935, 50.08924],
              [8.23004, 50.08851],
              [8.2305, 50.08815],
              [8.23082, 50.08792],
              [8.23096, 50.08782],
              [8.23169, 50.08729],
              [8.23185, 50.08717],
              [8.2325, 50.0867],
              [8.23314, 50.08623],
              [8.23366, 50.08578],
              [8.23501, 50.08459],
              [8.23593, 50.08379],
              [8.23582, 50.08338],
              [8.23581, 50.08327],
              [8.23583, 50.08285],
              [8.23588, 50.08241],
              [8.2359, 50.08223],
              [8.23598, 50.08168],
              [8.23605, 50.0813],
              [8.23609, 50.08107],
              [8.23612, 50.08088],
              [8.23622, 50.08024],
              [8.23623, 50.08016],
              [8.23639, 50.07893],
              [8.23644, 50.07835],
              [8.236676, 50.077684],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
            ],
          ],
        },
        id: "M0MjY",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "czMjI",
    name: "262 Hofheim <> Wiesbaden",
    lineName: "262",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.25112, 50.07194],
              [8.25181, 50.07198],
              [8.25311, 50.07204],
              [8.25403, 50.07209],
              [8.25487, 50.07212],
              [8.25494, 50.07212],
              [8.25662, 50.07215],
              [8.25749, 50.07199],
              [8.25808, 50.07153],
              [8.26015, 50.06999],
              [8.26098, 50.06939],
              [8.26144, 50.06909],
              [8.2622, 50.06868],
              [8.26323, 50.06821],
              [8.26469, 50.06766],
              [8.2676, 50.06659],
              [8.2685, 50.06624],
              [8.2716, 50.06513],
              [8.27346, 50.06446],
              [8.27435, 50.06412],
              [8.27631, 50.06341],
              [8.27966, 50.06219],
              [8.28254, 50.0609],
              [8.28415, 50.0598],
              [8.28489, 50.05911],
              [8.28598, 50.05764],
              [8.28659, 50.05657],
              [8.28676, 50.05631],
              [8.2874, 50.05518],
              [8.28844, 50.05372],
              [8.28981, 50.05228],
              [8.29017, 50.0518],
              [8.29012, 50.05131],
              [8.28998, 50.05104],
              [8.28972, 50.05032],
              [8.28985, 50.04994],
              [8.29023, 50.04962],
              [8.29093, 50.0494],
              [8.29236, 50.04955],
              [8.29338, 50.04963],
              [8.29456, 50.0496],
              [8.29715, 50.0496],
              [8.29916, 50.0499],
              [8.30033, 50.0501],
              [8.3007, 50.05018],
              [8.3031, 50.0509],
              [8.3052, 50.05186],
              [8.30705, 50.05277],
              [8.30748, 50.05297],
              [8.30808, 50.05325],
              [8.31036, 50.05425],
              [8.31094, 50.05448],
              [8.31242, 50.05505],
              [8.31392, 50.05547],
              [8.31523, 50.05573],
              [8.31813, 50.05601],
              [8.3239, 50.05579],
              [8.32604, 50.05575],
              [8.33122, 50.05574],
              [8.33457, 50.05573],
              [8.33636, 50.05573],
              [8.33726, 50.05572],
              [8.33845, 50.05555],
              [8.33916, 50.05552],
              [8.3402, 50.05556],
              [8.341, 50.05555],
              [8.34143, 50.05539],
              [8.34152, 50.05514],
              [8.34124, 50.05484],
              [8.34055, 50.05488],
              [8.33997, 50.05527],
              [8.33985, 50.05571],
              [8.34008, 50.05611],
              [8.34045, 50.05646],
              [8.34102, 50.05702],
              [8.34121, 50.05731],
              [8.34144, 50.05789],
              [8.34209, 50.05908],
              [8.34264, 50.05962],
              [8.344158, 50.060618],
              [8.34603, 50.06185],
              [8.34615, 50.06195],
              [8.34755, 50.06329],
              [8.34798, 50.0637],
              [8.34845, 50.0642],
              [8.35124, 50.06393],
              [8.35202, 50.06387],
              [8.35261, 50.06379],
              [8.35347, 50.06367],
              [8.35356, 50.06364],
              [8.35395, 50.06366],
              [8.35477, 50.06357],
              [8.35677, 50.06328],
              [8.35746, 50.06318],
              [8.35936, 50.06292],
              [8.36072, 50.06271],
              [8.36368, 50.06229],
              [8.36499, 50.06211],
              [8.36516, 50.06256],
              [8.36534, 50.06312],
              [8.36709, 50.06393],
              [8.367509, 50.064107],
              [8.36832, 50.06445],
              [8.3692, 50.06474],
              [8.36946, 50.06434],
              [8.37066, 50.06468],
              [8.37193, 50.06505],
              [8.3724, 50.06429],
              [8.37264, 50.06396],
              [8.37341, 50.06416],
              [8.37407, 50.06435],
              [8.37446, 50.06444],
              [8.37459, 50.06428],
              [8.37498, 50.06391],
              [8.375464, 50.063281],
              [8.37568, 50.063],
              [8.37603, 50.06249],
              [8.37662, 50.06223],
              [8.37751, 50.06172],
              [8.37787, 50.06103],
              [8.37921, 50.06108],
              [8.37991, 50.06118],
              [8.38009, 50.0612],
              [8.38034, 50.06123],
              [8.38102, 50.06154],
              [8.38147, 50.06185],
              [8.3821, 50.06273],
              [8.38219, 50.06311],
              [8.3822, 50.06327],
              [8.38198, 50.06387],
              [8.38126, 50.0648],
              [8.3811, 50.0651],
              [8.38069, 50.06607],
              [8.38049, 50.06637],
              [8.37957, 50.06733],
              [8.37762, 50.06886],
              [8.37786, 50.06908],
              [8.37972, 50.07086],
              [8.38042, 50.07178],
              [8.38197, 50.07117],
              [8.381477, 50.070654],
              [8.38051, 50.06964],
              [8.38078, 50.06905],
              [8.38146, 50.06766],
              [8.38193, 50.06662],
              [8.38215, 50.06634],
              [8.38215, 50.06634],
              [8.3838, 50.06666],
              [8.38429, 50.06543],
              [8.384732, 50.064558],
              [8.38502, 50.06399],
              [8.3849, 50.06299],
              [8.38604, 50.06294],
              [8.38692, 50.06289],
              [8.39212, 50.06288],
              [8.39387, 50.06279],
              [8.39731, 50.06255],
              [8.39988, 50.06271],
              [8.40109, 50.06255],
              [8.40177, 50.06233],
              [8.40225, 50.06222],
              [8.40355, 50.06217],
              [8.40424, 50.06215],
              [8.40767, 50.062],
              [8.40909, 50.06196],
              [8.41015, 50.0619],
              [8.41075, 50.06183],
              [8.41178, 50.06162],
              [8.41189, 50.0615],
              [8.41178, 50.05999],
              [8.41175, 50.05978],
              [8.41183, 50.0593],
              [8.41217, 50.05883],
              [8.41242, 50.05879],
              [8.41255, 50.05883],
              [8.41362, 50.05921],
              [8.41437, 50.05951],
              [8.4153, 50.05986],
              [8.41637, 50.06028],
              [8.41671, 50.06041],
              [8.41722, 50.06061],
              [8.41801, 50.06091],
              [8.41859, 50.06111],
              [8.4199, 50.06157],
              [8.42012, 50.06164],
              [8.42085, 50.06196],
              [8.42098, 50.06201],
              [8.42111, 50.06206],
              [8.42169, 50.06229],
              [8.42233, 50.06253],
              [8.42249, 50.06259],
              [8.4226, 50.06264],
              [8.423248, 50.062898],
              [8.42378, 50.06311],
              [8.42384, 50.06314],
              [8.42468, 50.06378],
              [8.42494, 50.06416],
              [8.4251, 50.06439],
              [8.42547, 50.0648],
              [8.42606, 50.06519],
              [8.42828, 50.06631],
              [8.42967, 50.06733],
              [8.43032, 50.06771],
              [8.43127, 50.06798],
              [8.43126, 50.06841],
              [8.43186, 50.06884],
              [8.43258, 50.0694],
              [8.4327, 50.06958],
              [8.43303, 50.07006],
              [8.43318, 50.0703],
              [8.4336, 50.07096],
              [8.4338, 50.07134],
              [8.43415, 50.0718],
              [8.43432, 50.072],
              [8.43494, 50.07268],
              [8.43513, 50.07289],
              [8.43707, 50.07413],
              [8.43716, 50.07414],
              [8.43715, 50.07438],
              [8.43718, 50.07453],
              [8.437283, 50.074895],
              [8.4376, 50.07602],
              [8.43784, 50.07677],
              [8.43794, 50.07738],
              [8.43796, 50.07749],
              [8.438289, 50.078918],
              [8.43838, 50.07931],
              [8.4384, 50.07942],
              [8.43846, 50.07973],
              [8.43859, 50.08033],
              [8.43873, 50.08063],
              [8.43909, 50.08102],
              [8.43932, 50.08124],
              [8.43954, 50.08146],
              [8.43986, 50.08178],
              [8.44054, 50.08244],
              [8.44071, 50.08264],
              [8.44115, 50.08338],
              [8.44127, 50.08371],
              [8.44133, 50.08408],
              [8.44135, 50.0842],
              [8.44136, 50.08429],
              [8.44171, 50.08476],
              [8.44176, 50.0848],
              [8.44228, 50.08499],
              [8.44303, 50.08489],
              [8.44351, 50.08495],
              [8.44363, 50.08496],
              [8.44385, 50.08494],
              [8.44407, 50.08482],
              [8.445096, 50.084466],
            ],
          ],
        },
        id: "czMjI",
        direction: "INBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.445096, 50.084466],
              [8.44407, 50.08482],
              [8.44385, 50.08494],
              [8.44363, 50.08496],
              [8.44351, 50.08495],
              [8.44303, 50.08489],
              [8.44228, 50.08499],
              [8.44176, 50.0848],
              [8.44171, 50.08476],
              [8.44136, 50.08429],
              [8.44135, 50.0842],
              [8.44133, 50.08408],
              [8.44127, 50.08371],
              [8.44115, 50.08338],
              [8.44071, 50.08264],
              [8.44054, 50.08244],
              [8.43986, 50.08178],
              [8.43954, 50.08146],
              [8.43932, 50.08124],
              [8.43909, 50.08102],
              [8.43873, 50.08063],
              [8.43859, 50.08033],
              [8.43846, 50.07973],
              [8.4384, 50.07942],
              [8.43838, 50.07931],
              [8.438289, 50.078918],
              [8.43796, 50.07749],
              [8.43794, 50.07738],
              [8.43784, 50.07677],
              [8.4376, 50.07602],
              [8.437283, 50.074895],
              [8.43718, 50.07453],
              [8.43715, 50.07438],
              [8.43716, 50.07414],
              [8.43707, 50.07413],
              [8.43513, 50.07289],
              [8.43494, 50.07268],
              [8.43432, 50.072],
              [8.43415, 50.0718],
              [8.4338, 50.07134],
              [8.4336, 50.07096],
              [8.43318, 50.0703],
              [8.43303, 50.07006],
              [8.4327, 50.06958],
              [8.43258, 50.0694],
              [8.43186, 50.06884],
              [8.43126, 50.06841],
              [8.43127, 50.06798],
              [8.43032, 50.06771],
              [8.42967, 50.06733],
              [8.42828, 50.06631],
              [8.42606, 50.06519],
              [8.42547, 50.0648],
              [8.4251, 50.06439],
              [8.42494, 50.06416],
              [8.42468, 50.06378],
              [8.42384, 50.06314],
              [8.42378, 50.06311],
              [8.423248, 50.062898],
              [8.4226, 50.06264],
              [8.42249, 50.06259],
              [8.42233, 50.06253],
              [8.42169, 50.06229],
              [8.42111, 50.06206],
              [8.42098, 50.06201],
              [8.42085, 50.06196],
              [8.42012, 50.06164],
              [8.4199, 50.06157],
              [8.41859, 50.06111],
              [8.41801, 50.06091],
              [8.41722, 50.06061],
              [8.41671, 50.06041],
              [8.41637, 50.06028],
              [8.4153, 50.05986],
              [8.41437, 50.05951],
              [8.41362, 50.05921],
              [8.41255, 50.05883],
              [8.41242, 50.05879],
              [8.41217, 50.05883],
              [8.41183, 50.0593],
              [8.41175, 50.05978],
              [8.41178, 50.05999],
              [8.41189, 50.0615],
              [8.41178, 50.06162],
              [8.41075, 50.06183],
              [8.41015, 50.0619],
              [8.40909, 50.06196],
              [8.40767, 50.062],
              [8.40424, 50.06215],
              [8.40355, 50.06217],
              [8.40225, 50.06222],
              [8.40177, 50.06233],
              [8.40109, 50.06255],
              [8.39988, 50.06271],
              [8.39731, 50.06255],
              [8.39387, 50.06279],
              [8.39212, 50.06288],
              [8.38692, 50.06289],
              [8.38604, 50.06294],
              [8.3849, 50.06299],
              [8.38502, 50.06399],
              [8.384732, 50.064558],
              [8.38429, 50.06543],
              [8.3838, 50.06666],
              [8.38215, 50.06634],
              [8.38215, 50.06634],
              [8.38193, 50.06662],
              [8.38146, 50.06766],
              [8.38078, 50.06905],
              [8.38051, 50.06964],
              [8.381477, 50.070654],
              [8.38197, 50.07117],
              [8.38042, 50.07178],
              [8.37972, 50.07086],
              [8.37786, 50.06908],
              [8.37762, 50.06886],
              [8.37957, 50.06733],
              [8.38049, 50.06637],
              [8.38069, 50.06607],
              [8.3811, 50.0651],
              [8.38126, 50.0648],
              [8.38198, 50.06387],
              [8.3822, 50.06327],
              [8.38219, 50.06311],
              [8.3821, 50.06273],
              [8.38147, 50.06185],
              [8.38102, 50.06154],
              [8.38034, 50.06123],
              [8.38009, 50.0612],
              [8.37991, 50.06118],
              [8.37921, 50.06108],
              [8.37787, 50.06103],
              [8.37751, 50.06172],
              [8.37662, 50.06223],
              [8.37603, 50.06249],
              [8.37568, 50.063],
              [8.375464, 50.063281],
              [8.37498, 50.06391],
              [8.37459, 50.06428],
              [8.37446, 50.06444],
              [8.37407, 50.06435],
              [8.37341, 50.06416],
              [8.37264, 50.06396],
              [8.3724, 50.06429],
              [8.37094, 50.06389],
              [8.36979, 50.0637],
              [8.36946, 50.06434],
              [8.3692, 50.06474],
              [8.36832, 50.06445],
              [8.367509, 50.064107],
              [8.36709, 50.06393],
              [8.36534, 50.06312],
              [8.36709, 50.06393],
              [8.36534, 50.06312],
              [8.36516, 50.06256],
              [8.36499, 50.06211],
              [8.36368, 50.06229],
              [8.36072, 50.06271],
              [8.35936, 50.06292],
              [8.35746, 50.06318],
              [8.35677, 50.06328],
              [8.35477, 50.06357],
              [8.35395, 50.06366],
              [8.35356, 50.06364],
              [8.35347, 50.06367],
              [8.35261, 50.06379],
              [8.35202, 50.06387],
              [8.35124, 50.06393],
              [8.34845, 50.0642],
              [8.34798, 50.0637],
              [8.34755, 50.06329],
              [8.34615, 50.06195],
              [8.34603, 50.06185],
              [8.344158, 50.060618],
              [8.34264, 50.05962],
              [8.34209, 50.05908],
              [8.34144, 50.05789],
              [8.34102, 50.05733],
              [8.34084, 50.05706],
              [8.34063, 50.05682],
              [8.34012, 50.05632],
              [8.33977, 50.05618],
              [8.33637, 50.05587],
              [8.33439, 50.05588],
              [8.32618, 50.0559],
              [8.32606, 50.0559],
              [8.32393, 50.05594],
              [8.31804, 50.05615],
              [8.31607, 50.056],
              [8.31372, 50.05558],
              [8.31321, 50.05546],
              [8.31267, 50.05548],
              [8.31122, 50.05498],
              [8.31084, 50.05462],
              [8.31025, 50.05439],
              [8.30926, 50.05396],
              [8.30842, 50.05361],
              [8.30793, 50.05339],
              [8.3074, 50.05312],
              [8.30688, 50.05287],
              [8.3065, 50.05269],
              [8.30527, 50.05207],
              [8.3027, 50.05092],
              [8.30062, 50.05031],
              [8.29918, 50.05006],
              [8.29744, 50.04991],
              [8.29609, 50.05002],
              [8.29515, 50.05038],
              [8.29398, 50.05097],
              [8.29308, 50.05117],
              [8.29175, 50.05133],
              [8.29082, 50.05172],
              [8.29047, 50.05191],
              [8.28998, 50.05232],
              [8.28857, 50.05377],
              [8.28748, 50.05531],
              [8.28677, 50.05657],
              [8.2866, 50.05689],
              [8.28613, 50.05769],
              [8.2855, 50.05858],
              [8.28426, 50.05985],
              [8.28263, 50.06098],
              [8.28017, 50.06211],
              [8.27644, 50.06349],
              [8.27444, 50.06423],
              [8.27387, 50.06444],
              [8.2717, 50.06521],
              [8.26859, 50.06634],
              [8.26768, 50.06668],
              [8.26479, 50.06775],
              [8.26329, 50.06831],
              [8.26225, 50.06878],
              [8.26137, 50.06928],
              [8.26031, 50.06999],
              [8.2593, 50.07078],
              [8.25805, 50.07172],
              [8.25767, 50.07199],
              [8.25727, 50.07224],
              [8.25699, 50.07233],
              [8.25657, 50.07234],
              [8.2531, 50.07223],
              [8.25181, 50.07215],
              [8.25111, 50.07212],
              [8.25036, 50.07207],
              [8.24916, 50.07199],
              [8.24887, 50.07198],
              [8.24834, 50.07196],
              [8.24819, 50.07195],
              [8.24803, 50.07194],
              [8.24745, 50.07192],
              [8.2459, 50.07184],
              [8.24492, 50.07179],
              [8.24475, 50.07159],
              [8.243761, 50.071537],
            ],
          ],
        },
        id: "Y1ODE",
        direction: "OUTBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "E0ODk",
    name: "249 Dotzheim <> Taunusstein",
    lineName: "249",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.155758, 50.149465],
              [8.15594, 50.14913],
              [8.15615, 50.14872],
              [8.15431, 50.14826],
              [8.15387, 50.14812],
              [8.15358, 50.14783],
              [8.15363, 50.14744],
              [8.15433, 50.14655],
              [8.15495, 50.14552],
              [8.15504, 50.14538],
              [8.15527, 50.14502],
              [8.15553, 50.14462],
              [8.15565, 50.14453],
              [8.15731, 50.14362],
              [8.15851, 50.14338],
              [8.15883, 50.14316],
              [8.15899, 50.14284],
              [8.15943, 50.1428],
              [8.15961, 50.14275],
              [8.15974, 50.14265],
              [8.16015, 50.14263],
              [8.15974, 50.14265],
              [8.1597, 50.14256],
              [8.15968, 50.14244],
              [8.15974, 50.1423],
              [8.16003, 50.1417],
              [8.16107, 50.13955],
              [8.16128, 50.13914],
              [8.16133, 50.13903],
              [8.16197, 50.13762],
              [8.16261, 50.13632],
              [8.16286, 50.13573],
              [8.16302, 50.13462],
              [8.16312, 50.13386],
              [8.16334, 50.13309],
              [8.16393, 50.13202],
              [8.16425, 50.13158],
              [8.1646, 50.1311],
              [8.16501, 50.13057],
              [8.166, 50.12911],
              [8.16616, 50.12872],
              [8.16687, 50.12597],
              [8.167335, 50.124359],
              [8.16745, 50.12396],
              [8.16765, 50.12362],
              [8.16779, 50.12343],
              [8.16796, 50.12324],
              [8.16853, 50.12284],
              [8.16872, 50.12275],
              [8.1694, 50.12254],
              [8.17106, 50.1225],
              [8.17137, 50.12236],
              [8.17297, 50.12241],
              [8.17309, 50.12249],
              [8.17421, 50.12216],
              [8.17598, 50.12085],
              [8.17803, 50.12025],
              [8.17926, 50.11962],
              [8.17974, 50.11895],
              [8.17974, 50.11835],
              [8.17947, 50.11765],
              [8.1794, 50.11753],
              [8.1789, 50.11628],
              [8.17898, 50.11527],
              [8.17922, 50.1142],
              [8.17971, 50.11337],
              [8.18004, 50.11299],
              [8.18084, 50.11214],
              [8.18167, 50.11178],
              [8.18425, 50.11163],
              [8.18489, 50.11143],
              [8.18512, 50.11132],
              [8.18696, 50.11029],
              [8.18776, 50.10998],
              [8.19164, 50.10885],
              [8.19222, 50.10868],
              [8.1929, 50.10849],
              [8.19355, 50.10831],
              [8.19407, 50.10816],
              [8.19541, 50.10802],
              [8.19846, 50.10786],
              [8.19956, 50.10759],
              [8.20032, 50.10719],
              [8.19894, 50.10644],
              [8.19457, 50.10383],
              [8.19268, 50.10252],
              [8.19178, 50.10203],
              [8.191408, 50.101852],
              [8.19111, 50.10171],
              [8.18971, 50.10088],
              [8.18708, 50.09929],
              [8.18631, 50.09882],
              [8.18591, 50.09857],
              [8.18584, 50.09849],
              [8.18577, 50.09762],
              [8.18571, 50.09686],
              [8.18606, 50.09676],
              [8.18603, 50.0967],
              [8.19023, 50.09243],
              [8.19195, 50.09073],
              [8.192, 50.09021],
              [8.19179, 50.08868],
              [8.19174, 50.08751],
              [8.19176, 50.08737],
              [8.19171, 50.08639],
              [8.191714, 50.086365],
              [8.19177, 50.08597],
              [8.19183, 50.08582],
              [8.19302, 50.08488],
              [8.19333, 50.08458],
              [8.19385, 50.0841],
              [8.19419, 50.08377],
              [8.19454, 50.08345],
              [8.1921, 50.08264],
              [8.19213, 50.08198],
              [8.19212, 50.0812],
              [8.19195, 50.08049],
              [8.19151, 50.07882],
              [8.191387, 50.078496],
              [8.19123, 50.07808],
              [8.19115, 50.07786],
              [8.19098, 50.07775],
              [8.19112, 50.07758],
              [8.19081, 50.07675],
              [8.19087, 50.07643],
              [8.19065, 50.07614],
              [8.1897, 50.0758],
              [8.18958, 50.07556],
              [8.18986, 50.07535],
              [8.19053, 50.0752],
              [8.19426, 50.07566],
              [8.19449, 50.07555],
              [8.19574, 50.076],
              [8.19723, 50.07632],
              [8.19765, 50.07635],
              [8.19847, 50.07605],
              [8.19976, 50.07545],
              [8.19979, 50.07535],
              [8.19774, 50.07509],
              [8.19791, 50.07448],
              [8.19587, 50.07324],
              [8.19562, 50.07308],
              [8.19476, 50.07251],
              [8.19464, 50.07244],
              [8.19536, 50.07195],
              [8.19514, 50.07175],
              [8.19477, 50.07111],
              [8.19473, 50.0706],
              [8.19502, 50.06942],
              [8.19503, 50.06914],
              [8.1947, 50.06853],
              [8.19452, 50.06836],
              [8.19365, 50.06797],
              [8.19292, 50.06784],
              [8.19142, 50.06766],
              [8.19169, 50.06634],
              [8.190875, 50.066287],
            ],
          ],
        },
        id: "E0ODk",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.190875, 50.066287],
              [8.18999, 50.06623],
              [8.18976, 50.06749],
              [8.19142, 50.06766],
              [8.19292, 50.06784],
              [8.19365, 50.06797],
              [8.19452, 50.06836],
              [8.1947, 50.06853],
              [8.19503, 50.06914],
              [8.19502, 50.06942],
              [8.19473, 50.0706],
              [8.19477, 50.07111],
              [8.19514, 50.07175],
              [8.19536, 50.07195],
              [8.19464, 50.07244],
              [8.19437, 50.0728],
              [8.19424, 50.07306],
              [8.1948, 50.07357],
              [8.19513, 50.07384],
              [8.19536, 50.0741],
              [8.19566, 50.07447],
              [8.19569, 50.07454],
              [8.1959, 50.0748],
              [8.19638, 50.07498],
              [8.19694, 50.07502],
              [8.19774, 50.07509],
              [8.19979, 50.07535],
              [8.19976, 50.07545],
              [8.19847, 50.07605],
              [8.19765, 50.07635],
              [8.19723, 50.07632],
              [8.19574, 50.076],
              [8.19449, 50.07555],
              [8.19426, 50.07566],
              [8.19053, 50.0752],
              [8.18986, 50.07535],
              [8.18958, 50.07556],
              [8.1897, 50.0758],
              [8.19065, 50.07614],
              [8.19087, 50.07643],
              [8.19081, 50.07675],
              [8.19112, 50.07758],
              [8.19098, 50.07775],
              [8.19115, 50.07786],
              [8.19123, 50.07808],
              [8.19184, 50.078],
              [8.19224, 50.07811],
              [8.19241, 50.07831],
              [8.19274, 50.07948],
              [8.19298, 50.08069],
              [8.19346, 50.08098],
              [8.19475, 50.08131],
              [8.19438, 50.08173],
              [8.194, 50.08224],
              [8.19536, 50.08274],
              [8.19454, 50.08345],
              [8.19419, 50.08377],
              [8.19385, 50.0841],
              [8.19333, 50.08458],
              [8.19302, 50.08488],
              [8.19183, 50.08582],
              [8.19177, 50.08597],
              [8.191714, 50.086365],
              [8.19171, 50.08639],
              [8.19176, 50.08737],
              [8.19174, 50.08751],
              [8.19179, 50.08868],
              [8.192, 50.09021],
              [8.19195, 50.09073],
              [8.19023, 50.09243],
              [8.18603, 50.0967],
              [8.18606, 50.09676],
              [8.18571, 50.09686],
              [8.18577, 50.09762],
              [8.18584, 50.09849],
              [8.18591, 50.09857],
              [8.18631, 50.09882],
              [8.18708, 50.09929],
              [8.18971, 50.10088],
              [8.19111, 50.10171],
              [8.191408, 50.101852],
              [8.19178, 50.10203],
              [8.19268, 50.10252],
              [8.19457, 50.10383],
              [8.19894, 50.10644],
              [8.20032, 50.10719],
              [8.19956, 50.10759],
              [8.19846, 50.10786],
              [8.19541, 50.10802],
              [8.19407, 50.10816],
              [8.19355, 50.10831],
              [8.1929, 50.10849],
              [8.19222, 50.10868],
              [8.19164, 50.10885],
              [8.18776, 50.10998],
              [8.18696, 50.11029],
              [8.18512, 50.11132],
              [8.18489, 50.11143],
              [8.18425, 50.11163],
              [8.18167, 50.11178],
              [8.18084, 50.11214],
              [8.18004, 50.11299],
              [8.17971, 50.11337],
              [8.17922, 50.1142],
              [8.17898, 50.11527],
              [8.1789, 50.11628],
              [8.1794, 50.11753],
              [8.17947, 50.11765],
              [8.17974, 50.11835],
              [8.17974, 50.11895],
              [8.17926, 50.11962],
              [8.17803, 50.12025],
              [8.17598, 50.12085],
              [8.17421, 50.12216],
              [8.17309, 50.12249],
              [8.17297, 50.12241],
              [8.17137, 50.12236],
              [8.17106, 50.1225],
              [8.1694, 50.12254],
              [8.16872, 50.12275],
              [8.16853, 50.12284],
              [8.16796, 50.12324],
              [8.16779, 50.12343],
              [8.16765, 50.12362],
              [8.16745, 50.12396],
              [8.167335, 50.124359],
              [8.16687, 50.12597],
              [8.16616, 50.12872],
              [8.166, 50.12911],
              [8.16501, 50.13057],
              [8.1646, 50.1311],
              [8.16425, 50.13158],
              [8.16393, 50.13202],
              [8.16334, 50.13309],
              [8.16312, 50.13386],
              [8.16302, 50.13462],
              [8.16286, 50.13573],
              [8.16261, 50.13632],
              [8.16197, 50.13762],
              [8.16133, 50.13903],
              [8.16128, 50.13914],
              [8.16107, 50.13955],
              [8.16003, 50.1417],
              [8.15974, 50.1423],
              [8.15968, 50.14244],
              [8.1597, 50.14256],
              [8.15974, 50.14265],
              [8.16015, 50.14263],
              [8.15974, 50.14265],
              [8.15961, 50.14275],
              [8.15943, 50.1428],
              [8.15899, 50.14284],
              [8.15883, 50.14316],
              [8.15851, 50.14338],
              [8.15731, 50.14362],
              [8.15565, 50.14453],
              [8.15553, 50.14462],
              [8.15527, 50.14502],
              [8.15504, 50.14538],
              [8.15495, 50.14552],
              [8.15433, 50.14655],
              [8.15256, 50.14603],
              [8.15236, 50.14592],
              [8.1514, 50.1463],
              [8.15031, 50.14671],
              [8.15016, 50.14677],
              [8.149607, 50.146978],
              [8.14923, 50.14712],
              [8.14863, 50.14738],
              [8.14817, 50.1478],
              [8.1479, 50.14817],
              [8.14781, 50.14862],
              [8.14772, 50.14932],
              [8.150602, 50.149756],
              [8.15063, 50.14976],
              [8.15481, 50.15123],
              [8.15488, 50.15109],
              [8.155059, 50.15076],
              [8.15521, 50.15048],
              [8.15556, 50.14983],
              [8.155758, 50.149465],
            ],
          ],
        },
        id: "Q1Njg",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "IzNzI",
    name: "230 Wiesbaden <> Bad-Camberg",
    lineName: "230",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.25111, 50.07212],
              [8.25181, 50.07215],
              [8.2531, 50.07223],
              [8.2547, 50.07235],
              [8.25518, 50.07238],
              [8.25641, 50.07248],
              [8.25671, 50.07253],
              [8.25693, 50.07258],
              [8.25746, 50.07284],
              [8.25775, 50.073],
              [8.25868, 50.07356],
              [8.25937, 50.07392],
              [8.25963, 50.07405],
              [8.26005, 50.07425],
              [8.26139, 50.07475],
              [8.26151, 50.07476],
              [8.26265, 50.07503],
              [8.26387, 50.07521],
              [8.26572, 50.07559],
              [8.266894, 50.076023],
              [8.2674, 50.07621],
              [8.26817, 50.0765],
              [8.26914, 50.0769],
              [8.27043, 50.07742],
              [8.27137, 50.07791],
              [8.27249, 50.07865],
              [8.27432, 50.08005],
              [8.27509, 50.08079],
              [8.27564, 50.08145],
              [8.27592, 50.0821],
              [8.27639, 50.0845],
              [8.27667, 50.08509],
              [8.27724, 50.08485],
              [8.27768, 50.084646],
              [8.27866, 50.08419],
              [8.27913, 50.08398],
              [8.28057, 50.0833],
              [8.28069, 50.08326],
              [8.28078, 50.08323],
              [8.28085, 50.0832],
              [8.28096, 50.08316],
              [8.28119, 50.08307],
              [8.28198, 50.08271],
              [8.28196, 50.08302],
              [8.28178, 50.08347],
              [8.28145, 50.0844],
              [8.28129, 50.08482],
              [8.28097, 50.08559],
              [8.28186, 50.08601],
              [8.280955, 50.088414],
              [8.28009, 50.09071],
              [8.28004, 50.09079],
              [8.27998, 50.09086],
              [8.27979, 50.09108],
              [8.27979, 50.09151],
              [8.27973, 50.09185],
              [8.279451, 50.092612],
              [8.27933, 50.09294],
              [8.2784, 50.09494],
              [8.27833, 50.09546],
              [8.27836, 50.09611],
              [8.27838, 50.09677],
              [8.27839, 50.09706],
              [8.27846, 50.09787],
              [8.27846, 50.09848],
              [8.27854, 50.10032],
              [8.27859, 50.10164],
              [8.27831, 50.10375],
              [8.27833, 50.104436],
              [8.27837, 50.1058],
              [8.27861, 50.10672],
              [8.27913, 50.10765],
              [8.27954, 50.10818],
              [8.28011, 50.10881],
              [8.28112, 50.10962],
              [8.28153, 50.110087],
              [8.28184, 50.11044],
              [8.28219, 50.11087],
              [8.28364, 50.11236],
              [8.28489, 50.11395],
              [8.28659, 50.11567],
              [8.28721, 50.11645],
              [8.2874, 50.1167],
              [8.28761, 50.11699],
              [8.28872, 50.11863],
              [8.28882, 50.11885],
              [8.28947, 50.12052],
              [8.28999, 50.12194],
              [8.2903, 50.12267],
              [8.29122, 50.12404],
              [8.2928, 50.12627],
              [8.29307, 50.12674],
              [8.29339, 50.12782],
              [8.2934, 50.1281],
              [8.29434, 50.1291],
              [8.29435, 50.12946],
              [8.29425, 50.12965],
              [8.29405, 50.13021],
              [8.29383, 50.13088],
              [8.29374, 50.13106],
              [8.29286, 50.13197],
              [8.2928, 50.1323],
              [8.29304, 50.13258],
              [8.29407, 50.13281],
              [8.29449, 50.13278],
              [8.29513, 50.13242],
              [8.29581, 50.13241],
              [8.29646, 50.13255],
              [8.29655, 50.13257],
              [8.2976, 50.13295],
              [8.297809, 50.133072],
              [8.29888, 50.1337],
              [8.29899, 50.13375],
              [8.30077, 50.13482],
              [8.30122, 50.13505],
              [8.3015, 50.13521],
              [8.30164, 50.13534],
              [8.30201, 50.13567],
              [8.30238, 50.13596],
              [8.30244, 50.13601],
              [8.30368, 50.137],
              [8.30458, 50.13784],
              [8.30468, 50.13838],
              [8.30471, 50.13859],
              [8.30483, 50.13916],
              [8.30508, 50.13954],
              [8.30568, 50.13978],
              [8.30739, 50.13988],
              [8.30766, 50.13941],
              [8.30785, 50.13916],
              [8.30835, 50.13886],
              [8.30959, 50.1387],
              [8.3111, 50.13858],
              [8.31255, 50.13844],
              [8.31544, 50.1382],
              [8.31705, 50.13837],
              [8.31761, 50.13847],
              [8.32289, 50.13961],
              [8.32486, 50.14003],
              [8.32631, 50.14051],
              [8.32674, 50.14078],
              [8.32749, 50.14138],
              [8.32819, 50.14198],
              [8.32844, 50.14217],
              [8.32878, 50.14238],
              [8.32958, 50.14268],
              [8.32958, 50.14286],
              [8.32945, 50.14308],
              [8.32844, 50.14386],
              [8.32811, 50.14417],
              [8.32779, 50.14483],
              [8.32775, 50.14529],
              [8.32773, 50.14568],
              [8.32749, 50.14707],
              [8.32737, 50.14759],
              [8.32732, 50.14787],
              [8.32724, 50.14824],
              [8.327, 50.14903],
              [8.32682, 50.14989],
              [8.32645, 50.15109],
              [8.32617, 50.15149],
              [8.32537, 50.15208],
              [8.32437, 50.15262],
              [8.32343, 50.15332],
              [8.32337, 50.15355],
              [8.32319, 50.15367],
              [8.32326, 50.15377],
              [8.32309, 50.15398],
              [8.32293, 50.15428],
              [8.32266, 50.15484],
              [8.32195, 50.15633],
              [8.32186, 50.15695],
              [8.32172, 50.15778],
              [8.3217, 50.15782],
              [8.32147, 50.15818],
              [8.32086, 50.15886],
              [8.32011, 50.15968],
              [8.31936, 50.16046],
              [8.31849, 50.161],
              [8.31765, 50.16136],
              [8.317366, 50.16146],
              [8.31765, 50.16136],
              [8.3175, 50.16108],
              [8.31751, 50.16078],
              [8.31692, 50.16037],
              [8.31575, 50.16022],
              [8.31511, 50.16029],
              [8.31436, 50.1604],
              [8.3133, 50.16084],
              [8.31298, 50.16096],
              [8.3124, 50.1605],
              [8.31161, 50.16086],
              [8.30941, 50.16176],
              [8.30914, 50.16175],
              [8.30902, 50.16187],
              [8.30891, 50.16194],
              [8.30821, 50.16218],
              [8.30772, 50.16243],
              [8.30737, 50.1624],
              [8.30683, 50.16207],
              [8.30633, 50.16211],
              [8.30605, 50.16225],
              [8.30457, 50.16291],
              [8.30436, 50.163],
              [8.304, 50.1632],
              [8.30396, 50.16342],
              [8.30408, 50.16392],
              [8.30319, 50.16483],
              [8.30307, 50.16503],
              [8.30243, 50.1662],
              [8.3024, 50.16765],
              [8.30236, 50.16897],
              [8.30206, 50.16952],
              [8.30147, 50.17003],
              [8.29983, 50.17118],
              [8.2993, 50.1715],
              [8.29401, 50.17416],
              [8.29348, 50.17444],
              [8.29175, 50.17535],
              [8.291232, 50.175473],
              [8.29065, 50.17561],
              [8.29007, 50.1757],
              [8.28981, 50.17574],
              [8.28826, 50.17599],
              [8.28783, 50.17614],
              [8.28717, 50.17642],
              [8.28547, 50.17711],
              [8.28538, 50.17715],
              [8.284966, 50.177564],
              [8.28476, 50.17777],
              [8.28454, 50.17804],
              [8.28483, 50.1784],
              [8.28481, 50.17853],
              [8.28566, 50.17899],
              [8.28639, 50.17927],
              [8.28677, 50.17932],
              [8.28717, 50.17938],
              [8.28772, 50.17953],
              [8.28815, 50.17965],
              [8.28873, 50.17986],
              [8.28914, 50.17997],
              [8.29007, 50.18033],
              [8.29036, 50.18054],
              [8.29109, 50.18118],
              [8.29519, 50.18454],
              [8.29547, 50.18468],
              [8.29613, 50.18505],
              [8.29547, 50.18468],
              [8.29419, 50.1857],
              [8.29281, 50.18666],
              [8.29174, 50.18753],
              [8.2914, 50.18812],
              [8.29132, 50.18877],
              [8.29135, 50.18985],
              [8.29109, 50.19062],
              [8.29053, 50.19119],
              [8.2886, 50.19234],
              [8.28692, 50.19331],
              [8.28965, 50.19439],
              [8.29052, 50.19515],
              [8.2906, 50.19527],
              [8.29065, 50.19535],
              [8.29087, 50.19568],
              [8.2912, 50.19615],
              [8.29133, 50.19632],
              [8.292142, 50.197179],
              [8.29256, 50.19762],
              [8.29264, 50.19855],
              [8.29144, 50.19961],
              [8.291, 50.2002],
              [8.29079, 50.20054],
              [8.29051, 50.20073],
              [8.29024, 50.20074],
              [8.28989, 50.20072],
              [8.28935, 50.20083],
              [8.28897, 50.20121],
              [8.28881, 50.20154],
              [8.2883, 50.2025],
              [8.28698, 50.20373],
              [8.28506, 50.20431],
              [8.28471, 50.20465],
              [8.28397, 50.20572],
              [8.28287, 50.2072],
              [8.28205, 50.20775],
              [8.28143, 50.20802],
              [8.28055, 50.20845],
              [8.2799, 50.20859],
              [8.27937, 50.20864],
              [8.27927, 50.20875],
              [8.27913, 50.20876],
              [8.27904, 50.20938],
              [8.27881, 50.20989],
              [8.27784, 50.21087],
              [8.27621, 50.21235],
              [8.27585, 50.21298],
              [8.27578, 50.21325],
              [8.27531, 50.21328],
              [8.27513, 50.21333],
              [8.274305, 50.214191],
              [8.27513, 50.21333],
              [8.27531, 50.21328],
              [8.27578, 50.21325],
              [8.27579, 50.21349],
              [8.27595, 50.21408],
              [8.27699, 50.21562],
              [8.2771, 50.21619],
              [8.27687, 50.21694],
              [8.27601, 50.21828],
              [8.27583, 50.21855],
              [8.2756, 50.21887],
              [8.27529, 50.21918],
              [8.27474, 50.21957],
              [8.27463, 50.21965],
              [8.27465, 50.21979],
              [8.27474, 50.21983],
              [8.27521, 50.22012],
              [8.27528, 50.22041],
              [8.27498, 50.22072],
              [8.27471, 50.22086],
              [8.27461, 50.22091],
              [8.27431, 50.22108],
              [8.27284, 50.22182],
              [8.271394, 50.222602],
              [8.27125, 50.22268],
              [8.27119, 50.22303],
              [8.27121, 50.22309],
              [8.27111, 50.22319],
              [8.27101, 50.22321],
              [8.27051, 50.22335],
              [8.26949, 50.22319],
              [8.26918, 50.22292],
              [8.26917, 50.22283],
              [8.26911, 50.22282],
              [8.26906, 50.22271],
              [8.26897, 50.22265],
              [8.2687, 50.22254],
              [8.26837, 50.22243],
              [8.268, 50.22231],
              [8.26732, 50.22207],
              [8.267298, 50.222062],
              [8.26701, 50.22195],
              [8.26685, 50.22189],
              [8.26664, 50.22197],
              [8.26661, 50.22207],
              [8.26657, 50.22222],
              [8.26637, 50.22288],
              [8.266171, 50.223504],
              [8.26607, 50.22382],
              [8.26602, 50.22398],
              [8.26548, 50.2253],
              [8.26507, 50.22621],
              [8.26495, 50.22649],
              [8.264876, 50.226713],
              [8.26477, 50.22703],
              [8.26474, 50.22713],
              [8.26486, 50.22723],
              [8.26472, 50.22736],
              [8.26464, 50.22736],
              [8.26459, 50.22746],
              [8.26451, 50.22763],
              [8.26429, 50.22806],
              [8.26408, 50.22855],
              [8.26387, 50.22903],
              [8.26376, 50.2293],
              [8.26334, 50.22989],
              [8.26301, 50.23027],
              [8.262763, 50.230557],
              [8.26245, 50.23092],
              [8.26181, 50.23156],
              [8.26176, 50.23169],
              [8.26166, 50.2317],
              [8.26132, 50.23215],
              [8.26019, 50.23356],
              [8.25936, 50.2345],
              [8.25858, 50.23545],
              [8.25831, 50.2362],
              [8.25783, 50.2398],
              [8.25758, 50.24036],
              [8.25691, 50.24127],
              [8.25694, 50.24142],
              [8.2568, 50.24144],
              [8.25676, 50.24188],
              [8.25673, 50.24292],
              [8.256406, 50.24368],
              [8.2563, 50.24393],
              [8.25625, 50.24405],
              [8.25599, 50.24454],
              [8.25585, 50.24468],
              [8.25543, 50.24505],
              [8.25509, 50.24539],
              [8.25503, 50.2457],
              [8.25575, 50.24599],
              [8.25615, 50.24619],
              [8.25669, 50.24653],
              [8.25675, 50.24658],
              [8.25676, 50.24698],
              [8.25682, 50.24744],
              [8.25729, 50.24776],
              [8.25739, 50.24779],
              [8.25901, 50.247997],
              [8.25911, 50.24801],
              [8.26046, 50.24818],
              [8.26102, 50.24835],
              [8.26122, 50.24858],
              [8.26104, 50.24906],
              [8.26053, 50.24994],
              [8.2606, 50.25026],
              [8.26094, 50.25046],
              [8.26162, 50.25043],
              [8.26243, 50.25019],
              [8.26369, 50.25037],
              [8.26477, 50.2506],
              [8.26504, 50.2508],
              [8.26535, 50.25125],
              [8.26593, 50.25205],
              [8.26678, 50.25316],
              [8.26998, 50.25592],
              [8.27067, 50.25683],
              [8.2714, 50.25797],
              [8.27281, 50.26019],
              [8.27318, 50.26077],
              [8.27392, 50.26193],
              [8.27515, 50.26386],
              [8.27565, 50.26466],
              [8.27644, 50.26592],
              [8.27691, 50.26663],
              [8.27696, 50.26671],
              [8.27733, 50.26729],
              [8.277686, 50.267852],
              [8.27783, 50.26808],
              [8.27789, 50.26818],
              [8.27846, 50.2691],
              [8.27863, 50.26936],
              [8.27896, 50.2698],
              [8.27959, 50.27003],
              [8.28, 50.27015],
              [8.28064, 50.27042],
              [8.28082, 50.27063],
              [8.28085, 50.27095],
              [8.2812, 50.2709],
              [8.28182, 50.27071],
              [8.28213, 50.27062],
              [8.28247, 50.27051],
              [8.28274, 50.27043],
              [8.28353, 50.27033],
              [8.28498, 50.27058],
              [8.2868, 50.2712],
              [8.28786, 50.27159],
              [8.287, 50.27235],
              [8.28634, 50.27279],
              [8.28569, 50.27323],
              [8.2843, 50.27417],
              [8.28267, 50.27565],
              [8.28247, 50.2759],
              [8.281675, 50.277075],
              [8.281425, 50.277445],
              [8.2805, 50.2792],
              [8.2804, 50.27958],
              [8.28044, 50.28037],
              [8.28039, 50.28142],
              [8.28025, 50.2817],
              [8.28, 50.28211],
              [8.27948, 50.28295],
              [8.27909, 50.28321],
              [8.27899, 50.28352],
              [8.27897, 50.28382],
              [8.27886, 50.28443],
              [8.278765, 50.284653],
              [8.27853, 50.2852],
              [8.27835, 50.28547],
              [8.27823, 50.28558],
              [8.277175, 50.286264],
              [8.27647, 50.28672],
              [8.27622, 50.28697],
              [8.27576, 50.28762],
              [8.27551, 50.28804],
              [8.27529, 50.28846],
              [8.27472, 50.28948],
              [8.27416, 50.29066],
              [8.27389, 50.29132],
              [8.27319, 50.29206],
              [8.27291, 50.29235],
              [8.27251, 50.29281],
              [8.27222, 50.29307],
              [8.27066, 50.29419],
              [8.270075, 50.294688],
              [8.26932, 50.29533],
              [8.2692, 50.29553],
              [8.26913, 50.29573],
              [8.26911, 50.29578],
              [8.269543, 50.295986],
              [8.26911, 50.29578],
              [8.26908, 50.2965],
              [8.26873, 50.29675],
              [8.26789, 50.29716],
              [8.2678, 50.2972],
              [8.26659, 50.29774],
              [8.26598, 50.29824],
              [8.26527, 50.29787],
              [8.26496, 50.29772],
              [8.26418, 50.29735],
              [8.263149, 50.297266],
              [8.26221, 50.29719],
              [8.26118, 50.2971],
              [8.26094, 50.29705],
              [8.25975, 50.29683],
              [8.25831, 50.29653],
              [8.2571, 50.29628],
              [8.25682, 50.29622],
              [8.2563, 50.29612],
              [8.25576, 50.29601],
              [8.25561, 50.29613],
              [8.25551, 50.2966],
            ],
          ],
        },
        id: "IzNzI",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.25551, 50.2966],
              [8.25561, 50.29613],
              [8.25576, 50.29601],
              [8.2563, 50.29612],
              [8.25682, 50.29622],
              [8.2571, 50.29628],
              [8.25831, 50.29653],
              [8.25975, 50.29683],
              [8.26094, 50.29705],
              [8.26118, 50.2971],
              [8.26221, 50.29719],
              [8.263149, 50.297266],
              [8.26418, 50.29735],
              [8.26496, 50.29772],
              [8.26527, 50.29787],
              [8.26598, 50.29824],
              [8.26659, 50.29774],
              [8.2678, 50.2972],
              [8.26789, 50.29716],
              [8.26873, 50.29675],
              [8.26908, 50.2965],
              [8.26911, 50.29578],
              [8.269543, 50.295986],
              [8.26911, 50.29578],
              [8.26913, 50.29573],
              [8.2692, 50.29553],
              [8.26932, 50.29533],
              [8.270075, 50.294688],
              [8.27066, 50.29419],
              [8.27222, 50.29307],
              [8.27251, 50.29281],
              [8.27291, 50.29235],
              [8.27319, 50.29206],
              [8.27389, 50.29132],
              [8.27416, 50.29066],
              [8.27472, 50.28948],
              [8.27529, 50.28846],
              [8.27551, 50.28804],
              [8.27576, 50.28762],
              [8.27622, 50.28697],
              [8.27647, 50.28672],
              [8.277175, 50.286264],
              [8.27823, 50.28558],
              [8.27835, 50.28547],
              [8.27853, 50.2852],
              [8.278765, 50.284653],
              [8.27886, 50.28443],
              [8.27897, 50.28382],
              [8.27899, 50.28352],
              [8.27909, 50.28321],
              [8.27948, 50.28295],
              [8.28, 50.28211],
              [8.28025, 50.2817],
              [8.28039, 50.28142],
              [8.28044, 50.28037],
              [8.2804, 50.27958],
              [8.2805, 50.2792],
              [8.281425, 50.277445],
              [8.281675, 50.277075],
              [8.28247, 50.2759],
              [8.28267, 50.27565],
              [8.2843, 50.27417],
              [8.28569, 50.27323],
              [8.28634, 50.27279],
              [8.287, 50.27235],
              [8.28786, 50.27159],
              [8.2868, 50.2712],
              [8.28498, 50.27058],
              [8.28353, 50.27033],
              [8.28274, 50.27043],
              [8.28247, 50.27051],
              [8.28213, 50.27062],
              [8.28182, 50.27071],
              [8.2812, 50.2709],
              [8.28085, 50.27095],
              [8.28082, 50.27063],
              [8.28064, 50.27042],
              [8.28, 50.27015],
              [8.27959, 50.27003],
              [8.27896, 50.2698],
              [8.27863, 50.26936],
              [8.27846, 50.2691],
              [8.27789, 50.26818],
              [8.27783, 50.26808],
              [8.277686, 50.267852],
              [8.27733, 50.26729],
              [8.27696, 50.26671],
              [8.27691, 50.26663],
              [8.27644, 50.26592],
              [8.27565, 50.26466],
              [8.27515, 50.26386],
              [8.27392, 50.26193],
              [8.27318, 50.26077],
              [8.27281, 50.26019],
              [8.2714, 50.25797],
              [8.27067, 50.25683],
              [8.26998, 50.25592],
              [8.26678, 50.25316],
              [8.26593, 50.25205],
              [8.26535, 50.25125],
              [8.26504, 50.2508],
              [8.26477, 50.2506],
              [8.26369, 50.25037],
              [8.26243, 50.25019],
              [8.26162, 50.25043],
              [8.26094, 50.25046],
              [8.2606, 50.25026],
              [8.26053, 50.24994],
              [8.26104, 50.24906],
              [8.26122, 50.24858],
              [8.26102, 50.24835],
              [8.26046, 50.24818],
              [8.25911, 50.24801],
              [8.25901, 50.247997],
              [8.25739, 50.24779],
              [8.25729, 50.24776],
              [8.25682, 50.24744],
              [8.25676, 50.24698],
              [8.25675, 50.24658],
              [8.25669, 50.24653],
              [8.25615, 50.24619],
              [8.25575, 50.24599],
              [8.25503, 50.2457],
              [8.25509, 50.24539],
              [8.25543, 50.24505],
              [8.25585, 50.24468],
              [8.25599, 50.24454],
              [8.25625, 50.24405],
              [8.2563, 50.24393],
              [8.256406, 50.24368],
              [8.25673, 50.24292],
              [8.25676, 50.24188],
              [8.2568, 50.24144],
              [8.25694, 50.24142],
              [8.25691, 50.24127],
              [8.25758, 50.24036],
              [8.25783, 50.2398],
              [8.25831, 50.2362],
              [8.25858, 50.23545],
              [8.25936, 50.2345],
              [8.26019, 50.23356],
              [8.26132, 50.23215],
              [8.26166, 50.2317],
              [8.26176, 50.23169],
              [8.26181, 50.23156],
              [8.26245, 50.23092],
              [8.262763, 50.230557],
              [8.26301, 50.23027],
              [8.26334, 50.22989],
              [8.26376, 50.2293],
              [8.26387, 50.22903],
              [8.26408, 50.22855],
              [8.26429, 50.22806],
              [8.26451, 50.22763],
              [8.26459, 50.22746],
              [8.26464, 50.22736],
              [8.26472, 50.22736],
              [8.26486, 50.22723],
              [8.26474, 50.22713],
              [8.26477, 50.22703],
              [8.264876, 50.226713],
              [8.26495, 50.22649],
              [8.26507, 50.22621],
              [8.26548, 50.2253],
              [8.26602, 50.22398],
              [8.26607, 50.22382],
              [8.266171, 50.223504],
              [8.26637, 50.22288],
              [8.26657, 50.22222],
              [8.26661, 50.22207],
              [8.26664, 50.22197],
              [8.26685, 50.22189],
              [8.26701, 50.22195],
              [8.267298, 50.222062],
              [8.26732, 50.22207],
              [8.268, 50.22231],
              [8.26837, 50.22243],
              [8.2687, 50.22254],
              [8.26897, 50.22265],
              [8.26906, 50.22271],
              [8.26911, 50.22282],
              [8.26917, 50.22283],
              [8.26918, 50.22292],
              [8.26949, 50.22319],
              [8.27051, 50.22335],
              [8.27101, 50.22321],
              [8.27111, 50.22319],
              [8.27121, 50.22309],
              [8.27119, 50.22303],
              [8.27125, 50.22268],
              [8.271394, 50.222602],
              [8.27284, 50.22182],
              [8.27431, 50.22108],
              [8.27461, 50.22091],
              [8.27471, 50.22086],
              [8.27498, 50.22072],
              [8.27528, 50.22041],
              [8.27521, 50.22012],
              [8.27474, 50.21983],
              [8.27465, 50.21979],
              [8.27463, 50.21965],
              [8.27474, 50.21957],
              [8.27529, 50.21918],
              [8.2756, 50.21887],
              [8.27583, 50.21855],
              [8.27601, 50.21828],
              [8.27687, 50.21694],
              [8.2771, 50.21619],
              [8.27699, 50.21562],
              [8.27595, 50.21408],
              [8.27579, 50.21349],
              [8.27578, 50.21325],
              [8.27531, 50.21328],
              [8.27513, 50.21333],
              [8.274305, 50.214191],
              [8.27513, 50.21333],
              [8.27531, 50.21328],
              [8.27578, 50.21325],
              [8.27579, 50.21349],
              [8.27578, 50.21325],
              [8.27585, 50.21298],
              [8.27621, 50.21235],
              [8.27784, 50.21087],
              [8.27881, 50.20989],
              [8.27904, 50.20938],
              [8.27913, 50.20876],
              [8.27927, 50.20875],
              [8.27937, 50.20864],
              [8.2799, 50.20859],
              [8.28055, 50.20845],
              [8.28143, 50.20802],
              [8.28205, 50.20775],
              [8.28287, 50.2072],
              [8.28397, 50.20572],
              [8.28471, 50.20465],
              [8.28506, 50.20431],
              [8.28698, 50.20373],
              [8.2883, 50.2025],
              [8.28881, 50.20154],
              [8.28897, 50.20121],
              [8.28935, 50.20083],
              [8.28989, 50.20072],
              [8.29024, 50.20074],
              [8.29051, 50.20073],
              [8.29079, 50.20054],
              [8.291, 50.2002],
              [8.29144, 50.19961],
              [8.29264, 50.19855],
              [8.29256, 50.19762],
              [8.292142, 50.197179],
              [8.29133, 50.19632],
              [8.2912, 50.19615],
              [8.29087, 50.19568],
              [8.29065, 50.19535],
              [8.2906, 50.19527],
              [8.29052, 50.19515],
              [8.28965, 50.19439],
              [8.28692, 50.19331],
              [8.2886, 50.19234],
              [8.29053, 50.19119],
              [8.29109, 50.19062],
              [8.29135, 50.18985],
              [8.29132, 50.18877],
              [8.2914, 50.18812],
              [8.29174, 50.18753],
              [8.29281, 50.18666],
              [8.29419, 50.1857],
              [8.29547, 50.18468],
              [8.29613, 50.18505],
              [8.29547, 50.18468],
              [8.29519, 50.18454],
              [8.29109, 50.18118],
              [8.29036, 50.18054],
              [8.29007, 50.18033],
              [8.28914, 50.17997],
              [8.28873, 50.17986],
              [8.28815, 50.17965],
              [8.28772, 50.17953],
              [8.28717, 50.17938],
              [8.28677, 50.17932],
              [8.28639, 50.17927],
              [8.28566, 50.17899],
              [8.28481, 50.17853],
              [8.28483, 50.1784],
              [8.28454, 50.17804],
              [8.28476, 50.17777],
              [8.284966, 50.177564],
              [8.28538, 50.17715],
              [8.28547, 50.17711],
              [8.28717, 50.17642],
              [8.28783, 50.17614],
              [8.28826, 50.17599],
              [8.28981, 50.17574],
              [8.29007, 50.1757],
              [8.29065, 50.17561],
              [8.291232, 50.175473],
              [8.29175, 50.17535],
              [8.29348, 50.17444],
              [8.29401, 50.17416],
              [8.2993, 50.1715],
              [8.29983, 50.17118],
              [8.30147, 50.17003],
              [8.30206, 50.16952],
              [8.30236, 50.16897],
              [8.3024, 50.16765],
              [8.30243, 50.1662],
              [8.30307, 50.16503],
              [8.30319, 50.16483],
              [8.30408, 50.16392],
              [8.30396, 50.16342],
              [8.304, 50.1632],
              [8.30436, 50.163],
              [8.30457, 50.16291],
              [8.30605, 50.16225],
              [8.30633, 50.16211],
              [8.30683, 50.16207],
              [8.30737, 50.1624],
              [8.30772, 50.16243],
              [8.30821, 50.16218],
              [8.30891, 50.16194],
              [8.30902, 50.16187],
              [8.30914, 50.16175],
              [8.30941, 50.16176],
              [8.31161, 50.16086],
              [8.3124, 50.1605],
              [8.31298, 50.16096],
              [8.3133, 50.16084],
              [8.31436, 50.1604],
              [8.31511, 50.16029],
              [8.31575, 50.16022],
              [8.31692, 50.16037],
              [8.31751, 50.16078],
              [8.3175, 50.16108],
              [8.31765, 50.16136],
              [8.317366, 50.16146],
              [8.31765, 50.16136],
              [8.31849, 50.161],
              [8.31936, 50.16046],
              [8.32011, 50.15968],
              [8.32086, 50.15886],
              [8.32147, 50.15818],
              [8.3217, 50.15782],
              [8.32172, 50.15778],
              [8.32186, 50.15695],
              [8.32195, 50.15633],
              [8.32266, 50.15484],
              [8.32293, 50.15428],
              [8.32309, 50.15398],
              [8.32326, 50.15377],
              [8.32319, 50.15367],
              [8.32337, 50.15355],
              [8.32343, 50.15332],
              [8.32437, 50.15262],
              [8.32537, 50.15208],
              [8.32617, 50.15149],
              [8.32645, 50.15109],
              [8.32682, 50.14989],
              [8.327, 50.14903],
              [8.32724, 50.14824],
              [8.32732, 50.14787],
              [8.32737, 50.14759],
              [8.32749, 50.14707],
              [8.32773, 50.14568],
              [8.32775, 50.14529],
              [8.32779, 50.14483],
              [8.32811, 50.14417],
              [8.32844, 50.14386],
              [8.32945, 50.14308],
              [8.32958, 50.14286],
              [8.32958, 50.14268],
              [8.32878, 50.14238],
              [8.32844, 50.14217],
              [8.32819, 50.14198],
              [8.32749, 50.14138],
              [8.32674, 50.14078],
              [8.32631, 50.14051],
              [8.32486, 50.14003],
              [8.32289, 50.13961],
              [8.31761, 50.13847],
              [8.31705, 50.13837],
              [8.31544, 50.1382],
              [8.31255, 50.13844],
              [8.3111, 50.13858],
              [8.30959, 50.1387],
              [8.30835, 50.13886],
              [8.30785, 50.13916],
              [8.30766, 50.13941],
              [8.30739, 50.13988],
              [8.30568, 50.13978],
              [8.30508, 50.13954],
              [8.30483, 50.13916],
              [8.30471, 50.13859],
              [8.30468, 50.13838],
              [8.30458, 50.13784],
              [8.30368, 50.137],
              [8.30244, 50.13601],
              [8.30238, 50.13596],
              [8.30201, 50.13567],
              [8.30164, 50.13534],
              [8.3015, 50.13521],
              [8.30122, 50.13505],
              [8.30077, 50.13482],
              [8.29899, 50.13375],
              [8.29888, 50.1337],
              [8.297809, 50.133072],
              [8.2976, 50.13295],
              [8.29655, 50.13257],
              [8.29646, 50.13255],
              [8.29581, 50.13241],
              [8.29513, 50.13242],
              [8.29449, 50.13278],
              [8.29407, 50.13281],
              [8.29304, 50.13258],
              [8.2928, 50.1323],
              [8.29286, 50.13197],
              [8.29374, 50.13106],
              [8.29383, 50.13088],
              [8.29405, 50.13021],
              [8.29425, 50.12965],
              [8.29435, 50.12946],
              [8.29434, 50.1291],
              [8.2934, 50.1281],
              [8.29339, 50.12782],
              [8.29307, 50.12674],
              [8.2928, 50.12627],
              [8.29122, 50.12404],
              [8.2903, 50.12267],
              [8.28999, 50.12194],
              [8.28947, 50.12052],
              [8.28882, 50.11885],
              [8.28872, 50.11863],
              [8.28761, 50.11699],
              [8.2874, 50.1167],
              [8.28721, 50.11645],
              [8.28659, 50.11567],
              [8.28489, 50.11395],
              [8.28364, 50.11236],
              [8.28219, 50.11087],
              [8.28184, 50.11044],
              [8.28153, 50.110087],
              [8.28112, 50.10962],
              [8.28011, 50.10881],
              [8.27954, 50.10818],
              [8.27913, 50.10765],
              [8.27861, 50.10672],
              [8.27837, 50.1058],
              [8.27833, 50.104436],
              [8.27831, 50.10375],
              [8.27859, 50.10164],
              [8.27854, 50.10032],
              [8.27846, 50.09848],
              [8.27846, 50.09787],
              [8.27839, 50.09706],
              [8.27838, 50.09677],
              [8.27836, 50.09611],
              [8.27833, 50.09546],
              [8.2784, 50.09494],
              [8.27933, 50.09294],
              [8.279451, 50.092612],
              [8.27973, 50.09185],
              [8.27979, 50.09151],
              [8.27979, 50.09108],
              [8.27998, 50.09086],
              [8.28004, 50.09079],
              [8.28009, 50.09071],
              [8.280955, 50.088414],
              [8.28186, 50.08601],
              [8.28244, 50.08508],
              [8.28281, 50.08454],
              [8.28318, 50.08403],
              [8.28332, 50.08368],
              [8.28178, 50.08347],
              [8.28095, 50.08333],
              [8.28072, 50.08331],
              [8.28057, 50.0833],
              [8.27913, 50.08398],
              [8.27866, 50.08419],
              [8.27768, 50.084646],
              [8.27724, 50.08485],
              [8.27667, 50.08509],
              [8.27639, 50.0845],
              [8.27592, 50.0821],
              [8.27564, 50.08145],
              [8.27509, 50.08079],
              [8.27432, 50.08005],
              [8.27249, 50.07865],
              [8.27137, 50.07791],
              [8.27043, 50.07742],
              [8.26914, 50.0769],
              [8.26817, 50.0765],
              [8.2674, 50.07621],
              [8.266894, 50.076023],
              [8.26572, 50.07559],
              [8.26387, 50.07521],
              [8.26265, 50.07503],
              [8.26151, 50.07476],
              [8.26139, 50.07475],
              [8.26005, 50.07425],
              [8.25963, 50.07405],
              [8.25937, 50.07392],
              [8.25868, 50.07356],
              [8.25775, 50.073],
              [8.25746, 50.07284],
              [8.25693, 50.07258],
              [8.25671, 50.07253],
              [8.25641, 50.07248],
              [8.25518, 50.07238],
              [8.2547, 50.07235],
              [8.2531, 50.07223],
              [8.25181, 50.07215],
              [8.25111, 50.07212],
              [8.24809, 50.07179],
              [8.24746, 50.07176],
              [8.24683, 50.07171],
              [8.24592, 50.07166],
              [8.24495, 50.07161],
              [8.24475, 50.07159],
              [8.243761, 50.071537],
            ],
          ],
        },
        id: "M5MTI",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "UwMjA",
    name: "176 Kiedrich_Hattenheim <> Mainz_Universität",
    lineName: "176",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.27445, 50.00291],
              [8.27452, 50.00282],
              [8.27487, 50.00243],
              [8.2756, 50.00144],
              [8.27362, 50.00079],
              [8.27341, 50.00074],
              [8.2732, 50.0007],
              [8.27264, 50.00063],
              [8.27196, 50.00058],
              [8.27188, 50.00026],
              [8.27174, 49.99993],
              [8.272044, 49.999506],
              [8.27227, 49.99919],
              [8.27072, 49.99873],
              [8.27, 49.99852],
              [8.2694, 49.99833],
              [8.26835, 49.99799],
              [8.26775, 49.99783],
              [8.26744, 49.99822],
              [8.267, 49.99856],
              [8.26585, 49.99931],
              [8.26553, 49.99954],
              [8.26534, 49.99961],
              [8.26437, 50.00014],
              [8.26412, 50.00017],
              [8.26386, 50.00023],
              [8.26337, 50.00046],
              [8.26256, 50.00077],
              [8.2614, 50.00124],
              [8.26124, 50.00131],
              [8.26052, 50.00034],
              [8.25994, 50.00007],
              [8.25909, 49.99976],
              [8.25889, 49.99971],
              [8.25846, 49.99967],
              [8.25828, 49.99963],
              [8.25791, 49.99939],
              [8.25746, 49.99916],
              [8.25718, 49.99895],
              [8.25633, 49.99802],
              [8.2563, 49.99799],
              [8.25597, 49.9977],
              [8.25585, 49.99764],
              [8.25465, 49.99756],
              [8.25286, 49.99758],
              [8.25186, 49.99756],
              [8.25089, 49.99748],
              [8.249185, 49.997403],
              [8.24912, 49.9974],
              [8.24715, 49.99712],
              [8.24712, 49.99803],
              [8.24707, 49.99833],
              [8.24698, 49.9987],
              [8.246817, 49.999798],
              [8.24678, 50.00005],
              [8.24676, 50.00019],
              [8.24655, 50.00154],
              [8.24635, 50.003],
              [8.24626, 50.0034],
              [8.24314, 50.0029],
              [8.24283, 50.00285],
              [8.24232, 50.00277],
              [8.24198, 50.00274],
              [8.241522, 50.002694],
              [8.24021, 50.00256],
              [8.23942, 50.00244],
              [8.23822, 50.00213],
              [8.2375, 50.00192],
              [8.23667, 50.00182],
              [8.23569, 50.00166],
              [8.23506, 50.00136],
              [8.23458, 50.00113],
              [8.23298, 50.00071],
              [8.23233, 50.00054],
              [8.23144, 50.00031],
              [8.23123, 50.00019],
              [8.23087, 50.00019],
              [8.23, 50.0002],
              [8.22945, 50.00021],
              [8.22909, 50.00021],
              [8.22835, 50.00022],
              [8.22758, 50.00023],
              [8.22551, 50.00045],
              [8.22449, 50.00075],
              [8.22438, 50.00078],
              [8.22425, 50.00081],
              [8.223573, 50.00087],
              [8.22279, 50.00094],
              [8.22167, 50.00098],
              [8.22114, 50.001],
              [8.22061, 50.00101],
              [8.21936, 50.00106],
              [8.21781, 50.00108],
              [8.2179, 50.00156],
              [8.21795, 50.00169],
              [8.21817, 50.00248],
              [8.21801, 50.00305],
              [8.21726, 50.00411],
              [8.2169, 50.0046],
              [8.21647, 50.00521],
              [8.21627, 50.00574],
              [8.21631, 50.00594],
              [8.21634, 50.00612],
              [8.21587, 50.00707],
              [8.21543, 50.00783],
              [8.21534, 50.00813],
              [8.21535, 50.0088],
              [8.21552, 50.00915],
              [8.21611, 50.00971],
              [8.21663, 50.01051],
              [8.21682, 50.01082],
              [8.21718, 50.01161],
              [8.2173, 50.01186],
              [8.21771, 50.01272],
              [8.21775, 50.0128],
              [8.21781, 50.01291],
              [8.21785, 50.013],
              [8.218031, 50.013397],
              [8.21811, 50.01357],
              [8.21848, 50.0144],
              [8.21906, 50.01559],
              [8.21922, 50.01594],
              [8.2195, 50.01653],
              [8.21984, 50.01727],
              [8.22, 50.0176],
              [8.22014, 50.01786],
              [8.22043, 50.01832],
              [8.22076, 50.01911],
              [8.22079, 50.01961],
              [8.22071, 50.02102],
              [8.22058, 50.0216],
              [8.22022, 50.02256],
              [8.22029, 50.02275],
              [8.22024, 50.02286],
              [8.22041, 50.0229],
              [8.220777, 50.023527],
              [8.22096, 50.02384],
              [8.22109, 50.02436],
              [8.22122, 50.02574],
              [8.22046, 50.02599],
              [8.22008, 50.02605],
              [8.22018, 50.02643],
              [8.22041, 50.02725],
              [8.22041, 50.02738],
              [8.22048, 50.02767],
              [8.22047, 50.02806],
              [8.22041, 50.02814],
              [8.22048, 50.0284],
              [8.22071, 50.02852],
              [8.21977, 50.02875],
              [8.21921, 50.02893],
              [8.21852, 50.02917],
              [8.21672, 50.02964],
              [8.21599, 50.02971],
              [8.21486, 50.02959],
              [8.21348, 50.02929],
              [8.21258, 50.0293],
              [8.21203, 50.02957],
              [8.21185, 50.02983],
              [8.21174, 50.03028],
              [8.21175, 50.03037],
              [8.21182, 50.03099],
              [8.21186, 50.03135],
              [8.21206, 50.03326],
              [8.21227, 50.03512],
              [8.21275, 50.03942],
              [8.21298, 50.04143],
              [8.21308, 50.04209],
              [8.21312, 50.04239],
              [8.21334, 50.04274],
              [8.21405, 50.04293],
              [8.21549, 50.04306],
              [8.21623, 50.04335],
              [8.2167, 50.0438],
              [8.21678, 50.04426],
              [8.21676, 50.04434],
              [8.21671, 50.04447],
              [8.21663, 50.04465],
              [8.21616, 50.04456],
              [8.214199, 50.044255],
              [8.21365, 50.04416],
              [8.213409, 50.044122],
              [8.21324, 50.0441],
              [8.21307, 50.04407],
              [8.21268, 50.04401],
              [8.21151, 50.04384],
              [8.21072, 50.0437],
              [8.21046, 50.04367],
              [8.21005, 50.04369],
              [8.20986, 50.04316],
              [8.20973, 50.04305],
              [8.20931, 50.04294],
              [8.208261, 50.043171],
              [8.20476, 50.04394],
              [8.20368, 50.04418],
              [8.2027, 50.0444],
              [8.20189, 50.04456],
              [8.20151, 50.04462],
              [8.20059, 50.04463],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.19923, 50.04589],
              [8.19767, 50.04577],
              [8.19617, 50.04565],
              [8.19585, 50.04563],
              [8.19456, 50.04562],
              [8.19392, 50.04562],
              [8.19219, 50.04532],
              [8.19174, 50.04524],
              [8.19042, 50.04504],
              [8.18751, 50.04477],
              [8.18518, 50.04465],
              [8.18471, 50.04463],
              [8.18368, 50.04457],
              [8.17787, 50.04428],
              [8.17699, 50.04427],
              [8.1763, 50.04424],
              [8.1756, 50.0442],
              [8.17298, 50.04392],
              [8.16961, 50.04324],
              [8.16936, 50.04317],
              [8.16922, 50.0431],
              [8.1671, 50.04252],
              [8.16708, 50.04242],
              [8.16679, 50.04237],
              [8.1656, 50.04165],
              [8.16543, 50.04154],
              [8.16522, 50.0414],
              [8.165191, 50.041379],
              [8.16495, 50.0412],
              [8.16388, 50.04011],
              [8.16252, 50.03817],
              [8.16237, 50.03795],
              [8.1622, 50.03768],
              [8.161779, 50.037073],
              [8.16161, 50.03683],
              [8.16157, 50.03677],
              [8.16133, 50.03644],
              [8.16122, 50.03629],
              [8.16092, 50.03589],
              [8.16056, 50.03559],
              [8.16006, 50.0353],
              [8.1597, 50.03511],
              [8.15942, 50.03498],
              [8.15915, 50.03486],
              [8.15786, 50.03385],
              [8.15712, 50.03337],
              [8.15606, 50.03295],
              [8.15223, 50.032225],
              [8.15157, 50.0321],
              [8.149, 50.03164],
              [8.14755, 50.03137],
              [8.14638, 50.03116],
              [8.14361, 50.03065],
              [8.13764, 50.02956],
              [8.134922, 50.029052],
              [8.13293, 50.02868],
              [8.13157, 50.02836],
              [8.1287, 50.02772],
              [8.12532, 50.02693],
              [8.12452, 50.02674],
              [8.12374, 50.02652],
              [8.1228, 50.02632],
              [8.12231, 50.0262],
              [8.12218, 50.02616],
              [8.12193, 50.02672],
              [8.12189, 50.0268],
              [8.12185, 50.02712],
              [8.12185, 50.02735],
              [8.121479, 50.027341],
              [8.12023, 50.02731],
              [8.11844, 50.02709],
              [8.11834, 50.02725],
              [8.11828, 50.02738],
              [8.11805, 50.02782],
              [8.11797, 50.02815],
              [8.11795, 50.02839],
              [8.11782, 50.02932],
              [8.11781, 50.02953],
              [8.11766, 50.0306],
              [8.11567, 50.03048],
              [8.11553, 50.03042],
              [8.11537, 50.03034],
              [8.11358, 50.02948],
              [8.113289, 50.029339],
              [8.11294, 50.02917],
              [8.11262, 50.02898],
              [8.11246, 50.02887],
              [8.11185, 50.02856],
              [8.11099, 50.0281],
              [8.11002, 50.02763],
              [8.10834, 50.02889],
              [8.10829, 50.02893],
              [8.10825, 50.02896],
              [8.10786, 50.02925],
              [8.10621, 50.03052],
              [8.10513, 50.03126],
              [8.10476, 50.03151],
              [8.10463, 50.03159],
              [8.10414, 50.0319],
              [8.1033, 50.03245],
              [8.10272, 50.0328],
              [8.10169, 50.03328],
              [8.10161, 50.03332],
              [8.1007, 50.03374],
              [8.099661, 50.03433],
              [8.09818, 50.03517],
              [8.09671, 50.03603],
              [8.09565, 50.03664],
              [8.09473, 50.03718],
              [8.09251, 50.03845],
              [8.09105, 50.03923],
              [8.09026, 50.03948],
              [8.08921, 50.03966],
              [8.08907, 50.03966],
              [8.088, 50.03949],
              [8.08764, 50.03944],
              [8.08659, 50.03934],
              [8.08595, 50.03941],
              [8.08461, 50.03965],
              [8.0831, 50.03992],
              [8.08119, 50.04],
              [8.0805, 50.04011],
              [8.07977, 50.04037],
              [8.07958, 50.0404],
              [8.07879, 50.04014],
              [8.07816, 50.0397],
              [8.07784, 50.0395],
              [8.07739, 50.0392],
              [8.07701, 50.03894],
              [8.07694, 50.0389],
              [8.07513, 50.03789],
              [8.075116, 50.037885],
              [8.07451, 50.03767],
              [8.07417, 50.03764],
              [8.07362, 50.03774],
              [8.07232, 50.03816],
              [8.07192, 50.03816],
              [8.07063, 50.03782],
              [8.06975, 50.03778],
              [8.06545, 50.03733],
              [8.06458, 50.03729],
              [8.06372, 50.03739],
              [8.060182, 50.037923],
              [8.05496, 50.03885],
              [8.05313, 50.0402],
              [8.05207, 50.04069],
              [8.05114, 50.04095],
              [8.05096, 50.04094],
              [8.05038, 50.04069],
              [8.04987, 50.0406],
              [8.049, 50.04084],
              [8.04793, 50.04144],
            ],
          ],
        },
        id: "UwMjA",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.04793, 50.04144],
              [8.049, 50.04084],
              [8.04987, 50.0406],
              [8.05038, 50.04069],
              [8.05096, 50.04094],
              [8.05114, 50.04095],
              [8.05207, 50.04069],
              [8.05313, 50.0402],
              [8.05496, 50.03885],
              [8.060182, 50.037923],
              [8.06372, 50.03739],
              [8.06458, 50.03729],
              [8.06545, 50.03733],
              [8.06975, 50.03778],
              [8.07063, 50.03782],
              [8.07192, 50.03816],
              [8.07232, 50.03816],
              [8.07362, 50.03774],
              [8.07417, 50.03764],
              [8.07451, 50.03767],
              [8.075116, 50.037885],
              [8.07513, 50.03789],
              [8.07694, 50.0389],
              [8.07701, 50.03894],
              [8.07739, 50.0392],
              [8.07784, 50.0395],
              [8.07816, 50.0397],
              [8.07879, 50.04014],
              [8.07958, 50.0404],
              [8.07977, 50.04037],
              [8.0805, 50.04011],
              [8.08119, 50.04],
              [8.0831, 50.03992],
              [8.08461, 50.03965],
              [8.08595, 50.03941],
              [8.08659, 50.03934],
              [8.08764, 50.03944],
              [8.088, 50.03949],
              [8.08907, 50.03966],
              [8.08921, 50.03966],
              [8.09026, 50.03948],
              [8.09105, 50.03923],
              [8.09251, 50.03845],
              [8.09473, 50.03718],
              [8.09565, 50.03664],
              [8.09671, 50.03603],
              [8.09818, 50.03517],
              [8.099661, 50.03433],
              [8.1007, 50.03374],
              [8.10161, 50.03332],
              [8.10169, 50.03328],
              [8.10272, 50.0328],
              [8.1033, 50.03245],
              [8.10414, 50.0319],
              [8.10463, 50.03159],
              [8.10476, 50.03151],
              [8.10513, 50.03126],
              [8.10621, 50.03052],
              [8.10786, 50.02925],
              [8.10825, 50.02896],
              [8.10829, 50.02893],
              [8.10834, 50.02889],
              [8.11002, 50.02763],
              [8.11099, 50.0281],
              [8.11185, 50.02856],
              [8.11246, 50.02887],
              [8.11262, 50.02898],
              [8.11294, 50.02917],
              [8.113289, 50.029339],
              [8.11358, 50.02948],
              [8.11537, 50.03034],
              [8.11553, 50.03042],
              [8.11567, 50.03048],
              [8.11766, 50.0306],
              [8.11781, 50.02953],
              [8.11782, 50.02932],
              [8.11795, 50.02839],
              [8.11797, 50.02815],
              [8.11805, 50.02782],
              [8.11828, 50.02738],
              [8.11834, 50.02725],
              [8.11844, 50.02709],
              [8.12023, 50.02731],
              [8.121479, 50.027341],
              [8.12185, 50.02735],
              [8.12185, 50.02712],
              [8.12189, 50.0268],
              [8.12193, 50.02672],
              [8.12218, 50.02616],
              [8.12231, 50.0262],
              [8.1228, 50.02632],
              [8.12374, 50.02652],
              [8.12452, 50.02674],
              [8.12532, 50.02693],
              [8.1287, 50.02772],
              [8.13157, 50.02836],
              [8.13293, 50.02868],
              [8.134922, 50.029052],
              [8.13764, 50.02956],
              [8.14361, 50.03065],
              [8.14638, 50.03116],
              [8.14755, 50.03137],
              [8.149, 50.03164],
              [8.15157, 50.0321],
              [8.15223, 50.032225],
              [8.15606, 50.03295],
              [8.15712, 50.03337],
              [8.15786, 50.03385],
              [8.15915, 50.03486],
              [8.15942, 50.03498],
              [8.1597, 50.03511],
              [8.16006, 50.0353],
              [8.16056, 50.03559],
              [8.16092, 50.03589],
              [8.16122, 50.03629],
              [8.16133, 50.03644],
              [8.16157, 50.03677],
              [8.16161, 50.03683],
              [8.161779, 50.037073],
              [8.1622, 50.03768],
              [8.16237, 50.03795],
              [8.16252, 50.03817],
              [8.16388, 50.04011],
              [8.16495, 50.0412],
              [8.165191, 50.041379],
              [8.16522, 50.0414],
              [8.16543, 50.04154],
              [8.1656, 50.04165],
              [8.16679, 50.04237],
              [8.16708, 50.04242],
              [8.1671, 50.04252],
              [8.16922, 50.0431],
              [8.16936, 50.04317],
              [8.16961, 50.04324],
              [8.17298, 50.04392],
              [8.1756, 50.0442],
              [8.1763, 50.04424],
              [8.17699, 50.04427],
              [8.17787, 50.04428],
              [8.18368, 50.04457],
              [8.18471, 50.04463],
              [8.18518, 50.04465],
              [8.18751, 50.04477],
              [8.19042, 50.04504],
              [8.19174, 50.04524],
              [8.19219, 50.04532],
              [8.19392, 50.04562],
              [8.19456, 50.04562],
              [8.19504, 50.04508],
              [8.19513, 50.04504],
              [8.19537, 50.04497],
              [8.19566, 50.04488],
              [8.19611, 50.04466],
              [8.19626, 50.04461],
              [8.19703, 50.04454],
              [8.19717, 50.04454],
              [8.19766, 50.04455],
              [8.19796, 50.04455],
              [8.19938, 50.0446],
              [8.20059, 50.04463],
              [8.20151, 50.04462],
              [8.20189, 50.04456],
              [8.2027, 50.0444],
              [8.20368, 50.04418],
              [8.20476, 50.04394],
              [8.208261, 50.043171],
              [8.20931, 50.04294],
              [8.20973, 50.04305],
              [8.20986, 50.04316],
              [8.21005, 50.04369],
              [8.21014, 50.04393],
              [8.21068, 50.04454],
              [8.21089, 50.04466],
              [8.21165, 50.04489],
              [8.21244, 50.04496],
              [8.2128, 50.04489],
              [8.21309, 50.04464],
              [8.21307, 50.04407],
              [8.21306, 50.04364],
              [8.21305, 50.0434],
              [8.21299, 50.04283],
              [8.21294, 50.04239],
              [8.21293, 50.04213],
              [8.21285, 50.04151],
              [8.21262, 50.03942],
              [8.21216, 50.03527],
              [8.21193, 50.03327],
              [8.21176, 50.03172],
              [8.21172, 50.03134],
              [8.21171, 50.03118],
              [8.21163, 50.03038],
              [8.21126, 50.02973],
              [8.21076, 50.02945],
              [8.21008, 50.02941],
              [8.20954, 50.02965],
              [8.20942, 50.02993],
              [8.20952, 50.03021],
              [8.21004, 50.0305],
              [8.21164, 50.03056],
              [8.21253, 50.03041],
              [8.21385, 50.02993],
              [8.21518, 50.02952],
              [8.21763, 50.02917],
              [8.21916, 50.02883],
              [8.21961, 50.02864],
              [8.22, 50.02841],
              [8.22032, 50.02799],
              [8.22048, 50.02767],
              [8.22041, 50.02738],
              [8.22041, 50.02725],
              [8.22018, 50.02643],
              [8.22008, 50.02605],
              [8.22046, 50.02599],
              [8.22122, 50.02574],
              [8.22109, 50.02436],
              [8.22096, 50.02384],
              [8.220777, 50.023527],
              [8.22041, 50.0229],
              [8.22024, 50.02286],
              [8.22029, 50.02275],
              [8.22022, 50.02256],
              [8.22058, 50.0216],
              [8.22071, 50.02102],
              [8.22079, 50.01961],
              [8.22076, 50.01911],
              [8.22043, 50.01832],
              [8.22014, 50.01786],
              [8.22, 50.0176],
              [8.21984, 50.01727],
              [8.2195, 50.01653],
              [8.21922, 50.01594],
              [8.21906, 50.01559],
              [8.21848, 50.0144],
              [8.21811, 50.01357],
              [8.218031, 50.013397],
              [8.21785, 50.013],
              [8.21781, 50.01291],
              [8.21775, 50.0128],
              [8.21771, 50.01272],
              [8.2173, 50.01186],
              [8.21718, 50.01161],
              [8.21682, 50.01082],
              [8.21663, 50.01051],
              [8.21611, 50.00971],
              [8.21552, 50.00915],
              [8.21535, 50.0088],
              [8.21534, 50.00813],
              [8.21543, 50.00783],
              [8.21587, 50.00707],
              [8.21634, 50.00612],
              [8.21631, 50.00594],
              [8.21627, 50.00574],
              [8.21647, 50.00521],
              [8.2169, 50.0046],
              [8.21726, 50.00411],
              [8.21801, 50.00305],
              [8.21817, 50.00248],
              [8.21795, 50.00169],
              [8.2179, 50.00156],
              [8.21781, 50.00108],
              [8.21936, 50.00106],
              [8.22061, 50.00101],
              [8.22114, 50.001],
              [8.22167, 50.00098],
              [8.22279, 50.00094],
              [8.223573, 50.00087],
              [8.22425, 50.00081],
              [8.22438, 50.00078],
              [8.22449, 50.00075],
              [8.22551, 50.00045],
              [8.22758, 50.00023],
              [8.22835, 50.00022],
              [8.22909, 50.00021],
              [8.22945, 50.00021],
              [8.23, 50.0002],
              [8.23087, 50.00019],
              [8.23123, 50.00019],
              [8.23144, 50.00031],
              [8.23233, 50.00054],
              [8.23298, 50.00071],
              [8.23458, 50.00113],
              [8.23506, 50.00136],
              [8.23569, 50.00166],
              [8.23667, 50.00182],
              [8.2375, 50.00192],
              [8.23822, 50.00213],
              [8.23942, 50.00244],
              [8.24021, 50.00256],
              [8.241522, 50.002694],
              [8.24198, 50.00274],
              [8.24232, 50.00277],
              [8.24283, 50.00285],
              [8.24314, 50.0029],
              [8.24626, 50.0034],
              [8.24635, 50.003],
              [8.24655, 50.00154],
              [8.24676, 50.00019],
              [8.24678, 50.00005],
              [8.246817, 49.999798],
              [8.24698, 49.9987],
              [8.24707, 49.99833],
              [8.24712, 49.99803],
              [8.24715, 49.99712],
              [8.24715, 49.9969],
              [8.24716, 49.99671],
              [8.24804, 49.99692],
              [8.24917, 49.99706],
              [8.25106, 49.99725],
              [8.25276, 49.99729],
              [8.25565, 49.99745],
              [8.2559, 49.99749],
              [8.25636, 49.99779],
              [8.25654, 49.99797],
              [8.25727, 49.99879],
              [8.2576, 49.99905],
              [8.25805, 49.99925],
              [8.25829, 49.99933],
              [8.258525, 49.999387],
              [8.25889, 49.99948],
              [8.25926, 49.99958],
              [8.26046, 50.00007],
              [8.26072, 50.00019],
              [8.26052, 50.00034],
              [8.25989, 50.00102],
              [8.25996, 50.00114],
              [8.26012, 50.00141],
              [8.26124, 50.00131],
              [8.2614, 50.00124],
              [8.26256, 50.00077],
              [8.26337, 50.00046],
              [8.26386, 50.00023],
              [8.26412, 50.00017],
              [8.26437, 50.00014],
              [8.26534, 49.99961],
              [8.26553, 49.99954],
              [8.26585, 49.99931],
              [8.267, 49.99856],
              [8.26744, 49.99822],
              [8.26775, 49.99783],
              [8.26835, 49.99799],
              [8.2694, 49.99833],
              [8.27, 49.99852],
              [8.27072, 49.99873],
              [8.27227, 49.99919],
              [8.272044, 49.999506],
              [8.27174, 49.99993],
              [8.27188, 50.00026],
              [8.27196, 50.00058],
              [8.27264, 50.00063],
              [8.2732, 50.0007],
              [8.27341, 50.00074],
              [8.27362, 50.00079],
              [8.2756, 50.00144],
              [8.27487, 50.00243],
              [8.27452, 50.00282],
              [8.27445, 50.00291],
            ],
          ],
        },
        id: "EyOTE",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "MyMjg",
    name: "171_Rüdesheim <> 171_Hauptbahnhof",
    lineName: "171",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.23872, 50.07439],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.236676, 50.077684],
              [8.235433, 50.077624],
              [8.235155, 50.07761],
              [8.233622, 50.07753],
              [8.232675, 50.077486],
              [8.23015, 50.07735],
              [8.23019, 50.07716],
              [8.229975, 50.076791],
              [8.23143, 50.07437],
              [8.23051, 50.07357],
              [8.23, 50.0731],
              [8.22984, 50.07296],
              [8.22977, 50.07289],
              [8.22913, 50.07232],
              [8.22823, 50.07151],
              [8.22797, 50.07128],
              [8.22732, 50.07069],
              [8.22609, 50.06969],
              [8.223309, 50.067704],
              [8.2211, 50.06611],
              [8.22093, 50.06596],
              [8.22074, 50.0658],
              [8.2205, 50.06562],
              [8.21961, 50.06501],
              [8.21893, 50.0645],
              [8.21802, 50.06383],
              [8.21742, 50.06338],
              [8.21711, 50.06314],
              [8.21684, 50.06292],
              [8.21641, 50.06261],
              [8.21632, 50.06254],
              [8.21592, 50.06222],
              [8.21535, 50.06172],
              [8.21432, 50.06094],
              [8.2127, 50.05968],
              [8.21242, 50.05947],
              [8.212155, 50.059241],
              [8.21138, 50.05857],
              [8.21121, 50.0584],
              [8.21018, 50.05707],
              [8.20941, 50.05611],
              [8.209087, 50.05575],
              [8.20813, 50.05468],
              [8.20687, 50.05325],
              [8.20608, 50.05235],
              [8.20563, 50.05185],
              [8.20429, 50.05032],
              [8.2029, 50.04876],
              [8.20258, 50.04839],
              [8.20195, 50.04766],
              [8.20113, 50.04675],
              [8.20083, 50.04642],
              [8.20039, 50.04593],
              [8.19923, 50.04589],
              [8.19767, 50.04577],
              [8.19617, 50.04565],
              [8.19585, 50.04563],
              [8.19456, 50.04562],
              [8.19392, 50.04562],
              [8.19219, 50.04532],
              [8.19174, 50.04524],
              [8.19042, 50.04504],
              [8.18751, 50.04477],
              [8.18518, 50.04465],
              [8.18471, 50.04463],
              [8.18368, 50.04457],
              [8.17787, 50.04428],
              [8.1763, 50.04418],
              [8.17526, 50.04412],
              [8.17365, 50.04395],
              [8.17296, 50.04384],
              [8.16942, 50.0431],
              [8.16922, 50.0431],
              [8.1671, 50.04252],
              [8.16708, 50.04242],
              [8.16679, 50.04237],
              [8.1656, 50.04165],
              [8.16543, 50.04154],
              [8.16522, 50.0414],
              [8.165191, 50.041379],
              [8.16495, 50.0412],
              [8.16388, 50.04011],
              [8.16252, 50.03817],
              [8.16237, 50.03795],
              [8.1622, 50.03768],
              [8.161779, 50.037073],
              [8.16161, 50.03683],
              [8.16157, 50.03677],
              [8.16133, 50.03644],
              [8.16122, 50.03629],
              [8.16092, 50.03589],
              [8.16056, 50.03559],
              [8.16006, 50.0353],
              [8.1597, 50.03511],
              [8.15942, 50.03498],
              [8.15915, 50.03486],
              [8.15786, 50.03385],
              [8.15712, 50.03337],
              [8.15606, 50.03295],
              [8.15223, 50.032225],
              [8.15157, 50.0321],
              [8.149, 50.03164],
              [8.14755, 50.03137],
              [8.14638, 50.03116],
              [8.14361, 50.03065],
              [8.13764, 50.02956],
              [8.134922, 50.029052],
              [8.13293, 50.02868],
              [8.13157, 50.02836],
              [8.1287, 50.02772],
              [8.12532, 50.02693],
              [8.12435, 50.02707],
              [8.1242, 50.0271],
              [8.12193, 50.02672],
              [8.12189, 50.0268],
              [8.12185, 50.02712],
              [8.12185, 50.02735],
              [8.121479, 50.027341],
              [8.12023, 50.02731],
              [8.12037, 50.02672],
              [8.11944, 50.02668],
              [8.11892, 50.02659],
              [8.11878, 50.02657],
              [8.118, 50.02634],
              [8.11712, 50.02589],
              [8.11697, 50.02571],
              [8.11693, 50.02556],
              [8.11694, 50.02542],
              [8.11734, 50.02487],
              [8.11636, 50.02443],
              [8.11572, 50.0242],
              [8.11382, 50.0236],
              [8.11229, 50.02319],
              [8.11124, 50.02289],
              [8.110101, 50.022694],
              [8.10961, 50.02261],
              [8.10898, 50.02262],
              [8.10816, 50.02267],
              [8.10807, 50.02268],
              [8.10691, 50.02277],
              [8.10676, 50.02279],
              [8.10648, 50.02281],
              [8.10523, 50.02291],
              [8.10474, 50.02294],
              [8.10291, 50.02306],
              [8.10215, 50.02311],
              [8.10185, 50.0231],
              [8.1003, 50.02269],
              [8.10004, 50.02261],
              [8.099195, 50.022363],
              [8.09802, 50.02202],
              [8.09661, 50.0216],
              [8.09615, 50.02135],
              [8.09606, 50.02125],
              [8.09549, 50.02061],
              [8.0953, 50.02042],
              [8.09525, 50.02037],
              [8.09486, 50.02018],
              [8.09462, 50.02011],
              [8.0944, 50.02003],
              [8.09408, 50.01989],
              [8.09359, 50.01962],
              [8.09304, 50.01939],
              [8.09228, 50.01924],
              [8.09109, 50.019],
              [8.09038, 50.01896],
              [8.0884, 50.01888],
              [8.08763, 50.01885],
              [8.08558, 50.01847],
              [8.08097, 50.01829],
              [8.07832, 50.01824],
              [8.0728, 50.01769],
              [8.07155, 50.01753],
              [8.0714, 50.01744],
              [8.07129, 50.01717],
              [8.07153, 50.01691],
              [8.0716, 50.01654],
              [8.071, 50.01632],
              [8.06759, 50.01506],
              [8.06507, 50.0141],
              [8.06442, 50.01385],
              [8.06345, 50.01349],
              [8.06223, 50.01305],
              [8.06118, 50.01276],
              [8.05995, 50.01251],
              [8.05547, 50.01218],
              [8.05125, 50.01154],
              [8.04903, 50.01116],
              [8.04748, 50.01118],
              [8.04724, 50.01117],
              [8.04445, 50.01119],
              [8.04418, 50.01119],
              [8.04417, 50.0112],
              [8.04377, 50.01113],
              [8.041909, 50.010472],
              [8.04063, 50.01002],
              [8.03789, 50.00902],
              [8.03767, 50.00894],
              [8.03706, 50.00873],
              [8.03608, 50.00838],
              [8.03491, 50.00796],
              [8.03366, 50.00751],
              [8.03209, 50.00693],
              [8.0317, 50.0068],
              [8.0302, 50.0062],
              [8.02997, 50.0061],
              [8.0292, 50.00577],
              [8.02899, 50.00555],
              [8.02887, 50.00536],
              [8.02871, 50.00518],
              [8.02821, 50.00486],
              [8.02758, 50.00459],
              [8.02735, 50.0045],
              [8.02605, 50.00395],
              [8.02387, 50.00335],
              [8.0222, 50.00291],
              [8.02133, 50.002769],
              [8.01967, 50.0025],
              [8.01935, 50.00236],
              [8.01925, 50.00238],
              [8.018057, 50.002238],
              [8.01765, 50.00219],
              [8.01834, 50.00085],
              [8.01758, 50.00062],
              [8.01719, 50.0005],
              [8.01608, 50.00015],
              [8.01514, 49.99981],
              [8.01416, 49.99943],
              [8.01281, 49.9988],
              [8.01217, 49.9985],
              [8.01135, 49.99811],
              [8.01029, 49.99774],
              [8.00916, 49.99743],
              [8.00792, 49.99699],
              [8.0068, 49.99645],
              [8.00623, 49.99612],
              [8.00541, 49.99563],
              [8.0046, 49.99507],
              [8.0037, 49.99446],
              [8.00232, 49.99517],
              [8.00145, 49.99574],
              [8.00135, 49.99576],
              [8.00092, 49.99532],
              [8.00088, 49.99525],
              [8.0007, 49.99495],
              [8.00036, 49.99434],
              [8.00022, 49.99409],
              [7.99983, 49.99355],
              [7.9993, 49.9928],
              [7.99915, 49.99267],
              [7.99883, 49.99249],
              [7.99754, 49.99223],
              [7.99615, 49.99195],
              [7.99417, 49.99155],
              [7.98588, 49.98988],
              [7.98535, 49.98977],
              [7.98438, 49.98958],
              [7.98312, 49.98926],
              [7.98272, 49.98925],
              [7.98248, 49.9892],
              [7.981786, 49.989058],
              [7.97945, 49.98858],
              [7.97694, 49.98804],
              [7.97648, 49.98787],
              [7.97626, 49.98792],
              [7.97482, 49.98762],
              [7.97414, 49.98748],
              [7.97218, 49.98647],
              [7.970963, 49.985814],
              [7.97027, 49.98544],
              [7.96943, 49.98498],
              [7.9686, 49.98554],
              [7.96943, 49.98498],
              [7.96999, 49.98458],
              [7.97028, 49.98442],
              [7.97052, 49.98422],
              [7.97126, 49.98368],
              [7.97163, 49.98343],
              [7.97032, 49.98214],
              [7.96922, 49.98122],
              [7.9686, 49.98088],
              [7.96797, 49.98065],
              [7.96726, 49.98052],
              [7.96684, 49.9806],
              [7.96618, 49.98139],
              [7.96523, 49.98131],
              [7.96498, 49.98192],
              [7.96398, 49.98174],
              [7.96386, 49.98198],
              [7.96267, 49.98174],
              [7.96218, 49.98173],
              [7.96112, 49.98203],
              [7.96017, 49.98222],
              [7.95998, 49.98222],
              [7.95745, 49.98208],
              [7.9562, 49.98202],
              [7.955583, 49.981974],
              [7.95486, 49.98192],
              [7.95472, 49.98188],
              [7.95467, 49.98192],
              [7.95446, 49.98185],
              [7.9529, 49.98189],
              [7.9491, 49.98214],
              [7.94715, 49.98224],
              [7.946153, 49.982219],
              [7.9439, 49.98217],
              [7.94319, 49.98214],
              [7.94259, 49.98212],
              [7.941962, 49.982039],
              [7.94119, 49.98194],
              [7.94118, 49.98202],
              [7.94111, 49.98243],
              [7.94103, 49.98285],
              [7.94098, 49.98292],
              [7.94093, 49.98301],
              [7.94046, 49.98349],
              [7.94027, 49.983875],
              [7.94012, 49.98418],
              [7.93984, 49.98442],
              [7.93947, 49.98473],
              [7.93919, 49.9852],
              [7.93787, 49.98493],
              [7.93619, 49.98459],
              [7.935547, 49.984562],
              [7.93461, 49.98452],
              [7.93384, 49.98428],
              [7.93356, 49.98501],
              [7.93324, 49.98555],
              [7.93303, 49.98576],
              [7.93263, 49.98637],
              [7.93242, 49.98672],
              [7.93219, 49.98714],
              [7.93183, 49.98759],
              [7.93164, 49.98769],
              [7.93071, 49.98751],
              [7.93077, 49.98717],
              [7.93102, 49.98665],
              [7.93021, 49.98636],
              [7.93015, 49.98633],
              [7.92956, 49.98612],
              [7.92896, 49.98581],
              [7.928813, 49.985513],
              [7.92857, 49.98502],
              [7.92841, 49.98462],
              [7.92829, 49.98429],
              [7.92806, 49.98399],
              [7.92744, 49.98329],
              [7.927, 49.98286],
              [7.92688, 49.98266],
              [7.92678, 49.98219],
              [7.92633, 49.98176],
              [7.9258, 49.98164],
              [7.92579, 49.9816],
              [7.92517, 49.98108],
              [7.92497, 49.98083],
              [7.92507, 49.9806],
              [7.92568, 49.97984],
              [7.92592, 49.97952],
              [7.92598, 49.97944],
              [7.92636, 49.97895],
              [7.92659, 49.97837],
              [7.92669, 49.97824],
              [7.92687, 49.97816],
              [7.92746, 49.97816],
              [7.92878, 49.97822],
              [7.92946, 49.978451],
            ],
          ],
        },
        id: "MyMjg",
        direction: "INBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [7.92946, 49.978451],
              [7.92878, 49.97822],
              [7.92746, 49.97816],
              [7.92687, 49.97816],
              [7.92669, 49.97824],
              [7.92659, 49.97837],
              [7.92636, 49.97895],
              [7.92598, 49.97944],
              [7.92592, 49.97952],
              [7.92568, 49.97984],
              [7.92507, 49.9806],
              [7.92497, 49.98083],
              [7.92517, 49.98108],
              [7.92579, 49.9816],
              [7.9258, 49.98164],
              [7.92633, 49.98176],
              [7.92678, 49.98219],
              [7.92688, 49.98266],
              [7.927, 49.98286],
              [7.92744, 49.98329],
              [7.92806, 49.98399],
              [7.92829, 49.98429],
              [7.92841, 49.98462],
              [7.92857, 49.98502],
              [7.928813, 49.985513],
              [7.92896, 49.98581],
              [7.92956, 49.98612],
              [7.93015, 49.98633],
              [7.93021, 49.98636],
              [7.93102, 49.98665],
              [7.93121, 49.98679],
              [7.93219, 49.98714],
              [7.93242, 49.98672],
              [7.93263, 49.98637],
              [7.93303, 49.98576],
              [7.93324, 49.98555],
              [7.93356, 49.98501],
              [7.93384, 49.98428],
              [7.93461, 49.98452],
              [7.935547, 49.984562],
              [7.93619, 49.98459],
              [7.93787, 49.98493],
              [7.93919, 49.9852],
              [7.93947, 49.98473],
              [7.93984, 49.98442],
              [7.94012, 49.98418],
              [7.94027, 49.983875],
              [7.94046, 49.98349],
              [7.94093, 49.98301],
              [7.94098, 49.98292],
              [7.94103, 49.98285],
              [7.94111, 49.98243],
              [7.94118, 49.98202],
              [7.94119, 49.98194],
              [7.941962, 49.982039],
              [7.94259, 49.98212],
              [7.94319, 49.98214],
              [7.9439, 49.98217],
              [7.946153, 49.982219],
              [7.94715, 49.98224],
              [7.9491, 49.98214],
              [7.9529, 49.98189],
              [7.95446, 49.98185],
              [7.95467, 49.98192],
              [7.95472, 49.98188],
              [7.95486, 49.98192],
              [7.955583, 49.981974],
              [7.9562, 49.98202],
              [7.95745, 49.98208],
              [7.95998, 49.98222],
              [7.96017, 49.98222],
              [7.96112, 49.98203],
              [7.96218, 49.98173],
              [7.96267, 49.98174],
              [7.96386, 49.98198],
              [7.96398, 49.98174],
              [7.96417, 49.98131],
              [7.96447, 49.98049],
              [7.96542, 49.98049],
              [7.96684, 49.9806],
              [7.96726, 49.98052],
              [7.96797, 49.98065],
              [7.9686, 49.98088],
              [7.96922, 49.98122],
              [7.97032, 49.98214],
              [7.97163, 49.98343],
              [7.97126, 49.98368],
              [7.97052, 49.98422],
              [7.97028, 49.98442],
              [7.96999, 49.98458],
              [7.96943, 49.98498],
              [7.9686, 49.98554],
              [7.96943, 49.98498],
              [7.97027, 49.98544],
              [7.970963, 49.985814],
              [7.97218, 49.98647],
              [7.97414, 49.98748],
              [7.97482, 49.98762],
              [7.97626, 49.98792],
              [7.97648, 49.98787],
              [7.97694, 49.98804],
              [7.97945, 49.98858],
              [7.981786, 49.989058],
              [7.98248, 49.9892],
              [7.98272, 49.98925],
              [7.98312, 49.98926],
              [7.98438, 49.98958],
              [7.98535, 49.98977],
              [7.98588, 49.98988],
              [7.99417, 49.99155],
              [7.99615, 49.99195],
              [7.99754, 49.99223],
              [7.99883, 49.99249],
              [7.99915, 49.99267],
              [7.9993, 49.9928],
              [7.99983, 49.99355],
              [8.00022, 49.99409],
              [8.00036, 49.99434],
              [8.0007, 49.99495],
              [8.00088, 49.99525],
              [8.00092, 49.99532],
              [8.00135, 49.99576],
              [8.00156, 49.99594],
              [8.00243, 49.99644],
              [8.00278, 49.9965],
              [8.00397, 49.99686],
              [8.00421, 49.997],
              [8.00484, 49.99728],
              [8.00494, 49.99732],
              [8.00549, 49.99756],
              [8.00561, 49.99761],
              [8.00697, 49.99807],
              [8.00823, 49.99858],
              [8.00915, 49.99893],
              [8.01054, 49.99958],
              [8.01139, 49.99993],
              [8.01177, 50.00011],
              [8.01289, 50.0007],
              [8.01329, 50.00089],
              [8.01343, 50.00096],
              [8.01418, 50.00135],
              [8.01473, 50.0016],
              [8.01503, 50.00168],
              [8.01538, 50.00175],
              [8.01605, 50.00184],
              [8.01643, 50.0019],
              [8.01765, 50.00219],
              [8.018057, 50.002238],
              [8.01925, 50.00238],
              [8.01935, 50.00236],
              [8.01967, 50.0025],
              [8.02133, 50.002769],
              [8.0222, 50.00291],
              [8.02387, 50.00335],
              [8.02605, 50.00395],
              [8.02735, 50.0045],
              [8.02758, 50.00459],
              [8.02821, 50.00486],
              [8.02871, 50.00518],
              [8.02887, 50.00536],
              [8.02899, 50.00555],
              [8.0292, 50.00577],
              [8.02997, 50.0061],
              [8.0302, 50.0062],
              [8.0317, 50.0068],
              [8.03209, 50.00693],
              [8.03366, 50.00751],
              [8.03491, 50.00796],
              [8.03608, 50.00838],
              [8.03706, 50.00873],
              [8.03767, 50.00894],
              [8.03789, 50.00902],
              [8.04063, 50.01002],
              [8.041909, 50.010472],
              [8.04377, 50.01113],
              [8.04417, 50.0112],
              [8.04418, 50.01119],
              [8.04445, 50.01119],
              [8.04724, 50.01117],
              [8.04748, 50.01118],
              [8.04903, 50.01116],
              [8.05125, 50.01154],
              [8.05547, 50.01218],
              [8.05995, 50.01251],
              [8.06118, 50.01276],
              [8.06223, 50.01305],
              [8.06345, 50.01349],
              [8.06442, 50.01385],
              [8.06507, 50.0141],
              [8.06759, 50.01506],
              [8.071, 50.01632],
              [8.0716, 50.01654],
              [8.07153, 50.01691],
              [8.07129, 50.01717],
              [8.0714, 50.01744],
              [8.07155, 50.01753],
              [8.0728, 50.01769],
              [8.07832, 50.01824],
              [8.08097, 50.01829],
              [8.08558, 50.01847],
              [8.08763, 50.01885],
              [8.0884, 50.01888],
              [8.09038, 50.01896],
              [8.09109, 50.019],
              [8.09228, 50.01924],
              [8.09304, 50.01939],
              [8.09359, 50.01962],
              [8.09408, 50.01989],
              [8.0944, 50.02003],
              [8.09462, 50.02011],
              [8.09486, 50.02018],
              [8.09525, 50.02037],
              [8.0953, 50.02042],
              [8.09549, 50.02061],
              [8.09606, 50.02125],
              [8.09615, 50.02135],
              [8.09661, 50.0216],
              [8.09802, 50.02202],
              [8.099195, 50.022363],
              [8.10004, 50.02261],
              [8.1003, 50.02269],
              [8.10185, 50.0231],
              [8.10215, 50.02311],
              [8.10291, 50.02306],
              [8.10474, 50.02294],
              [8.10523, 50.02291],
              [8.10648, 50.02281],
              [8.10676, 50.02279],
              [8.10691, 50.02277],
              [8.10807, 50.02268],
              [8.10816, 50.02267],
              [8.10898, 50.02262],
              [8.10961, 50.02261],
              [8.110101, 50.022694],
              [8.11124, 50.02289],
              [8.11229, 50.02319],
              [8.11382, 50.0236],
              [8.11572, 50.0242],
              [8.11636, 50.02443],
              [8.11734, 50.02487],
              [8.11694, 50.02542],
              [8.11693, 50.02556],
              [8.11697, 50.02571],
              [8.11712, 50.02589],
              [8.118, 50.02634],
              [8.11878, 50.02657],
              [8.11892, 50.02659],
              [8.11944, 50.02668],
              [8.12037, 50.02672],
              [8.12023, 50.02731],
              [8.121479, 50.027341],
              [8.12185, 50.02735],
              [8.12185, 50.02712],
              [8.12189, 50.0268],
              [8.12193, 50.02672],
              [8.1242, 50.0271],
              [8.12435, 50.02707],
              [8.12532, 50.02693],
              [8.1287, 50.02772],
              [8.13157, 50.02836],
              [8.13293, 50.02868],
              [8.134922, 50.029052],
              [8.13764, 50.02956],
              [8.14361, 50.03065],
              [8.14638, 50.03116],
              [8.14755, 50.03137],
              [8.149, 50.03164],
              [8.15157, 50.0321],
              [8.15223, 50.032225],
              [8.15606, 50.03295],
              [8.15712, 50.03337],
              [8.15786, 50.03385],
              [8.15915, 50.03486],
              [8.15942, 50.03498],
              [8.1597, 50.03511],
              [8.16006, 50.0353],
              [8.16056, 50.03559],
              [8.16092, 50.03589],
              [8.16122, 50.03629],
              [8.16133, 50.03644],
              [8.16157, 50.03677],
              [8.16161, 50.03683],
              [8.161779, 50.037073],
              [8.1622, 50.03768],
              [8.16237, 50.03795],
              [8.16252, 50.03817],
              [8.16388, 50.04011],
              [8.16495, 50.0412],
              [8.165191, 50.041379],
              [8.16522, 50.0414],
              [8.16543, 50.04154],
              [8.1656, 50.04165],
              [8.16679, 50.04237],
              [8.16708, 50.04242],
              [8.1671, 50.04252],
              [8.16922, 50.0431],
              [8.16942, 50.0431],
              [8.17296, 50.04384],
              [8.17365, 50.04395],
              [8.17526, 50.04412],
              [8.1763, 50.04418],
              [8.17787, 50.04428],
              [8.18368, 50.04457],
              [8.18471, 50.04463],
              [8.18518, 50.04465],
              [8.18751, 50.04477],
              [8.19042, 50.04504],
              [8.19174, 50.04524],
              [8.19219, 50.04532],
              [8.19392, 50.04562],
              [8.19456, 50.04562],
              [8.19504, 50.04508],
              [8.19513, 50.04504],
              [8.19537, 50.04497],
              [8.19566, 50.04488],
              [8.19611, 50.04466],
              [8.19626, 50.04461],
              [8.19703, 50.04454],
              [8.19717, 50.04454],
              [8.19766, 50.04455],
              [8.19796, 50.04455],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.20083, 50.04642],
              [8.20113, 50.04675],
              [8.20195, 50.04766],
              [8.20258, 50.04839],
              [8.2029, 50.04876],
              [8.20429, 50.05032],
              [8.20563, 50.05185],
              [8.20608, 50.05235],
              [8.20687, 50.05325],
              [8.20813, 50.05468],
              [8.209087, 50.05575],
              [8.20941, 50.05611],
              [8.21018, 50.05707],
              [8.21121, 50.0584],
              [8.21138, 50.05857],
              [8.212155, 50.059241],
              [8.21242, 50.05947],
              [8.2127, 50.05968],
              [8.21432, 50.06094],
              [8.21535, 50.06172],
              [8.21592, 50.06222],
              [8.21632, 50.06254],
              [8.21641, 50.06261],
              [8.21684, 50.06292],
              [8.21711, 50.06314],
              [8.21742, 50.06338],
              [8.21802, 50.06383],
              [8.21893, 50.0645],
              [8.21961, 50.06501],
              [8.2205, 50.06562],
              [8.22074, 50.0658],
              [8.22093, 50.06596],
              [8.2211, 50.06611],
              [8.223309, 50.067704],
              [8.22618, 50.06961],
              [8.22676, 50.07005],
              [8.22741, 50.07061],
              [8.22833, 50.07146],
              [8.22921, 50.07227],
              [8.22984, 50.07283],
              [8.22994, 50.07291],
              [8.2301, 50.07306],
              [8.23071, 50.07357],
              [8.23153, 50.07426],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23112, 50.07506],
              [8.23077, 50.07557],
              [8.23074, 50.07561],
              [8.23036, 50.07637],
              [8.23019, 50.07716],
              [8.23267, 50.07728],
              [8.234781, 50.07738],
              [8.23519, 50.07739],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
            ],
          ],
        },
        id: "Y2ODQ",
        direction: "OUTBOUND",
      },
    ],
  },
  {
    category: BusCategory.REGIONAL,
    id: "g3Mzc",
    name: "170 Rauenthal <> Hauptbahnhof",
    lineName: "170",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.236676, 50.077684],
              [8.235433, 50.077624],
              [8.235155, 50.07761],
              [8.233622, 50.07753],
              [8.232675, 50.077486],
              [8.23015, 50.07735],
              [8.22986, 50.07721],
              [8.22989, 50.07706],
              [8.229975, 50.076791],
              [8.23143, 50.07437],
              [8.23051, 50.07357],
              [8.23, 50.0731],
              [8.22984, 50.07296],
              [8.22977, 50.07289],
              [8.22913, 50.07232],
              [8.22823, 50.07151],
              [8.22797, 50.07128],
              [8.22732, 50.07069],
              [8.22609, 50.06969],
              [8.223309, 50.067704],
              [8.2211, 50.06611],
              [8.22093, 50.06596],
              [8.22074, 50.0658],
              [8.2205, 50.06562],
              [8.21961, 50.06501],
              [8.21893, 50.0645],
              [8.21802, 50.06383],
              [8.21742, 50.06338],
              [8.21711, 50.06314],
              [8.21684, 50.06292],
              [8.21641, 50.06261],
              [8.21632, 50.06254],
              [8.21592, 50.06222],
              [8.21535, 50.06172],
              [8.21432, 50.06094],
              [8.2127, 50.05968],
              [8.21242, 50.05947],
              [8.212155, 50.059241],
              [8.21138, 50.05857],
              [8.21121, 50.0584],
              [8.21018, 50.05707],
              [8.20941, 50.05611],
              [8.209087, 50.05575],
              [8.20813, 50.05468],
              [8.20687, 50.05325],
              [8.20608, 50.05235],
              [8.20563, 50.05185],
              [8.20429, 50.05032],
              [8.2029, 50.04876],
              [8.20258, 50.04839],
              [8.20195, 50.04766],
              [8.20113, 50.04675],
              [8.20083, 50.04642],
              [8.20039, 50.04593],
              [8.19923, 50.04589],
              [8.19767, 50.04577],
              [8.19617, 50.04565],
              [8.19585, 50.04563],
              [8.19456, 50.04562],
              [8.19392, 50.04562],
              [8.19219, 50.04532],
              [8.19174, 50.04524],
              [8.19042, 50.04504],
              [8.18751, 50.04477],
              [8.18518, 50.04465],
              [8.18471, 50.04463],
              [8.18368, 50.04457],
              [8.17787, 50.04428],
              [8.1763, 50.04418],
              [8.17526, 50.04412],
              [8.17365, 50.04395],
              [8.17296, 50.04384],
              [8.16942, 50.0431],
              [8.16922, 50.0431],
              [8.1671, 50.04252],
              [8.1669, 50.04263],
              [8.16672, 50.04256],
              [8.16583, 50.04277],
              [8.16524, 50.04282],
              [8.16443, 50.04298],
              [8.16413, 50.04316],
              [8.16404, 50.04322],
              [8.16274, 50.04443],
              [8.16216, 50.04489],
              [8.16209, 50.04489],
              [8.16201, 50.04489],
              [8.16079, 50.0444],
              [8.15988, 50.04441],
              [8.15962, 50.04367],
              [8.15972, 50.04353],
              [8.15955, 50.04346],
              [8.15927, 50.04281],
              [8.15901, 50.0424],
              [8.15809, 50.04139],
              [8.1577, 50.04105],
              [8.1575, 50.0409],
              [8.1572, 50.04068],
              [8.1569, 50.04046],
              [8.15656, 50.04025],
              [8.15559, 50.03969],
              [8.15469, 50.03923],
              [8.15445, 50.03906],
              [8.15417, 50.03866],
              [8.15427, 50.0383],
              [8.15438, 50.03817],
              [8.15482, 50.03793],
              [8.15551, 50.03765],
              [8.15588, 50.03743],
              [8.15604, 50.03724],
              [8.15616, 50.03688],
              [8.15605, 50.0365],
              [8.15522, 50.03672],
              [8.15341, 50.03744],
              [8.15274, 50.03778],
              [8.15224, 50.03803],
              [8.15179, 50.0382],
              [8.151198, 50.038421],
              [8.15077, 50.03858],
              [8.14827, 50.03989],
              [8.14691, 50.04092],
              [8.14436, 50.04195],
              [8.14416, 50.04202],
              [8.14333, 50.04232],
              [8.14272, 50.04257],
              [8.1423, 50.04274],
              [8.1425, 50.04307],
              [8.14252, 50.04346],
              [8.14283, 50.04374],
              [8.14344, 50.04387],
              [8.14382, 50.04407],
              [8.14401, 50.04455],
              [8.14446, 50.04497],
              [8.14494, 50.04541],
              [8.14323, 50.04574],
              [8.14286, 50.04581],
              [8.142, 50.04598],
              [8.14139, 50.04609],
              [8.13964, 50.0464],
              [8.13851, 50.04661],
              [8.13286, 50.04765],
              [8.13116, 50.04804],
              [8.12923, 50.04887],
              [8.12811, 50.04946],
              [8.12735, 50.04987],
              [8.12683, 50.05014],
              [8.12548, 50.05084],
              [8.12501, 50.05108],
              [8.12301, 50.05212],
              [8.12245, 50.05249],
              [8.12204, 50.0529],
              [8.12131, 50.05381],
              [8.12106, 50.054],
              [8.12013, 50.05447],
              [8.11988, 50.0546],
              [8.1196, 50.05486],
              [8.11935, 50.05557],
              [8.1191, 50.05674],
              [8.11904, 50.05697],
              [8.11874, 50.05828],
              [8.11685, 50.06094],
              [8.11571, 50.0622],
              [8.11284, 50.0649],
              [8.11186, 50.06586],
              [8.11004, 50.06873],
              [8.10957, 50.06959],
              [8.10946, 50.0704],
              [8.10943, 50.07101],
              [8.10925, 50.07096],
              [8.109, 50.07057],
              [8.10903, 50.07003],
              [8.10875, 50.06921],
              [8.10859, 50.0687],
              [8.10859, 50.06744],
              [8.10988, 50.06575],
              [8.10994, 50.06563],
              [8.10994, 50.0654],
              [8.10912, 50.06506],
              [8.1089, 50.06488],
              [8.10888, 50.0648],
              [8.10892, 50.06466],
            ],
          ],
        },
        id: "g3Mzc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.10892, 50.06466],
              [8.10963, 50.06347],
              [8.10973, 50.06329],
              [8.10991, 50.06242],
              [8.11, 50.06205],
              [8.11011, 50.06149],
              [8.11013, 50.06138],
              [8.11015, 50.06104],
              [8.11018, 50.06068],
              [8.11019, 50.06058],
              [8.11029, 50.06018],
              [8.11031, 50.0601],
              [8.11036, 50.05982],
              [8.11044, 50.05937],
              [8.11049, 50.0589],
              [8.11059, 50.05869],
              [8.110983, 50.058763],
              [8.11172, 50.0589],
              [8.11259, 50.05898],
              [8.11315, 50.05902],
              [8.11331, 50.05898],
              [8.11378, 50.0587],
              [8.11508, 50.0587],
              [8.11563, 50.05857],
              [8.11717, 50.05862],
              [8.1176, 50.05835],
              [8.11826, 50.05735],
              [8.11861, 50.05734],
              [8.11882, 50.05689],
              [8.11902, 50.05587],
              [8.11905, 50.05573],
              [8.11935, 50.05557],
              [8.1196, 50.05486],
              [8.11988, 50.0546],
              [8.12013, 50.05447],
              [8.12106, 50.054],
              [8.12131, 50.05381],
              [8.12204, 50.0529],
              [8.12245, 50.05249],
              [8.12301, 50.05212],
              [8.12501, 50.05108],
              [8.12548, 50.05084],
              [8.12683, 50.05014],
              [8.12735, 50.04987],
              [8.12811, 50.04946],
              [8.12923, 50.04887],
              [8.13116, 50.04804],
              [8.13286, 50.04765],
              [8.13851, 50.04661],
              [8.13964, 50.0464],
              [8.14139, 50.04609],
              [8.142, 50.04598],
              [8.14286, 50.04581],
              [8.14323, 50.04574],
              [8.14494, 50.04541],
              [8.14446, 50.04497],
              [8.14401, 50.04455],
              [8.14382, 50.04407],
              [8.14344, 50.04387],
              [8.14283, 50.04374],
              [8.14252, 50.04346],
              [8.1425, 50.04307],
              [8.1423, 50.04274],
              [8.14272, 50.04257],
              [8.14333, 50.04232],
              [8.14416, 50.04202],
              [8.14436, 50.04195],
              [8.14691, 50.04092],
              [8.14827, 50.03989],
              [8.15077, 50.03858],
              [8.151198, 50.038421],
              [8.15179, 50.0382],
              [8.15224, 50.03803],
              [8.15274, 50.03778],
              [8.15341, 50.03744],
              [8.15522, 50.03672],
              [8.15605, 50.0365],
              [8.15616, 50.03688],
              [8.15604, 50.03724],
              [8.15588, 50.03743],
              [8.15551, 50.03765],
              [8.15482, 50.03793],
              [8.15438, 50.03817],
              [8.15427, 50.0383],
              [8.15417, 50.03866],
              [8.15445, 50.03906],
              [8.15469, 50.03923],
              [8.15559, 50.03969],
              [8.15656, 50.04025],
              [8.1569, 50.04046],
              [8.1572, 50.04068],
              [8.1575, 50.0409],
              [8.1577, 50.04105],
              [8.15809, 50.04139],
              [8.15901, 50.0424],
              [8.15927, 50.04281],
              [8.15955, 50.04346],
              [8.15972, 50.04353],
              [8.15962, 50.04367],
              [8.15988, 50.04441],
              [8.16079, 50.0444],
              [8.16201, 50.04489],
              [8.16209, 50.04489],
              [8.16216, 50.04489],
              [8.16274, 50.04443],
              [8.16404, 50.04322],
              [8.16413, 50.04316],
              [8.16443, 50.04298],
              [8.16524, 50.04282],
              [8.16583, 50.04277],
              [8.16672, 50.04256],
              [8.1669, 50.04263],
              [8.1671, 50.04252],
              [8.16922, 50.0431],
              [8.16942, 50.0431],
              [8.17296, 50.04384],
              [8.17365, 50.04395],
              [8.17526, 50.04412],
              [8.1763, 50.04418],
              [8.17787, 50.04428],
              [8.18368, 50.04457],
              [8.18471, 50.04463],
              [8.18518, 50.04465],
              [8.18751, 50.04477],
              [8.19042, 50.04504],
              [8.19174, 50.04524],
              [8.19219, 50.04532],
              [8.19392, 50.04562],
              [8.19456, 50.04562],
              [8.19504, 50.04508],
              [8.19513, 50.04504],
              [8.19537, 50.04497],
              [8.19566, 50.04488],
              [8.19611, 50.04466],
              [8.19626, 50.04461],
              [8.19703, 50.04454],
              [8.19717, 50.04454],
              [8.19766, 50.04455],
              [8.19796, 50.04455],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.20083, 50.04642],
              [8.20113, 50.04675],
              [8.20195, 50.04766],
              [8.20258, 50.04839],
              [8.2029, 50.04876],
              [8.20429, 50.05032],
              [8.20563, 50.05185],
              [8.20608, 50.05235],
              [8.20687, 50.05325],
              [8.20813, 50.05468],
              [8.209087, 50.05575],
              [8.20941, 50.05611],
              [8.21018, 50.05707],
              [8.21121, 50.0584],
              [8.21138, 50.05857],
              [8.212155, 50.059241],
              [8.21242, 50.05947],
              [8.2127, 50.05968],
              [8.21432, 50.06094],
              [8.21535, 50.06172],
              [8.21592, 50.06222],
              [8.21632, 50.06254],
              [8.21641, 50.06261],
              [8.21684, 50.06292],
              [8.21711, 50.06314],
              [8.21742, 50.06338],
              [8.21802, 50.06383],
              [8.21893, 50.0645],
              [8.21961, 50.06501],
              [8.2205, 50.06562],
              [8.22074, 50.0658],
              [8.22093, 50.06596],
              [8.2211, 50.06611],
              [8.22369, 50.06782],
              [8.22492, 50.06868],
              [8.22618, 50.06961],
              [8.22676, 50.07005],
              [8.22741, 50.07061],
              [8.22833, 50.07146],
              [8.22921, 50.07227],
              [8.22984, 50.07283],
              [8.22994, 50.07291],
              [8.2301, 50.07306],
              [8.23071, 50.07357],
              [8.23153, 50.07426],
              [8.2316, 50.07454],
              [8.23112, 50.07506],
              [8.23077, 50.07557],
              [8.23074, 50.07561],
              [8.23036, 50.07637],
              [8.23019, 50.07716],
              [8.23267, 50.07728],
              [8.234781, 50.07738],
              [8.23519, 50.07739],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23836, 50.07167],
              [8.23907, 50.07161],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
            ],
          ],
        },
        id: "I1NTg",
        direction: "INBOUND",
      },
    ],
  },
];
