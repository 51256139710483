import { BusCategory, Line } from "@/types";

export const v1_quartiersBusse: Line[] = [
  {
    category: BusCategory.QUARTIER,
    id: "E5Njk",
    name: "31 (HSK <> Bierstadt)",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.197861, 50.075334],
              [8.198118, 50.075093],
              [8.198204, 50.074501],
              [8.197517, 50.074012],
              [8.196219, 50.07311],
              [8.194975, 50.072339],
              [8.194224, 50.072135],
              [8.193907, 50.072225],
              [8.192807, 50.072559],
              [8.190629, 50.073254],
              [8.188339, 50.074043],
              [8.186499, 50.074687],
              [8.185984, 50.074876],
              [8.185539, 50.075045],
              [8.185303, 50.075117],
              [8.184906, 50.075151],
              [8.184396, 50.075158],
              [8.183811, 50.075055],
              [8.182846, 50.074869],
              [8.182379, 50.074718],
              [8.181805, 50.074143],
              [8.181505, 50.073685],
              [8.181467, 50.073488],
              [8.180979, 50.072955],
              [8.180673, 50.072586],
              [8.180319, 50.072311],
              [8.178661, 50.071206],
              [8.1782, 50.070868],
              [8.177792, 50.070562],
              [8.177631, 50.070435],
              [8.177546, 50.070297],
              [8.177546, 50.070152],
              [8.177986, 50.070149],
              [8.17835, 50.070039],
              [8.178833, 50.069725],
              [8.179493, 50.069061],
              [8.180298, 50.068303],
              [8.1807, 50.067942],
              [8.181022, 50.067773],
              [8.181456, 50.067594],
              [8.182492, 50.067411],
              [8.183629, 50.067215],
              [8.184498, 50.067108],
              [8.18548, 50.067074],
              [8.186992, 50.067201],
              [8.188452, 50.067318],
              [8.189514, 50.067418],
              [8.189664, 50.066165],
              [8.189819, 50.065404],
              [8.190114, 50.064257],
              [8.190206, 50.06392],
              [8.190286, 50.063699],
              [8.190388, 50.063589],
              [8.190635, 50.06351],
              [8.190951, 50.063489],
              [8.191381, 50.063544],
              [8.193237, 50.063806],
              [8.193376, 50.063865],
              [8.193478, 50.063978],
              [8.193478, 50.064044],
              [8.193317, 50.064929],
              [8.193022, 50.066337],
              [8.192942, 50.066544],
              [8.192883, 50.066578],
              [8.192738, 50.066582],
              [8.191928, 50.06653],
              [8.191874, 50.066575],
              [8.191718, 50.067597],
              [8.192748, 50.067714],
              [8.19343, 50.067821],
              [8.194073, 50.067955],
              [8.194492, 50.068193],
              [8.19476, 50.068455],
              [8.195034, 50.068675],
              [8.195152, 50.068988],
              [8.195162, 50.069305],
              [8.19513, 50.069694],
              [8.194942, 50.070297],
              [8.19491, 50.070707],
              [8.194948, 50.070927],
              [8.19512, 50.071302],
              [8.19528, 50.071523],
              [8.195055, 50.071836],
              [8.194744, 50.072063],
              [8.194406, 50.072321],
              [8.194175, 50.072531],
              [8.194068, 50.072962],
              [8.19409, 50.073103],
              [8.194224, 50.073272],
              [8.19468, 50.073736],
              [8.195023, 50.07406],
              [8.195307, 50.074377],
              [8.195592, 50.074745],
              [8.195677, 50.074879],
              [8.195817, 50.074983],
              [8.196101, 50.075117],
              [8.196305, 50.075165],
              [8.197759, 50.075306],
              [8.197625, 50.075778],
              [8.197394, 50.076329],
              [8.196884, 50.076243],
              [8.196321, 50.076098],
              [8.195833, 50.07596],
              [8.19505, 50.075695],
              [8.19461, 50.075561],
              [8.194497, 50.075589],
              [8.194331, 50.075719],
              [8.194095, 50.076146],
              [8.193966, 50.07627],
              [8.193773, 50.076329],
              [8.192888, 50.076019],
              [8.192405, 50.075833],
              [8.191783, 50.075664],
              [8.191402, 50.075613],
              [8.191064, 50.075575],
              [8.190705, 50.075568],
              [8.190141, 50.075575],
              [8.189911, 50.075575],
              [8.189755, 50.07564],
              [8.189648, 50.075723],
              [8.189868, 50.075857],
              [8.190367, 50.076033],
              [8.190705, 50.076198],
              [8.190807, 50.076287],
              [8.190849, 50.076456],
              [8.190833, 50.076646],
              [8.190769, 50.076718],
              [8.19093, 50.076997],
              [8.191134, 50.077585],
              [8.191112, 50.077799],
              [8.191257, 50.078098],
              [8.191472, 50.078074],
              [8.191853, 50.078023],
              [8.192105, 50.07806],
              [8.192346, 50.078195],
              [8.192437, 50.078312],
              [8.192587, 50.078814],
              [8.192754, 50.079506],
              [8.192974, 50.08037],
              [8.193027, 50.080622],
              [8.193113, 50.080811],
              [8.193301, 50.080921],
              [8.193891, 50.081069],
              [8.194857, 50.081279],
              [8.194422, 50.08173],
              [8.194106, 50.082257],
              [8.195125, 50.082673],
              [8.195404, 50.082808],
              [8.195538, 50.082697],
              [8.195903, 50.082518],
              [8.196321, 50.082264],
              [8.196959, 50.081964],
              [8.197877, 50.081555],
              [8.19962, 50.080739],
              [8.200189, 50.080456],
              [8.200339, 50.080236],
              [8.20043, 50.079844],
              [8.202163, 50.080116],
              [8.203295, 50.08026],
              [8.20455, 50.080377],
              [8.20425, 50.081472],
              [8.204094, 50.081689],
              [8.203542, 50.08226],
              [8.202571, 50.083262],
              [8.201256, 50.084635],
              [8.200323, 50.085613],
              [8.200146, 50.085919],
              [8.200184, 50.086232],
              [8.200468, 50.086907],
              [8.200532, 50.086986],
              [8.199669, 50.087193],
              [8.199127, 50.087224],
              [8.199019, 50.087324],
              [8.198681, 50.088556],
              [8.198655, 50.089144],
              [8.198944, 50.089943],
              [8.199084, 50.089974],
              [8.199239, 50.090373],
              [8.199347, 50.090838],
              [8.199486, 50.092004],
              [8.199422, 50.092152],
              [8.199465, 50.092841],
              [8.19947, 50.093147],
              [8.199368, 50.093205],
              [8.19925, 50.093381],
              [8.198456, 50.094031],
              [8.198295, 50.094224],
              [8.198161, 50.094362],
              [8.198805, 50.094372],
              [8.199218, 50.094358],
              [8.200838, 50.094431],
              [8.201369, 50.094362],
              [8.20204, 50.094145],
              [8.203311, 50.093718],
              [8.204309, 50.093371],
              [8.204486, 50.093219],
              [8.205167, 50.093161],
              [8.206594, 50.093002],
              [8.207023, 50.092892],
              [8.207436, 50.092682],
              [8.207881, 50.092476],
              [8.208681, 50.092108],
              [8.209056, 50.091877],
              [8.21, 50.091419],
              [8.210875, 50.091006],
              [8.211465, 50.090707],
              [8.212264, 50.090318],
              [8.21264, 50.089994],
              [8.21345, 50.089186],
              [8.214088, 50.088549],
              [8.21434, 50.088287],
              [8.21441, 50.088181],
              [8.215418, 50.087654],
              [8.215756, 50.087489],
              [8.216438, 50.087313],
              [8.217666, 50.087014],
              [8.219013, 50.086732],
              [8.220638, 50.086384],
              [8.222784, 50.085916],
              [8.2242, 50.085634],
              [8.224613, 50.085572],
              [8.224962, 50.085547],
              [8.225128, 50.085448],
              [8.225428, 50.085451],
              [8.225702, 50.085496],
              [8.226453, 50.085489],
              [8.226609, 50.08553],
              [8.226855, 50.085547],
              [8.227408, 50.08532],
              [8.228481, 50.084835],
              [8.229189, 50.084494],
              [8.229618, 50.084301],
              [8.230138, 50.084184],
              [8.230374, 50.084143],
              [8.230401, 50.084205],
              [8.230289, 50.084374],
              [8.229929, 50.084622],
              [8.229409, 50.08498],
              [8.228813, 50.085338],
              [8.227344, 50.086549],
              [8.227054, 50.086838],
              [8.226646, 50.087141],
              [8.226153, 50.087492],
              [8.225638, 50.087843],
              [8.225402, 50.088208],
              [8.225359, 50.088401],
              [8.225101, 50.088979],
              [8.224919, 50.089241],
              [8.224618, 50.089433],
              [8.22347, 50.090294],
              [8.22258, 50.091017],
              [8.221904, 50.091657],
              [8.220756, 50.092751],
              [8.220263, 50.093226],
              [8.219951, 50.093377],
              [8.219662, 50.093488],
              [8.219254, 50.093412],
              [8.219082, 50.093377],
              [8.218943, 50.093525],
              [8.21876, 50.093684],
              [8.218465, 50.093883],
              [8.218047, 50.094062],
              [8.217553, 50.094293],
              [8.217376, 50.094355],
              [8.217216, 50.0944],
              [8.21677, 50.094427],
              [8.216604, 50.094448],
              [8.2164, 50.094537],
              [8.216389, 50.094668],
              [8.216438, 50.094723],
              [8.216545, 50.094737],
              [8.216792, 50.094696],
              [8.217001, 50.094689],
              [8.217414, 50.09472],
              [8.217629, 50.094751],
              [8.217838, 50.094899],
              [8.218133, 50.095109],
              [8.218675, 50.095518],
              [8.2189, 50.095749],
              [8.219259, 50.09612],
              [8.219506, 50.09632],
              [8.220059, 50.096685],
              [8.22022, 50.09687],
              [8.220359, 50.096812],
              [8.220536, 50.096605],
              [8.220906, 50.096206],
              [8.221078, 50.095986],
              [8.221357, 50.095566],
              [8.221539, 50.095243],
              [8.221582, 50.095153],
              [8.221845, 50.094696],
              [8.222001, 50.094468],
              [8.222473, 50.094111],
              [8.22288, 50.093808],
              [8.223202, 50.093612],
              [8.223578, 50.093501],
              [8.223985, 50.093384],
              [8.224221, 50.093312],
              [8.224356, 50.093381],
              [8.22449, 50.093464],
              [8.224946, 50.093429],
              [8.225724, 50.093329],
              [8.226442, 50.093216],
              [8.227231, 50.093037],
              [8.228046, 50.092882],
              [8.228765, 50.09272],
              [8.229237, 50.092624],
              [8.229484, 50.092538],
              [8.229768, 50.092493],
              [8.230004, 50.092476],
              [8.230224, 50.092541],
              [8.230364, 50.092596],
              [8.230573, 50.09261],
              [8.23104, 50.092538],
              [8.231549, 50.092424],
              [8.231984, 50.09228],
              [8.232762, 50.091915],
              [8.233261, 50.091595],
              [8.233663, 50.091295],
              [8.234044, 50.091027],
              [8.23421, 50.090851],
              [8.234548, 50.090545],
              [8.234854, 50.090259],
              [8.235031, 50.090087],
              [8.235165, 50.089984],
              [8.23532, 50.089915],
              [8.235492, 50.089901],
              [8.235637, 50.089901],
              [8.235766, 50.089939],
              [8.235868, 50.090029],
              [8.235943, 50.090208],
              [8.23598, 50.090311],
              [8.236039, 50.090476],
              [8.236125, 50.090579],
              [8.236383, 50.090858],
              [8.236898, 50.0906],
              [8.237278, 50.09037],
              [8.238024, 50.089864],
              [8.23877, 50.089382],
              [8.239247, 50.089089],
              [8.239387, 50.089065],
              [8.240218, 50.088552],
              [8.24105, 50.088022],
              [8.241967, 50.087416],
              [8.24223, 50.087262],
              [8.2426, 50.087403],
              [8.243662, 50.088122],
              [8.243893, 50.088273],
              [8.243914, 50.088463],
              [8.244097, 50.089093],
              [8.24415, 50.089217],
              [8.244317, 50.089592],
              [8.244461, 50.089874],
              [8.244992, 50.089891],
              [8.245191, 50.089926],
              [8.245298, 50.090018],
              [8.245282, 50.090136],
              [8.245073, 50.090579],
              [8.244982, 50.09091],
              [8.244992, 50.091013],
              [8.244386, 50.091058],
              [8.243812, 50.091113],
              [8.243152, 50.091192],
              [8.24275, 50.091199],
              [8.242391, 50.091233],
              [8.241827, 50.091364],
              [8.241409, 50.091612],
              [8.241152, 50.091753],
              [8.240905, 50.091829],
              [8.24032, 50.091932],
              [8.239596, 50.09228],
              [8.239676, 50.092417],
              [8.239725, 50.092524],
              [8.239719, 50.092693],
              [8.239548, 50.093357],
              [8.239483, 50.093725],
              [8.239505, 50.093963],
              [8.23958, 50.094262],
              [8.239569, 50.094355],
              [8.239387, 50.094788],
              [8.239328, 50.095195],
              [8.239419, 50.095559],
              [8.239499, 50.095728],
              [8.239762, 50.095962],
              [8.241076, 50.096771],
              [8.241538, 50.097012],
              [8.241704, 50.097166],
              [8.24209, 50.097511],
              [8.242203, 50.0977],
              [8.242219, 50.097824],
              [8.242251, 50.097989],
              [8.242364, 50.09812],
              [8.24268, 50.098271],
              [8.243871, 50.09825],
              [8.246816, 50.098292],
              [8.247401, 50.098295],
              [8.248098, 50.098309],
              [8.24944, 50.098316],
              [8.249542, 50.098233],
              [8.249574, 50.098058],
              [8.249611, 50.097944],
              [8.249622, 50.097772],
              [8.249424, 50.097751],
              [8.249118, 50.097665],
              [8.248774, 50.09759],
              [8.248715, 50.097586],
              [8.248587, 50.097762],
              [8.248356, 50.098054],
              [8.248217, 50.098285],
            ],
            [
              [8.249536, 50.098309],
              [8.249826, 50.098309],
              [8.250411, 50.098271],
              [8.250598, 50.098288],
              [8.25085, 50.098391],
              [8.251151, 50.098591],
              [8.251349, 50.098746],
              [8.251462, 50.098908],
              [8.251548, 50.099104],
              [8.251644, 50.099204],
              [8.251768, 50.099324],
              [8.251773, 50.099372],
              [8.252352, 50.099334],
              [8.252422, 50.099235],
              [8.252476, 50.098832],
              [8.252594, 50.098502],
              [8.252707, 50.09823],
              [8.25268, 50.098006],
              [8.252631, 50.097669],
              [8.252739, 50.09749],
              [8.252878, 50.09717],
              [8.253066, 50.096798],
              [8.253189, 50.096578],
              [8.25335, 50.096592],
              [8.253522, 50.096557],
              [8.253618, 50.096402],
              [8.253753, 50.096241],
              [8.253914, 50.096062],
              [8.254262, 50.095866],
              [8.254627, 50.095755],
              [8.254976, 50.095731],
              [8.255346, 50.095759],
              [8.255893, 50.095879],
              [8.256419, 50.096089],
              [8.257057, 50.09653],
              [8.257556, 50.097049],
              [8.257653, 50.097204],
              [8.257948, 50.097383],
              [8.258082, 50.097555],
              [8.258157, 50.097628],
              [8.258833, 50.098144],
              [8.25953, 50.098588],
              [8.260463, 50.099145],
              [8.261139, 50.099541],
              [8.261638, 50.099881],
              [8.262051, 50.100016],
              [8.262475, 50.100119],
              [8.262845, 50.100315],
              [8.263189, 50.10036],
              [8.263285, 50.100418],
              [8.26351, 50.100501],
              [8.263602, 50.100601],
              [8.263645, 50.100866],
              [8.263564, 50.101258],
              [8.263494, 50.101506],
              [8.263527, 50.101819],
              [8.263634, 50.102046],
              [8.263827, 50.102125],
              [8.264122, 50.102156],
              [8.264165, 50.102039],
              [8.264251, 50.101629],
              [8.264353, 50.101213],
              [8.264396, 50.101058],
              [8.26453, 50.101093],
              [8.264755, 50.101206],
              [8.265023, 50.101296],
              [8.265125, 50.10143],
              [8.265254, 50.101571],
              [8.265377, 50.10174],
              [8.265458, 50.101832],
              [8.265849, 50.102053],
              [8.265978, 50.102125],
              [8.266246, 50.102245],
              [8.266439, 50.102376],
              [8.266627, 50.102528],
              [8.266756, 50.102679],
              [8.266826, 50.102631],
              [8.266874, 50.102503],
              [8.266826, 50.102318],
              [8.266783, 50.102187],
              [8.266767, 50.102063],
              [8.26681, 50.101925],
              [8.266852, 50.101853],
              [8.266944, 50.101715],
              [8.267035, 50.10174],
              [8.267158, 50.10187],
              [8.26748, 50.101929],
              [8.268097, 50.102011],
              [8.268655, 50.102132],
              [8.269143, 50.1023],
              [8.269385, 50.102421],
              [8.26961, 50.102627],
              [8.269803, 50.10283],
              [8.269846, 50.103009],
              [8.269819, 50.103247],
              [8.269851, 50.10357],
              [8.269948, 50.103739],
              [8.27013, 50.103835],
              [8.270452, 50.103863],
              [8.271139, 50.103863],
              [8.271402, 50.103777],
              [8.271557, 50.10366],
              [8.27175, 50.103353],
              [8.271954, 50.102916],
              [8.272002, 50.102669],
              [8.271938, 50.102359],
              [8.271782, 50.102087],
              [8.271407, 50.101595],
              [8.271128, 50.101275],
              [8.270527, 50.100632],
              [8.270463, 50.100439],
              [8.270634, 50.099971],
              [8.270795, 50.099675],
              [8.270881, 50.099303],
              [8.270849, 50.098939],
              [8.270774, 50.098622],
              [8.26895, 50.097899],
              [8.2691, 50.097714],
              [8.269309, 50.097559],
              [8.269524, 50.09738],
              [8.269744, 50.097283],
              [8.269819, 50.097208],
              [8.269755, 50.097039],
              [8.269454, 50.096884],
              [8.269213, 50.09674],
              [8.268918, 50.096633],
              [8.268478, 50.096365],
              [8.268129, 50.096014],
              [8.268473, 50.095883],
              [8.269127, 50.095625],
              [8.269803, 50.096141],
              [8.270093, 50.096341],
              [8.270377, 50.096568],
              [8.270559, 50.096592],
              [8.271155, 50.096599],
              [8.271707, 50.096595],
              [8.271852, 50.09655],
              [8.271954, 50.096375],
              [8.2719, 50.09612],
              [8.271831, 50.09589],
              [8.273649, 50.095425],
              [8.273934, 50.095349],
              [8.274105, 50.095435],
              [8.274792, 50.095704],
              [8.27462, 50.095866],
              [8.274309, 50.09611],
              [8.274282, 50.096206],
              [8.274298, 50.096361],
              [8.274443, 50.096492],
              [8.274615, 50.096585],
              [8.274819, 50.096595],
              [8.275194, 50.096568],
              [8.275366, 50.096516],
              [8.275564, 50.096485],
              [8.275602, 50.096488],
              [8.275693, 50.096781],
              [8.275618, 50.096932],
              [8.275688, 50.097187],
              [8.275741, 50.097483],
              [8.275757, 50.097593],
              [8.275988, 50.097817],
              [8.276235, 50.097858],
              [8.276836, 50.097858],
              [8.277855, 50.097844],
              [8.278386, 50.097837],
              [8.278332, 50.096685],
              [8.278289, 50.095611],
              [8.27823, 50.095274],
              [8.27837, 50.094916],
              [8.278853, 50.093811],
              [8.279217, 50.092995],
              [8.27955, 50.092283],
              [8.279706, 50.091801],
              [8.279969, 50.091757],
              [8.280119, 50.091867],
              [8.28021, 50.092145],
              [8.280371, 50.0925],
              [8.280827, 50.092765],
              [8.280875, 50.092741],
              [8.281476, 50.092847],
              [8.282608, 50.092944],
              [8.283638, 50.093013],
              [8.284721, 50.093068],
              [8.284818, 50.092159],
              [8.284925, 50.09196],
              [8.285279, 50.091863],
              [8.285118, 50.09081],
              [8.284013, 50.090693],
              [8.283788, 50.09059],
              [8.28337, 50.090418],
              [8.283337, 50.090277],
              [8.283488, 50.089781],
              [8.2836, 50.08912],
              [8.283707, 50.088721],
              [8.283885, 50.088583],
              [8.284067, 50.088446],
              [8.284475, 50.08828],
              [8.284775, 50.088153],
              [8.284909, 50.088064],
              [8.28499, 50.087957],
              [8.285075, 50.087795],
              [8.285161, 50.087186],
              [8.285193, 50.086845],
              [8.282077, 50.08614],
              [8.282195, 50.085558],
              [8.282345, 50.085276],
              [8.282661, 50.084852],
              [8.282935, 50.084491],
              [8.283407, 50.083568],
              [8.280956, 50.083248],
              [8.281251, 50.083062],
              [8.281889, 50.082821],
              [8.28242, 50.082563],
              [8.283005, 50.082336],
              [8.283123, 50.082209],
              [8.283214, 50.082129],
              [8.284094, 50.082291],
              [8.284603, 50.082401],
              [8.285108, 50.082532],
              [8.285966, 50.082835],
              [8.286138, 50.082883],
              [8.286234, 50.082649],
              [8.286379, 50.082336],
              [8.28654, 50.082133],
              [8.286653, 50.082064],
              [8.286674, 50.081868],
              [8.286653, 50.081338],
              [8.286631, 50.080777],
              [8.286797, 50.080277],
              [8.287012, 50.07983],
              [8.287506, 50.079272],
              [8.287758, 50.079021],
              [8.287162, 50.079],
              [8.286143, 50.079],
              [8.283986, 50.079231],
              [8.281143, 50.079616],
              [8.281272, 50.079878],
              [8.281476, 50.080284],
              [8.279389, 50.080133],
              [8.279464, 50.081661],
              [8.275795, 50.081462],
              [8.274068, 50.081382],
              [8.271697, 50.081231],
              [8.27049, 50.081159],
              [8.270275, 50.083183],
              [8.270109, 50.08469],
              [8.273365, 50.085193],
              [8.273724, 50.085248],
              [8.274368, 50.085358],
              [8.274733, 50.085365],
              [8.274947, 50.085269],
              [8.276331, 50.084952],
              [8.276181, 50.084374],
              [8.275902, 50.083265],
              [8.275645, 50.082481],
              [8.275441, 50.081482],
            ],
            [
              [8.286138, 50.082914],
              [8.2861, 50.083138],
              [8.285961, 50.083427],
              [8.285687, 50.083616],
              [8.285387, 50.083706],
              [8.28514, 50.083716],
              [8.28456, 50.083709],
              [8.283654, 50.083627],
              [8.283429, 50.083579],
            ],
            [
              [8.281953, 50.083389],
              [8.281395, 50.084983],
              [8.281154, 50.085561],
              [8.282066, 50.086002],
            ],
            [
              [8.195329, 50.082845],
              [8.194921, 50.083155],
              [8.194438, 50.083541],
              [8.192217, 50.08268],
              [8.192099, 50.082522],
              [8.192142, 50.082205],
              [8.192089, 50.081289],
              [8.192046, 50.080952],
              [8.191745, 50.07961],
              [8.191541, 50.078804],
              [8.191284, 50.078143],
            ],
            [
              [8.199347, 50.092142],
              [8.19917, 50.092097],
              [8.197871, 50.091966],
              [8.197137, 50.09176],
              [8.196198, 50.091392],
              [8.195463, 50.091116],
              [8.1952, 50.090882],
              [8.195136, 50.090628],
              [8.195318, 50.090366],
              [8.195597, 50.090242],
              [8.19586, 50.090208],
              [8.196697, 50.090156],
              [8.198628, 50.090039],
              [8.198934, 50.089967],
            ],
            [
              [8.242761, 50.091106],
              [8.242911, 50.090817],
              [8.243104, 50.090459],
              [8.243437, 50.090218],
              [8.244241, 50.089943],
              [8.244359, 50.089895],
            ],
            [
              [8.285151, 50.088043],
              [8.286202, 50.088332],
              [8.286781, 50.088594],
              [8.286953, 50.088814],
              [8.286974, 50.089488],
              [8.28691, 50.090122],
              [8.286674, 50.09048],
              [8.285204, 50.090803],
            ],
          ],
        },
        id: "E5Njk",
      },
    ],
  },
  {
    category: BusCategory.QUARTIER,
    id: "Q4MzQ",
    name: "33 Nordenstadt <> Niedernhausen Bf",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.334256, 50.057249],
              [8.334374, 50.058248],
              [8.33461, 50.058661],
              [8.335243, 50.059625],
              [8.335737, 50.060321],
              [8.336134, 50.060369],
              [8.338655, 50.059818],
              [8.340855, 50.059274],
              [8.342078, 50.059095],
              [8.341938, 50.058813],
              [8.341466, 50.058],
              [8.341026, 50.057256],
              [8.340672, 50.057035],
              [8.339578, 50.057015],
              [8.334364, 50.057201],
              [8.333237, 50.057242],
              [8.331059, 50.057435],
              [8.329911, 50.057518],
              [8.328989, 50.057607],
              [8.328613, 50.05769],
              [8.328162, 50.057862],
              [8.327948, 50.058048],
              [8.327776, 50.05831],
              [8.327733, 50.058468],
              [8.327808, 50.058751],
              [8.328098, 50.059212],
              [8.329031, 50.0607],
              [8.329493, 50.061457],
              [8.329557, 50.061692],
              [8.329686, 50.061816],
              [8.329954, 50.062029],
              [8.330222, 50.062243],
              [8.331639, 50.064433],
              [8.332604, 50.065858],
              [8.333881, 50.067642],
              [8.334825, 50.068895],
              [8.335136, 50.06884],
              [8.340447, 50.067429],
              [8.339932, 50.066444],
              [8.33873, 50.064137],
              [8.337851, 50.06298],
              [8.33946, 50.062305],
              [8.340919, 50.061561],
              [8.340962, 50.06061],
              [8.342679, 50.060307],
              [8.342464, 50.059811],
              [8.34255, 50.059605],
              [8.345983, 50.061781],
              [8.348107, 50.06382],
              [8.348494, 50.064219],
              [8.349695, 50.064116],
              [8.351927, 50.063937],
              [8.352721, 50.06382],
              [8.353493, 50.063696],
              [8.353944, 50.063717],
              [8.355038, 50.063599],
              [8.357345, 50.063276],
              [8.360832, 50.062766],
              [8.362023, 50.062615],
              [8.364941, 50.062201],
              [8.365273, 50.063193],
              [8.369136, 50.064887],
              [8.369372, 50.065004],
              [8.369823, 50.065714],
              [8.370659, 50.067119],
              [8.370949, 50.067711],
              [8.370949, 50.068413],
              [8.370756, 50.069164],
              [8.370713, 50.070775],
              [8.370649, 50.072855],
              [8.370563, 50.073695],
              [8.370445, 50.073888],
              [8.369286, 50.074707],
              [8.369458, 50.07501],
              [8.369769, 50.07512],
              [8.370155, 50.075534],
              [8.370209, 50.075892],
              [8.370305, 50.076119],
              [8.370155, 50.076635],
              [8.369458, 50.077923],
              [8.369286, 50.07835],
              [8.368653, 50.079238],
              [8.368052, 50.080133],
              [8.367999, 50.080298],
              [8.363986, 50.080367],
              [8.363321, 50.081214],
              [8.362956, 50.081696],
              [8.362666, 50.081971],
              [8.361862, 50.083114],
              [8.361776, 50.083596],
              [8.361518, 50.08403],
              [8.36066, 50.085544],
              [8.359834, 50.087052],
              [8.359126, 50.088428],
              [8.358257, 50.090046],
              [8.357613, 50.091726],
              [8.357527, 50.092304],
              [8.357635, 50.092813],
              [8.357892, 50.093384],
              [8.359169, 50.09485],
              [8.359931, 50.095931],
              [8.360188, 50.096399],
              [8.360285, 50.097342],
              [8.36037, 50.097975],
              [8.36022, 50.098567],
              [8.359952, 50.099737],
              [8.359855, 50.101044],
              [8.359662, 50.101801],
              [8.35934, 50.102283],
              [8.358901, 50.102607],
              [8.358525, 50.102992],
              [8.358407, 50.10432],
              [8.358192, 50.105001],
              [8.35786, 50.106309],
              [8.35801, 50.107506],
              [8.358493, 50.109041],
              [8.359287, 50.110437],
              [8.360027, 50.111139],
              [8.360628, 50.112075],
              [8.360875, 50.113121],
              [8.361143, 50.114373],
              [8.361561, 50.115308],
              [8.361658, 50.115769],
              [8.361712, 50.116313],
              [8.361282, 50.117187],
              [8.360628, 50.118294],
              [8.359973, 50.119037],
              [8.359448, 50.11956],
              [8.359072, 50.120076],
              [8.359072, 50.12097],
              [8.359137, 50.12161],
              [8.358697, 50.122724],
              [8.358268, 50.12368],
              [8.358471, 50.124554],
              [8.358847, 50.125937],
              [8.359104, 50.127539],
              [8.358879, 50.127986],
              [8.358192, 50.129211],
              [8.357999, 50.129726],
              [8.357924, 50.130194],
              [8.357849, 50.132333],
              [8.357635, 50.132636],
              [8.356948, 50.133234],
              [8.356025, 50.134066],
              [8.355703, 50.134781],
              [8.3561, 50.134829],
              [8.356776, 50.134898],
              [8.357409, 50.134967],
              [8.358943, 50.135435],
              [8.360564, 50.135923],
              [8.362141, 50.136459],
              [8.362538, 50.136789],
              [8.36302, 50.137298],
              [8.363321, 50.137938],
              [8.364018, 50.138529],
              [8.364769, 50.13921],
              [8.364919, 50.139292],
              [8.36522, 50.139292],
              [8.365252, 50.139189],
              [8.365241, 50.139045],
              [8.365231, 50.138859],
              [8.365327, 50.138777],
              [8.365628, 50.138749],
              [8.365917, 50.138859],
              [8.366067, 50.139031],
              [8.3661, 50.139416],
              [8.365917, 50.139698],
              [8.364362, 50.141115],
              [8.364018, 50.141197],
              [8.363557, 50.141218],
              [8.363138, 50.141115],
              [8.362409, 50.140771],
              [8.362076, 50.140674],
              [8.361894, 50.140668],
              [8.3614, 50.140578],
              [8.359094, 50.140379],
              [8.358611, 50.140338],
              [8.357924, 50.140351],
              [8.357431, 50.140393],
              [8.357055, 50.140413],
              [8.356594, 50.140413],
              [8.356111, 50.140317],
              [8.354791, 50.139987],
              [8.353665, 50.139794],
              [8.353869, 50.139952],
              [8.354137, 50.140296],
              [8.354158, 50.140551],
              [8.354062, 50.140998],
              [8.353043, 50.142799],
              [8.352849, 50.143205],
              [8.352624, 50.143322],
              [8.352131, 50.143535],
              [8.351401, 50.143803],
              [8.350929, 50.143886],
              [8.350607, 50.143858],
              [8.349878, 50.143789],
              [8.349009, 50.143597],
              [8.348225, 50.143363],
              [8.347882, 50.143054],
              [8.347807, 50.142737],
              [8.347861, 50.142297],
              [8.347893, 50.141823],
              [8.347828, 50.141616],
              [8.34771, 50.141479],
              [8.347335, 50.1413],
              [8.346745, 50.140908],
              [8.346444, 50.140723],
              [8.346283, 50.140303],
              [8.346208, 50.140056],
              [8.346219, 50.139801],
              [8.34623, 50.139574],
              [8.346326, 50.139347],
              [8.345404, 50.139279],
              [8.343955, 50.139258],
              [8.342786, 50.139251],
              [8.342443, 50.139361],
              [8.342185, 50.139457],
              [8.342121, 50.139478],
              [8.341713, 50.139409],
              [8.341166, 50.139327],
              [8.34034, 50.139244],
              [8.339846, 50.139162],
              [8.339267, 50.139203],
              [8.338516, 50.13932],
              [8.338602, 50.139842],
              [8.338162, 50.140241],
              [8.33607, 50.140826],
              [8.33534, 50.141046],
              [8.335093, 50.141032],
              [8.334557, 50.140963],
              [8.334213, 50.140956],
              [8.334042, 50.141025],
              [8.332883, 50.141479],
              [8.3321, 50.141782],
              [8.331842, 50.141898],
              [8.331478, 50.14196],
              [8.331167, 50.14194],
              [8.33092, 50.142008],
              [8.330255, 50.142572],
              [8.329664, 50.142765],
              [8.329428, 50.142999],
              [8.328989, 50.143535],
              [8.328441, 50.143947],
              [8.327969, 50.144442],
              [8.327765, 50.145013],
              [8.327733, 50.145604],
              [8.327594, 50.146519],
              [8.327422, 50.147447],
              [8.326993, 50.149283],
              [8.326617, 50.150768],
              [8.326285, 50.151469],
              [8.325748, 50.151868],
              [8.325062, 50.152239],
              [8.323903, 50.152892],
              [8.32342, 50.153325],
              [8.323345, 50.153511],
              [8.322541, 50.155167],
              [8.322036, 50.156171],
              [8.3218, 50.157298],
              [8.321543, 50.158116],
              [8.320416, 50.159209],
              [8.319547, 50.160192],
              [8.319054, 50.160721],
              [8.318185, 50.161037],
              [8.317316, 50.161367],
              [8.316318, 50.161821],
              [8.315116, 50.162446],
              [8.314494, 50.161828],
              [8.31399, 50.161422],
              [8.313518, 50.160879],
              [8.313271, 50.16079],
              [8.312852, 50.160975],
              [8.312573, 50.161161],
              [8.311715, 50.161381],
              [8.310041, 50.161793],
              [8.309258, 50.161876],
              [8.309076, 50.161896],
              [8.309205, 50.161718],
              [8.309827, 50.161498],
              [8.310728, 50.161147],
              [8.311104, 50.160962],
              [8.311543, 50.160811],
              [8.312176, 50.160659],
              [8.31238, 50.160563],
              [8.312874, 50.160893],
            ],
            [
              [8.367934, 50.080367],
              [8.367473, 50.081503],
              [8.366572, 50.082522],
              [8.366615, 50.082673],
              [8.366743, 50.082756],
              [8.367741, 50.083031],
              [8.369114, 50.082673],
              [8.370262, 50.082322],
              [8.370638, 50.081909],
              [8.371432, 50.081049],
              [8.371797, 50.080773],
              [8.372312, 50.080622],
              [8.372784, 50.080573],
              [8.373191, 50.080649],
              [8.373889, 50.080697],
              [8.374232, 50.080429],
              [8.374543, 50.07983],
              [8.37509, 50.079555],
              [8.375101, 50.07921],
              [8.375015, 50.078838],
              [8.374822, 50.078343],
              [8.374811, 50.077875],
              [8.374532, 50.077751],
              [8.372773, 50.077475],
              [8.372505, 50.077331],
              [8.372129, 50.076986],
              [8.371754, 50.076828],
              [8.370187, 50.07658],
            ],
            [
              [8.33402, 50.067615],
              [8.334932, 50.06738],
              [8.33607, 50.067084],
              [8.337421, 50.066705],
              [8.339632, 50.066093],
            ],
          ],
        },
        id: "Q4MzQ",
      },
    ],
  },
  {
    category: BusCategory.QUARTIER,
    id: "c3OTY",
    name: "34 (Naurod <> Domäne Mechthildshausen)",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.296663, 50.127807],
              [8.298583, 50.126796],
              [8.298637, 50.126624],
              [8.298647, 50.126439],
              [8.298647, 50.126054],
              [8.298519, 50.126033],
              [8.297188, 50.126026],
              [8.296899, 50.126033],
              [8.295954, 50.126576],
              [8.295944, 50.127271],
              [8.296598, 50.127856],
              [8.295064, 50.128523],
              [8.294882, 50.128922],
              [8.295311, 50.129541],
              [8.295504, 50.129878],
              [8.295407, 50.130132],
              [8.295182, 50.130283],
              [8.294485, 50.130297],
              [8.29412, 50.130352],
              [8.293787, 50.131164],
              [8.293476, 50.131549],
              [8.293047, 50.131893],
              [8.292929, 50.132147],
              [8.292929, 50.132388],
              [8.293208, 50.132574],
              [8.29339, 50.132677],
              [8.293691, 50.132711],
              [8.29412, 50.132752],
              [8.294474, 50.132746],
              [8.294592, 50.132649],
              [8.294839, 50.132512],
              [8.295075, 50.132374],
              [8.29545, 50.132347],
              [8.29603, 50.132415],
              [8.29648, 50.132567],
              [8.297274, 50.132704],
              [8.29765, 50.13289],
              [8.298969, 50.133667],
              [8.301394, 50.135111],
              [8.301866, 50.134898],
              [8.303272, 50.133983],
              [8.303958, 50.133557],
              [8.304784, 50.133055],
              [8.305954, 50.132409],
              [8.306522, 50.132168],
              [8.307885, 50.131824],
              [8.308572, 50.13157],
              [8.309999, 50.131178],
              [8.310803, 50.130923],
              [8.311876, 50.130462],
              [8.312316, 50.130421],
              [8.313196, 50.130194],
              [8.315138, 50.12963],
              [8.315599, 50.129403],
              [8.316597, 50.128365],
              [8.317165, 50.127973],
              [8.317959, 50.127388],
              [8.318667, 50.126941],
              [8.319236, 50.126673],
              [8.320502, 50.125978],
              [8.32077, 50.125716],
              [8.320888, 50.12551],
              [8.321146, 50.125311],
              [8.321564, 50.125152],
              [8.322154, 50.125063],
              [8.322809, 50.125132],
              [8.323216, 50.125097],
              [8.323485, 50.12498],
              [8.323495, 50.124843],
              [8.323678, 50.124506],
              [8.323828, 50.124272],
              [8.324193, 50.124018],
              [8.324568, 50.123804],
              [8.324794, 50.123598],
              [8.325008, 50.123515],
              [8.325137, 50.123564],
              [8.32533, 50.123488],
              [8.325512, 50.12335],
              [8.325899, 50.123082],
              [8.326102, 50.123006],
              [8.326725, 50.122876],
              [8.327047, 50.122745],
              [8.327326, 50.122594],
              [8.327487, 50.122497],
              [8.327851, 50.122408],
              [8.329278, 50.121858],
              [8.329493, 50.121651],
              [8.329782, 50.121417],
              [8.330083, 50.121218],
              [8.330641, 50.120901],
              [8.33137, 50.120585],
              [8.33166, 50.120434],
              [8.332314, 50.120269],
              [8.33298, 50.120042],
              [8.333216, 50.119925],
              [8.333398, 50.119835],
              [8.333462, 50.119608],
              [8.333634, 50.119347],
              [8.33387, 50.119072],
              [8.33401, 50.118982],
              [8.333613, 50.117524],
              [8.333291, 50.116526],
              [8.332894, 50.115336],
              [8.332797, 50.114896],
              [8.332754, 50.114669],
              [8.332862, 50.114559],
              [8.333302, 50.11418],
              [8.333827, 50.113472],
              [8.334439, 50.112646],
              [8.334922, 50.111944],
              [8.33505, 50.111648],
              [8.335222, 50.110857],
              [8.335458, 50.109825],
              [8.335587, 50.10924],
              [8.335758, 50.109144],
              [8.336091, 50.109027],
              [8.336198, 50.108869],
              [8.336263, 50.108614],
              [8.336284, 50.108476],
              [8.336477, 50.108263],
              [8.336735, 50.107953],
              [8.337057, 50.107602],
              [8.338087, 50.106894],
              [8.338215, 50.106687],
              [8.338408, 50.106446],
              [8.338248, 50.106233],
              [8.338183, 50.106027],
              [8.338344, 50.105786],
              [8.338677, 50.105318],
              [8.339074, 50.104926],
              [8.339471, 50.104595],
              [8.339878, 50.104299],
              [8.340704, 50.103687],
              [8.340586, 50.103432],
              [8.340265, 50.102944],
              [8.340018, 50.102538],
              [8.340018, 50.102235],
              [8.339986, 50.10207],
              [8.339224, 50.101953],
              [8.339031, 50.101905],
              [8.339031, 50.101664],
              [8.338859, 50.100811],
              [8.338645, 50.100226],
              [8.338258, 50.099358],
              [8.338172, 50.099007],
              [8.338119, 50.098533],
              [8.338376, 50.097762],
              [8.338677, 50.097418],
              [8.339406, 50.096578],
              [8.340415, 50.095704],
              [8.341112, 50.095112],
              [8.343515, 50.092792],
              [8.344889, 50.091609],
              [8.346047, 50.090191],
              [8.34682, 50.089337],
              [8.347399, 50.088277],
              [8.347743, 50.087492],
              [8.348751, 50.086281],
              [8.349245, 50.085289],
              [8.349996, 50.084243],
              [8.350854, 50.08321],
              [8.35242, 50.081613],
              [8.352796, 50.081647],
              [8.353815, 50.081586],
              [8.35477, 50.081455],
              [8.355489, 50.08131],
              [8.356701, 50.080808],
              [8.357517, 50.080567],
              [8.358353, 50.080512],
              [8.359394, 50.08047],
              [8.363718, 50.080443],
              [8.363943, 50.080339],
              [8.364705, 50.080319],
              [8.367934, 50.08025],
              [8.368524, 50.07932],
              [8.368975, 50.078687],
              [8.369254, 50.078129],
              [8.369565, 50.077647],
              [8.370048, 50.07667],
              [8.370112, 50.076463],
              [8.370166, 50.075809],
              [8.369941, 50.075458],
              [8.369479, 50.074962],
              [8.370359, 50.074487],
              [8.372312, 50.072945],
              [8.37494, 50.070968],
              [8.377322, 50.069095],
              [8.377569, 50.06893],
              [8.378041, 50.069185],
              [8.378427, 50.069625],
              [8.379307, 50.070479],
              [8.380122, 50.071347],
              [8.380401, 50.071774],
              [8.381882, 50.071168],
              [8.381377, 50.070624],
              [8.380852, 50.06999],
              [8.380487, 50.06955],
              [8.380734, 50.069054],
              [8.381259, 50.067952],
              [8.381892, 50.066692],
              [8.38201, 50.066402],
              [8.382182, 50.066327],
              [8.382493, 50.066354],
              [8.382912, 50.066485],
              [8.383727, 50.066616],
              [8.384532, 50.064949],
              [8.384746, 50.064481],
              [8.385004, 50.063882],
              [8.384886, 50.063007],
              [8.38466, 50.063028],
              [8.384124, 50.063076],
              [8.383631, 50.063221],
              [8.383352, 50.063393],
              [8.383255, 50.063558],
              [8.383126, 50.063765],
              [8.383073, 50.064219],
              [8.382944, 50.064626],
              [8.382568, 50.064812],
              [8.382246, 50.064894],
              [8.381785, 50.064949],
              [8.381259, 50.064929],
              [8.381431, 50.064619],
              [8.38185, 50.064047],
              [8.38215, 50.06362],
              [8.382268, 50.063042],
              [8.38215, 50.062635],
              [8.381817, 50.062146],
              [8.381238, 50.06163],
              [8.38083, 50.061451],
              [8.380187, 50.061223],
              [8.379296, 50.061106],
              [8.378384, 50.061092],
              [8.377848, 50.061051],
              [8.377622, 50.061623],
              [8.377204, 50.061933],
              [8.37685, 50.062153],
              [8.376174, 50.062449],
              [8.375938, 50.062635],
              [8.375702, 50.062973],
              [8.375509, 50.063283],
              [8.375262, 50.063634],
              [8.374908, 50.063944],
              [8.374608, 50.064357],
              [8.374479, 50.064433],
              [8.372837, 50.064006],
              [8.372623, 50.063964],
              [8.372419, 50.064288],
              [8.369715, 50.063682],
              [8.369672, 50.063296],
              [8.369737, 50.063055],
              [8.370048, 50.062463],
              [8.370284, 50.061912],
              [8.37037, 50.061533],
              [8.370831, 50.061079],
              [8.368943, 50.059873],
              [8.369018, 50.059653],
              [8.369747, 50.059157],
              [8.370005, 50.059005],
              [8.370874, 50.058516],
              [8.37082, 50.058324],
              [8.370337, 50.058055],
              [8.369951, 50.057896],
              [8.369876, 50.057648],
              [8.369898, 50.057463],
              [8.370069, 50.057304],
              [8.370316, 50.057125],
              [8.370552, 50.057001],
              [8.370852, 50.057022],
              [8.371378, 50.057339],
              [8.373749, 50.058537],
              [8.372526, 50.059494],
              [8.372054, 50.05946],
              [8.371507, 50.059329],
              [8.371142, 50.059253],
              [8.370895, 50.058854],
              [8.370541, 50.058854],
              [8.369983, 50.058902],
              [8.369662, 50.058558],
              [8.369436, 50.058172],
              [8.369544, 50.057277],
              [8.369887, 50.056023],
              [8.37038, 50.054893],
              [8.37141, 50.05417],
              [8.372247, 50.053557],
              [8.374071, 50.052255],
              [8.375401, 50.05129],
              [8.374919, 50.050712],
              [8.374565, 50.05003],
              [8.373953, 50.049327],
              [8.372902, 50.048438],
              [8.371807, 50.047763],
              [8.371249, 50.047508],
              [8.370563, 50.04737],
              [8.369393, 50.047343],
              [8.368235, 50.047322],
              [8.367516, 50.047184],
              [8.366765, 50.046978],
              [8.366067, 50.046668],
              [8.365649, 50.046351],
              [8.362033, 50.047288],
              [8.361551, 50.047405],
              [8.361315, 50.047412],
              [8.360907, 50.047033],
              [8.360596, 50.04642],
              [8.360349, 50.045896],
              [8.359802, 50.045565],
              [8.358493, 50.0456],
              [8.358074, 50.045538],
              [8.357077, 50.045476],
              [8.356197, 50.045421],
              [8.355317, 50.045331],
              [8.354743, 50.045338],
              [8.354153, 50.045376],
              [8.353617, 50.045572],
              [8.352812, 50.046023],
              [8.350945, 50.046985],
              [8.350704, 50.047067],
              [8.350462, 50.047053],
              [8.350317, 50.046923],
              [8.349261, 50.044742],
              [8.34918, 50.044446],
              [8.349309, 50.04416],
              [8.350119, 50.043598],
              [8.350307, 50.043484],
              [8.350554, 50.043395],
              [8.350897, 50.043312],
              [8.351197, 50.043412],
              [8.351696, 50.044263],
              [8.352216, 50.045066],
              [8.352495, 50.045569],
              [8.352613, 50.0457],
              [8.352823, 50.045955],
            ],
            [
              [8.304033, 50.133612],
              [8.305557, 50.134596],
              [8.305922, 50.134451],
              [8.306522, 50.134204],
              [8.306855, 50.13419],
              [8.307263, 50.134245],
              [8.30943, 50.135008],
              [8.309001, 50.135565],
            ],
            [
              [8.355221, 50.04531],
              [8.354968, 50.04498],
              [8.354545, 50.044294],
              [8.354083, 50.043533],
              [8.353563, 50.042723],
              [8.353161, 50.042137],
              [8.35315, 50.04201],
              [8.353166, 50.041914],
              [8.353756, 50.041762],
              [8.355027, 50.041397],
              [8.355296, 50.041355],
              [8.355392, 50.04139],
              [8.355532, 50.041504],
              [8.356766, 50.043443],
              [8.356985, 50.043898],
              [8.357012, 50.044267],
              [8.357012, 50.044945],
              [8.357018, 50.045438],
            ],
            [
              [8.359619, 50.045527],
              [8.359641, 50.045324],
              [8.359544, 50.045193],
              [8.358766, 50.044618],
              [8.358675, 50.044432],
              [8.358788, 50.044267],
              [8.359228, 50.043791],
              [8.359469, 50.043522],
              [8.359812, 50.043026],
              [8.360338, 50.042454],
              [8.360606, 50.042292],
              [8.360805, 50.042165],
              [8.362479, 50.041352],
              [8.361481, 50.03976],
              [8.361105, 50.039292],
              [8.360483, 50.039433],
              [8.36015, 50.039119],
              [8.359963, 50.038985],
              [8.357694, 50.038096],
              [8.357506, 50.038093],
              [8.357372, 50.038179],
              [8.356825, 50.038375],
              [8.355634, 50.038765],
              [8.355081, 50.0376],
              [8.354732, 50.036835],
              [8.354223, 50.036473],
              [8.351514, 50.035378],
              [8.348869, 50.03434],
              [8.348504, 50.034175],
              [8.348252, 50.033941],
              [8.348188, 50.033786],
              [8.347678, 50.033741],
              [8.341198, 50.031301],
              [8.339975, 50.03086],
              [8.335168, 50.028889],
              [8.333066, 50.028062],
              [8.326521, 50.035133],
              [8.324053, 50.037752],
              [8.323839, 50.038048],
              [8.32386, 50.038289],
              [8.322583, 50.038668],
            ],
            [
              [8.367827, 50.080298],
              [8.367387, 50.081503],
              [8.366539, 50.082481],
              [8.366486, 50.08268],
              [8.366604, 50.082763],
              [8.367076, 50.08288],
              [8.367816, 50.083079],
              [8.370316, 50.08235],
              [8.370949, 50.081641],
              [8.371443, 50.081083],
              [8.371818, 50.080794],
              [8.372247, 50.080677],
              [8.372773, 50.080601],
              [8.373288, 50.080704],
              [8.37391, 50.080725],
              [8.374296, 50.080463],
              [8.374586, 50.07983],
              [8.375144, 50.079575],
              [8.375144, 50.079135],
              [8.374865, 50.078336],
              [8.374865, 50.077868],
              [8.374532, 50.077696],
              [8.372805, 50.077434],
              [8.372204, 50.076993],
              [8.371872, 50.076821],
              [8.370134, 50.076532],
            ],
            [
              [8.357667, 50.038048],
              [8.358257, 50.037276],
              [8.360306, 50.038034],
              [8.362087, 50.038103],
              [8.362087, 50.038227],
              [8.36198, 50.038399],
              [8.361626, 50.038696],
              [8.361207, 50.038916],
              [8.361003, 50.039116],
              [8.360982, 50.039274],
            ],
            [
              [8.323538, 50.125036],
              [8.323839, 50.125207],
              [8.324139, 50.125263],
              [8.324912, 50.125407],
              [8.325448, 50.125586],
              [8.325866, 50.12507],
              [8.326199, 50.124698],
              [8.326349, 50.124458],
              [8.326467, 50.124176],
              [8.326564, 50.123949],
              [8.326671, 50.123811],
              [8.326671, 50.123502],
              [8.326542, 50.123151],
              [8.326446, 50.122986],
            ],
            [
              [8.369737, 50.063785],
              [8.369737, 50.06393],
              [8.369468, 50.064302],
              [8.369157, 50.064839],
              [8.369318, 50.064922],
              [8.37038, 50.066588],
              [8.371818, 50.06517],
              [8.371979, 50.064998],
              [8.372054, 50.064929],
              [8.372376, 50.064323],
            ],
            [
              [8.369468, 50.054067],
              [8.37082, 50.054122],
              [8.371217, 50.054273],
            ],
          ],
        },
        id: "c3OTY",
      },
    ],
  },
  {
    category: BusCategory.QUARTIER,
    id: "E3OTc",
    name: "35 Kostheim <> Sonnenberg",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.265375, 50.101753],
              [8.264951, 50.101294],
              [8.264484, 50.101113],
              [8.2642, 50.102134],
              [8.264916, 50.101898],
              [8.265399, 50.101781],
              [8.265849, 50.101664],
              [8.266085, 50.10143],
              [8.266751, 50.100687],
              [8.267158, 50.100205],
              [8.267223, 50.099785],
              [8.266997, 50.099324],
              [8.266718, 50.099049],
              [8.265989, 50.098629],
              [8.265334, 50.098292],
              [8.264616, 50.098009],
              [8.264272, 50.09781],
              [8.264272, 50.097486],
              [8.26438, 50.097197],
              [8.264551, 50.09706],
              [8.265163, 50.09686],
              [8.267341, 50.096103],
              [8.268703, 50.095552],
              [8.269551, 50.095312],
              [8.270849, 50.094541],
              [8.271557, 50.093956],
              [8.271943, 50.093536],
              [8.272222, 50.093054],
              [8.272716, 50.092386],
              [8.273113, 50.091801],
              [8.273295, 50.091436],
              [8.273338, 50.091182],
              [8.274626, 50.09081],
              [8.275194, 50.090549],
              [8.275774, 50.090197],
              [8.276128, 50.089901],
              [8.276396, 50.089599],
              [8.277018, 50.088773],
              [8.277533, 50.087947],
              [8.277619, 50.087348],
              [8.277619, 50.086948],
              [8.276739, 50.085317],
              [8.276567, 50.084807],
              [8.27999, 50.0833],
              [8.281353, 50.082715],
              [8.282404, 50.082288],
              [8.282855, 50.082061],
              [8.28293, 50.08195],
              [8.283069, 50.081916],
              [8.283745, 50.081964],
              [8.284904, 50.082136],
              [8.28618, 50.082618],
              [8.286438, 50.082109],
              [8.286524, 50.081999],
              [8.286588, 50.081895],
              [8.286545, 50.081407],
              [8.28662, 50.080684],
              [8.286653, 50.080408],
              [8.286878, 50.079968],
              [8.28705, 50.079623],
              [8.287489, 50.079176],
              [8.28824, 50.078549],
              [8.288552, 50.078088],
              [8.289045, 50.077317],
              [8.289721, 50.07605],
              [8.290536, 50.074542],
              [8.290912, 50.073819],
              [8.291062, 50.073227],
              [8.291245, 50.072139],
              [8.291395, 50.071595],
              [8.291802, 50.070707],
              [8.293294, 50.067566],
              [8.295043, 50.06384],
              [8.29589, 50.062105],
              [8.295954, 50.061699],
              [8.295944, 50.061175],
              [8.295965, 50.05957],
              [8.295933, 50.058468],
              [8.295836, 50.057945],
              [8.295954, 50.057559],
              [8.296126, 50.057035],
              [8.296341, 50.056436],
              [8.296394, 50.056209],
              [8.296416, 50.055802],
              [8.298744, 50.055045],
              [8.300278, 50.054149],
              [8.3009, 50.053715],
              [8.301351, 50.053619],
              [8.302435, 50.053267],
              [8.303089, 50.053164],
              [8.304205, 50.053088],
              [8.30458, 50.053068],
              [8.304859, 50.053185],
              [8.305535, 50.053068],
              [8.306201, 50.052916],
              [8.306576, 50.052661],
              [8.307091, 50.052275],
              [8.307241, 50.0522],
              [8.307123, 50.051724],
              [8.30707, 50.051008],
              [8.307016, 50.050677],
              [8.30781, 50.049113],
              [8.308014, 50.048838],
              [8.307842, 50.0487],
              [8.307477, 50.048679],
              [8.305149, 50.048397],
              [8.303432, 50.048383],
              [8.301823, 50.048383],
              [8.301619, 50.047667],
              [8.30133, 50.047047],
              [8.301115, 50.046757],
              [8.300836, 50.046316],
              [8.300697, 50.045896],
              [8.300568, 50.04562],
              [8.300418, 50.0452],
              [8.300396, 50.045035],
              [8.300321, 50.044766],
              [8.300149, 50.044339],
              [8.299634, 50.043884],
              [8.299141, 50.043595],
              [8.298937, 50.04336],
              [8.298776, 50.043057],
              [8.298723, 50.042802],
              [8.29883, 50.042217],
              [8.298712, 50.041156],
              [8.298594, 50.040239],
              [8.298283, 50.038765],
              [8.297864, 50.037889],
              [8.29736, 50.036904],
              [8.29648, 50.035167],
              [8.295901, 50.033975],
              [8.295654, 50.03321],
              [8.295246, 50.032121],
              [8.294388, 50.030805],
              [8.29339, 50.029289],
              [8.292253, 50.0274],
              [8.291255, 50.025939],
              [8.289174, 50.026497],
              [8.285494, 50.027669],
              [8.281621, 50.02882],
              [8.280269, 50.02922],
              [8.279722, 50.029282],
              [8.278005, 50.029406],
              [8.276696, 50.029468],
              [8.275301, 50.029537],
              [8.274636, 50.029537],
              [8.27395, 50.029392],
              [8.273392, 50.029082],
              [8.273145, 50.02882],
              [8.272909, 50.02831],
              [8.272705, 50.027945],
              [8.273724, 50.027359],
              [8.276879, 50.025643],
              [8.278488, 50.024733],
              [8.27793, 50.023926],
              [8.27867, 50.023582],
              [8.280183, 50.023058],
              [8.282104, 50.02232],
              [8.284796, 50.021135],
              [8.287865, 50.019894],
              [8.28838, 50.019528],
              [8.290944, 50.018281],
              [8.292392, 50.017385],
              [8.293852, 50.016371],
              [8.295203, 50.015358],
              [8.296437, 50.01442],
              [8.297188, 50.013675],
              [8.297596, 50.013165],
              [8.298175, 50.012117],
              [8.298701, 50.01109],
              [8.299913, 50.008546],
              [8.300343, 50.007815],
              [8.300568, 50.007574],
              [8.301147, 50.006636],
              [8.30148, 50.005836],
              [8.301716, 50.005257],
              [8.301898, 50.00485],
              [8.301845, 50.004222],
              [8.301759, 50.00345],
              [8.30104, 50.002326],
              [8.300493, 50.001402],
              [8.301812, 50.001029],
              [8.302724, 50.000657],
              [8.304065, 50.000374],
              [8.304902, 50.000298],
              [8.305782, 50.000298],
              [8.305718, 50.001402],
              [8.305771, 50.003422],
              [8.302735, 50.003388],
              [8.301694, 50.001126],
            ],
            [
              [8.300482, 50.045007],
              [8.301029, 50.045111],
              [8.302596, 50.045159],
              [8.303701, 50.045021],
              [8.30561, 50.044759],
              [8.308164, 50.044242],
              [8.311114, 50.043588],
              [8.313153, 50.043168],
              [8.313357, 50.042857],
              [8.314011, 50.042479],
              [8.314569, 50.042292],
              [8.317133, 50.041721],
              [8.31885, 50.041286],
              [8.319247, 50.041045],
              [8.319923, 50.040535],
              [8.321961, 50.03893],
              [8.322487, 50.038675],
            ],
            [
              [8.286256, 50.082708],
              [8.286234, 50.082997],
              [8.286127, 50.083334],
              [8.285934, 50.08352],
              [8.285644, 50.083658],
              [8.285108, 50.083692],
              [8.284099, 50.083658],
              [8.282994, 50.083568],
              [8.280923, 50.0833],
              [8.280247, 50.083231],
            ],
          ],
        },
        id: "E3OTc",
      },
    ],
  },
  {
    category: BusCategory.QUARTIER,
    id: "cxODE",
    name: "37 Nordenstadt <> Hochheim",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.353751, 50.011249],
              [8.353053, 50.011049],
              [8.352635, 50.010952],
              [8.351905, 50.010945],
              [8.35153, 50.010993],
              [8.350843, 50.01109],
              [8.350446, 50.011131],
              [8.349341, 50.011131],
              [8.349116, 50.011049],
              [8.348655, 50.010814],
              [8.34815, 50.01069],
              [8.347238, 50.010676],
              [8.346101, 50.010766],
              [8.345135, 50.010814],
              [8.345393, 50.01229],
              [8.34549, 50.012883],
              [8.345125, 50.013655],
              [8.344781, 50.014096],
              [8.344653, 50.014579],
              [8.344706, 50.015061],
              [8.344932, 50.015578],
              [8.34565, 50.016364],
              [8.346208, 50.016992],
              [8.346498, 50.01744],
              [8.346723, 50.017977],
              [8.347034, 50.018391],
              [8.347238, 50.01866],
              [8.347399, 50.018825],
              [8.347603, 50.018929],
              [8.348075, 50.018956],
              [8.348783, 50.018901],
              [8.352463, 50.018729],
              [8.353547, 50.01857],
              [8.354073, 50.018384],
              [8.354952, 50.017977],
              [8.35624, 50.017295],
              [8.356422, 50.017329],
              [8.356605, 50.017481],
              [8.357946, 50.019329],
              [8.358418, 50.019887],
              [8.359179, 50.020866],
              [8.360499, 50.022293],
              [8.361508, 50.023616],
              [8.362452, 50.024636],
              [8.363568, 50.025808],
              [8.364619, 50.027056],
              [8.364909, 50.027352],
              [8.365316, 50.027766],
              [8.366153, 50.028296],
              [8.368084, 50.02942],
              [8.369758, 50.030419],
              [8.370874, 50.031039],
              [8.371915, 50.031611],
              [8.373556, 50.032383],
              [8.374372, 50.032638],
              [8.375562, 50.033072],
              [8.376442, 50.033389],
              [8.377558, 50.033899],
              [8.378298, 50.034299],
              [8.379403, 50.03483],
              [8.380197, 50.03536],
              [8.380927, 50.035822],
              [8.381238, 50.036284],
              [8.381613, 50.037042],
              [8.381839, 50.037497],
              [8.382128, 50.037924],
              [8.382654, 50.038365],
              [8.383276, 50.038785],
              [8.383856, 50.039082],
              [8.384382, 50.039357],
              [8.385004, 50.039819],
              [8.385336, 50.040122],
              [8.385959, 50.040584],
              [8.38657, 50.041197],
              [8.387364, 50.041838],
              [8.387482, 50.042093],
              [8.387257, 50.042196],
              [8.386774, 50.042492],
              [8.386087, 50.04294],
              [8.38509, 50.043739],
              [8.384274, 50.044236],
              [8.383126, 50.045269],
              [8.381302, 50.046971],
              [8.379489, 50.048431],
              [8.377547, 50.04983],
              [8.375595, 50.051159],
              [8.375133, 50.051614],
              [8.373256, 50.052957],
              [8.371153, 50.054452],
              [8.370595, 50.0549],
              [8.370337, 50.055327],
              [8.370177, 50.055727],
              [8.364737, 50.055734],
              [8.358182, 50.055727],
              [8.35021, 50.055823],
              [8.342453, 50.055761],
              [8.340683, 50.055789],
              [8.340232, 50.055858],
              [8.340275, 50.056092],
              [8.340511, 50.056395],
              [8.340822, 50.056739],
              [8.341101, 50.057077],
              [8.340318, 50.057063],
              [8.334364, 50.057277],
              [8.334428, 50.058227],
              [8.334911, 50.059047],
              [8.335791, 50.060287],
              [8.336102, 50.060424],
              [8.336295, 50.060707],
              [8.336595, 50.061361],
              [8.336788, 50.061864],
              [8.336821, 50.062208],
              [8.336853, 50.062539],
              [8.336853, 50.062828],
              [8.336885, 50.062945],
              [8.337057, 50.06311],
              [8.338301, 50.06267],
              [8.338763, 50.06247],
              [8.33931, 50.062167],
              [8.340576, 50.06163],
              [8.340726, 50.061409],
              [8.340737, 50.061148],
              [8.340737, 50.060583],
              [8.340822, 50.060514],
              [8.342432, 50.060259],
              [8.342292, 50.059866],
              [8.342303, 50.059646],
              [8.342443, 50.05957],
              [8.3427, 50.059515],
              [8.342893, 50.05966],
              [8.345404, 50.06134],
              [8.346101, 50.061802],
              [8.346605, 50.062277],
              [8.347743, 50.063351],
              [8.348494, 50.064116],
              [8.348365, 50.064267],
              [8.346316, 50.06444],
              [8.345693, 50.064922],
              [8.345339, 50.06528],
              [8.344138, 50.065755],
              [8.343194, 50.066031],
              [8.3424, 50.066168],
              [8.34005, 50.066471],
              [8.34049, 50.067463],
              [8.334809, 50.068954],
              [8.333784, 50.067625],
            ],
            [
              [8.345414, 50.012179],
              [8.349159, 50.012752],
              [8.349577, 50.012634],
              [8.35345, 50.011814],
              [8.353354, 50.01118],
            ],
            [
              [8.333983, 50.06758],
              [8.336268, 50.066947],
              [8.338993, 50.066199],
              [8.339589, 50.066041],
              [8.339755, 50.066006],
              [8.340039, 50.06644],
            ],
            [
              [8.369501, 50.054053],
              [8.370863, 50.054101],
              [8.371314, 50.054266],
            ],
          ],
        },
        id: "cxODE",
      },
    ],
  },
];
