import { BusCategory, Line, PlanVersion } from "@/types";

export const v2_metroBusse: Line[] = [
  {
    category: BusCategory.METRO,
    id: "g2Mzc",
    name: "M7 Mainz Kastel <> Klarenthal",

    planVersion: PlanVersion.V2,
    lineName: "M7",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.20825, 50.08436],
              [8.20872, 50.08415],
              [8.21007, 50.08364],
              [8.21173, 50.083],
              [8.21466, 50.08234],
              [8.21553, 50.08232],
              [8.21592, 50.08205],
              [8.21659, 50.08186],
              [8.21771, 50.08167],
              [8.21822, 50.08156],
              [8.21862, 50.08145],
              [8.21878, 50.08139],
              [8.21894, 50.08134],
              [8.21922, 50.08124],
              [8.22054, 50.08072],
              [8.22219, 50.07995],
              [8.22389, 50.07917],
              [8.22585, 50.07828],
              [8.22656, 50.07796],
              [8.22682, 50.07786],
              [8.22799, 50.07726],
              [8.22819, 50.07719],
              [8.22837, 50.07718],
              [8.22894, 50.07703],
              [8.22941, 50.07699],
              [8.22989, 50.07706],
              [8.229975, 50.076791],
              [8.23011, 50.07636],
              [8.2305, 50.07565],
              [8.23053, 50.0756],
              [8.23096, 50.075],
              [8.23143, 50.07437],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.24824, 50.07126],
              [8.248298, 50.071034],
              [8.24837, 50.07075],
              [8.24865, 50.06979],
              [8.2488, 50.06933],
              [8.2489, 50.06901],
              [8.24894, 50.0689],
              [8.24926, 50.06801],
              [8.24992, 50.06671],
              [8.250343, 50.066051],
              [8.25139, 50.06442],
              [8.25186, 50.06367],
              [8.2523, 50.06297],
              [8.252632, 50.062431],
              [8.25288, 50.06203],
              [8.25335, 50.06116],
              [8.25456, 50.05882],
              [8.2548, 50.05837],
              [8.25486, 50.05825],
              [8.25511, 50.05775],
              [8.25618, 50.05599],
              [8.25634, 50.05577],
              [8.25655, 50.0555],
              [8.257, 50.05488],
              [8.25718, 50.05436],
              [8.25718, 50.05428],
              [8.25712, 50.05381],
              [8.25679, 50.05318],
              [8.25661, 50.05267],
              [8.2565, 50.05243],
              [8.25638, 50.05194],
              [8.25635, 50.05129],
              [8.25633, 50.05048],
              [8.25631, 50.0497],
              [8.25625, 50.04888],
              [8.25614, 50.04865],
              [8.25593, 50.0479],
              [8.25564, 50.04664],
              [8.2554, 50.0456],
              [8.25539, 50.04509],
              [8.25543, 50.04495],
              [8.25538, 50.04489],
              [8.25507, 50.04476],
              [8.25438, 50.04474],
              [8.25404, 50.04474],
              [8.25294, 50.0446],
              [8.2524, 50.04435],
              [8.25189, 50.04392],
              [8.25165, 50.04373],
              [8.25133, 50.04354],
              [8.25248, 50.0427],
              [8.25476, 50.04112],
              [8.25531, 50.04075],
              [8.25593, 50.04032],
              [8.25657, 50.03983],
              [8.2572, 50.03896],
              [8.25775, 50.03816],
              [8.2582, 50.0375],
              [8.25886, 50.03653],
              [8.25916, 50.03609],
              [8.25961, 50.03542],
              [8.26022, 50.03453],
              [8.26067, 50.03387],
              [8.26101, 50.03338],
              [8.26239, 50.03135],
              [8.26247, 50.03124],
              [8.26353, 50.0297],
              [8.26444, 50.02836],
              [8.26456, 50.02819],
              [8.26467, 50.02804],
              [8.26525, 50.02731],
              [8.26533, 50.02721],
              [8.26548, 50.02702],
              [8.26591, 50.02651],
              [8.26611, 50.02627],
              [8.26643, 50.02593],
              [8.26691, 50.02538],
              [8.268, 50.02409],
              [8.26897, 50.02296],
              [8.26906, 50.02285],
              [8.2697, 50.02218],
              [8.27006, 50.02183],
              [8.270869, 50.021054],
              [8.27156, 50.02039],
              [8.27218, 50.0198],
              [8.2751, 50.01701],
              [8.2762, 50.01592],
              [8.27713, 50.01491],
              [8.27799, 50.01395],
              [8.27815, 50.01377],
              [8.27918, 50.01261],
              [8.27929, 50.01231],
              [8.279218, 50.011832],
              [8.27917, 50.01151],
              [8.27917, 50.01113],
              [8.27926, 50.0106],
              [8.28001, 50.00913],
              [8.279979, 50.008973],
              [8.280033, 50.008732],
              [8.280087, 50.008566],
              [8.280344, 50.008366],
              [8.280762, 50.008298],
              [8.28108, 50.00839],
              [8.28146, 50.00835],
              [8.28176, 50.00807],
              [8.28208, 50.00778],
              [8.28285, 50.00734],
              [8.28339, 50.00707],
              [8.283716, 50.006878],
            ],
          ],
        },
        id: "g2Mzc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.283874, 50.006967],
              [8.283466, 50.007194],
              [8.282892, 50.007484],
              [8.282157, 50.007925],
              [8.281889, 50.008167],
              [8.281599, 50.008415],
              [8.281438, 50.008546],
              [8.28133, 50.00867],
              [8.28126, 50.00877],
              [8.28097, 50.00894],
              [8.2807, 50.00896],
              [8.2806, 50.00894],
              [8.28024, 50.00897],
              [8.28001, 50.00913],
              [8.27926, 50.0106],
              [8.27917, 50.01113],
              [8.27917, 50.01151],
              [8.279218, 50.011832],
              [8.27929, 50.01231],
              [8.27918, 50.01261],
              [8.27815, 50.01377],
              [8.27799, 50.01395],
              [8.27713, 50.01491],
              [8.2762, 50.01592],
              [8.2751, 50.01701],
              [8.27218, 50.0198],
              [8.27156, 50.02039],
              [8.270869, 50.021054],
              [8.27006, 50.02183],
              [8.2697, 50.02218],
              [8.26906, 50.02285],
              [8.26897, 50.02296],
              [8.268, 50.02409],
              [8.26691, 50.02538],
              [8.26643, 50.02593],
              [8.26611, 50.02627],
              [8.26591, 50.02651],
              [8.26548, 50.02702],
              [8.26533, 50.02721],
              [8.26525, 50.02731],
              [8.26467, 50.02804],
              [8.26456, 50.02819],
              [8.26444, 50.02836],
              [8.26353, 50.0297],
              [8.26247, 50.03124],
              [8.26239, 50.03135],
              [8.26101, 50.03338],
              [8.26067, 50.03387],
              [8.26022, 50.03453],
              [8.25961, 50.03542],
              [8.25916, 50.03609],
              [8.25886, 50.03653],
              [8.2582, 50.0375],
              [8.25775, 50.03816],
              [8.2572, 50.03896],
              [8.25657, 50.03983],
              [8.25593, 50.04032],
              [8.25531, 50.04075],
              [8.25476, 50.04112],
              [8.25248, 50.0427],
              [8.25133, 50.04354],
              [8.25165, 50.04373],
              [8.25189, 50.04392],
              [8.2524, 50.04435],
              [8.25294, 50.0446],
              [8.25404, 50.04474],
              [8.25438, 50.04474],
              [8.25507, 50.04476],
              [8.25537, 50.04475],
              [8.25543, 50.04466],
              [8.25579, 50.04464],
              [8.25585, 50.04466],
              [8.25592, 50.04474],
              [8.25594, 50.04484],
              [8.2559, 50.04492],
              [8.25589, 50.04521],
              [8.25635, 50.04768],
              [8.2563, 50.04801],
              [8.25653, 50.04925],
              [8.2566, 50.04981],
              [8.25667, 50.05047],
              [8.25669, 50.05118],
              [8.25675, 50.05193],
              [8.25689, 50.05246],
              [8.25696, 50.05258],
              [8.25716, 50.05306],
              [8.2574, 50.05368],
              [8.25738, 50.0541],
              [8.25735, 50.05435],
              [8.25713, 50.05489],
              [8.25671, 50.05551],
              [8.25656, 50.05571],
              [8.25635, 50.05596],
              [8.25549, 50.05732],
              [8.25525, 50.05778],
              [8.25516, 50.05795],
              [8.25483, 50.0586],
              [8.25472, 50.05883],
              [8.2535, 50.06118],
              [8.25303, 50.06205],
              [8.252744, 50.062491],
              [8.25242, 50.06299],
              [8.25196, 50.0637],
              [8.25151, 50.064419],
              [8.250414, 50.066171],
              [8.25007, 50.06672],
              [8.24938, 50.06803],
              [8.24908, 50.06892],
              [8.24904, 50.06904],
              [8.24895, 50.06939],
              [8.24893, 50.06955],
              [8.24889, 50.06981],
              [8.24881, 50.07026],
              [8.248624, 50.07106],
              [8.24857, 50.07129],
              [8.24838, 50.07181],
              [8.24803, 50.07194],
              [8.24745, 50.07192],
              [8.2459, 50.07184],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23112, 50.07506],
              [8.23077, 50.07557],
              [8.23074, 50.07561],
              [8.23036, 50.07637],
              [8.23019, 50.07716],
              [8.23015, 50.07735],
              [8.22997, 50.07829],
              [8.22974, 50.07827],
              [8.2287, 50.07815],
              [8.22777, 50.07806],
              [8.22711, 50.078],
              [8.2267, 50.07797],
              [8.22608, 50.07824],
              [8.22394, 50.07924],
              [8.22227, 50.08002],
              [8.22111, 50.08055],
              [8.2206, 50.08078],
              [8.21973, 50.08116],
              [8.21934, 50.08132],
              [8.21901, 50.08143],
              [8.21884, 50.08148],
              [8.2183, 50.08162],
              [8.21663, 50.08197],
              [8.21592, 50.08205],
              [8.21553, 50.08232],
              [8.21466, 50.08234],
              [8.21173, 50.083],
              [8.21007, 50.08364],
              [8.20872, 50.08415],
              [8.20825, 50.08436],
            ],
          ],
        },
        id: "Y1Mjg",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "c2MTk",
    name: "M6 Nordfriedhof <> Mainz",
    lineName: "M6",
    planVersion: PlanVersion.V2,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.22988, 49.99649],
              [8.23051, 49.99655],
              [8.23067, 49.99643],
              [8.23081, 49.99632],
              [8.23099, 49.99619],
              [8.23131, 49.99595],
              [8.23191, 49.99551],
              [8.23224, 49.99542],
              [8.2328, 49.99555],
              [8.23336, 49.99566],
              [8.2337, 49.99574],
              [8.23499, 49.99603],
              [8.23536, 49.99608],
              [8.23632, 49.99634],
              [8.23645, 49.99637],
              [8.23777, 49.99664],
              [8.23755, 49.99716],
              [8.23747, 49.9973],
              [8.23766, 49.99784],
              [8.237748, 49.997962],
              [8.23807, 49.99841],
              [8.23971, 49.9979],
              [8.24014, 49.99777],
              [8.24026, 49.99773],
              [8.24096, 49.99753],
              [8.24106, 49.9975],
              [8.24116, 49.99747],
              [8.24135, 49.99741],
              [8.24287, 49.99695],
              [8.24327, 49.99683],
              [8.24335, 49.99681],
              [8.24398, 49.99671],
              [8.24491, 49.99672],
              [8.24679, 49.99705],
              [8.24715, 49.99712],
              [8.24912, 49.9974],
              [8.249185, 49.997403],
              [8.25089, 49.99748],
              [8.25186, 49.99756],
              [8.25286, 49.99758],
              [8.25465, 49.99756],
              [8.25585, 49.99764],
              [8.25597, 49.9977],
              [8.2563, 49.99799],
              [8.25633, 49.99802],
              [8.25718, 49.99895],
              [8.25746, 49.99916],
              [8.25791, 49.99939],
              [8.25828, 49.99963],
              [8.25846, 49.99967],
              [8.25889, 49.99971],
              [8.25909, 49.99976],
              [8.25994, 50.00007],
              [8.26052, 50.00034],
              [8.25989, 50.00102],
              [8.25996, 50.00114],
              [8.26012, 50.00141],
              [8.26124, 50.00131],
              [8.2614, 50.00124],
              [8.26256, 50.00077],
              [8.26337, 50.00046],
              [8.26386, 50.00023],
              [8.26419, 50.0004],
              [8.26475, 50.00086],
              [8.26557, 50.00153],
              [8.26614, 50.00198],
              [8.26643, 50.00221],
              [8.26686, 50.00254],
              [8.2677, 50.00322],
              [8.26871, 50.00412],
              [8.2693, 50.00465],
              [8.26941, 50.00474],
              [8.27007, 50.00533],
              [8.27079, 50.00586],
              [8.27135, 50.00613],
              [8.27167, 50.0061],
              [8.27224, 50.005452],
              [8.27225, 50.00544],
              [8.27306, 50.00463],
              [8.27384, 50.00376],
              [8.27404, 50.00348],
              [8.27426, 50.00332],
              [8.27455, 50.00338],
              [8.27447, 50.00364],
              [8.27365, 50.00446],
              [8.27324, 50.00494],
              [8.27336, 50.00517],
              [8.27367, 50.00531],
              [8.27391, 50.00541],
              [8.27591, 50.00622],
              [8.27639, 50.00642],
              [8.27988, 50.00785],
              [8.2809, 50.00833],
              [8.28108, 50.00839],
              [8.28128, 50.00853],
              [8.28133, 50.00867],
              [8.28126, 50.00877],
              [8.28097, 50.00894],
              [8.2807, 50.00896],
              [8.2806, 50.00894],
              [8.28024, 50.00897],
              [8.28001, 50.00913],
              [8.27926, 50.0106],
              [8.27917, 50.01113],
              [8.27917, 50.01151],
              [8.279218, 50.011832],
              [8.27929, 50.01231],
              [8.27918, 50.01261],
              [8.27815, 50.01377],
              [8.27799, 50.01395],
              [8.27713, 50.01491],
              [8.2762, 50.01592],
              [8.2751, 50.01701],
              [8.27218, 50.0198],
              [8.27156, 50.02039],
              [8.270869, 50.021054],
              [8.27006, 50.02183],
              [8.2697, 50.02218],
              [8.26906, 50.02285],
              [8.26897, 50.02296],
              [8.268, 50.02409],
              [8.26691, 50.02538],
              [8.26643, 50.02593],
              [8.26611, 50.02627],
              [8.26591, 50.02651],
              [8.26548, 50.02702],
              [8.26533, 50.02721],
              [8.26525, 50.02731],
              [8.26467, 50.02804],
              [8.26456, 50.02819],
              [8.26444, 50.02836],
              [8.26353, 50.0297],
              [8.26247, 50.03124],
              [8.26239, 50.03135],
              [8.26101, 50.03338],
              [8.26067, 50.03387],
              [8.26022, 50.03453],
              [8.25961, 50.03542],
              [8.25916, 50.03609],
              [8.25886, 50.03653],
              [8.2582, 50.0375],
              [8.25775, 50.03816],
              [8.2572, 50.03896],
              [8.25657, 50.03983],
              [8.25603, 50.04037],
              [8.25551, 50.04074],
              [8.25151, 50.0435],
              [8.25133, 50.04354],
              [8.25165, 50.04373],
              [8.25189, 50.04392],
              [8.2524, 50.04435],
              [8.25294, 50.0446],
              [8.25404, 50.04474],
              [8.25438, 50.04474],
              [8.25507, 50.04476],
              [8.25537, 50.04475],
              [8.25543, 50.04466],
              [8.25579, 50.04464],
              [8.25585, 50.04466],
              [8.25592, 50.04474],
              [8.25594, 50.04484],
              [8.2559, 50.04492],
              [8.25589, 50.04521],
              [8.25635, 50.04768],
              [8.2563, 50.04801],
              [8.25653, 50.04925],
              [8.2566, 50.04981],
              [8.25667, 50.05047],
              [8.25669, 50.05118],
              [8.25675, 50.05193],
              [8.25689, 50.05246],
              [8.25696, 50.05258],
              [8.25716, 50.05306],
              [8.2574, 50.05368],
              [8.25738, 50.0541],
              [8.25735, 50.05435],
              [8.25713, 50.05489],
              [8.25671, 50.05551],
              [8.25656, 50.05571],
              [8.25635, 50.05596],
              [8.25549, 50.05732],
              [8.25525, 50.05778],
              [8.25516, 50.05795],
              [8.25483, 50.0586],
              [8.25472, 50.05883],
              [8.2535, 50.06118],
              [8.25303, 50.06205],
              [8.252744, 50.062491],
              [8.25242, 50.06299],
              [8.25196, 50.0637],
              [8.25151, 50.064419],
              [8.250414, 50.066171],
              [8.25007, 50.06672],
              [8.24938, 50.06803],
              [8.24908, 50.06892],
              [8.24904, 50.06904],
              [8.24895, 50.06939],
              [8.24893, 50.06955],
              [8.24889, 50.06981],
              [8.24881, 50.07026],
              [8.248624, 50.07106],
              [8.24857, 50.07129],
              [8.24838, 50.07181],
              [8.24803, 50.07194],
              [8.24745, 50.07192],
              [8.2459, 50.07184],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.236676, 50.077684],
              [8.237032, 50.077704],
              [8.23674, 50.07786],
              [8.23658, 50.07894],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.2363, 50.08098],
              [8.23627, 50.08116],
              [8.23625, 50.08138],
              [8.23623, 50.08182],
              [8.23617, 50.08226],
              [8.23615, 50.08241],
              [8.23611, 50.08276],
              [8.23607, 50.08306],
              [8.23606, 50.08328],
              [8.23603, 50.08364],
              [8.23593, 50.08379],
              [8.23583, 50.08524],
              [8.23583, 50.08531],
              [8.23568, 50.08665],
              [8.235231, 50.086733],
              [8.23432, 50.0869],
              [8.23232, 50.0873],
              [8.23169, 50.08729],
              [8.23096, 50.08782],
              [8.23082, 50.08792],
              [8.2305, 50.08815],
              [8.23004, 50.08851],
              [8.22935, 50.08924],
              [8.22887, 50.08983],
              [8.22843, 50.09032],
              [8.22811, 50.09071],
              [8.227802, 50.091053],
              [8.22697, 50.09198],
              [8.22632, 50.09232],
              [8.22537, 50.09277],
              [8.22482, 50.09304],
              [8.22421, 50.09335],
              [8.22324, 50.09368],
              [8.22308, 50.09377],
              [8.22261, 50.094135],
              [8.22196, 50.09464],
              [8.22164, 50.09517],
              [8.22112, 50.09604],
              [8.221, 50.0962],
              [8.22114, 50.09634],
              [8.22116, 50.09698],
            ],
          ],
        },
        id: "c2MTk",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.22116, 50.09698],
              [8.22089, 50.09707],
              [8.22025, 50.09698],
              [8.221, 50.0962],
              [8.22112, 50.09604],
              [8.22164, 50.09517],
              [8.22196, 50.09464],
              [8.22261, 50.094135],
              [8.22308, 50.09377],
              [8.22324, 50.09368],
              [8.22421, 50.09335],
              [8.22482, 50.09304],
              [8.22537, 50.09277],
              [8.22632, 50.09232],
              [8.22697, 50.09198],
              [8.227802, 50.091053],
              [8.22811, 50.09071],
              [8.22843, 50.09032],
              [8.22887, 50.08983],
              [8.22935, 50.08924],
              [8.23004, 50.08851],
              [8.2305, 50.08815],
              [8.23082, 50.08792],
              [8.23096, 50.08782],
              [8.23169, 50.08729],
              [8.23232, 50.0873],
              [8.23432, 50.0869],
              [8.235231, 50.086733],
              [8.23568, 50.08665],
              [8.23583, 50.08531],
              [8.23583, 50.08524],
              [8.23593, 50.08379],
              [8.23582, 50.08338],
              [8.23581, 50.08327],
              [8.23583, 50.08285],
              [8.23588, 50.08241],
              [8.2359, 50.08223],
              [8.23598, 50.08168],
              [8.23605, 50.0813],
              [8.23609, 50.08107],
              [8.23612, 50.08088],
              [8.23622, 50.08024],
              [8.23623, 50.08016],
              [8.23639, 50.07893],
              [8.23644, 50.07835],
              [8.236676, 50.077684],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.24824, 50.07126],
              [8.248298, 50.071034],
              [8.24837, 50.07075],
              [8.24865, 50.06979],
              [8.2488, 50.06933],
              [8.2489, 50.06901],
              [8.24894, 50.0689],
              [8.24926, 50.06801],
              [8.24992, 50.06671],
              [8.250343, 50.066051],
              [8.25139, 50.06442],
              [8.25186, 50.06367],
              [8.2523, 50.06297],
              [8.252632, 50.062431],
              [8.25288, 50.06203],
              [8.25335, 50.06116],
              [8.25456, 50.05882],
              [8.2548, 50.05837],
              [8.25486, 50.05825],
              [8.25511, 50.05775],
              [8.25618, 50.05599],
              [8.25634, 50.05577],
              [8.25655, 50.0555],
              [8.257, 50.05488],
              [8.25718, 50.05436],
              [8.25718, 50.05428],
              [8.25712, 50.05381],
              [8.25679, 50.05318],
              [8.25661, 50.05267],
              [8.2565, 50.05243],
              [8.25638, 50.05194],
              [8.25635, 50.05129],
              [8.25633, 50.05048],
              [8.25631, 50.0497],
              [8.25625, 50.04888],
              [8.25614, 50.04865],
              [8.25593, 50.0479],
              [8.25564, 50.04664],
              [8.2554, 50.0456],
              [8.25539, 50.04509],
              [8.25543, 50.04495],
              [8.25538, 50.04489],
              [8.25507, 50.04476],
              [8.25438, 50.04474],
              [8.25404, 50.04474],
              [8.25294, 50.0446],
              [8.2524, 50.04435],
              [8.25189, 50.04392],
              [8.25165, 50.04373],
              [8.25133, 50.04354],
              [8.25248, 50.0427],
              [8.25476, 50.04112],
              [8.25531, 50.04075],
              [8.25593, 50.04032],
              [8.25657, 50.03983],
              [8.2572, 50.03896],
              [8.25775, 50.03816],
              [8.2582, 50.0375],
              [8.25886, 50.03653],
              [8.25916, 50.03609],
              [8.25961, 50.03542],
              [8.26022, 50.03453],
              [8.26067, 50.03387],
              [8.26101, 50.03338],
              [8.26239, 50.03135],
              [8.26247, 50.03124],
              [8.26353, 50.0297],
              [8.26444, 50.02836],
              [8.26456, 50.02819],
              [8.26467, 50.02804],
              [8.26525, 50.02731],
              [8.26533, 50.02721],
              [8.26548, 50.02702],
              [8.26591, 50.02651],
              [8.26611, 50.02627],
              [8.26643, 50.02593],
              [8.26691, 50.02538],
              [8.268, 50.02409],
              [8.26897, 50.02296],
              [8.26906, 50.02285],
              [8.2697, 50.02218],
              [8.27006, 50.02183],
              [8.270869, 50.021054],
              [8.27156, 50.02039],
              [8.27218, 50.0198],
              [8.2751, 50.01701],
              [8.2762, 50.01592],
              [8.27713, 50.01491],
              [8.27799, 50.01395],
              [8.27815, 50.01377],
              [8.27918, 50.01261],
              [8.27929, 50.01231],
              [8.279218, 50.011832],
              [8.27917, 50.01151],
              [8.27917, 50.01113],
              [8.27926, 50.0106],
              [8.28001, 50.00913],
              [8.28014, 50.00869],
              [8.28011, 50.00849],
              [8.28009, 50.00821],
              [8.27981, 50.00787],
              [8.2797, 50.00783],
              [8.27634, 50.00647],
              [8.27589, 50.00628],
              [8.27483, 50.00585],
              [8.27388, 50.00546],
              [8.2733, 50.00523],
              [8.27292, 50.00526],
              [8.27284, 50.00532],
              [8.27241, 50.0058],
              [8.27202, 50.00622],
              [8.2719, 50.00636],
              [8.27152, 50.00639],
              [8.27135, 50.00634],
              [8.271064, 50.006095],
              [8.27079, 50.00586],
              [8.27007, 50.00533],
              [8.26941, 50.00474],
              [8.2693, 50.00465],
              [8.26871, 50.00412],
              [8.2677, 50.00322],
              [8.26686, 50.00254],
              [8.26643, 50.00221],
              [8.26614, 50.00198],
              [8.26557, 50.00153],
              [8.26475, 50.00086],
              [8.26419, 50.0004],
              [8.26386, 50.00023],
              [8.26337, 50.00046],
              [8.26256, 50.00077],
              [8.2614, 50.00124],
              [8.26124, 50.00131],
              [8.26012, 50.00141],
              [8.25996, 50.00114],
              [8.25989, 50.00102],
              [8.26052, 50.00034],
              [8.25994, 50.00007],
              [8.25909, 49.99976],
              [8.25889, 49.99971],
              [8.25846, 49.99967],
              [8.25828, 49.99963],
              [8.25791, 49.99939],
              [8.25746, 49.99916],
              [8.25718, 49.99895],
              [8.25633, 49.99802],
              [8.2563, 49.99799],
              [8.25597, 49.9977],
              [8.25585, 49.99764],
              [8.25465, 49.99756],
              [8.25286, 49.99758],
              [8.25186, 49.99756],
              [8.25089, 49.99748],
              [8.249185, 49.997403],
              [8.24912, 49.9974],
              [8.24715, 49.99712],
              [8.24679, 49.99705],
              [8.24491, 49.99672],
              [8.24398, 49.99671],
              [8.24335, 49.99681],
              [8.24327, 49.99683],
              [8.24287, 49.99695],
              [8.24135, 49.99741],
              [8.24116, 49.99747],
              [8.24106, 49.9975],
              [8.24096, 49.99753],
              [8.24026, 49.99773],
              [8.24014, 49.99777],
              [8.23971, 49.9979],
              [8.23807, 49.99841],
              [8.237748, 49.997962],
              [8.23766, 49.99784],
              [8.23747, 49.9973],
              [8.23755, 49.99716],
              [8.23777, 49.99664],
              [8.23645, 49.99637],
              [8.23632, 49.99634],
              [8.23536, 49.99608],
              [8.23499, 49.99603],
              [8.2337, 49.99574],
              [8.23336, 49.99566],
              [8.2328, 49.99555],
              [8.23224, 49.99542],
              [8.23191, 49.99551],
              [8.23131, 49.99595],
              [8.23099, 49.99619],
              [8.23081, 49.99632],
              [8.23067, 49.99643],
              [8.23051, 49.99655],
              [8.22988, 49.99649],
            ],
          ],
        },
        id: "g0NzI",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "QzMzY",
    name: "M5 Südfriedhof <> Dotzheim",
    lineName: "M5",
    planVersion: PlanVersion.V2,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.190875, 50.066287],
              [8.18999, 50.06623],
              [8.18976, 50.06749],
              [8.19142, 50.06766],
              [8.19292, 50.06784],
              [8.19365, 50.06797],
              [8.19452, 50.06836],
              [8.1947, 50.06853],
              [8.19503, 50.06914],
              [8.19502, 50.06942],
              [8.19473, 50.0706],
              [8.19477, 50.07111],
              [8.19514, 50.07175],
              [8.19536, 50.07195],
              [8.19464, 50.07244],
              [8.19437, 50.0728],
              [8.19424, 50.07306],
              [8.1948, 50.07357],
              [8.19513, 50.07384],
              [8.19536, 50.0741],
              [8.19566, 50.07447],
              [8.19569, 50.07454],
              [8.1959, 50.0748],
              [8.19638, 50.07498],
              [8.19694, 50.07502],
              [8.19774, 50.07509],
              [8.19979, 50.07535],
              [8.20016, 50.07537],
              [8.2022, 50.07564],
              [8.2025, 50.07569],
              [8.20294, 50.07573],
              [8.20326, 50.07574],
              [8.2037, 50.07579],
              [8.204227, 50.075836],
              [8.20769, 50.07623],
              [8.20857, 50.07628],
              [8.20963, 50.07636],
              [8.21431, 50.07681],
              [8.21606, 50.07697],
              [8.216627, 50.077025],
              [8.22051, 50.07737],
              [8.22071, 50.07739],
              [8.22216, 50.07755],
              [8.2223, 50.07756],
              [8.22386, 50.0777],
              [8.22585, 50.0779],
              [8.22656, 50.07796],
              [8.22777, 50.07806],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23658, 50.07894],
              [8.23639, 50.07893],
              [8.23644, 50.07835],
              [8.236676, 50.077684],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.25112, 50.07194],
              [8.25181, 50.07198],
              [8.25311, 50.07204],
              [8.25403, 50.07209],
              [8.25487, 50.07212],
              [8.25494, 50.07212],
              [8.25533, 50.07217],
              [8.25677, 50.07227],
              [8.25699, 50.07233],
              [8.25732, 50.0721],
              [8.25749, 50.07199],
              [8.25808, 50.07153],
              [8.26015, 50.06999],
              [8.26098, 50.06939],
              [8.26144, 50.06909],
              [8.2622, 50.06868],
              [8.26225, 50.06859],
              [8.263269, 50.068021],
              [8.26354, 50.06787],
              [8.26346, 50.06777],
              [8.26371, 50.06734],
              [8.26389, 50.06726],
              [8.26454, 50.06618],
              [8.26531, 50.06492],
              [8.265773, 50.064132],
              [8.26618, 50.06344],
              [8.2674, 50.06139],
              [8.26787, 50.06119],
              [8.26803, 50.06114],
              [8.2682, 50.06099],
              [8.26826, 50.06088],
              [8.26816, 50.06085],
              [8.26812, 50.0607],
              [8.26786, 50.06063],
            ],
          ],
        },
        id: "QzMzY",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.26786, 50.06063],
              [8.26812, 50.0607],
              [8.26816, 50.06085],
              [8.26826, 50.06088],
              [8.2682, 50.06099],
              [8.26803, 50.06114],
              [8.26787, 50.06119],
              [8.2674, 50.06139],
              [8.26618, 50.06344],
              [8.265773, 50.064132],
              [8.26531, 50.06492],
              [8.26454, 50.06618],
              [8.26389, 50.06726],
              [8.26371, 50.06734],
              [8.26346, 50.06777],
              [8.26354, 50.06787],
              [8.263861, 50.068304],
              [8.263, 50.06857],
              [8.26225, 50.06878],
              [8.26137, 50.06928],
              [8.26031, 50.06999],
              [8.2593, 50.07078],
              [8.25805, 50.07172],
              [8.25767, 50.07199],
              [8.25727, 50.07224],
              [8.25699, 50.07233],
              [8.25671, 50.07253],
              [8.25641, 50.07248],
              [8.25518, 50.07238],
              [8.2547, 50.07235],
              [8.2531, 50.07223],
              [8.25181, 50.07215],
              [8.25111, 50.07212],
              [8.25036, 50.07207],
              [8.24916, 50.07199],
              [8.24887, 50.07198],
              [8.24834, 50.07196],
              [8.24819, 50.07195],
              [8.24803, 50.07194],
              [8.24745, 50.07192],
              [8.2459, 50.07184],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.236676, 50.077684],
              [8.23644, 50.07835],
              [8.23639, 50.07893],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.2287, 50.07815],
              [8.22777, 50.07806],
              [8.22656, 50.07796],
              [8.22585, 50.0779],
              [8.22386, 50.0777],
              [8.2223, 50.07756],
              [8.22216, 50.07755],
              [8.22071, 50.07739],
              [8.216627, 50.077025],
              [8.21606, 50.07697],
              [8.21431, 50.07681],
              [8.20963, 50.07636],
              [8.20857, 50.07628],
              [8.20769, 50.07623],
              [8.204227, 50.075836],
              [8.2037, 50.07579],
              [8.20326, 50.07574],
              [8.20294, 50.07573],
              [8.2025, 50.07569],
              [8.2022, 50.07564],
              [8.20016, 50.07537],
              [8.19979, 50.07535],
              [8.19774, 50.07509],
              [8.19791, 50.07448],
              [8.19587, 50.07324],
              [8.19791, 50.07448],
              [8.19587, 50.07324],
              [8.19562, 50.07308],
              [8.19476, 50.07251],
              [8.19464, 50.07244],
              [8.19536, 50.07195],
              [8.19514, 50.07175],
              [8.19477, 50.07111],
              [8.19473, 50.0706],
              [8.19502, 50.06942],
              [8.19503, 50.06914],
              [8.1947, 50.06853],
              [8.19452, 50.06836],
              [8.19365, 50.06797],
              [8.19292, 50.06784],
              [8.19142, 50.06766],
              [8.19169, 50.06634],
              [8.190875, 50.066287],
            ],
          ],
        },
        id: "Y3NzY",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "Q4NjA",
    name: "M4_Biebrich <> Klarenthal",
    lineName: "M4",
    planVersion: PlanVersion.V2,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.19933, 50.09207],
              [8.19895, 50.09005],
              [8.19728, 50.09015],
              [8.1965, 50.0902],
              [8.19574, 50.09026],
              [8.19544, 50.09037],
              [8.19523, 50.09061],
              [8.19522, 50.09086],
              [8.19537, 50.09102],
              [8.19596, 50.09126],
              [8.19682, 50.09162],
              [8.19716, 50.09173],
              [8.19796, 50.09199],
              [8.19933, 50.09207],
              [8.19939, 50.09263],
              [8.19945, 50.09309],
              [8.19938, 50.09323],
              [8.20013, 50.09327],
              [8.20045, 50.09327],
              [8.2015, 50.09322],
              [8.20192, 50.09311],
              [8.2027, 50.09236],
              [8.20295, 50.09207],
              [8.2032, 50.09176],
              [8.20377, 50.09111],
              [8.20433, 50.09045],
              [8.20454, 50.09012],
              [8.204764, 50.089613],
              [8.20527, 50.0877],
              [8.20539, 50.08711],
              [8.20548, 50.08669],
              [8.20568, 50.08625],
              [8.20617, 50.0857],
              [8.2062, 50.08551],
              [8.20605, 50.08541],
              [8.20807, 50.08447],
              [8.20814, 50.08448],
              [8.20825, 50.08436],
              [8.20872, 50.08415],
              [8.21007, 50.08364],
              [8.21173, 50.083],
              [8.21466, 50.08234],
              [8.21553, 50.08232],
              [8.21592, 50.08205],
              [8.21659, 50.08186],
              [8.21771, 50.08167],
              [8.21822, 50.08156],
              [8.21862, 50.08145],
              [8.21878, 50.08139],
              [8.21894, 50.08134],
              [8.22219, 50.07995],
              [8.22389, 50.07917],
              [8.22394, 50.07924],
              [8.22422, 50.07969],
              [8.22433, 50.07999],
              [8.22461, 50.08059],
              [8.224955, 50.080535],
              [8.22604, 50.08036],
              [8.22687, 50.08041],
              [8.22757, 50.08043],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22997, 50.07829],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23658, 50.07894],
              [8.23741, 50.07898],
              [8.23815, 50.07899],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.24239, 50.07799],
              [8.24243, 50.07777],
              [8.24236, 50.07769],
              [8.242431, 50.07695],
              [8.2425, 50.07623],
              [8.24263, 50.07583],
              [8.24271, 50.07536],
              [8.24279, 50.07458],
              [8.24281, 50.0744],
              [8.24288, 50.0739],
              [8.24298, 50.07321],
              [8.24313, 50.07218],
              [8.24307, 50.07209],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.2405, 50.07147],
              [8.24038, 50.07135],
              [8.24031, 50.07117],
              [8.23998, 50.0702],
              [8.23998, 50.06971],
              [8.24003, 50.06949],
              [8.2402, 50.06926],
              [8.2405, 50.06799],
              [8.24075, 50.06702],
              [8.24136, 50.06466],
              [8.24164, 50.06356],
              [8.24176, 50.06298],
              [8.24194, 50.06229],
              [8.24212, 50.06155],
              [8.24221, 50.06109],
              [8.24232, 50.0607],
              [8.2424, 50.06045],
              [8.24289, 50.05855],
              [8.24305, 50.05794],
              [8.24313, 50.05762],
              [8.24316, 50.05749],
              [8.24333, 50.05684],
              [8.24338, 50.05663],
              [8.24342, 50.05647],
              [8.24364, 50.05555],
              [8.24365, 50.05536],
              [8.24365, 50.0552],
              [8.24361, 50.05504],
              [8.24355, 50.05487],
              [8.24344, 50.05459],
              [8.2434, 50.05448],
              [8.24324, 50.05407],
              [8.24308, 50.0538],
              [8.24298, 50.05359],
              [8.24293, 50.05349],
              [8.24267, 50.05281],
              [8.24235, 50.05199],
              [8.24224, 50.05171],
              [8.2422, 50.05161],
              [8.24204, 50.05124],
              [8.24183, 50.050709],
              [8.24164, 50.05023],
              [8.24127, 50.04932],
              [8.24115, 50.04901],
              [8.24104, 50.04875],
              [8.24097, 50.04827],
              [8.24095, 50.04785],
              [8.24096, 50.04769],
              [8.24103, 50.04725],
              [8.24105, 50.04685],
              [8.24108, 50.04583],
              [8.24111, 50.04482],
              [8.24084, 50.04427],
              [8.24075, 50.04406],
              [8.24066, 50.04387],
              [8.24026, 50.04295],
              [8.23988, 50.04208],
              [8.23971, 50.04173],
              [8.23963, 50.04157],
              [8.23925, 50.04075],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23706, 50.03668],
              [8.23684, 50.03639],
              [8.23645, 50.03653],
              [8.23523, 50.03691],
              [8.23501, 50.03695],
              [8.23292, 50.0373],
              [8.23146, 50.03754],
              [8.23124, 50.03758],
              [8.23113, 50.0376],
              [8.23087, 50.03766],
              [8.2282, 50.03868],
              [8.2263, 50.03916],
              [8.22679, 50.03993],
              [8.22769, 50.04186],
              [8.22856, 50.04299],
              [8.2291, 50.04363],
              [8.22949, 50.0441],
              [8.23049, 50.04529],
              [8.230907, 50.045816],
              [8.23091, 50.04582],
              [8.23103, 50.04612],
              [8.23105, 50.04625],
              [8.23121, 50.04728],
              [8.23026, 50.0471],
              [8.22902, 50.04688],
              [8.22746, 50.04661],
              [8.22621, 50.04638],
              [8.22592, 50.04633],
              [8.22574, 50.0463],
              [8.22506, 50.04618],
              [8.22458, 50.04609],
              [8.22413, 50.04601],
              [8.22381, 50.04595],
              [8.22319, 50.04584],
              [8.22238, 50.0457],
              [8.22199, 50.04563],
              [8.22056, 50.04537],
              [8.220429, 50.046125],
              [8.220218, 50.04734],
              [8.214267, 50.046989],
              [8.214384, 50.045994],
            ],
          ],
        },
        id: "Q4NjA",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.214384, 50.045994],
              [8.214267, 50.046989],
              [8.220218, 50.04734],
              [8.220429, 50.046125],
              [8.22056, 50.04537],
              [8.22199, 50.04563],
              [8.22238, 50.0457],
              [8.22319, 50.04584],
              [8.22381, 50.04595],
              [8.22413, 50.04601],
              [8.22458, 50.04609],
              [8.22506, 50.04618],
              [8.22574, 50.0463],
              [8.22592, 50.04633],
              [8.22621, 50.04638],
              [8.22746, 50.04661],
              [8.22902, 50.04688],
              [8.23026, 50.0471],
              [8.23121, 50.04728],
              [8.23105, 50.04625],
              [8.23103, 50.04612],
              [8.23091, 50.04582],
              [8.230907, 50.045816],
              [8.23049, 50.04529],
              [8.22949, 50.0441],
              [8.2291, 50.04363],
              [8.22856, 50.04299],
              [8.22769, 50.04186],
              [8.22679, 50.03993],
              [8.2263, 50.03916],
              [8.2282, 50.03868],
              [8.23087, 50.03766],
              [8.23113, 50.0376],
              [8.23124, 50.03758],
              [8.23146, 50.03754],
              [8.23292, 50.0373],
              [8.23501, 50.03695],
              [8.23523, 50.03691],
              [8.23645, 50.03653],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23862, 50.03686],
              [8.23911, 50.03682],
              [8.23916, 50.0369],
              [8.23984, 50.03824],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.24111, 50.04086],
              [8.24119, 50.04111],
              [8.24123, 50.04126],
              [8.24122, 50.04133],
              [8.24118, 50.04279],
              [8.24116, 50.04376],
              [8.24115, 50.04427],
              [8.24111, 50.04482],
              [8.24108, 50.04583],
              [8.24105, 50.04685],
              [8.24103, 50.04725],
              [8.24108, 50.04768],
              [8.24109, 50.04783],
              [8.2411, 50.04823],
              [8.24126, 50.04884],
              [8.24131, 50.04897],
              [8.24146, 50.04929],
              [8.24179, 50.05015],
              [8.241973, 50.050675],
              [8.24216, 50.05121],
              [8.24235, 50.05159],
              [8.24238, 50.05169],
              [8.24248, 50.05195],
              [8.24251, 50.05202],
              [8.24268, 50.05244],
              [8.24284, 50.05285],
              [8.24297, 50.05316],
              [8.243, 50.05324],
              [8.2432, 50.05378],
              [8.24324, 50.05407],
              [8.2434, 50.05448],
              [8.24344, 50.05459],
              [8.24355, 50.05487],
              [8.24361, 50.05504],
              [8.24365, 50.0552],
              [8.24365, 50.05536],
              [8.24364, 50.05555],
              [8.24342, 50.05647],
              [8.24338, 50.05663],
              [8.24333, 50.05684],
              [8.24316, 50.05749],
              [8.24313, 50.05762],
              [8.24305, 50.05794],
              [8.24289, 50.05855],
              [8.2424, 50.06045],
              [8.24232, 50.0607],
              [8.24221, 50.06109],
              [8.24212, 50.06155],
              [8.24194, 50.06229],
              [8.24176, 50.06298],
              [8.24164, 50.06356],
              [8.24136, 50.06466],
              [8.24075, 50.06702],
              [8.2405, 50.06799],
              [8.2402, 50.06926],
              [8.24024, 50.06949],
              [8.24024, 50.06986],
              [8.24031, 50.07077],
              [8.24048, 50.07119],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.24328, 50.0717],
              [8.24313, 50.07218],
              [8.24298, 50.07321],
              [8.24288, 50.0739],
              [8.24281, 50.0744],
              [8.24279, 50.07458],
              [8.24271, 50.07536],
              [8.24263, 50.07583],
              [8.24265, 50.07623],
              [8.242575, 50.076958],
              [8.2425, 50.07769],
              [8.24243, 50.07777],
              [8.24239, 50.07799],
              [8.24221, 50.07922],
              [8.2421, 50.07999],
              [8.24206, 50.08049],
              [8.24188, 50.08048],
              [8.23954, 50.08035],
              [8.23934, 50.08034],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22963, 50.08055],
              [8.22949, 50.08052],
              [8.22757, 50.08043],
              [8.22687, 50.08041],
              [8.22604, 50.08036],
              [8.224955, 50.080535],
              [8.22461, 50.08059],
              [8.22433, 50.07999],
              [8.22422, 50.07969],
              [8.22394, 50.07924],
              [8.22227, 50.08002],
              [8.21884, 50.08148],
              [8.2183, 50.08162],
              [8.21663, 50.08197],
              [8.21592, 50.08205],
              [8.21553, 50.08232],
              [8.21466, 50.08234],
              [8.21173, 50.083],
              [8.21007, 50.08364],
              [8.20872, 50.08415],
              [8.20825, 50.08436],
              [8.20814, 50.08448],
              [8.20807, 50.08447],
              [8.20605, 50.08541],
              [8.2062, 50.08551],
              [8.20617, 50.0857],
              [8.20568, 50.08625],
              [8.20548, 50.08669],
              [8.20539, 50.08711],
              [8.20527, 50.0877],
              [8.204764, 50.089613],
              [8.20454, 50.09012],
              [8.20433, 50.09045],
              [8.20377, 50.09111],
              [8.2032, 50.09176],
              [8.20295, 50.09207],
              [8.2027, 50.09236],
              [8.20192, 50.09311],
              [8.2015, 50.09322],
              [8.20045, 50.09327],
              [8.20013, 50.09327],
              [8.19938, 50.09323],
              [8.19945, 50.09309],
              [8.19939, 50.09263],
              [8.19933, 50.09207],
              [8.19796, 50.09199],
              [8.19716, 50.09173],
              [8.19682, 50.09162],
              [8.19596, 50.09126],
              [8.19537, 50.09102],
              [8.19522, 50.09086],
              [8.19523, 50.09061],
              [8.19544, 50.09037],
              [8.19574, 50.09026],
              [8.1965, 50.0902],
              [8.19728, 50.09015],
              [8.19895, 50.09005],
              [8.19933, 50.09207],
            ],
          ],
        },
        id: "cyMDk",
        direction: "INBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.19933, 50.09207],
              [8.19895, 50.09005],
              [8.19728, 50.09015],
              [8.1965, 50.0902],
              [8.19574, 50.09026],
              [8.19544, 50.09037],
              [8.19523, 50.09061],
              [8.19522, 50.09086],
              [8.19537, 50.09102],
              [8.19596, 50.09126],
              [8.19682, 50.09162],
              [8.19716, 50.09173],
              [8.19796, 50.09199],
              [8.19933, 50.09207],
              [8.19939, 50.09263],
              [8.19945, 50.09309],
              [8.19938, 50.09323],
              [8.20013, 50.09327],
              [8.20045, 50.09327],
              [8.2015, 50.09322],
              [8.20192, 50.09311],
              [8.2027, 50.09236],
              [8.20295, 50.09207],
              [8.2032, 50.09176],
              [8.20377, 50.09111],
              [8.20433, 50.09045],
              [8.20454, 50.09012],
              [8.204764, 50.089613],
              [8.20527, 50.0877],
              [8.20539, 50.08711],
              [8.20548, 50.08669],
              [8.20568, 50.08625],
              [8.20617, 50.0857],
              [8.2062, 50.08551],
              [8.20605, 50.08541],
              [8.20807, 50.08447],
              [8.20814, 50.08448],
              [8.20825, 50.08436],
              [8.20872, 50.08415],
              [8.21007, 50.08364],
              [8.21173, 50.083],
              [8.21466, 50.08234],
              [8.21553, 50.08232],
              [8.21592, 50.08205],
              [8.21659, 50.08186],
              [8.21771, 50.08167],
              [8.21822, 50.08156],
              [8.21862, 50.08145],
              [8.21878, 50.08139],
              [8.21894, 50.08134],
              [8.22219, 50.07995],
              [8.22389, 50.07917],
              [8.22394, 50.07924],
              [8.22422, 50.07969],
              [8.22433, 50.07999],
              [8.22461, 50.08059],
              [8.224955, 50.080535],
              [8.22604, 50.08036],
              [8.22687, 50.08041],
              [8.22757, 50.08043],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22997, 50.07829],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23658, 50.07894],
              [8.23741, 50.07898],
              [8.23815, 50.07899],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.24239, 50.07799],
              [8.24243, 50.07777],
              [8.24236, 50.07769],
              [8.242431, 50.07695],
              [8.2425, 50.07623],
              [8.24263, 50.07583],
              [8.24271, 50.07536],
              [8.24279, 50.07458],
              [8.24281, 50.0744],
              [8.24288, 50.0739],
              [8.24298, 50.07321],
              [8.24313, 50.07218],
              [8.24307, 50.07209],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.2405, 50.07147],
              [8.24038, 50.07135],
              [8.24031, 50.07117],
              [8.23998, 50.0702],
              [8.23998, 50.06971],
              [8.24003, 50.06949],
              [8.2402, 50.06926],
              [8.2405, 50.06799],
              [8.24075, 50.06702],
              [8.24136, 50.06466],
              [8.24164, 50.06356],
              [8.24176, 50.06298],
              [8.24194, 50.06229],
              [8.24212, 50.06155],
              [8.24221, 50.06109],
              [8.24232, 50.0607],
              [8.2424, 50.06045],
              [8.24289, 50.05855],
              [8.24305, 50.05794],
              [8.24313, 50.05762],
              [8.24316, 50.05749],
              [8.24333, 50.05684],
              [8.24338, 50.05663],
              [8.24342, 50.05647],
              [8.24364, 50.05555],
              [8.24365, 50.05536],
              [8.24365, 50.0552],
              [8.24361, 50.05504],
              [8.24355, 50.05487],
              [8.24344, 50.05459],
              [8.2434, 50.05448],
              [8.24324, 50.05407],
              [8.24308, 50.0538],
              [8.24298, 50.05359],
              [8.24293, 50.05349],
              [8.24267, 50.05281],
              [8.24235, 50.05199],
              [8.24224, 50.05171],
              [8.2422, 50.05161],
              [8.24204, 50.05124],
              [8.24183, 50.050709],
              [8.24164, 50.05023],
              [8.24127, 50.04932],
              [8.24115, 50.04901],
              [8.24104, 50.04875],
              [8.24097, 50.04827],
              [8.24095, 50.04785],
              [8.24096, 50.04769],
              [8.24103, 50.04725],
              [8.24105, 50.04685],
              [8.24108, 50.04583],
              [8.24111, 50.04482],
              [8.24084, 50.04427],
              [8.24075, 50.04406],
              [8.24066, 50.04387],
              [8.24026, 50.04295],
              [8.23988, 50.04208],
              [8.23971, 50.04173],
              [8.23963, 50.04157],
              [8.23925, 50.04075],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23706, 50.03668],
              [8.23684, 50.03639],
              [8.23645, 50.03653],
              [8.23523, 50.03691],
              [8.23501, 50.03695],
              [8.23292, 50.0373],
              [8.23146, 50.03754],
              [8.23124, 50.03758],
              [8.23113, 50.0376],
              [8.23087, 50.03766],
              [8.2282, 50.03868],
              [8.2263, 50.03916],
              [8.22679, 50.03993],
              [8.22769, 50.04186],
              [8.22856, 50.04299],
              [8.2291, 50.04363],
              [8.22949, 50.0441],
              [8.23049, 50.04529],
              [8.230907, 50.045816],
              [8.23091, 50.04582],
              [8.23103, 50.04612],
              [8.23105, 50.04625],
              [8.23121, 50.04728],
              [8.23026, 50.0471],
              [8.22902, 50.04688],
              [8.22746, 50.04661],
              [8.22621, 50.04638],
              [8.22592, 50.04633],
              [8.22574, 50.0463],
              [8.22506, 50.04618],
              [8.22458, 50.04609],
              [8.22413, 50.04601],
              [8.22381, 50.04595],
              [8.22319, 50.04584],
              [8.22238, 50.0457],
              [8.22199, 50.04563],
              [8.22056, 50.04537],
              [8.220429, 50.046125],
              [8.220218, 50.04734],
              [8.214267, 50.046989],
              [8.214384, 50.045994],
            ],
          ],
        },
        id: "cxOTI",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.214384, 50.045994],
              [8.214267, 50.046989],
              [8.220218, 50.04734],
              [8.220429, 50.046125],
              [8.22056, 50.04537],
              [8.22199, 50.04563],
              [8.22238, 50.0457],
              [8.22319, 50.04584],
              [8.22381, 50.04595],
              [8.22413, 50.04601],
              [8.22458, 50.04609],
              [8.22506, 50.04618],
              [8.22574, 50.0463],
              [8.22592, 50.04633],
              [8.22621, 50.04638],
              [8.22746, 50.04661],
              [8.22902, 50.04688],
              [8.23026, 50.0471],
              [8.23121, 50.04728],
              [8.23105, 50.04625],
              [8.23103, 50.04612],
              [8.23091, 50.04582],
              [8.230907, 50.045816],
              [8.23049, 50.04529],
              [8.22949, 50.0441],
              [8.2291, 50.04363],
              [8.22856, 50.04299],
              [8.22769, 50.04186],
              [8.22679, 50.03993],
              [8.2263, 50.03916],
              [8.2282, 50.03868],
              [8.23087, 50.03766],
              [8.23113, 50.0376],
              [8.23124, 50.03758],
              [8.23146, 50.03754],
              [8.23292, 50.0373],
              [8.23501, 50.03695],
              [8.23523, 50.03691],
              [8.23645, 50.03653],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23862, 50.03686],
              [8.23911, 50.03682],
              [8.23916, 50.0369],
              [8.23984, 50.03824],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.24111, 50.04086],
              [8.24119, 50.04111],
              [8.24123, 50.04126],
              [8.24122, 50.04133],
              [8.24118, 50.04279],
              [8.24116, 50.04376],
              [8.24115, 50.04427],
              [8.24111, 50.04482],
              [8.24108, 50.04583],
              [8.24105, 50.04685],
              [8.24103, 50.04725],
              [8.24108, 50.04768],
              [8.24109, 50.04783],
              [8.2411, 50.04823],
              [8.24126, 50.04884],
              [8.24131, 50.04897],
              [8.24146, 50.04929],
              [8.24179, 50.05015],
              [8.241973, 50.050675],
              [8.24216, 50.05121],
              [8.24235, 50.05159],
              [8.24238, 50.05169],
              [8.24248, 50.05195],
              [8.24251, 50.05202],
              [8.24268, 50.05244],
              [8.24284, 50.05285],
              [8.24297, 50.05316],
              [8.243, 50.05324],
              [8.2432, 50.05378],
              [8.24324, 50.05407],
              [8.2434, 50.05448],
              [8.24344, 50.05459],
              [8.24355, 50.05487],
              [8.24361, 50.05504],
              [8.24365, 50.0552],
              [8.24365, 50.05536],
              [8.24364, 50.05555],
              [8.24342, 50.05647],
              [8.24338, 50.05663],
              [8.24333, 50.05684],
              [8.24316, 50.05749],
              [8.24313, 50.05762],
              [8.24305, 50.05794],
              [8.24289, 50.05855],
              [8.2424, 50.06045],
              [8.24232, 50.0607],
              [8.24221, 50.06109],
              [8.24212, 50.06155],
              [8.24194, 50.06229],
              [8.24176, 50.06298],
              [8.24164, 50.06356],
              [8.24136, 50.06466],
              [8.24075, 50.06702],
              [8.2405, 50.06799],
              [8.2402, 50.06926],
              [8.24024, 50.06949],
              [8.24024, 50.06986],
              [8.24031, 50.07077],
              [8.24048, 50.07119],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.24328, 50.0717],
              [8.24313, 50.07218],
              [8.24298, 50.07321],
              [8.24288, 50.0739],
              [8.24281, 50.0744],
              [8.24279, 50.07458],
              [8.24271, 50.07536],
              [8.24263, 50.07583],
              [8.24265, 50.07623],
              [8.242575, 50.076958],
              [8.2425, 50.07769],
              [8.24243, 50.07777],
              [8.24239, 50.07799],
              [8.24221, 50.07922],
              [8.2421, 50.07999],
              [8.24206, 50.08049],
              [8.24188, 50.08048],
              [8.23954, 50.08035],
              [8.23934, 50.08034],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22963, 50.08055],
              [8.22949, 50.08052],
              [8.22757, 50.08043],
              [8.22687, 50.08041],
              [8.22604, 50.08036],
              [8.224955, 50.080535],
              [8.22461, 50.08059],
              [8.22433, 50.07999],
              [8.22422, 50.07969],
              [8.22394, 50.07924],
              [8.22227, 50.08002],
              [8.21884, 50.08148],
              [8.2183, 50.08162],
              [8.21663, 50.08197],
              [8.21592, 50.08205],
              [8.21553, 50.08232],
              [8.21466, 50.08234],
              [8.21173, 50.083],
              [8.21007, 50.08364],
              [8.20872, 50.08415],
              [8.20825, 50.08436],
              [8.20814, 50.08448],
              [8.20807, 50.08447],
              [8.20605, 50.08541],
              [8.2062, 50.08551],
              [8.20617, 50.0857],
              [8.20568, 50.08625],
              [8.20548, 50.08669],
              [8.20539, 50.08711],
              [8.20527, 50.0877],
              [8.204764, 50.089613],
              [8.20454, 50.09012],
              [8.20433, 50.09045],
              [8.20377, 50.09111],
              [8.2032, 50.09176],
              [8.20295, 50.09207],
              [8.2027, 50.09236],
              [8.20192, 50.09311],
              [8.2015, 50.09322],
              [8.20045, 50.09327],
              [8.20013, 50.09327],
              [8.19938, 50.09323],
              [8.19945, 50.09309],
              [8.19939, 50.09263],
              [8.19933, 50.09207],
              [8.19796, 50.09199],
              [8.19716, 50.09173],
              [8.19682, 50.09162],
              [8.19596, 50.09126],
              [8.19537, 50.09102],
              [8.19522, 50.09086],
              [8.19523, 50.09061],
              [8.19544, 50.09037],
              [8.19574, 50.09026],
              [8.1965, 50.0902],
              [8.19728, 50.09015],
              [8.19895, 50.09005],
              [8.19933, 50.09207],
            ],
          ],
        },
        id: "UxMTQ",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "k1MTI",
    name: "M3 Biebrich <> Dotzheim",
    lineName: "M3",
    planVersion: PlanVersion.V2,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.18242, 50.07472],
              [8.18216, 50.07452],
              [8.1815, 50.0736],
              [8.18241, 50.07305],
              [8.18251, 50.07285],
              [8.18266, 50.07276],
              [8.18304, 50.07209],
              [8.18329, 50.07167],
              [8.18352, 50.07127],
              [8.1837, 50.07103],
              [8.18568, 50.06989],
              [8.18637, 50.06953],
              [8.187116, 50.069103],
              [8.19027, 50.06862],
              [8.19174, 50.06858],
              [8.19238, 50.0683],
              [8.192492, 50.068204],
              [8.19292, 50.06784],
              [8.19365, 50.06797],
              [8.19452, 50.06836],
              [8.1947, 50.06853],
              [8.19503, 50.06914],
              [8.19502, 50.06942],
              [8.19473, 50.0706],
              [8.19515, 50.0716],
              [8.19541, 50.07186],
              [8.1955, 50.07192],
              [8.19464, 50.07244],
              [8.19437, 50.0728],
              [8.19424, 50.07306],
              [8.1948, 50.07357],
              [8.19513, 50.07384],
              [8.19536, 50.0741],
              [8.19566, 50.07447],
              [8.19569, 50.07454],
              [8.1959, 50.0748],
              [8.19638, 50.07498],
              [8.19694, 50.07502],
              [8.19774, 50.07509],
              [8.19979, 50.07535],
              [8.20016, 50.07537],
              [8.2022, 50.07564],
              [8.2025, 50.07569],
              [8.20294, 50.07573],
              [8.20326, 50.07574],
              [8.2037, 50.07579],
              [8.204227, 50.075836],
              [8.20769, 50.07623],
              [8.20857, 50.07628],
              [8.20963, 50.07636],
              [8.21431, 50.07681],
              [8.21606, 50.07697],
              [8.216627, 50.077025],
              [8.22071, 50.07739],
              [8.22216, 50.07755],
              [8.2223, 50.07756],
              [8.22386, 50.0777],
              [8.22585, 50.0779],
              [8.22656, 50.07796],
              [8.22777, 50.07806],
              [8.2287, 50.07815],
              [8.22974, 50.07827],
              [8.22997, 50.07829],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.24239, 50.07799],
              [8.24243, 50.07777],
              [8.2425, 50.07769],
              [8.242575, 50.076958],
              [8.24265, 50.07623],
              [8.24263, 50.07583],
              [8.24271, 50.07536],
              [8.24279, 50.07458],
              [8.24281, 50.0744],
              [8.24288, 50.0739],
              [8.24298, 50.07321],
              [8.24313, 50.07218],
              [8.24307, 50.07209],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.2405, 50.07147],
              [8.24038, 50.07135],
              [8.24031, 50.07117],
              [8.23998, 50.0702],
              [8.23998, 50.06971],
              [8.24003, 50.06949],
              [8.2402, 50.06926],
              [8.2405, 50.06799],
              [8.24075, 50.06702],
              [8.24136, 50.06466],
              [8.24164, 50.06356],
              [8.24176, 50.06298],
              [8.24194, 50.06229],
              [8.24212, 50.06155],
              [8.24221, 50.06109],
              [8.24232, 50.0607],
              [8.2424, 50.06045],
              [8.24289, 50.05855],
              [8.24305, 50.05794],
              [8.24313, 50.05762],
              [8.24316, 50.05749],
              [8.24333, 50.05684],
              [8.24338, 50.05663],
              [8.24342, 50.05647],
              [8.24364, 50.05555],
              [8.24365, 50.05536],
              [8.24365, 50.0552],
              [8.24361, 50.05504],
              [8.24355, 50.05487],
              [8.24344, 50.05459],
              [8.2434, 50.05448],
              [8.24324, 50.05407],
              [8.24308, 50.0538],
              [8.24298, 50.05359],
              [8.24293, 50.05349],
              [8.24267, 50.05281],
              [8.24235, 50.05199],
              [8.24224, 50.05171],
              [8.2422, 50.05161],
              [8.24204, 50.05124],
              [8.24183, 50.050709],
              [8.24164, 50.05023],
              [8.24127, 50.04932],
              [8.24115, 50.04901],
              [8.24104, 50.04875],
              [8.24097, 50.04827],
              [8.24095, 50.04785],
              [8.24096, 50.04769],
              [8.24103, 50.04725],
              [8.24111, 50.04482],
              [8.24084, 50.04427],
              [8.24075, 50.04406],
              [8.24066, 50.04387],
              [8.24026, 50.04295],
              [8.23988, 50.04208],
              [8.23971, 50.04173],
              [8.23963, 50.04157],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
            ],
          ],
        },
        id: "k1MTI",
        direction: "INBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23862, 50.03686],
              [8.23911, 50.03682],
              [8.23916, 50.0369],
              [8.23984, 50.03824],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.24111, 50.04086],
              [8.24119, 50.04111],
              [8.24123, 50.04126],
              [8.24122, 50.04133],
              [8.24118, 50.04279],
              [8.24116, 50.04376],
              [8.24115, 50.04427],
              [8.24111, 50.04482],
              [8.24103, 50.04725],
              [8.24108, 50.04768],
              [8.24109, 50.04783],
              [8.2411, 50.04823],
              [8.24126, 50.04884],
              [8.24131, 50.04897],
              [8.24146, 50.04929],
              [8.24179, 50.05015],
              [8.241973, 50.050675],
              [8.24216, 50.05121],
              [8.24235, 50.05159],
              [8.24238, 50.05169],
              [8.24248, 50.05195],
              [8.24251, 50.05202],
              [8.24268, 50.05244],
              [8.24284, 50.05285],
              [8.24297, 50.05316],
              [8.24293, 50.05349],
              [8.24298, 50.05359],
              [8.24308, 50.0538],
              [8.24324, 50.05407],
              [8.2434, 50.05448],
              [8.24344, 50.05459],
              [8.24355, 50.05487],
              [8.24361, 50.05504],
              [8.24365, 50.0552],
              [8.24365, 50.05536],
              [8.24364, 50.05555],
              [8.24342, 50.05647],
              [8.24338, 50.05663],
              [8.24333, 50.05684],
              [8.24316, 50.05749],
              [8.24313, 50.05762],
              [8.24305, 50.05794],
              [8.24289, 50.05855],
              [8.2424, 50.06045],
              [8.24232, 50.0607],
              [8.24221, 50.06109],
              [8.24212, 50.06155],
              [8.24194, 50.06229],
              [8.24176, 50.06298],
              [8.24164, 50.06356],
              [8.24136, 50.06466],
              [8.24075, 50.06702],
              [8.2405, 50.06799],
              [8.2402, 50.06926],
              [8.24024, 50.06949],
              [8.24024, 50.06986],
              [8.24031, 50.07077],
              [8.24048, 50.07119],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.24328, 50.0717],
              [8.24313, 50.07218],
              [8.24298, 50.07321],
              [8.24288, 50.0739],
              [8.24281, 50.0744],
              [8.24279, 50.07458],
              [8.24271, 50.07536],
              [8.24263, 50.07583],
              [8.24265, 50.07623],
              [8.242575, 50.076958],
              [8.2425, 50.07769],
              [8.24243, 50.07777],
              [8.24239, 50.07799],
              [8.24221, 50.07922],
              [8.2421, 50.07999],
              [8.24206, 50.08049],
              [8.24188, 50.08048],
              [8.23954, 50.08035],
              [8.23934, 50.08034],
              [8.23623, 50.08016],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.2287, 50.07815],
              [8.22777, 50.07806],
              [8.22656, 50.07796],
              [8.22585, 50.0779],
              [8.22386, 50.0777],
              [8.2223, 50.07756],
              [8.22216, 50.07755],
              [8.22071, 50.07739],
              [8.216627, 50.077025],
              [8.21606, 50.07697],
              [8.21431, 50.07681],
              [8.20963, 50.07636],
              [8.20857, 50.07628],
              [8.20769, 50.07623],
              [8.204227, 50.075836],
              [8.2037, 50.07579],
              [8.20326, 50.07574],
              [8.20294, 50.07573],
              [8.2025, 50.07569],
              [8.2022, 50.07564],
              [8.20016, 50.07537],
              [8.2022, 50.07564],
              [8.20016, 50.07537],
              [8.19979, 50.07535],
              [8.19774, 50.07509],
              [8.19791, 50.07448],
              [8.19587, 50.07324],
              [8.19562, 50.07308],
              [8.19476, 50.07251],
              [8.19464, 50.07244],
              [8.1955, 50.07192],
              [8.19541, 50.07186],
              [8.19515, 50.0716],
              [8.19473, 50.0706],
              [8.19502, 50.06942],
              [8.19503, 50.06914],
              [8.1947, 50.06853],
              [8.19452, 50.06836],
              [8.19365, 50.06797],
              [8.19292, 50.06784],
              [8.192492, 50.068204],
              [8.19238, 50.0683],
              [8.19174, 50.06858],
              [8.19027, 50.06862],
              [8.187116, 50.069103],
              [8.18637, 50.06953],
              [8.18568, 50.06989],
              [8.1837, 50.07103],
              [8.18352, 50.07127],
              [8.18329, 50.07167],
              [8.18304, 50.07209],
              [8.18266, 50.07276],
              [8.18251, 50.07285],
              [8.18241, 50.07305],
              [8.1815, 50.0736],
              [8.18216, 50.07452],
              [8.18242, 50.07472],
            ],
          ],
        },
        id: "cwOTU",
        direction: "OUTBOUND",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "I5MzA",
    name: "M2 Wallau <> Klarenthal",
    lineName: "M2",
    planVersion: PlanVersion.V2,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.19933, 50.09207],
              [8.19796, 50.09199],
              [8.19716, 50.09173],
              [8.19682, 50.09162],
              [8.19596, 50.09126],
              [8.19537, 50.09102],
              [8.19522, 50.09086],
              [8.19523, 50.09061],
              [8.19544, 50.09037],
              [8.19574, 50.09026],
              [8.1965, 50.0902],
              [8.19728, 50.09015],
              [8.19895, 50.09005],
              [8.19866, 50.08924],
              [8.19867, 50.0885],
              [8.199, 50.08722],
              [8.19923, 50.0872],
              [8.20028, 50.08703],
              [8.2006, 50.08696],
              [8.20021, 50.08617],
              [8.20021, 50.08588],
              [8.201042, 50.084996],
              [8.20131, 50.08471],
              [8.2019, 50.08406],
              [8.20279, 50.0831],
              [8.203234, 50.082608],
              [8.204255, 50.081558],
              [8.20471, 50.08041],
              [8.20542, 50.08052],
              [8.20588, 50.07895],
              [8.20633, 50.07744],
              [8.207, 50.0768],
              [8.20769, 50.07623],
              [8.20857, 50.07628],
              [8.20963, 50.07636],
              [8.21431, 50.07681],
              [8.21606, 50.07697],
              [8.216627, 50.077025],
              [8.22071, 50.07739],
              [8.22216, 50.07755],
              [8.2223, 50.07756],
              [8.22386, 50.0777],
              [8.22585, 50.0779],
              [8.22656, 50.07796],
              [8.22777, 50.07806],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.244535, 50.079334],
              [8.24507, 50.07936],
              [8.24587, 50.07943],
              [8.24632, 50.07958],
              [8.24687, 50.07991],
              [8.24755, 50.07937],
              [8.24768, 50.07928],
              [8.248549, 50.07863],
              [8.24899, 50.0783],
              [8.24915, 50.07819],
              [8.24944, 50.07797],
              [8.24968, 50.07779],
              [8.25015, 50.07744],
              [8.25152, 50.07643],
              [8.25176, 50.07625],
              [8.25278, 50.07548],
              [8.25318, 50.0752],
              [8.25374, 50.07475],
              [8.2541, 50.07439],
              [8.25505, 50.07369],
              [8.25569, 50.07325],
              [8.25606, 50.07299],
              [8.25671, 50.07253],
              [8.25699, 50.07233],
              [8.25732, 50.0721],
              [8.25749, 50.07199],
              [8.25808, 50.07153],
              [8.26015, 50.06999],
              [8.26098, 50.06939],
              [8.26144, 50.06909],
              [8.2622, 50.06868],
              [8.26225, 50.06859],
              [8.263269, 50.068021],
              [8.26354, 50.06787],
              [8.26346, 50.06777],
              [8.26371, 50.06734],
              [8.26389, 50.06726],
              [8.26393, 50.06768],
              [8.26439, 50.06814],
              [8.2684, 50.0667],
              [8.26938, 50.06653],
              [8.2711, 50.06648],
              [8.27289, 50.06612],
              [8.27424, 50.06578],
              [8.27548, 50.06552],
              [8.27541, 50.06543],
              [8.27528, 50.06527],
              [8.27472, 50.06458],
              [8.27379, 50.06344],
              [8.27276, 50.06382],
              [8.27253, 50.06417],
              [8.27264, 50.06436],
              [8.27298, 50.06446],
              [8.27346, 50.06446],
              [8.27435, 50.06412],
              [8.27631, 50.06341],
              [8.27966, 50.06219],
              [8.28254, 50.0609],
              [8.28263, 50.06098],
              [8.28312, 50.06079],
              [8.28398, 50.06059],
              [8.28557, 50.06002],
              [8.28631, 50.05975],
              [8.2865, 50.05968],
              [8.28739, 50.05935],
              [8.288623, 50.058902],
              [8.29112, 50.057992],
              [8.29192, 50.0577],
              [8.29482, 50.05664],
              [8.29528, 50.05647],
              [8.29648, 50.05603],
              [8.29903, 50.05507],
              [8.30044, 50.05423],
              [8.30079, 50.05403],
              [8.30106, 50.05387],
              [8.30126, 50.05388],
              [8.30134, 50.05374],
              [8.30228, 50.05341],
              [8.30262, 50.05332],
              [8.30433, 50.05313],
              [8.30465, 50.05326],
              [8.3054, 50.05481],
              [8.30534, 50.05529],
              [8.3056, 50.05533],
              [8.30667, 50.0555],
              [8.307, 50.05556],
              [8.30731, 50.05561],
              [8.30745, 50.05564],
              [8.3075, 50.05566],
              [8.307443, 50.056],
              [8.3074, 50.05626],
              [8.30742, 50.05662],
              [8.30756, 50.05684],
              [8.30782, 50.05719],
              [8.30823, 50.05771],
              [8.30832, 50.05782],
              [8.30843, 50.05795],
              [8.30861, 50.05811],
              [8.30931, 50.05866],
              [8.3095, 50.0588],
              [8.31092, 50.0599],
              [8.31197, 50.06048],
              [8.31475, 50.06208],
              [8.31675, 50.0642],
              [8.318013, 50.063769],
              [8.31924, 50.06335],
              [8.32128, 50.06317],
              [8.32313, 50.06285],
              [8.32765, 50.06198],
              [8.3296, 50.06161],
              [8.330026, 50.061527],
              [8.33278, 50.06099],
              [8.33391, 50.06078],
              [8.33583, 50.06043],
              [8.336146, 50.060883],
              [8.33704, 50.06325],
              [8.33785, 50.06301],
              [8.33862, 50.0627],
              [8.33877, 50.06262],
              [8.33895, 50.06254],
              [8.34003, 50.06206],
              [8.3408, 50.06168],
              [8.34088, 50.06156],
              [8.340923, 50.061073],
              [8.34096, 50.06065],
              [8.34221, 50.0604],
              [8.34267, 50.06031],
              [8.34246, 50.05984],
              [8.34264, 50.05962],
              [8.344158, 50.060618],
              [8.34603, 50.06185],
              [8.34615, 50.06195],
              [8.34755, 50.06329],
              [8.34798, 50.0637],
              [8.34845, 50.0642],
              [8.35124, 50.06393],
              [8.35202, 50.06387],
              [8.35261, 50.06379],
              [8.35347, 50.06367],
              [8.35356, 50.06364],
              [8.35395, 50.06366],
              [8.35477, 50.06357],
              [8.35677, 50.06328],
              [8.35746, 50.06318],
              [8.35936, 50.06292],
              [8.36072, 50.06271],
              [8.36368, 50.06229],
              [8.36499, 50.06211],
              [8.36516, 50.06256],
              [8.36534, 50.06312],
              [8.36709, 50.06393],
              [8.367509, 50.064107],
              [8.36832, 50.06445],
              [8.3692, 50.06474],
              [8.36946, 50.06434],
              [8.36979, 50.0637],
              [8.36974, 50.06339],
              [8.36982, 50.06299],
              [8.37005, 50.06267],
              [8.37041, 50.0617],
              [8.37077, 50.06128],
              [8.37092, 50.06106],
              [8.370344, 50.060753],
              [8.37017, 50.06066],
              [8.36896, 50.05985],
              [8.36941, 50.05944],
              [8.36956, 50.05931],
              [8.37014, 50.05892],
              [8.36965, 50.05843],
              [8.36947, 50.05784],
              [8.36956, 50.05737],
              [8.36971, 50.05691],
              [8.36977, 50.05668],
              [8.3698, 50.0566],
              [8.3703, 50.05514],
              [8.37075, 50.054669],
              [8.37106, 50.05444],
              [8.37124, 50.05431],
              [8.37177, 50.05391],
              [8.371776, 50.053906],
              [8.372276, 50.053541],
            ],
          ],
        },
        id: "I5MzA",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.372276, 50.053541],
              [8.371776, 50.053906],
              [8.37177, 50.05391],
              [8.37124, 50.05431],
              [8.37106, 50.05444],
              [8.37075, 50.054669],
              [8.3703, 50.05514],
              [8.3698, 50.0566],
              [8.36977, 50.05668],
              [8.36971, 50.05691],
              [8.36956, 50.05737],
              [8.36947, 50.05784],
              [8.36965, 50.05843],
              [8.37014, 50.05892],
              [8.36956, 50.05931],
              [8.36941, 50.05944],
              [8.36896, 50.05985],
              [8.37017, 50.06066],
              [8.370344, 50.060753],
              [8.37092, 50.06106],
              [8.37077, 50.06128],
              [8.37041, 50.0617],
              [8.37005, 50.06267],
              [8.36982, 50.06299],
              [8.36974, 50.06339],
              [8.36979, 50.0637],
              [8.36946, 50.06434],
              [8.3692, 50.06474],
              [8.36832, 50.06445],
              [8.367509, 50.064107],
              [8.36709, 50.06393],
              [8.36534, 50.06312],
              [8.36516, 50.06256],
              [8.36499, 50.06211],
              [8.36368, 50.06229],
              [8.36072, 50.06271],
              [8.35936, 50.06292],
              [8.35746, 50.06318],
              [8.35677, 50.06328],
              [8.35477, 50.06357],
              [8.35395, 50.06366],
              [8.35356, 50.06364],
              [8.35347, 50.06367],
              [8.35261, 50.06379],
              [8.35202, 50.06387],
              [8.35124, 50.06393],
              [8.34845, 50.0642],
              [8.34798, 50.0637],
              [8.34755, 50.06329],
              [8.34615, 50.06195],
              [8.34603, 50.06185],
              [8.344158, 50.060618],
              [8.34264, 50.05962],
              [8.34246, 50.05984],
              [8.34267, 50.06031],
              [8.34221, 50.0604],
              [8.34096, 50.06065],
              [8.340923, 50.061073],
              [8.34088, 50.06156],
              [8.3408, 50.06168],
              [8.34003, 50.06206],
              [8.33895, 50.06254],
              [8.33877, 50.06262],
              [8.33862, 50.0627],
              [8.33785, 50.06301],
              [8.33704, 50.06325],
              [8.336146, 50.060883],
              [8.33583, 50.06043],
              [8.33391, 50.06078],
              [8.33278, 50.06099],
              [8.330026, 50.061527],
              [8.3296, 50.06161],
              [8.32765, 50.06198],
              [8.32313, 50.06285],
              [8.32128, 50.06317],
              [8.31924, 50.06335],
              [8.318013, 50.063769],
              [8.31675, 50.0642],
              [8.31475, 50.06208],
              [8.31197, 50.06048],
              [8.31092, 50.0599],
              [8.3095, 50.0588],
              [8.30931, 50.05866],
              [8.30861, 50.05811],
              [8.30843, 50.05795],
              [8.30832, 50.05782],
              [8.30823, 50.05771],
              [8.30782, 50.05719],
              [8.30756, 50.05684],
              [8.30742, 50.05662],
              [8.3074, 50.05626],
              [8.307443, 50.056],
              [8.3075, 50.05566],
              [8.30745, 50.05564],
              [8.30731, 50.05561],
              [8.307, 50.05556],
              [8.30667, 50.0555],
              [8.3056, 50.05533],
              [8.30534, 50.05529],
              [8.3054, 50.05481],
              [8.30465, 50.05326],
              [8.30433, 50.05313],
              [8.30262, 50.05332],
              [8.30228, 50.05341],
              [8.30134, 50.05374],
              [8.30126, 50.05388],
              [8.30106, 50.05387],
              [8.30079, 50.05403],
              [8.30044, 50.05423],
              [8.29903, 50.05507],
              [8.29648, 50.05603],
              [8.29528, 50.05647],
              [8.29482, 50.05664],
              [8.29192, 50.0577],
              [8.29112, 50.057992],
              [8.288623, 50.058902],
              [8.28739, 50.05935],
              [8.2865, 50.05968],
              [8.28631, 50.05975],
              [8.28557, 50.06002],
              [8.28398, 50.06059],
              [8.28312, 50.06079],
              [8.28263, 50.06098],
              [8.28017, 50.06211],
              [8.27644, 50.06349],
              [8.27592, 50.06388],
              [8.27584, 50.06414],
              [8.27609, 50.06458],
              [8.27632, 50.065],
              [8.27622, 50.06508],
              [8.2759, 50.06533],
              [8.27573, 50.06537],
              [8.27541, 50.06543],
              [8.27424, 50.06578],
              [8.27289, 50.06612],
              [8.2711, 50.06648],
              [8.26938, 50.06653],
              [8.2684, 50.0667],
              [8.26439, 50.06814],
              [8.263861, 50.068304],
              [8.263, 50.06857],
              [8.26225, 50.06878],
              [8.26137, 50.06928],
              [8.26031, 50.06999],
              [8.2593, 50.07078],
              [8.25805, 50.07172],
              [8.25767, 50.07199],
              [8.25727, 50.07224],
              [8.25699, 50.07233],
              [8.25693, 50.07258],
              [8.25587, 50.07334],
              [8.25514, 50.07374],
              [8.25419, 50.07444],
              [8.25374, 50.07475],
              [8.25318, 50.0752],
              [8.25278, 50.07548],
              [8.25176, 50.07625],
              [8.25152, 50.07643],
              [8.25015, 50.07744],
              [8.24968, 50.07779],
              [8.24944, 50.07797],
              [8.24915, 50.07819],
              [8.24899, 50.0783],
              [8.246827, 50.079919],
              [8.246162, 50.080291],
              [8.245497, 50.080608],
              [8.244874, 50.080649],
              [8.242042, 50.080498],
              [8.236442, 50.080195],
              [8.236291, 50.080856],
              [8.229446, 50.080525],
              [8.229747, 50.078267],
              [8.2267, 50.07797],
              [8.20962, 50.07638],
              [8.208203, 50.081007],
              [8.204727, 50.080401],
              [8.204277, 50.081572],
              [8.202989, 50.082852],
              [8.201144, 50.084918],
              [8.200564, 50.085523],
              [8.2002, 50.085895],
              [8.200243, 50.086212],
              [8.20058, 50.087021],
              [8.199384, 50.087251],
              [8.198998, 50.087217],
              [8.198698, 50.088511],
              [8.198698, 50.089254],
              [8.198934, 50.090053],
              [8.195715, 50.090246],
              [8.195286, 50.090507],
              [8.195221, 50.09081],
              [8.195436, 50.09103],
              [8.196917, 50.091677],
              [8.197968, 50.09198],
              [8.19933, 50.09207],
            ],
          ],
        },
        id: "UyMDI",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.METRO,
    id: "k3MTc",
    name: "M1 Bierstadt <> Schierstein",
    lineName: "M1",
    planVersion: PlanVersion.V2,
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.194674, 50.045614],
              [8.19504, 50.04508],
              [8.19513, 50.04504],
              [8.19537, 50.04497],
              [8.19566, 50.04488],
              [8.19611, 50.04466],
              [8.19626, 50.04461],
              [8.19703, 50.04454],
              [8.19717, 50.04454],
              [8.19766, 50.04455],
              [8.19796, 50.04455],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.20083, 50.04642],
              [8.20113, 50.04675],
              [8.20195, 50.04766],
              [8.20258, 50.04839],
              [8.2029, 50.04876],
              [8.20429, 50.05032],
              [8.20563, 50.05185],
              [8.20608, 50.05235],
              [8.20687, 50.05325],
              [8.20813, 50.05468],
              [8.209087, 50.05575],
              [8.20941, 50.05611],
              [8.21018, 50.05707],
              [8.21121, 50.0584],
              [8.21138, 50.05857],
              [8.21222, 50.05909],
              [8.2129, 50.05924],
              [8.21395, 50.05944],
              [8.21423, 50.05956],
              [8.21479, 50.05999],
              [8.2149, 50.06018],
              [8.21554, 50.06106],
              [8.21658, 50.06188],
              [8.21698, 50.06216],
              [8.21708, 50.06222],
              [8.21733, 50.06243],
              [8.2178, 50.06282],
              [8.21794, 50.06294],
              [8.21998, 50.0649],
              [8.22048, 50.06548],
              [8.221, 50.06593],
              [8.221162, 50.066064],
              [8.22291, 50.06727],
              [8.22369, 50.06782],
              [8.22618, 50.06961],
              [8.22676, 50.07005],
              [8.22741, 50.07061],
              [8.22833, 50.07146],
              [8.22921, 50.07227],
              [8.22984, 50.07283],
              [8.22994, 50.07291],
              [8.2301, 50.07306],
              [8.23071, 50.07357],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.24328, 50.0717],
              [8.24313, 50.07218],
            ],
            [
              [8.242214, 50.07921],
              [8.244535, 50.079334],
              [8.24507, 50.07936],
              [8.24587, 50.07943],
              [8.24632, 50.07958],
              [8.24687, 50.07991],
              [8.24759, 50.08014],
              [8.24787, 50.08022],
              [8.24875, 50.08047],
              [8.2496, 50.08069],
              [8.24982, 50.08075],
              [8.250337, 50.080887],
              [8.25069, 50.08098],
              [8.25159, 50.08121],
              [8.25238, 50.08142],
              [8.25398, 50.08184],
              [8.25421, 50.0819],
              [8.25536, 50.08215],
              [8.25562, 50.08218],
              [8.25594, 50.08222],
              [8.25755, 50.08251],
              [8.25794, 50.08254],
              [8.2585, 50.08264],
              [8.25922, 50.08281],
              [8.26007, 50.08306],
              [8.26159, 50.08346],
              [8.26261, 50.08372],
              [8.26405, 50.08408],
              [8.26533, 50.08438],
              [8.26591, 50.08444],
              [8.26664, 50.08449],
              [8.26798, 50.08459],
              [8.27015, 50.08475],
              [8.27178, 50.08499],
              [8.27217, 50.08504],
              [8.2737, 50.08527],
              [8.275, 50.08543],
              [8.27653, 50.08513],
              [8.27667, 50.08509],
              [8.27724, 50.08485],
              [8.27768, 50.084646],
              [8.27866, 50.08419],
              [8.27913, 50.08398],
              [8.28057, 50.0833],
              [8.28069, 50.08326],
              [8.28078, 50.08323],
              [8.28085, 50.0832],
              [8.28096, 50.08316],
              [8.28119, 50.08307],
              [8.28198, 50.08271],
              [8.28307, 50.08219],
              [8.28327, 50.08212],
              [8.28367, 50.08217],
              [8.28435, 50.08232],
              [8.28453, 50.08235],
              [8.28526, 50.08255],
              [8.28638, 50.08293],
              [8.28671, 50.08218],
              [8.28761, 50.08224],
              [8.28782, 50.08248],
              [8.28812, 50.08265],
              [8.28908, 50.08318],
              [8.28844, 50.08414],
              [8.28816, 50.08446],
              [8.28778, 50.08491],
              [8.2875, 50.08536],
              [8.28705, 50.08659],
              [8.28686, 50.08716],
              [8.28601, 50.08698],
              [8.28562, 50.08689],
              [8.28533, 50.08683],
              [8.28528, 50.0878],
              [8.28514, 50.08809],
              [8.28644, 50.08854],
              [8.28694, 50.0889],
              [8.28696, 50.08938],
              [8.28695, 50.09016],
              [8.2867, 50.09038],
              [8.286326, 50.090475],
              [8.28517, 50.09077],
              [8.28465, 50.09075],
              [8.28413, 50.09066],
              [8.28347, 50.09045],
              [8.28286, 50.09067],
              [8.28303, 50.09092],
              [8.283031, 50.090928],
              [8.28311, 50.09161],
              [8.282716, 50.092975],
              [8.282715, 50.092976],
              [8.283804, 50.093033],
            ],
          ],
        },
        id: "k3MTc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.283804, 50.093033],
              [8.284733, 50.093082],
              [8.284733, 50.093082],
              [8.284759, 50.09211],
              [8.28476, 50.092066],
              [8.285233, 50.09182],
              [8.28531, 50.09178],
              [8.28517, 50.09077],
            ],
            [
              [8.286374, 50.082907],
              [8.28633, 50.08305],
              [8.28613, 50.08342],
              [8.28541, 50.0838],
              [8.28459, 50.08377],
              [8.28332, 50.08368],
              [8.28178, 50.08347],
              [8.28095, 50.08333],
              [8.28072, 50.08331],
              [8.28057, 50.0833],
            ],
            [
              [8.24687, 50.07991],
              [8.24576, 50.08049],
              [8.24493, 50.08066],
              [8.24371, 50.0806],
              [8.24282, 50.08054],
              [8.2422, 50.0805],
              [8.24206, 50.08049],
              [8.2421, 50.07999],
              [8.24221, 50.07922],
              [8.24239, 50.07799],
              [8.24243, 50.07777],
              [8.2425, 50.07769],
              [8.242575, 50.076958],
              [8.24265, 50.07623],
              [8.24281, 50.0744],
              [8.24288, 50.0739],
              [8.24298, 50.07321],
              [8.24313, 50.07218],
              [8.24307, 50.07209],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23143, 50.07437],
              [8.23051, 50.07357],
              [8.23, 50.0731],
              [8.22984, 50.07296],
              [8.22977, 50.07289],
              [8.22913, 50.07232],
              [8.22823, 50.07151],
              [8.22797, 50.07128],
              [8.22732, 50.07069],
              [8.22609, 50.06969],
              [8.223309, 50.067704],
              [8.2211, 50.06611],
              [8.22093, 50.06596],
              [8.22074, 50.0658],
              [8.2205, 50.06562],
              [8.21961, 50.06501],
              [8.21893, 50.0645],
              [8.21802, 50.06383],
              [8.21742, 50.06338],
              [8.21711, 50.06314],
              [8.21684, 50.06292],
              [8.21641, 50.06261],
              [8.21632, 50.06254],
              [8.21592, 50.06222],
              [8.21535, 50.06172],
              [8.21432, 50.06094],
              [8.2127, 50.05968],
              [8.21242, 50.05947],
              [8.212155, 50.059241],
              [8.21138, 50.05857],
            ],
            [
              [8.20035, 50.045937],
              [8.19923, 50.04589],
              [8.19767, 50.04577],
              [8.19617, 50.04565],
              [8.19585, 50.04563],
              [8.19456, 50.04562],
              [8.19392, 50.04562],
              [8.19219, 50.04532],
              [8.19174, 50.04524],
              [8.19042, 50.04504],
              [8.19007, 50.04548],
              [8.18957, 50.04619],
              [8.18927, 50.04654],
              [8.18667, 50.04678],
              [8.18628, 50.04672],
              [8.18616, 50.04651],
              [8.18659, 50.0458],
              [8.18686, 50.04559],
              [8.18721, 50.0454],
              [8.18816, 50.04529],
              [8.19007, 50.04548],
            ],
          ],
        },
        id: "UwMzU",
        direction: "INBOUND",
      },
    ],
  },
];
