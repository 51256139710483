import { Line, BusCategory } from "@/types";

export const v2_stadtbusse: Line[] = [
  {
    category: BusCategory.STADT,
    id: "IxOTI",
    name: "34_Mainz-Amöneburg <> 34_Hauptbahnhof",
    lineName: "34",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24505, 50.071],
              [8.24518, 50.07077],
              [8.24525, 50.07065],
              [8.24532, 50.07051],
              [8.24541, 50.07039],
              [8.24608, 50.07029],
              [8.24657, 50.06915],
              [8.24693, 50.06863],
              [8.2477, 50.06781],
              [8.24773, 50.06783],
              [8.24799, 50.06779],
              [8.24926, 50.06801],
              [8.24938, 50.06803],
              [8.24926, 50.06801],
              [8.24992, 50.06671],
              [8.250343, 50.066051],
              [8.25139, 50.06442],
              [8.25186, 50.06367],
              [8.2523, 50.06297],
              [8.252632, 50.062431],
              [8.25288, 50.06203],
              [8.25335, 50.06116],
              [8.25456, 50.05882],
              [8.2548, 50.05837],
              [8.25486, 50.05825],
              [8.25511, 50.05775],
              [8.25618, 50.05599],
              [8.25634, 50.05577],
              [8.25655, 50.0555],
              [8.257, 50.05488],
              [8.25718, 50.05436],
              [8.25718, 50.05428],
              [8.25712, 50.05381],
              [8.25679, 50.05318],
              [8.25661, 50.05267],
              [8.2565, 50.05243],
              [8.25638, 50.05194],
              [8.25635, 50.05129],
              [8.25633, 50.05048],
              [8.25631, 50.0497],
              [8.25625, 50.04888],
              [8.25614, 50.04865],
              [8.25593, 50.0479],
              [8.25564, 50.04664],
              [8.2554, 50.0456],
              [8.25539, 50.04509],
              [8.25543, 50.04495],
              [8.25557, 50.045],
              [8.2559, 50.04492],
              [8.25594, 50.04484],
              [8.25638, 50.04475],
              [8.25648, 50.04464],
              [8.25635, 50.04426],
              [8.25641, 50.04397],
              [8.25663, 50.04377],
              [8.257245, 50.043074],
              [8.25837, 50.0418],
              [8.25884, 50.04168],
              [8.25926, 50.04171],
              [8.26075, 50.04239],
              [8.26177, 50.04267],
              [8.26258, 50.04273],
              [8.26177, 50.04267],
              [8.26075, 50.04239],
              [8.25926, 50.04171],
              [8.25973, 50.04121],
              [8.261343, 50.039857],
              [8.261585, 50.03995],
              [8.261708, 50.040012],
              [8.261858, 50.039998],
              [8.261901, 50.039957],
              [8.261907, 50.039908],
            ],
          ],
        },
        id: "IxOTI",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.261928, 50.039895],
              [8.261853, 50.039815],
              [8.261563, 50.039681],
              [8.26144, 50.039764],
              [8.25973, 50.04121],
              [8.25926, 50.04171],
              [8.26075, 50.04239],
              [8.26177, 50.04267],
              [8.26258, 50.04273],
              [8.26177, 50.04267],
              [8.26075, 50.04239],
              [8.25926, 50.04171],
              [8.25884, 50.04168],
              [8.25837, 50.0418],
              [8.257245, 50.043074],
              [8.25663, 50.04377],
              [8.25641, 50.04397],
              [8.25635, 50.04426],
              [8.25648, 50.04464],
              [8.25638, 50.04475],
              [8.25594, 50.04484],
              [8.2559, 50.04492],
              [8.25589, 50.04521],
              [8.25635, 50.04768],
              [8.2563, 50.04801],
              [8.25653, 50.04925],
              [8.2566, 50.04981],
              [8.25667, 50.05047],
              [8.25669, 50.05118],
              [8.25675, 50.05193],
              [8.25689, 50.05246],
              [8.25696, 50.05258],
              [8.25716, 50.05306],
              [8.2574, 50.05368],
              [8.25738, 50.0541],
              [8.25735, 50.05435],
              [8.25713, 50.05489],
              [8.25671, 50.05551],
              [8.25656, 50.05571],
              [8.25635, 50.05596],
              [8.25549, 50.05732],
              [8.25525, 50.05778],
              [8.25516, 50.05795],
              [8.25483, 50.0586],
              [8.25472, 50.05883],
              [8.2535, 50.06118],
              [8.25303, 50.06205],
              [8.252744, 50.062491],
              [8.25242, 50.06299],
              [8.25196, 50.0637],
              [8.25151, 50.064419],
              [8.250414, 50.066171],
              [8.25007, 50.06672],
              [8.24992, 50.06671],
              [8.24926, 50.06801],
              [8.24938, 50.06803],
              [8.24926, 50.06801],
              [8.24799, 50.06779],
              [8.24773, 50.06783],
              [8.2477, 50.06781],
              [8.24693, 50.06863],
              [8.24657, 50.06915],
              [8.24608, 50.07029],
              [8.24541, 50.07039],
              [8.24532, 50.07051],
              [8.24525, 50.07065],
              [8.24518, 50.07077],
              [8.24505, 50.071],
              [8.24475, 50.07159],
              [8.243761, 50.071537],
            ],
          ],
        },
        id: "A2MzA",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "U4ODQ",
    name: "33 Bierstadt <> Sonnenberg",
    lineName: "33",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.26442, 50.10102],
              [8.264755, 50.101179],
              [8.265104, 50.101389],
              [8.265291, 50.101588],
              [8.2654, 50.10183],
              [8.26655, 50.10247],
              [8.26676, 50.10271],
              [8.26692, 50.10254],
              [8.26678, 50.10206],
              [8.26686, 50.10181],
              [8.26703, 50.10174],
              [8.26732, 50.10189],
              [8.26903, 50.10223],
              [8.26932, 50.10239],
              [8.26972, 50.10272],
              [8.26986, 50.10297],
              [8.26989, 50.10355],
              [8.27, 50.10373],
              [8.27022, 50.10384],
              [8.27049, 50.10384],
              [8.27143, 50.1038],
              [8.272, 50.10281],
              [8.27197, 50.10234],
              [8.27114, 50.10128],
              [8.27051, 50.10057],
              [8.27073, 50.09973],
              [8.27087, 50.09931],
              [8.27087, 50.09895],
              [8.27074, 50.09855],
              [8.269304, 50.098019],
              [8.2689, 50.09787],
              [8.269181, 50.097668],
              [8.26979, 50.09723],
              [8.26982, 50.0971],
              [8.26899, 50.09665],
              [8.26857, 50.09642],
              [8.26806, 50.09592],
              [8.26901, 50.09552],
              [8.26806, 50.09592],
              [8.26901, 50.09552],
              [8.27045, 50.09658],
              [8.27182, 50.09651],
              [8.27188, 50.09611],
              [8.2723, 50.09613],
              [8.27318, 50.09614],
              [8.27333, 50.09602],
              [8.27375, 50.09604],
              [8.27442, 50.09555],
              [8.27482, 50.09573],
              [8.27523, 50.09578],
              [8.27541, 50.09587],
              [8.27605, 50.09784],
              [8.27729, 50.09787],
              [8.27846, 50.09787],
              [8.27839, 50.09706],
              [8.27838, 50.09677],
              [8.27836, 50.09611],
              [8.27833, 50.09546],
              [8.2784, 50.09494],
              [8.27933, 50.09294],
              [8.279451, 50.092612],
              [8.27973, 50.09185],
              [8.280138, 50.091983],
              [8.280446, 50.092594],
              [8.280912, 50.092798],
              [8.282716, 50.092975],
              [8.282715, 50.092976],
              [8.283804, 50.093033],
              [8.284733, 50.093082],
              [8.284733, 50.093082],
              [8.284759, 50.09211],
              [8.28476, 50.092066],
              [8.285233, 50.09182],
              [8.28531, 50.09178],
              [8.28517, 50.09077],
              [8.286326, 50.090475],
              [8.2867, 50.09038],
              [8.28695, 50.09016],
              [8.28696, 50.08938],
              [8.28694, 50.0889],
              [8.28644, 50.08854],
              [8.28514, 50.08809],
              [8.28528, 50.0878],
              [8.28533, 50.08683],
              [8.283874, 50.086486],
              [8.28186, 50.08601],
              [8.28244, 50.08508],
              [8.28281, 50.08454],
              [8.28318, 50.08403],
              [8.28332, 50.08368],
              [8.28178, 50.08347],
              [8.28095, 50.08333],
              [8.28072, 50.08331],
              [8.28095, 50.08333],
              [8.28085, 50.0832],
              [8.28096, 50.08316],
              [8.28119, 50.08307],
              [8.28198, 50.08271],
              [8.28307, 50.08219],
              [8.28327, 50.08212],
              [8.28367, 50.08217],
              [8.28435, 50.08232],
              [8.28453, 50.08235],
              [8.28526, 50.08255],
              [8.28638, 50.08293],
              [8.28671, 50.08218],
              [8.28672, 50.08071],
              [8.28708, 50.07984],
              [8.28782, 50.079],
              [8.28454, 50.07912],
              [8.28106, 50.07961],
              [8.28144, 50.08026],
              [8.27861, 50.08163],
              [8.27671, 50.08153],
              [8.27564, 50.08145],
              [8.27298, 50.0813],
              [8.27048, 50.08117],
              [8.27026, 50.08318],
            ],
          ],
        },
        id: "U4ODQ",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.270235, 50.083224],
              [8.270063, 50.084725],
              [8.271823, 50.085],
              [8.273689, 50.085289],
              [8.274119, 50.083513],
              [8.271522, 50.083293],
              [8.27026, 50.08318],
              [8.27048, 50.08117],
              [8.27298, 50.0813],
              [8.27564, 50.08145],
              [8.27671, 50.08153],
              [8.27861, 50.08163],
              [8.28144, 50.08026],
              [8.28106, 50.07961],
              [8.28454, 50.07912],
              [8.28782, 50.079],
              [8.28708, 50.07984],
              [8.28672, 50.08071],
              [8.28671, 50.08218],
              [8.28638, 50.08293],
              [8.28633, 50.08305],
              [8.28613, 50.08342],
              [8.28541, 50.0838],
              [8.28459, 50.08377],
              [8.28332, 50.08368],
              [8.28178, 50.08347],
              [8.28145, 50.0844],
              [8.28129, 50.08482],
              [8.28097, 50.08559],
              [8.28186, 50.08601],
              [8.283874, 50.086486],
              [8.28533, 50.08683],
              [8.28528, 50.0878],
              [8.28514, 50.08809],
              [8.28644, 50.08854],
              [8.28694, 50.0889],
              [8.28696, 50.08938],
              [8.28695, 50.09016],
              [8.2867, 50.09038],
              [8.286326, 50.090475],
              [8.28517, 50.09077],
              [8.28531, 50.09178],
              [8.285233, 50.09182],
              [8.28476, 50.092066],
              [8.284759, 50.09211],
              [8.284733, 50.093082],
              [8.283804, 50.093033],
              [8.282715, 50.092976],
              [8.282716, 50.092975],
              [8.280912, 50.092798],
              [8.280446, 50.092594],
              [8.280138, 50.091983],
              [8.27973, 50.09185],
              [8.279451, 50.092612],
              [8.27933, 50.09294],
              [8.2784, 50.09494],
              [8.27833, 50.09546],
              [8.27836, 50.09611],
              [8.27838, 50.09677],
              [8.27839, 50.09706],
              [8.27846, 50.09787],
              [8.27729, 50.09787],
              [8.27605, 50.09784],
              [8.27541, 50.09587],
              [8.27523, 50.09578],
              [8.27482, 50.09573],
              [8.27442, 50.09555],
              [8.27375, 50.09604],
              [8.27333, 50.09602],
              [8.27318, 50.09614],
              [8.2723, 50.09613],
              [8.27188, 50.09611],
              [8.27182, 50.09651],
              [8.27045, 50.09658],
              [8.26901, 50.09552],
              [8.26806, 50.09592],
              [8.26857, 50.09642],
              [8.26899, 50.09665],
              [8.26982, 50.0971],
              [8.26979, 50.09723],
              [8.269181, 50.097668],
              [8.2689, 50.09787],
              [8.269304, 50.098019],
              [8.27074, 50.09855],
              [8.27087, 50.09895],
              [8.27087, 50.09931],
              [8.27073, 50.09973],
              [8.27051, 50.10057],
              [8.27114, 50.10128],
              [8.27197, 50.10234],
              [8.272, 50.10281],
              [8.27143, 50.1038],
              [8.27049, 50.10384],
              [8.27022, 50.10384],
              [8.27, 50.10373],
              [8.26989, 50.10355],
              [8.26986, 50.10297],
              [8.26972, 50.10272],
              [8.26932, 50.10239],
              [8.26903, 50.10223],
              [8.26732, 50.10189],
              [8.26703, 50.10174],
              [8.26686, 50.10181],
              [8.26678, 50.10206],
              [8.26692, 50.10254],
              [8.26676, 50.10271],
              [8.26655, 50.10247],
              [8.2654, 50.10183],
              [8.26417, 50.10217],
              [8.26416, 50.10183],
              [8.26423, 50.10168],
              [8.26442, 50.10102],
            ],
          ],
        },
        id: "UyNTk",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "Y3Nzk",
    name: "32 Nordfriedhof <> Sonnenberg",
    lineName: "32",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.26442, 50.10102],
              [8.26501, 50.1013],
              [8.26529, 50.10167],
              [8.2654, 50.10183],
              [8.26417, 50.10217],
              [8.26356, 50.10194],
              [8.26355, 50.10145],
              [8.26363, 50.10084],
              [8.26359, 50.10061],
              [8.26344, 50.10046],
              [8.26287, 50.10031],
              [8.26235, 50.10007],
              [8.26189, 50.10001],
              [8.26189, 50.09996],
              [8.26167, 50.09992],
              [8.261416, 50.099764],
              [8.25973, 50.09873],
              [8.25868, 50.098],
              [8.25817, 50.09758],
              [8.25811, 50.09753],
              [8.25793, 50.09751],
              [8.25791, 50.09735],
              [8.2576, 50.09717],
              [8.25722, 50.09671],
              [8.25622, 50.09599],
              [8.25527, 50.09574],
              [8.25442, 50.09582],
              [8.254117, 50.095979],
              [8.254, 50.09604],
              [8.25343, 50.0966],
              [8.25316, 50.09659],
              [8.25269, 50.09763],
              [8.25268, 50.0982],
              [8.2525, 50.09857],
              [8.25248, 50.09932],
              [8.25174, 50.09936],
              [8.25137, 50.09875],
              [8.25077, 50.09833],
              [8.25054, 50.09827],
              [8.24956, 50.09831],
              [8.24743, 50.0983],
              [8.24367, 50.09825],
              [8.24265, 50.09823],
              [8.24214, 50.09838],
              [8.24224, 50.09796],
              [8.24193, 50.09742],
              [8.24099, 50.09677],
              [8.239994, 50.096086],
              [8.23949, 50.09574],
              [8.2393, 50.09543],
              [8.23927, 50.09507],
              [8.23945, 50.09464],
              [8.23946, 50.09339],
              [8.23968, 50.09271],
              [8.2397, 50.09253],
              [8.23956, 50.09225],
              [8.23987, 50.09206],
              [8.241, 50.09175],
              [8.24177, 50.09132],
              [8.24271, 50.09115],
              [8.24332, 50.09028],
              [8.24441, 50.08987],
              [8.24427, 50.08962],
              [8.24403, 50.08908],
              [8.24389, 50.08833],
              [8.24306, 50.08778],
              [8.24228, 50.08728],
              [8.24103, 50.08805],
              [8.238921, 50.089397],
              [8.23651, 50.09095],
              [8.23625, 50.09122],
              [8.23616, 50.09117],
              [8.23469, 50.09182],
              [8.23438, 50.09194],
              [8.23379, 50.0922],
              [8.23317, 50.09267],
              [8.231244, 50.093574],
              [8.23121, 50.09359],
              [8.22692, 50.09435],
              [8.22612, 50.09467],
              [8.226, 50.09489],
              [8.22609, 50.09507],
              [8.22643, 50.09518],
              [8.22609, 50.09507],
              [8.226, 50.09489],
              [8.22612, 50.09467],
              [8.22692, 50.09435],
              [8.23121, 50.09359],
              [8.231244, 50.093574],
              [8.23317, 50.09267],
              [8.23379, 50.0922],
              [8.23438, 50.09194],
              [8.23469, 50.09182],
              [8.23616, 50.09117],
              [8.23625, 50.09122],
              [8.23651, 50.09095],
              [8.23626, 50.09075],
              [8.23596, 50.09027],
              [8.23576, 50.08994],
              [8.23528, 50.08994],
              [8.23316, 50.09167],
              [8.23213, 50.09225],
              [8.23161, 50.09239],
              [8.2305, 50.09261],
              [8.23032, 50.09258],
              [8.22977, 50.09249],
              [8.22902, 50.09266],
              [8.22814, 50.09285],
              [8.22721, 50.09304],
              [8.22544, 50.09338],
              [8.22452, 50.09347],
              [8.22421, 50.09335],
              [8.22324, 50.09368],
              [8.22308, 50.09377],
              [8.22261, 50.094135],
              [8.22196, 50.09464],
              [8.22164, 50.09517],
              [8.22112, 50.09604],
              [8.221, 50.0962],
              [8.22114, 50.09634],
              [8.22116, 50.09698],
            ],
          ],
        },
        id: "Y3Nzk",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.22116, 50.09698],
              [8.22114, 50.09634],
              [8.221, 50.0962],
              [8.22112, 50.09604],
              [8.22164, 50.09517],
              [8.22196, 50.09464],
              [8.22261, 50.094135],
              [8.22308, 50.09377],
              [8.22324, 50.09368],
              [8.22421, 50.09335],
              [8.22452, 50.09347],
              [8.22544, 50.09338],
              [8.22721, 50.09304],
              [8.22814, 50.09285],
              [8.22902, 50.09266],
              [8.22977, 50.09249],
              [8.23032, 50.09258],
              [8.2305, 50.09261],
              [8.23161, 50.09239],
              [8.23213, 50.09225],
              [8.23316, 50.09167],
              [8.23528, 50.08994],
              [8.23576, 50.08994],
              [8.23596, 50.09027],
              [8.23626, 50.09075],
              [8.23651, 50.09095],
              [8.23625, 50.09122],
              [8.23616, 50.09117],
              [8.23469, 50.09182],
              [8.23438, 50.09194],
              [8.23379, 50.0922],
              [8.23317, 50.09267],
              [8.231244, 50.093574],
              [8.23121, 50.09359],
              [8.22692, 50.09435],
              [8.22612, 50.09467],
              [8.226, 50.09489],
              [8.22609, 50.09507],
              [8.22643, 50.09518],
              [8.22609, 50.09507],
              [8.226, 50.09489],
              [8.22612, 50.09467],
              [8.22692, 50.09435],
              [8.23121, 50.09359],
              [8.231244, 50.093574],
              [8.23317, 50.09267],
              [8.23379, 50.0922],
              [8.23438, 50.09194],
              [8.23469, 50.09182],
              [8.23616, 50.09117],
              [8.23625, 50.09122],
              [8.23651, 50.09095],
              [8.238921, 50.089397],
              [8.24103, 50.08805],
              [8.24228, 50.08728],
              [8.24306, 50.08778],
              [8.24389, 50.08833],
              [8.24403, 50.08908],
              [8.24427, 50.08962],
              [8.24441, 50.08987],
              [8.24517, 50.09],
              [8.24495, 50.09093],
              [8.24453, 50.09104],
              [8.24449, 50.09097],
              [8.24271, 50.09115],
              [8.24177, 50.09132],
              [8.241, 50.09175],
              [8.23987, 50.09206],
              [8.23956, 50.09225],
              [8.2397, 50.09253],
              [8.23968, 50.09271],
              [8.23946, 50.09339],
              [8.23945, 50.09464],
              [8.23927, 50.09507],
              [8.2393, 50.09543],
              [8.23949, 50.09574],
              [8.239994, 50.096086],
              [8.24099, 50.09677],
              [8.24193, 50.09742],
              [8.24224, 50.09796],
              [8.24214, 50.09838],
              [8.24265, 50.09823],
              [8.24367, 50.09825],
              [8.24743, 50.0983],
              [8.24956, 50.09831],
              [8.25054, 50.09827],
              [8.25077, 50.09833],
              [8.25137, 50.09875],
              [8.25174, 50.09936],
              [8.25248, 50.09932],
              [8.2525, 50.09857],
              [8.25268, 50.0982],
              [8.25269, 50.09763],
              [8.25316, 50.09659],
              [8.25343, 50.0966],
              [8.254, 50.09604],
              [8.254117, 50.095979],
              [8.25442, 50.09582],
              [8.25527, 50.09574],
              [8.25622, 50.09599],
              [8.25722, 50.09671],
              [8.2576, 50.09717],
              [8.25791, 50.09735],
              [8.25793, 50.09751],
              [8.25811, 50.09753],
              [8.25817, 50.09758],
              [8.25868, 50.098],
              [8.25973, 50.09873],
              [8.261416, 50.099764],
              [8.26167, 50.09992],
              [8.26189, 50.09996],
              [8.26189, 50.10001],
              [8.26235, 50.10007],
              [8.26287, 50.10031],
              [8.26344, 50.10046],
              [8.26359, 50.10061],
              [8.26363, 50.10084],
              [8.26355, 50.10145],
              [8.26356, 50.10194],
              [8.26417, 50.10217],
              [8.26416, 50.10183],
              [8.26423, 50.10168],
              [8.26442, 50.10102],
            ],
          ],
        },
        id: "U2ODk",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "IyMDU",
    name: "31 Nordfriedhof <> Kohlheck",
    lineName: "31",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.19136, 50.0862],
              [8.19177, 50.08597],
              [8.19183, 50.08582],
              [8.19302, 50.08488],
              [8.19333, 50.08458],
              [8.19385, 50.0841],
              [8.19419, 50.08377],
              [8.19454, 50.08345],
              [8.19536, 50.08274],
              [8.19903, 50.08089],
              [8.199593, 50.080635],
              [8.199754, 50.080546],
              [8.200039, 50.080326],
              [8.200226, 50.080081],
              [8.200318, 50.079816],
              [8.200629, 50.079844],
              [8.201278, 50.079923],
              [8.20255, 50.08014],
              [8.20282, 50.08019],
              [8.2031, 50.08022],
              [8.20471, 50.08041],
              [8.20542, 50.08052],
              [8.20566, 50.08056],
              [8.20642, 50.08069],
              [8.20659, 50.08071],
              [8.20724, 50.08082],
              [8.20827, 50.08101],
              [8.20795, 50.08208],
              [8.20792, 50.08234],
              [8.20757, 50.08355],
              [8.20765, 50.08385],
              [8.20773, 50.08402],
              [8.20808, 50.08433],
              [8.20801, 50.0844],
              [8.207417, 50.084669],
              [8.20506, 50.0857],
              [8.20394, 50.08612],
              [8.20242, 50.0865],
              [8.20242, 50.08661],
              [8.20068, 50.08705],
              [8.20034, 50.08713],
              [8.1992, 50.0873],
              [8.199, 50.08722],
              [8.19867, 50.0885],
              [8.19866, 50.08924],
              [8.19895, 50.09005],
              [8.19728, 50.09015],
              [8.1965, 50.0902],
              [8.19574, 50.09026],
              [8.19544, 50.09037],
              [8.19523, 50.09061],
              [8.19522, 50.09086],
              [8.19537, 50.09102],
              [8.19596, 50.09126],
              [8.19682, 50.09162],
              [8.19716, 50.09173],
              [8.19796, 50.09199],
              [8.19933, 50.09207],
              [8.19939, 50.09263],
              [8.19945, 50.09309],
              [8.19938, 50.09323],
              [8.19871, 50.09383],
              [8.19814, 50.09438],
              [8.19832, 50.09439],
              [8.20092, 50.09446],
              [8.20189, 50.09425],
              [8.20235, 50.09409],
              [8.20429, 50.09337],
              [8.20467, 50.09339],
              [8.20683, 50.09295],
              [8.2083, 50.09231],
              [8.21135, 50.09082],
              [8.211745, 50.090644],
              [8.21209, 50.09049],
              [8.21244, 50.09025],
              [8.21438, 50.08829],
              [8.21444, 50.08824],
              [8.21544, 50.08762],
              [8.21584, 50.08746],
              [8.21741, 50.08712],
              [8.218173, 50.086954],
              [8.21897, 50.08678],
              [8.22058, 50.08644],
              [8.22093, 50.08637],
              [8.222181, 50.086095],
              [8.22261, 50.086],
              [8.22414, 50.08567],
              [8.22461, 50.08575],
              [8.22529, 50.08538],
              [8.2254, 50.08548],
              [8.22568, 50.0856],
              [8.226101, 50.085576],
              [8.22709, 50.08552],
              [8.22741, 50.08538],
              [8.22796, 50.0851],
              [8.22842, 50.08487],
              [8.22927, 50.08448],
              [8.22941, 50.08442],
              [8.22974, 50.08431],
              [8.23042, 50.08417],
              [8.23022, 50.08446],
              [8.22894, 50.08529],
              [8.22797, 50.08595],
              [8.22719, 50.08668],
              [8.22556, 50.08792],
              [8.22543, 50.08819],
              [8.22512, 50.08897],
              [8.22493, 50.08921],
              [8.22477, 50.08925],
              [8.22479, 50.0893],
              [8.22378, 50.09001],
              [8.22247, 50.09116],
              [8.22147, 50.09205],
              [8.2208, 50.0927],
              [8.22007, 50.09333],
              [8.21961, 50.09345],
              [8.21898, 50.09331],
              [8.21845, 50.09374],
              [8.21812, 50.09394],
              [8.21763, 50.09421],
              [8.21691, 50.09437],
              [8.21633, 50.09443],
              [8.21615, 50.09464],
              [8.21647, 50.09485],
              [8.21739, 50.0948],
              [8.21785, 50.09506],
              [8.21833, 50.0955],
              [8.21848, 50.09563],
              [8.21887, 50.09596],
              [8.219277, 50.096281],
              [8.21972, 50.09663],
              [8.22025, 50.09698],
              [8.22089, 50.09707],
              [8.22116, 50.09698],
            ],
          ],
        },
        id: "IyMDU",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.22116, 50.09698],
              [8.22089, 50.09707],
              [8.22025, 50.09698],
              [8.21972, 50.09663],
              [8.219277, 50.096281],
              [8.21887, 50.09596],
              [8.21848, 50.09563],
              [8.21833, 50.0955],
              [8.21785, 50.09506],
              [8.21739, 50.0948],
              [8.21647, 50.09485],
              [8.21615, 50.09464],
              [8.21633, 50.09443],
              [8.21691, 50.09437],
              [8.21763, 50.09421],
              [8.21812, 50.09394],
              [8.21845, 50.09374],
              [8.21898, 50.09331],
              [8.21961, 50.09345],
              [8.22007, 50.09333],
              [8.2208, 50.0927],
              [8.22147, 50.09205],
              [8.22247, 50.09116],
              [8.22378, 50.09001],
              [8.22479, 50.0893],
              [8.22477, 50.08925],
              [8.22493, 50.08921],
              [8.22512, 50.08897],
              [8.22543, 50.08819],
              [8.22556, 50.08792],
              [8.22719, 50.08668],
              [8.22797, 50.08595],
              [8.22894, 50.08529],
              [8.23022, 50.08446],
              [8.230412, 50.084167],
              [8.22974, 50.08431],
              [8.22941, 50.08442],
              [8.22927, 50.08448],
              [8.22842, 50.08487],
              [8.22796, 50.0851],
              [8.22741, 50.08538],
              [8.22709, 50.08552],
              [8.226101, 50.085576],
              [8.22568, 50.0856],
              [8.2254, 50.08548],
              [8.22529, 50.08538],
              [8.22461, 50.08575],
              [8.22414, 50.08567],
              [8.22261, 50.086],
              [8.222181, 50.086095],
              [8.22093, 50.08637],
              [8.22058, 50.08644],
              [8.21897, 50.08678],
              [8.218173, 50.086954],
              [8.21741, 50.08712],
              [8.21584, 50.08746],
              [8.21544, 50.08762],
              [8.21444, 50.08824],
              [8.21438, 50.08829],
              [8.21244, 50.09025],
              [8.21209, 50.09049],
              [8.211745, 50.090644],
              [8.21135, 50.09082],
              [8.2083, 50.09231],
              [8.20683, 50.09295],
              [8.20467, 50.09339],
              [8.20429, 50.09337],
              [8.20235, 50.09409],
              [8.20189, 50.09425],
              [8.20092, 50.09446],
              [8.19832, 50.09439],
              [8.19814, 50.09438],
              [8.19871, 50.09383],
              [8.19938, 50.09323],
              [8.19945, 50.09309],
              [8.19939, 50.09263],
              [8.19933, 50.09207],
              [8.19796, 50.09199],
              [8.19716, 50.09173],
              [8.19682, 50.09162],
              [8.19596, 50.09126],
              [8.19537, 50.09102],
              [8.19522, 50.09086],
              [8.19523, 50.09061],
              [8.19544, 50.09037],
              [8.19574, 50.09026],
              [8.1965, 50.0902],
              [8.19728, 50.09015],
              [8.19895, 50.09005],
              [8.19866, 50.08924],
              [8.19867, 50.0885],
              [8.199, 50.08722],
              [8.1992, 50.0873],
              [8.20034, 50.08713],
              [8.20068, 50.08705],
              [8.20242, 50.08661],
              [8.20242, 50.0865],
              [8.20394, 50.08612],
              [8.20506, 50.0857],
              [8.207417, 50.084669],
              [8.20801, 50.0844],
              [8.20808, 50.08433],
              [8.20773, 50.08402],
              [8.20765, 50.08385],
              [8.20757, 50.08355],
              [8.20792, 50.08234],
              [8.20795, 50.08208],
              [8.20827, 50.08101],
              [8.20724, 50.08082],
              [8.20659, 50.08071],
              [8.20642, 50.08069],
              [8.20566, 50.08056],
              [8.20542, 50.08052],
              [8.20471, 50.08041],
              [8.2031, 50.08022],
              [8.20282, 50.08019],
              [8.20255, 50.08014],
              [8.201278, 50.079919],
              [8.200318, 50.079816],
              [8.200226, 50.080081],
              [8.200033, 50.080329],
              [8.199717, 50.080573],
              [8.199422, 50.080715],
              [8.19903, 50.08089],
              [8.19536, 50.08274],
              [8.19454, 50.08345],
              [8.19419, 50.08377],
              [8.19385, 50.0841],
              [8.19333, 50.08458],
              [8.19302, 50.08488],
              [8.19183, 50.08582],
              [8.19177, 50.08597],
              [8.19136, 50.0862],
            ],
          ],
        },
        id: "g0MTk",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "AwMzY",
    name: "30 Nordenstadt <> Mainz",
    lineName: "30",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.20645, 50.00673],
              [8.20536, 50.00828],
              [8.20534, 50.00852],
              [8.20563, 50.00876],
              [8.20649, 50.00886],
              [8.20708, 50.00834],
              [8.20741, 50.00832],
              [8.207803, 50.008425],
              [8.20935, 50.00884],
              [8.21073, 50.00924],
              [8.21089, 50.00923],
              [8.21121, 50.00907],
              [8.21176, 50.00829],
              [8.21426, 50.00898],
              [8.21508, 50.00921],
              [8.21552, 50.00915],
              [8.21535, 50.0088],
              [8.21534, 50.00813],
              [8.21543, 50.00783],
              [8.21587, 50.00707],
              [8.21634, 50.00612],
              [8.21631, 50.00594],
              [8.21627, 50.00574],
              [8.21647, 50.00521],
              [8.2169, 50.0046],
              [8.21726, 50.00411],
              [8.21801, 50.00305],
              [8.21817, 50.00248],
              [8.21795, 50.00169],
              [8.2179, 50.00156],
              [8.21781, 50.00108],
              [8.217885, 50.000598],
              [8.21794, 50.00024],
              [8.21841, 49.99968],
              [8.2188, 49.99942],
              [8.21945, 49.99909],
              [8.21995, 49.99882],
              [8.22076, 49.99822],
              [8.221702, 49.997609],
              [8.22227, 49.99724],
              [8.22352, 49.99658],
              [8.2247, 49.99573],
              [8.22507, 49.99593],
              [8.22653, 49.99624],
              [8.22708, 49.99622],
              [8.22768, 49.99634],
              [8.22864, 49.99655],
              [8.22988, 49.99649],
              [8.23051, 49.99655],
              [8.23067, 49.99643],
              [8.23081, 49.99632],
              [8.23099, 49.99619],
              [8.23131, 49.99595],
              [8.23191, 49.99551],
              [8.23224, 49.99542],
              [8.2328, 49.99555],
              [8.23336, 49.99566],
              [8.2337, 49.99574],
              [8.23499, 49.99603],
              [8.23536, 49.99608],
              [8.23632, 49.99634],
              [8.23645, 49.99637],
              [8.23777, 49.99664],
              [8.23755, 49.99716],
              [8.23747, 49.9973],
              [8.23766, 49.99784],
              [8.237748, 49.997962],
              [8.23807, 49.99841],
              [8.23971, 49.9979],
              [8.24014, 49.99777],
              [8.24026, 49.99773],
              [8.24096, 49.99753],
              [8.24106, 49.9975],
              [8.24116, 49.99747],
              [8.24135, 49.99741],
              [8.24287, 49.99695],
              [8.24327, 49.99683],
              [8.24335, 49.99681],
              [8.24351, 49.99676],
              [8.24416, 49.99659],
              [8.24464, 49.99652],
              [8.24534, 49.99634],
              [8.246306, 49.996537],
              [8.24716, 49.99671],
              [8.24804, 49.99692],
              [8.24917, 49.99706],
              [8.25106, 49.99725],
              [8.25276, 49.99729],
              [8.25565, 49.99745],
              [8.2559, 49.99749],
              [8.25636, 49.99779],
              [8.25654, 49.99797],
              [8.25727, 49.99879],
              [8.2576, 49.99905],
              [8.25805, 49.99925],
              [8.25829, 49.99933],
              [8.258525, 49.999387],
              [8.25889, 49.99948],
              [8.25926, 49.99958],
              [8.26046, 50.00007],
              [8.26072, 50.00019],
              [8.26052, 50.00034],
              [8.25989, 50.00102],
              [8.25996, 50.00114],
              [8.26012, 50.00141],
              [8.26124, 50.00131],
              [8.2614, 50.00124],
              [8.26256, 50.00077],
              [8.26337, 50.00046],
              [8.26386, 50.00023],
              [8.26419, 50.0004],
              [8.26475, 50.00086],
              [8.26557, 50.00153],
              [8.26614, 50.00198],
              [8.26643, 50.00221],
              [8.26686, 50.00254],
              [8.2677, 50.00322],
              [8.26871, 50.00412],
              [8.2693, 50.00465],
              [8.26941, 50.00474],
              [8.27007, 50.00533],
              [8.27079, 50.00586],
              [8.27135, 50.00613],
              [8.27167, 50.0061],
              [8.27224, 50.005452],
              [8.27225, 50.00544],
              [8.27306, 50.00463],
              [8.27384, 50.00376],
              [8.27404, 50.00348],
              [8.27407, 50.00343],
              [8.2742, 50.00325],
              [8.27445, 50.00291],
              [8.27467, 50.00297],
              [8.27455, 50.00338],
              [8.27447, 50.00364],
              [8.27365, 50.00446],
              [8.27324, 50.00494],
              [8.27336, 50.00517],
              [8.27367, 50.00531],
              [8.27391, 50.00541],
              [8.27591, 50.00622],
              [8.27639, 50.00642],
              [8.27988, 50.00785],
              [8.28046, 50.00803],
              [8.28116, 50.00833],
              [8.28132, 50.00833],
              [8.28176, 50.00807],
              [8.28208, 50.00778],
              [8.28285, 50.00734],
              [8.28339, 50.00707],
              [8.283716, 50.006878],
              [8.284, 50.00671],
              [8.28512, 50.0062],
              [8.28525, 50.00617],
              [8.28618, 50.00612],
              [8.28777, 50.00633],
              [8.2884, 50.00649],
              [8.28886, 50.00664],
              [8.290872, 50.004792],
              [8.29114, 50.00457],
              [8.29237, 50.00399],
              [8.29397, 50.00339],
              [8.29237, 50.00399],
              [8.29397, 50.00339],
              [8.29611, 50.00262],
              [8.29744, 50.00219],
              [8.30019, 50.00134],
              [8.30103, 50.00108],
              [8.30151, 50.00095],
              [8.30294, 50.00049],
              [8.30329, 50.0004],
              [8.30381, 50.00026],
              [8.30465, 50.00015],
              [8.30482, 50.00015],
              [8.30562, 50.00016],
              [8.30584, 50.00018],
              [8.30693, 50.00033],
              [8.3073, 50.00041],
              [8.30749, 50.00045],
              [8.30809, 50.00055],
              [8.30848, 50.00063],
              [8.30872, 50.00061],
              [8.30876, 50.00069],
              [8.30889, 50.00075],
              [8.30987, 50.0013],
              [8.30991, 50.00137],
              [8.30975, 50.00156],
              [8.3095, 50.00179],
              [8.30898, 50.00239],
              [8.30873, 50.00267],
              [8.30863, 50.00278],
              [8.30834, 50.00318],
              [8.30804, 50.00358],
              [8.30797, 50.00368],
              [8.3078, 50.00415],
              [8.30768, 50.00448],
              [8.30766, 50.00473],
              [8.30781, 50.00488],
              [8.30856, 50.00508],
              [8.30893, 50.00519],
              [8.30928, 50.00525],
              [8.31324, 50.00574],
              [8.31543, 50.00599],
              [8.3157, 50.006],
              [8.31702, 50.00599],
              [8.31799, 50.00587],
              [8.31844, 50.00605],
              [8.31856, 50.00626],
              [8.318538, 50.007016],
              [8.31851, 50.00801],
              [8.31854, 50.0081],
              [8.32049, 50.00782],
              [8.32134, 50.00769],
              [8.32311, 50.00742],
              [8.32449, 50.00748],
              [8.32634, 50.00765],
              [8.32808, 50.00793],
              [8.32933, 50.00824],
              [8.33024, 50.00846],
              [8.33223, 50.00871],
              [8.33462, 50.00928],
              [8.33617, 50.0096],
              [8.33658, 50.00976],
              [8.33741, 50.01013],
              [8.33768, 50.01025],
              [8.33989, 50.01077],
              [8.34002, 50.01079],
              [8.34136, 50.01097],
              [8.34195, 50.01099],
              [8.34321, 50.01101],
              [8.34343, 50.011],
              [8.34485, 50.01091],
              [8.344906, 50.010919],
              [8.34525, 50.01097],
              [8.34544, 50.01212],
              [8.34706, 50.01236],
              [8.34783, 50.01249],
              [8.34845, 50.01259],
              [8.34943, 50.01267],
              [8.35004, 50.01252],
              [8.35118, 50.01228],
              [8.35148, 50.01222],
              [8.35276, 50.01195],
              [8.3534, 50.0118],
              [8.35434, 50.01167],
              [8.35468, 50.01162],
              [8.35581, 50.01205],
              [8.3569, 50.01248],
              [8.35704, 50.01254],
              [8.35778, 50.0128],
              [8.35992, 50.01359],
              [8.35944, 50.01408],
              [8.35905, 50.01452],
              [8.35896, 50.01463],
              [8.35781, 50.01592],
              [8.35768, 50.01605],
              [8.35746, 50.01629],
              [8.357216, 50.01653],
              [8.35678, 50.01696],
              [8.3566, 50.01709],
              [8.35636, 50.01727],
              [8.35648, 50.01741],
              [8.35659, 50.01756],
              [8.35679, 50.01758],
              [8.35711, 50.01803],
              [8.35757, 50.01864],
              [8.3575, 50.01876],
              [8.358307, 50.019769],
              [8.35612, 50.020296],
              [8.355584, 50.020564],
              [8.354512, 50.020564],
              [8.353647, 50.020811],
              [8.352864, 50.021038],
              [8.35216, 50.02093],
              [8.35227, 50.02251],
              [8.35228, 50.02295],
              [8.35228, 50.02383],
              [8.35198, 50.02577],
              [8.35152, 50.02711],
              [8.35082, 50.02851],
              [8.35009, 50.02985],
              [8.349722, 50.030474],
              [8.34901, 50.03168],
              [8.34878, 50.03206],
              [8.34857, 50.03245],
              [8.34839, 50.03279],
              [8.348142, 50.033412],
              [8.34822, 50.033887],
              [8.35005, 50.03475],
              [8.3505, 50.03493],
              [8.3518, 50.03543],
              [8.35309, 50.03593],
              [8.35438, 50.03649],
              [8.35479, 50.03686],
              [8.35519, 50.03775],
              [8.35545, 50.03833],
              [8.35564, 50.03874],
              [8.3568, 50.03837],
              [8.35752, 50.03802],
              [8.35774, 50.03773],
              [8.35814, 50.03729],
              [8.35832, 50.03726],
              [8.3592, 50.03757],
              [8.35957, 50.03772],
              [8.36042, 50.038],
              [8.36018, 50.03829],
              [8.36004, 50.03846],
              [8.36129, 50.03897],
              [8.3611, 50.03925],
              [8.36146, 50.03972],
              [8.36254, 50.0414],
              [8.36153, 50.04187],
              [8.36042, 50.04245],
              [8.35985, 50.04302],
              [8.35921, 50.04385],
              [8.3587, 50.04431],
              [8.35866, 50.04446],
              [8.35971, 50.04536],
              [8.3596, 50.04553],
              [8.359891, 50.045646],
              [8.36023, 50.04578],
              [8.36051, 50.04612],
              [8.36071, 50.04652],
              [8.36093, 50.04708],
              [8.36138, 50.04746],
              [8.36196, 50.04735],
              [8.36266, 50.0471],
              [8.36273, 50.04708],
              [8.36334, 50.04691],
              [8.3634, 50.0469],
              [8.36443, 50.04664],
              [8.3645, 50.04662],
              [8.36471, 50.04657],
              [8.36515, 50.04647],
              [8.36574, 50.04636],
              [8.36603, 50.04662],
              [8.36635, 50.04673],
              [8.36767, 50.04721],
              [8.36813, 50.04728],
              [8.36851, 50.0473],
              [8.37003, 50.04735],
              [8.37067, 50.04741],
              [8.37142, 50.04756],
              [8.37199, 50.04785],
              [8.37303, 50.04852],
              [8.37433, 50.04983],
              [8.374894, 50.05066],
              [8.37516, 50.05105],
              [8.3754, 50.05129],
              [8.37513, 50.05148],
              [8.37435, 50.05204],
              [8.3742, 50.05214],
              [8.3739, 50.05236],
              [8.37381, 50.05243],
              [8.3734, 50.05272],
              [8.3731, 50.05294],
              [8.37299, 50.05302],
              [8.372276, 50.053541],
              [8.371776, 50.053906],
              [8.37177, 50.05391],
              [8.37124, 50.05431],
              [8.37106, 50.05444],
              [8.37075, 50.054669],
              [8.3703, 50.05514],
              [8.3698, 50.0566],
              [8.36977, 50.05668],
              [8.36971, 50.05691],
              [8.36956, 50.05737],
              [8.36947, 50.05784],
              [8.36965, 50.05843],
              [8.37014, 50.05892],
              [8.37048, 50.05875],
              [8.37087, 50.05842],
              [8.36979, 50.05784],
              [8.36976, 50.05776],
              [8.37001, 50.0573],
              [8.370128, 50.057227],
              [8.37038, 50.05707],
              [8.37172, 50.05633],
              [8.37226, 50.05619],
              [8.37329, 50.05613],
              [8.37533, 50.05625],
              [8.37556, 50.05638],
              [8.37574, 50.05704],
              [8.37549, 50.05724],
              [8.37374, 50.05854],
              [8.3725, 50.05954],
              [8.3713, 50.05932],
              [8.37069, 50.05887],
              [8.37048, 50.05875],
              [8.37014, 50.05892],
              [8.36956, 50.05931],
              [8.36941, 50.05944],
              [8.36896, 50.05985],
              [8.37017, 50.06066],
              [8.370344, 50.060753],
              [8.37092, 50.06106],
              [8.37077, 50.06128],
              [8.37041, 50.0617],
              [8.37005, 50.06267],
              [8.36982, 50.06299],
              [8.36974, 50.06339],
              [8.36979, 50.0637],
              [8.36946, 50.06434],
              [8.3692, 50.06474],
              [8.36832, 50.06445],
              [8.367509, 50.064107],
              [8.36709, 50.06393],
              [8.36534, 50.06312],
              [8.36516, 50.06256],
              [8.36499, 50.06211],
              [8.36368, 50.06229],
              [8.36072, 50.06271],
              [8.35936, 50.06292],
              [8.35746, 50.06318],
              [8.35677, 50.06328],
              [8.35477, 50.06357],
              [8.35395, 50.06366],
              [8.35356, 50.06364],
              [8.35347, 50.06367],
              [8.35261, 50.06379],
              [8.35338, 50.06358],
              [8.35347, 50.06354],
              [8.35326, 50.06326],
              [8.35306, 50.06308],
              [8.35051, 50.06067],
              [8.35023, 50.06055],
              [8.35015, 50.06051],
              [8.34912, 50.06024],
              [8.34799, 50.06017],
              [8.34518, 50.05897],
              [8.3446, 50.0588],
              [8.34392, 50.05874],
              [8.34209, 50.05908],
              [8.34264, 50.05962],
              [8.34246, 50.05984],
              [8.34267, 50.06031],
              [8.34221, 50.0604],
              [8.34096, 50.06065],
              [8.340923, 50.061073],
              [8.34088, 50.06156],
              [8.3408, 50.06168],
              [8.34003, 50.06206],
              [8.33895, 50.06254],
              [8.33877, 50.06262],
              [8.33862, 50.0627],
              [8.33785, 50.06301],
              [8.33704, 50.06325],
              [8.336146, 50.060883],
              [8.33583, 50.06043],
              [8.33391, 50.06078],
              [8.33278, 50.06099],
              [8.330026, 50.061527],
              [8.3296, 50.06161],
              [8.330026, 50.061527],
              [8.331989, 50.064709],
            ],
          ],
        },
        id: "AwMzY",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.331989, 50.064709],
              [8.330026, 50.061527],
              [8.3296, 50.06161],
              [8.330026, 50.061527],
              [8.33278, 50.06099],
              [8.33391, 50.06078],
              [8.33583, 50.06043],
              [8.336146, 50.060883],
              [8.33704, 50.06325],
              [8.33785, 50.06301],
              [8.33862, 50.0627],
              [8.33877, 50.06262],
              [8.33895, 50.06254],
              [8.34003, 50.06206],
              [8.3408, 50.06168],
              [8.34088, 50.06156],
              [8.340923, 50.061073],
              [8.34096, 50.06065],
              [8.34221, 50.0604],
              [8.34267, 50.06031],
              [8.34246, 50.05984],
              [8.34264, 50.05962],
              [8.34209, 50.05908],
              [8.34392, 50.05874],
              [8.3446, 50.0588],
              [8.34518, 50.05897],
              [8.34799, 50.06017],
              [8.34912, 50.06024],
              [8.35015, 50.06051],
              [8.35023, 50.06055],
              [8.35051, 50.06067],
              [8.35306, 50.06308],
              [8.35326, 50.06326],
              [8.35347, 50.06354],
              [8.35338, 50.06358],
              [8.35261, 50.06379],
              [8.35347, 50.06367],
              [8.35356, 50.06364],
              [8.35395, 50.06366],
              [8.35477, 50.06357],
              [8.35677, 50.06328],
              [8.35746, 50.06318],
              [8.35936, 50.06292],
              [8.36072, 50.06271],
              [8.36368, 50.06229],
              [8.36499, 50.06211],
              [8.36516, 50.06256],
              [8.36534, 50.06312],
              [8.36709, 50.06393],
              [8.367509, 50.064107],
              [8.36832, 50.06445],
              [8.3692, 50.06474],
              [8.36946, 50.06434],
              [8.36979, 50.0637],
              [8.36974, 50.06339],
              [8.36982, 50.06299],
              [8.37005, 50.06267],
              [8.37041, 50.0617],
              [8.37077, 50.06128],
              [8.37092, 50.06106],
              [8.370344, 50.060753],
              [8.37017, 50.06066],
              [8.36896, 50.05985],
              [8.36941, 50.05944],
              [8.36956, 50.05931],
              [8.37014, 50.05892],
              [8.37048, 50.05875],
              [8.37087, 50.05842],
              [8.36979, 50.05784],
              [8.36976, 50.05776],
              [8.37001, 50.0573],
              [8.370128, 50.057227],
              [8.37038, 50.05707],
              [8.37172, 50.05633],
              [8.37226, 50.05619],
              [8.37329, 50.05613],
              [8.37533, 50.05625],
              [8.37556, 50.05638],
              [8.37574, 50.05704],
              [8.37549, 50.05724],
              [8.37374, 50.05854],
              [8.3725, 50.05954],
              [8.3713, 50.05932],
              [8.37069, 50.05887],
              [8.37014, 50.05892],
              [8.36965, 50.05843],
              [8.36947, 50.05784],
              [8.36956, 50.05737],
              [8.36971, 50.05691],
              [8.36977, 50.05668],
              [8.3698, 50.0566],
              [8.3703, 50.05514],
              [8.37075, 50.054669],
              [8.37106, 50.05444],
              [8.37124, 50.05431],
              [8.37177, 50.05391],
              [8.371776, 50.053906],
              [8.372276, 50.053541],
              [8.37299, 50.05302],
              [8.3731, 50.05294],
              [8.3734, 50.05272],
              [8.37381, 50.05243],
              [8.3739, 50.05236],
              [8.3742, 50.05214],
              [8.37435, 50.05204],
              [8.37513, 50.05148],
              [8.3754, 50.05129],
              [8.37516, 50.05105],
              [8.374894, 50.05066],
              [8.37433, 50.04983],
              [8.37303, 50.04852],
              [8.37199, 50.04785],
              [8.37142, 50.04756],
              [8.37067, 50.04741],
              [8.37003, 50.04735],
              [8.36851, 50.0473],
              [8.36813, 50.04728],
              [8.36767, 50.04721],
              [8.36635, 50.04673],
              [8.36603, 50.04662],
              [8.36574, 50.04636],
              [8.36515, 50.04647],
              [8.36471, 50.04657],
              [8.3645, 50.04662],
              [8.36443, 50.04664],
              [8.3634, 50.0469],
              [8.36334, 50.04691],
              [8.36273, 50.04708],
              [8.36266, 50.0471],
              [8.36196, 50.04735],
              [8.36138, 50.04746],
              [8.36093, 50.04708],
              [8.36071, 50.04652],
              [8.36051, 50.04612],
              [8.36023, 50.04578],
              [8.359891, 50.045646],
              [8.3596, 50.04553],
              [8.35971, 50.04536],
              [8.35866, 50.04446],
              [8.3587, 50.04431],
              [8.35921, 50.04385],
              [8.35985, 50.04302],
              [8.36042, 50.04245],
              [8.36153, 50.04187],
              [8.36254, 50.0414],
              [8.36146, 50.03972],
              [8.3611, 50.03925],
              [8.36042, 50.03941],
              [8.36025, 50.03915],
              [8.36002, 50.03899],
              [8.35752, 50.03802],
              [8.3568, 50.03837],
              [8.35564, 50.03874],
              [8.35545, 50.03833],
              [8.35519, 50.03775],
              [8.35479, 50.03686],
              [8.35438, 50.03649],
              [8.35309, 50.03593],
              [8.3518, 50.03543],
              [8.3505, 50.03493],
              [8.35005, 50.03475],
              [8.34822, 50.033887],
              [8.348142, 50.033412],
              [8.34839, 50.03279],
              [8.34857, 50.03245],
              [8.34878, 50.03206],
              [8.34901, 50.03168],
              [8.349722, 50.030474],
              [8.35009, 50.02985],
              [8.35082, 50.02851],
              [8.35152, 50.02711],
              [8.35198, 50.02577],
              [8.35228, 50.02383],
              [8.35228, 50.02295],
              [8.35227, 50.02251],
              [8.35216, 50.02093],
              [8.352864, 50.021038],
              [8.353647, 50.020811],
              [8.354512, 50.020564],
              [8.355584, 50.020564],
              [8.35612, 50.020296],
              [8.358307, 50.019769],
              [8.3575, 50.01876],
              [8.35757, 50.01864],
              [8.35711, 50.01803],
              [8.35679, 50.01758],
              [8.35659, 50.01756],
              [8.35648, 50.01741],
              [8.35636, 50.01727],
              [8.3566, 50.01709],
              [8.35678, 50.01696],
              [8.357216, 50.01653],
              [8.35746, 50.01629],
              [8.35768, 50.01605],
              [8.35781, 50.01592],
              [8.35896, 50.01463],
              [8.35905, 50.01452],
              [8.35944, 50.01408],
              [8.35992, 50.01359],
              [8.35778, 50.0128],
              [8.35704, 50.01254],
              [8.3569, 50.01248],
              [8.35581, 50.01205],
              [8.35468, 50.01162],
              [8.3543, 50.01148],
              [8.35336, 50.01115],
              [8.35265, 50.01099],
              [8.35217, 50.01099],
              [8.35097, 50.01109],
              [8.35083, 50.01111],
              [8.35044, 50.01114],
              [8.3499, 50.01116],
              [8.34947, 50.01112],
              [8.34926, 50.01108],
              [8.34857, 50.01087],
              [8.34801, 50.01074],
              [8.34761, 50.01071],
              [8.34696, 50.01077],
              [8.34684, 50.01078],
              [8.34537, 50.01089],
              [8.34525, 50.01097],
              [8.34544, 50.01212],
              [8.34467, 50.0118],
              [8.34377, 50.01138],
              [8.34274, 50.01116],
              [8.34263, 50.01109],
              [8.34195, 50.01099],
              [8.34136, 50.01097],
              [8.34002, 50.01079],
              [8.33989, 50.01077],
              [8.33768, 50.01025],
              [8.33741, 50.01013],
              [8.33658, 50.00976],
              [8.33617, 50.0096],
              [8.33462, 50.00928],
              [8.33223, 50.00871],
              [8.33024, 50.00846],
              [8.32933, 50.00824],
              [8.32808, 50.00793],
              [8.32634, 50.00765],
              [8.32449, 50.00748],
              [8.32311, 50.00742],
              [8.32134, 50.00769],
              [8.32049, 50.00782],
              [8.31854, 50.0081],
              [8.31851, 50.00801],
              [8.318538, 50.007016],
              [8.31856, 50.00626],
              [8.31844, 50.00605],
              [8.31799, 50.00587],
              [8.31702, 50.00599],
              [8.3157, 50.006],
              [8.31543, 50.00599],
              [8.31324, 50.00574],
              [8.30928, 50.00525],
              [8.30893, 50.00519],
              [8.30856, 50.00508],
              [8.30781, 50.00488],
              [8.30766, 50.00473],
              [8.30768, 50.00448],
              [8.3078, 50.00415],
              [8.30797, 50.00368],
              [8.30804, 50.00358],
              [8.30736, 50.0035],
              [8.30672, 50.00349],
              [8.30582, 50.00348],
              [8.30474, 50.00348],
              [8.30371, 50.00345],
              [8.30263, 50.00343],
              [8.30235, 50.0028],
              [8.30125, 50.00303],
              [8.30107, 50.00275],
              [8.3006, 50.00198],
              [8.30042, 50.00167],
              [8.30019, 50.00134],
              [8.29744, 50.00219],
              [8.29611, 50.00262],
              [8.29397, 50.00339],
              [8.29237, 50.00399],
              [8.29114, 50.00457],
              [8.290872, 50.004792],
              [8.28878, 50.00673],
              [8.28831, 50.00659],
              [8.28755, 50.00642],
              [8.2863, 50.00625],
              [8.28532, 50.00628],
              [8.28503, 50.00635],
              [8.28418, 50.00678],
              [8.283716, 50.006878],
              [8.27988, 50.00785],
              [8.27981, 50.00787],
              [8.2797, 50.00783],
              [8.27634, 50.00647],
              [8.27589, 50.00628],
              [8.27483, 50.00585],
              [8.27388, 50.00546],
              [8.2733, 50.00523],
              [8.27292, 50.00526],
              [8.27284, 50.00532],
              [8.27241, 50.0058],
              [8.27202, 50.00622],
              [8.2719, 50.00636],
              [8.27152, 50.00639],
              [8.27135, 50.00634],
              [8.271064, 50.006095],
              [8.27079, 50.00586],
              [8.27007, 50.00533],
              [8.26941, 50.00474],
              [8.2693, 50.00465],
              [8.26871, 50.00412],
              [8.2677, 50.00322],
              [8.26686, 50.00254],
              [8.26643, 50.00221],
              [8.26614, 50.00198],
              [8.26557, 50.00153],
              [8.26475, 50.00086],
              [8.26419, 50.0004],
              [8.26386, 50.00023],
              [8.26337, 50.00046],
              [8.26256, 50.00077],
              [8.2614, 50.00124],
              [8.26124, 50.00131],
              [8.26012, 50.00141],
              [8.25996, 50.00114],
              [8.25989, 50.00102],
              [8.26052, 50.00034],
              [8.25994, 50.00007],
              [8.25909, 49.99976],
              [8.25889, 49.99971],
              [8.25846, 49.99967],
              [8.25828, 49.99963],
              [8.25791, 49.99939],
              [8.25746, 49.99916],
              [8.25718, 49.99895],
              [8.25633, 49.99802],
              [8.2563, 49.99799],
              [8.25597, 49.9977],
              [8.25585, 49.99764],
              [8.25465, 49.99756],
              [8.25286, 49.99758],
              [8.25186, 49.99756],
              [8.25089, 49.99748],
              [8.249185, 49.997403],
              [8.24912, 49.9974],
              [8.24715, 49.99712],
              [8.24679, 49.99705],
              [8.24491, 49.99672],
              [8.24398, 49.99671],
              [8.24335, 49.99681],
              [8.24327, 49.99683],
              [8.24287, 49.99695],
              [8.24135, 49.99741],
              [8.24116, 49.99747],
              [8.24106, 49.9975],
              [8.24096, 49.99753],
              [8.24026, 49.99773],
              [8.24014, 49.99777],
              [8.23971, 49.9979],
              [8.23807, 49.99841],
              [8.237748, 49.997962],
              [8.23766, 49.99784],
              [8.23747, 49.9973],
              [8.23755, 49.99716],
              [8.23777, 49.99664],
              [8.23645, 49.99637],
              [8.23632, 49.99634],
              [8.23536, 49.99608],
              [8.23499, 49.99603],
              [8.2337, 49.99574],
              [8.23336, 49.99566],
              [8.2328, 49.99555],
              [8.23224, 49.99542],
              [8.23191, 49.99551],
              [8.23131, 49.99595],
              [8.23099, 49.99619],
              [8.23081, 49.99632],
              [8.23067, 49.99643],
              [8.23051, 49.99655],
              [8.22988, 49.99649],
              [8.22864, 49.99655],
              [8.22768, 49.99634],
              [8.22708, 49.99622],
              [8.22653, 49.99624],
              [8.22507, 49.99593],
              [8.2247, 49.99573],
              [8.22352, 49.99658],
              [8.22227, 49.99724],
              [8.221702, 49.997609],
              [8.22076, 49.99822],
              [8.21995, 49.99882],
              [8.21945, 49.99909],
              [8.2188, 49.99942],
              [8.21841, 49.99968],
              [8.21794, 50.00024],
              [8.217885, 50.000598],
              [8.21781, 50.00108],
              [8.2179, 50.00156],
              [8.21795, 50.00169],
              [8.21817, 50.00248],
              [8.21801, 50.00305],
              [8.21726, 50.00411],
              [8.2169, 50.0046],
              [8.21647, 50.00521],
              [8.21627, 50.00574],
              [8.21631, 50.00594],
              [8.21634, 50.00612],
              [8.21587, 50.00707],
              [8.21543, 50.00783],
              [8.21534, 50.00813],
              [8.21535, 50.0088],
              [8.21552, 50.00915],
              [8.21508, 50.00921],
              [8.21426, 50.00898],
              [8.21176, 50.00829],
              [8.208631, 50.007358],
              [8.20696, 50.00686],
              [8.20645, 50.00673],
            ],
          ],
        },
        id: "QwNjE",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "A0NDc",
    name: "20 Sonnenberg <> Mainz_Bismarckplatz",
    lineName: "20",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.2487, 50.01254],
              [8.24894, 50.0125],
              [8.24897, 50.01218],
              [8.24903, 50.01192],
              [8.24955, 50.01116],
              [8.2502, 50.01056],
              [8.25079, 50.01008],
              [8.2519, 50.00916],
              [8.25206, 50.00928],
              [8.25256, 50.00965],
              [8.25345, 50.01036],
              [8.25462, 50.01132],
              [8.25526, 50.01191],
              [8.25579, 50.01236],
              [8.25597, 50.01227],
              [8.257564, 50.011343],
              [8.25786, 50.01117],
              [8.25903, 50.01064],
              [8.25945, 50.0104],
              [8.2588, 50.00998],
              [8.25895, 50.0099],
              [8.25895, 50.00979],
              [8.25888, 50.0086],
              [8.2595, 50.00831],
              [8.26102, 50.0076],
              [8.261626, 50.00734],
              [8.26216, 50.00711],
              [8.26319, 50.00662],
              [8.26363, 50.00657],
              [8.26483, 50.00598],
              [8.26521, 50.00569],
              [8.2661, 50.00532],
              [8.26673, 50.00506],
              [8.26749, 50.00476],
              [8.26871, 50.00412],
              [8.2693, 50.00465],
              [8.26941, 50.00474],
              [8.27007, 50.00533],
              [8.27079, 50.00586],
              [8.27135, 50.00613],
              [8.27167, 50.0061],
              [8.27224, 50.005452],
              [8.27225, 50.00544],
              [8.27306, 50.00463],
              [8.27384, 50.00376],
              [8.27404, 50.00348],
              [8.27426, 50.00332],
              [8.27455, 50.00338],
              [8.27447, 50.00364],
              [8.27365, 50.00446],
              [8.27324, 50.00494],
              [8.27336, 50.00517],
              [8.27367, 50.00531],
              [8.27391, 50.00541],
              [8.27591, 50.00622],
              [8.27639, 50.00642],
              [8.27988, 50.00785],
              [8.2809, 50.00833],
              [8.28108, 50.00839],
              [8.28146, 50.00835],
              [8.28176, 50.00807],
              [8.28208, 50.00778],
              [8.28285, 50.00734],
              [8.28339, 50.00707],
              [8.283716, 50.006878],
              [8.28418, 50.00678],
              [8.28503, 50.00635],
              [8.28532, 50.00628],
              [8.2863, 50.00625],
              [8.28755, 50.00642],
              [8.28831, 50.00659],
              [8.28878, 50.00673],
              [8.28768, 50.00807],
              [8.2868, 50.009],
              [8.28635, 50.00924],
              [8.28487, 50.00977],
              [8.28427, 50.01],
              [8.283605, 50.010245],
              [8.28242, 50.01068],
              [8.28229, 50.01072],
              [8.28175, 50.011],
              [8.28167, 50.01121],
              [8.28168, 50.01129],
              [8.28179, 50.01146],
              [8.28185, 50.01164],
              [8.28189, 50.0118],
              [8.282004, 50.012093],
              [8.2822, 50.0126],
              [8.28245, 50.0131],
              [8.28257, 50.01335],
              [8.2831, 50.01451],
              [8.28336, 50.01487],
              [8.2838, 50.01533],
              [8.28393, 50.01546],
              [8.28413, 50.01563],
              [8.28522, 50.01653],
              [8.2857, 50.01698],
              [8.28689, 50.01849],
              [8.28783, 50.01969],
              [8.28841, 50.02044],
              [8.28862, 50.0208],
              [8.28904, 50.02197],
              [8.28942, 50.02315],
              [8.29006, 50.02433],
              [8.29073, 50.0253],
              [8.29102, 50.02583],
              [8.29107, 50.0259],
              [8.29156, 50.02649],
              [8.2923, 50.02759],
              [8.292918, 50.028677],
              [8.29337, 50.02947],
              [8.29356, 50.02975],
              [8.29364, 50.02986],
              [8.29382, 50.0301],
              [8.2942, 50.03069],
              [8.29478, 50.03157],
              [8.29524, 50.03251],
              [8.29554, 50.03326],
              [8.29616, 50.03474],
              [8.29665, 50.03568],
              [8.298, 50.03844],
              [8.29817, 50.03855],
              [8.29827, 50.03883],
              [8.29818, 50.03892],
              [8.29862, 50.04054],
              [8.29872, 50.04181],
              [8.29868, 50.04271],
              [8.29875, 50.0432],
              [8.29894, 50.04352],
              [8.29927, 50.04374],
              [8.3, 50.0442],
              [8.30021, 50.04454],
              [8.3003, 50.04494],
              [8.30091, 50.04507],
              [8.30136, 50.04511],
              [8.30311, 50.0451],
              [8.30624, 50.04467],
              [8.31311, 50.043154],
              [8.313475, 50.042795],
              [8.31444, 50.042299],
              [8.316157, 50.041914],
              [8.317895, 50.041528],
              [8.318903, 50.041252],
              [8.319333, 50.04099],
              [8.320105, 50.04037],
              [8.321843, 50.038964],
              [8.322594, 50.038647],
              [8.323903, 50.038275],
              [8.325706, 50.037862],
              [8.323903, 50.038275],
              [8.322594, 50.03862],
              [8.321843, 50.038951],
              [8.320148, 50.040342],
              [8.319333, 50.04099],
              [8.318903, 50.041238],
              [8.317895, 50.041514],
              [8.314419, 50.042313],
              [8.313453, 50.042795],
              [8.313131, 50.043154],
              [8.30624, 50.04467],
              [8.30311, 50.0451],
              [8.30136, 50.04511],
              [8.30091, 50.04507],
              [8.3003, 50.04494],
              [8.300272, 50.045307],
              [8.300658, 50.046139],
              [8.30139, 50.047566],
              [8.30171, 50.04839],
              [8.302481, 50.0484],
              [8.30495, 50.04843],
              [8.30801, 50.04877],
              [8.30704, 50.05062],
              [8.307126, 50.051938],
              [8.30465, 50.05326],
              [8.30433, 50.05313],
              [8.30262, 50.05332],
              [8.30228, 50.05341],
              [8.30134, 50.05374],
              [8.30126, 50.05388],
              [8.30106, 50.05387],
              [8.30079, 50.05403],
              [8.30044, 50.05423],
              [8.29903, 50.05507],
              [8.29648, 50.05603],
              [8.29652, 50.05624],
              [8.29602, 50.0579],
              [8.29598, 50.05806],
              [8.29595, 50.05852],
              [8.29607, 50.05925],
              [8.29603, 50.06014],
              [8.296, 50.06082],
              [8.29597, 50.06143],
              [8.29597, 50.0615],
              [8.29587, 50.06215],
              [8.2939, 50.06632],
              [8.291705, 50.070914],
              [8.29142, 50.07151],
              [8.29134, 50.07174],
              [8.2911, 50.07325],
              [8.29082, 50.07411],
              [8.28979, 50.076],
              [8.28843, 50.07833],
              [8.28806, 50.07874],
              [8.28782, 50.079],
              [8.28708, 50.07984],
              [8.28672, 50.08071],
              [8.28671, 50.08218],
              [8.28638, 50.08293],
              [8.28633, 50.08305],
              [8.28613, 50.08342],
              [8.28541, 50.0838],
              [8.28459, 50.08377],
              [8.28332, 50.08368],
              [8.28178, 50.08347],
              [8.28095, 50.08333],
              [8.28072, 50.08331],
              [8.28057, 50.0833],
              [8.27913, 50.08398],
              [8.27866, 50.08419],
              [8.27768, 50.084646],
              [8.27724, 50.08485],
              [8.27667, 50.08509],
              [8.27653, 50.08513],
              [8.27743, 50.0865],
              [8.2776, 50.08714],
              [8.27756, 50.0876],
              [8.27702, 50.08873],
              [8.27635, 50.08967],
              [8.27598, 50.09003],
              [8.27547, 50.09041],
              [8.27341, 50.0913],
              [8.27302, 50.0913],
              [8.27119, 50.09175],
              [8.26935, 50.09237],
              [8.26807, 50.09306],
              [8.26712, 50.0938],
              [8.26788, 50.0944],
              [8.26824, 50.09476],
              [8.26846, 50.09502],
              [8.26901, 50.09552],
              [8.26806, 50.09592],
              [8.26857, 50.09642],
              [8.26899, 50.09665],
              [8.26982, 50.0971],
              [8.26979, 50.09723],
              [8.269181, 50.097668],
              [8.2689, 50.09787],
              [8.26769, 50.09744],
              [8.26725, 50.09726],
              [8.26586, 50.09673],
              [8.26522, 50.09693],
              [8.26515, 50.09695],
              [8.26471, 50.0971],
              [8.26438, 50.09747],
              [8.26438, 50.09763],
              [8.26457, 50.09786],
              [8.26549, 50.09822],
              [8.2668, 50.099],
              [8.26704, 50.09924],
              [8.26738, 50.09976],
              [8.26724, 50.1003],
              [8.26666, 50.10085],
              [8.26608, 50.10159],
              [8.26578, 50.10176],
              [8.2654, 50.10183],
              [8.26417, 50.10217],
              [8.26416, 50.10183],
              [8.26423, 50.10168],
              [8.26442, 50.10102],
            ],
          ],
        },
        id: "A0NDc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.26442, 50.10102],
              [8.26501, 50.1013],
              [8.26529, 50.10167],
              [8.2654, 50.10183],
              [8.26578, 50.10176],
              [8.26608, 50.10159],
              [8.26666, 50.10085],
              [8.26724, 50.1003],
              [8.26738, 50.09976],
              [8.26704, 50.09924],
              [8.2668, 50.099],
              [8.26549, 50.09822],
              [8.26457, 50.09786],
              [8.26438, 50.09763],
              [8.26438, 50.09747],
              [8.26471, 50.0971],
              [8.26515, 50.09695],
              [8.26522, 50.09693],
              [8.26586, 50.09673],
              [8.26671, 50.09647],
              [8.26806, 50.09592],
              [8.26857, 50.09642],
              [8.26899, 50.09665],
              [8.26982, 50.0971],
              [8.26979, 50.09723],
              [8.269181, 50.097668],
              [8.2689, 50.09787],
              [8.26769, 50.09744],
              [8.26725, 50.09726],
              [8.26586, 50.09673],
              [8.26671, 50.09647],
              [8.26806, 50.09592],
              [8.26901, 50.09552],
              [8.26846, 50.09502],
              [8.26824, 50.09476],
              [8.26788, 50.0944],
              [8.26712, 50.0938],
              [8.26807, 50.09306],
              [8.26935, 50.09237],
              [8.27119, 50.09175],
              [8.27302, 50.0913],
              [8.27341, 50.0913],
              [8.27547, 50.09041],
              [8.27598, 50.09003],
              [8.27635, 50.08967],
              [8.27702, 50.08873],
              [8.27756, 50.0876],
              [8.2776, 50.08714],
              [8.27743, 50.0865],
              [8.27653, 50.08513],
              [8.27667, 50.08509],
              [8.27724, 50.08485],
              [8.27768, 50.084646],
              [8.27866, 50.08419],
              [8.27913, 50.08398],
              [8.28057, 50.0833],
              [8.28069, 50.08326],
              [8.28078, 50.08323],
              [8.28085, 50.0832],
              [8.28096, 50.08316],
              [8.28119, 50.08307],
              [8.28198, 50.08271],
              [8.28307, 50.08219],
              [8.28327, 50.08212],
              [8.28367, 50.08217],
              [8.28435, 50.08232],
              [8.28453, 50.08235],
              [8.28526, 50.08255],
              [8.28638, 50.08293],
              [8.28671, 50.08218],
              [8.28672, 50.08071],
              [8.28708, 50.07984],
              [8.28782, 50.079],
              [8.28806, 50.07874],
              [8.28843, 50.07833],
              [8.28979, 50.076],
              [8.29082, 50.07411],
              [8.2911, 50.07325],
              [8.29134, 50.07174],
              [8.29142, 50.07151],
              [8.291705, 50.070914],
              [8.2939, 50.06632],
              [8.29587, 50.06215],
              [8.29597, 50.0615],
              [8.29597, 50.06143],
              [8.296, 50.06082],
              [8.29603, 50.06014],
              [8.29607, 50.05925],
              [8.29595, 50.05852],
              [8.29598, 50.05806],
              [8.29602, 50.0579],
              [8.29652, 50.05624],
              [8.29648, 50.05603],
              [8.29903, 50.05507],
              [8.30044, 50.05423],
              [8.30079, 50.05403],
              [8.30106, 50.05387],
              [8.30126, 50.05388],
              [8.30134, 50.05374],
              [8.30228, 50.05341],
              [8.30262, 50.05332],
              [8.30433, 50.05313],
              [8.30465, 50.05326],
              [8.307126, 50.051938],
              [8.30704, 50.05062],
              [8.30801, 50.04877],
              [8.30495, 50.04843],
              [8.302481, 50.0484],
              [8.30171, 50.04839],
              [8.30139, 50.047566],
              [8.300658, 50.046139],
              [8.300272, 50.045307],
              [8.300321, 50.044959],
              [8.301158, 50.045097],
              [8.303132, 50.045111],
              [8.306243, 50.044656],
              [8.313174, 50.04314],
              [8.313432, 50.042809],
              [8.31444, 50.042286],
              [8.319011, 50.041238],
              [8.321822, 50.038951],
              [8.322594, 50.03862],
              [8.325684, 50.037862],
              [8.322573, 50.038634],
              [8.3218, 50.038951],
              [8.318968, 50.041266],
              [8.314483, 50.042272],
              [8.31341, 50.042809],
              [8.313174, 50.04314],
              [8.306222, 50.044656],
              [8.303111, 50.045124],
              [8.301179, 50.045097],
              [8.300321, 50.044959],
              [8.29956, 50.04476],
              [8.29892, 50.04459],
              [8.29751, 50.044],
              [8.29642, 50.04352],
              [8.29659, 50.04324],
              [8.29748, 50.04268],
              [8.29811, 50.04246],
              [8.29848, 50.04215],
              [8.29856, 50.04183],
              [8.29844, 50.04052],
              [8.29803, 50.03894],
              [8.29781, 50.03889],
              [8.29772, 50.0386],
              [8.29785, 50.03846],
              [8.29649, 50.03572],
              [8.29583, 50.03445],
              [8.29539, 50.03326],
              [8.2952, 50.03279],
              [8.29483, 50.03202],
              [8.29405, 50.03083],
              [8.29355, 50.03014],
              [8.29349, 50.02997],
              [8.2934, 50.02977],
              [8.29337, 50.02947],
              [8.292918, 50.028677],
              [8.2923, 50.02759],
              [8.29156, 50.02649],
              [8.29107, 50.0259],
              [8.29102, 50.02583],
              [8.29073, 50.0253],
              [8.29006, 50.02433],
              [8.28942, 50.02315],
              [8.28904, 50.02197],
              [8.28862, 50.0208],
              [8.28841, 50.02044],
              [8.28783, 50.01969],
              [8.28689, 50.01849],
              [8.2857, 50.01698],
              [8.28522, 50.01653],
              [8.28413, 50.01563],
              [8.28393, 50.01546],
              [8.2838, 50.01533],
              [8.28336, 50.01487],
              [8.2831, 50.01451],
              [8.28257, 50.01335],
              [8.28245, 50.0131],
              [8.2822, 50.0126],
              [8.282004, 50.012093],
              [8.28189, 50.0118],
              [8.28185, 50.01164],
              [8.28179, 50.01146],
              [8.28168, 50.01129],
              [8.28167, 50.01121],
              [8.28175, 50.011],
              [8.28229, 50.01072],
              [8.28242, 50.01068],
              [8.283605, 50.010245],
              [8.28427, 50.01],
              [8.28487, 50.00977],
              [8.28635, 50.00924],
              [8.2868, 50.009],
              [8.28768, 50.00807],
              [8.28878, 50.00673],
              [8.28831, 50.00659],
              [8.28755, 50.00642],
              [8.2863, 50.00625],
              [8.28532, 50.00628],
              [8.28503, 50.00635],
              [8.28418, 50.00678],
              [8.283716, 50.006878],
              [8.28339, 50.00707],
              [8.28285, 50.00734],
              [8.28208, 50.00778],
              [8.28176, 50.00807],
              [8.28146, 50.00835],
              [8.28108, 50.00839],
              [8.2809, 50.00833],
              [8.27988, 50.00785],
              [8.27981, 50.00787],
              [8.2797, 50.00783],
              [8.27634, 50.00647],
              [8.27589, 50.00628],
              [8.27483, 50.00585],
              [8.27388, 50.00546],
              [8.2733, 50.00523],
              [8.27292, 50.00526],
              [8.27284, 50.00532],
              [8.27241, 50.0058],
              [8.27202, 50.00622],
              [8.2719, 50.00636],
              [8.27152, 50.00639],
              [8.27135, 50.00634],
              [8.271064, 50.006095],
              [8.27079, 50.00586],
              [8.27007, 50.00533],
              [8.26941, 50.00474],
              [8.2693, 50.00465],
              [8.26871, 50.00412],
              [8.26749, 50.00476],
              [8.26673, 50.00506],
              [8.2661, 50.00532],
              [8.26521, 50.00569],
              [8.26483, 50.00598],
              [8.26363, 50.00657],
              [8.26319, 50.00662],
              [8.26216, 50.00711],
              [8.261626, 50.00734],
              [8.26102, 50.0076],
              [8.2595, 50.00831],
              [8.25888, 50.0086],
              [8.25895, 50.00979],
              [8.25895, 50.0099],
              [8.2588, 50.00998],
              [8.25945, 50.0104],
              [8.25903, 50.01064],
              [8.25786, 50.01117],
              [8.257564, 50.011343],
              [8.25597, 50.01227],
              [8.25579, 50.01236],
              [8.25526, 50.01191],
              [8.25462, 50.01132],
              [8.25345, 50.01036],
              [8.25256, 50.00965],
              [8.25206, 50.00928],
              [8.2519, 50.00916],
              [8.25079, 50.01008],
              [8.2502, 50.01056],
              [8.24955, 50.01116],
              [8.24903, 50.01192],
              [8.24897, 50.01218],
              [8.24894, 50.0125],
              [8.2487, 50.01254],
            ],
          ],
        },
        id: "AyODI",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "g4ODU",
    name: "29 Nordfriedhof <> Hauptbahnhof",
    lineName: "29",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24499, 50.07137],
              [8.24521, 50.07105],
              [8.2459, 50.07106],
              [8.24603, 50.0709],
              [8.2459, 50.07089],
              [8.24603, 50.0709],
              [8.24604, 50.0707],
              [8.24604, 50.07065],
              [8.24605, 50.07052],
              [8.24608, 50.07029],
              [8.24657, 50.06915],
              [8.24693, 50.06863],
              [8.2477, 50.06781],
              [8.24773, 50.06783],
              [8.24799, 50.06779],
              [8.24926, 50.06801],
              [8.24938, 50.06803],
              [8.24926, 50.06801],
              [8.24992, 50.06671],
              [8.25007, 50.06672],
              [8.25131, 50.06699],
              [8.251328, 50.066994],
              [8.25224, 50.06719],
              [8.25458, 50.06769],
              [8.25661, 50.0681],
              [8.25783, 50.06699],
              [8.25896, 50.06597],
              [8.25922, 50.06562],
              [8.26044, 50.06358],
              [8.259471, 50.063378],
              [8.259, 50.06328],
              [8.2575, 50.06298],
              [8.25528, 50.06252],
              [8.25525, 50.06241],
              [8.25538, 50.0623],
              [8.25575, 50.06164],
              [8.255774, 50.061604],
              [8.25701, 50.0597],
              [8.25813, 50.05839],
              [8.25748, 50.05827],
              [8.25687, 50.05822],
              [8.25567, 50.05824],
              [8.254871, 50.05836],
              [8.2548, 50.05837],
              [8.254, 50.05851],
              [8.25383, 50.0585],
              [8.25083, 50.05772],
              [8.25063, 50.05775],
              [8.25024, 50.05786],
              [8.25004, 50.05799],
              [8.24967, 50.05849],
              [8.24919, 50.05972],
              [8.24835, 50.06087],
              [8.24763, 50.06156],
              [8.24681, 50.06212],
              [8.24614, 50.0623],
              [8.24212, 50.06155],
              [8.24197, 50.06154],
              [8.24098, 50.06145],
              [8.23998, 50.06139],
              [8.23966, 50.0615],
              [8.23851, 50.06213],
              [8.23822, 50.06229],
              [8.237604, 50.062628],
              [8.23749, 50.06269],
              [8.23461, 50.0643],
              [8.23438, 50.0645],
              [8.23251, 50.0668],
              [8.232187, 50.067155],
              [8.232, 50.06736],
              [8.22885, 50.06939],
              [8.228524, 50.069651],
              [8.22732, 50.07069],
              [8.22658, 50.07144],
              [8.22573, 50.07235],
              [8.2248, 50.07333],
              [8.22412, 50.07409],
              [8.22372, 50.07443],
              [8.22355, 50.07462],
              [8.22315, 50.07508],
              [8.22246, 50.07563],
              [8.22193, 50.07602],
              [8.22151, 50.07637],
              [8.22076, 50.07706],
              [8.220629, 50.077223],
              [8.22051, 50.07737],
              [8.2202, 50.07792],
              [8.22009, 50.0781],
              [8.21976, 50.07867],
              [8.21966, 50.07894],
              [8.21924, 50.0796],
              [8.21869, 50.08067],
              [8.21878, 50.08122],
              [8.21878, 50.08139],
              [8.21884, 50.08148],
              [8.21935, 50.08198],
              [8.21996, 50.08234],
              [8.2206, 50.08256],
              [8.22087, 50.08261],
              [8.22241, 50.08265],
              [8.22283, 50.08263],
              [8.22357, 50.08264],
              [8.22383, 50.08284],
              [8.223996, 50.08309],
              [8.22489, 50.08353],
              [8.22493, 50.08423],
              [8.225, 50.08456],
              [8.2254, 50.0853],
              [8.22547, 50.08541],
              [8.2254, 50.08548],
              [8.2247, 50.08588],
              [8.224601, 50.086148],
              [8.2246, 50.08615],
              [8.22331, 50.08707],
              [8.22287, 50.08733],
              [8.2228, 50.08738],
              [8.22268, 50.08745],
              [8.22187, 50.08794],
              [8.2216, 50.08812],
              [8.22054, 50.0889],
              [8.22045, 50.08906],
              [8.2205, 50.08932],
              [8.220784, 50.089683],
              [8.22079, 50.08969],
              [8.22125, 50.08998],
              [8.2213, 50.09002],
              [8.22145, 50.09032],
              [8.2214, 50.09055],
              [8.22027, 50.09161],
              [8.21919, 50.09309],
              [8.21898, 50.09331],
              [8.21845, 50.09374],
              [8.21812, 50.09394],
              [8.21763, 50.09421],
              [8.21691, 50.09437],
              [8.21633, 50.09443],
              [8.21615, 50.09464],
              [8.21647, 50.09485],
              [8.21739, 50.0948],
              [8.21785, 50.09506],
              [8.21833, 50.0955],
              [8.21848, 50.09563],
              [8.21887, 50.09596],
              [8.219277, 50.096281],
              [8.21972, 50.09663],
              [8.22025, 50.09698],
              [8.22089, 50.09707],
              [8.22117, 50.09721],
              [8.22116, 50.09698],
            ],
          ],
        },
        id: "g4ODU",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.22116, 50.09698],
              [8.22117, 50.09721],
              [8.22089, 50.09707],
              [8.22025, 50.09698],
              [8.21972, 50.09663],
              [8.219277, 50.096281],
              [8.21887, 50.09596],
              [8.21848, 50.09563],
              [8.21833, 50.0955],
              [8.21785, 50.09506],
              [8.21739, 50.0948],
              [8.21647, 50.09485],
              [8.21615, 50.09464],
              [8.21633, 50.09443],
              [8.21691, 50.09437],
              [8.21763, 50.09421],
              [8.21812, 50.09394],
              [8.21845, 50.09374],
              [8.21898, 50.09331],
              [8.21919, 50.09309],
              [8.22027, 50.09161],
              [8.2214, 50.09055],
              [8.22145, 50.09032],
              [8.2213, 50.09002],
              [8.22125, 50.08998],
              [8.22079, 50.08969],
              [8.220784, 50.089683],
              [8.2205, 50.08932],
              [8.22045, 50.08906],
              [8.22054, 50.0889],
              [8.2216, 50.08812],
              [8.22187, 50.08794],
              [8.22268, 50.08745],
              [8.2228, 50.08738],
              [8.22287, 50.08733],
              [8.22331, 50.08707],
              [8.2246, 50.08615],
              [8.224601, 50.086148],
              [8.2247, 50.08588],
              [8.2254, 50.08548],
              [8.22547, 50.08541],
              [8.2254, 50.0853],
              [8.225, 50.08456],
              [8.22493, 50.08423],
              [8.22489, 50.08353],
              [8.223996, 50.08309],
              [8.22383, 50.08284],
              [8.22357, 50.08264],
              [8.22283, 50.08263],
              [8.22241, 50.08265],
              [8.22087, 50.08261],
              [8.2206, 50.08256],
              [8.21996, 50.08234],
              [8.21935, 50.08198],
              [8.21884, 50.08148],
              [8.21878, 50.08139],
              [8.21878, 50.08122],
              [8.21869, 50.08067],
              [8.21924, 50.0796],
              [8.21966, 50.07894],
              [8.21976, 50.07867],
              [8.22009, 50.0781],
              [8.2202, 50.07792],
              [8.22051, 50.07737],
              [8.220629, 50.077223],
              [8.22076, 50.07706],
              [8.22151, 50.07637],
              [8.22193, 50.07602],
              [8.22246, 50.07563],
              [8.22315, 50.07508],
              [8.22355, 50.07462],
              [8.22372, 50.07443],
              [8.22412, 50.07409],
              [8.2248, 50.07333],
              [8.22573, 50.07235],
              [8.22658, 50.07144],
              [8.22732, 50.07069],
              [8.228524, 50.069651],
              [8.22885, 50.06939],
              [8.232, 50.06736],
              [8.232187, 50.067155],
              [8.23251, 50.0668],
              [8.23438, 50.0645],
              [8.23461, 50.0643],
              [8.23749, 50.06269],
              [8.237604, 50.062628],
              [8.23822, 50.06229],
              [8.23851, 50.06213],
              [8.23966, 50.0615],
              [8.23998, 50.06139],
              [8.24098, 50.06145],
              [8.24197, 50.06154],
              [8.24212, 50.06155],
              [8.24614, 50.0623],
              [8.24681, 50.06212],
              [8.24763, 50.06156],
              [8.24835, 50.06087],
              [8.24919, 50.05972],
              [8.24967, 50.05849],
              [8.25004, 50.05799],
              [8.25024, 50.05786],
              [8.25063, 50.05775],
              [8.25083, 50.05772],
              [8.25383, 50.0585],
              [8.254, 50.05851],
              [8.2548, 50.05837],
              [8.254871, 50.05836],
              [8.25567, 50.05824],
              [8.25687, 50.05822],
              [8.25748, 50.05827],
              [8.25813, 50.05839],
              [8.25701, 50.0597],
              [8.255774, 50.061604],
              [8.25575, 50.06164],
              [8.25538, 50.0623],
              [8.25525, 50.06241],
              [8.25528, 50.06252],
              [8.2575, 50.06298],
              [8.259, 50.06328],
              [8.259471, 50.063378],
              [8.260431, 50.063586],
              [8.25922, 50.06562],
              [8.25896, 50.06597],
              [8.25783, 50.06699],
              [8.25661, 50.0681],
              [8.25458, 50.06769],
              [8.25224, 50.06719],
              [8.251328, 50.066994],
              [8.25131, 50.06699],
              [8.25007, 50.06672],
              [8.24992, 50.06671],
              [8.24926, 50.06801],
              [8.24938, 50.06803],
              [8.24926, 50.06801],
              [8.24799, 50.06779],
              [8.24773, 50.06783],
              [8.2477, 50.06781],
              [8.24693, 50.06863],
              [8.24657, 50.06915],
              [8.24608, 50.07029],
              [8.24605, 50.07052],
              [8.24604, 50.07065],
              [8.24604, 50.0707],
              [8.24603, 50.0709],
              [8.2459, 50.07089],
              [8.24603, 50.0709],
              [8.2459, 50.07106],
              [8.24521, 50.07105],
              [8.24499, 50.07137],
              [8.24495, 50.07161],
              [8.24475, 50.07159],
              [8.243761, 50.071537],
            ],
          ],
        },
        id: "EzODc",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "gzMTU",
    name: "19 Kurhaus <> Delkenheim",
    lineName: "19",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.35479, 50.03686],
              [8.35519, 50.03775],
              [8.35545, 50.03833],
              [8.35564, 50.03874],
              [8.3568, 50.03837],
              [8.35752, 50.03802],
              [8.35774, 50.03773],
              [8.35814, 50.03729],
              [8.35832, 50.03726],
              [8.3592, 50.03757],
              [8.35957, 50.03772],
              [8.36042, 50.038],
              [8.36145, 50.03803],
              [8.36231, 50.03809],
              [8.36178, 50.03866],
              [8.36129, 50.03897],
              [8.3611, 50.03925],
              [8.36146, 50.03972],
              [8.36254, 50.0414],
              [8.36153, 50.04187],
              [8.36042, 50.04245],
              [8.35985, 50.04302],
              [8.35921, 50.04385],
              [8.3587, 50.04431],
              [8.35866, 50.04446],
              [8.35971, 50.04536],
              [8.3596, 50.04553],
              [8.35835, 50.0455],
              [8.35743, 50.04794],
              [8.35738, 50.04886],
              [8.35781, 50.05056],
              [8.35765, 50.05147],
              [8.35733, 50.05203],
              [8.3566, 50.05274],
              [8.35541, 50.0534],
              [8.35214, 50.05487],
              [8.35164, 50.0551],
              [8.35057, 50.05548],
              [8.34392, 50.05553],
              [8.34329, 50.0555],
              [8.34251, 50.05522],
              [8.34233, 50.05513],
              [8.34197, 50.05493],
              [8.34185, 50.05488],
              [8.34136, 50.05477],
              [8.34055, 50.05488],
              [8.33997, 50.05527],
              [8.33985, 50.05571],
              [8.34008, 50.05611],
              [8.34045, 50.05646],
              [8.34102, 50.05702],
              [8.34121, 50.05731],
              [8.34144, 50.05789],
              [8.34209, 50.05908],
              [8.34264, 50.05962],
              [8.344158, 50.060618],
              [8.34603, 50.06185],
              [8.34615, 50.06195],
              [8.34755, 50.06329],
              [8.34798, 50.0637],
              [8.34845, 50.0642],
              [8.34774, 50.06427],
              [8.34669, 50.06437],
              [8.34625, 50.06441],
              [8.34521, 50.06524],
              [8.34414, 50.06569],
              [8.343644, 50.065838],
              [8.3431, 50.066],
              [8.34292, 50.06603],
              [8.34263, 50.06602],
              [8.33984, 50.06642],
              [8.34016, 50.06703],
              [8.34042, 50.06735],
              [8.33875, 50.06782],
              [8.338416, 50.067914],
              [8.33697, 50.06832],
              [8.33566, 50.06867],
              [8.33488, 50.06889],
              [8.33471, 50.06887],
              [8.334108, 50.067956],
              [8.33396, 50.06773],
              [8.33402, 50.06764],
              [8.33388, 50.06759],
              [8.33302, 50.06629],
              [8.3327, 50.06586],
              [8.331989, 50.064709],
              [8.330026, 50.061527],
              [8.3296, 50.06161],
              [8.32765, 50.06198],
              [8.32313, 50.06285],
              [8.32128, 50.06317],
              [8.31924, 50.06335],
              [8.318013, 50.063769],
              [8.31675, 50.0642],
              [8.31475, 50.06208],
              [8.31197, 50.06048],
              [8.31092, 50.0599],
              [8.3095, 50.0588],
              [8.30931, 50.05866],
              [8.30861, 50.05811],
              [8.30843, 50.05795],
              [8.30832, 50.05782],
              [8.30823, 50.05771],
              [8.30782, 50.05719],
              [8.30756, 50.05684],
              [8.30742, 50.05662],
              [8.3074, 50.05626],
              [8.307443, 50.056],
              [8.3075, 50.05566],
              [8.30745, 50.05564],
              [8.30731, 50.05561],
              [8.307, 50.05556],
              [8.30667, 50.0555],
              [8.3056, 50.05533],
              [8.30534, 50.05529],
              [8.3054, 50.05481],
              [8.30465, 50.05326],
              [8.30433, 50.05313],
              [8.30262, 50.05332],
              [8.30228, 50.05341],
              [8.30134, 50.05374],
              [8.30126, 50.05388],
              [8.30106, 50.05387],
              [8.30079, 50.05403],
              [8.30044, 50.05423],
              [8.29903, 50.05507],
              [8.29648, 50.05603],
              [8.29652, 50.05624],
              [8.29602, 50.0579],
              [8.29598, 50.05806],
              [8.29595, 50.05852],
              [8.29607, 50.05925],
              [8.29603, 50.06014],
              [8.296, 50.06082],
              [8.29597, 50.06143],
              [8.29376, 50.06211],
              [8.29313, 50.06215],
              [8.29142, 50.06171],
              [8.29036, 50.05959],
              [8.288623, 50.058902],
              [8.28739, 50.05935],
              [8.2865, 50.05968],
              [8.28631, 50.05975],
              [8.28557, 50.06002],
              [8.28398, 50.06059],
              [8.28312, 50.06079],
              [8.28263, 50.06098],
              [8.28017, 50.06211],
              [8.27644, 50.06349],
              [8.27592, 50.06388],
              [8.27584, 50.06414],
              [8.27609, 50.06458],
              [8.27632, 50.065],
              [8.27622, 50.06508],
              [8.2759, 50.06533],
              [8.27573, 50.06537],
              [8.27528, 50.06527],
              [8.27472, 50.06458],
              [8.27379, 50.06344],
              [8.27373, 50.06336],
              [8.27305, 50.06258],
              [8.27281, 50.06239],
              [8.272768, 50.06237],
              [8.27227, 50.06213],
              [8.27222, 50.06205],
              [8.27207, 50.06204],
              [8.27188, 50.0619],
              [8.26826, 50.06088],
              [8.26816, 50.06085],
              [8.26748, 50.06067],
              [8.26731, 50.06062],
              [8.26483, 50.05996],
              [8.2621, 50.05925],
              [8.26194, 50.05921],
              [8.26147, 50.05909],
              [8.260677, 50.058923],
              [8.26024, 50.05883],
              [8.26008, 50.0588],
              [8.25948, 50.05868],
              [8.25813, 50.05839],
              [8.25748, 50.05827],
              [8.25687, 50.05822],
              [8.25567, 50.05824],
              [8.254871, 50.05836],
              [8.2548, 50.05837],
              [8.254, 50.05851],
              [8.25383, 50.0585],
              [8.25083, 50.05772],
              [8.25063, 50.05775],
              [8.25024, 50.05786],
              [8.25004, 50.05799],
              [8.24967, 50.05849],
              [8.24919, 50.05972],
              [8.24835, 50.06087],
              [8.24763, 50.06156],
              [8.24681, 50.06212],
              [8.24614, 50.0623],
              [8.24212, 50.06155],
              [8.24197, 50.06154],
              [8.24098, 50.06145],
              [8.23998, 50.06139],
              [8.23966, 50.0615],
              [8.23851, 50.06213],
              [8.23822, 50.06229],
              [8.237604, 50.062628],
              [8.23749, 50.06269],
              [8.23461, 50.0643],
              [8.23438, 50.0645],
              [8.23251, 50.0668],
              [8.232187, 50.067155],
              [8.232, 50.06736],
              [8.22885, 50.06939],
              [8.228524, 50.069651],
              [8.22732, 50.07069],
              [8.22658, 50.07144],
              [8.22573, 50.07235],
              [8.2248, 50.07333],
              [8.22412, 50.07409],
              [8.22372, 50.07443],
              [8.22355, 50.07462],
              [8.22315, 50.07508],
              [8.22246, 50.07563],
              [8.22193, 50.07602],
              [8.22151, 50.07637],
              [8.22076, 50.07706],
              [8.220629, 50.077223],
              [8.22051, 50.07737],
              [8.2202, 50.07792],
              [8.22009, 50.0781],
              [8.21976, 50.07867],
              [8.21966, 50.07894],
              [8.21924, 50.0796],
              [8.21869, 50.08067],
              [8.21878, 50.08122],
              [8.21878, 50.08139],
              [8.21884, 50.08148],
              [8.21935, 50.08198],
              [8.21996, 50.08234],
              [8.2206, 50.08256],
              [8.22087, 50.08261],
              [8.22241, 50.08265],
              [8.22283, 50.08263],
              [8.22357, 50.08264],
              [8.22383, 50.08284],
              [8.223996, 50.08309],
              [8.22489, 50.08353],
              [8.22493, 50.08423],
              [8.225, 50.08456],
              [8.2254, 50.0853],
              [8.22547, 50.08541],
              [8.22568, 50.0856],
              [8.226101, 50.085576],
              [8.22709, 50.08552],
              [8.22741, 50.08538],
              [8.22796, 50.0851],
              [8.22842, 50.08487],
              [8.22927, 50.08448],
              [8.22941, 50.08442],
              [8.22974, 50.08431],
              [8.23042, 50.08417],
              [8.23086, 50.0841],
              [8.23147, 50.08399],
              [8.23155, 50.08398],
              [8.23253, 50.08382],
              [8.23274, 50.08378],
              [8.23435, 50.08355],
              [8.23581, 50.08327],
              [8.23582, 50.08338],
              [8.23593, 50.08379],
              [8.23583, 50.08524],
              [8.23583, 50.08531],
              [8.23568, 50.08665],
              [8.23573, 50.08668],
              [8.23673, 50.08739],
              [8.23731, 50.08774],
              [8.23774, 50.08803],
              [8.23801, 50.08821],
              [8.23832, 50.08841],
              [8.23872, 50.08869],
              [8.23936, 50.08911],
              [8.24103, 50.08805],
              [8.24334, 50.08656],
              [8.24407, 50.08616],
              [8.24439, 50.08601],
              [8.24437, 50.0857],
              [8.24439, 50.08537],
              [8.24444, 50.08499],
              [8.24446, 50.08483],
              [8.24451, 50.08446],
              [8.24452, 50.08436],
              [8.24453, 50.0843],
              [8.24461, 50.08374],
            ],
          ],
        },
        id: "gzMTU",
        direction: "INBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.24461, 50.08374],
              [8.24303, 50.0837],
              [8.24282, 50.08385],
              [8.24277, 50.08414],
              [8.24261, 50.08446],
              [8.24198, 50.08471],
              [8.24182, 50.08476],
              [8.24095, 50.08511],
              [8.24145, 50.08545],
              [8.24198, 50.08577],
              [8.24243, 50.08605],
              [8.24334, 50.08656],
              [8.24103, 50.08805],
              [8.23936, 50.08911],
              [8.23872, 50.08869],
              [8.23832, 50.08841],
              [8.23801, 50.08821],
              [8.23774, 50.08803],
              [8.23731, 50.08774],
              [8.23673, 50.08739],
              [8.23573, 50.08668],
              [8.23568, 50.08665],
              [8.23583, 50.08531],
              [8.23583, 50.08524],
              [8.23593, 50.08379],
              [8.23582, 50.08338],
              [8.23581, 50.08327],
              [8.23435, 50.08355],
              [8.23274, 50.08378],
              [8.23253, 50.08382],
              [8.23155, 50.08398],
              [8.23147, 50.08399],
              [8.23086, 50.0841],
              [8.23042, 50.08417],
              [8.22974, 50.08431],
              [8.22941, 50.08442],
              [8.22927, 50.08448],
              [8.22842, 50.08487],
              [8.22796, 50.0851],
              [8.22741, 50.08538],
              [8.22709, 50.08552],
              [8.226101, 50.085576],
              [8.22568, 50.0856],
              [8.22547, 50.08541],
              [8.2254, 50.0853],
              [8.225, 50.08456],
              [8.22493, 50.08423],
              [8.22489, 50.08353],
              [8.223996, 50.08309],
              [8.22383, 50.08284],
              [8.22357, 50.08264],
              [8.22283, 50.08263],
              [8.22241, 50.08265],
              [8.22087, 50.08261],
              [8.2206, 50.08256],
              [8.21996, 50.08234],
              [8.21935, 50.08198],
              [8.21884, 50.08148],
              [8.21878, 50.08139],
              [8.21878, 50.08122],
              [8.21869, 50.08067],
              [8.21924, 50.0796],
              [8.21966, 50.07894],
              [8.21976, 50.07867],
              [8.22009, 50.0781],
              [8.2202, 50.07792],
              [8.22051, 50.07737],
              [8.220629, 50.077223],
              [8.22076, 50.07706],
              [8.22151, 50.07637],
              [8.22193, 50.07602],
              [8.22246, 50.07563],
              [8.22315, 50.07508],
              [8.22355, 50.07462],
              [8.22372, 50.07443],
              [8.22412, 50.07409],
              [8.2248, 50.07333],
              [8.22573, 50.07235],
              [8.22658, 50.07144],
              [8.22732, 50.07069],
              [8.228524, 50.069651],
              [8.22885, 50.06939],
              [8.232, 50.06736],
              [8.232187, 50.067155],
              [8.23251, 50.0668],
              [8.23438, 50.0645],
              [8.23461, 50.0643],
              [8.23749, 50.06269],
              [8.237604, 50.062628],
              [8.23822, 50.06229],
              [8.23851, 50.06213],
              [8.23966, 50.0615],
              [8.23998, 50.06139],
              [8.24098, 50.06145],
              [8.24197, 50.06154],
              [8.24212, 50.06155],
              [8.24614, 50.0623],
              [8.24681, 50.06212],
              [8.24763, 50.06156],
              [8.24835, 50.06087],
              [8.24919, 50.05972],
              [8.24967, 50.05849],
              [8.25004, 50.05799],
              [8.25024, 50.05786],
              [8.25063, 50.05775],
              [8.25083, 50.05772],
              [8.25383, 50.0585],
              [8.254, 50.05851],
              [8.2548, 50.05837],
              [8.254871, 50.05836],
              [8.25567, 50.05824],
              [8.25687, 50.05822],
              [8.25748, 50.05827],
              [8.25813, 50.05839],
              [8.25948, 50.05868],
              [8.26008, 50.0588],
              [8.26024, 50.05883],
              [8.260677, 50.058923],
              [8.26147, 50.05909],
              [8.26194, 50.05921],
              [8.2621, 50.05925],
              [8.26483, 50.05996],
              [8.26731, 50.06062],
              [8.26748, 50.06067],
              [8.26816, 50.06085],
              [8.26826, 50.06088],
              [8.27188, 50.0619],
              [8.27207, 50.06204],
              [8.27222, 50.06205],
              [8.27227, 50.06213],
              [8.272768, 50.06237],
              [8.27281, 50.06239],
              [8.27305, 50.06258],
              [8.27373, 50.06336],
              [8.27379, 50.06344],
              [8.27276, 50.06382],
              [8.27253, 50.06417],
              [8.27264, 50.06436],
              [8.27298, 50.06446],
              [8.27346, 50.06446],
              [8.27435, 50.06412],
              [8.27631, 50.06341],
              [8.27966, 50.06219],
              [8.28254, 50.0609],
              [8.28415, 50.0598],
              [8.2846, 50.05906],
              [8.2845, 50.05821],
              [8.28451, 50.05771],
              [8.28482, 50.05726],
              [8.28489, 50.05718],
              [8.28563, 50.05675],
              [8.28659, 50.05657],
              [8.28677, 50.05657],
              [8.28747, 50.05666],
              [8.28856, 50.05714],
              [8.28958, 50.05807],
              [8.28992, 50.05843],
              [8.29007, 50.05868],
              [8.29036, 50.05959],
              [8.29142, 50.06171],
              [8.29313, 50.06215],
              [8.29376, 50.06211],
              [8.29597, 50.06143],
              [8.296, 50.06082],
              [8.29603, 50.06014],
              [8.29607, 50.05925],
              [8.29595, 50.05852],
              [8.29598, 50.05806],
              [8.29602, 50.0579],
              [8.29652, 50.05624],
              [8.29648, 50.05603],
              [8.29903, 50.05507],
              [8.30044, 50.05423],
              [8.30079, 50.05403],
              [8.30106, 50.05387],
              [8.30126, 50.05388],
              [8.30134, 50.05374],
              [8.30228, 50.05341],
              [8.30262, 50.05332],
              [8.30433, 50.05313],
              [8.30465, 50.05326],
              [8.3054, 50.05481],
              [8.30567, 50.0548],
              [8.3054, 50.05481],
              [8.30534, 50.05529],
              [8.3056, 50.05533],
              [8.30667, 50.0555],
              [8.307, 50.05556],
              [8.30731, 50.05561],
              [8.30745, 50.05564],
              [8.3075, 50.05566],
              [8.307443, 50.056],
              [8.3074, 50.05626],
              [8.30742, 50.05662],
              [8.30756, 50.05684],
              [8.30782, 50.05719],
              [8.30823, 50.05771],
              [8.30832, 50.05782],
              [8.30843, 50.05795],
              [8.30861, 50.05811],
              [8.30931, 50.05866],
              [8.3095, 50.0588],
              [8.31092, 50.0599],
              [8.31197, 50.06048],
              [8.31475, 50.06208],
              [8.31675, 50.0642],
              [8.318013, 50.063769],
              [8.31924, 50.06335],
              [8.32128, 50.06317],
              [8.32313, 50.06285],
              [8.32765, 50.06198],
              [8.3296, 50.06161],
              [8.330026, 50.061527],
              [8.331989, 50.064709],
              [8.3327, 50.06586],
              [8.33302, 50.06629],
              [8.33388, 50.06759],
              [8.33402, 50.06764],
              [8.33612, 50.06706],
              [8.33741, 50.0667],
              [8.33777, 50.06661],
              [8.3382, 50.06651],
              [8.33885, 50.06631],
              [8.339295, 50.066202],
              [8.33967, 50.06611],
              [8.33984, 50.06642],
              [8.34263, 50.06602],
              [8.34292, 50.06603],
              [8.3431, 50.066],
              [8.343644, 50.065838],
              [8.34414, 50.06569],
              [8.34521, 50.06524],
              [8.34625, 50.06441],
              [8.34669, 50.06437],
              [8.34774, 50.06427],
              [8.34845, 50.0642],
              [8.34798, 50.0637],
              [8.34755, 50.06329],
              [8.34615, 50.06195],
              [8.34603, 50.06185],
              [8.344158, 50.060618],
              [8.34264, 50.05962],
              [8.34209, 50.05908],
              [8.34144, 50.05789],
              [8.34121, 50.05731],
              [8.34102, 50.05702],
              [8.34045, 50.05646],
              [8.34008, 50.05611],
              [8.33985, 50.05571],
              [8.33997, 50.05527],
              [8.34055, 50.05488],
              [8.34136, 50.05477],
              [8.34185, 50.05488],
              [8.34197, 50.05493],
              [8.34233, 50.05513],
              [8.34251, 50.05522],
              [8.34329, 50.0555],
              [8.34392, 50.05553],
              [8.35057, 50.05548],
              [8.35164, 50.0551],
              [8.35214, 50.05487],
              [8.35541, 50.0534],
              [8.3566, 50.05274],
              [8.35733, 50.05203],
              [8.35765, 50.05147],
              [8.35781, 50.05056],
              [8.35738, 50.04886],
              [8.35743, 50.04794],
              [8.35835, 50.0455],
              [8.3596, 50.04553],
              [8.35971, 50.04536],
              [8.35866, 50.04446],
              [8.3587, 50.04431],
              [8.35921, 50.04385],
              [8.35985, 50.04302],
              [8.36042, 50.04245],
              [8.36153, 50.04187],
              [8.36254, 50.0414],
              [8.36146, 50.03972],
              [8.3611, 50.03925],
              [8.36042, 50.03941],
              [8.36025, 50.03915],
              [8.36002, 50.03899],
              [8.35752, 50.03802],
              [8.3568, 50.03837],
              [8.35564, 50.03874],
              [8.35545, 50.03833],
              [8.35519, 50.03775],
              [8.35479, 50.03686],
            ],
          ],
        },
        id: "I1MjI",
        direction: "OUTBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "MxMDc",
    name: "18 Tierpark <> Sonnenberg",
    lineName: "18",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.26442, 50.10102],
              [8.26501, 50.1013],
              [8.26529, 50.10167],
              [8.2654, 50.10183],
              [8.26578, 50.10176],
              [8.26608, 50.10159],
              [8.26666, 50.10085],
              [8.26724, 50.1003],
              [8.26738, 50.09976],
              [8.26704, 50.09924],
              [8.2668, 50.099],
              [8.26549, 50.09822],
              [8.26457, 50.09786],
              [8.26438, 50.09763],
              [8.26438, 50.09747],
              [8.26471, 50.0971],
              [8.26515, 50.09695],
              [8.26522, 50.09693],
              [8.26586, 50.09673],
              [8.26671, 50.09647],
              [8.26806, 50.09592],
              [8.26901, 50.09552],
              [8.26846, 50.09502],
              [8.26824, 50.09476],
              [8.26788, 50.0944],
              [8.26712, 50.0938],
              [8.26621, 50.09317],
              [8.26568, 50.0929],
              [8.2657, 50.09275],
              [8.265776, 50.092346],
              [8.26582, 50.09211],
              [8.26567, 50.09183],
              [8.26529, 50.09156],
              [8.26416, 50.09073],
              [8.2633, 50.09017],
              [8.26254, 50.08966],
              [8.26231, 50.08924],
              [8.26223, 50.08897],
              [8.26204, 50.08876],
              [8.26146, 50.08861],
              [8.26064, 50.0885],
              [8.26032, 50.08845],
              [8.26034, 50.0884],
              [8.2602, 50.08831],
              [8.26015, 50.08782],
              [8.26015, 50.08767],
              [8.26015, 50.08726],
              [8.26018, 50.0872],
              [8.26036, 50.08719],
              [8.2604, 50.08716],
              [8.26034, 50.08702],
              [8.26069, 50.08661],
              [8.26075, 50.08636],
              [8.2605, 50.0855],
              [8.26007, 50.08485],
              [8.25997, 50.08472],
              [8.25925, 50.08381],
              [8.25909, 50.08359],
              [8.25846, 50.0831],
              [8.258472, 50.082883],
              [8.25848, 50.08273],
              [8.2585, 50.08264],
              [8.25848, 50.08165],
              [8.25835, 50.079783],
              [8.25826, 50.07848],
              [8.2581, 50.07775],
              [8.25789, 50.07684],
              [8.25782, 50.07653],
              [8.25782, 50.07653],
              [8.25788, 50.07571],
              [8.25801, 50.07515],
              [8.25815, 50.07476],
              [8.25845, 50.07404],
              [8.25868, 50.07356],
              [8.25775, 50.073],
              [8.25746, 50.07284],
              [8.25693, 50.07258],
              [8.25671, 50.07253],
              [8.25641, 50.07248],
              [8.25518, 50.07238],
              [8.2547, 50.07235],
              [8.2531, 50.07223],
              [8.25181, 50.07215],
              [8.25111, 50.07212],
              [8.25036, 50.07207],
              [8.24916, 50.07199],
              [8.24887, 50.07198],
              [8.24834, 50.07196],
              [8.24819, 50.07195],
              [8.24803, 50.07194],
              [8.24745, 50.07192],
              [8.2459, 50.07184],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23112, 50.07506],
              [8.23077, 50.07557],
              [8.23074, 50.07561],
              [8.23036, 50.07637],
              [8.23019, 50.07716],
              [8.23015, 50.07735],
              [8.22997, 50.07829],
              [8.22976, 50.07946],
              [8.22963, 50.08055],
              [8.22955, 50.08119],
              [8.22952, 50.08153],
              [8.22947, 50.08188],
              [8.22942, 50.08225],
              [8.22938, 50.08254],
              [8.22929, 50.08323],
              [8.22897, 50.08326],
              [8.22837, 50.08335],
              [8.22766, 50.08366],
              [8.22693, 50.08414],
              [8.22575, 50.08506],
              [8.2254, 50.0853],
              [8.22529, 50.08538],
              [8.22461, 50.08575],
              [8.22414, 50.08567],
              [8.22261, 50.086],
              [8.222181, 50.086095],
              [8.22093, 50.08637],
              [8.22058, 50.08644],
              [8.21897, 50.08678],
              [8.218173, 50.086954],
              [8.21741, 50.08712],
              [8.21584, 50.08746],
              [8.21544, 50.08762],
              [8.21444, 50.08824],
              [8.21438, 50.08829],
              [8.21244, 50.09025],
              [8.21209, 50.09049],
              [8.211745, 50.090644],
              [8.21135, 50.09082],
              [8.2083, 50.09231],
              [8.20683, 50.09295],
              [8.20467, 50.09339],
              [8.20429, 50.09337],
              [8.20235, 50.09409],
              [8.20189, 50.09425],
              [8.20092, 50.09446],
              [8.19832, 50.09439],
              [8.19814, 50.09438],
              [8.19678, 50.09438],
              [8.19563, 50.09438],
              [8.19415, 50.09438],
              [8.19275, 50.09449],
              [8.19069, 50.09523],
              [8.19045, 50.09532],
              [8.18784, 50.09623],
              [8.18606, 50.09676],
              [8.18571, 50.09686],
              [8.18577, 50.09762],
              [8.18584, 50.09849],
              [8.18591, 50.09857],
              [8.18631, 50.09882],
              [8.18708, 50.09929],
              [8.18971, 50.10088],
              [8.19111, 50.10171],
              [8.191408, 50.101852],
            ],
          ],
        },
        id: "MxMDc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.191408, 50.101852],
              [8.19111, 50.10171],
              [8.18971, 50.10088],
              [8.18708, 50.09929],
              [8.18631, 50.09882],
              [8.18591, 50.09857],
              [8.18584, 50.09849],
              [8.18577, 50.09762],
              [8.18571, 50.09686],
              [8.18606, 50.09676],
              [8.18784, 50.09623],
              [8.19045, 50.09532],
              [8.19069, 50.09523],
              [8.19275, 50.09449],
              [8.19415, 50.09438],
              [8.19563, 50.09438],
              [8.19678, 50.09438],
              [8.19814, 50.09438],
              [8.19832, 50.09439],
              [8.20092, 50.09446],
              [8.20189, 50.09425],
              [8.20235, 50.09409],
              [8.20429, 50.09337],
              [8.20467, 50.09339],
              [8.20683, 50.09295],
              [8.2083, 50.09231],
              [8.21135, 50.09082],
              [8.211745, 50.090644],
              [8.21209, 50.09049],
              [8.21244, 50.09025],
              [8.21438, 50.08829],
              [8.21444, 50.08824],
              [8.21544, 50.08762],
              [8.21584, 50.08746],
              [8.21741, 50.08712],
              [8.218173, 50.086954],
              [8.21897, 50.08678],
              [8.22058, 50.08644],
              [8.22093, 50.08637],
              [8.222181, 50.086095],
              [8.22261, 50.086],
              [8.22414, 50.08567],
              [8.22461, 50.08575],
              [8.22529, 50.08538],
              [8.2254, 50.0853],
              [8.22575, 50.08506],
              [8.22693, 50.08414],
              [8.22766, 50.08366],
              [8.22837, 50.08335],
              [8.22882, 50.08312],
              [8.22888, 50.08279],
              [8.228921, 50.082748],
              [8.22908, 50.08258],
              [8.22915, 50.08253],
              [8.2293, 50.08186],
              [8.22934, 50.08152],
              [8.2294, 50.08117],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22983, 50.07742],
              [8.22986, 50.07721],
              [8.22989, 50.07706],
              [8.229975, 50.076791],
              [8.23143, 50.07437],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.25112, 50.07194],
              [8.25181, 50.07198],
              [8.25311, 50.07204],
              [8.25403, 50.07209],
              [8.25487, 50.07212],
              [8.25494, 50.07212],
              [8.25533, 50.07217],
              [8.25677, 50.07227],
              [8.25699, 50.07233],
              [8.25709, 50.07238],
              [8.25737, 50.07252],
              [8.2577, 50.07273],
              [8.25816, 50.07314],
              [8.25856, 50.07339],
              [8.25874, 50.07359],
              [8.25869, 50.07375],
              [8.25838, 50.07448],
              [8.25829, 50.07478],
              [8.25811, 50.07521],
              [8.25788, 50.07571],
              [8.25782, 50.07653],
              [8.25826, 50.07848],
              [8.25835, 50.079783],
              [8.25848, 50.08165],
              [8.2585, 50.08264],
              [8.25848, 50.08273],
              [8.25997, 50.08472],
              [8.26007, 50.08485],
              [8.2605, 50.0855],
              [8.26075, 50.08636],
              [8.26069, 50.08661],
              [8.26034, 50.08702],
              [8.2604, 50.08716],
              [8.26036, 50.08719],
              [8.26018, 50.0872],
              [8.26015, 50.08726],
              [8.26015, 50.08767],
              [8.26015, 50.08782],
              [8.2602, 50.08831],
              [8.26034, 50.0884],
              [8.26032, 50.08845],
              [8.26064, 50.0885],
              [8.26146, 50.08861],
              [8.26204, 50.08876],
              [8.26223, 50.08897],
              [8.26231, 50.08924],
              [8.26254, 50.08966],
              [8.2633, 50.09017],
              [8.26416, 50.09073],
              [8.265367, 50.091609],
              [8.265688, 50.091849],
              [8.265817, 50.092083],
              [8.265776, 50.092346],
              [8.2657, 50.09275],
              [8.26568, 50.0929],
              [8.26621, 50.09317],
              [8.26712, 50.0938],
              [8.26788, 50.0944],
              [8.26824, 50.09476],
              [8.26846, 50.09502],
              [8.26901, 50.09552],
              [8.26806, 50.09592],
              [8.26671, 50.09647],
              [8.26586, 50.09673],
              [8.26522, 50.09693],
              [8.26515, 50.09695],
              [8.26471, 50.0971],
              [8.26438, 50.09747],
              [8.26438, 50.09763],
              [8.26457, 50.09786],
              [8.26549, 50.09822],
              [8.2668, 50.099],
              [8.26704, 50.09924],
              [8.26738, 50.09976],
              [8.26724, 50.1003],
              [8.26666, 50.10085],
              [8.26608, 50.10159],
              [8.26578, 50.10176],
              [8.2654, 50.10183],
              [8.26417, 50.10217],
              [8.26416, 50.10183],
              [8.26423, 50.10168],
              [8.26442, 50.10102],
            ],
          ],
        },
        id: "M3NDQ",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "QxNTE",
    name: "28_Gräselberg <> 28_Delkenheim",
    lineName: "28",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.352249, 50.046292],
              [8.354166, 50.045384],
              [8.3551, 50.04533],
              [8.35516, 50.04527],
              [8.35469, 50.04452],
              [8.354328, 50.043947],
              [8.35312, 50.04203],
              [8.35324, 50.04188],
              [8.35523, 50.04136],
              [8.35546, 50.04144],
              [8.355847, 50.042043],
              [8.35691, 50.0437],
              [8.35703, 50.04546],
              [8.35719, 50.04547],
              [8.35835, 50.0455],
              [8.3596, 50.04553],
              [8.359891, 50.045646],
              [8.36023, 50.04578],
              [8.36051, 50.04612],
              [8.36071, 50.04652],
              [8.36093, 50.04708],
              [8.36138, 50.04746],
              [8.36196, 50.04735],
              [8.36266, 50.0471],
              [8.36273, 50.04708],
              [8.36334, 50.04691],
              [8.3634, 50.0469],
              [8.36443, 50.04664],
              [8.3645, 50.04662],
              [8.36471, 50.04657],
              [8.36515, 50.04647],
              [8.36574, 50.04636],
              [8.36603, 50.04662],
              [8.36635, 50.04673],
              [8.36767, 50.04721],
              [8.36813, 50.04728],
              [8.36851, 50.0473],
              [8.37003, 50.04735],
              [8.37067, 50.04741],
              [8.37142, 50.04756],
              [8.37199, 50.04785],
              [8.37303, 50.04852],
              [8.37433, 50.04983],
              [8.374894, 50.05066],
              [8.37516, 50.05105],
              [8.3754, 50.05129],
              [8.37513, 50.05148],
              [8.37435, 50.05204],
              [8.3742, 50.05214],
              [8.3739, 50.05236],
              [8.37381, 50.05243],
              [8.3734, 50.05272],
              [8.3731, 50.05294],
              [8.37299, 50.05302],
              [8.372276, 50.053541],
              [8.371776, 50.053906],
              [8.368689, 50.053904],
              [8.36676, 50.054151],
              [8.363348, 50.054234],
              [8.361732, 50.053789],
              [8.360182, 50.053113],
              [8.35977, 50.053113],
              [8.35916, 50.05336],
              [8.358204, 50.052453],
              [8.357693, 50.052173],
              [8.35733, 50.05203],
              [8.3566, 50.05274],
              [8.35541, 50.0534],
              [8.35214, 50.05487],
              [8.35164, 50.0551],
              [8.35057, 50.05548],
              [8.34392, 50.05553],
              [8.34329, 50.0555],
              [8.34251, 50.05522],
              [8.34233, 50.05513],
              [8.34197, 50.05493],
              [8.34185, 50.05488],
              [8.34136, 50.05477],
              [8.34055, 50.05488],
              [8.33997, 50.05527],
              [8.33985, 50.05571],
              [8.34008, 50.05611],
              [8.34045, 50.05646],
              [8.34102, 50.05702],
              [8.34093, 50.05704],
              [8.34084, 50.05706],
              [8.3405, 50.05708],
              [8.34034, 50.05706],
              [8.33782, 50.05711],
              [8.33428, 50.05721],
              [8.333624, 50.057262],
              [8.32898, 50.05763],
              [8.32815, 50.05787],
              [8.32779, 50.05824],
              [8.32774, 50.05845],
              [8.32786, 50.05888],
              [8.32809, 50.05924],
              [8.3296, 50.06161],
              [8.330026, 50.061527],
              [8.331989, 50.064709],
              [8.3327, 50.06586],
              [8.33302, 50.06629],
              [8.33388, 50.06759],
              [8.33402, 50.06764],
              [8.33612, 50.06706],
              [8.33741, 50.0667],
              [8.33777, 50.06661],
              [8.3382, 50.06651],
              [8.33885, 50.06631],
              [8.339295, 50.066202],
              [8.33967, 50.06611],
              [8.33984, 50.06642],
              [8.34263, 50.06602],
              [8.34292, 50.06603],
              [8.3431, 50.066],
              [8.343644, 50.065838],
              [8.3431, 50.066],
              [8.34304, 50.0661],
              [8.34238, 50.06698],
              [8.34098, 50.06864],
              [8.33965, 50.07022],
              [8.33861, 50.07119],
              [8.33712, 50.07245],
              [8.33592, 50.07347],
              [8.33513, 50.07425],
              [8.33444, 50.07513],
              [8.33228, 50.07636],
              [8.33075, 50.07722],
              [8.3298, 50.07781],
              [8.32965, 50.0783],
              [8.32932, 50.07883],
              [8.32892, 50.07918],
              [8.32756, 50.08006],
              [8.32725, 50.08053],
              [8.32709, 50.08055],
              [8.32695, 50.08056],
              [8.32632, 50.08051],
              [8.32618, 50.08054],
              [8.3261, 50.08063],
              [8.325887, 50.081087],
              [8.3256, 50.0817],
              [8.32438, 50.08388],
              [8.32428, 50.08455],
              [8.32432, 50.08532],
              [8.323694, 50.086571],
              [8.32366, 50.08664],
              [8.32354, 50.08709],
              [8.32332, 50.08787],
              [8.32325, 50.08793],
              [8.32229, 50.08777],
              [8.32204, 50.08785],
              [8.32171, 50.08832],
              [8.32151, 50.08862],
              [8.32114, 50.08921],
              [8.32027, 50.09007],
              [8.31788, 50.09149],
              [8.31687, 50.09221],
              [8.3162, 50.09251],
              [8.31509, 50.09292],
              [8.31312, 50.09338],
              [8.31279, 50.09344],
              [8.3122, 50.09361],
              [8.31007, 50.0942],
              [8.30943, 50.09441],
              [8.30877, 50.09465],
              [8.30844, 50.0946],
              [8.30793, 50.09452],
              [8.30764, 50.09451],
              [8.30687, 50.09469],
              [8.30654, 50.09445],
              [8.30659, 50.09416],
              [8.30659, 50.0941],
              [8.30633, 50.09383],
              [8.30567, 50.09346],
              [8.30484, 50.09355],
              [8.30401, 50.09336],
              [8.30216, 50.09284],
              [8.30124, 50.09256],
              [8.29919, 50.09194],
              [8.29848, 50.09173],
              [8.29731, 50.09139],
              [8.2966, 50.09106],
              [8.29436, 50.08896],
              [8.29345, 50.08854],
              [8.29201, 50.08823],
              [8.29053, 50.08792],
              [8.28992, 50.08779],
              [8.287249, 50.08724],
              [8.28686, 50.08716],
              [8.28705, 50.08659],
              [8.2875, 50.08536],
              [8.28778, 50.08491],
              [8.28816, 50.08446],
              [8.28844, 50.08414],
              [8.28908, 50.08318],
              [8.28812, 50.08265],
              [8.28782, 50.08248],
              [8.28761, 50.08224],
              [8.28671, 50.08218],
              [8.28638, 50.08293],
              [8.28633, 50.08305],
              [8.28613, 50.08342],
              [8.28541, 50.0838],
              [8.28459, 50.08377],
              [8.28332, 50.08368],
              [8.28178, 50.08347],
              [8.28095, 50.08333],
              [8.28072, 50.08331],
              [8.28057, 50.0833],
              [8.27913, 50.08398],
              [8.27866, 50.08419],
              [8.27768, 50.084646],
              [8.27724, 50.08485],
              [8.27667, 50.08509],
              [8.27653, 50.08513],
              [8.275, 50.08543],
              [8.2737, 50.08527],
              [8.27217, 50.08504],
              [8.27178, 50.08499],
              [8.27015, 50.08475],
              [8.26798, 50.08459],
              [8.26664, 50.08449],
              [8.26591, 50.08444],
              [8.26533, 50.08438],
              [8.26405, 50.08408],
              [8.26261, 50.08372],
              [8.26159, 50.08346],
              [8.26007, 50.08306],
              [8.25848, 50.08273],
              [8.2585, 50.08264],
              [8.25848, 50.08165],
              [8.25835, 50.079783],
              [8.25826, 50.07848],
              [8.2581, 50.07775],
              [8.25789, 50.07684],
              [8.25782, 50.07653],
              [8.25782, 50.07653],
              [8.25788, 50.07571],
              [8.25801, 50.07515],
              [8.25815, 50.07476],
              [8.25845, 50.07404],
              [8.25868, 50.07356],
              [8.25775, 50.073],
              [8.25746, 50.07284],
              [8.25693, 50.07258],
              [8.25671, 50.07253],
              [8.25641, 50.07248],
              [8.25518, 50.07238],
              [8.2547, 50.07235],
              [8.2531, 50.07223],
              [8.25181, 50.07215],
              [8.25111, 50.07212],
              [8.25036, 50.07207],
              [8.24916, 50.07199],
              [8.24887, 50.07198],
              [8.24834, 50.07196],
              [8.24819, 50.07195],
              [8.24803, 50.07194],
              [8.24745, 50.07192],
              [8.2459, 50.07184],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23143, 50.07437],
              [8.23051, 50.07357],
              [8.23, 50.0731],
              [8.22984, 50.07296],
              [8.22977, 50.07289],
              [8.22913, 50.07232],
              [8.22823, 50.07151],
              [8.22797, 50.07128],
              [8.22732, 50.07069],
              [8.22609, 50.06969],
              [8.2248, 50.06876],
              [8.22359, 50.0679],
              [8.223309, 50.067704],
              [8.22279, 50.06734],
              [8.22196, 50.06674],
              [8.2211, 50.06611],
              [8.22093, 50.06596],
              [8.22074, 50.0658],
              [8.2205, 50.06562],
              [8.21961, 50.06501],
              [8.21893, 50.0645],
              [8.21802, 50.06383],
              [8.21742, 50.06338],
              [8.21711, 50.06314],
              [8.21684, 50.06292],
              [8.21641, 50.06261],
              [8.216721, 50.062429],
              [8.21708, 50.06222],
              [8.21739, 50.06202],
              [8.21756, 50.06191],
              [8.2185, 50.06139],
              [8.2192, 50.06102],
              [8.219797, 50.060709],
              [8.22033, 50.06043],
              [8.21877, 50.0594],
              [8.21858, 50.05917],
              [8.21845, 50.059],
              [8.21835, 50.05845],
              [8.2186, 50.05792],
              [8.218624, 50.057901],
              [8.21912, 50.0575],
              [8.22, 50.05673],
              [8.22014, 50.05638],
              [8.220123, 50.05626],
              [8.22009, 50.05602],
              [8.22011, 50.05586],
              [8.22093, 50.05589],
              [8.22215, 50.056],
              [8.22299, 50.05644],
              [8.22341, 50.05611],
              [8.22377, 50.05594],
              [8.22468, 50.05589],
            ],
          ],
        },
        id: "QxNTE",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.22468, 50.05589],
              [8.22377, 50.05594],
              [8.22341, 50.05611],
              [8.22299, 50.05644],
              [8.22215, 50.056],
              [8.22093, 50.05589],
              [8.22011, 50.05586],
              [8.22009, 50.05602],
              [8.220123, 50.05626],
              [8.22014, 50.05638],
              [8.22, 50.05673],
              [8.21912, 50.0575],
              [8.218624, 50.057901],
              [8.2186, 50.05792],
              [8.21835, 50.05845],
              [8.21845, 50.059],
              [8.21858, 50.05917],
              [8.21877, 50.0594],
              [8.22033, 50.06043],
              [8.219797, 50.060709],
              [8.2192, 50.06102],
              [8.2185, 50.06139],
              [8.21756, 50.06191],
              [8.21739, 50.06202],
              [8.21708, 50.06222],
              [8.21733, 50.06243],
              [8.2178, 50.06282],
              [8.21794, 50.06294],
              [8.21998, 50.0649],
              [8.22048, 50.06548],
              [8.221, 50.06593],
              [8.221162, 50.066064],
              [8.22117, 50.06607],
              [8.22185, 50.06655],
              [8.22291, 50.06727],
              [8.22369, 50.06782],
              [8.22492, 50.06868],
              [8.22618, 50.06961],
              [8.22676, 50.07005],
              [8.22741, 50.07061],
              [8.22833, 50.07146],
              [8.22921, 50.07227],
              [8.22984, 50.07283],
              [8.22994, 50.07291],
              [8.2301, 50.07306],
              [8.23071, 50.07357],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.24825, 50.0718],
              [8.24838, 50.07181],
              [8.24889, 50.07184],
              [8.24917, 50.07186],
              [8.25038, 50.0719],
              [8.25112, 50.07194],
              [8.25181, 50.07198],
              [8.25311, 50.07204],
              [8.25403, 50.07209],
              [8.25487, 50.07212],
              [8.25494, 50.07212],
              [8.25533, 50.07217],
              [8.25677, 50.07227],
              [8.25699, 50.07233],
              [8.25709, 50.07238],
              [8.25737, 50.07252],
              [8.2577, 50.07273],
              [8.25908, 50.07357],
              [8.25868, 50.07356],
              [8.25845, 50.07404],
              [8.25815, 50.07476],
              [8.25801, 50.07515],
              [8.25788, 50.07571],
              [8.25782, 50.07653],
              [8.25782, 50.07653],
              [8.25789, 50.07684],
              [8.2581, 50.07775],
              [8.25826, 50.07848],
              [8.25835, 50.079783],
              [8.25848, 50.08165],
              [8.2585, 50.08264],
              [8.25848, 50.08273],
              [8.26007, 50.08306],
              [8.26159, 50.08346],
              [8.26261, 50.08372],
              [8.26405, 50.08408],
              [8.26533, 50.08438],
              [8.26591, 50.08444],
              [8.26664, 50.08449],
              [8.26798, 50.08459],
              [8.27015, 50.08475],
              [8.27178, 50.08499],
              [8.27217, 50.08504],
              [8.2737, 50.08527],
              [8.275, 50.08543],
              [8.27653, 50.08513],
              [8.27667, 50.08509],
              [8.27724, 50.08485],
              [8.27768, 50.084646],
              [8.27866, 50.08419],
              [8.27913, 50.08398],
              [8.28057, 50.0833],
              [8.28069, 50.08326],
              [8.28078, 50.08323],
              [8.28085, 50.0832],
              [8.28096, 50.08316],
              [8.28119, 50.08307],
              [8.28198, 50.08271],
              [8.28307, 50.08219],
              [8.28327, 50.08212],
              [8.28367, 50.08217],
              [8.28435, 50.08232],
              [8.28453, 50.08235],
              [8.28526, 50.08255],
              [8.28638, 50.08293],
              [8.28671, 50.08218],
              [8.28761, 50.08224],
              [8.28782, 50.08248],
              [8.28812, 50.08265],
              [8.28908, 50.08318],
              [8.28844, 50.08414],
              [8.28816, 50.08446],
              [8.28778, 50.08491],
              [8.2875, 50.08536],
              [8.28705, 50.08659],
              [8.28686, 50.08716],
              [8.287249, 50.08724],
              [8.28992, 50.08779],
              [8.29053, 50.08792],
              [8.29201, 50.08823],
              [8.29345, 50.08854],
              [8.29436, 50.08896],
              [8.2966, 50.09106],
              [8.29731, 50.09139],
              [8.29848, 50.09173],
              [8.29919, 50.09194],
              [8.30124, 50.09256],
              [8.30216, 50.09284],
              [8.30401, 50.09336],
              [8.30484, 50.09355],
              [8.30567, 50.09346],
              [8.30633, 50.09383],
              [8.30659, 50.0941],
              [8.30659, 50.09416],
              [8.30654, 50.09445],
              [8.30687, 50.09469],
              [8.30764, 50.09451],
              [8.30793, 50.09452],
              [8.30844, 50.0946],
              [8.30877, 50.09465],
              [8.30943, 50.09441],
              [8.31007, 50.0942],
              [8.3122, 50.09361],
              [8.31279, 50.09344],
              [8.31312, 50.09338],
              [8.31509, 50.09292],
              [8.3162, 50.09251],
              [8.31687, 50.09221],
              [8.31788, 50.09149],
              [8.32027, 50.09007],
              [8.32114, 50.08921],
              [8.32151, 50.08862],
              [8.32171, 50.08832],
              [8.32204, 50.08785],
              [8.32229, 50.08777],
              [8.32325, 50.08793],
              [8.32332, 50.08787],
              [8.32354, 50.08709],
              [8.32366, 50.08664],
              [8.323694, 50.086571],
              [8.32432, 50.08532],
              [8.32428, 50.08455],
              [8.32438, 50.08388],
              [8.3256, 50.0817],
              [8.325887, 50.081087],
              [8.3261, 50.08063],
              [8.32618, 50.08054],
              [8.32632, 50.08051],
              [8.32695, 50.08056],
              [8.32709, 50.08055],
              [8.32725, 50.08053],
              [8.32756, 50.08006],
              [8.32892, 50.07918],
              [8.32932, 50.07883],
              [8.32965, 50.0783],
              [8.3298, 50.07781],
              [8.33075, 50.07722],
              [8.33228, 50.07636],
              [8.33444, 50.07513],
              [8.33513, 50.07425],
              [8.33592, 50.07347],
              [8.33712, 50.07245],
              [8.33861, 50.07119],
              [8.33965, 50.07022],
              [8.34098, 50.06864],
              [8.34238, 50.06698],
              [8.34304, 50.0661],
              [8.3431, 50.066],
              [8.343644, 50.065838],
              [8.3431, 50.066],
              [8.34292, 50.06603],
              [8.34263, 50.06602],
              [8.33984, 50.06642],
              [8.34016, 50.06703],
              [8.34042, 50.06735],
              [8.33875, 50.06782],
              [8.338416, 50.067914],
              [8.33697, 50.06832],
              [8.33566, 50.06867],
              [8.33488, 50.06889],
              [8.33471, 50.06887],
              [8.334108, 50.067956],
              [8.33396, 50.06773],
              [8.33402, 50.06764],
              [8.33388, 50.06759],
              [8.33302, 50.06629],
              [8.3327, 50.06586],
              [8.331989, 50.064709],
              [8.330026, 50.061527],
              [8.3296, 50.06161],
              [8.32809, 50.05924],
              [8.32786, 50.05888],
              [8.32774, 50.05845],
              [8.32779, 50.05824],
              [8.32815, 50.05787],
              [8.32898, 50.05763],
              [8.333624, 50.057262],
              [8.33428, 50.05721],
              [8.33782, 50.05711],
              [8.34034, 50.05706],
              [8.34052, 50.05699],
              [8.34063, 50.05682],
              [8.34012, 50.05632],
              [8.33989, 50.05609],
              [8.33972, 50.05571],
              [8.33977, 50.05538],
              [8.34008, 50.05501],
              [8.34075, 50.05474],
              [8.34148, 50.05472],
              [8.3419, 50.0548],
              [8.34203, 50.05487],
              [8.34251, 50.05522],
              [8.34329, 50.0555],
              [8.34392, 50.05553],
              [8.35057, 50.05548],
              [8.35164, 50.0551],
              [8.35214, 50.05487],
              [8.35541, 50.0534],
              [8.3566, 50.05274],
              [8.35733, 50.05203],
              [8.357693, 50.052173],
              [8.358204, 50.052453],
              [8.35916, 50.05336],
              [8.35977, 50.053113],
              [8.360182, 50.053113],
              [8.361732, 50.053789],
              [8.363348, 50.054234],
              [8.36676, 50.054151],
              [8.368689, 50.053904],
              [8.371776, 50.053906],
              [8.372276, 50.053541],
              [8.37299, 50.05302],
              [8.3731, 50.05294],
              [8.3734, 50.05272],
              [8.37381, 50.05243],
              [8.3739, 50.05236],
              [8.3742, 50.05214],
              [8.37435, 50.05204],
              [8.37513, 50.05148],
              [8.3754, 50.05129],
              [8.37516, 50.05105],
              [8.374894, 50.05066],
              [8.37433, 50.04983],
              [8.37303, 50.04852],
              [8.37199, 50.04785],
              [8.37142, 50.04756],
              [8.37067, 50.04741],
              [8.37003, 50.04735],
              [8.36851, 50.0473],
              [8.36813, 50.04728],
              [8.36767, 50.04721],
              [8.36635, 50.04673],
              [8.36603, 50.04662],
              [8.36574, 50.04636],
              [8.36515, 50.04647],
              [8.36471, 50.04657],
              [8.3645, 50.04662],
              [8.36443, 50.04664],
              [8.3634, 50.0469],
              [8.36334, 50.04691],
              [8.36273, 50.04708],
              [8.36266, 50.0471],
              [8.36196, 50.04735],
              [8.36138, 50.04746],
              [8.36093, 50.04708],
              [8.36071, 50.04652],
              [8.36051, 50.04612],
              [8.36023, 50.04578],
              [8.359891, 50.045646],
              [8.3596, 50.04553],
              [8.35835, 50.0455],
              [8.35719, 50.04547],
              [8.35703, 50.04546],
              [8.35597, 50.0454],
              [8.35532, 50.04533],
              [8.35516, 50.04527],
              [8.35469, 50.04452],
              [8.354328, 50.043947],
              [8.35312, 50.04203],
              [8.35324, 50.04188],
              [8.35523, 50.04136],
              [8.35546, 50.04144],
              [8.355847, 50.042043],
              [8.35691, 50.0437],
              [8.35703, 50.04546],
              [8.35597, 50.0454],
              [8.35532, 50.04533],
              [8.3553, 50.04537],
              [8.3551, 50.04533],
              [8.354166, 50.045384],
              [8.35405, 50.04539],
              [8.35288, 50.04596],
              [8.35271, 50.04582],
              [8.35166, 50.04419],
              [8.35107, 50.0434],
              [8.35084, 50.04335],
              [8.35025, 50.04351],
              [8.34926, 50.04421],
              [8.34914, 50.04439],
              [8.34917, 50.0446],
              [8.35038, 50.04699],
              [8.35075, 50.04708],
              [8.352249, 50.046292],
            ],
          ],
        },
        id: "UyMDU",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "gxODM",
    name: "27 Domaene_Mechthildshausen <> Nerotal",
    lineName: "27",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.22643, 50.09518],
              [8.22803, 50.09487],
              [8.22939, 50.09478],
              [8.23129, 50.09486],
              [8.231561, 50.094791],
              [8.23238, 50.09458],
              [8.234451, 50.093375],
              [8.23494, 50.09309],
              [8.23547, 50.09224],
              [8.23625, 50.09122],
              [8.23651, 50.09095],
              [8.238921, 50.089397],
              [8.23936, 50.08911],
              [8.23872, 50.08869],
              [8.23832, 50.08841],
              [8.23801, 50.08821],
              [8.23774, 50.08803],
              [8.23731, 50.08774],
              [8.23673, 50.08739],
              [8.23573, 50.08668],
              [8.23568, 50.08665],
              [8.23583, 50.08531],
              [8.23583, 50.08524],
              [8.23593, 50.08379],
              [8.23582, 50.08338],
              [8.23581, 50.08327],
              [8.23583, 50.08285],
              [8.23588, 50.08241],
              [8.2359, 50.08223],
              [8.23598, 50.08168],
              [8.23605, 50.0813],
              [8.23609, 50.08107],
              [8.23612, 50.08088],
              [8.23622, 50.08024],
              [8.23623, 50.08016],
              [8.23639, 50.07893],
              [8.23644, 50.07835],
              [8.236676, 50.077684],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24505, 50.071],
              [8.24518, 50.07077],
              [8.24525, 50.07065],
              [8.24532, 50.07051],
              [8.24541, 50.07039],
              [8.24608, 50.07029],
              [8.24657, 50.06915],
              [8.24693, 50.06863],
              [8.2477, 50.06781],
              [8.24773, 50.06783],
              [8.24799, 50.06779],
              [8.24926, 50.06801],
              [8.24938, 50.06803],
              [8.25007, 50.06672],
              [8.25131, 50.06699],
              [8.251328, 50.066994],
              [8.25224, 50.06719],
              [8.25458, 50.06769],
              [8.25661, 50.0681],
              [8.25783, 50.06699],
              [8.25896, 50.06597],
              [8.25922, 50.06562],
              [8.26044, 50.06358],
              [8.259471, 50.063378],
              [8.259, 50.06328],
              [8.2575, 50.06298],
              [8.25528, 50.06252],
              [8.25525, 50.06241],
              [8.25538, 50.0623],
              [8.25575, 50.06164],
              [8.255774, 50.061604],
              [8.25701, 50.0597],
              [8.25813, 50.05839],
              [8.25948, 50.05868],
              [8.26008, 50.0588],
              [8.26024, 50.05883],
              [8.260677, 50.058923],
              [8.26147, 50.05909],
              [8.26194, 50.05921],
              [8.2621, 50.05925],
              [8.26483, 50.05996],
              [8.26731, 50.06062],
              [8.26748, 50.06067],
              [8.26816, 50.06085],
              [8.26826, 50.06088],
              [8.27188, 50.0619],
              [8.27207, 50.06204],
              [8.27222, 50.06205],
              [8.27227, 50.06213],
              [8.272768, 50.06237],
              [8.27281, 50.06239],
              [8.27305, 50.06258],
              [8.27373, 50.06336],
              [8.27379, 50.06344],
              [8.27276, 50.06382],
              [8.27253, 50.06417],
              [8.27264, 50.06436],
              [8.27298, 50.06446],
              [8.27346, 50.06446],
              [8.27435, 50.06412],
              [8.27631, 50.06341],
              [8.27966, 50.06219],
              [8.28254, 50.0609],
              [8.28415, 50.0598],
              [8.2846, 50.05906],
              [8.2845, 50.05821],
              [8.28451, 50.05771],
              [8.28482, 50.05726],
              [8.28489, 50.05718],
              [8.28563, 50.05675],
              [8.28659, 50.05657],
              [8.28677, 50.05657],
              [8.28747, 50.05666],
              [8.28856, 50.05714],
              [8.28958, 50.05807],
              [8.28992, 50.05843],
              [8.29112, 50.057992],
              [8.29192, 50.0577],
              [8.28969, 50.05516],
              [8.28971, 50.05491],
              [8.289934, 50.054656],
              [8.29075, 50.05373],
              [8.29114, 50.05329],
              [8.292129, 50.052527],
              [8.293543, 50.05217],
              [8.294822, 50.052158],
              [8.298253, 50.052211],
              [8.298453, 50.0515],
              [8.29966, 50.050881],
              [8.29959, 50.05078],
              [8.2996, 50.05062],
              [8.29972, 50.04999],
              [8.30018, 50.04964],
              [8.30057, 50.04941],
              [8.30162, 50.04923],
              [8.3018, 50.04901],
              [8.30162, 50.04923],
              [8.30057, 50.04941],
              [8.30018, 50.04964],
              [8.29972, 50.04999],
              [8.2996, 50.05062],
              [8.29959, 50.05078],
              [8.29966, 50.050881],
              [8.29959, 50.05078],
              [8.2996, 50.05062],
              [8.29972, 50.04999],
              [8.30018, 50.04964],
              [8.30057, 50.04941],
              [8.30162, 50.04923],
              [8.3018, 50.04901],
              [8.30171, 50.04839],
              [8.30139, 50.047566],
              [8.300658, 50.046139],
              [8.300272, 50.045307],
              [8.30091, 50.04507],
              [8.30136, 50.04511],
              [8.30311, 50.0451],
              [8.30624, 50.04467],
              [8.313217, 50.04314],
              [8.313518, 50.042782],
              [8.314505, 50.042272],
              [8.319032, 50.041211],
              [8.321843, 50.038951],
              [8.322487, 50.038703],
            ],
          ],
        },
        id: "gxODM",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.32253, 50.038675],
              [8.321779, 50.038964],
              [8.319032, 50.041211],
              [8.314548, 50.042272],
              [8.313496, 50.042795],
              [8.313196, 50.043126],
              [8.30624, 50.04467],
              [8.30311, 50.0451],
              [8.30136, 50.04511],
              [8.30091, 50.04507],
              [8.300272, 50.045307],
              [8.300658, 50.046139],
              [8.30139, 50.047566],
              [8.30171, 50.04839],
              [8.3018, 50.04901],
              [8.30162, 50.04923],
              [8.30057, 50.04941],
              [8.30018, 50.04964],
              [8.29972, 50.04999],
              [8.2996, 50.05062],
              [8.29959, 50.05078],
              [8.29966, 50.050881],
              [8.298453, 50.0515],
              [8.298253, 50.052211],
              [8.294822, 50.052158],
              [8.293543, 50.05217],
              [8.292129, 50.052527],
              [8.29114, 50.05329],
              [8.29075, 50.05373],
              [8.289934, 50.054656],
              [8.28971, 50.05491],
              [8.28969, 50.05516],
              [8.29192, 50.0577],
              [8.29112, 50.057992],
              [8.288623, 50.058902],
              [8.28739, 50.05935],
              [8.2865, 50.05968],
              [8.28631, 50.05975],
              [8.28557, 50.06002],
              [8.28398, 50.06059],
              [8.28312, 50.06079],
              [8.28263, 50.06098],
              [8.28017, 50.06211],
              [8.27644, 50.06349],
              [8.27592, 50.06388],
              [8.27584, 50.06414],
              [8.27609, 50.06458],
              [8.27632, 50.065],
              [8.27619, 50.06526],
              [8.27578, 50.06544],
              [8.27548, 50.06552],
              [8.27541, 50.06543],
              [8.27528, 50.06527],
              [8.27472, 50.06458],
              [8.27379, 50.06344],
              [8.27373, 50.06336],
              [8.27305, 50.06258],
              [8.27281, 50.06239],
              [8.272768, 50.06237],
              [8.27227, 50.06213],
              [8.27222, 50.06205],
              [8.27207, 50.06204],
              [8.27188, 50.0619],
              [8.26826, 50.06088],
              [8.26816, 50.06085],
              [8.26748, 50.06067],
              [8.26731, 50.06062],
              [8.26483, 50.05996],
              [8.2621, 50.05925],
              [8.26194, 50.05921],
              [8.26147, 50.05909],
              [8.260677, 50.058923],
              [8.26024, 50.05883],
              [8.26008, 50.0588],
              [8.25948, 50.05868],
              [8.25813, 50.05839],
              [8.25701, 50.0597],
              [8.255774, 50.061604],
              [8.25575, 50.06164],
              [8.25538, 50.0623],
              [8.25525, 50.06241],
              [8.25528, 50.06252],
              [8.2575, 50.06298],
              [8.259, 50.06328],
              [8.259471, 50.063378],
              [8.26044, 50.06358],
              [8.25922, 50.06562],
              [8.25896, 50.06597],
              [8.25783, 50.06699],
              [8.25661, 50.0681],
              [8.25458, 50.06769],
              [8.25224, 50.06719],
              [8.251328, 50.066994],
              [8.25131, 50.06699],
              [8.25007, 50.06672],
              [8.24938, 50.06803],
              [8.24926, 50.06801],
              [8.24799, 50.06779],
              [8.24773, 50.06783],
              [8.2477, 50.06781],
              [8.24693, 50.06863],
              [8.24657, 50.06915],
              [8.24608, 50.07029],
              [8.24541, 50.07039],
              [8.24532, 50.07051],
              [8.24525, 50.07065],
              [8.24518, 50.07077],
              [8.24505, 50.071],
              [8.24475, 50.07159],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.23872, 50.07439],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.23674, 50.07786],
              [8.23658, 50.07894],
              [8.23654, 50.07946],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.2363, 50.08098],
              [8.23627, 50.08116],
              [8.23625, 50.08138],
              [8.23623, 50.08182],
              [8.23617, 50.08226],
              [8.23615, 50.08241],
              [8.23611, 50.08276],
              [8.23607, 50.08306],
              [8.23593, 50.08327],
              [8.23593, 50.08379],
              [8.23583, 50.08524],
              [8.23583, 50.08531],
              [8.23568, 50.08665],
              [8.23573, 50.08668],
              [8.23673, 50.08739],
              [8.23731, 50.08774],
              [8.23774, 50.08803],
              [8.23801, 50.08821],
              [8.23832, 50.08841],
              [8.23872, 50.08869],
              [8.23936, 50.08911],
              [8.238921, 50.089397],
              [8.23651, 50.09095],
              [8.23625, 50.09122],
              [8.23547, 50.09224],
              [8.23494, 50.09309],
              [8.234451, 50.093375],
              [8.23238, 50.09458],
              [8.231561, 50.094791],
              [8.23129, 50.09486],
              [8.22939, 50.09478],
              [8.22803, 50.09487],
              [8.22643, 50.09518],
            ],
          ],
        },
        id: "IxMTM",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "EwMDg",
    name: "17 Biebrich <> Klarenthal",
    lineName: "17",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.19933, 50.09207],
              [8.19796, 50.09199],
              [8.19716, 50.09173],
              [8.19682, 50.09162],
              [8.19596, 50.09126],
              [8.19537, 50.09102],
              [8.19522, 50.09086],
              [8.19523, 50.09061],
              [8.19544, 50.09037],
              [8.19574, 50.09026],
              [8.1965, 50.0902],
              [8.19728, 50.09015],
              [8.19895, 50.09005],
              [8.19866, 50.08924],
              [8.19867, 50.0885],
              [8.199, 50.08722],
              [8.19923, 50.0872],
              [8.20028, 50.08703],
              [8.2006, 50.08696],
              [8.20242, 50.0865],
              [8.20394, 50.08612],
              [8.20506, 50.0857],
              [8.20807, 50.08447],
              [8.20814, 50.08448],
              [8.20825, 50.08436],
              [8.20872, 50.08415],
              [8.21007, 50.08364],
              [8.21173, 50.083],
              [8.21466, 50.08234],
              [8.21553, 50.08232],
              [8.21621, 50.08235],
              [8.21747, 50.08199],
              [8.21777, 50.08189],
              [8.2183, 50.08162],
              [8.21884, 50.08148],
              [8.21935, 50.08198],
              [8.21996, 50.08234],
              [8.2206, 50.08256],
              [8.22087, 50.08261],
              [8.22241, 50.08265],
              [8.22283, 50.08263],
              [8.22357, 50.08264],
              [8.22383, 50.08284],
              [8.223996, 50.08309],
              [8.22489, 50.08353],
              [8.22493, 50.08423],
              [8.225, 50.08456],
              [8.2254, 50.0853],
              [8.22547, 50.08541],
              [8.22568, 50.0856],
              [8.226101, 50.085576],
              [8.22709, 50.08552],
              [8.22741, 50.08538],
              [8.22796, 50.0851],
              [8.22842, 50.08487],
              [8.22927, 50.08448],
              [8.22941, 50.08442],
              [8.22974, 50.08431],
              [8.23042, 50.08417],
              [8.23086, 50.0841],
              [8.23147, 50.08399],
              [8.23155, 50.08398],
              [8.23253, 50.08382],
              [8.23274, 50.08378],
              [8.23435, 50.08355],
              [8.23582, 50.08338],
              [8.23581, 50.08327],
              [8.23583, 50.08285],
              [8.23588, 50.08241],
              [8.2359, 50.08223],
              [8.23598, 50.08168],
              [8.23605, 50.0813],
              [8.23609, 50.08107],
              [8.23612, 50.08088],
              [8.23622, 50.08024],
              [8.23623, 50.08016],
              [8.23639, 50.07893],
              [8.23644, 50.07835],
              [8.236676, 50.077684],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24505, 50.071],
              [8.24518, 50.07077],
              [8.24525, 50.07065],
              [8.24532, 50.07051],
              [8.24541, 50.07039],
              [8.24608, 50.07029],
              [8.24657, 50.06915],
              [8.24693, 50.06863],
              [8.2477, 50.06781],
              [8.24773, 50.06783],
              [8.24799, 50.06779],
              [8.24926, 50.06801],
              [8.24938, 50.06803],
              [8.25007, 50.06672],
              [8.25131, 50.06699],
              [8.251328, 50.066994],
              [8.25224, 50.06719],
              [8.25458, 50.06769],
              [8.25661, 50.0681],
              [8.25783, 50.06699],
              [8.25896, 50.06597],
              [8.25922, 50.06562],
              [8.26044, 50.06358],
              [8.259471, 50.063378],
              [8.259, 50.06328],
              [8.2575, 50.06298],
              [8.25528, 50.06252],
              [8.25525, 50.06241],
              [8.25538, 50.0623],
              [8.25575, 50.06164],
              [8.255774, 50.061604],
              [8.25701, 50.0597],
              [8.25813, 50.05839],
              [8.25748, 50.05827],
              [8.25585, 50.05791],
              [8.25525, 50.05778],
              [8.25511, 50.05775],
              [8.25618, 50.05599],
              [8.25634, 50.05577],
              [8.25655, 50.0555],
              [8.257, 50.05488],
              [8.25718, 50.05436],
              [8.25718, 50.05428],
              [8.25712, 50.05381],
              [8.25679, 50.05318],
              [8.25661, 50.05267],
              [8.2565, 50.05243],
              [8.25638, 50.05194],
              [8.25635, 50.05129],
              [8.25633, 50.05048],
              [8.25631, 50.0497],
              [8.25625, 50.04888],
              [8.25614, 50.04865],
              [8.25593, 50.0479],
              [8.25564, 50.04664],
              [8.2554, 50.0456],
              [8.25539, 50.04509],
              [8.25543, 50.04495],
              [8.25538, 50.04489],
              [8.25507, 50.04476],
              [8.25438, 50.04474],
              [8.25404, 50.04474],
              [8.25294, 50.0446],
              [8.2524, 50.04435],
              [8.25189, 50.04392],
              [8.25165, 50.04373],
              [8.25133, 50.04354],
              [8.250522, 50.043281],
              [8.249344, 50.043011],
              [8.248347, 50.042638],
              [8.248034, 50.042422],
              [8.24814, 50.042218],
              [8.248359, 50.041983],
              [8.248407, 50.041741],
              [8.248343, 50.041553],
              [8.247906, 50.040851],
              [8.247237, 50.039763],
              [8.245705, 50.037343],
              [8.245539, 50.03712],
              [8.24497, 50.036353],
              [8.244304, 50.035349],
              [8.243831, 50.034779],
              [8.243654, 50.034409],
              [8.243545, 50.034404],
              [8.243467, 50.034339],
              [8.243308, 50.034349],
              [8.242684, 50.034482],
              [8.24185, 50.03466],
              [8.23966, 50.03539],
              [8.23926, 50.03556],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23862, 50.03686],
              [8.23911, 50.03682],
              [8.23916, 50.0369],
              [8.23984, 50.03824],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
            ],
          ],
        },
        id: "EwMDg",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23863, 50.036856],
              [8.239118, 50.036832],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23926, 50.03556],
              [8.23966, 50.03539],
              [8.24185, 50.03466],
              [8.242684, 50.034482],
              [8.243308, 50.034349],
              [8.243467, 50.034339],
              [8.243545, 50.034404],
              [8.243654, 50.034409],
              [8.243831, 50.034779],
              [8.244304, 50.035349],
              [8.24497, 50.036353],
              [8.245539, 50.03712],
              [8.245705, 50.037343],
              [8.247237, 50.039763],
              [8.247906, 50.040851],
              [8.248343, 50.041553],
              [8.248407, 50.041741],
              [8.248359, 50.041983],
              [8.24814, 50.042218],
              [8.248034, 50.042422],
              [8.248347, 50.042638],
              [8.249344, 50.043011],
              [8.250522, 50.043281],
              [8.25133, 50.04354],
              [8.25165, 50.04373],
              [8.25189, 50.04392],
              [8.2524, 50.04435],
              [8.25294, 50.0446],
              [8.25404, 50.04474],
              [8.25438, 50.04474],
              [8.25507, 50.04476],
              [8.25538, 50.04489],
              [8.25543, 50.04495],
              [8.25557, 50.045],
              [8.2559, 50.04492],
              [8.25589, 50.04521],
              [8.25635, 50.04768],
              [8.2563, 50.04801],
              [8.25653, 50.04925],
              [8.2566, 50.04981],
              [8.25667, 50.05047],
              [8.25669, 50.05118],
              [8.25675, 50.05193],
              [8.25689, 50.05246],
              [8.25696, 50.05258],
              [8.25716, 50.05306],
              [8.2574, 50.05368],
              [8.25738, 50.0541],
              [8.25735, 50.05435],
              [8.25713, 50.05489],
              [8.25671, 50.05551],
              [8.25656, 50.05571],
              [8.25635, 50.05596],
              [8.25549, 50.05732],
              [8.25525, 50.05778],
              [8.25585, 50.05791],
              [8.25748, 50.05827],
              [8.25813, 50.05839],
              [8.25701, 50.0597],
              [8.255774, 50.061604],
              [8.25575, 50.06164],
              [8.25538, 50.0623],
              [8.25525, 50.06241],
              [8.25528, 50.06252],
              [8.2575, 50.06298],
              [8.259, 50.06328],
              [8.259471, 50.063378],
              [8.26044, 50.06358],
              [8.25922, 50.06562],
              [8.25896, 50.06597],
              [8.25783, 50.06699],
              [8.25661, 50.0681],
              [8.25458, 50.06769],
              [8.25224, 50.06719],
              [8.251328, 50.066994],
              [8.25131, 50.06699],
              [8.25007, 50.06672],
              [8.24938, 50.06803],
              [8.24926, 50.06801],
              [8.24799, 50.06779],
              [8.24773, 50.06783],
              [8.2477, 50.06781],
              [8.24693, 50.06863],
              [8.24657, 50.06915],
              [8.24608, 50.07029],
              [8.24541, 50.07039],
              [8.24532, 50.07051],
              [8.24525, 50.07065],
              [8.24518, 50.07077],
              [8.24505, 50.071],
              [8.24475, 50.07159],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.236676, 50.077684],
              [8.23644, 50.07835],
              [8.23639, 50.07893],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.2363, 50.08098],
              [8.23627, 50.08116],
              [8.23625, 50.08138],
              [8.23623, 50.08182],
              [8.23617, 50.08226],
              [8.23615, 50.08241],
              [8.23611, 50.08276],
              [8.23607, 50.08306],
              [8.23593, 50.08327],
              [8.23581, 50.08327],
              [8.23435, 50.08355],
              [8.23274, 50.08378],
              [8.23253, 50.08382],
              [8.23155, 50.08398],
              [8.23147, 50.08399],
              [8.23086, 50.0841],
              [8.23042, 50.08417],
              [8.22974, 50.08431],
              [8.22941, 50.08442],
              [8.22927, 50.08448],
              [8.22842, 50.08487],
              [8.22796, 50.0851],
              [8.22741, 50.08538],
              [8.22709, 50.08552],
              [8.226101, 50.085576],
              [8.22568, 50.0856],
              [8.22547, 50.08541],
              [8.2254, 50.0853],
              [8.225, 50.08456],
              [8.22493, 50.08423],
              [8.22489, 50.08353],
              [8.223996, 50.08309],
              [8.22383, 50.08284],
              [8.22357, 50.08264],
              [8.22283, 50.08263],
              [8.22241, 50.08265],
              [8.22087, 50.08261],
              [8.2206, 50.08256],
              [8.21996, 50.08234],
              [8.21935, 50.08198],
              [8.21884, 50.08148],
              [8.2183, 50.08162],
              [8.21777, 50.08189],
              [8.21747, 50.08199],
              [8.21621, 50.08235],
              [8.21553, 50.08232],
              [8.21466, 50.08234],
              [8.21173, 50.083],
              [8.21007, 50.08364],
              [8.20872, 50.08415],
              [8.20825, 50.08436],
              [8.20814, 50.08448],
              [8.20807, 50.08447],
              [8.20506, 50.0857],
              [8.20394, 50.08612],
              [8.20242, 50.0865],
              [8.2006, 50.08696],
              [8.20028, 50.08703],
              [8.19923, 50.0872],
              [8.199, 50.08722],
              [8.19867, 50.0885],
              [8.19866, 50.08924],
              [8.19895, 50.09005],
              [8.19728, 50.09015],
              [8.1965, 50.0902],
              [8.19574, 50.09026],
              [8.19544, 50.09037],
              [8.19523, 50.09061],
              [8.19522, 50.09086],
              [8.19537, 50.09102],
              [8.19596, 50.09126],
              [8.19682, 50.09162],
              [8.19716, 50.09173],
              [8.19796, 50.09199],
              [8.19933, 50.09207],
            ],
          ],
        },
        id: "Y2NzI",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "Y5MzcABC",
    name: "16 Schierstein <> Bierstadt",
    lineName: "16",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.283804, 50.093033],
              [8.284733, 50.093082],
              [8.284733, 50.093082],
              [8.284759, 50.09211],
              [8.28476, 50.092066],
              [8.285233, 50.09182],
              [8.28531, 50.09178],
              [8.28517, 50.09077],
              [8.286326, 50.090475],
              [8.2867, 50.09038],
              [8.28695, 50.09016],
              [8.28696, 50.08938],
              [8.28694, 50.0889],
              [8.28644, 50.08854],
              [8.28514, 50.08809],
              [8.28528, 50.0878],
              [8.28533, 50.08683],
              [8.283874, 50.086486],
              [8.28186, 50.08601],
              [8.28244, 50.08508],
              [8.28281, 50.08454],
              [8.28318, 50.08403],
              [8.28332, 50.08368],
              [8.28178, 50.08347],
              [8.28095, 50.08333],
              [8.28072, 50.08331],
              [8.28057, 50.0833],
              [8.27913, 50.08398],
              [8.27866, 50.08419],
              [8.27768, 50.084646],
              [8.27724, 50.08485],
              [8.27667, 50.08509],
              [8.27653, 50.08513],
              [8.27646, 50.08499],
              [8.27639, 50.0845],
              [8.27592, 50.0821],
              [8.27564, 50.08145],
              [8.27509, 50.08079],
              [8.27432, 50.08005],
              [8.27249, 50.07865],
              [8.27222, 50.07837],
              [8.27213, 50.07804],
              [8.27197, 50.07767],
              [8.27189, 50.07697],
              [8.27295, 50.07323],
              [8.27336, 50.07236],
              [8.2741, 50.07145],
              [8.27499, 50.07057],
              [8.27559, 50.06975],
              [8.27532, 50.06942],
              [8.27449, 50.06927],
              [8.27314, 50.06898],
              [8.27357, 50.06832],
              [8.27366, 50.06819],
              [8.27391, 50.06774],
              [8.273899, 50.067579],
              [8.27388, 50.06731],
              [8.27289, 50.06612],
              [8.27424, 50.06578],
              [8.27541, 50.06543],
              [8.27528, 50.06527],
              [8.27472, 50.06458],
              [8.27379, 50.06344],
              [8.27373, 50.06336],
              [8.27305, 50.06258],
              [8.27281, 50.06239],
              [8.272768, 50.06237],
              [8.27227, 50.06213],
              [8.27222, 50.06205],
              [8.27207, 50.06204],
              [8.27188, 50.0619],
              [8.26826, 50.06088],
              [8.26816, 50.06085],
              [8.26748, 50.06067],
              [8.26731, 50.06062],
              [8.26483, 50.05996],
              [8.2621, 50.05925],
              [8.26194, 50.05921],
              [8.26147, 50.05909],
              [8.260677, 50.058923],
              [8.26024, 50.05883],
              [8.26008, 50.0588],
              [8.25948, 50.05868],
              [8.25813, 50.05839],
              [8.25748, 50.05827],
              [8.25687, 50.05822],
              [8.25567, 50.05824],
              [8.254871, 50.05836],
              [8.2548, 50.05837],
              [8.254, 50.05851],
              [8.25383, 50.0585],
              [8.25345, 50.05843],
              [8.25327, 50.05837],
              [8.25226, 50.05803],
              [8.25144, 50.05779],
              [8.25083, 50.05772],
              [8.25063, 50.05775],
              [8.25024, 50.05786],
              [8.25004, 50.05799],
              [8.24967, 50.05849],
              [8.24919, 50.05972],
              [8.24835, 50.06087],
              [8.24763, 50.06156],
              [8.24681, 50.06212],
              [8.24614, 50.0623],
              [8.24601, 50.06231],
              [8.24491, 50.06214],
              [8.24376, 50.06181],
              [8.24233, 50.06158],
              [8.24212, 50.06155],
              [8.24197, 50.06154],
              [8.24098, 50.06145],
              [8.23998, 50.06139],
              [8.23966, 50.0615],
              [8.23897, 50.06104],
              [8.23797, 50.06069],
              [8.23697, 50.06032],
              [8.23612, 50.05995],
              [8.23591, 50.05977],
              [8.23523, 50.0599],
              [8.23512, 50.05972],
              [8.2351, 50.05946],
              [8.23508, 50.05897],
              [8.23492, 50.05889],
              [8.23503, 50.0588],
              [8.23485, 50.05837],
              [8.23489, 50.05795],
              [8.23592, 50.05674],
              [8.237503, 50.054518],
              [8.2379, 50.05396],
              [8.23844, 50.05297],
              [8.2387, 50.05207],
              [8.23897, 50.05206],
              [8.2403, 50.05184],
              [8.24125, 50.05145],
              [8.24204, 50.05124],
              [8.24183, 50.050709],
              [8.24164, 50.05023],
              [8.24127, 50.04932],
              [8.24115, 50.04901],
              [8.24104, 50.04875],
              [8.24097, 50.04827],
              [8.24095, 50.04785],
              [8.24096, 50.04769],
              [8.24103, 50.04725],
              [8.24105, 50.04685],
              [8.24108, 50.04583],
              [8.24111, 50.04482],
              [8.24084, 50.04427],
              [8.24075, 50.04406],
              [8.24066, 50.04387],
              [8.24026, 50.04295],
              [8.23988, 50.04208],
              [8.23971, 50.04173],
              [8.23963, 50.04157],
              [8.23925, 50.04075],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23706, 50.03668],
              [8.23684, 50.03639],
              [8.23645, 50.03653],
              [8.23523, 50.03691],
              [8.23501, 50.03695],
              [8.23292, 50.0373],
              [8.23146, 50.03754],
              [8.23124, 50.03758],
              [8.23113, 50.0376],
              [8.23087, 50.03766],
              [8.2282, 50.03868],
              [8.2263, 50.03916],
              [8.22679, 50.03993],
              [8.22769, 50.04186],
              [8.22856, 50.04299],
              [8.2291, 50.04363],
              [8.22949, 50.0441],
              [8.23049, 50.04529],
              [8.230907, 50.045816],
              [8.23091, 50.04582],
              [8.23103, 50.04612],
              [8.23105, 50.04625],
              [8.23121, 50.04728],
              [8.23026, 50.0471],
              [8.22902, 50.04688],
              [8.22746, 50.04661],
              [8.22621, 50.04638],
              [8.22592, 50.04633],
              [8.22574, 50.0463],
              [8.22506, 50.04618],
              [8.22458, 50.04609],
              [8.22413, 50.04601],
              [8.22381, 50.04595],
              [8.22319, 50.04584],
              [8.22238, 50.0457],
              [8.22199, 50.04563],
              [8.22056, 50.04537],
              [8.220429, 50.046125],
              [8.220218, 50.04734],
              [8.219971, 50.048853],
              [8.217355, 50.048694],
              [8.215933, 50.048608],
              [8.21583, 50.0486],
              [8.21321, 50.04846],
              [8.21309, 50.04846],
              [8.21064, 50.04828],
              [8.209539, 50.048191],
              [8.20817, 50.04808],
              [8.205291, 50.047819],
              [8.20431, 50.04773],
              [8.2023, 50.04757],
              [8.20195, 50.04766],
              [8.20113, 50.04675],
              [8.20083, 50.04642],
              [8.20039, 50.04593],
              [8.19923, 50.04589],
              [8.19767, 50.04577],
              [8.19617, 50.04565],
              [8.19585, 50.04563],
              [8.19456, 50.04562],
              [8.19392, 50.04562],
              [8.19219, 50.04532],
              [8.19174, 50.04524],
              [8.19042, 50.04504],
              [8.19007, 50.04548],
              [8.18957, 50.04619],
              [8.18927, 50.04654],
              [8.18667, 50.04678],
              [8.18628, 50.04672],
              [8.18616, 50.04651],
              [8.18659, 50.0458],
              [8.18686, 50.04559],
            ],
          ],
        },
        id: "Y5MzcABC",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.186853, 50.045551],
              [8.187153, 50.0454],
              [8.187733, 50.045317],
              [8.188119, 50.045303],
              [8.18887, 50.045359],
              [8.189406, 50.045386],
              [8.19007, 50.04548],
              [8.19042, 50.04504],
              [8.19174, 50.04524],
              [8.19219, 50.04532],
              [8.19392, 50.04562],
              [8.19456, 50.04562],
              [8.19504, 50.04508],
              [8.19513, 50.04504],
              [8.19537, 50.04497],
              [8.19566, 50.04488],
              [8.19611, 50.04466],
              [8.19626, 50.04461],
              [8.19703, 50.04454],
              [8.19717, 50.04454],
              [8.19766, 50.04455],
              [8.19796, 50.04455],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.20083, 50.04642],
              [8.20113, 50.04675],
              [8.20195, 50.04766],
              [8.2023, 50.04757],
              [8.20431, 50.04773],
              [8.205291, 50.047819],
              [8.20817, 50.04808],
              [8.209539, 50.048191],
              [8.21064, 50.04828],
              [8.21309, 50.04846],
              [8.21321, 50.04846],
              [8.21583, 50.0486],
              [8.215933, 50.048608],
              [8.217355, 50.048694],
              [8.219971, 50.048853],
              [8.220218, 50.04734],
              [8.220429, 50.046125],
              [8.22056, 50.04537],
              [8.22199, 50.04563],
              [8.22238, 50.0457],
              [8.22319, 50.04584],
              [8.22381, 50.04595],
              [8.22413, 50.04601],
              [8.22458, 50.04609],
              [8.22506, 50.04618],
              [8.22574, 50.0463],
              [8.22592, 50.04633],
              [8.22621, 50.04638],
              [8.22746, 50.04661],
              [8.22902, 50.04688],
              [8.23026, 50.0471],
              [8.23121, 50.04728],
              [8.23105, 50.04625],
              [8.23103, 50.04612],
              [8.23091, 50.04582],
              [8.230907, 50.045816],
              [8.23049, 50.04529],
              [8.22949, 50.0441],
              [8.2291, 50.04363],
              [8.22856, 50.04299],
              [8.22769, 50.04186],
              [8.22679, 50.03993],
              [8.2263, 50.03916],
              [8.2282, 50.03868],
              [8.23087, 50.03766],
              [8.23113, 50.0376],
              [8.23124, 50.03758],
              [8.23146, 50.03754],
              [8.23292, 50.0373],
              [8.23501, 50.03695],
              [8.23523, 50.03691],
              [8.23645, 50.03653],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23862, 50.03686],
              [8.23911, 50.03682],
              [8.23916, 50.0369],
              [8.23984, 50.03824],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.24111, 50.04086],
              [8.24119, 50.04111],
              [8.24123, 50.04126],
              [8.24122, 50.04133],
              [8.24118, 50.04279],
              [8.24116, 50.04376],
              [8.24115, 50.04427],
              [8.24111, 50.04482],
              [8.24108, 50.04583],
              [8.24105, 50.04685],
              [8.24103, 50.04725],
              [8.24096, 50.04769],
              [8.24095, 50.04785],
              [8.24097, 50.04827],
              [8.24104, 50.04875],
              [8.24115, 50.04901],
              [8.24127, 50.04932],
              [8.24164, 50.05023],
              [8.24183, 50.050709],
              [8.24204, 50.05124],
              [8.24125, 50.05145],
              [8.2403, 50.05184],
              [8.23897, 50.05206],
              [8.2387, 50.05207],
              [8.23844, 50.05297],
              [8.2379, 50.05396],
              [8.237503, 50.054518],
              [8.23592, 50.05674],
              [8.23489, 50.05795],
              [8.23485, 50.05837],
              [8.23503, 50.0588],
              [8.23492, 50.05889],
              [8.23508, 50.05897],
              [8.2351, 50.05946],
              [8.23512, 50.05972],
              [8.23523, 50.0599],
              [8.23591, 50.05977],
              [8.23612, 50.05995],
              [8.23697, 50.06032],
              [8.23797, 50.06069],
              [8.23897, 50.06104],
              [8.23966, 50.0615],
              [8.23998, 50.06139],
              [8.24098, 50.06145],
              [8.24197, 50.06154],
              [8.24212, 50.06155],
              [8.24233, 50.06158],
              [8.24376, 50.06181],
              [8.24491, 50.06214],
              [8.24601, 50.06231],
              [8.24614, 50.0623],
              [8.24681, 50.06212],
              [8.24763, 50.06156],
              [8.24835, 50.06087],
              [8.24919, 50.05972],
              [8.24967, 50.05849],
              [8.25004, 50.05799],
              [8.25024, 50.05786],
              [8.25063, 50.05775],
              [8.25083, 50.05772],
              [8.25144, 50.05779],
              [8.25226, 50.05803],
              [8.25327, 50.05837],
              [8.25345, 50.05843],
              [8.25383, 50.0585],
              [8.254, 50.05851],
              [8.2548, 50.05837],
              [8.254871, 50.05836],
              [8.25567, 50.05824],
              [8.25687, 50.05822],
              [8.25748, 50.05827],
              [8.25813, 50.05839],
              [8.25948, 50.05868],
              [8.26008, 50.0588],
              [8.26024, 50.05883],
              [8.260677, 50.058923],
              [8.26147, 50.05909],
              [8.26194, 50.05921],
              [8.2621, 50.05925],
              [8.26483, 50.05996],
              [8.26731, 50.06062],
              [8.26748, 50.06067],
              [8.26816, 50.06085],
              [8.26826, 50.06088],
              [8.27188, 50.0619],
              [8.27207, 50.06204],
              [8.27222, 50.06205],
              [8.27227, 50.06213],
              [8.272768, 50.06237],
              [8.27281, 50.06239],
              [8.27305, 50.06258],
              [8.27373, 50.06336],
              [8.27379, 50.06344],
              [8.27472, 50.06458],
              [8.27528, 50.06527],
              [8.27541, 50.06543],
              [8.27424, 50.06578],
              [8.27289, 50.06612],
              [8.27388, 50.06731],
              [8.273899, 50.067579],
              [8.27391, 50.06774],
              [8.27366, 50.06819],
              [8.27357, 50.06832],
              [8.27314, 50.06898],
              [8.27449, 50.06927],
              [8.27532, 50.06942],
              [8.27559, 50.06975],
              [8.27499, 50.07057],
              [8.2741, 50.07145],
              [8.27336, 50.07236],
              [8.27295, 50.07323],
              [8.27189, 50.07697],
              [8.27197, 50.07767],
              [8.27213, 50.07804],
              [8.27293, 50.07885],
              [8.27424, 50.07987],
              [8.27522, 50.08079],
              [8.27564, 50.08145],
              [8.27592, 50.0821],
              [8.27639, 50.0845],
              [8.27667, 50.08509],
              [8.27724, 50.08485],
              [8.27768, 50.084646],
              [8.27866, 50.08419],
              [8.27913, 50.08398],
              [8.28057, 50.0833],
              [8.28069, 50.08326],
              [8.28078, 50.08323],
              [8.28085, 50.0832],
              [8.28096, 50.08316],
              [8.28119, 50.08307],
              [8.28198, 50.08271],
              [8.28196, 50.08302],
              [8.28178, 50.08347],
              [8.28145, 50.0844],
              [8.28129, 50.08482],
              [8.28097, 50.08559],
              [8.28186, 50.08601],
              [8.283874, 50.086486],
              [8.28533, 50.08683],
              [8.28528, 50.0878],
              [8.28514, 50.08809],
              [8.28644, 50.08854],
              [8.28694, 50.0889],
              [8.28696, 50.08938],
              [8.28695, 50.09016],
              [8.2867, 50.09038],
              [8.286326, 50.090475],
              [8.28517, 50.09077],
              [8.28465, 50.09075],
              [8.28413, 50.09066],
              [8.28347, 50.09045],
              [8.28286, 50.09067],
              [8.28303, 50.09092],
              [8.283031, 50.090928],
              [8.28311, 50.09161],
              [8.282716, 50.092975],
              [8.282715, 50.092976],
              [8.283804, 50.093033],
            ],
          ],
        },
        id: "kxMDM",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "c0NDU",
    name: "26 Schierstein <> Breckenheim",
    lineName: "26",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.3718, 50.0807],
              [8.37242, 50.08057],
              [8.37287, 50.08056],
              [8.37374, 50.08065],
              [8.37452, 50.07982],
              [8.37489, 50.07958],
              [8.37499, 50.07937],
              [8.37465, 50.07788],
              [8.37436, 50.07782],
              [8.3725, 50.07749],
              [8.37189, 50.07697],
              [8.37134, 50.07682],
              [8.37019, 50.0766],
              [8.3703, 50.07598],
              [8.37019, 50.07552],
              [8.36962, 50.07504],
              [8.36966, 50.07487],
              [8.37036, 50.07444],
              [8.37762, 50.06886],
              [8.37786, 50.06908],
              [8.37972, 50.07086],
              [8.38042, 50.07178],
              [8.38197, 50.07117],
              [8.381477, 50.070654],
              [8.38051, 50.06964],
              [8.38078, 50.06905],
              [8.38146, 50.06766],
              [8.38193, 50.06662],
              [8.38215, 50.06634],
              [8.38215, 50.06634],
              [8.3838, 50.06666],
              [8.38429, 50.06543],
              [8.384732, 50.064558],
              [8.38502, 50.06399],
              [8.3849, 50.06299],
              [8.38403, 50.06308],
              [8.38333, 50.06344],
              [8.3831, 50.06392],
              [8.38293, 50.06453],
              [8.38248, 50.06484],
              [8.38177, 50.06494],
              [8.38126, 50.0648],
              [8.38198, 50.06387],
              [8.3822, 50.06327],
              [8.38219, 50.06311],
              [8.3821, 50.06273],
              [8.38147, 50.06185],
              [8.38102, 50.06154],
              [8.38034, 50.06123],
              [8.38009, 50.0612],
              [8.37991, 50.06118],
              [8.37921, 50.06108],
              [8.37787, 50.06103],
              [8.37751, 50.06172],
              [8.37662, 50.06223],
              [8.37603, 50.06249],
              [8.37568, 50.063],
              [8.375464, 50.063281],
              [8.37498, 50.06391],
              [8.37459, 50.06428],
              [8.37446, 50.06444],
              [8.37407, 50.06435],
              [8.37341, 50.06416],
              [8.37264, 50.06396],
              [8.3724, 50.06429],
              [8.37094, 50.06389],
              [8.36979, 50.0637],
              [8.36946, 50.06434],
              [8.3692, 50.06474],
              [8.36832, 50.06445],
              [8.367509, 50.064107],
              [8.36709, 50.06393],
              [8.36534, 50.06312],
              [8.36516, 50.06256],
              [8.36499, 50.06211],
              [8.36548, 50.06203],
              [8.36565, 50.062],
              [8.366, 50.0619],
              [8.366049, 50.061875],
              [8.36677, 50.0615],
              [8.36702, 50.06132],
              [8.36764, 50.06086],
              [8.36854, 50.06017],
              [8.36896, 50.05985],
              [8.36941, 50.05944],
              [8.36956, 50.05931],
              [8.37014, 50.05892],
              [8.36965, 50.05843],
              [8.36947, 50.05784],
              [8.36956, 50.05737],
              [8.36971, 50.05691],
              [8.36977, 50.05668],
              [8.3698, 50.0566],
              [8.3703, 50.05514],
              [8.37075, 50.054669],
              [8.37106, 50.05444],
              [8.37124, 50.05431],
              [8.37177, 50.05391],
              [8.371776, 50.053906],
              [8.372276, 50.053541],
              [8.371776, 50.053906],
              [8.368689, 50.053904],
              [8.36676, 50.054151],
              [8.363348, 50.054234],
              [8.361732, 50.053789],
              [8.360182, 50.053113],
              [8.35977, 50.053113],
              [8.35916, 50.05336],
              [8.358204, 50.052453],
              [8.357693, 50.052173],
              [8.35733, 50.05203],
              [8.3566, 50.05274],
              [8.35541, 50.0534],
              [8.35214, 50.05487],
              [8.35164, 50.0551],
              [8.35057, 50.05548],
              [8.34392, 50.05553],
              [8.34329, 50.0555],
              [8.34251, 50.05522],
              [8.34203, 50.05487],
              [8.3419, 50.0548],
              [8.34148, 50.05472],
              [8.34075, 50.05474],
              [8.34008, 50.05501],
              [8.33977, 50.05538],
              [8.33972, 50.05571],
              [8.33989, 50.05609],
              [8.34012, 50.05632],
              [8.34063, 50.05682],
              [8.34052, 50.05699],
              [8.34034, 50.05706],
              [8.33782, 50.05711],
              [8.33428, 50.05721],
              [8.333624, 50.057262],
              [8.32898, 50.05763],
              [8.32815, 50.05787],
              [8.32779, 50.05824],
              [8.32774, 50.05845],
              [8.32786, 50.05888],
              [8.32809, 50.05924],
              [8.3296, 50.06161],
              [8.32765, 50.06198],
              [8.32313, 50.06285],
              [8.32128, 50.06317],
              [8.31924, 50.06335],
              [8.318013, 50.063769],
              [8.31675, 50.0642],
              [8.31475, 50.06208],
              [8.31197, 50.06048],
              [8.31092, 50.0599],
              [8.3095, 50.0588],
              [8.30931, 50.05866],
              [8.30861, 50.05811],
              [8.30843, 50.05795],
              [8.30832, 50.05782],
              [8.30823, 50.05771],
              [8.30782, 50.05719],
              [8.30756, 50.05684],
              [8.30742, 50.05662],
              [8.3074, 50.05626],
              [8.307443, 50.056],
              [8.3075, 50.05566],
              [8.30745, 50.05564],
              [8.30731, 50.05561],
              [8.307, 50.05556],
              [8.30667, 50.0555],
              [8.3056, 50.05533],
              [8.30534, 50.05529],
              [8.3054, 50.05481],
              [8.30536, 50.05429],
              [8.30489, 50.05358],
              [8.30465, 50.05326],
              [8.30433, 50.05313],
              [8.30262, 50.05332],
              [8.30228, 50.05341],
              [8.30134, 50.05374],
              [8.30126, 50.05388],
              [8.30106, 50.05387],
              [8.30079, 50.05403],
              [8.30044, 50.05423],
              [8.29903, 50.05507],
              [8.29648, 50.05603],
              [8.29528, 50.05647],
              [8.29482, 50.05664],
              [8.29192, 50.0577],
              [8.29112, 50.057992],
              [8.28992, 50.05843],
              [8.28958, 50.05807],
              [8.28856, 50.05714],
              [8.28747, 50.05666],
              [8.28677, 50.05657],
              [8.28659, 50.05657],
              [8.28563, 50.05675],
              [8.28489, 50.05718],
              [8.28482, 50.05726],
              [8.2847, 50.05764],
              [8.28497, 50.05791],
              [8.2854, 50.05797],
              [8.28598, 50.05764],
              [8.28659, 50.05657],
              [8.28676, 50.05631],
              [8.2874, 50.05518],
              [8.28844, 50.05372],
              [8.28981, 50.05228],
              [8.29017, 50.0518],
              [8.29012, 50.05131],
              [8.28998, 50.05104],
              [8.28955, 50.05056],
              [8.28882, 50.0504],
              [8.28564, 50.05047],
              [8.28439, 50.05068],
              [8.28417, 50.05072],
              [8.27785, 50.05164],
              [8.27127, 50.05227],
              [8.26665, 50.05264],
              [8.26423, 50.0528],
              [8.2625, 50.05285],
              [8.2617, 50.05283],
              [8.25937, 50.0527],
              [8.2581, 50.05258],
              [8.25633, 50.05242],
              [8.25506, 50.05231],
              [8.25123, 50.05214],
              [8.24881, 50.0522],
              [8.24786, 50.05224],
              [8.24712, 50.05228],
              [8.24388, 50.05278],
              [8.24284, 50.05285],
              [8.24267, 50.05281],
              [8.24235, 50.05199],
              [8.24224, 50.05171],
              [8.2422, 50.05161],
              [8.24204, 50.05124],
              [8.24183, 50.050709],
              [8.24164, 50.05023],
              [8.24127, 50.04932],
              [8.24115, 50.04901],
              [8.24104, 50.04875],
              [8.24097, 50.04827],
              [8.24095, 50.04785],
              [8.24096, 50.04769],
              [8.24103, 50.04725],
              [8.24111, 50.04482],
              [8.24084, 50.04427],
              [8.24075, 50.04406],
              [8.24066, 50.04387],
              [8.24026, 50.04295],
              [8.23988, 50.04208],
              [8.23971, 50.04173],
              [8.23963, 50.04157],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23684, 50.03639],
              [8.23645, 50.03653],
              [8.23523, 50.03691],
              [8.23501, 50.03695],
              [8.23292, 50.0373],
              [8.23146, 50.03754],
              [8.23124, 50.03758],
              [8.23113, 50.0376],
              [8.23087, 50.03766],
              [8.2282, 50.03868],
              [8.2263, 50.03916],
              [8.22679, 50.03993],
              [8.22769, 50.04186],
              [8.22856, 50.04299],
              [8.2291, 50.04363],
              [8.22949, 50.0441],
              [8.23049, 50.04529],
              [8.230907, 50.045816],
              [8.23091, 50.04582],
              [8.23103, 50.04612],
              [8.23105, 50.04625],
              [8.23121, 50.04728],
              [8.23026, 50.0471],
              [8.22902, 50.04688],
              [8.22746, 50.04661],
              [8.22621, 50.04638],
              [8.22592, 50.04633],
              [8.22574, 50.0463],
              [8.22506, 50.04618],
              [8.22458, 50.04609],
              [8.22413, 50.04601],
              [8.22381, 50.04595],
              [8.22319, 50.04584],
              [8.22238, 50.0457],
              [8.22199, 50.04563],
              [8.22056, 50.04537],
              [8.220429, 50.046125],
              [8.220218, 50.04734],
              [8.219971, 50.048853],
              [8.217355, 50.048694],
              [8.215933, 50.048608],
              [8.21583, 50.0486],
              [8.21321, 50.04846],
              [8.21309, 50.04846],
              [8.21064, 50.04828],
              [8.209539, 50.048191],
              [8.20817, 50.04808],
              [8.205291, 50.047819],
              [8.20431, 50.04773],
              [8.2023, 50.04757],
              [8.20195, 50.04766],
              [8.20113, 50.04675],
              [8.20083, 50.04642],
              [8.20039, 50.04593],
              [8.1996, 50.04504],
              [8.19938, 50.0446],
              [8.20059, 50.04463],
              [8.20151, 50.04462],
              [8.20189, 50.04456],
              [8.2027, 50.0444],
              [8.20368, 50.04418],
              [8.20476, 50.04394],
              [8.20437, 50.04316],
              [8.20415, 50.043],
              [8.20387, 50.04291],
              [8.20267, 50.04289],
              [8.20179, 50.04292],
              [8.20146, 50.0429],
              [8.200414, 50.042722],
            ],
          ],
        },
        id: "c0NDU",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.200414, 50.042722],
              [8.20005, 50.04266],
              [8.19974, 50.04342],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.20083, 50.04642],
              [8.20113, 50.04675],
              [8.20195, 50.04766],
              [8.2023, 50.04757],
              [8.20431, 50.04773],
              [8.205291, 50.047819],
              [8.20817, 50.04808],
              [8.209539, 50.048191],
              [8.21064, 50.04828],
              [8.21309, 50.04846],
              [8.21321, 50.04846],
              [8.21583, 50.0486],
              [8.215933, 50.048608],
              [8.217355, 50.048694],
              [8.219971, 50.048853],
              [8.220218, 50.04734],
              [8.220429, 50.046125],
              [8.22056, 50.04537],
              [8.22199, 50.04563],
              [8.22238, 50.0457],
              [8.22319, 50.04584],
              [8.22381, 50.04595],
              [8.22413, 50.04601],
              [8.22458, 50.04609],
              [8.22506, 50.04618],
              [8.22574, 50.0463],
              [8.22592, 50.04633],
              [8.22621, 50.04638],
              [8.22746, 50.04661],
              [8.22902, 50.04688],
              [8.23026, 50.0471],
              [8.23121, 50.04728],
              [8.23105, 50.04625],
              [8.23103, 50.04612],
              [8.23091, 50.04582],
              [8.230907, 50.045816],
              [8.23049, 50.04529],
              [8.22949, 50.0441],
              [8.2291, 50.04363],
              [8.22856, 50.04299],
              [8.22769, 50.04186],
              [8.22679, 50.03993],
              [8.2263, 50.03916],
              [8.2282, 50.03868],
              [8.23087, 50.03766],
              [8.23113, 50.0376],
              [8.23124, 50.03758],
              [8.23146, 50.03754],
              [8.23292, 50.0373],
              [8.23501, 50.03695],
              [8.23523, 50.03691],
              [8.23645, 50.03653],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23862, 50.03686],
              [8.23911, 50.03682],
              [8.23916, 50.0369],
              [8.23984, 50.03824],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.24111, 50.04086],
              [8.24119, 50.04111],
              [8.24123, 50.04126],
              [8.24122, 50.04133],
              [8.24118, 50.04279],
              [8.24116, 50.04376],
              [8.24115, 50.04427],
              [8.24111, 50.04482],
              [8.24103, 50.04725],
              [8.24108, 50.04768],
              [8.24109, 50.04783],
              [8.2411, 50.04823],
              [8.24126, 50.04884],
              [8.24131, 50.04897],
              [8.24146, 50.04929],
              [8.24179, 50.05015],
              [8.241973, 50.050675],
              [8.24216, 50.05121],
              [8.24235, 50.05159],
              [8.24377, 50.05158],
              [8.24555, 50.05216],
              [8.24677, 50.0522],
              [8.24711, 50.05218],
              [8.24798, 50.05213],
              [8.24903, 50.05208],
              [8.25171, 50.05202],
              [8.25501, 50.05218],
              [8.25631, 50.05229],
              [8.25937, 50.05259],
              [8.26171, 50.05272],
              [8.26267, 50.05272],
              [8.26464, 50.05265],
              [8.26601, 50.05255],
              [8.26665, 50.0525],
              [8.27234, 50.05206],
              [8.27645, 50.05168],
              [8.28313, 50.05075],
              [8.28416, 50.05059],
              [8.28433, 50.05056],
              [8.28562, 50.05038],
              [8.28738, 50.05015],
              [8.28794, 50.05002],
              [8.28974, 50.04944],
              [8.29093, 50.04921],
              [8.29214, 50.04936],
              [8.29264, 50.0495],
              [8.29338, 50.04963],
              [8.29456, 50.0496],
              [8.29496, 50.04953],
              [8.29538, 50.04932],
              [8.29547, 50.049],
              [8.2952, 50.04872],
              [8.29457, 50.04865],
              [8.29389, 50.04895],
              [8.29247, 50.05026],
              [8.29171, 50.05087],
              [8.29047, 50.05191],
              [8.28998, 50.05232],
              [8.28857, 50.05377],
              [8.28748, 50.05531],
              [8.2876, 50.05604],
              [8.28802, 50.0566],
              [8.28856, 50.05714],
              [8.28958, 50.05807],
              [8.28992, 50.05843],
              [8.29112, 50.057992],
              [8.29192, 50.0577],
              [8.29482, 50.05664],
              [8.29528, 50.05647],
              [8.29648, 50.05603],
              [8.29903, 50.05507],
              [8.30044, 50.05423],
              [8.30079, 50.05403],
              [8.30106, 50.05387],
              [8.30126, 50.05388],
              [8.30134, 50.05374],
              [8.30228, 50.05341],
              [8.30262, 50.05332],
              [8.30433, 50.05313],
              [8.30465, 50.05326],
              [8.30489, 50.05358],
              [8.30536, 50.05429],
              [8.3054, 50.05481],
              [8.30534, 50.05529],
              [8.3056, 50.05533],
              [8.30667, 50.0555],
              [8.307, 50.05556],
              [8.30731, 50.05561],
              [8.30745, 50.05564],
              [8.3075, 50.05566],
              [8.307443, 50.056],
              [8.3074, 50.05626],
              [8.30742, 50.05662],
              [8.30756, 50.05684],
              [8.30782, 50.05719],
              [8.30823, 50.05771],
              [8.30832, 50.05782],
              [8.30843, 50.05795],
              [8.30861, 50.05811],
              [8.30931, 50.05866],
              [8.3095, 50.0588],
              [8.31092, 50.0599],
              [8.31197, 50.06048],
              [8.31475, 50.06208],
              [8.31675, 50.0642],
              [8.318013, 50.063769],
              [8.31924, 50.06335],
              [8.32128, 50.06317],
              [8.32313, 50.06285],
              [8.32765, 50.06198],
              [8.3296, 50.06161],
              [8.32809, 50.05924],
              [8.32786, 50.05888],
              [8.32774, 50.05845],
              [8.32779, 50.05824],
              [8.32815, 50.05787],
              [8.32898, 50.05763],
              [8.333624, 50.057262],
              [8.33428, 50.05721],
              [8.33782, 50.05711],
              [8.34034, 50.05706],
              [8.34052, 50.05699],
              [8.34063, 50.05682],
              [8.34012, 50.05632],
              [8.33989, 50.05609],
              [8.33972, 50.05571],
              [8.33977, 50.05538],
              [8.34008, 50.05501],
              [8.34075, 50.05474],
              [8.34148, 50.05472],
              [8.3419, 50.0548],
              [8.34203, 50.05487],
              [8.34251, 50.05522],
              [8.34329, 50.0555],
              [8.34392, 50.05553],
              [8.35057, 50.05548],
              [8.35164, 50.0551],
              [8.35214, 50.05487],
              [8.35541, 50.0534],
              [8.3566, 50.05274],
              [8.35733, 50.05203],
              [8.357693, 50.052173],
              [8.358204, 50.052453],
              [8.35916, 50.05336],
              [8.35977, 50.053113],
              [8.360182, 50.053113],
              [8.361732, 50.053789],
              [8.363348, 50.054234],
              [8.36676, 50.054151],
              [8.368689, 50.053904],
              [8.371776, 50.053906],
              [8.372276, 50.053541],
              [8.371776, 50.053906],
              [8.37177, 50.05391],
              [8.37124, 50.05431],
              [8.37106, 50.05444],
              [8.37075, 50.054669],
              [8.3703, 50.05514],
              [8.3698, 50.0566],
              [8.36977, 50.05668],
              [8.36971, 50.05691],
              [8.36956, 50.05737],
              [8.36947, 50.05784],
              [8.36965, 50.05843],
              [8.37014, 50.05892],
              [8.36956, 50.05931],
              [8.36941, 50.05944],
              [8.36896, 50.05985],
              [8.36854, 50.06017],
              [8.36764, 50.06086],
              [8.36702, 50.06132],
              [8.36677, 50.0615],
              [8.366049, 50.061875],
              [8.366, 50.0619],
              [8.36565, 50.062],
              [8.36548, 50.06203],
              [8.36499, 50.06211],
              [8.36516, 50.06256],
              [8.36534, 50.06312],
              [8.36709, 50.06393],
              [8.367509, 50.064107],
              [8.36832, 50.06445],
              [8.3692, 50.06474],
              [8.36946, 50.06434],
              [8.37066, 50.06468],
              [8.37193, 50.06505],
              [8.3724, 50.06429],
              [8.37264, 50.06396],
              [8.37341, 50.06416],
              [8.37407, 50.06435],
              [8.37446, 50.06444],
              [8.37459, 50.06428],
              [8.37498, 50.06391],
              [8.375464, 50.063281],
              [8.37568, 50.063],
              [8.37603, 50.06249],
              [8.37662, 50.06223],
              [8.37751, 50.06172],
              [8.37787, 50.06103],
              [8.37921, 50.06108],
              [8.37991, 50.06118],
              [8.38009, 50.0612],
              [8.38034, 50.06123],
              [8.38102, 50.06154],
              [8.38147, 50.06185],
              [8.3821, 50.06273],
              [8.38219, 50.06311],
              [8.3822, 50.06327],
              [8.38198, 50.06387],
              [8.38126, 50.0648],
              [8.38177, 50.06494],
              [8.38248, 50.06484],
              [8.38293, 50.06453],
              [8.3831, 50.06392],
              [8.38333, 50.06344],
              [8.38403, 50.06308],
              [8.3849, 50.06299],
              [8.38502, 50.06399],
              [8.384732, 50.064558],
              [8.38429, 50.06543],
              [8.3838, 50.06666],
              [8.38215, 50.06634],
              [8.38215, 50.06634],
              [8.38193, 50.06662],
              [8.38146, 50.06766],
              [8.38078, 50.06905],
              [8.38051, 50.06964],
              [8.381477, 50.070654],
              [8.38197, 50.07117],
              [8.38042, 50.07178],
              [8.37972, 50.07086],
              [8.37786, 50.06908],
              [8.37762, 50.06886],
              [8.37036, 50.07444],
              [8.36966, 50.07487],
              [8.36962, 50.07504],
              [8.37019, 50.07552],
              [8.3703, 50.07598],
              [8.36969, 50.07766],
              [8.36927, 50.07845],
              [8.3692, 50.07861],
              [8.36845, 50.07956],
              [8.36808, 50.0803],
              [8.36754, 50.08159],
              [8.367525, 50.081606],
              [8.36726, 50.08188],
              [8.36665, 50.08252],
              [8.36658, 50.0826],
              [8.36704, 50.08272],
              [8.36797, 50.08295],
              [8.36915, 50.08262],
              [8.3693, 50.08258],
              [8.36983, 50.08237],
              [8.37, 50.08232],
              [8.3703, 50.08214],
              [8.371, 50.08138],
              [8.37128, 50.08109],
              [8.3718, 50.0807],
            ],
          ],
        },
        id: "U3MTY",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "A1NzQ",
    name: "15 Kohlheck <> Niederjosbach",
    lineName: "15",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.350908, 50.153964],
              [8.35091, 50.15399],
              [8.35076, 50.15433],
              [8.35024, 50.15511],
              [8.35064, 50.15522],
              [8.35089, 50.15541],
              [8.35101, 50.15568],
              [8.35102, 50.15607],
              [8.35092, 50.15636],
              [8.35068, 50.15669],
              [8.35028, 50.15715],
              [8.35024, 50.15747],
              [8.35032, 50.15761],
              [8.35068, 50.15797],
              [8.35124, 50.15803],
              [8.35172, 50.15785],
              [8.351995, 50.157567],
              [8.35206, 50.1575],
              [8.35274, 50.15637],
              [8.35309, 50.15539],
              [8.35324, 50.15473],
              [8.35332, 50.15404],
              [8.35343, 50.15364],
              [8.353625, 50.153335],
              [8.35394, 50.15284],
              [8.35356, 50.15276],
              [8.35344, 50.15276],
              [8.3526, 50.15308],
              [8.35148, 50.15361],
              [8.35088, 50.1536],
              [8.35071, 50.15336],
              [8.35024, 50.15303],
              [8.35002, 50.15294],
              [8.349797, 50.152774],
              [8.34923, 50.15235],
              [8.34887, 50.15204],
              [8.3487, 50.15145],
              [8.34904, 50.15118],
              [8.35018, 50.15036],
              [8.35058, 50.14992],
              [8.35067, 50.14978],
              [8.35156, 50.14872],
              [8.35305, 50.14682],
              [8.35315, 50.14573],
              [8.353319, 50.145392],
              [8.35355, 50.14493],
              [8.35409, 50.14448],
              [8.35427, 50.14434],
              [8.35446, 50.14421],
              [8.35558, 50.14345],
              [8.35601, 50.14303],
              [8.35609, 50.14283],
              [8.35591, 50.14239],
              [8.3559, 50.14202],
              [8.35629, 50.14139],
              [8.35719, 50.14064],
              [8.35727, 50.14038],
              [8.35756, 50.14035],
              [8.35772, 50.14031],
              [8.35892, 50.1403],
              [8.35968, 50.14035],
              [8.36067, 50.14045],
              [8.36075, 50.14045],
              [8.36175, 50.14059],
              [8.36193, 50.14069],
              [8.36208, 50.14065],
              [8.36278, 50.14088],
              [8.3634, 50.14112],
              [8.36368, 50.14119],
              [8.36425, 50.14099],
              [8.36469, 50.14069],
              [8.36585, 50.13962],
              [8.36599, 50.13926],
              [8.3659, 50.13906],
              [8.36552, 50.13886],
              [8.36524, 50.13891],
              [8.3653, 50.13921],
              [8.36506, 50.13936],
              [8.36484, 50.13936],
              [8.36439, 50.13895],
              [8.364018, 50.138639],
              [8.36336, 50.13809],
              [8.36316, 50.13784],
              [8.36292, 50.13732],
              [8.36253, 50.13687],
              [8.36234, 50.13673],
              [8.361814, 50.136485],
              [8.36133, 50.13626],
              [8.36063, 50.13603],
              [8.36001, 50.13584],
              [8.35931, 50.13561],
              [8.3587, 50.13541],
              [8.35799, 50.13517],
              [8.35734, 50.13501],
              [8.3565, 50.13494],
              [8.35562, 50.13482],
              [8.355, 50.13591],
              [8.35489, 50.13603],
              [8.35466, 50.13624],
              [8.35357, 50.13739],
              [8.35246, 50.13898],
              [8.35227, 50.13938],
              [8.35335, 50.13968],
              [8.35341, 50.13989],
              [8.353576, 50.140004],
              [8.35389, 50.14022],
              [8.35402, 50.14058],
              [8.35394, 50.14092],
              [8.35371, 50.14132],
              [8.35325, 50.1421],
              [8.35279, 50.14286],
              [8.35268, 50.14316],
              [8.35182, 50.14353],
              [8.35068, 50.14378],
              [8.34997, 50.1437],
              [8.34897, 50.14346],
              [8.34859, 50.14335],
              [8.34822, 50.14308],
              [8.34794, 50.14269],
              [8.34807, 50.14185],
              [8.34807, 50.14165],
              [8.34786, 50.14138],
              [8.34742, 50.14108],
              [8.3466, 50.14063],
              [8.34638, 50.14017],
              [8.34661, 50.13918],
              [8.34493, 50.13916],
              [8.34346, 50.13917],
              [8.34293, 50.13917],
              [8.34208, 50.13922],
              [8.34183, 50.13937],
              [8.34126, 50.13921],
              [8.34039, 50.13908],
              [8.33881, 50.13912],
              [8.33843, 50.13919],
              [8.33847, 50.13852],
              [8.33834, 50.13477],
              [8.33829, 50.13282],
              [8.33798, 50.13239],
              [8.33775, 50.13218],
              [8.3374, 50.13198],
              [8.33608, 50.13135],
              [8.33564, 50.13114],
              [8.33543, 50.13103],
              [8.33517, 50.13089],
              [8.33495, 50.13071],
              [8.33489, 50.13033],
              [8.3349, 50.13013],
              [8.33497, 50.12938],
              [8.33503, 50.12869],
              [8.33527, 50.1258],
              [8.33534, 50.12496],
              [8.33554, 50.12371],
              [8.33544, 50.12227],
              [8.3354, 50.12203],
              [8.33511, 50.12103],
              [8.33481, 50.12003],
              [8.33414, 50.11901],
              [8.33385, 50.11922],
              [8.33362, 50.11956],
              [8.33348, 50.11983],
              [8.3329, 50.12017],
              [8.33159, 50.12055],
              [8.33052, 50.12099],
              [8.32934, 50.12187],
              [8.3286, 50.12219],
              [8.32752, 50.12261],
              [8.32738, 50.12266],
              [8.32646, 50.123],
              [8.32596, 50.12311],
              [8.32576, 50.1232],
              [8.32533, 50.12361],
              [8.32485, 50.12363],
              [8.32511, 50.12298],
              [8.32527, 50.1226],
              [8.32556, 50.12138],
              [8.32567, 50.12055],
              [8.32572, 50.12002],
              [8.32558, 50.11993],
              [8.32594, 50.1195],
              [8.3262, 50.11878],
              [8.32595, 50.11716],
              [8.32608, 50.1166],
              [8.326086, 50.116593],
              [8.32671, 50.11574],
              [8.32633, 50.11571],
              [8.32457, 50.11656],
              [8.32388, 50.11673],
              [8.32261, 50.11686],
              [8.32136, 50.11716],
              [8.32066, 50.1172],
              [8.31961, 50.11705],
              [8.31157, 50.11574],
              [8.30886, 50.11589],
              [8.30805, 50.1159],
              [8.30612, 50.11557],
              [8.30405, 50.11509],
              [8.30235, 50.1142],
              [8.30203, 50.11403],
              [8.30086, 50.11281],
              [8.29993, 50.11234],
              [8.29928, 50.11196],
              [8.29919, 50.11192],
              [8.29874, 50.11168],
              [8.29814, 50.11138],
              [8.29676, 50.11104],
              [8.29628, 50.11093],
              [8.29635, 50.11075],
              [8.29679, 50.11023],
              [8.29739, 50.10947],
              [8.2978, 50.10908],
              [8.2979, 50.10877],
              [8.29785, 50.1085],
              [8.29765, 50.10813],
              [8.29742, 50.10748],
              [8.29771, 50.10648],
              [8.2978, 50.1059],
              [8.29768, 50.10478],
              [8.29776, 50.10291],
              [8.29744, 50.1021],
              [8.29663, 50.10094],
              [8.29678, 50.10026],
              [8.29695, 50.10002],
              [8.29831, 50.09906],
              [8.30036, 50.0979],
              [8.30113, 50.09754],
              [8.30181, 50.09725],
              [8.30211, 50.09712],
              [8.30308, 50.09676],
              [8.30345, 50.09664],
              [8.30414, 50.09636],
              [8.30365, 50.09579],
              [8.3053, 50.09518],
              [8.30654, 50.09445],
              [8.30659, 50.09416],
              [8.30659, 50.0941],
              [8.30633, 50.09383],
              [8.30567, 50.09346],
              [8.30484, 50.09355],
              [8.30401, 50.09336],
              [8.30216, 50.09284],
              [8.30124, 50.09256],
              [8.29919, 50.09194],
              [8.29848, 50.09173],
              [8.29731, 50.09139],
              [8.2966, 50.09106],
              [8.29436, 50.08896],
              [8.29345, 50.08854],
              [8.29201, 50.08823],
              [8.29053, 50.08792],
              [8.28992, 50.08779],
              [8.287249, 50.08724],
              [8.283874, 50.086486],
              [8.28186, 50.08601],
              [8.28244, 50.08508],
              [8.28281, 50.08454],
              [8.28318, 50.08403],
              [8.28332, 50.08368],
              [8.28178, 50.08347],
              [8.28095, 50.08333],
              [8.28072, 50.08331],
              [8.28057, 50.0833],
              [8.27913, 50.08398],
              [8.27866, 50.08419],
              [8.27768, 50.084646],
              [8.27724, 50.08485],
              [8.2737, 50.08527],
              [8.27217, 50.08504],
              [8.27178, 50.08499],
              [8.27015, 50.08475],
              [8.26798, 50.08459],
              [8.26664, 50.08449],
              [8.26591, 50.08444],
              [8.26533, 50.08438],
              [8.26405, 50.08408],
              [8.26261, 50.08372],
              [8.26159, 50.08346],
              [8.26007, 50.08306],
              [8.25848, 50.08273],
              [8.25789, 50.08261],
              [8.25755, 50.08251],
              [8.25594, 50.08222],
              [8.25562, 50.08218],
              [8.25536, 50.08215],
              [8.25421, 50.0819],
              [8.25398, 50.08184],
              [8.25238, 50.08142],
              [8.25159, 50.08121],
              [8.25069, 50.08098],
              [8.250337, 50.080887],
              [8.24982, 50.08075],
              [8.2496, 50.08069],
              [8.24875, 50.08047],
              [8.24787, 50.08022],
              [8.24759, 50.08014],
              [8.24687, 50.07991],
              [8.24576, 50.08049],
              [8.24493, 50.08066],
              [8.24371, 50.0806],
              [8.24282, 50.08054],
              [8.2422, 50.0805],
              [8.24206, 50.08049],
              [8.24188, 50.08048],
              [8.23954, 50.08035],
              [8.23934, 50.08034],
              [8.23796, 50.08029],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22949, 50.08052],
              [8.22757, 50.08043],
              [8.22687, 50.08041],
              [8.22604, 50.08036],
              [8.224955, 50.080535],
              [8.22461, 50.08059],
              [8.22433, 50.07999],
              [8.22422, 50.07969],
              [8.22394, 50.07924],
              [8.22227, 50.08002],
              [8.22111, 50.08055],
              [8.2206, 50.08078],
              [8.21973, 50.08116],
              [8.21934, 50.08132],
              [8.21901, 50.08143],
              [8.21884, 50.08148],
              [8.2183, 50.08162],
              [8.21663, 50.08197],
              [8.21592, 50.08205],
              [8.21553, 50.08232],
              [8.21466, 50.08234],
              [8.21173, 50.083],
              [8.21007, 50.08364],
              [8.20872, 50.08415],
              [8.20825, 50.08436],
              [8.20808, 50.08433],
              [8.20773, 50.08402],
              [8.20765, 50.08385],
              [8.20757, 50.08355],
              [8.20792, 50.08234],
              [8.20795, 50.08208],
              [8.20827, 50.08101],
              [8.20724, 50.08082],
              [8.20659, 50.08071],
              [8.20642, 50.08069],
              [8.20566, 50.08056],
              [8.20542, 50.08052],
              [8.20471, 50.08041],
              [8.2031, 50.08022],
              [8.20282, 50.08019],
              [8.20255, 50.08014],
              [8.20131, 50.07987],
              [8.20033, 50.07975],
              [8.19996, 50.08027],
              [8.19964, 50.08054],
              [8.19903, 50.08089],
              [8.19536, 50.08274],
              [8.19454, 50.08345],
              [8.19419, 50.08377],
              [8.19385, 50.0841],
              [8.19333, 50.08458],
              [8.19302, 50.08488],
              [8.19183, 50.08582],
              [8.19177, 50.08597],
              [8.19136, 50.0862],
              [8.19069, 50.0868],
              [8.19064, 50.08699],
              [8.19076, 50.08757],
              [8.1908, 50.08775],
              [8.18944, 50.08798],
              [8.188447, 50.088309],
              [8.1876, 50.08859],
              [8.18802, 50.08913],
              [8.18748, 50.08952],
              [8.18656, 50.09032],
              [8.18599, 50.09051],
              [8.1858, 50.0905],
              [8.18581, 50.09013],
              [8.185828, 50.089706],
            ],
          ],
        },
        id: "A1NzQ",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.185828, 50.089706],
              [8.18583, 50.08966],
              [8.18601, 50.08941],
              [8.18654, 50.08906],
              [8.18697, 50.08886],
              [8.1876, 50.08859],
              [8.188447, 50.088309],
              [8.18944, 50.08798],
              [8.1908, 50.08775],
              [8.19076, 50.08757],
              [8.19064, 50.08699],
              [8.19069, 50.0868],
              [8.19136, 50.0862],
              [8.19177, 50.08597],
              [8.19183, 50.08582],
              [8.19302, 50.08488],
              [8.19333, 50.08458],
              [8.19385, 50.0841],
              [8.19419, 50.08377],
              [8.19454, 50.08345],
              [8.19536, 50.08274],
              [8.19903, 50.08089],
              [8.19964, 50.08054],
              [8.19996, 50.08027],
              [8.20033, 50.07975],
              [8.20131, 50.07987],
              [8.20255, 50.08014],
              [8.20282, 50.08019],
              [8.2031, 50.08022],
              [8.20471, 50.08041],
              [8.20542, 50.08052],
              [8.20566, 50.08056],
              [8.20642, 50.08069],
              [8.20659, 50.08071],
              [8.20724, 50.08082],
              [8.20827, 50.08101],
              [8.20795, 50.08208],
              [8.20792, 50.08234],
              [8.20757, 50.08355],
              [8.20765, 50.08385],
              [8.20773, 50.08402],
              [8.20808, 50.08433],
              [8.20825, 50.08436],
              [8.20872, 50.08415],
              [8.21007, 50.08364],
              [8.21173, 50.083],
              [8.21466, 50.08234],
              [8.21553, 50.08232],
              [8.21592, 50.08205],
              [8.21659, 50.08186],
              [8.21771, 50.08167],
              [8.21822, 50.08156],
              [8.21862, 50.08145],
              [8.21878, 50.08139],
              [8.21894, 50.08134],
              [8.22219, 50.07995],
              [8.22389, 50.07917],
              [8.224254, 50.079747],
              [8.224608, 50.080587],
              [8.226056, 50.080367],
              [8.229479, 50.080512],
              [8.229758, 50.078267],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.244535, 50.079334],
              [8.24507, 50.07936],
              [8.24587, 50.07943],
              [8.24632, 50.07958],
              [8.24687, 50.07991],
              [8.24759, 50.08014],
              [8.24787, 50.08022],
              [8.24875, 50.08047],
              [8.2496, 50.08069],
              [8.24982, 50.08075],
              [8.250337, 50.080887],
              [8.25069, 50.08098],
              [8.25159, 50.08121],
              [8.25238, 50.08142],
              [8.25398, 50.08184],
              [8.25421, 50.0819],
              [8.25536, 50.08215],
              [8.25562, 50.08218],
              [8.25594, 50.08222],
              [8.25755, 50.08251],
              [8.25789, 50.08261],
              [8.25848, 50.08273],
              [8.26007, 50.08306],
              [8.26159, 50.08346],
              [8.26261, 50.08372],
              [8.26405, 50.08408],
              [8.26533, 50.08438],
              [8.26591, 50.08444],
              [8.26664, 50.08449],
              [8.26798, 50.08459],
              [8.27015, 50.08475],
              [8.27178, 50.08499],
              [8.27217, 50.08504],
              [8.2737, 50.08527],
              [8.27724, 50.08485],
              [8.27768, 50.084646],
              [8.27866, 50.08419],
              [8.27913, 50.08398],
              [8.28057, 50.0833],
              [8.28069, 50.08326],
              [8.28078, 50.08323],
              [8.28085, 50.0832],
              [8.28096, 50.08316],
              [8.28119, 50.08307],
              [8.28198, 50.08271],
              [8.28196, 50.08302],
              [8.28178, 50.08347],
              [8.28145, 50.0844],
              [8.28129, 50.08482],
              [8.28097, 50.08559],
              [8.28186, 50.08601],
              [8.283874, 50.086486],
              [8.287249, 50.08724],
              [8.28992, 50.08779],
              [8.29053, 50.08792],
              [8.29201, 50.08823],
              [8.29345, 50.08854],
              [8.29436, 50.08896],
              [8.2966, 50.09106],
              [8.29731, 50.09139],
              [8.29848, 50.09173],
              [8.29919, 50.09194],
              [8.30124, 50.09256],
              [8.30216, 50.09284],
              [8.30401, 50.09336],
              [8.30484, 50.09355],
              [8.30567, 50.09346],
              [8.30633, 50.09383],
              [8.30659, 50.0941],
              [8.30659, 50.09416],
              [8.30654, 50.09445],
              [8.3053, 50.09518],
              [8.30365, 50.09579],
              [8.30414, 50.09636],
              [8.30345, 50.09664],
              [8.30308, 50.09676],
              [8.30211, 50.09712],
              [8.30181, 50.09725],
              [8.30113, 50.09754],
              [8.30036, 50.0979],
              [8.29831, 50.09906],
              [8.29695, 50.10002],
              [8.29678, 50.10026],
              [8.29663, 50.10094],
              [8.29744, 50.1021],
              [8.29776, 50.10291],
              [8.29768, 50.10478],
              [8.2978, 50.1059],
              [8.29771, 50.10648],
              [8.29742, 50.10748],
              [8.29765, 50.10813],
              [8.29785, 50.1085],
              [8.2979, 50.10877],
              [8.2978, 50.10908],
              [8.29739, 50.10947],
              [8.29679, 50.11023],
              [8.29635, 50.11075],
              [8.29628, 50.11093],
              [8.29676, 50.11104],
              [8.29814, 50.11138],
              [8.29874, 50.11168],
              [8.29919, 50.11192],
              [8.29928, 50.11196],
              [8.29993, 50.11234],
              [8.30086, 50.11281],
              [8.30203, 50.11403],
              [8.30235, 50.1142],
              [8.30405, 50.11509],
              [8.30612, 50.11557],
              [8.30805, 50.1159],
              [8.30886, 50.11589],
              [8.31157, 50.11574],
              [8.31961, 50.11705],
              [8.32066, 50.1172],
              [8.32136, 50.11716],
              [8.32261, 50.11686],
              [8.32388, 50.11673],
              [8.32457, 50.11656],
              [8.32633, 50.11571],
              [8.32671, 50.11574],
              [8.326086, 50.116593],
              [8.32608, 50.1166],
              [8.32595, 50.11716],
              [8.3262, 50.11878],
              [8.32594, 50.1195],
              [8.32558, 50.11993],
              [8.32572, 50.12002],
              [8.32567, 50.12055],
              [8.32556, 50.12138],
              [8.32527, 50.1226],
              [8.32511, 50.12298],
              [8.32485, 50.12363],
              [8.32533, 50.12361],
              [8.32576, 50.1232],
              [8.32596, 50.12311],
              [8.32646, 50.123],
              [8.32738, 50.12266],
              [8.32752, 50.12261],
              [8.3286, 50.12219],
              [8.32934, 50.12187],
              [8.33052, 50.12099],
              [8.33159, 50.12055],
              [8.3329, 50.12017],
              [8.33348, 50.11983],
              [8.33362, 50.11956],
              [8.33385, 50.11922],
              [8.33414, 50.11901],
              [8.33481, 50.12003],
              [8.33511, 50.12103],
              [8.3354, 50.12203],
              [8.33544, 50.12227],
              [8.33554, 50.12371],
              [8.33534, 50.12496],
              [8.33527, 50.1258],
              [8.33503, 50.12869],
              [8.33497, 50.12938],
              [8.3349, 50.13013],
              [8.33489, 50.13033],
              [8.33495, 50.13071],
              [8.33517, 50.13089],
              [8.33543, 50.13103],
              [8.33564, 50.13114],
              [8.33608, 50.13135],
              [8.3374, 50.13198],
              [8.33775, 50.13218],
              [8.33798, 50.13239],
              [8.33829, 50.13282],
              [8.33834, 50.13477],
              [8.33847, 50.13852],
              [8.33843, 50.13919],
              [8.33881, 50.13912],
              [8.34039, 50.13908],
              [8.34126, 50.13921],
              [8.34183, 50.13937],
              [8.34208, 50.13922],
              [8.34293, 50.13917],
              [8.34346, 50.13917],
              [8.34493, 50.13916],
              [8.34661, 50.13918],
              [8.34638, 50.14017],
              [8.3466, 50.14063],
              [8.34742, 50.14108],
              [8.34786, 50.14138],
              [8.34807, 50.14165],
              [8.34807, 50.14185],
              [8.34794, 50.14269],
              [8.34822, 50.14308],
              [8.34859, 50.14335],
              [8.34897, 50.14346],
              [8.34997, 50.1437],
              [8.35068, 50.14378],
              [8.35182, 50.14353],
              [8.35268, 50.14316],
              [8.35279, 50.14286],
              [8.35325, 50.1421],
              [8.35371, 50.14132],
              [8.35394, 50.14092],
              [8.35402, 50.14058],
              [8.35389, 50.14022],
              [8.353576, 50.140004],
              [8.35341, 50.13989],
              [8.35335, 50.13968],
              [8.35227, 50.13938],
              [8.35246, 50.13898],
              [8.35357, 50.13739],
              [8.35466, 50.13624],
              [8.35489, 50.13603],
              [8.355, 50.13591],
              [8.35562, 50.13482],
              [8.3565, 50.13494],
              [8.35734, 50.13501],
              [8.35799, 50.13517],
              [8.3587, 50.13541],
              [8.35931, 50.13561],
              [8.36001, 50.13584],
              [8.36063, 50.13603],
              [8.36133, 50.13626],
              [8.361814, 50.136485],
              [8.36234, 50.13673],
              [8.36253, 50.13687],
              [8.36292, 50.13732],
              [8.36316, 50.13784],
              [8.36336, 50.13809],
              [8.364018, 50.138639],
              [8.36439, 50.13895],
              [8.36484, 50.13936],
              [8.36506, 50.13936],
              [8.3653, 50.13921],
              [8.36524, 50.13891],
              [8.36552, 50.13886],
              [8.3659, 50.13906],
              [8.36599, 50.13926],
              [8.36585, 50.13962],
              [8.36469, 50.14069],
              [8.36425, 50.14099],
              [8.36368, 50.14119],
              [8.3634, 50.14112],
              [8.36278, 50.14088],
              [8.36208, 50.14065],
              [8.36193, 50.14069],
              [8.36175, 50.14059],
              [8.36075, 50.14045],
              [8.36067, 50.14045],
              [8.35968, 50.14035],
              [8.35892, 50.1403],
              [8.35772, 50.14031],
              [8.35756, 50.14035],
              [8.35727, 50.14038],
              [8.35719, 50.14064],
              [8.35629, 50.14139],
              [8.3559, 50.14202],
              [8.35591, 50.14239],
              [8.35609, 50.14283],
              [8.35601, 50.14303],
              [8.35558, 50.14345],
              [8.35446, 50.14421],
              [8.35427, 50.14434],
              [8.35409, 50.14448],
              [8.35355, 50.14493],
              [8.353319, 50.145392],
              [8.35315, 50.14573],
              [8.35305, 50.14682],
              [8.35156, 50.14872],
              [8.35067, 50.14978],
              [8.35058, 50.14992],
              [8.35018, 50.15036],
              [8.34904, 50.15118],
              [8.3487, 50.15145],
              [8.34887, 50.15204],
              [8.34923, 50.15235],
              [8.349797, 50.152774],
              [8.35002, 50.15294],
              [8.35024, 50.15303],
              [8.35071, 50.15336],
              [8.35088, 50.1536],
              [8.350908, 50.153964],
            ],
          ],
        },
        id: "cxNDI",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "I2MTY",
    name: "25 Fasanerie <> Rambach",
    lineName: "25",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.27607, 50.11952],
              [8.27598, 50.11932],
              [8.27569, 50.11905],
              [8.27469, 50.11819],
              [8.27389, 50.11732],
              [8.2738, 50.11727],
              [8.2736, 50.11718],
              [8.273425, 50.11711],
              [8.2729, 50.1169],
              [8.27185, 50.11688],
              [8.27075, 50.11686],
              [8.27038, 50.11673],
              [8.26999, 50.11634],
              [8.26998, 50.11604],
              [8.27006, 50.11586],
              [8.270361, 50.115466],
              [8.2719, 50.11345],
              [8.2723, 50.11288],
              [8.27239, 50.11276],
              [8.27254, 50.11258],
              [8.27258, 50.11253],
              [8.27212, 50.11215],
              [8.26911, 50.1096],
              [8.268855, 50.10939],
              [8.26826, 50.1089],
              [8.26823, 50.10884],
              [8.2682, 50.10796],
              [8.26818, 50.10781],
              [8.26831, 50.10746],
              [8.268428, 50.107129],
              [8.26867, 50.10645],
              [8.26844, 50.10587],
              [8.26749, 50.10548],
              [8.266309, 50.104728],
              [8.26526, 50.10406],
              [8.26437, 50.10329],
              [8.26409, 50.10295],
              [8.26421, 50.10242],
              [8.26417, 50.10217],
              [8.2654, 50.10183],
              [8.26578, 50.10176],
              [8.26608, 50.10159],
              [8.26666, 50.10085],
              [8.26724, 50.1003],
              [8.26738, 50.09976],
              [8.26704, 50.09924],
              [8.2668, 50.099],
              [8.26549, 50.09822],
              [8.26457, 50.09786],
              [8.26438, 50.09763],
              [8.26438, 50.09747],
              [8.26471, 50.0971],
              [8.26515, 50.09695],
              [8.26522, 50.09693],
              [8.26586, 50.09673],
              [8.26671, 50.09647],
              [8.26806, 50.09592],
              [8.26857, 50.09642],
              [8.26899, 50.09665],
              [8.26982, 50.0971],
              [8.26979, 50.09723],
              [8.269181, 50.097668],
              [8.2689, 50.09787],
              [8.26769, 50.09744],
              [8.26725, 50.09726],
              [8.26586, 50.09673],
              [8.26671, 50.09647],
              [8.26806, 50.09592],
              [8.26901, 50.09552],
              [8.26846, 50.09502],
              [8.26824, 50.09476],
              [8.26788, 50.0944],
              [8.26712, 50.0938],
              [8.26621, 50.09317],
              [8.26568, 50.0929],
              [8.2657, 50.09275],
              [8.265776, 50.092346],
              [8.26582, 50.09211],
              [8.26567, 50.09183],
              [8.26529, 50.09156],
              [8.26416, 50.09073],
              [8.2633, 50.09017],
              [8.26254, 50.08966],
              [8.26231, 50.08924],
              [8.26223, 50.08897],
              [8.26204, 50.08876],
              [8.26146, 50.08861],
              [8.26064, 50.0885],
              [8.26032, 50.08845],
              [8.26034, 50.0884],
              [8.2602, 50.08831],
              [8.26015, 50.08782],
              [8.26015, 50.08767],
              [8.26015, 50.08726],
              [8.26018, 50.0872],
              [8.26036, 50.08719],
              [8.2604, 50.08716],
              [8.26034, 50.08702],
              [8.26069, 50.08661],
              [8.26075, 50.08636],
              [8.2605, 50.0855],
              [8.26007, 50.08485],
              [8.25997, 50.08472],
              [8.25925, 50.08381],
              [8.25909, 50.08359],
              [8.25846, 50.0831],
              [8.258472, 50.082883],
              [8.25848, 50.08273],
              [8.25562, 50.08218],
              [8.25536, 50.08215],
              [8.25421, 50.0819],
              [8.25398, 50.08184],
              [8.25238, 50.08142],
              [8.25159, 50.08121],
              [8.25069, 50.08098],
              [8.250337, 50.080887],
              [8.24982, 50.08075],
              [8.2496, 50.08069],
              [8.24875, 50.08047],
              [8.24787, 50.08022],
              [8.24759, 50.08014],
              [8.24687, 50.07991],
              [8.24576, 50.08049],
              [8.24493, 50.08066],
              [8.24371, 50.0806],
              [8.24282, 50.08054],
              [8.23934, 50.08034],
              [8.23796, 50.08029],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.229666, 50.080536],
              [8.229532, 50.081754],
              [8.229398, 50.082856],
              [8.229334, 50.083124],
              [8.229173, 50.083248],
              [8.228964, 50.083262],
              [8.228744, 50.083265],
              [8.22837, 50.08335],
              [8.22766, 50.08366],
              [8.22693, 50.08414],
              [8.22575, 50.08506],
              [8.2254, 50.0853],
              [8.22529, 50.08538],
              [8.22461, 50.08575],
              [8.22414, 50.08567],
              [8.22261, 50.086],
              [8.222181, 50.086095],
              [8.22093, 50.08637],
              [8.22058, 50.08644],
              [8.21897, 50.08678],
              [8.218173, 50.086954],
              [8.21741, 50.08712],
              [8.21584, 50.08746],
              [8.21544, 50.08762],
              [8.21444, 50.08824],
              [8.21438, 50.08829],
              [8.21244, 50.09025],
              [8.21209, 50.09049],
              [8.211745, 50.090644],
            ],
          ],
        },
        id: "I2MTY",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.211745, 50.090644],
              [8.21209, 50.09049],
              [8.21244, 50.09025],
              [8.21438, 50.08829],
              [8.21444, 50.08824],
              [8.21544, 50.08762],
              [8.21584, 50.08746],
              [8.21741, 50.08712],
              [8.218173, 50.086954],
              [8.21897, 50.08678],
              [8.22058, 50.08644],
              [8.22093, 50.08637],
              [8.222181, 50.086095],
              [8.22261, 50.086],
              [8.22414, 50.08567],
              [8.22461, 50.08575],
              [8.22529, 50.08538],
              [8.2254, 50.0853],
              [8.22575, 50.08506],
              [8.22693, 50.08414],
              [8.22766, 50.08366],
              [8.22837, 50.08335],
              [8.22882, 50.08312],
              [8.22888, 50.08279],
              [8.228921, 50.082748],
              [8.22908, 50.08258],
              [8.22915, 50.08253],
              [8.2293, 50.08186],
              [8.22934, 50.08152],
              [8.2294, 50.08117],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22997, 50.07829],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.244535, 50.079334],
              [8.24507, 50.07936],
              [8.24587, 50.07943],
              [8.24632, 50.07958],
              [8.24687, 50.07991],
              [8.24759, 50.08014],
              [8.24787, 50.08022],
              [8.24875, 50.08047],
              [8.2496, 50.08069],
              [8.24982, 50.08075],
              [8.250337, 50.080887],
              [8.25069, 50.08098],
              [8.25159, 50.08121],
              [8.25238, 50.08142],
              [8.25398, 50.08184],
              [8.25421, 50.0819],
              [8.25536, 50.08215],
              [8.25562, 50.08218],
              [8.25848, 50.08273],
              [8.25997, 50.08472],
              [8.26007, 50.08485],
              [8.2605, 50.0855],
              [8.26075, 50.08636],
              [8.26069, 50.08661],
              [8.26034, 50.08702],
              [8.2604, 50.08716],
              [8.26036, 50.08719],
              [8.26018, 50.0872],
              [8.26015, 50.08726],
              [8.26015, 50.08767],
              [8.26015, 50.08782],
              [8.2602, 50.08831],
              [8.26034, 50.0884],
              [8.26032, 50.08845],
              [8.26064, 50.0885],
              [8.26146, 50.08861],
              [8.26204, 50.08876],
              [8.26223, 50.08897],
              [8.26231, 50.08924],
              [8.26254, 50.08966],
              [8.2633, 50.09017],
              [8.26416, 50.09073],
              [8.26529, 50.09156],
              [8.26567, 50.09183],
              [8.26582, 50.09211],
              [8.265776, 50.092346],
              [8.2657, 50.09275],
              [8.26568, 50.0929],
              [8.26621, 50.09317],
              [8.26712, 50.0938],
              [8.26788, 50.0944],
              [8.26824, 50.09476],
              [8.26846, 50.09502],
              [8.26901, 50.09552],
              [8.26806, 50.09592],
              [8.26857, 50.09642],
              [8.26899, 50.09665],
              [8.26982, 50.0971],
              [8.26979, 50.09723],
              [8.269181, 50.097668],
              [8.2689, 50.09787],
              [8.26769, 50.09744],
              [8.26725, 50.09726],
              [8.26586, 50.09673],
              [8.26522, 50.09693],
              [8.26515, 50.09695],
              [8.26471, 50.0971],
              [8.26438, 50.09747],
              [8.26438, 50.09763],
              [8.26457, 50.09786],
              [8.26549, 50.09822],
              [8.2668, 50.099],
              [8.26704, 50.09924],
              [8.26738, 50.09976],
              [8.26724, 50.1003],
              [8.26666, 50.10085],
              [8.26608, 50.10159],
              [8.26578, 50.10176],
              [8.2654, 50.10183],
              [8.26417, 50.10217],
              [8.26421, 50.10242],
              [8.26409, 50.10295],
              [8.26437, 50.10329],
              [8.2648, 50.10341],
              [8.26543, 50.10379],
              [8.26583, 50.10399],
              [8.26628, 50.10422],
              [8.266728, 50.1045],
              [8.26705, 50.1047],
              [8.26856, 50.10515],
              [8.26897, 50.10547],
              [8.26903, 50.10594],
              [8.26867, 50.10645],
              [8.268428, 50.107129],
              [8.26831, 50.10746],
              [8.26818, 50.10781],
              [8.2682, 50.10796],
              [8.26823, 50.10884],
              [8.26826, 50.1089],
              [8.268855, 50.10939],
              [8.26911, 50.1096],
              [8.27212, 50.11215],
              [8.27258, 50.11253],
              [8.27254, 50.11258],
              [8.27239, 50.11276],
              [8.2723, 50.11288],
              [8.2719, 50.11345],
              [8.270361, 50.115466],
              [8.27006, 50.11586],
              [8.26998, 50.11604],
              [8.26999, 50.11634],
              [8.27038, 50.11673],
              [8.27075, 50.11686],
              [8.27185, 50.11688],
              [8.2729, 50.1169],
              [8.273425, 50.11711],
              [8.2736, 50.11718],
              [8.2738, 50.11727],
              [8.27389, 50.11732],
              [8.27469, 50.11819],
              [8.27569, 50.11905],
              [8.27598, 50.11932],
              [8.27607, 50.11952],
            ],
          ],
        },
        id: "I5MjY",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "g3MTk",
    name: "14 Mainz-Kastel <> Dotzheim",
    lineName: "14",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.18266, 50.07276],
              [8.18304, 50.07209],
              [8.18329, 50.07167],
              [8.18352, 50.07127],
              [8.1837, 50.07103],
              [8.18568, 50.06989],
              [8.18637, 50.06953],
              [8.187116, 50.069103],
              [8.18761, 50.06882],
              [8.18795, 50.06869],
              [8.18975, 50.06864],
              [8.19027, 50.06862],
              [8.19174, 50.06858],
              [8.19238, 50.0683],
              [8.192492, 50.068204],
              [8.19292, 50.06784],
              [8.19365, 50.06797],
              [8.19452, 50.06836],
              [8.1947, 50.06853],
              [8.19529, 50.06851],
              [8.19576, 50.06863],
              [8.19688, 50.06906],
              [8.19792, 50.06946],
              [8.198449, 50.069649],
              [8.20005, 50.07022],
              [8.20312, 50.06908],
              [8.20596, 50.06807],
              [8.207273, 50.067596],
              [8.20823, 50.06725],
              [8.20899, 50.06684],
              [8.21005, 50.06622],
              [8.21226, 50.06494],
              [8.21257, 50.06476],
              [8.21317, 50.06441],
              [8.21471, 50.06351],
              [8.21486, 50.06342],
              [8.21575, 50.06292],
              [8.21588, 50.06285],
              [8.21617, 50.06273],
              [8.21641, 50.06261],
              [8.216721, 50.062429],
              [8.21708, 50.06222],
              [8.21739, 50.06202],
              [8.21756, 50.06191],
              [8.2185, 50.06139],
              [8.2192, 50.06102],
              [8.219797, 50.060709],
              [8.22033, 50.06043],
              [8.21877, 50.0594],
              [8.21858, 50.05917],
              [8.21845, 50.059],
              [8.21835, 50.05845],
              [8.2186, 50.05792],
              [8.218624, 50.057901],
              [8.21912, 50.0575],
              [8.22, 50.05673],
              [8.22014, 50.05638],
              [8.220123, 50.05626],
              [8.22009, 50.05602],
              [8.22011, 50.05586],
              [8.2201, 50.05552],
              [8.22028, 50.05504],
              [8.22086, 50.05448],
              [8.22201, 50.05409],
              [8.22394, 50.05406],
              [8.224838, 50.054048],
              [8.22545, 50.05404],
              [8.22708, 50.05407],
              [8.229078, 50.054209],
              [8.22995, 50.05427],
              [8.23142, 50.05271],
              [8.231729, 50.052395],
              [8.23245, 50.05166],
              [8.23266, 50.05149],
              [8.233867, 50.051628],
              [8.235036, 50.051759],
              [8.236538, 50.051965],
              [8.237633, 50.052083],
              [8.238995, 50.052062],
              [8.240283, 50.051855],
              [8.241194, 50.051456],
              [8.242042, 50.051242],
              [8.241817, 50.050663],
              [8.241817, 50.050181],
              [8.241302, 50.048969],
              [8.241173, 50.048521],
              [8.241119, 50.048266],
              [8.241098, 50.047798],
              [8.23752, 50.04808],
              [8.23748, 50.04808],
              [8.23436, 50.047837],
              [8.23121, 50.04728],
              [8.23105, 50.04625],
              [8.23103, 50.04612],
              [8.23091, 50.04582],
              [8.230907, 50.045816],
              [8.23049, 50.04529],
              [8.22949, 50.0441],
              [8.2291, 50.04363],
              [8.22856, 50.04299],
              [8.22769, 50.04186],
              [8.22679, 50.03993],
              [8.2263, 50.03916],
              [8.2282, 50.03868],
              [8.23087, 50.03766],
              [8.23113, 50.0376],
              [8.23124, 50.03758],
              [8.23146, 50.03754],
              [8.23292, 50.0373],
              [8.23501, 50.03695],
              [8.23523, 50.03691],
              [8.23645, 50.03653],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23926, 50.03556],
              [8.23966, 50.03539],
              [8.24185, 50.03466],
              [8.242684, 50.034482],
              [8.243308, 50.034349],
              [8.243467, 50.034339],
              [8.243501, 50.034266],
              [8.243572, 50.034229],
              [8.243668, 50.03424],
              [8.243738, 50.034274],
              [8.24437, 50.03407],
              [8.245846, 50.033541],
              [8.246682, 50.033251],
              [8.24853, 50.03263],
              [8.25004, 50.03206],
              [8.2502, 50.03199],
              [8.250901, 50.031634],
              [8.25152, 50.03132],
              [8.25334, 50.03034],
              [8.25356, 50.03022],
              [8.25402, 50.02997],
              [8.25443, 50.02976],
              [8.25598, 50.02898],
              [8.25676, 50.02856],
              [8.25753, 50.02902],
              [8.25825, 50.02937],
              [8.26037, 50.03032],
              [8.261, 50.03061],
              [8.26247, 50.03124],
              [8.26353, 50.0297],
              [8.26444, 50.02836],
              [8.26456, 50.02819],
              [8.26467, 50.02804],
              [8.26525, 50.02731],
              [8.26533, 50.02721],
              [8.26548, 50.02702],
              [8.26591, 50.02651],
              [8.26611, 50.02627],
              [8.26643, 50.02593],
              [8.26691, 50.02538],
              [8.268, 50.02409],
              [8.26844, 50.02413],
              [8.26916, 50.02425],
              [8.27153, 50.02453],
              [8.27336, 50.02455],
              [8.27389, 50.02452],
              [8.27642, 50.02415],
              [8.2778, 50.02381],
              [8.2781, 50.02414],
              [8.27853, 50.02471],
              [8.27658, 50.02578],
              [8.27269, 50.02797],
              [8.2732, 50.02881],
              [8.27346, 50.0291],
              [8.27389, 50.02935],
              [8.274189, 50.02942],
              [8.27783, 50.02941],
              [8.27898, 50.02935],
              [8.27927, 50.02932],
              [8.28, 50.02926],
              [8.28162, 50.02886],
              [8.2819, 50.02877],
              [8.2836, 50.02824],
              [8.28553, 50.02764],
              [8.28728, 50.02708],
              [8.28899, 50.02654],
              [8.28905, 50.02638],
              [8.28931, 50.02643],
              [8.29102, 50.02583],
              [8.29073, 50.0253],
              [8.29006, 50.02433],
              [8.28942, 50.02315],
              [8.28904, 50.02197],
              [8.28862, 50.0208],
              [8.28841, 50.02044],
              [8.28783, 50.01969],
              [8.28901, 50.01915],
              [8.28953, 50.0189],
              [8.2916, 50.01778],
              [8.2932, 50.01678],
              [8.29384, 50.01634],
              [8.29425, 50.01604],
              [8.29609, 50.01456],
              [8.297, 50.01372],
              [8.29878, 50.01442],
              [8.29926, 50.0146],
              [8.2999, 50.01486],
              [8.30027, 50.015],
              [8.3008, 50.01521],
              [8.30052, 50.01553],
              [8.30252, 50.01653],
              [8.30258, 50.01674],
              [8.30167, 50.01756],
              [8.30165, 50.01783],
              [8.3005, 50.01888],
              [8.3003, 50.01917],
              [8.29976, 50.01959],
              [8.29847, 50.01892],
              [8.29767, 50.01849],
              [8.297286, 50.018265],
            ],
          ],
        },
        id: "g3MTk",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.297286, 50.018265],
              [8.2968, 50.01798],
              [8.29658, 50.01791],
              [8.29666, 50.01777],
              [8.2958, 50.0173],
              [8.29569, 50.01716],
              [8.29556, 50.01674],
              [8.29536, 50.01663],
              [8.29486, 50.01635],
              [8.29425, 50.01604],
              [8.29384, 50.01634],
              [8.2932, 50.01678],
              [8.2916, 50.01778],
              [8.28953, 50.0189],
              [8.28901, 50.01915],
              [8.28783, 50.01969],
              [8.28529, 50.02081],
              [8.28414, 50.02131],
              [8.28055, 50.02282],
              [8.2778, 50.02381],
              [8.27642, 50.02415],
              [8.27389, 50.02452],
              [8.27336, 50.02455],
              [8.27153, 50.02453],
              [8.26916, 50.02425],
              [8.26844, 50.02413],
              [8.268, 50.02409],
              [8.26691, 50.02538],
              [8.26643, 50.02593],
              [8.26611, 50.02627],
              [8.26591, 50.02651],
              [8.26548, 50.02702],
              [8.26533, 50.02721],
              [8.26525, 50.02731],
              [8.26467, 50.02804],
              [8.26456, 50.02819],
              [8.26444, 50.02836],
              [8.26353, 50.0297],
              [8.26247, 50.03124],
              [8.26239, 50.03135],
              [8.26247, 50.03124],
              [8.261, 50.03061],
              [8.26037, 50.03032],
              [8.25825, 50.02937],
              [8.25753, 50.02902],
              [8.25676, 50.02856],
              [8.25598, 50.02898],
              [8.25443, 50.02976],
              [8.25402, 50.02997],
              [8.25356, 50.03022],
              [8.25334, 50.03034],
              [8.25152, 50.03132],
              [8.250901, 50.031634],
              [8.2502, 50.03199],
              [8.25004, 50.03206],
              [8.24853, 50.03263],
              [8.246682, 50.033251],
              [8.245846, 50.033541],
              [8.24437, 50.03407],
              [8.243738, 50.034274],
              [8.243668, 50.03424],
              [8.243572, 50.034229],
              [8.243501, 50.034266],
              [8.243467, 50.034339],
              [8.243308, 50.034349],
              [8.242684, 50.034482],
              [8.24185, 50.03466],
              [8.23966, 50.03539],
              [8.23926, 50.03556],
              [8.23788, 50.03603],
              [8.23723, 50.036255],
              [8.23684, 50.03639],
              [8.23645, 50.03653],
              [8.23523, 50.03691],
              [8.23501, 50.03695],
              [8.23292, 50.0373],
              [8.23146, 50.03754],
              [8.23124, 50.03758],
              [8.23113, 50.0376],
              [8.23087, 50.03766],
              [8.2282, 50.03868],
              [8.2263, 50.03916],
              [8.22679, 50.03993],
              [8.22769, 50.04186],
              [8.22856, 50.04299],
              [8.2291, 50.04363],
              [8.22949, 50.0441],
              [8.23049, 50.04529],
              [8.230907, 50.045816],
              [8.23091, 50.04582],
              [8.23103, 50.04612],
              [8.23105, 50.04625],
              [8.23121, 50.04728],
              [8.23436, 50.047837],
              [8.23748, 50.04808],
              [8.23936, 50.047949],
              [8.240787, 50.047811],
              [8.241076, 50.047791],
              [8.241119, 50.048238],
              [8.241184, 50.048576],
              [8.241323, 50.049003],
              [8.241602, 50.049637],
              [8.241827, 50.050216],
              [8.242171, 50.051215],
              [8.241259, 50.051449],
              [8.240304, 50.051835],
              [8.239006, 50.052062],
              [8.237643, 50.052089],
              [8.235186, 50.051773],
              [8.23266, 50.05149],
              [8.23245, 50.05166],
              [8.231729, 50.052395],
              [8.23142, 50.05271],
              [8.22995, 50.05427],
              [8.229078, 50.054209],
              [8.22708, 50.05407],
              [8.22545, 50.05404],
              [8.224838, 50.054048],
              [8.22394, 50.05406],
              [8.22201, 50.05409],
              [8.22086, 50.05448],
              [8.22028, 50.05504],
              [8.2201, 50.05552],
              [8.22011, 50.05586],
              [8.22009, 50.05602],
              [8.220123, 50.05626],
              [8.22014, 50.05638],
              [8.22, 50.05673],
              [8.21912, 50.0575],
              [8.218624, 50.057901],
              [8.2186, 50.05792],
              [8.21835, 50.05845],
              [8.21845, 50.059],
              [8.21858, 50.05917],
              [8.21877, 50.0594],
              [8.22033, 50.06043],
              [8.219797, 50.060709],
              [8.2192, 50.06102],
              [8.2185, 50.06139],
              [8.21756, 50.06191],
              [8.21739, 50.06202],
              [8.21708, 50.06222],
              [8.216721, 50.062429],
              [8.21641, 50.06261],
              [8.21617, 50.06273],
              [8.21588, 50.06285],
              [8.21575, 50.06292],
              [8.21486, 50.06342],
              [8.21471, 50.06351],
              [8.21317, 50.06441],
              [8.21257, 50.06476],
              [8.21226, 50.06494],
              [8.21005, 50.06622],
              [8.20899, 50.06684],
              [8.20823, 50.06725],
              [8.207273, 50.067596],
              [8.20596, 50.06807],
              [8.20312, 50.06908],
              [8.20005, 50.07022],
              [8.198449, 50.069649],
              [8.19792, 50.06946],
              [8.19688, 50.06906],
              [8.19576, 50.06863],
              [8.19529, 50.06851],
              [8.1947, 50.06853],
              [8.19452, 50.06836],
              [8.19365, 50.06797],
              [8.19292, 50.06784],
              [8.192492, 50.068204],
              [8.19238, 50.0683],
              [8.19174, 50.06858],
              [8.19027, 50.06862],
              [8.18975, 50.06864],
              [8.18795, 50.06869],
              [8.18761, 50.06882],
              [8.187116, 50.069103],
              [8.18637, 50.06953],
              [8.18568, 50.06989],
              [8.1837, 50.07103],
              [8.18352, 50.07127],
              [8.184918, 50.071749],
              [8.1865, 50.07229],
              [8.18668, 50.07255],
              [8.186242, 50.073111],
              [8.18597, 50.07346],
              [8.18534, 50.07357],
              [8.18309, 50.07283],
              [8.18266, 50.07276],
            ],
          ],
        },
        id: "M1OTQ",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "MyNjU",
    name: "24 Aeppelallee <> Tierpark",
    lineName: "24",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.191408, 50.101852],
              [8.19111, 50.10171],
              [8.18971, 50.10088],
              [8.18708, 50.09929],
              [8.18631, 50.09882],
              [8.18591, 50.09857],
              [8.18584, 50.09849],
              [8.18577, 50.09762],
              [8.18571, 50.09686],
              [8.18606, 50.09676],
              [8.18784, 50.09623],
              [8.19045, 50.09532],
              [8.19069, 50.09523],
              [8.19275, 50.09449],
              [8.19415, 50.09438],
              [8.19563, 50.09438],
              [8.19678, 50.09438],
              [8.19814, 50.09438],
              [8.19871, 50.09383],
              [8.19938, 50.09323],
              [8.19945, 50.09309],
              [8.19939, 50.09263],
              [8.19933, 50.09207],
              [8.19931, 50.09189],
              [8.19929, 50.0916],
              [8.1991, 50.09037],
              [8.19895, 50.09005],
              [8.19866, 50.08924],
              [8.19867, 50.0885],
              [8.199, 50.08722],
              [8.19923, 50.0872],
              [8.20028, 50.08703],
              [8.2006, 50.08696],
              [8.20242, 50.0865],
              [8.20394, 50.08612],
              [8.20506, 50.0857],
              [8.207417, 50.084669],
              [8.20801, 50.0844],
              [8.20808, 50.08433],
              [8.20773, 50.08402],
              [8.20765, 50.08385],
              [8.20757, 50.08355],
              [8.20792, 50.08234],
              [8.20795, 50.08208],
              [8.20827, 50.08101],
              [8.20724, 50.08082],
              [8.20659, 50.08071],
              [8.20642, 50.08069],
              [8.20566, 50.08056],
              [8.20542, 50.08052],
              [8.20471, 50.08041],
              [8.2031, 50.08022],
              [8.20282, 50.08019],
              [8.20255, 50.08014],
              [8.20131, 50.07987],
              [8.20033, 50.07975],
              [8.20056, 50.07904],
              [8.20119, 50.07809],
              [8.20158, 50.07741],
              [8.20222, 50.07628],
              [8.2025, 50.07569],
              [8.2022, 50.07564],
              [8.20016, 50.07537],
              [8.19979, 50.07535],
              [8.19774, 50.07509],
              [8.19694, 50.07502],
              [8.19638, 50.07498],
              [8.1959, 50.0748],
              [8.19569, 50.07454],
              [8.19566, 50.07447],
              [8.19536, 50.0741],
              [8.19513, 50.07384],
              [8.1948, 50.07357],
              [8.19424, 50.07306],
              [8.19437, 50.0728],
              [8.19464, 50.07244],
              [8.19536, 50.07195],
              [8.1955, 50.07192],
              [8.19699, 50.07143],
              [8.19713, 50.07138],
              [8.19808, 50.07109],
              [8.19875, 50.070804],
              [8.19946, 50.0705],
              [8.20005, 50.07022],
              [8.20312, 50.06908],
              [8.20596, 50.06807],
              [8.207273, 50.067596],
              [8.20823, 50.06725],
              [8.20899, 50.06684],
              [8.21005, 50.06622],
              [8.21226, 50.06494],
              [8.21257, 50.06476],
              [8.21317, 50.06441],
              [8.21471, 50.06351],
              [8.21486, 50.06342],
              [8.21575, 50.06292],
              [8.21588, 50.06285],
              [8.21632, 50.06254],
              [8.21686, 50.06221],
              [8.21698, 50.06216],
              [8.21756, 50.06191],
              [8.2185, 50.06139],
              [8.2192, 50.06102],
              [8.219797, 50.060709],
              [8.22033, 50.06043],
              [8.21877, 50.0594],
              [8.21858, 50.05917],
              [8.21845, 50.059],
              [8.21835, 50.05845],
              [8.2186, 50.05792],
              [8.218624, 50.057901],
              [8.21912, 50.0575],
              [8.22, 50.05673],
              [8.22014, 50.05638],
              [8.220123, 50.05626],
              [8.22009, 50.05602],
              [8.22011, 50.05586],
              [8.2201, 50.05552],
              [8.22028, 50.05504],
              [8.22086, 50.05448],
              [8.22201, 50.05409],
              [8.22394, 50.05406],
              [8.224838, 50.054048],
              [8.22545, 50.05404],
              [8.22708, 50.05407],
              [8.229078, 50.054209],
              [8.22995, 50.05427],
              [8.23142, 50.05271],
              [8.231729, 50.052395],
              [8.23245, 50.05166],
              [8.23266, 50.05149],
              [8.23562, 50.05183],
              [8.23658, 50.05197],
              [8.23763, 50.05208],
              [8.23831, 50.05208],
              [8.2387, 50.05207],
              [8.23897, 50.05206],
              [8.2403, 50.05184],
              [8.24125, 50.05145],
              [8.24204, 50.05124],
              [8.24183, 50.050709],
              [8.24179, 50.05015],
              [8.24146, 50.04929],
              [8.24131, 50.04897],
              [8.24126, 50.04884],
              [8.2411, 50.04823],
              [8.24109, 50.04783],
              [8.24108, 50.04768],
              [8.24103, 50.04725],
              [8.24111, 50.04482],
              [8.24084, 50.04427],
              [8.24075, 50.04406],
              [8.24066, 50.04387],
              [8.24026, 50.04295],
              [8.23988, 50.04208],
              [8.23971, 50.04173],
              [8.23963, 50.04157],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23684, 50.03639],
              [8.23645, 50.03653],
              [8.23523, 50.03691],
              [8.23501, 50.03695],
              [8.23292, 50.0373],
              [8.23146, 50.03754],
              [8.23124, 50.03758],
              [8.23113, 50.0376],
              [8.23087, 50.03766],
              [8.2282, 50.03868],
              [8.2263, 50.03916],
              [8.225601, 50.039314],
              [8.221736, 50.040166],
              [8.22136, 50.04025],
              [8.22118, 50.04029],
              [8.21983, 50.04058],
              [8.216713, 50.041284],
              [8.21633, 50.04137],
              [8.21648, 50.04191],
              [8.21679, 50.04284],
              [8.21701, 50.04295],
              [8.21822, 50.04289],
              [8.22134, 50.04273],
              [8.22197, 50.04382],
              [8.2202, 50.04424],
              [8.22044, 50.04471],
              [8.22056, 50.04537],
              [8.220429, 50.046125],
              [8.220218, 50.04734],
              [8.214267, 50.046989],
              [8.214384, 50.045994],
            ],
          ],
        },
        id: "MyNjU",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.214384, 50.045994],
              [8.214267, 50.046989],
              [8.220218, 50.04734],
              [8.220429, 50.046125],
              [8.22056, 50.04537],
              [8.22044, 50.04471],
              [8.2202, 50.04424],
              [8.22197, 50.04382],
              [8.22134, 50.04273],
              [8.21822, 50.04289],
              [8.21701, 50.04295],
              [8.21679, 50.04284],
              [8.21648, 50.04191],
              [8.21633, 50.04137],
              [8.216713, 50.041284],
              [8.21983, 50.04058],
              [8.22118, 50.04029],
              [8.22136, 50.04025],
              [8.221736, 50.040166],
              [8.225601, 50.039314],
              [8.2263, 50.03916],
              [8.2282, 50.03868],
              [8.23087, 50.03766],
              [8.23113, 50.0376],
              [8.23124, 50.03758],
              [8.23146, 50.03754],
              [8.23292, 50.0373],
              [8.23501, 50.03695],
              [8.23523, 50.03691],
              [8.23645, 50.03653],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23862, 50.03686],
              [8.23911, 50.03682],
              [8.23916, 50.0369],
              [8.23984, 50.03824],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.24111, 50.04086],
              [8.24119, 50.04111],
              [8.24123, 50.04126],
              [8.24122, 50.04133],
              [8.24118, 50.04279],
              [8.24116, 50.04376],
              [8.24115, 50.04427],
              [8.24111, 50.04482],
              [8.24103, 50.04725],
              [8.24108, 50.04768],
              [8.24109, 50.04783],
              [8.2411, 50.04823],
              [8.24126, 50.04884],
              [8.24131, 50.04897],
              [8.24146, 50.04929],
              [8.24179, 50.05015],
              [8.241973, 50.050675],
              [8.24216, 50.05121],
              [8.24204, 50.05124],
              [8.24125, 50.05145],
              [8.2403, 50.05184],
              [8.23897, 50.05206],
              [8.2387, 50.05207],
              [8.23831, 50.05208],
              [8.23763, 50.05208],
              [8.23658, 50.05197],
              [8.23562, 50.05183],
              [8.23266, 50.05149],
              [8.23245, 50.05166],
              [8.231729, 50.052395],
              [8.23142, 50.05271],
              [8.22995, 50.05427],
              [8.229078, 50.054209],
              [8.22708, 50.05407],
              [8.22545, 50.05404],
              [8.224838, 50.054048],
              [8.22394, 50.05406],
              [8.22201, 50.05409],
              [8.22086, 50.05448],
              [8.22028, 50.05504],
              [8.2201, 50.05552],
              [8.22011, 50.05586],
              [8.22009, 50.05602],
              [8.220123, 50.05626],
              [8.22014, 50.05638],
              [8.22, 50.05673],
              [8.21912, 50.0575],
              [8.218624, 50.057901],
              [8.2186, 50.05792],
              [8.21835, 50.05845],
              [8.21845, 50.059],
              [8.21858, 50.05917],
              [8.21877, 50.0594],
              [8.22033, 50.06043],
              [8.219797, 50.060709],
              [8.2192, 50.06102],
              [8.2185, 50.06139],
              [8.21756, 50.06191],
              [8.21698, 50.06216],
              [8.21686, 50.06221],
              [8.21632, 50.06254],
              [8.21588, 50.06285],
              [8.21575, 50.06292],
              [8.21486, 50.06342],
              [8.21471, 50.06351],
              [8.21317, 50.06441],
              [8.21257, 50.06476],
              [8.21226, 50.06494],
              [8.21005, 50.06622],
              [8.20899, 50.06684],
              [8.20823, 50.06725],
              [8.207273, 50.067596],
              [8.20596, 50.06807],
              [8.20312, 50.06908],
              [8.20005, 50.07022],
              [8.19946, 50.0705],
              [8.19875, 50.070804],
              [8.19808, 50.07109],
              [8.19713, 50.07138],
              [8.19699, 50.07143],
              [8.1955, 50.07192],
              [8.19536, 50.07195],
              [8.19464, 50.07244],
              [8.19476, 50.07251],
              [8.19562, 50.07308],
              [8.19587, 50.07324],
              [8.19791, 50.07448],
              [8.19774, 50.07509],
              [8.19979, 50.07535],
              [8.20016, 50.07537],
              [8.2022, 50.07564],
              [8.2025, 50.07569],
              [8.20222, 50.07628],
              [8.20158, 50.07741],
              [8.20119, 50.07809],
              [8.20056, 50.07904],
              [8.20033, 50.07975],
              [8.20131, 50.07987],
              [8.20255, 50.08014],
              [8.20282, 50.08019],
              [8.2031, 50.08022],
              [8.20471, 50.08041],
              [8.20542, 50.08052],
              [8.20566, 50.08056],
              [8.20642, 50.08069],
              [8.20659, 50.08071],
              [8.20724, 50.08082],
              [8.20827, 50.08101],
              [8.20795, 50.08208],
              [8.20792, 50.08234],
              [8.20757, 50.08355],
              [8.20765, 50.08385],
              [8.20773, 50.08402],
              [8.20808, 50.08433],
              [8.20801, 50.0844],
              [8.207417, 50.084669],
              [8.20506, 50.0857],
              [8.20394, 50.08612],
              [8.20242, 50.0865],
              [8.2006, 50.08696],
              [8.20028, 50.08703],
              [8.19923, 50.0872],
              [8.199, 50.08722],
              [8.19867, 50.0885],
              [8.19866, 50.08924],
              [8.19895, 50.09005],
              [8.19933, 50.09207],
              [8.19939, 50.09263],
              [8.19945, 50.09309],
              [8.19938, 50.09323],
              [8.19871, 50.09383],
              [8.19814, 50.09438],
              [8.19678, 50.09438],
              [8.19563, 50.09438],
              [8.19415, 50.09438],
              [8.19275, 50.09449],
              [8.19069, 50.09523],
              [8.19045, 50.09532],
              [8.18784, 50.09623],
              [8.18606, 50.09676],
              [8.18571, 50.09686],
              [8.18577, 50.09762],
              [8.18584, 50.09849],
              [8.18591, 50.09857],
              [8.18631, 50.09882],
              [8.18708, 50.09929],
              [8.18971, 50.10088],
              [8.19111, 50.10171],
              [8.191408, 50.101852],
            ],
          ],
        },
        id: "IwOTM",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "A5MTg",
    name: "13 Schierstein <> Sonnenberg",
    lineName: "13",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.243139, 50.106017],
              [8.24371, 50.10464],
              [8.24606, 50.1006],
              [8.24743, 50.0983],
              [8.24731, 50.09719],
              [8.2469, 50.09601],
              [8.24663, 50.09528],
              [8.24656, 50.09505],
              [8.246358, 50.094545],
              [8.24628, 50.09435],
              [8.24582, 50.09353],
              [8.24531, 50.0927],
              [8.24512, 50.09236],
              [8.24492, 50.09187],
              [8.24495, 50.09093],
              [8.24517, 50.09],
              [8.24441, 50.08987],
              [8.24427, 50.08962],
              [8.24403, 50.08908],
              [8.24389, 50.08833],
              [8.24306, 50.08778],
              [8.24228, 50.08728],
              [8.24334, 50.08656],
              [8.24243, 50.08605],
              [8.24198, 50.08577],
              [8.24145, 50.08545],
              [8.24095, 50.08511],
              [8.24031, 50.08541],
              [8.2393, 50.08504],
              [8.23897, 50.08467],
              [8.23881, 50.08443],
              [8.23874, 50.08432],
              [8.23869, 50.08415],
              [8.2384, 50.08368],
              [8.23827, 50.08359],
              [8.2378, 50.08332],
              [8.2372, 50.08317],
              [8.23648, 50.0832],
              [8.23606, 50.08328],
              [8.23593, 50.08327],
              [8.23581, 50.08327],
              [8.23583, 50.08285],
              [8.23588, 50.08241],
              [8.2359, 50.08223],
              [8.23598, 50.08168],
              [8.23605, 50.0813],
              [8.23609, 50.08107],
              [8.23612, 50.08088],
              [8.23622, 50.08024],
              [8.23623, 50.08016],
              [8.23639, 50.07893],
              [8.23644, 50.07835],
              [8.236676, 50.077684],
              [8.235433, 50.077624],
              [8.235155, 50.07761],
              [8.233622, 50.07753],
              [8.232675, 50.077486],
              [8.23015, 50.07735],
              [8.22997, 50.07829],
              [8.22974, 50.07827],
              [8.2287, 50.07815],
              [8.22777, 50.07806],
              [8.22656, 50.07796],
              [8.22585, 50.0779],
              [8.22386, 50.0777],
              [8.2223, 50.07756],
              [8.22216, 50.07755],
              [8.22071, 50.07739],
              [8.22051, 50.07737],
              [8.220629, 50.077223],
              [8.22076, 50.07706],
              [8.22151, 50.07637],
              [8.22193, 50.07602],
              [8.22246, 50.07563],
              [8.22315, 50.07508],
              [8.22355, 50.07462],
              [8.22372, 50.07443],
              [8.22298, 50.0743],
              [8.220794, 50.073291],
              [8.22038, 50.0731],
              [8.21785, 50.071985],
              [8.21731, 50.07174],
              [8.21716, 50.07166],
              [8.2168, 50.07117],
              [8.21673, 50.07091],
              [8.21686, 50.07084],
              [8.2168, 50.07071],
              [8.21702, 50.07051],
              [8.21746, 50.07031],
              [8.21821, 50.07016],
              [8.21835, 50.07013],
              [8.21914, 50.06983],
              [8.21978, 50.06941],
              [8.22061, 50.06885],
              [8.22082, 50.06871],
              [8.22171, 50.06811],
              [8.22279, 50.06734],
              [8.22196, 50.06674],
              [8.2211, 50.06611],
              [8.22093, 50.06596],
              [8.22074, 50.0658],
              [8.2205, 50.06562],
              [8.21961, 50.06501],
              [8.21893, 50.0645],
              [8.21802, 50.06383],
              [8.21742, 50.06338],
              [8.21711, 50.06314],
              [8.21684, 50.06292],
              [8.21649, 50.06275],
              [8.21617, 50.06273],
              [8.21588, 50.06285],
              [8.21575, 50.06292],
              [8.21486, 50.06342],
              [8.21356, 50.06248],
              [8.2127, 50.06186],
              [8.212289, 50.061782],
              [8.21207, 50.06174],
              [8.21168, 50.06145],
              [8.21135, 50.06118],
              [8.21099, 50.06115],
              [8.209417, 50.062145],
              [8.20721, 50.06354],
              [8.20535, 50.06437],
              [8.20416, 50.0632],
              [8.2029, 50.06368],
              [8.20257, 50.06366],
              [8.20073, 50.06346],
              [8.19914, 50.06349],
              [8.19818, 50.06351],
              [8.196875, 50.06351],
              [8.19641, 50.06351],
              [8.19585, 50.0635],
              [8.19249, 50.06306],
              [8.19036, 50.0625],
              [8.18936, 50.06202],
              [8.18802, 50.06133],
              [8.18897, 50.06061],
              [8.18984, 50.05993],
              [8.19022, 50.05963],
              [8.1911, 50.05876],
              [8.19144, 50.05804],
              [8.19148, 50.05782],
              [8.19178, 50.05598],
              [8.19174, 50.05502],
              [8.19136, 50.05393],
              [8.19123, 50.05316],
              [8.19141, 50.05182],
              [8.1916, 50.05108],
              [8.1918, 50.05054],
              [8.19211, 50.04985],
              [8.19236, 50.04939],
              [8.19268, 50.04877],
              [8.19352, 50.04889],
              [8.19413, 50.04896],
              [8.19588, 50.04921],
              [8.19687, 50.04936],
              [8.197625, 50.049466],
              [8.20066, 50.04989],
              [8.20181, 50.05022],
              [8.20407, 50.05038],
              [8.20429, 50.05032],
              [8.2029, 50.04876],
              [8.20258, 50.04839],
              [8.20195, 50.04766],
              [8.20113, 50.04675],
              [8.20083, 50.04642],
              [8.20039, 50.04593],
              [8.1996, 50.04504],
              [8.19938, 50.0446],
              [8.20059, 50.04463],
              [8.20151, 50.04462],
              [8.20189, 50.04456],
              [8.2027, 50.0444],
              [8.20368, 50.04418],
              [8.20476, 50.04394],
              [8.20437, 50.04316],
              [8.20415, 50.043],
              [8.20387, 50.04291],
              [8.20267, 50.04289],
              [8.20179, 50.04292],
              [8.20146, 50.0429],
              [8.200414, 50.042722],
            ],
          ],
        },
        id: "A5MTg",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.200414, 50.042722],
              [8.20005, 50.04266],
              [8.19974, 50.04342],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.20083, 50.04642],
              [8.20113, 50.04675],
              [8.20195, 50.04766],
              [8.20258, 50.04839],
              [8.2029, 50.04876],
              [8.20429, 50.05032],
              [8.20407, 50.05038],
              [8.20181, 50.05022],
              [8.20066, 50.04989],
              [8.197625, 50.049466],
              [8.19687, 50.04936],
              [8.19588, 50.04921],
              [8.19413, 50.04896],
              [8.19352, 50.04889],
              [8.19268, 50.04877],
              [8.19236, 50.04939],
              [8.19211, 50.04985],
              [8.1918, 50.05054],
              [8.1916, 50.05108],
              [8.19141, 50.05182],
              [8.19123, 50.05316],
              [8.19136, 50.05393],
              [8.19174, 50.05502],
              [8.19178, 50.05598],
              [8.19148, 50.05782],
              [8.19144, 50.05804],
              [8.1911, 50.05876],
              [8.19022, 50.05963],
              [8.18984, 50.05993],
              [8.18897, 50.06061],
              [8.18802, 50.06133],
              [8.18936, 50.06202],
              [8.19036, 50.0625],
              [8.19249, 50.06306],
              [8.19585, 50.0635],
              [8.19641, 50.06351],
              [8.196875, 50.06351],
              [8.19818, 50.06351],
              [8.19914, 50.06349],
              [8.20073, 50.06346],
              [8.20257, 50.06366],
              [8.2029, 50.06368],
              [8.20416, 50.0632],
              [8.20535, 50.06437],
              [8.20721, 50.06354],
              [8.209417, 50.062145],
              [8.21099, 50.06115],
              [8.21135, 50.06118],
              [8.21168, 50.06145],
              [8.21207, 50.06174],
              [8.212289, 50.061782],
              [8.2127, 50.06186],
              [8.21356, 50.06248],
              [8.21486, 50.06342],
              [8.21575, 50.06292],
              [8.21588, 50.06285],
              [8.21617, 50.06273],
              [8.21649, 50.06275],
              [8.21684, 50.06292],
              [8.21711, 50.06314],
              [8.2178, 50.06282],
              [8.21794, 50.06294],
              [8.21998, 50.0649],
              [8.21794, 50.06294],
              [8.2178, 50.06282],
              [8.21794, 50.06294],
              [8.21998, 50.0649],
              [8.22048, 50.06548],
              [8.221, 50.06593],
              [8.221162, 50.066064],
              [8.22291, 50.06727],
              [8.22279, 50.06734],
              [8.22171, 50.06811],
              [8.22082, 50.06871],
              [8.22061, 50.06885],
              [8.21978, 50.06941],
              [8.21914, 50.06983],
              [8.21835, 50.07013],
              [8.21821, 50.07016],
              [8.21746, 50.07031],
              [8.21702, 50.07051],
              [8.2168, 50.07071],
              [8.21686, 50.07084],
              [8.21673, 50.07091],
              [8.2168, 50.07117],
              [8.21716, 50.07166],
              [8.21731, 50.07174],
              [8.21785, 50.071985],
              [8.22038, 50.0731],
              [8.220794, 50.073291],
              [8.22298, 50.0743],
              [8.22372, 50.07443],
              [8.22398, 50.07443],
              [8.22381, 50.07469],
              [8.22332, 50.07518],
              [8.22314, 50.07531],
              [8.2226, 50.0757],
              [8.22208, 50.07609],
              [8.22093, 50.07714],
              [8.22071, 50.07739],
              [8.22216, 50.07755],
              [8.2223, 50.07756],
              [8.22386, 50.0777],
              [8.22585, 50.0779],
              [8.22656, 50.07796],
              [8.229983, 50.078288],
              [8.232472, 50.078508],
              [8.234521, 50.078708],
              [8.235304, 50.07879],
              [8.236248, 50.078873],
              [8.236592, 50.078914],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.2363, 50.08098],
              [8.23627, 50.08116],
              [8.23625, 50.08138],
              [8.23623, 50.08182],
              [8.23617, 50.08226],
              [8.23615, 50.08241],
              [8.23611, 50.08276],
              [8.23607, 50.08306],
              [8.23606, 50.08328],
              [8.23648, 50.0832],
              [8.2372, 50.08317],
              [8.2378, 50.08332],
              [8.23827, 50.08359],
              [8.2384, 50.08368],
              [8.23869, 50.08415],
              [8.23874, 50.08432],
              [8.23881, 50.08443],
              [8.23897, 50.08467],
              [8.2393, 50.08504],
              [8.24031, 50.08541],
              [8.24095, 50.08511],
              [8.24145, 50.08545],
              [8.24198, 50.08577],
              [8.24243, 50.08605],
              [8.24334, 50.08656],
              [8.24228, 50.08728],
              [8.24306, 50.08778],
              [8.24389, 50.08833],
              [8.24403, 50.08908],
              [8.24427, 50.08962],
              [8.24441, 50.08987],
              [8.24517, 50.09],
              [8.24495, 50.09093],
              [8.24492, 50.09187],
              [8.24512, 50.09236],
              [8.24531, 50.0927],
              [8.24582, 50.09353],
              [8.24628, 50.09435],
              [8.246358, 50.094545],
              [8.24656, 50.09505],
              [8.24663, 50.09528],
              [8.2469, 50.09601],
              [8.24731, 50.09719],
              [8.24743, 50.0983],
              [8.24606, 50.1006],
              [8.24371, 50.10464],
              [8.243139, 50.106017],
            ],
          ],
        },
        id: "Q4NjU",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "Y4Mzc",
    name: "23 Biebrich <> Neroberg",
    lineName: "23",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.231903, 50.098085],
              [8.231227, 50.098147],
              [8.231024, 50.098195],
              [8.230991, 50.098464],
              [8.231174, 50.098828],
              [8.236527, 50.100129],
              [8.238502, 50.098037],
              [8.24157, 50.0986],
              [8.24214, 50.09838],
              [8.24224, 50.09796],
              [8.24193, 50.09742],
              [8.24099, 50.09677],
              [8.24193, 50.09742],
              [8.24224, 50.09796],
              [8.24193, 50.09742],
              [8.24099, 50.09677],
              [8.239994, 50.096086],
              [8.23949, 50.09574],
              [8.2393, 50.09543],
              [8.23927, 50.09507],
              [8.23945, 50.09464],
              [8.23946, 50.09339],
              [8.23968, 50.09271],
              [8.2397, 50.09253],
              [8.23956, 50.09225],
              [8.23987, 50.09206],
              [8.241, 50.09175],
              [8.24177, 50.09132],
              [8.24271, 50.09115],
              [8.24332, 50.09028],
              [8.24441, 50.08987],
              [8.24427, 50.08962],
              [8.24403, 50.08908],
              [8.24389, 50.08833],
              [8.24306, 50.08778],
              [8.24228, 50.08728],
              [8.24334, 50.08656],
              [8.24095, 50.08511],
              [8.24031, 50.08541],
              [8.2393, 50.08504],
              [8.23897, 50.08467],
              [8.2393, 50.08504],
              [8.24031, 50.08541],
              [8.2393, 50.08504],
              [8.23897, 50.08467],
              [8.23881, 50.08443],
              [8.23874, 50.08432],
              [8.23869, 50.08415],
              [8.2384, 50.08368],
              [8.23827, 50.08359],
              [8.2378, 50.08332],
              [8.2372, 50.08317],
              [8.23648, 50.0832],
              [8.23606, 50.08328],
              [8.23593, 50.08327],
              [8.23581, 50.08327],
              [8.23583, 50.08285],
              [8.23588, 50.08241],
              [8.2359, 50.08223],
              [8.23598, 50.08168],
              [8.23605, 50.0813],
              [8.23609, 50.08107],
              [8.23612, 50.08088],
              [8.23622, 50.08024],
              [8.23623, 50.08016],
              [8.23639, 50.07893],
              [8.23644, 50.07835],
              [8.236676, 50.077684],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.23738, 50.07138],
              [8.23726, 50.07103],
              [8.23722, 50.07092],
              [8.23713, 50.0709],
              [8.2368, 50.07084],
              [8.23575, 50.07067],
              [8.2356, 50.07066],
              [8.23568, 50.070377],
              [8.23591, 50.06956],
              [8.2361, 50.06941],
              [8.23605, 50.06856],
              [8.23598, 50.06768],
              [8.235469, 50.067559],
              [8.23434, 50.06729],
              [8.23251, 50.0668],
              [8.232187, 50.067155],
              [8.232, 50.06736],
              [8.22885, 50.06939],
              [8.22758, 50.06871],
              [8.22643, 50.067935],
              [8.22522, 50.06712],
              [8.22418, 50.06642],
              [8.22543, 50.06559],
              [8.22628, 50.06504],
              [8.22639, 50.06497],
              [8.22911, 50.06337],
              [8.23001, 50.06288],
              [8.23065, 50.06249],
              [8.23204, 50.06169],
              [8.232618, 50.061368],
              [8.23523, 50.0599],
              [8.23591, 50.05977],
              [8.23609, 50.05974],
              [8.23794, 50.05856],
              [8.23871, 50.05806],
              [8.23953, 50.057563],
              [8.23993, 50.05732],
              [8.24088, 50.05676],
              [8.24151, 50.05679],
              [8.24219, 50.05671],
              [8.24338, 50.05663],
              [8.24342, 50.05647],
              [8.24364, 50.05555],
              [8.24365, 50.05536],
              [8.24365, 50.0552],
              [8.24361, 50.05504],
              [8.24355, 50.05487],
              [8.24344, 50.05459],
              [8.2434, 50.05448],
              [8.24324, 50.05407],
              [8.24308, 50.0538],
              [8.24298, 50.05359],
              [8.24293, 50.05349],
              [8.24267, 50.05281],
              [8.24235, 50.05199],
              [8.24224, 50.05171],
              [8.2422, 50.05161],
              [8.24204, 50.05124],
              [8.24183, 50.050709],
              [8.24164, 50.05023],
              [8.24127, 50.04932],
              [8.24115, 50.04901],
              [8.24104, 50.04875],
              [8.24097, 50.04827],
              [8.24095, 50.04785],
              [8.24109, 50.04783],
              [8.24205, 50.04763],
              [8.24279, 50.04747],
              [8.24386, 50.04715],
              [8.24407, 50.04708],
              [8.24434, 50.04737],
              [8.24531, 50.04754],
              [8.24732, 50.04675],
              [8.24837, 50.04654],
              [8.24937, 50.04659],
              [8.24932, 50.04704],
              [8.24913, 50.04715],
              [8.2486, 50.04715],
              [8.248025, 50.047288],
            ],
          ],
        },
        id: "Y4Mzc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.248025, 50.047288],
              [8.24785, 50.04733],
              [8.24774, 50.04756],
              [8.24707, 50.0476],
              [8.24648, 50.04765],
              [8.24531, 50.04754],
              [8.24434, 50.04737],
              [8.24407, 50.04708],
              [8.24386, 50.04715],
              [8.24279, 50.04747],
              [8.24205, 50.04763],
              [8.24109, 50.04783],
              [8.2411, 50.04823],
              [8.24126, 50.04884],
              [8.24131, 50.04897],
              [8.24146, 50.04929],
              [8.24179, 50.05015],
              [8.241973, 50.050675],
              [8.24216, 50.05121],
              [8.24235, 50.05159],
              [8.24238, 50.05169],
              [8.24248, 50.05195],
              [8.24251, 50.05202],
              [8.24268, 50.05244],
              [8.24284, 50.05285],
              [8.24297, 50.05316],
              [8.243, 50.05324],
              [8.2432, 50.05378],
              [8.24324, 50.05407],
              [8.2434, 50.05448],
              [8.24344, 50.05459],
              [8.24355, 50.05487],
              [8.24361, 50.05504],
              [8.24365, 50.0552],
              [8.24365, 50.05536],
              [8.24364, 50.05555],
              [8.24342, 50.05647],
              [8.24338, 50.05663],
              [8.24219, 50.05671],
              [8.24151, 50.05679],
              [8.24088, 50.05676],
              [8.23993, 50.05732],
              [8.23953, 50.057563],
              [8.23871, 50.05806],
              [8.23794, 50.05856],
              [8.23609, 50.05974],
              [8.23591, 50.05977],
              [8.23523, 50.0599],
              [8.232618, 50.061368],
              [8.23204, 50.06169],
              [8.23065, 50.06249],
              [8.23001, 50.06288],
              [8.22911, 50.06337],
              [8.22639, 50.06497],
              [8.22628, 50.06504],
              [8.22543, 50.06559],
              [8.22418, 50.06642],
              [8.22522, 50.06712],
              [8.22643, 50.067935],
              [8.22758, 50.06871],
              [8.22885, 50.06939],
              [8.232, 50.06736],
              [8.232187, 50.067155],
              [8.23251, 50.0668],
              [8.232187, 50.067155],
              [8.232, 50.06736],
              [8.23223, 50.06763],
              [8.23235, 50.068],
              [8.23262, 50.06904],
              [8.23269, 50.069295],
              [8.23282, 50.06977],
              [8.23301, 50.07028],
              [8.23307, 50.07063],
              [8.23339, 50.07169],
              [8.23344, 50.07182],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.23872, 50.07439],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.23674, 50.07786],
              [8.23658, 50.07894],
              [8.23654, 50.07946],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.2363, 50.08098],
              [8.23627, 50.08116],
              [8.23625, 50.08138],
              [8.23623, 50.08182],
              [8.23617, 50.08226],
              [8.23615, 50.08241],
              [8.23611, 50.08276],
              [8.23607, 50.08306],
              [8.23606, 50.08328],
              [8.23648, 50.0832],
              [8.2372, 50.08317],
              [8.2378, 50.08332],
              [8.23827, 50.08359],
              [8.2384, 50.08368],
              [8.23869, 50.08415],
              [8.23874, 50.08432],
              [8.23881, 50.08443],
              [8.23897, 50.08467],
              [8.2393, 50.08504],
              [8.24031, 50.08541],
              [8.24095, 50.08511],
              [8.24334, 50.08656],
              [8.24228, 50.08728],
              [8.24306, 50.08778],
              [8.24389, 50.08833],
              [8.24403, 50.08908],
              [8.24427, 50.08962],
              [8.24441, 50.08987],
              [8.24517, 50.09],
              [8.24495, 50.09093],
              [8.24453, 50.09104],
              [8.24449, 50.09097],
              [8.24271, 50.09115],
              [8.24177, 50.09132],
              [8.241, 50.09175],
              [8.23987, 50.09206],
              [8.23956, 50.09225],
              [8.2397, 50.09253],
              [8.23968, 50.09271],
              [8.23946, 50.09339],
              [8.23945, 50.09464],
              [8.23927, 50.09507],
              [8.2393, 50.09543],
              [8.23949, 50.09574],
              [8.239994, 50.096086],
              [8.24099, 50.09677],
              [8.24193, 50.09742],
              [8.24224, 50.09796],
              [8.24214, 50.09838],
              [8.24157, 50.0986],
              [8.238491, 50.09803],
              [8.236506, 50.100122],
              [8.233513, 50.099379],
              [8.233051, 50.097906],
              [8.231903, 50.098071],
            ],
          ],
        },
        id: "Y1NjI",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "M0MDg",
    name: "22 Mainz-Gonsenheim <> Bierstadt",
    lineName: "22",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.28144, 50.08026],
              [8.28106, 50.07961],
              [8.28454, 50.07912],
              [8.28782, 50.079],
              [8.28708, 50.07984],
              [8.28672, 50.08071],
              [8.28671, 50.08218],
              [8.28638, 50.08293],
              [8.28633, 50.08305],
              [8.28613, 50.08342],
              [8.28541, 50.0838],
              [8.28459, 50.08377],
              [8.28332, 50.08368],
              [8.28178, 50.08347],
              [8.28095, 50.08333],
              [8.28072, 50.08331],
              [8.28057, 50.0833],
              [8.27913, 50.08398],
              [8.27866, 50.08419],
              [8.27768, 50.084646],
              [8.27724, 50.08485],
              [8.27667, 50.08509],
              [8.27653, 50.08513],
              [8.275, 50.08543],
              [8.27506, 50.08565],
              [8.27565, 50.0861],
              [8.27587, 50.08626],
              [8.27611, 50.0866],
              [8.27624, 50.0869],
              [8.27618, 50.08724],
              [8.27598, 50.08751],
              [8.27562, 50.08774],
              [8.274887, 50.087803],
              [8.27457, 50.08783],
              [8.27272, 50.08758],
              [8.27123, 50.08734],
              [8.26937, 50.08708],
              [8.269145, 50.087068],
              [8.26844, 50.08703],
              [8.26775, 50.08708],
              [8.26715, 50.0872],
              [8.26395, 50.0881],
              [8.26239, 50.08793],
              [8.26125, 50.08761],
              [8.26036, 50.08719],
              [8.2604, 50.08716],
              [8.26034, 50.08702],
              [8.26069, 50.08661],
              [8.26075, 50.08636],
              [8.2605, 50.0855],
              [8.26007, 50.08485],
              [8.25997, 50.08472],
              [8.25925, 50.08381],
              [8.25909, 50.08359],
              [8.25846, 50.0831],
              [8.258472, 50.082883],
              [8.25848, 50.08273],
              [8.25562, 50.08218],
              [8.25536, 50.08215],
              [8.25421, 50.0819],
              [8.25398, 50.08184],
              [8.25238, 50.08142],
              [8.25159, 50.08121],
              [8.25069, 50.08098],
              [8.250337, 50.080887],
              [8.24982, 50.08075],
              [8.2496, 50.08069],
              [8.24875, 50.08047],
              [8.24787, 50.08022],
              [8.24759, 50.08014],
              [8.24687, 50.07991],
              [8.24632, 50.07958],
              [8.24587, 50.07943],
              [8.24507, 50.07936],
              [8.24521, 50.07813],
              [8.24525, 50.07796],
              [8.245312, 50.077414],
              [8.24537, 50.07691],
              [8.24528, 50.07677],
              [8.24535, 50.07539],
              [8.24521, 50.07381],
              [8.24503, 50.0734],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.23738, 50.07138],
              [8.23726, 50.07103],
              [8.23722, 50.07092],
              [8.23713, 50.0709],
              [8.2368, 50.07084],
              [8.23575, 50.07067],
              [8.2356, 50.07066],
              [8.23568, 50.070377],
              [8.23591, 50.06956],
              [8.2361, 50.06941],
              [8.23605, 50.06856],
              [8.23598, 50.06768],
              [8.235469, 50.067559],
              [8.23434, 50.06729],
              [8.23251, 50.0668],
              [8.23438, 50.0645],
              [8.23461, 50.0643],
              [8.23749, 50.06269],
              [8.237604, 50.062628],
              [8.23822, 50.06229],
              [8.23851, 50.06213],
              [8.23966, 50.0615],
              [8.23897, 50.06104],
              [8.23797, 50.06069],
              [8.23697, 50.06032],
              [8.23612, 50.05995],
              [8.23591, 50.05977],
              [8.2351, 50.05946],
              [8.23508, 50.05897],
              [8.23492, 50.05889],
              [8.23503, 50.0588],
              [8.23485, 50.05837],
              [8.23489, 50.05795],
              [8.23592, 50.05674],
              [8.237503, 50.054518],
              [8.2379, 50.05396],
              [8.23844, 50.05297],
              [8.2387, 50.05207],
              [8.23897, 50.05206],
              [8.2403, 50.05184],
              [8.24125, 50.05145],
              [8.24204, 50.05124],
              [8.24183, 50.050709],
              [8.24164, 50.05023],
              [8.24127, 50.04932],
              [8.24115, 50.04901],
              [8.24104, 50.04875],
              [8.24097, 50.04827],
              [8.24095, 50.04785],
              [8.24109, 50.04783],
              [8.24108, 50.04768],
              [8.24103, 50.04725],
              [8.24111, 50.04482],
              [8.24084, 50.04427],
              [8.24075, 50.04406],
              [8.24066, 50.04387],
              [8.24026, 50.04295],
              [8.23988, 50.04208],
              [8.23971, 50.04173],
              [8.23963, 50.04157],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23684, 50.03639],
              [8.23645, 50.03653],
              [8.23523, 50.03691],
              [8.23501, 50.03695],
              [8.23292, 50.0373],
              [8.23146, 50.03754],
              [8.23124, 50.03758],
              [8.23113, 50.0376],
              [8.23087, 50.03766],
              [8.2282, 50.03868],
              [8.2263, 50.03916],
              [8.225601, 50.039314],
              [8.221736, 50.040166],
              [8.22136, 50.04025],
              [8.22118, 50.04029],
              [8.21983, 50.04058],
              [8.216713, 50.041284],
              [8.21347, 50.042],
              [8.21293, 50.04213],
              [8.21257, 50.04221],
              [8.21018, 50.04276],
              [8.21004, 50.04302],
              [8.21003, 50.04317],
              [8.21004, 50.04334],
              [8.21005, 50.04369],
              [8.21014, 50.04393],
              [8.21068, 50.04454],
              [8.21089, 50.04466],
              [8.21165, 50.04489],
              [8.21244, 50.04496],
              [8.2128, 50.04489],
              [8.21309, 50.04464],
              [8.21307, 50.04407],
              [8.21306, 50.04364],
              [8.21305, 50.0434],
              [8.21299, 50.04283],
              [8.21294, 50.04239],
              [8.21293, 50.04213],
              [8.21285, 50.04151],
              [8.21262, 50.03942],
              [8.21216, 50.03527],
              [8.21193, 50.03327],
              [8.21176, 50.03172],
              [8.21172, 50.03134],
              [8.21171, 50.03118],
              [8.21163, 50.03038],
              [8.21126, 50.02973],
              [8.21076, 50.02945],
              [8.21008, 50.02941],
              [8.20954, 50.02965],
              [8.20942, 50.02993],
              [8.20952, 50.03021],
              [8.21004, 50.0305],
              [8.21164, 50.03056],
              [8.21253, 50.03041],
              [8.21385, 50.02993],
              [8.21518, 50.02952],
              [8.21763, 50.02917],
              [8.21916, 50.02883],
              [8.21961, 50.02864],
              [8.22, 50.02841],
              [8.22032, 50.02799],
              [8.22048, 50.02767],
              [8.22041, 50.02738],
              [8.22041, 50.02725],
              [8.22018, 50.02643],
              [8.22008, 50.02605],
              [8.22046, 50.02599],
              [8.22122, 50.02574],
              [8.22109, 50.02436],
              [8.22096, 50.02384],
              [8.220777, 50.023527],
              [8.22041, 50.0229],
              [8.22024, 50.02286],
              [8.22029, 50.02275],
              [8.22022, 50.02256],
              [8.22058, 50.0216],
              [8.22071, 50.02102],
              [8.22079, 50.01961],
              [8.22076, 50.01911],
              [8.22043, 50.01832],
              [8.22014, 50.01786],
              [8.22, 50.0176],
              [8.21984, 50.01727],
              [8.2195, 50.01653],
              [8.21922, 50.01594],
              [8.21906, 50.01559],
              [8.21848, 50.0144],
              [8.21811, 50.01357],
              [8.218031, 50.013397],
              [8.21785, 50.013],
              [8.21781, 50.01291],
              [8.21775, 50.0128],
              [8.21771, 50.01272],
              [8.2173, 50.01186],
              [8.21718, 50.01161],
              [8.21682, 50.01082],
              [8.21663, 50.01051],
              [8.21611, 50.00971],
              [8.21552, 50.00915],
              [8.21508, 50.00921],
              [8.21426, 50.00898],
              [8.21176, 50.00829],
              [8.208631, 50.007358],
              [8.20696, 50.00686],
              [8.20645, 50.00673],
              [8.20612, 50.00662],
              [8.20571, 50.00649],
              [8.20414, 50.00599],
              [8.20304, 50.00554],
              [8.20252, 50.00533],
              [8.20107, 50.0047],
              [8.20152, 50.00425],
              [8.20227, 50.00353],
              [8.20386, 50.00204],
              [8.20387, 50.00188],
              [8.20409, 50.00181],
              [8.20442, 50.00145],
              [8.20514, 50.00165],
              [8.20592, 50.00173],
              [8.20707, 50.00192],
              [8.20714, 50.00194],
              [8.20811, 50.0022],
              [8.20856, 50.00233],
              [8.21015, 50.00277],
              [8.21035, 50.00282],
              [8.21159, 50.00316],
              [8.213, 50.00355],
            ],
          ],
        },
        id: "M0MDg",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.213, 50.00355],
              [8.21159, 50.00316],
              [8.21035, 50.00282],
              [8.21015, 50.00277],
              [8.20856, 50.00233],
              [8.20811, 50.0022],
              [8.20714, 50.00194],
              [8.20707, 50.00192],
              [8.20592, 50.00173],
              [8.20514, 50.00165],
              [8.20442, 50.00145],
              [8.20409, 50.00181],
              [8.20387, 50.00188],
              [8.20386, 50.00204],
              [8.20227, 50.00353],
              [8.20152, 50.00425],
              [8.20107, 50.0047],
              [8.20252, 50.00533],
              [8.20304, 50.00554],
              [8.20414, 50.00599],
              [8.20571, 50.00649],
              [8.20612, 50.00662],
              [8.20645, 50.00673],
              [8.20536, 50.00828],
              [8.20534, 50.00852],
              [8.20563, 50.00876],
              [8.20649, 50.00886],
              [8.20708, 50.00834],
              [8.20741, 50.00832],
              [8.207803, 50.008425],
              [8.20935, 50.00884],
              [8.21073, 50.00924],
              [8.21089, 50.00923],
              [8.21121, 50.00907],
              [8.21176, 50.00829],
              [8.21426, 50.00898],
              [8.21508, 50.00921],
              [8.21552, 50.00915],
              [8.21611, 50.00971],
              [8.21663, 50.01051],
              [8.21682, 50.01082],
              [8.21718, 50.01161],
              [8.2173, 50.01186],
              [8.21771, 50.01272],
              [8.21775, 50.0128],
              [8.21781, 50.01291],
              [8.21785, 50.013],
              [8.218031, 50.013397],
              [8.21811, 50.01357],
              [8.21848, 50.0144],
              [8.21906, 50.01559],
              [8.21922, 50.01594],
              [8.2195, 50.01653],
              [8.21984, 50.01727],
              [8.22, 50.0176],
              [8.22014, 50.01786],
              [8.22043, 50.01832],
              [8.22076, 50.01911],
              [8.22079, 50.01961],
              [8.22071, 50.02102],
              [8.22058, 50.0216],
              [8.22022, 50.02256],
              [8.22029, 50.02275],
              [8.22024, 50.02286],
              [8.22041, 50.0229],
              [8.220777, 50.023527],
              [8.22096, 50.02384],
              [8.22109, 50.02436],
              [8.22122, 50.02574],
              [8.22046, 50.02599],
              [8.22008, 50.02605],
              [8.22018, 50.02643],
              [8.22041, 50.02725],
              [8.22041, 50.02738],
              [8.22048, 50.02767],
              [8.22047, 50.02806],
              [8.22041, 50.02814],
              [8.22048, 50.0284],
              [8.22071, 50.02852],
              [8.21977, 50.02875],
              [8.21921, 50.02893],
              [8.21852, 50.02917],
              [8.21672, 50.02964],
              [8.21599, 50.02971],
              [8.21486, 50.02959],
              [8.21348, 50.02929],
              [8.21258, 50.0293],
              [8.21203, 50.02957],
              [8.21185, 50.02983],
              [8.21174, 50.03028],
              [8.21175, 50.03037],
              [8.21182, 50.03099],
              [8.21186, 50.03135],
              [8.21206, 50.03326],
              [8.21227, 50.03512],
              [8.21275, 50.03942],
              [8.21298, 50.04143],
              [8.21308, 50.04209],
              [8.21312, 50.04239],
              [8.21334, 50.04274],
              [8.21405, 50.04293],
              [8.21549, 50.04306],
              [8.21623, 50.04335],
              [8.2167, 50.0438],
              [8.21678, 50.04426],
              [8.21676, 50.04434],
              [8.21641, 50.04446],
              [8.21616, 50.04456],
              [8.214199, 50.044255],
              [8.21365, 50.04416],
              [8.213409, 50.044122],
              [8.21324, 50.0441],
              [8.21307, 50.04407],
              [8.21268, 50.04401],
              [8.21151, 50.04384],
              [8.21072, 50.0437],
              [8.21046, 50.04367],
              [8.21004, 50.04334],
              [8.21003, 50.04317],
              [8.21004, 50.04302],
              [8.21018, 50.04276],
              [8.21257, 50.04221],
              [8.21293, 50.04213],
              [8.21347, 50.042],
              [8.216713, 50.041284],
              [8.21983, 50.04058],
              [8.22118, 50.04029],
              [8.22136, 50.04025],
              [8.221736, 50.040166],
              [8.225601, 50.039314],
              [8.2263, 50.03916],
              [8.2282, 50.03868],
              [8.23087, 50.03766],
              [8.23113, 50.0376],
              [8.23124, 50.03758],
              [8.23146, 50.03754],
              [8.23292, 50.0373],
              [8.23501, 50.03695],
              [8.23523, 50.03691],
              [8.23645, 50.03653],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23862, 50.03686],
              [8.23911, 50.03682],
              [8.23916, 50.0369],
              [8.23984, 50.03824],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.24111, 50.04086],
              [8.24119, 50.04111],
              [8.24123, 50.04126],
              [8.24122, 50.04133],
              [8.24118, 50.04279],
              [8.24116, 50.04376],
              [8.24115, 50.04427],
              [8.24111, 50.04482],
              [8.24103, 50.04725],
              [8.24108, 50.04768],
              [8.24109, 50.04783],
              [8.2411, 50.04823],
              [8.24126, 50.04884],
              [8.24131, 50.04897],
              [8.24146, 50.04929],
              [8.24179, 50.05015],
              [8.241973, 50.050675],
              [8.24216, 50.05121],
              [8.24204, 50.05124],
              [8.24125, 50.05145],
              [8.2403, 50.05184],
              [8.23897, 50.05206],
              [8.2387, 50.05207],
              [8.23844, 50.05297],
              [8.2379, 50.05396],
              [8.237503, 50.054518],
              [8.23592, 50.05674],
              [8.23489, 50.05795],
              [8.23485, 50.05837],
              [8.23503, 50.0588],
              [8.23492, 50.05889],
              [8.23508, 50.05897],
              [8.2351, 50.05946],
              [8.23591, 50.05977],
              [8.23612, 50.05995],
              [8.23697, 50.06032],
              [8.23797, 50.06069],
              [8.23897, 50.06104],
              [8.23966, 50.0615],
              [8.23851, 50.06213],
              [8.23822, 50.06229],
              [8.237604, 50.062628],
              [8.23749, 50.06269],
              [8.23461, 50.0643],
              [8.23438, 50.0645],
              [8.23251, 50.0668],
              [8.232187, 50.067155],
              [8.232, 50.06736],
              [8.23223, 50.06763],
              [8.23235, 50.068],
              [8.23262, 50.06904],
              [8.23269, 50.069295],
              [8.23282, 50.06977],
              [8.23301, 50.07028],
              [8.23307, 50.07063],
              [8.23339, 50.07169],
              [8.23344, 50.07182],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24492, 50.07179],
              [8.24493, 50.07231],
              [8.24536, 50.07338],
              [8.245401, 50.073665],
              [8.24542, 50.0738],
              [8.24563, 50.07517],
              [8.2455, 50.0768],
              [8.24537, 50.07691],
              [8.245312, 50.077414],
              [8.24525, 50.07796],
              [8.24521, 50.07813],
              [8.24507, 50.07936],
              [8.24587, 50.07943],
              [8.24632, 50.07958],
              [8.24687, 50.07991],
              [8.24759, 50.08014],
              [8.24787, 50.08022],
              [8.24875, 50.08047],
              [8.2496, 50.08069],
              [8.24982, 50.08075],
              [8.250337, 50.080887],
              [8.25069, 50.08098],
              [8.25159, 50.08121],
              [8.25238, 50.08142],
              [8.25398, 50.08184],
              [8.25421, 50.0819],
              [8.25536, 50.08215],
              [8.25562, 50.08218],
              [8.25848, 50.08273],
              [8.258472, 50.082883],
              [8.25846, 50.0831],
              [8.25909, 50.08359],
              [8.25925, 50.08381],
              [8.25997, 50.08472],
              [8.26007, 50.08485],
              [8.2605, 50.0855],
              [8.26075, 50.08636],
              [8.26069, 50.08661],
              [8.26034, 50.08702],
              [8.2604, 50.08716],
              [8.26036, 50.08719],
              [8.26125, 50.08761],
              [8.26239, 50.08793],
              [8.26395, 50.0881],
              [8.26715, 50.0872],
              [8.26775, 50.08708],
              [8.26844, 50.08703],
              [8.269145, 50.087068],
              [8.26937, 50.08708],
              [8.27123, 50.08734],
              [8.27272, 50.08758],
              [8.27457, 50.08783],
              [8.274887, 50.087803],
              [8.27562, 50.08774],
              [8.27598, 50.08751],
              [8.27618, 50.08724],
              [8.27624, 50.0869],
              [8.27611, 50.0866],
              [8.27587, 50.08626],
              [8.27565, 50.0861],
              [8.27506, 50.08565],
              [8.275, 50.08543],
              [8.27653, 50.08513],
              [8.27667, 50.08509],
              [8.27724, 50.08485],
              [8.27768, 50.084646],
              [8.27866, 50.08419],
              [8.27913, 50.08398],
              [8.28057, 50.0833],
              [8.28069, 50.08326],
              [8.28078, 50.08323],
              [8.28085, 50.0832],
              [8.28096, 50.08316],
              [8.28119, 50.08307],
              [8.28198, 50.08271],
              [8.28307, 50.08219],
              [8.28327, 50.08212],
              [8.28367, 50.08217],
              [8.28435, 50.08232],
              [8.28453, 50.08235],
              [8.28526, 50.08255],
              [8.28638, 50.08293],
              [8.28671, 50.08218],
              [8.28672, 50.08071],
              [8.28708, 50.07984],
              [8.28782, 50.079],
              [8.28454, 50.07912],
              [8.28106, 50.07961],
              [8.28144, 50.08026],
            ],
          ],
        },
        id: "g1MTA",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "k5MDE",
    name: "12 Kohlheck <> Oberjosbach",
    lineName: "12",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.336779, 50.169795],
              [8.336467, 50.170198],
              [8.336327, 50.170095],
              [8.334932, 50.169779],
              [8.334471, 50.169594],
              [8.333087, 50.168487],
              [8.332379, 50.168157],
              [8.33143, 50.16785],
              [8.33075, 50.16766],
              [8.3301, 50.16745],
              [8.32942, 50.16724],
              [8.32838, 50.16707],
              [8.32774, 50.16697],
              [8.32651, 50.16663],
              [8.32614, 50.16646],
              [8.32647, 50.16621],
              [8.32654, 50.16605],
              [8.32629, 50.16557],
              [8.32572, 50.16473],
              [8.325555, 50.164325],
              [8.32548, 50.16414],
              [8.32556, 50.16389],
              [8.32612, 50.16332],
              [8.32603, 50.16275],
              [8.32595, 50.16226],
              [8.32584, 50.16157],
              [8.3257, 50.16079],
              [8.3255, 50.16072],
              [8.32529, 50.16076],
              [8.32455, 50.16118],
              [8.32306, 50.16196],
              [8.32286, 50.16193],
              [8.32277, 50.1618],
              [8.32324, 50.16065],
              [8.32366, 50.16007],
              [8.32347, 50.1587],
              [8.3233, 50.15872],
              [8.32293, 50.15869],
              [8.322659, 50.158595],
              [8.32147, 50.15818],
              [8.32086, 50.15886],
              [8.32011, 50.15968],
              [8.31936, 50.16046],
              [8.31849, 50.161],
              [8.31765, 50.16136],
              [8.31811, 50.16172],
              [8.317703, 50.162029],
              [8.31728, 50.16235],
              [8.31727, 50.16381],
              [8.31726, 50.16458],
              [8.31719, 50.16552],
              [8.31714, 50.16632],
              [8.31709, 50.16685],
              [8.31705, 50.16728],
              [8.31697, 50.168145],
              [8.31697, 50.16815],
              [8.31657, 50.16886],
              [8.31596, 50.16928],
              [8.31521, 50.16955],
              [8.31444, 50.16969],
              [8.31421, 50.16973],
              [8.31314, 50.16997],
              [8.31251, 50.17027],
              [8.31168, 50.17088],
              [8.3114, 50.17109],
              [8.30989, 50.17051],
              [8.30938, 50.17015],
              [8.30915, 50.16971],
              [8.309153, 50.16969],
              [8.30923, 50.16923],
              [8.30934, 50.16901],
              [8.30985, 50.1686],
              [8.31182, 50.16779],
              [8.31259, 50.16763],
              [8.31303, 50.16754],
              [8.313707, 50.167426],
              [8.31422, 50.16734],
              [8.31396, 50.16655],
              [8.31357, 50.16547],
              [8.31327, 50.1652],
              [8.3126, 50.16462],
              [8.31226, 50.16434],
              [8.312828, 50.163967],
              [8.31508, 50.16249],
              [8.31396, 50.16151],
              [8.31355, 50.16102],
              [8.3133, 50.16084],
              [8.31298, 50.16096],
              [8.3124, 50.1605],
              [8.31161, 50.16086],
              [8.30941, 50.16176],
              [8.30914, 50.16175],
              [8.30902, 50.16187],
              [8.30891, 50.16194],
              [8.30821, 50.16218],
              [8.30772, 50.16243],
              [8.30737, 50.1624],
              [8.30683, 50.16207],
              [8.30722, 50.16168],
              [8.30716, 50.16157],
              [8.30668, 50.16141],
              [8.30622, 50.1613],
              [8.30617, 50.16122],
              [8.30645, 50.16106],
              [8.30796, 50.16017],
              [8.30936, 50.15917],
              [8.30959, 50.15901],
              [8.30994, 50.158529],
              [8.31018, 50.1582],
              [8.31009, 50.15773],
              [8.30955, 50.15702],
              [8.31119, 50.15583],
              [8.31177, 50.15539],
              [8.31362, 50.15431],
              [8.31403, 50.15422],
              [8.31359, 50.15341],
              [8.31304, 50.15298],
              [8.31216, 50.1526],
              [8.3114, 50.15231],
              [8.31091, 50.15214],
              [8.31075, 50.15209],
              [8.31052, 50.15201],
              [8.31019, 50.15186],
              [8.30993, 50.15173],
              [8.30949, 50.15124],
              [8.30953, 50.15082],
              [8.31043, 50.14992],
              [8.31078, 50.14943],
              [8.31083, 50.14927],
              [8.31084, 50.14866],
              [8.31082, 50.14855],
              [8.31077, 50.14842],
              [8.31047, 50.14772],
              [8.31035, 50.14745],
              [8.31017, 50.14703],
              [8.30995, 50.14651],
              [8.30956, 50.14561],
              [8.30926, 50.14521],
              [8.30818, 50.14474],
              [8.30725, 50.14433],
              [8.30711, 50.14424],
              [8.30634, 50.14346],
              [8.30618, 50.14283],
              [8.3062, 50.14255],
              [8.30623, 50.14233],
              [8.30632, 50.14184],
              [8.30724, 50.14015],
              [8.30739, 50.13988],
              [8.30568, 50.13978],
              [8.30508, 50.13954],
              [8.30483, 50.13916],
              [8.30471, 50.13859],
              [8.30468, 50.13838],
              [8.30458, 50.13784],
              [8.30368, 50.137],
              [8.30244, 50.13601],
              [8.30238, 50.13596],
              [8.30201, 50.13567],
              [8.30164, 50.13534],
              [8.3015, 50.13521],
              [8.30122, 50.13505],
              [8.30077, 50.13482],
              [8.29899, 50.13375],
              [8.29888, 50.1337],
              [8.297809, 50.133072],
              [8.2976, 50.13295],
              [8.29655, 50.13257],
              [8.29646, 50.13255],
              [8.29581, 50.13241],
              [8.29513, 50.13242],
              [8.29449, 50.13278],
              [8.29407, 50.13281],
              [8.29304, 50.13258],
              [8.2928, 50.1323],
              [8.29286, 50.13197],
              [8.29374, 50.13106],
              [8.29383, 50.13088],
              [8.29405, 50.13021],
              [8.29464, 50.13022],
              [8.29532, 50.13011],
              [8.29545, 50.12996],
              [8.2954, 50.12977],
              [8.29481, 50.12902],
              [8.2948, 50.12883],
              [8.29511, 50.12857],
              [8.29546, 50.12841],
              [8.29659, 50.1278],
              [8.29608, 50.12723],
              [8.29601, 50.12692],
              [8.29599, 50.12663],
              [8.29641, 50.12635],
              [8.29676, 50.12616],
              [8.29703, 50.12601],
              [8.29742, 50.126],
              [8.29762, 50.126],
              [8.29785, 50.12601],
              [8.29861, 50.12605],
              [8.29863, 50.12663],
              [8.29856, 50.12676],
              [8.298, 50.12707],
              [8.297449, 50.127355],
              [8.29659, 50.1278],
              [8.29546, 50.12841],
              [8.29511, 50.12857],
              [8.2948, 50.12883],
              [8.29481, 50.12902],
              [8.2954, 50.12977],
              [8.29545, 50.12996],
              [8.29532, 50.13011],
              [8.29464, 50.13022],
              [8.29405, 50.13021],
              [8.29337, 50.13003],
              [8.2923, 50.1296],
              [8.29185, 50.12943],
              [8.2914, 50.12926],
              [8.29035, 50.1288],
              [8.2879, 50.12733],
              [8.28703, 50.12692],
              [8.2852, 50.12661],
              [8.28278, 50.12631],
              [8.28147, 50.12586],
              [8.28102, 50.12568],
              [8.28033, 50.12538],
              [8.27986, 50.12495],
              [8.2798, 50.12426],
              [8.27949, 50.12395],
              [8.27866, 50.12375],
              [8.27829, 50.12361],
              [8.27789, 50.12331],
              [8.27782, 50.12281],
              [8.27798, 50.1225],
              [8.27803, 50.12219],
              [8.27771, 50.12185],
              [8.27736, 50.12164],
              [8.27718, 50.12152],
              [8.27701, 50.12137],
              [8.27654, 50.12079],
              [8.27607, 50.11952],
              [8.27598, 50.11932],
              [8.27569, 50.11905],
              [8.27469, 50.11819],
              [8.27389, 50.11732],
              [8.2738, 50.11727],
              [8.2736, 50.11718],
              [8.273425, 50.11711],
              [8.2729, 50.1169],
              [8.27185, 50.11688],
              [8.27075, 50.11686],
              [8.27038, 50.11673],
              [8.26999, 50.11634],
              [8.26998, 50.11604],
              [8.27006, 50.11586],
              [8.270361, 50.115466],
              [8.2719, 50.11345],
              [8.2723, 50.11288],
              [8.27239, 50.11276],
              [8.27254, 50.11258],
              [8.27258, 50.11253],
              [8.27212, 50.11215],
              [8.26911, 50.1096],
              [8.268855, 50.10939],
              [8.26826, 50.1089],
              [8.26823, 50.10884],
              [8.2682, 50.10796],
              [8.26818, 50.10781],
              [8.26831, 50.10746],
              [8.268428, 50.107129],
              [8.26867, 50.10645],
              [8.26844, 50.10587],
              [8.26749, 50.10548],
              [8.266309, 50.104728],
              [8.26526, 50.10406],
              [8.26437, 50.10329],
              [8.26409, 50.10295],
              [8.26421, 50.10242],
              [8.26417, 50.10217],
              [8.26416, 50.10183],
              [8.26423, 50.10168],
              [8.26442, 50.10102],
              [8.26432, 50.10045],
              [8.26416, 50.09982],
              [8.26408, 50.09971],
              [8.26334, 50.09927],
              [8.26227, 50.09874],
              [8.26133, 50.09826],
              [8.26034, 50.09719],
              [8.25971, 50.09657],
              [8.25916, 50.09604],
              [8.25754, 50.09447],
              [8.25743, 50.09431],
              [8.25723, 50.09354],
              [8.25714, 50.09315],
              [8.25702, 50.09211],
              [8.25709, 50.09135],
              [8.257247, 50.090718],
              [8.25757, 50.08942],
              [8.25749, 50.08891],
              [8.2572, 50.08787],
              [8.25715, 50.08771],
              [8.25677, 50.08674],
              [8.25609, 50.0862],
              [8.25541, 50.08593],
              [8.25322, 50.08547],
              [8.25293, 50.08542],
              [8.252203, 50.085318],
              [8.25172, 50.08525],
              [8.25151, 50.08524],
              [8.24809, 50.08553],
              [8.24693, 50.08567],
              [8.24665, 50.08571],
              [8.24526, 50.08592],
              [8.24439, 50.08601],
              [8.24437, 50.0857],
              [8.24439, 50.08537],
              [8.24444, 50.08499],
              [8.24446, 50.08483],
              [8.24451, 50.08446],
              [8.24452, 50.08436],
              [8.24453, 50.0843],
              [8.24461, 50.08374],
              [8.24463, 50.08332],
              [8.24466, 50.08299],
              [8.2447, 50.0827],
              [8.24486, 50.08134],
              [8.244894, 50.081014],
              [8.24493, 50.08066],
              [8.24507, 50.07936],
              [8.24521, 50.07813],
              [8.24525, 50.07796],
              [8.245312, 50.077414],
              [8.24537, 50.07691],
              [8.24528, 50.07677],
              [8.24535, 50.07539],
              [8.24521, 50.07381],
              [8.24503, 50.0734],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.2405, 50.07147],
              [8.24038, 50.07135],
              [8.239065, 50.071401],
              [8.23747, 50.0716],
              [8.23738, 50.07138],
              [8.23726, 50.07103],
              [8.23722, 50.07092],
              [8.23713, 50.0709],
              [8.2368, 50.07084],
              [8.23575, 50.07067],
              [8.2356, 50.07066],
              [8.23568, 50.070377],
              [8.23591, 50.06956],
              [8.2361, 50.06941],
              [8.23605, 50.06856],
              [8.23598, 50.06768],
              [8.235469, 50.067559],
              [8.23434, 50.06729],
              [8.23251, 50.0668],
              [8.232187, 50.067155],
              [8.232, 50.06736],
              [8.22885, 50.06939],
              [8.228524, 50.069651],
              [8.227408, 50.070617],
              [8.226217, 50.069653],
              [8.22609, 50.06969],
              [8.22571, 50.06996],
              [8.22529, 50.07022],
              [8.22501, 50.07041],
              [8.22446, 50.07071],
              [8.22388, 50.07105],
              [8.22355, 50.07126],
              [8.22263, 50.07176],
              [8.22038, 50.0731],
              [8.21785, 50.071985],
              [8.21464, 50.07105],
              [8.21402, 50.0707],
              [8.21402, 50.0707],
              [8.21283, 50.07123],
              [8.2124, 50.07153],
              [8.21162, 50.07262],
              [8.21126, 50.07315],
              [8.210913, 50.073426],
              [8.21053, 50.07373],
              [8.20949, 50.07461],
              [8.20843, 50.07542],
              [8.20833, 50.0755],
              [8.20778, 50.07614],
              [8.20769, 50.07623],
              [8.204227, 50.075836],
              [8.2037, 50.07579],
              [8.20326, 50.07574],
              [8.20294, 50.07573],
              [8.2025, 50.07569],
              [8.20222, 50.07628],
              [8.20158, 50.07741],
              [8.20119, 50.07809],
              [8.20056, 50.07904],
              [8.20033, 50.07975],
              [8.19996, 50.08027],
              [8.19964, 50.08054],
              [8.19903, 50.08089],
              [8.19536, 50.08274],
              [8.19454, 50.08345],
              [8.1921, 50.08264],
              [8.19213, 50.08198],
              [8.19212, 50.0812],
              [8.19195, 50.08049],
              [8.19151, 50.07882],
              [8.191387, 50.078496],
            ],
          ],
        },
        id: "k5MDE",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.191381, 50.078515],
              [8.191123, 50.077813],
              [8.191209, 50.077689],
              [8.191402, 50.077537],
              [8.191659, 50.077399],
              [8.192132, 50.077386],
              [8.192496, 50.077427],
              [8.192904, 50.077634],
              [8.193161, 50.077758],
              [8.193805, 50.07773],
              [8.194385, 50.077647],
              [8.195415, 50.077799],
              [8.195844, 50.07795],
              [8.196595, 50.077992],
              [8.196874, 50.077923],
              [8.197582, 50.077716],
              [8.198268, 50.077441],
              [8.198869, 50.077331],
              [8.200285, 50.077372],
              [8.20158, 50.07741],
              [8.20222, 50.07628],
              [8.2025, 50.07569],
              [8.20294, 50.07573],
              [8.20326, 50.07574],
              [8.2037, 50.07579],
              [8.204227, 50.075836],
              [8.20769, 50.07623],
              [8.20778, 50.07614],
              [8.20833, 50.0755],
              [8.20843, 50.07542],
              [8.20949, 50.07461],
              [8.21053, 50.07373],
              [8.210913, 50.073426],
              [8.21126, 50.07315],
              [8.21162, 50.07262],
              [8.2124, 50.07153],
              [8.21283, 50.07123],
              [8.21402, 50.0707],
              [8.21402, 50.0707],
              [8.21464, 50.07105],
              [8.21785, 50.071985],
              [8.22038, 50.0731],
              [8.22263, 50.07176],
              [8.22355, 50.07126],
              [8.22388, 50.07105],
              [8.22446, 50.07071],
              [8.22501, 50.07041],
              [8.22529, 50.07022],
              [8.22571, 50.06996],
              [8.22609, 50.06969],
              [8.227322, 50.070686],
              [8.228524, 50.069651],
              [8.22885, 50.06939],
              [8.232, 50.06736],
              [8.232187, 50.067155],
              [8.23269, 50.069295],
              [8.23282, 50.06977],
              [8.23301, 50.07028],
              [8.23307, 50.07063],
              [8.23339, 50.07169],
              [8.23344, 50.07182],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24492, 50.07179],
              [8.24493, 50.07231],
              [8.24536, 50.07338],
              [8.245401, 50.073665],
              [8.24542, 50.0738],
              [8.24563, 50.07517],
              [8.2455, 50.0768],
              [8.24537, 50.07691],
              [8.245312, 50.077414],
              [8.24525, 50.07796],
              [8.24521, 50.07813],
              [8.24507, 50.07936],
              [8.24493, 50.08066],
              [8.244894, 50.081014],
              [8.24486, 50.08134],
              [8.2447, 50.0827],
              [8.24466, 50.08299],
              [8.24463, 50.08332],
              [8.24461, 50.08374],
              [8.24453, 50.0843],
              [8.24452, 50.08436],
              [8.24412, 50.0844],
              [8.24402, 50.08463],
              [8.24408, 50.08475],
              [8.24446, 50.08483],
              [8.24444, 50.08499],
              [8.24439, 50.08537],
              [8.24437, 50.0857],
              [8.24439, 50.08601],
              [8.24526, 50.08592],
              [8.24665, 50.08571],
              [8.24693, 50.08567],
              [8.24809, 50.08553],
              [8.25151, 50.08524],
              [8.25172, 50.08525],
              [8.252203, 50.085318],
              [8.25293, 50.08542],
              [8.25322, 50.08547],
              [8.25541, 50.08593],
              [8.25609, 50.0862],
              [8.25677, 50.08674],
              [8.25715, 50.08771],
              [8.2572, 50.08787],
              [8.25749, 50.08891],
              [8.25757, 50.08942],
              [8.257247, 50.090718],
              [8.25709, 50.09135],
              [8.25702, 50.09211],
              [8.25714, 50.09315],
              [8.25723, 50.09354],
              [8.25743, 50.09431],
              [8.25754, 50.09447],
              [8.25916, 50.09604],
              [8.25971, 50.09657],
              [8.26034, 50.09719],
              [8.26133, 50.09826],
              [8.26227, 50.09874],
              [8.26334, 50.09927],
              [8.26408, 50.09971],
              [8.26416, 50.09982],
              [8.26432, 50.10045],
              [8.26442, 50.10102],
              [8.26423, 50.10168],
              [8.26416, 50.10183],
              [8.26417, 50.10217],
              [8.26421, 50.10242],
              [8.26409, 50.10295],
              [8.26437, 50.10329],
              [8.2648, 50.10341],
              [8.26543, 50.10379],
              [8.26583, 50.10399],
              [8.26628, 50.10422],
              [8.266728, 50.1045],
              [8.26705, 50.1047],
              [8.26856, 50.10515],
              [8.26897, 50.10547],
              [8.26903, 50.10594],
              [8.26867, 50.10645],
              [8.268428, 50.107129],
              [8.26831, 50.10746],
              [8.26818, 50.10781],
              [8.2682, 50.10796],
              [8.26823, 50.10884],
              [8.26826, 50.1089],
              [8.268855, 50.10939],
              [8.26911, 50.1096],
              [8.27212, 50.11215],
              [8.27258, 50.11253],
              [8.27254, 50.11258],
              [8.27239, 50.11276],
              [8.2723, 50.11288],
              [8.2719, 50.11345],
              [8.270361, 50.115466],
              [8.27006, 50.11586],
              [8.26998, 50.11604],
              [8.26999, 50.11634],
              [8.27038, 50.11673],
              [8.27075, 50.11686],
              [8.27185, 50.11688],
              [8.2729, 50.1169],
              [8.273425, 50.11711],
              [8.2736, 50.11718],
              [8.2738, 50.11727],
              [8.27389, 50.11732],
              [8.27469, 50.11819],
              [8.27569, 50.11905],
              [8.27598, 50.11932],
              [8.27607, 50.11952],
              [8.27654, 50.12079],
              [8.27701, 50.12137],
              [8.27718, 50.12152],
              [8.27736, 50.12164],
              [8.27771, 50.12185],
              [8.27803, 50.12219],
              [8.27798, 50.1225],
              [8.27782, 50.12281],
              [8.27789, 50.12331],
              [8.27829, 50.12361],
              [8.27866, 50.12375],
              [8.27949, 50.12395],
              [8.2798, 50.12426],
              [8.27986, 50.12495],
              [8.28033, 50.12538],
              [8.28102, 50.12568],
              [8.28147, 50.12586],
              [8.28278, 50.12631],
              [8.2852, 50.12661],
              [8.28703, 50.12692],
              [8.2879, 50.12733],
              [8.29035, 50.1288],
              [8.2914, 50.12926],
              [8.29185, 50.12943],
              [8.2923, 50.1296],
              [8.29337, 50.13003],
              [8.29405, 50.13021],
              [8.29464, 50.13022],
              [8.29532, 50.13011],
              [8.29545, 50.12996],
              [8.2954, 50.12977],
              [8.29481, 50.12902],
              [8.2948, 50.12883],
              [8.29511, 50.12857],
              [8.29546, 50.12841],
              [8.29659, 50.1278],
              [8.29608, 50.12723],
              [8.29601, 50.12692],
              [8.29599, 50.12663],
              [8.29641, 50.12635],
              [8.29676, 50.12616],
              [8.29703, 50.12601],
              [8.29742, 50.126],
              [8.29762, 50.126],
              [8.29785, 50.12601],
              [8.29861, 50.12605],
              [8.29863, 50.12663],
              [8.29856, 50.12676],
              [8.298, 50.12707],
              [8.297449, 50.127355],
              [8.29659, 50.1278],
              [8.29546, 50.12841],
              [8.29511, 50.12857],
              [8.2948, 50.12883],
              [8.29481, 50.12902],
              [8.2954, 50.12977],
              [8.29545, 50.12996],
              [8.29532, 50.13011],
              [8.29464, 50.13022],
              [8.29405, 50.13021],
              [8.29383, 50.13088],
              [8.29374, 50.13106],
              [8.29286, 50.13197],
              [8.2928, 50.1323],
              [8.29304, 50.13258],
              [8.29407, 50.13281],
              [8.29449, 50.13278],
              [8.29513, 50.13242],
              [8.29581, 50.13241],
              [8.29646, 50.13255],
              [8.29655, 50.13257],
              [8.2976, 50.13295],
              [8.297809, 50.133072],
              [8.29888, 50.1337],
              [8.29899, 50.13375],
              [8.30077, 50.13482],
              [8.30122, 50.13505],
              [8.3015, 50.13521],
              [8.30164, 50.13534],
              [8.30201, 50.13567],
              [8.30238, 50.13596],
              [8.30244, 50.13601],
              [8.30368, 50.137],
              [8.30458, 50.13784],
              [8.30468, 50.13838],
              [8.30471, 50.13859],
              [8.30483, 50.13916],
              [8.30508, 50.13954],
              [8.30568, 50.13978],
              [8.30739, 50.13988],
              [8.30724, 50.14015],
              [8.30632, 50.14184],
              [8.30623, 50.14233],
              [8.3062, 50.14255],
              [8.30618, 50.14283],
              [8.30634, 50.14346],
              [8.30711, 50.14424],
              [8.30725, 50.14433],
              [8.30818, 50.14474],
              [8.30926, 50.14521],
              [8.30956, 50.14561],
              [8.30995, 50.14651],
              [8.31017, 50.14703],
              [8.31035, 50.14745],
              [8.31047, 50.14772],
              [8.31077, 50.14842],
              [8.31082, 50.14855],
              [8.31084, 50.14866],
              [8.31083, 50.14927],
              [8.31078, 50.14943],
              [8.31043, 50.14992],
              [8.30953, 50.15082],
              [8.30949, 50.15124],
              [8.30993, 50.15173],
              [8.31019, 50.15186],
              [8.31052, 50.15201],
              [8.31075, 50.15209],
              [8.31091, 50.15214],
              [8.3114, 50.15231],
              [8.31216, 50.1526],
              [8.31304, 50.15298],
              [8.31359, 50.15341],
              [8.31403, 50.15422],
              [8.31362, 50.15431],
              [8.31177, 50.15539],
              [8.31119, 50.15583],
              [8.30955, 50.15702],
              [8.31009, 50.15773],
              [8.31018, 50.1582],
              [8.30994, 50.158529],
              [8.30959, 50.15901],
              [8.30936, 50.15917],
              [8.30796, 50.16017],
              [8.30645, 50.16106],
              [8.30617, 50.16122],
              [8.30622, 50.1613],
              [8.30668, 50.16141],
              [8.30716, 50.16157],
              [8.30722, 50.16168],
              [8.30683, 50.16207],
              [8.30737, 50.1624],
              [8.30772, 50.16243],
              [8.30821, 50.16218],
              [8.30891, 50.16194],
              [8.30902, 50.16187],
              [8.30914, 50.16175],
              [8.30941, 50.16176],
              [8.31161, 50.16086],
              [8.3124, 50.1605],
              [8.31298, 50.16096],
              [8.3133, 50.16084],
              [8.31355, 50.16102],
              [8.31396, 50.16151],
              [8.31508, 50.16249],
              [8.312828, 50.163967],
              [8.31226, 50.16434],
              [8.3126, 50.16462],
              [8.31327, 50.1652],
              [8.31357, 50.16547],
              [8.31396, 50.16655],
              [8.31422, 50.16734],
              [8.313707, 50.167426],
              [8.31303, 50.16754],
              [8.31259, 50.16763],
              [8.31182, 50.16779],
              [8.30985, 50.1686],
              [8.30934, 50.16901],
              [8.30923, 50.16923],
              [8.309153, 50.16969],
              [8.30915, 50.16971],
              [8.30938, 50.17015],
              [8.30989, 50.17051],
              [8.3114, 50.17109],
              [8.31168, 50.17088],
              [8.31251, 50.17027],
              [8.31314, 50.16997],
              [8.31421, 50.16973],
              [8.31444, 50.16969],
              [8.31521, 50.16955],
              [8.31596, 50.16928],
              [8.31657, 50.16886],
              [8.31697, 50.16815],
              [8.31697, 50.168145],
              [8.31705, 50.16728],
              [8.31709, 50.16685],
              [8.31714, 50.16632],
              [8.31719, 50.16552],
              [8.31726, 50.16458],
              [8.31727, 50.16381],
              [8.31728, 50.16235],
              [8.317703, 50.162029],
              [8.31811, 50.16172],
              [8.31765, 50.16136],
              [8.31849, 50.161],
              [8.31936, 50.16046],
              [8.32011, 50.15968],
              [8.32086, 50.15886],
              [8.32147, 50.15818],
              [8.322659, 50.158595],
              [8.32293, 50.15869],
              [8.3233, 50.15872],
              [8.32347, 50.1587],
              [8.32366, 50.16007],
              [8.32324, 50.16065],
              [8.32277, 50.1618],
              [8.32286, 50.16193],
              [8.32306, 50.16196],
              [8.32455, 50.16118],
              [8.32529, 50.16076],
              [8.3255, 50.16072],
              [8.3257, 50.16079],
              [8.32584, 50.16157],
              [8.32595, 50.16226],
              [8.32603, 50.16275],
              [8.32612, 50.16332],
              [8.32556, 50.16389],
              [8.32548, 50.16414],
              [8.325555, 50.164325],
              [8.32572, 50.16473],
              [8.32629, 50.16557],
              [8.32654, 50.16605],
              [8.32647, 50.16621],
              [8.32614, 50.16646],
              [8.32651, 50.16663],
              [8.32774, 50.16697],
              [8.32838, 50.16707],
              [8.32942, 50.16724],
              [8.3301, 50.16745],
              [8.33075, 50.16766],
              [8.33143, 50.16785],
              [8.332368, 50.168144],
              [8.333119, 50.168508],
              [8.33358, 50.16892],
              [8.334482, 50.169601],
              [8.33498, 50.1682],
              [8.33546, 50.16829],
              [8.33728, 50.16896],
              [8.336779, 50.169795],
            ],
          ],
        },
        id: "YwNTE",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "UyMTY",
    name: "11 Schierstein <> Nerotal",
    lineName: "11",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.22643, 50.09518],
              [8.22803, 50.09487],
              [8.22939, 50.09478],
              [8.23129, 50.09486],
              [8.231561, 50.094791],
              [8.23238, 50.09458],
              [8.234451, 50.093375],
              [8.23494, 50.09309],
              [8.23547, 50.09224],
              [8.23625, 50.09122],
              [8.23651, 50.09095],
              [8.23709, 50.09057],
              [8.23835, 50.08977],
              [8.238921, 50.089397],
              [8.23936, 50.08911],
              [8.24103, 50.08805],
              [8.24228, 50.08728],
              [8.24334, 50.08656],
              [8.24243, 50.08605],
              [8.24198, 50.08577],
              [8.24145, 50.08545],
              [8.24095, 50.08511],
              [8.24182, 50.08476],
              [8.24198, 50.08471],
              [8.24261, 50.08446],
              [8.24277, 50.08414],
              [8.24282, 50.08385],
              [8.24303, 50.0837],
              [8.24461, 50.08374],
              [8.24463, 50.08332],
              [8.24466, 50.08299],
              [8.2447, 50.0827],
              [8.24486, 50.08134],
              [8.244894, 50.081014],
              [8.24493, 50.08066],
              [8.24576, 50.08049],
              [8.24687, 50.07991],
              [8.24755, 50.07937],
              [8.24768, 50.07928],
              [8.248549, 50.07863],
              [8.24899, 50.0783],
              [8.24915, 50.07819],
              [8.24944, 50.07797],
              [8.24968, 50.07779],
              [8.25015, 50.07744],
              [8.25152, 50.07643],
              [8.25176, 50.07625],
              [8.25278, 50.07548],
              [8.25318, 50.0752],
              [8.25374, 50.07475],
              [8.2541, 50.07439],
              [8.25505, 50.07369],
              [8.25569, 50.07325],
              [8.25606, 50.07299],
              [8.25671, 50.07253],
              [8.25699, 50.07233],
              [8.25671, 50.07253],
              [8.25641, 50.07248],
              [8.25518, 50.07238],
              [8.2547, 50.07235],
              [8.2531, 50.07223],
              [8.25181, 50.07215],
              [8.25111, 50.07212],
              [8.25036, 50.07207],
              [8.24916, 50.07199],
              [8.24887, 50.07198],
              [8.24834, 50.07196],
              [8.24819, 50.07195],
              [8.24803, 50.07194],
              [8.24745, 50.07192],
              [8.2459, 50.07184],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23143, 50.07437],
              [8.23051, 50.07357],
              [8.23, 50.0731],
              [8.22984, 50.07296],
              [8.22977, 50.07289],
              [8.22913, 50.07232],
              [8.22823, 50.07151],
              [8.22797, 50.07128],
              [8.22732, 50.07069],
              [8.22609, 50.06969],
              [8.2248, 50.06876],
              [8.22359, 50.0679],
              [8.223309, 50.067704],
              [8.22279, 50.06734],
              [8.22291, 50.06727],
              [8.22385, 50.06666],
              [8.22418, 50.06642],
              [8.22543, 50.06559],
              [8.22628, 50.06504],
              [8.22639, 50.06497],
              [8.22911, 50.06337],
              [8.23001, 50.06288],
              [8.23065, 50.06249],
              [8.23204, 50.06169],
              [8.232618, 50.061368],
              [8.23523, 50.0599],
              [8.23591, 50.05977],
              [8.23609, 50.05974],
              [8.23794, 50.05856],
              [8.23871, 50.05806],
              [8.23953, 50.057563],
              [8.23993, 50.05732],
              [8.24088, 50.05676],
              [8.24151, 50.05679],
              [8.24219, 50.05671],
              [8.24338, 50.05663],
              [8.24342, 50.05647],
              [8.24364, 50.05555],
              [8.24365, 50.05536],
              [8.24365, 50.0552],
              [8.24361, 50.05504],
              [8.24355, 50.05487],
              [8.24344, 50.05459],
              [8.2434, 50.05448],
              [8.24324, 50.05407],
              [8.2432, 50.05378],
              [8.243, 50.05324],
              [8.24297, 50.05316],
              [8.24284, 50.05285],
              [8.24268, 50.05244],
              [8.24251, 50.05202],
              [8.24248, 50.05195],
              [8.24238, 50.05169],
              [8.24235, 50.05159],
              [8.24216, 50.05121],
              [8.241973, 50.050675],
              [8.24179, 50.05015],
              [8.24146, 50.04929],
              [8.24131, 50.04897],
              [8.24126, 50.04884],
              [8.2411, 50.04823],
              [8.24109, 50.04783],
              [8.24095, 50.04785],
              [8.23954, 50.04801],
              [8.23907, 50.04804],
              [8.23867, 50.04807],
              [8.23801, 50.04807],
              [8.23752, 50.04808],
              [8.23748, 50.04808],
              [8.23436, 50.047837],
              [8.23121, 50.04728],
              [8.23026, 50.0471],
              [8.230095, 50.047529],
              [8.22999, 50.0478],
              [8.22991, 50.04842],
              [8.22753, 50.04858],
              [8.22339, 50.04888],
              [8.22139, 50.04894],
              [8.219971, 50.048853],
              [8.220218, 50.04734],
              [8.220429, 50.046125],
              [8.22056, 50.04537],
              [8.21727, 50.044763],
              [8.21704, 50.04472],
              [8.21663, 50.04465],
              [8.21616, 50.04456],
              [8.214199, 50.044255],
              [8.21365, 50.04416],
              [8.213409, 50.044122],
              [8.21324, 50.0441],
              [8.21307, 50.04407],
              [8.21268, 50.04401],
              [8.21151, 50.04384],
              [8.21072, 50.0437],
              [8.21046, 50.04367],
              [8.21004, 50.04334],
              [8.21003, 50.04317],
              [8.21004, 50.04302],
              [8.21018, 50.04276],
              [8.21257, 50.04221],
              [8.21293, 50.04213],
              [8.21347, 50.042],
            ],
          ],
        },
        id: "UyMTY",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.21347, 50.042],
              [8.21293, 50.04213],
              [8.21257, 50.04221],
              [8.21018, 50.04276],
              [8.21004, 50.04302],
              [8.21003, 50.04317],
              [8.21004, 50.04334],
              [8.21046, 50.04367],
              [8.21072, 50.0437],
              [8.21151, 50.04384],
              [8.21268, 50.04401],
              [8.21307, 50.04407],
              [8.21324, 50.0441],
              [8.213409, 50.044122],
              [8.21365, 50.04416],
              [8.214199, 50.044255],
              [8.21616, 50.04456],
              [8.21663, 50.04465],
              [8.21704, 50.04472],
              [8.21727, 50.044763],
              [8.22056, 50.04537],
              [8.220429, 50.046125],
              [8.220218, 50.04734],
              [8.219971, 50.048853],
              [8.22139, 50.04894],
              [8.22339, 50.04888],
              [8.22753, 50.04858],
              [8.22991, 50.04842],
              [8.22999, 50.0478],
              [8.230095, 50.047529],
              [8.23026, 50.0471],
              [8.23121, 50.04728],
              [8.23436, 50.047837],
              [8.23748, 50.04808],
              [8.23752, 50.04808],
              [8.23801, 50.04807],
              [8.23867, 50.04807],
              [8.23907, 50.04804],
              [8.23954, 50.04801],
              [8.24095, 50.04785],
              [8.24109, 50.04783],
              [8.2411, 50.04823],
              [8.24126, 50.04884],
              [8.24131, 50.04897],
              [8.24146, 50.04929],
              [8.24179, 50.05015],
              [8.241973, 50.050675],
              [8.24216, 50.05121],
              [8.24235, 50.05159],
              [8.24238, 50.05169],
              [8.24248, 50.05195],
              [8.24251, 50.05202],
              [8.24268, 50.05244],
              [8.24284, 50.05285],
              [8.24297, 50.05316],
              [8.243, 50.05324],
              [8.2432, 50.05378],
              [8.24324, 50.05407],
              [8.2434, 50.05448],
              [8.24344, 50.05459],
              [8.24355, 50.05487],
              [8.24361, 50.05504],
              [8.24365, 50.0552],
              [8.24365, 50.05536],
              [8.24364, 50.05555],
              [8.24342, 50.05647],
              [8.24338, 50.05663],
              [8.24219, 50.05671],
              [8.24151, 50.05679],
              [8.24088, 50.05676],
              [8.23993, 50.05732],
              [8.23953, 50.057563],
              [8.23871, 50.05806],
              [8.23794, 50.05856],
              [8.23609, 50.05974],
              [8.23591, 50.05977],
              [8.23523, 50.0599],
              [8.232618, 50.061368],
              [8.23204, 50.06169],
              [8.23065, 50.06249],
              [8.23001, 50.06288],
              [8.22911, 50.06337],
              [8.22639, 50.06497],
              [8.22628, 50.06504],
              [8.22543, 50.06559],
              [8.22418, 50.06642],
              [8.22385, 50.06666],
              [8.22291, 50.06727],
              [8.22369, 50.06782],
              [8.22492, 50.06868],
              [8.22618, 50.06961],
              [8.22676, 50.07005],
              [8.22741, 50.07061],
              [8.22833, 50.07146],
              [8.22921, 50.07227],
              [8.22984, 50.07283],
              [8.22994, 50.07291],
              [8.2301, 50.07306],
              [8.23071, 50.07357],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.24825, 50.0718],
              [8.24838, 50.07181],
              [8.24889, 50.07184],
              [8.24917, 50.07186],
              [8.25038, 50.0719],
              [8.25112, 50.07194],
              [8.25181, 50.07198],
              [8.25311, 50.07204],
              [8.25403, 50.07209],
              [8.25487, 50.07212],
              [8.25494, 50.07212],
              [8.25533, 50.07217],
              [8.25677, 50.07227],
              [8.25699, 50.07233],
              [8.25693, 50.07258],
              [8.25587, 50.07334],
              [8.25514, 50.07374],
              [8.25419, 50.07444],
              [8.25374, 50.07475],
              [8.25318, 50.0752],
              [8.25278, 50.07548],
              [8.25176, 50.07625],
              [8.25152, 50.07643],
              [8.25015, 50.07744],
              [8.24968, 50.07779],
              [8.24944, 50.07797],
              [8.24915, 50.07819],
              [8.24899, 50.0783],
              [8.248549, 50.07863],
              [8.24768, 50.07928],
              [8.24755, 50.07937],
              [8.24687, 50.07991],
              [8.24576, 50.08049],
              [8.24493, 50.08066],
              [8.244894, 50.081014],
              [8.24486, 50.08134],
              [8.2447, 50.0827],
              [8.24466, 50.08299],
              [8.24463, 50.08332],
              [8.24461, 50.08374],
              [8.24303, 50.0837],
              [8.24282, 50.08385],
              [8.24277, 50.08414],
              [8.24261, 50.08446],
              [8.24198, 50.08471],
              [8.24182, 50.08476],
              [8.24095, 50.08511],
              [8.24145, 50.08545],
              [8.24198, 50.08577],
              [8.24243, 50.08605],
              [8.24334, 50.08656],
              [8.24228, 50.08728],
              [8.24103, 50.08805],
              [8.23936, 50.08911],
              [8.238921, 50.089397],
              [8.23835, 50.08977],
              [8.23709, 50.09057],
              [8.23651, 50.09095],
              [8.23625, 50.09122],
              [8.23547, 50.09224],
              [8.23494, 50.09309],
              [8.234451, 50.093375],
              [8.23238, 50.09458],
              [8.231561, 50.094791],
              [8.23129, 50.09486],
              [8.22939, 50.09478],
              [8.22803, 50.09487],
              [8.22643, 50.09518],
            ],
          ],
        },
        id: "Q5ODk",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "I4NTI",
    name: "21 Dotzheim <> Eigenheim",
    lineName: "21",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.24956, 50.09831],
              [8.24743, 50.0983],
              [8.24731, 50.09719],
              [8.2469, 50.09601],
              [8.24822, 50.09571],
              [8.24942, 50.09525],
              [8.24964, 50.09469],
              [8.24979, 50.0945],
              [8.250022, 50.09446],
              [8.25054, 50.09437],
              [8.25162, 50.09418],
              [8.25328, 50.09374],
              [8.2546, 50.0934],
              [8.25482, 50.09315],
              [8.2548, 50.09127],
              [8.25496, 50.09064],
              [8.254502, 50.090692],
              [8.25408, 50.09074],
              [8.25337, 50.09068],
              [8.25011, 50.09062],
              [8.249435, 50.090639],
              [8.24833, 50.09067],
              [8.24827, 50.09067],
              [8.2466, 50.0908],
              [8.24581, 50.09075],
              [8.24495, 50.09093],
              [8.24517, 50.09],
              [8.24441, 50.08987],
              [8.24427, 50.08962],
              [8.24403, 50.08908],
              [8.24389, 50.08833],
              [8.24306, 50.08778],
              [8.24228, 50.08728],
              [8.24334, 50.08656],
              [8.24243, 50.08605],
              [8.24198, 50.08577],
              [8.24145, 50.08545],
              [8.24095, 50.08511],
              [8.24182, 50.08476],
              [8.24198, 50.08471],
              [8.24261, 50.08446],
              [8.24277, 50.08414],
              [8.24282, 50.08385],
              [8.24303, 50.0837],
              [8.24461, 50.08374],
              [8.24463, 50.08332],
              [8.24466, 50.08299],
              [8.2447, 50.0827],
              [8.24486, 50.08134],
              [8.244894, 50.081014],
              [8.24493, 50.08066],
              [8.24576, 50.08049],
              [8.24687, 50.07991],
              [8.24755, 50.07937],
              [8.24768, 50.07928],
              [8.248549, 50.07863],
              [8.24899, 50.0783],
              [8.24915, 50.07819],
              [8.24944, 50.07797],
              [8.24968, 50.07779],
              [8.25015, 50.07744],
              [8.25152, 50.07643],
              [8.25176, 50.07625],
              [8.25278, 50.07548],
              [8.25318, 50.0752],
              [8.25374, 50.07475],
              [8.2541, 50.07439],
              [8.25505, 50.07369],
              [8.25569, 50.07325],
              [8.25606, 50.07299],
              [8.25671, 50.07253],
              [8.25641, 50.07248],
              [8.25518, 50.07238],
              [8.2547, 50.07235],
              [8.2531, 50.07223],
              [8.25181, 50.07215],
              [8.25111, 50.07212],
              [8.25036, 50.07207],
              [8.24916, 50.07199],
              [8.24887, 50.07198],
              [8.24834, 50.07196],
              [8.24819, 50.07195],
              [8.24803, 50.07194],
              [8.24745, 50.07192],
              [8.2459, 50.07184],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23143, 50.07437],
              [8.23051, 50.07357],
              [8.23, 50.0731],
              [8.22984, 50.07296],
              [8.22977, 50.07289],
              [8.22913, 50.07232],
              [8.22823, 50.07151],
              [8.22797, 50.07128],
              [8.22732, 50.07069],
              [8.22609, 50.06969],
              [8.223309, 50.067704],
              [8.2211, 50.06611],
              [8.22093, 50.06596],
              [8.22074, 50.0658],
              [8.2205, 50.06562],
              [8.21961, 50.06501],
              [8.21893, 50.0645],
              [8.21802, 50.06383],
              [8.21742, 50.06338],
              [8.21711, 50.06314],
              [8.21684, 50.06292],
              [8.21649, 50.06275],
              [8.21617, 50.06273],
              [8.21588, 50.06285],
              [8.21575, 50.06292],
              [8.21486, 50.06342],
              [8.21356, 50.06248],
              [8.2127, 50.06186],
              [8.212289, 50.061782],
              [8.21207, 50.06174],
              [8.21168, 50.06145],
              [8.21135, 50.06118],
              [8.21099, 50.06115],
              [8.209417, 50.062145],
              [8.20721, 50.06354],
              [8.20535, 50.06437],
              [8.20416, 50.0632],
              [8.2029, 50.06368],
              [8.20257, 50.06366],
              [8.20073, 50.06346],
              [8.19914, 50.06349],
              [8.19818, 50.06351],
              [8.196875, 50.06351],
              [8.19641, 50.06351],
              [8.19585, 50.0635],
              [8.19249, 50.06306],
              [8.19036, 50.0625],
              [8.18936, 50.06202],
              [8.18802, 50.06133],
              [8.18702, 50.06212],
              [8.18635, 50.06264],
              [8.18583, 50.06304],
              [8.18512, 50.06363],
              [8.18415, 50.06457],
              [8.18384, 50.06487],
              [8.18378, 50.06494],
              [8.18154, 50.06723],
              [8.18156, 50.06749],
              [8.18163, 50.06762],
              [8.182356, 50.06749],
              [8.1833, 50.06732],
              [8.18517, 50.06713],
              [8.187924, 50.067341],
              [8.18882, 50.06741],
              [8.18923, 50.06744],
              [8.18976, 50.06749],
              [8.19142, 50.06766],
              [8.19169, 50.06634],
              [8.190875, 50.066287],
            ],
          ],
        },
        id: "I4NTI",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.190875, 50.066287],
              [8.18999, 50.06623],
              [8.18976, 50.06749],
              [8.18923, 50.06744],
              [8.18882, 50.06741],
              [8.187924, 50.067341],
              [8.18517, 50.06713],
              [8.1833, 50.06732],
              [8.182356, 50.06749],
              [8.18163, 50.06762],
              [8.18156, 50.06749],
              [8.18154, 50.06723],
              [8.18378, 50.06494],
              [8.18384, 50.06487],
              [8.18415, 50.06457],
              [8.18512, 50.06363],
              [8.18583, 50.06304],
              [8.18635, 50.06264],
              [8.18702, 50.06212],
              [8.18802, 50.06133],
              [8.18936, 50.06202],
              [8.19036, 50.0625],
              [8.19249, 50.06306],
              [8.19585, 50.0635],
              [8.19641, 50.06351],
              [8.196875, 50.06351],
              [8.19818, 50.06351],
              [8.19914, 50.06349],
              [8.20073, 50.06346],
              [8.20257, 50.06366],
              [8.2029, 50.06368],
              [8.20416, 50.0632],
              [8.20535, 50.06437],
              [8.20721, 50.06354],
              [8.209417, 50.062145],
              [8.21099, 50.06115],
              [8.21135, 50.06118],
              [8.21168, 50.06145],
              [8.21207, 50.06174],
              [8.212289, 50.061782],
              [8.2127, 50.06186],
              [8.21356, 50.06248],
              [8.21486, 50.06342],
              [8.21575, 50.06292],
              [8.21588, 50.06285],
              [8.21617, 50.06273],
              [8.21649, 50.06275],
              [8.21684, 50.06292],
              [8.21711, 50.06314],
              [8.2178, 50.06282],
              [8.21794, 50.06294],
              [8.21998, 50.0649],
              [8.22048, 50.06548],
              [8.221, 50.06593],
              [8.221162, 50.066064],
              [8.22291, 50.06727],
              [8.22369, 50.06782],
              [8.22618, 50.06961],
              [8.22676, 50.07005],
              [8.22741, 50.07061],
              [8.22833, 50.07146],
              [8.22921, 50.07227],
              [8.22984, 50.07283],
              [8.22994, 50.07291],
              [8.2301, 50.07306],
              [8.23071, 50.07357],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.24825, 50.0718],
              [8.24838, 50.07181],
              [8.24889, 50.07184],
              [8.24917, 50.07186],
              [8.25038, 50.0719],
              [8.25112, 50.07194],
              [8.25181, 50.07198],
              [8.25311, 50.07204],
              [8.25403, 50.07209],
              [8.25487, 50.07212],
              [8.25494, 50.07212],
              [8.25533, 50.07217],
              [8.25677, 50.07227],
              [8.25699, 50.07233],
              [8.25693, 50.07258],
              [8.25587, 50.07334],
              [8.25514, 50.07374],
              [8.25419, 50.07444],
              [8.25374, 50.07475],
              [8.25318, 50.0752],
              [8.25278, 50.07548],
              [8.25176, 50.07625],
              [8.25152, 50.07643],
              [8.25015, 50.07744],
              [8.24968, 50.07779],
              [8.24944, 50.07797],
              [8.24915, 50.07819],
              [8.24899, 50.0783],
              [8.248549, 50.07863],
              [8.24768, 50.07928],
              [8.24755, 50.07937],
              [8.24687, 50.07991],
              [8.24576, 50.08049],
              [8.24493, 50.08066],
              [8.244894, 50.081014],
              [8.24486, 50.08134],
              [8.2447, 50.0827],
              [8.24466, 50.08299],
              [8.24463, 50.08332],
              [8.24461, 50.08374],
              [8.24303, 50.0837],
              [8.24282, 50.08385],
              [8.24277, 50.08414],
              [8.24261, 50.08446],
              [8.24198, 50.08471],
              [8.24182, 50.08476],
              [8.24095, 50.08511],
              [8.24145, 50.08545],
              [8.24198, 50.08577],
              [8.24243, 50.08605],
              [8.24334, 50.08656],
              [8.24228, 50.08728],
              [8.24306, 50.08778],
              [8.24389, 50.08833],
              [8.24403, 50.08908],
              [8.24427, 50.08962],
              [8.24441, 50.08987],
              [8.24517, 50.09],
              [8.24495, 50.09093],
              [8.24581, 50.09075],
              [8.2466, 50.0908],
              [8.24827, 50.09067],
              [8.24833, 50.09067],
              [8.249435, 50.090639],
              [8.25011, 50.09062],
              [8.25337, 50.09068],
              [8.25408, 50.09074],
              [8.254502, 50.090692],
              [8.25496, 50.09064],
              [8.2548, 50.09127],
              [8.25482, 50.09315],
              [8.2546, 50.0934],
              [8.25328, 50.09374],
              [8.25162, 50.09418],
              [8.25054, 50.09437],
              [8.250022, 50.09446],
              [8.24979, 50.0945],
              [8.24964, 50.09469],
              [8.24942, 50.09525],
              [8.24822, 50.09571],
              [8.2469, 50.09601],
              [8.24731, 50.09719],
              [8.24743, 50.0983],
              [8.24816, 50.09831],
              [8.24865, 50.09755],
              [8.249174, 50.097661],
              [8.24974, 50.09778],
              [8.24956, 50.09831],
            ],
          ],
        },
        id: "I1ODA",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "cxNDY",
    name: "10 Mainz-Kostheim <> Schierstein",
    lineName: "10",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.194751, 50.047338],
              [8.19402, 50.04725],
              [8.19366, 50.04709],
              [8.19387, 50.04673],
              [8.19391, 50.04666],
              [8.19456, 50.04562],
              [8.19504, 50.04508],
              [8.19513, 50.04504],
              [8.19537, 50.04497],
              [8.19566, 50.04488],
              [8.19611, 50.04466],
              [8.19626, 50.04461],
              [8.19703, 50.04454],
              [8.19717, 50.04454],
              [8.19766, 50.04455],
              [8.19796, 50.04455],
              [8.19938, 50.0446],
              [8.20059, 50.04463],
              [8.20151, 50.04462],
              [8.20189, 50.04456],
              [8.2027, 50.0444],
              [8.20368, 50.04418],
              [8.20476, 50.04394],
              [8.208261, 50.043171],
              [8.20931, 50.04294],
              [8.20986, 50.04283],
              [8.21018, 50.04276],
              [8.21257, 50.04221],
              [8.21293, 50.04213],
              [8.21347, 50.042],
              [8.216713, 50.041284],
              [8.21983, 50.04058],
              [8.22118, 50.04029],
              [8.22136, 50.04025],
              [8.221736, 50.040166],
              [8.225601, 50.039314],
              [8.2263, 50.03916],
              [8.2282, 50.03868],
              [8.23087, 50.03766],
              [8.23113, 50.0376],
              [8.23124, 50.03758],
              [8.23146, 50.03754],
              [8.23292, 50.0373],
              [8.23501, 50.03695],
              [8.23523, 50.03691],
              [8.23645, 50.03653],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23862, 50.03686],
              [8.23911, 50.03682],
              [8.23917, 50.03676],
              [8.23911, 50.03682],
              [8.23916, 50.0369],
              [8.23984, 50.03824],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.24341, 50.04075],
              [8.246008, 50.041759],
              [8.24753, 50.04234],
              [8.24777, 50.04243],
              [8.248034, 50.042422],
              [8.248347, 50.042638],
              [8.249344, 50.043011],
              [8.250522, 50.043281],
              [8.25133, 50.04354],
              [8.25151, 50.0435],
              [8.25551, 50.04074],
              [8.25603, 50.04037],
              [8.25657, 50.03983],
              [8.2572, 50.03896],
              [8.25775, 50.03816],
              [8.2582, 50.0375],
              [8.25886, 50.03653],
              [8.25916, 50.03609],
              [8.25961, 50.03542],
              [8.26022, 50.03453],
              [8.26067, 50.03387],
              [8.26101, 50.03338],
              [8.26239, 50.03135],
              [8.26247, 50.03124],
              [8.26353, 50.0297],
              [8.26444, 50.02836],
              [8.26456, 50.02819],
              [8.26467, 50.02804],
              [8.26525, 50.02731],
              [8.26533, 50.02721],
              [8.26548, 50.02702],
              [8.26591, 50.02651],
              [8.26611, 50.02627],
              [8.26643, 50.02593],
              [8.26691, 50.02538],
              [8.268, 50.02409],
              [8.26897, 50.02296],
              [8.26906, 50.02285],
              [8.2697, 50.02218],
              [8.27006, 50.02183],
              [8.270869, 50.021054],
              [8.27156, 50.02039],
              [8.27218, 50.0198],
              [8.2751, 50.01701],
              [8.2762, 50.01592],
              [8.27713, 50.01491],
              [8.27799, 50.01395],
              [8.27815, 50.01377],
              [8.27918, 50.01261],
              [8.27929, 50.01231],
              [8.279218, 50.011832],
              [8.27917, 50.01151],
              [8.27917, 50.01113],
              [8.27926, 50.0106],
              [8.28001, 50.00913],
              [8.28024, 50.00897],
              [8.2806, 50.00894],
              [8.2807, 50.00896],
              [8.28097, 50.00894],
              [8.28126, 50.00877],
              [8.28133, 50.00867],
              [8.28156, 50.00843],
              [8.28191, 50.00812],
              [8.28225, 50.00787],
              [8.28278, 50.00758],
              [8.28303, 50.00744],
              [8.2835, 50.00718],
              [8.28418, 50.00678],
              [8.28503, 50.00635],
              [8.28532, 50.00628],
              [8.2863, 50.00625],
              [8.28755, 50.00642],
              [8.28831, 50.00659],
              [8.28878, 50.00673],
              [8.291, 50.00752],
              [8.29175, 50.00765],
              [8.29242, 50.00769],
              [8.29265, 50.00769],
              [8.29467, 50.00769],
              [8.29491, 50.00769],
              [8.29519, 50.00769],
              [8.29628, 50.00769],
              [8.29657, 50.00769],
              [8.2983, 50.00769],
              [8.29941, 50.00771],
              [8.30027, 50.00772],
              [8.30034, 50.0076],
              [8.3006, 50.00717],
              [8.30086, 50.00678],
              [8.30094, 50.00662],
              [8.30098, 50.00653],
              [8.30115, 50.0061],
              [8.30123, 50.00594],
              [8.30153, 50.00525],
              [8.3016, 50.00501],
              [8.30167, 50.00444],
              [8.30161, 50.00385],
              [8.30125, 50.00303],
              [8.30107, 50.00275],
              [8.3006, 50.00198],
              [8.30042, 50.00167],
              [8.30019, 50.00134],
              [8.30103, 50.00108],
              [8.30151, 50.00095],
              [8.30294, 50.00049],
              [8.30329, 50.0004],
              [8.30381, 50.00026],
              [8.30465, 50.00015],
              [8.30482, 50.00015],
              [8.30562, 50.00016],
              [8.30584, 50.00018],
              [8.30583, 50.00049],
              [8.30582, 50.00087],
              [8.30583, 50.00139],
              [8.30582, 50.0021],
            ],
          ],
        },
        id: "cxNDY",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.30582, 50.0021],
              [8.30583, 50.00247],
              [8.30582, 50.00279],
              [8.30582, 50.00348],
              [8.30474, 50.00348],
              [8.30371, 50.00345],
              [8.30263, 50.00343],
              [8.30235, 50.0028],
              [8.30125, 50.00303],
              [8.30161, 50.00385],
              [8.30167, 50.00444],
              [8.3016, 50.00501],
              [8.30153, 50.00525],
              [8.30123, 50.00594],
              [8.30115, 50.0061],
              [8.30098, 50.00653],
              [8.30094, 50.00662],
              [8.30086, 50.00678],
              [8.3006, 50.00717],
              [8.30034, 50.0076],
              [8.30027, 50.00772],
              [8.29941, 50.00771],
              [8.2983, 50.00769],
              [8.29657, 50.00769],
              [8.29628, 50.00769],
              [8.29519, 50.00769],
              [8.29491, 50.00769],
              [8.29467, 50.00769],
              [8.29265, 50.00769],
              [8.29242, 50.00769],
              [8.29175, 50.00765],
              [8.291, 50.00752],
              [8.28878, 50.00673],
              [8.28831, 50.00659],
              [8.28755, 50.00642],
              [8.2863, 50.00625],
              [8.28532, 50.00628],
              [8.28503, 50.00635],
              [8.28418, 50.00678],
              [8.2835, 50.00718],
              [8.28303, 50.00744],
              [8.28278, 50.00758],
              [8.28225, 50.00787],
              [8.28191, 50.00812],
              [8.28156, 50.00843],
              [8.28133, 50.00867],
              [8.28126, 50.00877],
              [8.28097, 50.00894],
              [8.2807, 50.00896],
              [8.2806, 50.00894],
              [8.28024, 50.00897],
              [8.28001, 50.00913],
              [8.27926, 50.0106],
              [8.27917, 50.01113],
              [8.27926, 50.0106],
              [8.28001, 50.00913],
              [8.27926, 50.0106],
              [8.27917, 50.01113],
              [8.27917, 50.01151],
              [8.279218, 50.011832],
              [8.27929, 50.01231],
              [8.27918, 50.01261],
              [8.27815, 50.01377],
              [8.27799, 50.01395],
              [8.27713, 50.01491],
              [8.2762, 50.01592],
              [8.2751, 50.01701],
              [8.27218, 50.0198],
              [8.27156, 50.02039],
              [8.270869, 50.021054],
              [8.27006, 50.02183],
              [8.2697, 50.02218],
              [8.26906, 50.02285],
              [8.26897, 50.02296],
              [8.268, 50.02409],
              [8.26691, 50.02538],
              [8.26643, 50.02593],
              [8.26611, 50.02627],
              [8.26591, 50.02651],
              [8.26548, 50.02702],
              [8.26533, 50.02721],
              [8.26525, 50.02731],
              [8.26467, 50.02804],
              [8.26456, 50.02819],
              [8.26444, 50.02836],
              [8.26353, 50.0297],
              [8.26247, 50.03124],
              [8.26239, 50.03135],
              [8.26101, 50.03338],
              [8.26067, 50.03387],
              [8.26022, 50.03453],
              [8.25961, 50.03542],
              [8.25916, 50.03609],
              [8.25886, 50.03653],
              [8.2582, 50.0375],
              [8.25775, 50.03816],
              [8.2572, 50.03896],
              [8.25657, 50.03983],
              [8.25593, 50.04032],
              [8.25531, 50.04075],
              [8.25476, 50.04112],
              [8.25248, 50.0427],
              [8.25133, 50.04354],
              [8.250522, 50.043281],
              [8.249344, 50.043011],
              [8.248347, 50.042638],
              [8.248034, 50.042422],
              [8.24777, 50.04243],
              [8.24753, 50.04234],
              [8.246008, 50.041759],
              [8.24341, 50.04075],
              [8.2405, 50.03964],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23684, 50.03639],
              [8.23645, 50.03653],
              [8.23523, 50.03691],
              [8.23501, 50.03695],
              [8.23292, 50.0373],
              [8.23146, 50.03754],
              [8.23124, 50.03758],
              [8.23113, 50.0376],
              [8.23087, 50.03766],
              [8.2282, 50.03868],
              [8.2263, 50.03916],
              [8.2282, 50.03868],
              [8.23087, 50.03766],
              [8.23113, 50.0376],
              [8.23124, 50.03758],
              [8.23113, 50.0376],
              [8.23087, 50.03766],
              [8.2282, 50.03868],
              [8.2263, 50.03916],
              [8.225601, 50.039314],
              [8.221736, 50.040166],
              [8.22136, 50.04025],
              [8.22118, 50.04029],
              [8.21983, 50.04058],
              [8.216713, 50.041284],
              [8.21347, 50.042],
              [8.21293, 50.04213],
              [8.21257, 50.04221],
              [8.21018, 50.04276],
              [8.20986, 50.04283],
              [8.20931, 50.04294],
              [8.208261, 50.043171],
              [8.20476, 50.04394],
              [8.20368, 50.04418],
              [8.2027, 50.0444],
              [8.20189, 50.04456],
              [8.20151, 50.04462],
              [8.20059, 50.04463],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.20083, 50.04642],
              [8.20113, 50.04675],
              [8.20023, 50.04672],
              [8.19912, 50.04657],
              [8.19756, 50.04646],
              [8.19744, 50.04699],
              [8.19734, 50.04765],
              [8.194751, 50.047338],
            ],
          ],
        },
        id: "A1NDg",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.STADT,
    id: "g1ODg",
    name: "9 Mainz_Hbf <> Schierstein",
    lineName: "9",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.194751, 50.047338],
              [8.19402, 50.04725],
              [8.19366, 50.04709],
              [8.19387, 50.04673],
              [8.19391, 50.04666],
              [8.19456, 50.04562],
              [8.19504, 50.04508],
              [8.19513, 50.04504],
              [8.19537, 50.04497],
              [8.19566, 50.04488],
              [8.19611, 50.04466],
              [8.19626, 50.04461],
              [8.19703, 50.04454],
              [8.19717, 50.04454],
              [8.19766, 50.04455],
              [8.19796, 50.04455],
              [8.19938, 50.0446],
              [8.20059, 50.04463],
              [8.20151, 50.04462],
              [8.20189, 50.04456],
              [8.2027, 50.0444],
              [8.20368, 50.04418],
              [8.20476, 50.04394],
              [8.208261, 50.043171],
              [8.20931, 50.04294],
              [8.20986, 50.04283],
              [8.21018, 50.04276],
              [8.21257, 50.04221],
              [8.21293, 50.04213],
              [8.21347, 50.042],
              [8.216713, 50.041284],
              [8.21983, 50.04058],
              [8.22118, 50.04029],
              [8.22136, 50.04025],
              [8.221736, 50.040166],
              [8.225601, 50.039314],
              [8.2263, 50.03916],
              [8.2282, 50.03868],
              [8.23087, 50.03766],
              [8.23113, 50.0376],
              [8.23124, 50.03758],
              [8.23146, 50.03754],
              [8.23292, 50.0373],
              [8.23501, 50.03695],
              [8.23523, 50.03691],
              [8.23645, 50.03653],
              [8.23684, 50.03639],
              [8.23723, 50.036255],
              [8.23788, 50.03603],
              [8.23809, 50.03625],
              [8.23828, 50.036468],
              [8.23862, 50.03686],
              [8.23911, 50.03682],
              [8.23916, 50.0369],
              [8.23984, 50.03824],
              [8.239946, 50.038465],
              [8.2404, 50.03943],
              [8.2405, 50.03964],
              [8.24341, 50.04075],
              [8.246008, 50.041759],
              [8.24753, 50.04234],
              [8.24777, 50.04243],
              [8.248034, 50.042422],
              [8.248347, 50.042638],
              [8.249344, 50.043011],
              [8.250522, 50.043281],
              [8.25133, 50.04354],
              [8.25248, 50.0427],
              [8.25476, 50.04112],
              [8.25531, 50.04075],
              [8.25593, 50.04032],
              [8.25657, 50.03983],
              [8.2572, 50.03896],
              [8.25775, 50.03816],
              [8.2582, 50.0375],
              [8.25886, 50.03653],
              [8.25916, 50.03609],
              [8.25961, 50.03542],
              [8.26022, 50.03453],
              [8.26067, 50.03387],
              [8.26101, 50.03338],
              [8.26239, 50.03135],
              [8.26247, 50.03124],
              [8.26353, 50.0297],
              [8.26444, 50.02836],
              [8.26456, 50.02819],
              [8.26467, 50.02804],
              [8.26525, 50.02731],
              [8.26533, 50.02721],
              [8.26548, 50.02702],
              [8.26591, 50.02651],
              [8.26611, 50.02627],
              [8.26643, 50.02593],
              [8.26691, 50.02538],
              [8.268, 50.02409],
              [8.26897, 50.02296],
              [8.26906, 50.02285],
              [8.2697, 50.02218],
              [8.27006, 50.02183],
              [8.270869, 50.021054],
              [8.27156, 50.02039],
              [8.27218, 50.0198],
              [8.2751, 50.01701],
              [8.2762, 50.01592],
              [8.27713, 50.01491],
              [8.27799, 50.01395],
              [8.27815, 50.01377],
              [8.27918, 50.01261],
              [8.27929, 50.01231],
              [8.279218, 50.011832],
              [8.27917, 50.01151],
              [8.27917, 50.01113],
              [8.27926, 50.0106],
              [8.28001, 50.00913],
              [8.28014, 50.00869],
              [8.28011, 50.00849],
              [8.28009, 50.00821],
              [8.27981, 50.00787],
              [8.2797, 50.00783],
              [8.27634, 50.00647],
              [8.27589, 50.00628],
              [8.27483, 50.00585],
              [8.27388, 50.00546],
              [8.2733, 50.00523],
              [8.27292, 50.00526],
              [8.27284, 50.00532],
              [8.27241, 50.0058],
              [8.27202, 50.00622],
              [8.2719, 50.00636],
              [8.27168, 50.00659],
              [8.27141, 50.0069],
              [8.27076, 50.00762],
              [8.27052, 50.00777],
              [8.26994, 50.00835],
              [8.26896, 50.00902],
              [8.26837, 50.00924],
              [8.26819, 50.00918],
              [8.267834, 50.008845],
              [8.2675, 50.00853],
              [8.26717, 50.00823],
              [8.26661, 50.00794],
              [8.26644, 50.00786],
              [8.26608, 50.00755],
              [8.26585, 50.00729],
              [8.2654, 50.00668],
              [8.26483, 50.00598],
              [8.26471, 50.00585],
              [8.26464, 50.00578],
              [8.26408, 50.00516],
              [8.26383, 50.00493],
              [8.26305, 50.00418],
              [8.26236, 50.00352],
              [8.26146, 50.00282],
              [8.26113, 50.00247],
              [8.26105, 50.00235],
              [8.26109, 50.0022],
              [8.26132, 50.00203],
              [8.26153, 50.00186],
              [8.26154, 50.00183],
              [8.26124, 50.00131],
              [8.26012, 50.00141],
            ],
          ],
        },
        id: "g1ODg",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.26012, 50.00141],
              [8.26124, 50.00131],
              [8.2614, 50.00124],
              [8.26179, 50.00184],
              [8.26199, 50.00209],
              [8.26205, 50.00215],
              [8.26232, 50.00247],
              [8.26279, 50.00306],
              [8.26287, 50.0033],
              [8.263195, 50.003614],
              [8.26347, 50.00388],
              [8.26431, 50.00472],
              [8.26456, 50.00499],
              [8.26521, 50.00569],
              [8.26608, 50.00644],
              [8.26668, 50.00686],
              [8.26701, 50.00718],
              [8.26722, 50.00749],
              [8.26725, 50.00761],
              [8.26731, 50.00795],
              [8.26777, 50.00845],
              [8.26837, 50.00924],
              [8.26896, 50.00902],
              [8.26994, 50.00835],
              [8.27052, 50.00777],
              [8.27063, 50.00755],
              [8.27131, 50.00686],
              [8.27152, 50.00639],
              [8.27162, 50.00617],
              [8.27167, 50.0061],
              [8.27224, 50.005452],
              [8.27225, 50.00544],
              [8.27306, 50.00463],
              [8.27384, 50.00376],
              [8.27404, 50.00348],
              [8.27407, 50.00343],
              [8.2742, 50.00325],
              [8.27445, 50.00291],
              [8.27467, 50.00297],
              [8.27455, 50.00338],
              [8.27447, 50.00364],
              [8.27365, 50.00446],
              [8.27324, 50.00494],
              [8.27336, 50.00517],
              [8.27367, 50.00531],
              [8.27391, 50.00541],
              [8.27591, 50.00622],
              [8.27639, 50.00642],
              [8.27988, 50.00785],
              [8.2809, 50.00833],
              [8.28108, 50.00839],
              [8.28128, 50.00853],
              [8.28133, 50.00867],
              [8.28128, 50.00853],
              [8.28108, 50.00839],
              [8.28128, 50.00853],
              [8.28133, 50.00867],
              [8.28126, 50.00877],
              [8.28097, 50.00894],
              [8.2807, 50.00896],
              [8.2806, 50.00894],
              [8.28024, 50.00897],
              [8.28001, 50.00913],
              [8.27926, 50.0106],
              [8.27917, 50.01113],
              [8.27917, 50.01151],
              [8.279218, 50.011832],
              [8.27929, 50.01231],
              [8.27918, 50.01261],
              [8.27815, 50.01377],
              [8.27799, 50.01395],
              [8.27713, 50.01491],
              [8.2762, 50.01592],
              [8.2751, 50.01701],
              [8.27218, 50.0198],
              [8.27156, 50.02039],
              [8.270869, 50.021054],
              [8.27006, 50.02183],
              [8.2697, 50.02218],
              [8.26906, 50.02285],
              [8.26897, 50.02296],
              [8.268, 50.02409],
              [8.26691, 50.02538],
              [8.26643, 50.02593],
              [8.26611, 50.02627],
              [8.26591, 50.02651],
              [8.26548, 50.02702],
              [8.26533, 50.02721],
              [8.26525, 50.02731],
              [8.26467, 50.02804],
              [8.26456, 50.02819],
              [8.26444, 50.02836],
              [8.26353, 50.0297],
              [8.26247, 50.03124],
              [8.26239, 50.03135],
              [8.26101, 50.03338],
              [8.26067, 50.03387],
              [8.26022, 50.03453],
              [8.25961, 50.03542],
              [8.25916, 50.03609],
              [8.25886, 50.03653],
              [8.2582, 50.0375],
              [8.25775, 50.03816],
              [8.2572, 50.03896],
              [8.25657, 50.03983],
              [8.25593, 50.04032],
              [8.25531, 50.04075],
              [8.25476, 50.04112],
              [8.25248, 50.0427],
              [8.25133, 50.04354],
              [8.250522, 50.043281],
              [8.249344, 50.043011],
              [8.248347, 50.042638],
              [8.248034, 50.042422],
              [8.24777, 50.04243],
              [8.24753, 50.04234],
              [8.246008, 50.041759],
              [8.24341, 50.04075],
              [8.2405, 50.03964],
              [8.23887, 50.03996],
              [8.2382, 50.03859],
              [8.23779, 50.03774],
              [8.23773, 50.03762],
              [8.23759, 50.03738],
              [8.23684, 50.03639],
              [8.23645, 50.03653],
              [8.23523, 50.03691],
              [8.23501, 50.03695],
              [8.23292, 50.0373],
              [8.23146, 50.03754],
              [8.23124, 50.03758],
              [8.23113, 50.0376],
              [8.23087, 50.03766],
              [8.2282, 50.03868],
              [8.2263, 50.03916],
              [8.225601, 50.039314],
              [8.221736, 50.040166],
              [8.22136, 50.04025],
              [8.22118, 50.04029],
              [8.21983, 50.04058],
              [8.216713, 50.041284],
              [8.21347, 50.042],
              [8.21293, 50.04213],
              [8.21257, 50.04221],
              [8.21018, 50.04276],
              [8.20986, 50.04283],
              [8.20931, 50.04294],
              [8.208261, 50.043171],
              [8.20476, 50.04394],
              [8.20368, 50.04418],
              [8.2027, 50.0444],
              [8.20189, 50.04456],
              [8.20151, 50.04462],
              [8.20059, 50.04463],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.20083, 50.04642],
              [8.20113, 50.04675],
              [8.20023, 50.04672],
              [8.19912, 50.04657],
              [8.19756, 50.04646],
              [8.19744, 50.04699],
              [8.19734, 50.04765],
              [8.194751, 50.047338],
            ],
          ],
        },
        id: "ExNzA",
        direction: "INBOUND",
      },
    ],
  },
];
