import { BusCategory, Line } from "@/types";

export const v2_expressBusse: Line[] = [
  {
    category: BusCategory.EXPRESS,
    id: "gzMzA",
    name: "X88 Bonifatiuskirche <> Mainz-Lerchenberg",
    lineName: "X88",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.23934, 50.08034],
              [8.23623, 50.08016],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.24239, 50.07799],
              [8.24243, 50.07777],
              [8.24236, 50.07769],
              [8.242431, 50.07695],
              [8.24281, 50.0744],
              [8.24288, 50.0739],
              [8.24298, 50.07321],
              [8.24313, 50.07218],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23143, 50.07437],
              [8.23051, 50.07357],
              [8.23, 50.0731],
              [8.22984, 50.07296],
              [8.22977, 50.07289],
              [8.22913, 50.07232],
              [8.22823, 50.07151],
              [8.22797, 50.07128],
              [8.22732, 50.07069],
              [8.22609, 50.06969],
              [8.223309, 50.067704],
              [8.2211, 50.06611],
              [8.22093, 50.06596],
              [8.22074, 50.0658],
              [8.2205, 50.06562],
              [8.21961, 50.06501],
              [8.21893, 50.0645],
              [8.21802, 50.06383],
              [8.21742, 50.06338],
              [8.21711, 50.06314],
              [8.21684, 50.06292],
              [8.21641, 50.06261],
              [8.21632, 50.06254],
              [8.21592, 50.06222],
              [8.21535, 50.06172],
              [8.21437, 50.06056],
              [8.21376, 50.05995],
              [8.21334, 50.05948],
              [8.21261, 50.05821],
              [8.21235, 50.05722],
              [8.21231, 50.05551],
              [8.21233, 50.05534],
              [8.21247, 50.05441],
              [8.2125, 50.05405],
              [8.2126, 50.05319],
              [8.21267, 50.05233],
              [8.21271, 50.05204],
              [8.21281, 50.05116],
              [8.21283, 50.05103],
              [8.21292, 50.05015],
              [8.21294, 50.04992],
              [8.21312, 50.04791],
              [8.21316, 50.0455],
              [8.21309, 50.04464],
              [8.21307, 50.04407],
              [8.21306, 50.04364],
              [8.21305, 50.0434],
              [8.21299, 50.04283],
              [8.21294, 50.04239],
              [8.21293, 50.04213],
              [8.21285, 50.04151],
              [8.21262, 50.03942],
              [8.21216, 50.03527],
              [8.21193, 50.03327],
              [8.21176, 50.03172],
              [8.21172, 50.03134],
              [8.21171, 50.03118],
              [8.21163, 50.03038],
              [8.21161, 50.03024],
              [8.21151, 50.02942],
              [8.21143, 50.02878],
              [8.21139, 50.02815],
              [8.21136, 50.02788],
              [8.21128, 50.02733],
              [8.21121, 50.02689],
              [8.2111, 50.02623],
              [8.21059, 50.02422],
              [8.20958, 50.02165],
              [8.20915, 50.0208],
              [8.20799, 50.01891],
              [8.20613, 50.01648],
              [8.20379, 50.014],
              [8.2033, 50.01353],
              [8.20207, 50.01242],
              [8.20163, 50.01205],
              [8.2007, 50.01129],
              [8.19945, 50.0103],
              [8.19822, 50.00943],
              [8.19522, 50.00748],
              [8.19118, 50.00531],
              [8.18801, 50.00388],
              [8.18768, 50.00374],
              [8.18612, 50.00307],
              [8.18587, 50.00302],
              [8.18469, 50.00263],
              [8.18336, 50.00214],
              [8.18291, 50.00187],
              [8.1824, 50.00137],
              [8.18218, 50.00082],
              [8.18223, 50.00017],
              [8.18247, 49.99974],
              [8.18268, 49.99949],
              [8.1833, 49.99903],
              [8.18368, 49.99886],
              [8.18388, 49.99879],
              [8.18527, 49.99816],
              [8.18577, 49.99768],
              [8.18645, 49.99668],
              [8.18663, 49.99635],
              [8.18701, 49.99559],
              [8.18762, 49.99434],
              [8.18807, 49.99344],
              [8.18894, 49.99199],
              [8.1907, 49.99006],
              [8.19087, 49.98991],
              [8.19141, 49.98939],
              [8.19174, 49.98903],
              [8.19197, 49.98822],
              [8.19189, 49.9875],
              [8.19152, 49.987],
              [8.19057, 49.98643],
              [8.18699, 49.98582],
              [8.18678, 49.98578],
              [8.18603, 49.98566],
              [8.18432, 49.98537],
              [8.18276, 49.98511],
              [8.18208, 49.98499],
              [8.18208, 49.98492],
              [8.18137, 49.98475],
              [8.18208, 49.98492],
              [8.18208, 49.98499],
              [8.18276, 49.98511],
              [8.18331, 49.98447],
              [8.18438, 49.98326],
              [8.18587, 49.98156],
              [8.18666, 49.98053],
              [8.187003, 49.979644],
              [8.18716, 49.97924],
              [8.18734, 49.9788],
              [8.18816, 49.97741],
              [8.18876, 49.97688],
              [8.18902, 49.97671],
              [8.18876, 49.97688],
              [8.18824, 49.97648],
              [8.18792, 49.97606],
              [8.18785, 49.97566],
              [8.18804, 49.97442],
              [8.18807, 49.97424],
              [8.18825, 49.97312],
              [8.1883, 49.97238],
              [8.18805, 49.97173],
              [8.18663, 49.97004],
              [8.18636, 49.96944],
              [8.18639, 49.96874],
              [8.18678, 49.96755],
              [8.18675, 49.96652],
              [8.18712, 49.96642],
              [8.18758, 49.96608],
              [8.18744, 49.96455],
              [8.18743, 49.9642],
              [8.18738, 49.96381],
              [8.18734, 49.96313],
              [8.18727, 49.96236],
              [8.18718, 49.9616],
              [8.18717, 49.9613],
              [8.18716, 49.9611],
              [8.18713, 49.96079],
              [8.18728, 49.9606],
              [8.18777, 49.96022],
              [8.18811, 49.95993],
              [8.18817, 49.95981],
              [8.18834, 49.95942],
              [8.18848, 49.95909],
              [8.1886, 49.95873],
              [8.18877, 49.95824],
              [8.18894, 49.95784],
              [8.18909, 49.9575],
              [8.18926, 49.95715],
              [8.18993, 49.95724],
              [8.19046, 49.95733],
              [8.19095, 49.95739],
              [8.19158, 49.95751],
              [8.19192, 49.95758],
              [8.19287, 49.95773],
              [8.1938, 49.95787],
              [8.19481, 49.95804],
              [8.19556, 49.95811],
              [8.19604, 49.95815],
              [8.19665, 49.95815],
              [8.19701, 49.95823],
              [8.19742, 49.95836],
              [8.19771, 49.95845],
            ],
          ],
        },
        id: "gzMzA",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.19771, 49.95845],
              [8.19742, 49.95836],
              [8.19701, 49.95823],
              [8.19665, 49.95815],
              [8.19604, 49.95815],
              [8.19556, 49.95811],
              [8.19481, 49.95804],
              [8.1938, 49.95787],
              [8.19287, 49.95773],
              [8.19192, 49.95758],
              [8.19158, 49.95751],
              [8.19095, 49.95739],
              [8.19046, 49.95733],
              [8.18993, 49.95724],
              [8.18926, 49.95715],
              [8.18909, 49.9575],
              [8.18894, 49.95784],
              [8.18877, 49.95824],
              [8.1886, 49.95873],
              [8.18848, 49.95909],
              [8.18834, 49.95942],
              [8.18817, 49.95981],
              [8.18811, 49.95993],
              [8.18777, 49.96022],
              [8.18728, 49.9606],
              [8.18713, 49.96079],
              [8.18716, 49.9611],
              [8.18717, 49.9613],
              [8.18718, 49.9616],
              [8.18727, 49.96236],
              [8.18734, 49.96313],
              [8.18738, 49.96381],
              [8.18743, 49.9642],
              [8.18744, 49.96455],
              [8.18758, 49.96608],
              [8.18712, 49.96642],
              [8.18675, 49.96652],
              [8.18678, 49.96755],
              [8.18639, 49.96874],
              [8.18636, 49.96944],
              [8.18663, 49.97004],
              [8.18805, 49.97173],
              [8.1883, 49.97238],
              [8.18825, 49.97312],
              [8.18807, 49.97424],
              [8.18804, 49.97442],
              [8.18785, 49.97566],
              [8.18792, 49.97606],
              [8.18824, 49.97648],
              [8.18876, 49.97688],
              [8.18902, 49.97671],
              [8.18876, 49.97688],
              [8.18816, 49.97741],
              [8.18734, 49.9788],
              [8.18716, 49.97924],
              [8.187003, 49.979644],
              [8.18666, 49.98053],
              [8.18587, 49.98156],
              [8.18438, 49.98326],
              [8.18331, 49.98447],
              [8.18276, 49.98511],
              [8.18208, 49.98499],
              [8.18208, 49.98492],
              [8.18137, 49.98475],
              [8.18208, 49.98492],
              [8.18208, 49.98499],
              [8.18276, 49.98511],
              [8.18432, 49.98537],
              [8.18603, 49.98566],
              [8.18678, 49.98578],
              [8.18699, 49.98582],
              [8.19057, 49.98643],
              [8.19067, 49.98644],
              [8.1922, 49.98668],
              [8.19361, 49.98689],
              [8.19584, 49.98726],
              [8.19671, 49.98748],
              [8.19771, 49.98762],
              [8.19788, 49.9876],
              [8.19833, 49.98731],
              [8.19833, 49.98699],
              [8.19799, 49.98674],
              [8.19715, 49.98672],
              [8.19555, 49.98738],
              [8.19413, 49.98804],
              [8.19224, 49.98911],
              [8.19163, 49.98953],
              [8.19087, 49.99012],
              [8.18973, 49.99122],
              [8.18954, 49.99145],
              [8.18871, 49.99257],
              [8.18819, 49.99349],
              [8.18775, 49.99435],
              [8.18711, 49.99564],
              [8.18677, 49.99637],
              [8.18657, 49.99674],
              [8.18625, 49.9973],
              [8.18571, 49.9979],
              [8.18523, 49.99842],
              [8.18419, 49.99925],
              [8.18364, 49.99981],
              [8.18335, 50.00042],
              [8.1834, 50.00105],
              [8.18354, 50.00134],
              [8.18406, 50.00186],
              [8.18514, 50.00253],
              [8.18599, 50.00288],
              [8.18617, 50.00295],
              [8.18767, 50.00357],
              [8.18858, 50.00395],
              [8.1925, 50.00582],
              [8.19599, 50.00778],
              [8.19948, 50.01011],
              [8.20228, 50.01238],
              [8.20356, 50.01351],
              [8.20377, 50.01371],
              [8.20403, 50.01398],
              [8.20642, 50.01656],
              [8.20774, 50.01827],
              [8.20859, 50.01961],
              [8.2097, 50.02163],
              [8.21066, 50.02408],
              [8.21122, 50.02621],
              [8.21133, 50.02688],
              [8.2114, 50.02731],
              [8.21147, 50.02788],
              [8.21151, 50.02814],
              [8.21158, 50.02878],
              [8.21167, 50.02947],
              [8.21174, 50.03028],
              [8.21175, 50.03037],
              [8.21182, 50.03099],
              [8.21186, 50.03135],
              [8.21206, 50.03326],
              [8.21227, 50.03512],
              [8.21275, 50.03942],
              [8.21298, 50.04143],
              [8.21308, 50.04209],
              [8.21312, 50.04239],
              [8.21319, 50.04345],
              [8.21324, 50.0441],
              [8.21327, 50.04463],
              [8.21329, 50.04517],
              [8.2133, 50.04563],
              [8.21321, 50.04846],
              [8.21318, 50.04891],
              [8.21314, 50.0493],
              [8.21307, 50.04993],
              [8.213, 50.05063],
              [8.21297, 50.05089],
              [8.21294, 50.05105],
              [8.21284, 50.05205],
              [8.2128, 50.05233],
              [8.21271, 50.05304],
              [8.21265, 50.05363],
              [8.2126, 50.05408],
              [8.21255, 50.05442],
              [8.21246, 50.05534],
              [8.21244, 50.05551],
              [8.21245, 50.05712],
              [8.21278, 50.05834],
              [8.21337, 50.0593],
              [8.21391, 50.0599],
              [8.21554, 50.06106],
              [8.21658, 50.06188],
              [8.21698, 50.06216],
              [8.21708, 50.06222],
              [8.21733, 50.06243],
              [8.2178, 50.06282],
              [8.21794, 50.06294],
              [8.21998, 50.0649],
              [8.22048, 50.06548],
              [8.221, 50.06593],
              [8.221162, 50.066064],
              [8.22369, 50.06782],
              [8.22618, 50.06961],
              [8.22676, 50.07005],
              [8.22741, 50.07061],
              [8.22833, 50.07146],
              [8.22921, 50.07227],
              [8.22984, 50.07283],
              [8.22994, 50.07291],
              [8.2301, 50.07306],
              [8.23071, 50.07357],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24328, 50.0717],
              [8.24313, 50.07218],
              [8.24298, 50.07321],
              [8.24288, 50.0739],
              [8.24281, 50.0744],
              [8.24265, 50.07623],
              [8.242575, 50.076958],
              [8.2425, 50.07769],
              [8.24243, 50.07777],
              [8.24239, 50.07799],
              [8.24221, 50.07922],
              [8.2421, 50.07999],
              [8.24206, 50.08049],
              [8.24188, 50.08048],
              [8.23954, 50.08035],
              [8.23934, 50.08034],
            ],
          ],
        },
        id: "E1MjE",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.EXPRESS,
    id: "cyMTA",
    name: "X87 Bonifatiuskirche <> Mainz-Ingelheim",
    lineName: "X87",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.054487, 49.976124],
              [8.05496, 49.97629],
              [8.05522, 49.9763],
              [8.05559, 49.97627],
              [8.05583, 49.97634],
              [8.05601, 49.97627],
              [8.05633, 49.97534],
              [8.05646, 49.97487],
              [8.057211, 49.974944],
              [8.05828, 49.97505],
              [8.05965, 49.97521],
              [8.06108, 49.97537],
              [8.06146, 49.97541],
              [8.062022, 49.975478],
              [8.06221, 49.9755],
              [8.06371, 49.97563],
              [8.064265, 49.975683],
              [8.06434, 49.97569],
              [8.06562, 49.97588],
              [8.06594, 49.97585],
              [8.06697, 49.97592],
              [8.06866, 49.97609],
              [8.06949, 49.97617],
              [8.06989, 49.97615],
              [8.0701, 49.97617],
              [8.070828, 49.97624],
              [8.07083, 49.97624],
              [8.07107, 49.97626],
              [8.07128, 49.9763],
              [8.0721, 49.9763],
              [8.07298, 49.97642],
              [8.07343, 49.97646],
              [8.07445, 49.97663],
              [8.0753, 49.9767],
              [8.07607, 49.97677],
              [8.07729, 49.9769],
              [8.07789, 49.97695],
              [8.08125, 49.97726],
              [8.08159, 49.97729],
              [8.08483, 49.97758],
              [8.08632, 49.97772],
              [8.08683, 49.97777],
              [8.09317, 49.97835],
              [8.09406, 49.97842],
              [8.09476, 49.97841],
              [8.09503, 49.97838],
              [8.09632, 49.97812],
              [8.09693, 49.97795],
              [8.09758, 49.97777],
              [8.10047, 49.97696],
              [8.10268, 49.97635],
              [8.10411, 49.97596],
              [8.10996, 49.9743],
              [8.11066, 49.97412],
              [8.11081, 49.97408],
              [8.11122, 49.97397],
              [8.11178, 49.97381],
              [8.11306, 49.97361],
              [8.11437, 49.97369],
              [8.1152, 49.97382],
              [8.11561, 49.9739],
              [8.11611, 49.97398],
              [8.11651, 49.97404],
              [8.11752, 49.9742],
              [8.11781, 49.97425],
              [8.11882, 49.97442],
              [8.11904, 49.97446],
              [8.11986, 49.97459],
              [8.12205, 49.97496],
              [8.1222, 49.97499],
              [8.124051, 49.975293],
              [8.12446, 49.97536],
              [8.12448, 49.97533],
              [8.12475, 49.9753],
              [8.1248, 49.97541],
              [8.1257, 49.97557],
              [8.13074, 49.97641],
              [8.13204, 49.97663],
              [8.13829, 49.97767],
              [8.14098, 49.97812],
              [8.14329, 49.97851],
              [8.14349, 49.97854],
              [8.14522, 49.97883],
              [8.14883, 49.97943],
              [8.15852, 49.98104],
              [8.15949, 49.98121],
              [8.1604, 49.98136],
              [8.161295, 49.981506],
              [8.16334, 49.98184],
              [8.1641, 49.98197],
              [8.16538, 49.98219],
              [8.16569, 49.98224],
              [8.1667, 49.9824],
              [8.16696, 49.98245],
              [8.16869, 49.98274],
              [8.17026, 49.98299],
              [8.171049, 49.983128],
              [8.17272, 49.98342],
              [8.17392, 49.98362],
              [8.17461, 49.98373],
              [8.17618, 49.98368],
              [8.17641, 49.98367],
              [8.17712, 49.98364],
              [8.17834, 49.98365],
              [8.17904, 49.984],
              [8.17954, 49.98439],
              [8.18025, 49.98466],
              [8.18098, 49.98478],
              [8.1811, 49.9848],
              [8.18208, 49.98499],
              [8.18208, 49.98492],
              [8.18137, 49.98475],
              [8.18208, 49.98492],
              [8.18208, 49.98499],
              [8.18276, 49.98511],
              [8.18432, 49.98537],
              [8.18603, 49.98566],
              [8.18678, 49.98578],
              [8.18699, 49.98582],
              [8.19057, 49.98643],
              [8.19067, 49.98644],
              [8.1922, 49.98668],
              [8.19361, 49.98689],
              [8.19584, 49.98726],
              [8.19671, 49.98748],
              [8.19771, 49.98762],
              [8.19788, 49.9876],
              [8.19833, 49.98731],
              [8.19833, 49.98699],
              [8.19799, 49.98674],
              [8.19715, 49.98672],
              [8.19555, 49.98738],
              [8.19413, 49.98804],
              [8.19224, 49.98911],
              [8.19163, 49.98953],
              [8.19087, 49.99012],
              [8.18973, 49.99122],
              [8.18954, 49.99145],
              [8.18871, 49.99257],
              [8.18819, 49.99349],
              [8.18775, 49.99435],
              [8.18711, 49.99564],
              [8.18677, 49.99637],
              [8.18657, 49.99674],
              [8.18625, 49.9973],
              [8.18571, 49.9979],
              [8.18523, 49.99842],
              [8.18419, 49.99925],
              [8.18364, 49.99981],
              [8.18335, 50.00042],
              [8.1834, 50.00105],
              [8.18354, 50.00134],
              [8.18406, 50.00186],
              [8.18514, 50.00253],
              [8.18599, 50.00288],
              [8.18617, 50.00295],
              [8.18767, 50.00357],
              [8.18858, 50.00395],
              [8.1925, 50.00582],
              [8.19599, 50.00778],
              [8.19948, 50.01011],
              [8.20228, 50.01238],
              [8.20356, 50.01351],
              [8.20377, 50.01371],
              [8.20403, 50.01398],
              [8.20642, 50.01656],
              [8.20774, 50.01827],
              [8.20859, 50.01961],
              [8.2097, 50.02163],
              [8.21066, 50.02408],
              [8.21122, 50.02621],
              [8.21133, 50.02688],
              [8.2114, 50.02731],
              [8.21147, 50.02788],
              [8.21151, 50.02814],
              [8.21158, 50.02878],
              [8.21167, 50.02947],
              [8.21174, 50.03028],
              [8.21175, 50.03037],
              [8.21182, 50.03099],
              [8.21186, 50.03135],
              [8.21206, 50.03326],
              [8.21227, 50.03512],
              [8.21275, 50.03942],
              [8.21298, 50.04143],
              [8.21308, 50.04209],
              [8.21312, 50.04239],
              [8.21319, 50.04345],
              [8.21324, 50.0441],
              [8.21327, 50.04463],
              [8.21329, 50.04517],
              [8.2133, 50.04563],
              [8.21321, 50.04846],
              [8.21318, 50.04891],
              [8.21314, 50.0493],
              [8.21307, 50.04993],
              [8.213, 50.05063],
              [8.21297, 50.05089],
              [8.21294, 50.05105],
              [8.21284, 50.05205],
              [8.2128, 50.05233],
              [8.21271, 50.05304],
              [8.21265, 50.05363],
              [8.2126, 50.05408],
              [8.21255, 50.05442],
              [8.21246, 50.05534],
              [8.21244, 50.05551],
              [8.21245, 50.05712],
              [8.21278, 50.05834],
              [8.21337, 50.0593],
              [8.21391, 50.0599],
              [8.21554, 50.06106],
              [8.21658, 50.06188],
              [8.21698, 50.06216],
              [8.21708, 50.06222],
              [8.21733, 50.06243],
              [8.2178, 50.06282],
              [8.21794, 50.06294],
              [8.21998, 50.0649],
              [8.22048, 50.06548],
              [8.221, 50.06593],
              [8.221162, 50.066064],
              [8.22369, 50.06782],
              [8.22618, 50.06961],
              [8.22676, 50.07005],
              [8.22741, 50.07061],
              [8.22833, 50.07146],
              [8.22921, 50.07227],
              [8.22984, 50.07283],
              [8.22994, 50.07291],
              [8.2301, 50.07306],
              [8.23071, 50.07357],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24328, 50.0717],
              [8.24313, 50.07218],
              [8.24298, 50.07321],
              [8.24288, 50.0739],
              [8.24281, 50.0744],
              [8.24265, 50.07623],
              [8.242575, 50.076958],
              [8.2425, 50.07769],
              [8.24243, 50.07777],
              [8.24239, 50.07799],
              [8.24221, 50.07922],
              [8.2421, 50.07999],
              [8.24206, 50.08049],
              [8.24188, 50.08048],
              [8.23954, 50.08035],
              [8.23934, 50.08034],
            ],
          ],
        },
        id: "cyMTA",
        direction: "INBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.23934, 50.08034],
              [8.23623, 50.08016],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.24239, 50.07799],
              [8.24243, 50.07777],
              [8.24236, 50.07769],
              [8.242431, 50.07695],
              [8.24281, 50.0744],
              [8.24288, 50.0739],
              [8.24298, 50.07321],
              [8.24313, 50.07218],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23143, 50.07437],
              [8.23051, 50.07357],
              [8.23, 50.0731],
              [8.22984, 50.07296],
              [8.22977, 50.07289],
              [8.22913, 50.07232],
              [8.22823, 50.07151],
              [8.22797, 50.07128],
              [8.22732, 50.07069],
              [8.22609, 50.06969],
              [8.223309, 50.067704],
              [8.2211, 50.06611],
              [8.22093, 50.06596],
              [8.22074, 50.0658],
              [8.2205, 50.06562],
              [8.21961, 50.06501],
              [8.21893, 50.0645],
              [8.21802, 50.06383],
              [8.21742, 50.06338],
              [8.21711, 50.06314],
              [8.21684, 50.06292],
              [8.21641, 50.06261],
              [8.21632, 50.06254],
              [8.21592, 50.06222],
              [8.21535, 50.06172],
              [8.21437, 50.06056],
              [8.21376, 50.05995],
              [8.21334, 50.05948],
              [8.21261, 50.05821],
              [8.21235, 50.05722],
              [8.21231, 50.05551],
              [8.21233, 50.05534],
              [8.21247, 50.05441],
              [8.2125, 50.05405],
              [8.2126, 50.05319],
              [8.21267, 50.05233],
              [8.21271, 50.05204],
              [8.21281, 50.05116],
              [8.21283, 50.05103],
              [8.21292, 50.05015],
              [8.21294, 50.04992],
              [8.21312, 50.04791],
              [8.21316, 50.0455],
              [8.21309, 50.04464],
              [8.21307, 50.04407],
              [8.21306, 50.04364],
              [8.21305, 50.0434],
              [8.21299, 50.04283],
              [8.21294, 50.04239],
              [8.21293, 50.04213],
              [8.21285, 50.04151],
              [8.21262, 50.03942],
              [8.21216, 50.03527],
              [8.21193, 50.03327],
              [8.21176, 50.03172],
              [8.21172, 50.03134],
              [8.21171, 50.03118],
              [8.21163, 50.03038],
              [8.21161, 50.03024],
              [8.21151, 50.02942],
              [8.21143, 50.02878],
              [8.21139, 50.02815],
              [8.21136, 50.02788],
              [8.21128, 50.02733],
              [8.21121, 50.02689],
              [8.2111, 50.02623],
              [8.21059, 50.02422],
              [8.20958, 50.02165],
              [8.20915, 50.0208],
              [8.20799, 50.01891],
              [8.20613, 50.01648],
              [8.20379, 50.014],
              [8.2033, 50.01353],
              [8.20207, 50.01242],
              [8.20163, 50.01205],
              [8.2007, 50.01129],
              [8.19945, 50.0103],
              [8.19822, 50.00943],
              [8.19522, 50.00748],
              [8.19118, 50.00531],
              [8.18801, 50.00388],
              [8.18768, 50.00374],
              [8.18612, 50.00307],
              [8.18587, 50.00302],
              [8.18469, 50.00263],
              [8.18336, 50.00214],
              [8.18291, 50.00187],
              [8.1824, 50.00137],
              [8.18218, 50.00082],
              [8.18223, 50.00017],
              [8.18247, 49.99974],
              [8.18268, 49.99949],
              [8.1833, 49.99903],
              [8.18368, 49.99886],
              [8.18388, 49.99879],
              [8.18527, 49.99816],
              [8.18577, 49.99768],
              [8.18645, 49.99668],
              [8.18663, 49.99635],
              [8.18701, 49.99559],
              [8.18762, 49.99434],
              [8.18807, 49.99344],
              [8.18894, 49.99199],
              [8.1907, 49.99006],
              [8.19087, 49.98991],
              [8.19141, 49.98939],
              [8.19174, 49.98903],
              [8.19197, 49.98822],
              [8.19189, 49.9875],
              [8.19152, 49.987],
              [8.19057, 49.98643],
              [8.18699, 49.98582],
              [8.18678, 49.98578],
              [8.18603, 49.98566],
              [8.18432, 49.98537],
              [8.18276, 49.98511],
              [8.18208, 49.98499],
              [8.18208, 49.98492],
              [8.18137, 49.98475],
              [8.18208, 49.98492],
              [8.18208, 49.98499],
              [8.1811, 49.9848],
              [8.18098, 49.98478],
              [8.18025, 49.98466],
              [8.17954, 49.98439],
              [8.17904, 49.984],
              [8.17834, 49.98365],
              [8.17712, 49.98364],
              [8.17641, 49.98367],
              [8.17618, 49.98368],
              [8.17461, 49.98373],
              [8.17392, 49.98362],
              [8.17272, 49.98342],
              [8.171049, 49.983128],
              [8.17026, 49.98299],
              [8.16869, 49.98274],
              [8.16696, 49.98245],
              [8.1667, 49.9824],
              [8.16569, 49.98224],
              [8.16538, 49.98219],
              [8.1641, 49.98197],
              [8.16334, 49.98184],
              [8.161295, 49.981506],
              [8.1604, 49.98136],
              [8.15949, 49.98121],
              [8.15852, 49.98104],
              [8.14883, 49.97943],
              [8.14522, 49.97883],
              [8.14349, 49.97854],
              [8.14329, 49.97851],
              [8.14098, 49.97812],
              [8.13829, 49.97767],
              [8.13204, 49.97663],
              [8.13074, 49.97641],
              [8.1257, 49.97557],
              [8.1248, 49.97541],
              [8.12475, 49.9753],
              [8.12448, 49.97533],
              [8.12446, 49.97536],
              [8.124051, 49.975293],
              [8.1222, 49.97499],
              [8.12205, 49.97496],
              [8.11986, 49.97459],
              [8.11904, 49.97446],
              [8.11882, 49.97442],
              [8.11781, 49.97425],
              [8.11752, 49.9742],
              [8.11651, 49.97404],
              [8.11611, 49.97398],
              [8.11561, 49.9739],
              [8.1152, 49.97382],
              [8.11437, 49.97369],
              [8.11306, 49.97361],
              [8.11178, 49.97381],
              [8.11122, 49.97397],
              [8.11081, 49.97408],
              [8.11066, 49.97412],
              [8.10996, 49.9743],
              [8.10411, 49.97596],
              [8.10268, 49.97635],
              [8.10047, 49.97696],
              [8.09758, 49.97777],
              [8.09693, 49.97795],
              [8.09632, 49.97812],
              [8.09503, 49.97838],
              [8.09476, 49.97841],
              [8.09406, 49.97842],
              [8.09317, 49.97835],
              [8.08683, 49.97777],
              [8.08632, 49.97772],
              [8.08483, 49.97758],
              [8.08159, 49.97729],
              [8.08125, 49.97726],
              [8.07789, 49.97695],
              [8.07729, 49.9769],
              [8.07607, 49.97677],
              [8.07577, 49.97738],
              [8.07471, 49.97831],
              [8.07442, 49.97859],
              [8.07422, 49.97873],
              [8.07307, 49.97922],
              [8.072783, 49.979306],
              [8.07267, 49.97934],
              [8.07178, 49.97944],
              [8.07156, 49.97946],
              [8.07061, 49.97954],
              [8.06989, 49.97962],
              [8.06887, 49.97994],
              [8.06876, 49.98],
              [8.06829, 49.98032],
              [8.06794, 49.98064],
              [8.06756, 49.98038],
              [8.06578, 49.97938],
              [8.06562, 49.97929],
              [8.06412, 49.97849],
              [8.064007, 49.97844],
              [8.06369, 49.9783],
              [8.0616, 49.97758],
              [8.06135, 49.9775],
              [8.061056, 49.977391],
              [8.06084, 49.97731],
              [8.06059, 49.97724],
              [8.05974, 49.97693],
              [8.05943, 49.97663],
              [8.05934, 49.97623],
              [8.05965, 49.97521],
              [8.05828, 49.97505],
              [8.057211, 49.974944],
              [8.05646, 49.97487],
              [8.05633, 49.97534],
              [8.05601, 49.97627],
              [8.05583, 49.97634],
              [8.05559, 49.97627],
              [8.05522, 49.9763],
              [8.05496, 49.97629],
              [8.054487, 49.976124],
            ],
          ],
        },
        id: "M4MTE",
        direction: "OUTBOUND",
      },
    ],
  },
  {
    category: BusCategory.EXPRESS,
    id: "M5NDA",
    name: "X83 Wallau <> Mainz",
    lineName: "X83",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.372276, 50.053541],
              [8.37299, 50.05302],
              [8.3731, 50.05294],
              [8.3734, 50.05272],
              [8.37381, 50.05243],
              [8.3739, 50.05236],
              [8.3742, 50.05214],
              [8.37435, 50.05204],
              [8.37513, 50.05148],
              [8.3754, 50.05129],
              [8.37516, 50.05105],
              [8.374894, 50.05066],
              [8.37433, 50.04983],
              [8.37303, 50.04852],
              [8.37199, 50.04785],
              [8.37142, 50.04756],
              [8.37067, 50.04741],
              [8.37003, 50.04735],
              [8.36851, 50.0473],
              [8.36813, 50.04728],
              [8.36767, 50.04721],
              [8.36635, 50.04673],
              [8.36603, 50.04662],
              [8.36574, 50.04636],
              [8.36515, 50.04647],
              [8.36471, 50.04657],
              [8.3645, 50.04662],
              [8.36443, 50.04664],
              [8.3634, 50.0469],
              [8.36334, 50.04691],
              [8.36273, 50.04708],
              [8.36266, 50.0471],
              [8.36196, 50.04735],
              [8.359891, 50.045646],
              [8.3596, 50.04553],
              [8.35835, 50.0455],
              [8.35842, 50.0452],
              [8.35845, 50.04441],
              [8.35839, 50.044],
              [8.35814, 50.04325],
              [8.35646, 50.04059],
              [8.3559, 50.03931],
              [8.35564, 50.03874],
              [8.35545, 50.03833],
              [8.35519, 50.03775],
              [8.35479, 50.03686],
              [8.35438, 50.03649],
              [8.35309, 50.03593],
              [8.3518, 50.03543],
              [8.3505, 50.03493],
              [8.35005, 50.03475],
              [8.34822, 50.033887],
              [8.348142, 50.033412],
              [8.34839, 50.03279],
              [8.34857, 50.03245],
              [8.34878, 50.03206],
              [8.34901, 50.03168],
              [8.349722, 50.030474],
              [8.35009, 50.02985],
              [8.35082, 50.02851],
              [8.35152, 50.02711],
              [8.35198, 50.02577],
              [8.35228, 50.02383],
              [8.35228, 50.02295],
              [8.35227, 50.02251],
              [8.35216, 50.02093],
              [8.3522, 50.02008],
              [8.35245, 50.01873],
              [8.35324, 50.01862],
              [8.35422, 50.01837],
              [8.35439, 50.0183],
              [8.35451, 50.01826],
              [8.35553, 50.01777],
              [8.35561, 50.01773],
              [8.35636, 50.01727],
              [8.3566, 50.01709],
              [8.35678, 50.01696],
              [8.357216, 50.01653],
              [8.35746, 50.01629],
              [8.35768, 50.01605],
              [8.35781, 50.01592],
              [8.35896, 50.01463],
              [8.35905, 50.01452],
              [8.35944, 50.01408],
              [8.35992, 50.01359],
              [8.35778, 50.0128],
              [8.35704, 50.01254],
              [8.3569, 50.01248],
              [8.35581, 50.01205],
              [8.35468, 50.01162],
              [8.35434, 50.01167],
              [8.3534, 50.0118],
              [8.3533, 50.01137],
              [8.35336, 50.01115],
              [8.35265, 50.01099],
              [8.35217, 50.01099],
              [8.35097, 50.01109],
              [8.35083, 50.01111],
              [8.35044, 50.01114],
              [8.3499, 50.01116],
              [8.34947, 50.01112],
              [8.34926, 50.01108],
              [8.34857, 50.01087],
              [8.34801, 50.01074],
              [8.34761, 50.01071],
              [8.34696, 50.01077],
              [8.34684, 50.01078],
              [8.34537, 50.01089],
              [8.34525, 50.01097],
              [8.344906, 50.010919],
              [8.34485, 50.01091],
              [8.34343, 50.011],
              [8.34321, 50.01101],
              [8.34274, 50.01116],
              [8.34239, 50.01115],
              [8.34191, 50.01111],
              [8.34131, 50.01106],
              [8.34054, 50.01095],
              [8.33996, 50.01105],
              [8.33984, 50.01113],
              [8.33971, 50.0115],
              [8.33996, 50.01176],
              [8.34043, 50.01178],
              [8.34085, 50.01152],
              [8.3411, 50.01108],
              [8.3422, 50.00899],
              [8.34235, 50.00869],
              [8.34313, 50.00706],
              [8.34402, 50.00482],
              [8.34498, 50.00177],
              [8.34537, 49.99956],
              [8.34542, 49.99747],
              [8.34516, 49.99701],
              [8.34463, 49.99684],
              [8.34322, 49.99679],
              [8.34257, 49.99652],
              [8.34233, 49.99616],
              [8.34245, 49.99587],
              [8.34265, 49.99572],
              [8.34329, 49.9955],
              [8.34747, 49.99552],
              [8.3483, 49.99547],
              [8.34853, 49.99545],
              [8.34963, 49.99533],
              [8.34975, 49.99532],
              [8.35408, 49.9946],
              [8.35517, 49.99428],
              [8.35783, 49.99347],
              [8.357575, 49.993171],
              [8.3573, 49.99285],
              [8.35603, 49.99177],
              [8.356, 49.99157],
              [8.3561, 49.99138],
              [8.35699, 49.99093],
              [8.35734, 49.99077],
              [8.35723, 49.99066],
              [8.35668, 49.99001],
              [8.35622, 49.98963],
              [8.355655, 49.989424],
              [8.35493, 49.98916],
              [8.3552, 49.98887],
              [8.35563, 49.98839],
              [8.35565, 49.98834],
              [8.35593, 49.98786],
              [8.35618, 49.9876],
              [8.35657, 49.98713],
              [8.35543, 49.98667],
              [8.35419, 49.98621],
              [8.35297, 49.98576],
              [8.35211, 49.98542],
              [8.35162, 49.9852],
              [8.35146, 49.98511],
              [8.35103, 49.98471],
              [8.35092, 49.98451],
              [8.35055, 49.98345],
              [8.35037, 49.98285],
              [8.35024, 49.98253],
              [8.3501, 49.98225],
              [8.34987, 49.98184],
              [8.34869, 49.98042],
              [8.34802, 49.97946],
              [8.34764, 49.97856],
              [8.34754, 49.9782],
              [8.34741, 49.97766],
              [8.34732, 49.97718],
              [8.34509, 49.97701],
              [8.34455, 49.97703],
              [8.34416, 49.97733],
              [8.34399, 49.97735],
              [8.34304, 49.97728],
              [8.34202, 49.977],
              [8.34139, 49.97669],
              [8.34025, 49.97617],
              [8.33987, 49.9765],
              [8.33955, 49.97679],
              [8.33779, 49.97836],
              [8.33708, 49.97893],
              [8.33667, 49.97927],
              [8.33634, 49.97951],
              [8.33454, 49.98101],
              [8.33445, 49.9811],
              [8.33292, 49.98258],
              [8.33235, 49.98269],
              [8.33189, 49.9826],
              [8.33114, 49.98217],
              [8.33063, 49.98173],
              [8.3303, 49.98114],
              [8.33028, 49.98059],
              [8.33066, 49.97987],
              [8.33078, 49.97967],
              [8.3307, 49.97928],
              [8.32957, 49.9787],
              [8.3292, 49.97851],
              [8.3237, 49.97566],
              [8.32321, 49.97541],
              [8.32126, 49.9744],
              [8.31928, 49.97337],
              [8.31879, 49.97311],
              [8.31778, 49.97264],
              [8.31667, 49.9721],
              [8.31551, 49.97161],
              [8.31532, 49.97154],
              [8.31463, 49.9713],
              [8.3129, 49.97081],
              [8.31273, 49.97077],
              [8.31185, 49.97061],
              [8.30898, 49.97025],
              [8.30848, 49.97043],
              [8.30836, 49.97064],
              [8.30848, 49.97086],
              [8.309, 49.97103],
              [8.31122, 49.97136],
              [8.31169, 49.97119],
              [8.31188, 49.97095],
              [8.31218, 49.97022],
              [8.31242, 49.96979],
              [8.31269, 49.96947],
              [8.31302, 49.96913],
              [8.31346, 49.96873],
              [8.31418, 49.96833],
              [8.31547, 49.9677],
              [8.31714, 49.96724],
              [8.31817, 49.9671],
              [8.31949, 49.96701],
              [8.32083, 49.96691],
              [8.32147, 49.96663],
              [8.32144, 49.96643],
              [8.32118, 49.96634],
              [8.31974, 49.96649],
              [8.31983, 49.96628],
              [8.31994, 49.96597],
              [8.32035, 49.96488],
              [8.32035, 49.96481],
              [8.31995, 49.96432],
              [8.31988, 49.96424],
              [8.31956, 49.96389],
              [8.31921, 49.96348],
              [8.31868, 49.96287],
              [8.31851, 49.96278],
              [8.31707, 49.96257],
              [8.31646, 49.96231],
              [8.31632, 49.96217],
              [8.31561, 49.96194],
              [8.31458, 49.96173],
              [8.31407, 49.96167],
              [8.31396, 49.96166],
              [8.31377, 49.96145],
              [8.3138, 49.96086],
              [8.31368, 49.96077],
              [8.31318, 49.96071],
              [8.31296, 49.96071],
            ],
          ],
        },
        id: "M5NDA",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.31296, 49.96071],
              [8.31318, 49.96071],
              [8.31368, 49.96077],
              [8.3138, 49.96086],
              [8.31377, 49.96145],
              [8.31396, 49.96166],
              [8.31407, 49.96167],
              [8.31458, 49.96173],
              [8.31561, 49.96194],
              [8.31632, 49.96217],
              [8.31646, 49.96231],
              [8.31707, 49.96257],
              [8.31851, 49.96278],
              [8.31868, 49.96287],
              [8.31921, 49.96348],
              [8.31956, 49.96389],
              [8.31988, 49.96424],
              [8.31995, 49.96432],
              [8.32035, 49.96481],
              [8.32035, 49.96488],
              [8.31994, 49.96597],
              [8.31983, 49.96628],
              [8.31974, 49.96649],
              [8.31958, 49.96683],
              [8.31949, 49.96701],
              [8.31946, 49.9671],
              [8.31939, 49.96728],
              [8.31926, 49.96763],
              [8.3219, 49.96734],
              [8.32202, 49.9672],
              [8.32203, 49.96702],
              [8.32162, 49.96688],
              [8.32088, 49.96699],
              [8.31946, 49.9671],
              [8.31719, 49.96731],
              [8.31554, 49.96778],
              [8.31436, 49.96834],
              [8.3136, 49.96898],
              [8.31343, 49.96951],
              [8.31369, 49.96988],
              [8.31394, 49.97019],
              [8.31398, 49.97036],
              [8.31412, 49.97085],
              [8.31467, 49.97122],
              [8.31526, 49.97141],
              [8.3156, 49.97153],
              [8.31673, 49.972],
              [8.31744, 49.97233],
              [8.31888, 49.97304],
              [8.31938, 49.97331],
              [8.32135, 49.97433],
              [8.32332, 49.97534],
              [8.32376, 49.97558],
              [8.32808, 49.9778],
              [8.32818, 49.97785],
              [8.32882, 49.97818],
              [8.32921, 49.978382],
              [8.329833, 49.978559],
              [8.330742, 49.979026],
              [8.331469, 49.979361],
              [8.332051, 49.979567],
              [8.332395, 49.979555],
              [8.332747, 49.979514],
              [8.33313, 49.97917],
              [8.33402, 49.9787],
              [8.335, 49.97862],
              [8.33603, 49.97892],
              [8.33647, 49.97899],
              [8.33708, 49.97893],
              [8.33779, 49.97836],
              [8.33955, 49.97679],
              [8.33987, 49.9765],
              [8.34025, 49.97617],
              [8.34139, 49.97669],
              [8.34202, 49.977],
              [8.34304, 49.97728],
              [8.34399, 49.97735],
              [8.34416, 49.97733],
              [8.34455, 49.97703],
              [8.34509, 49.97701],
              [8.34732, 49.97718],
              [8.34741, 49.97766],
              [8.34754, 49.9782],
              [8.34764, 49.97856],
              [8.34802, 49.97946],
              [8.34869, 49.98042],
              [8.34987, 49.98184],
              [8.3501, 49.98225],
              [8.35024, 49.98253],
              [8.35037, 49.98285],
              [8.35055, 49.98345],
              [8.35092, 49.98451],
              [8.35103, 49.98471],
              [8.35146, 49.98511],
              [8.35162, 49.9852],
              [8.35211, 49.98542],
              [8.35297, 49.98576],
              [8.35419, 49.98621],
              [8.35543, 49.98667],
              [8.35657, 49.98713],
              [8.35618, 49.9876],
              [8.35593, 49.98786],
              [8.35565, 49.98834],
              [8.35563, 49.98839],
              [8.3552, 49.98887],
              [8.35493, 49.98916],
              [8.355655, 49.989424],
              [8.35622, 49.98963],
              [8.35668, 49.99001],
              [8.35723, 49.99066],
              [8.35734, 49.99077],
              [8.35699, 49.99093],
              [8.3561, 49.99138],
              [8.356, 49.99157],
              [8.35603, 49.99177],
              [8.3573, 49.99285],
              [8.357575, 49.993171],
              [8.35783, 49.99347],
              [8.35517, 49.99428],
              [8.35408, 49.9946],
              [8.34975, 49.99532],
              [8.34963, 49.99533],
              [8.34861, 49.99571],
              [8.34836, 49.99605],
              [8.34765, 49.99634],
              [8.34739, 49.99637],
              [8.34627, 49.99657],
              [8.34572, 49.99681],
              [8.34557, 49.99705],
              [8.34559, 49.99747],
              [8.34551, 49.99956],
              [8.34523, 50.00129],
              [8.34436, 50.00426],
              [8.34356, 50.00637],
              [8.34326, 50.00707],
              [8.34184, 50.01008],
              [8.34164, 50.01043],
              [8.34128, 50.01111],
              [8.34118, 50.01179],
              [8.34154, 50.01207],
              [8.34184, 50.01215],
              [8.34224, 50.0121],
              [8.34257, 50.01188],
              [8.34268, 50.0118],
              [8.34278, 50.01151],
              [8.34274, 50.01116],
              [8.34321, 50.01101],
              [8.34343, 50.011],
              [8.34485, 50.01091],
              [8.344906, 50.010919],
              [8.34525, 50.01097],
              [8.34544, 50.01212],
              [8.34706, 50.01236],
              [8.34783, 50.01249],
              [8.34845, 50.01259],
              [8.34943, 50.01267],
              [8.35004, 50.01252],
              [8.35118, 50.01228],
              [8.35148, 50.01222],
              [8.35276, 50.01195],
              [8.3534, 50.0118],
              [8.35434, 50.01167],
              [8.35468, 50.01162],
              [8.35581, 50.01205],
              [8.3569, 50.01248],
              [8.35704, 50.01254],
              [8.35778, 50.0128],
              [8.35992, 50.01359],
              [8.35944, 50.01408],
              [8.35905, 50.01452],
              [8.35896, 50.01463],
              [8.35781, 50.01592],
              [8.35768, 50.01605],
              [8.35746, 50.01629],
              [8.357216, 50.01653],
              [8.35678, 50.01696],
              [8.3566, 50.01709],
              [8.35636, 50.01727],
              [8.35561, 50.01773],
              [8.35553, 50.01777],
              [8.35451, 50.01826],
              [8.35439, 50.0183],
              [8.35422, 50.01837],
              [8.35324, 50.01862],
              [8.35245, 50.01873],
              [8.3522, 50.02008],
              [8.35216, 50.02093],
              [8.35227, 50.02251],
              [8.35228, 50.02295],
              [8.35228, 50.02383],
              [8.35198, 50.02577],
              [8.35152, 50.02711],
              [8.35082, 50.02851],
              [8.35009, 50.02985],
              [8.349722, 50.030474],
              [8.34901, 50.03168],
              [8.34878, 50.03206],
              [8.34857, 50.03245],
              [8.34839, 50.03279],
              [8.348142, 50.033412],
              [8.34822, 50.033887],
              [8.35005, 50.03475],
              [8.3505, 50.03493],
              [8.3518, 50.03543],
              [8.35309, 50.03593],
              [8.35438, 50.03649],
              [8.35479, 50.03686],
              [8.35519, 50.03775],
              [8.35545, 50.03833],
              [8.35564, 50.03874],
              [8.3559, 50.03931],
              [8.35646, 50.04059],
              [8.35814, 50.04325],
              [8.35839, 50.044],
              [8.35845, 50.04441],
              [8.35842, 50.0452],
              [8.35835, 50.0455],
              [8.3596, 50.04553],
              [8.359891, 50.045646],
              [8.36196, 50.04735],
              [8.36266, 50.0471],
              [8.36273, 50.04708],
              [8.36334, 50.04691],
              [8.3634, 50.0469],
              [8.36443, 50.04664],
              [8.3645, 50.04662],
              [8.36471, 50.04657],
              [8.36515, 50.04647],
              [8.36574, 50.04636],
              [8.36603, 50.04662],
              [8.36635, 50.04673],
              [8.36767, 50.04721],
              [8.36813, 50.04728],
              [8.36851, 50.0473],
              [8.37003, 50.04735],
              [8.37067, 50.04741],
              [8.37142, 50.04756],
              [8.37199, 50.04785],
              [8.37303, 50.04852],
              [8.37433, 50.04983],
              [8.374894, 50.05066],
              [8.37516, 50.05105],
              [8.3754, 50.05129],
              [8.37513, 50.05148],
              [8.37435, 50.05204],
              [8.3742, 50.05214],
              [8.3739, 50.05236],
              [8.37381, 50.05243],
              [8.3734, 50.05272],
              [8.3731, 50.05294],
              [8.37299, 50.05302],
              [8.372276, 50.053541],
            ],
          ],
        },
        id: "Q2Mzk",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.EXPRESS,
    id: "EwNjI",
    name: "X79 Bad Schwalbach <> Mainz",
    lineName: "X79",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.27445, 50.00291],
              [8.27452, 50.00282],
              [8.27487, 50.00243],
              [8.2756, 50.00144],
              [8.27362, 50.00079],
              [8.27341, 50.00074],
              [8.2732, 50.0007],
              [8.27264, 50.00063],
              [8.27196, 50.00058],
              [8.27188, 50.00026],
              [8.27174, 49.99993],
              [8.272044, 49.999506],
              [8.27227, 49.99919],
              [8.27072, 49.99873],
              [8.27, 49.99852],
              [8.2694, 49.99833],
              [8.26835, 49.99799],
              [8.26775, 49.99783],
              [8.26744, 49.99822],
              [8.267, 49.99856],
              [8.26585, 49.99931],
              [8.26553, 49.99954],
              [8.26534, 49.99961],
              [8.26437, 50.00014],
              [8.26412, 50.00017],
              [8.26386, 50.00023],
              [8.25975, 50.00174],
              [8.259848, 50.001642],
              [8.260004, 50.001394],
              [8.259978, 50.00123],
              [8.259912, 50.0011],
              [8.259941, 50.000972],
              [8.260124, 50.000729],
              [8.259955, 50.000972],
              [8.259966, 50.00115],
              [8.260088, 50.001297],
              [8.260245, 50.001364],
              [8.260506, 50.001397],
              [8.261071, 50.001361],
              [8.26159, 50.001195],
              [8.26337, 50.000453],
              [8.266871, 49.998641],
              [8.26337, 50.000453],
              [8.26159, 50.001195],
              [8.261071, 50.001361],
              [8.260506, 50.001397],
              [8.260245, 50.001364],
              [8.260088, 50.001297],
              [8.259966, 50.00115],
              [8.259955, 50.000972],
              [8.260124, 50.000729],
              [8.259941, 50.000972],
              [8.259912, 50.0011],
              [8.259978, 50.00123],
              [8.260004, 50.001394],
              [8.259848, 50.001642],
              [8.25975, 50.00174],
              [8.26386, 50.00023],
              [8.26337, 50.00046],
              [8.26256, 50.00077],
              [8.2614, 50.00124],
              [8.26124, 50.00131],
              [8.26012, 50.00141],
              [8.25996, 50.00114],
              [8.25989, 50.00102],
              [8.26052, 50.00034],
              [8.25994, 50.00007],
              [8.25909, 49.99976],
              [8.25889, 49.99971],
              [8.25846, 49.99967],
              [8.25828, 49.99963],
              [8.25791, 49.99939],
              [8.25746, 49.99916],
              [8.25718, 49.99895],
              [8.25633, 49.99802],
              [8.2563, 49.99799],
              [8.25597, 49.9977],
              [8.25585, 49.99764],
              [8.25465, 49.99756],
              [8.25286, 49.99758],
              [8.25186, 49.99756],
              [8.25089, 49.99748],
              [8.249185, 49.997403],
              [8.24912, 49.9974],
              [8.24715, 49.99712],
              [8.24679, 49.99705],
              [8.24464, 49.99652],
              [8.24331, 49.99608],
              [8.24225, 49.99581],
              [8.23936, 49.99516],
              [8.23024, 49.99329],
              [8.22785, 49.99284],
              [8.22696, 49.99269],
              [8.2219, 49.9918],
              [8.22029, 49.99157],
              [8.21999, 49.99152],
              [8.21923, 49.99157],
              [8.21913, 49.99161],
              [8.21866, 49.99152],
              [8.21861, 49.99145],
              [8.21835, 49.99133],
              [8.21753, 49.99111],
              [8.21619, 49.99091],
              [8.20745, 49.98968],
              [8.20617, 49.98953],
              [8.2056, 49.98946],
              [8.20393, 49.98928],
              [8.2029, 49.98917],
              [8.2009, 49.9889],
              [8.19799, 49.98847],
              [8.19605, 49.98844],
              [8.19424, 49.98864],
              [8.19279, 49.98901],
              [8.19163, 49.98953],
              [8.19087, 49.99012],
              [8.18973, 49.99122],
              [8.18954, 49.99145],
              [8.18871, 49.99257],
              [8.18819, 49.99349],
              [8.18775, 49.99435],
              [8.18711, 49.99564],
              [8.18677, 49.99637],
              [8.18657, 49.99674],
              [8.18625, 49.9973],
              [8.18571, 49.9979],
              [8.18523, 49.99842],
              [8.18419, 49.99925],
              [8.18364, 49.99981],
              [8.18335, 50.00042],
              [8.1834, 50.00105],
              [8.18354, 50.00134],
              [8.18406, 50.00186],
              [8.18514, 50.00253],
              [8.18599, 50.00288],
              [8.18617, 50.00295],
              [8.18767, 50.00357],
              [8.18858, 50.00395],
              [8.1925, 50.00582],
              [8.19599, 50.00778],
              [8.19948, 50.01011],
              [8.20228, 50.01238],
              [8.20356, 50.01351],
              [8.20377, 50.01371],
              [8.20403, 50.01398],
              [8.20642, 50.01656],
              [8.20774, 50.01827],
              [8.20859, 50.01961],
              [8.2097, 50.02163],
              [8.21066, 50.02408],
              [8.21122, 50.02621],
              [8.21133, 50.02688],
              [8.2114, 50.02731],
              [8.21147, 50.02788],
              [8.21151, 50.02814],
              [8.21158, 50.02878],
              [8.21167, 50.02947],
              [8.21174, 50.03028],
              [8.21175, 50.03037],
              [8.21182, 50.03099],
              [8.21186, 50.03135],
              [8.21206, 50.03326],
              [8.21227, 50.03512],
              [8.21275, 50.03942],
              [8.21298, 50.04143],
              [8.21308, 50.04209],
              [8.21312, 50.04239],
              [8.21334, 50.04274],
              [8.21405, 50.04293],
              [8.21549, 50.04306],
              [8.21623, 50.04335],
              [8.2167, 50.0438],
              [8.21678, 50.04426],
              [8.21676, 50.04434],
              [8.21641, 50.04446],
              [8.21616, 50.04456],
              [8.214199, 50.044255],
              [8.21365, 50.04416],
              [8.213409, 50.044122],
              [8.21324, 50.0441],
              [8.21307, 50.04407],
              [8.21268, 50.04401],
              [8.21151, 50.04384],
              [8.21072, 50.0437],
              [8.21046, 50.04367],
              [8.21005, 50.04369],
              [8.20986, 50.04316],
              [8.20973, 50.04305],
              [8.20931, 50.04294],
              [8.208261, 50.043171],
              [8.20476, 50.04394],
              [8.20368, 50.04418],
              [8.2027, 50.0444],
              [8.20189, 50.04456],
              [8.20151, 50.04462],
              [8.20059, 50.04463],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.19923, 50.04589],
              [8.19767, 50.04577],
              [8.19617, 50.04565],
              [8.19585, 50.04563],
              [8.19456, 50.04562],
              [8.19392, 50.04562],
              [8.19219, 50.04532],
              [8.19174, 50.04524],
              [8.19042, 50.04504],
              [8.18751, 50.04477],
              [8.18518, 50.04465],
              [8.18471, 50.04463],
              [8.18368, 50.04457],
              [8.17787, 50.04428],
              [8.1763, 50.04418],
              [8.17526, 50.04412],
              [8.17365, 50.04395],
              [8.17296, 50.04384],
              [8.16942, 50.0431],
              [8.16922, 50.0431],
              [8.1671, 50.04252],
              [8.1669, 50.04263],
              [8.16672, 50.04256],
              [8.16583, 50.04277],
              [8.16524, 50.04282],
              [8.16443, 50.04298],
              [8.16413, 50.04316],
              [8.16404, 50.04322],
              [8.16274, 50.04443],
              [8.16216, 50.04489],
              [8.16209, 50.04489],
              [8.16201, 50.04489],
              [8.16079, 50.0444],
              [8.15988, 50.04441],
              [8.15962, 50.04367],
              [8.15972, 50.04353],
              [8.15955, 50.04346],
              [8.15927, 50.04281],
              [8.15901, 50.0424],
              [8.15809, 50.04139],
              [8.1577, 50.04105],
              [8.1575, 50.0409],
              [8.1572, 50.04068],
              [8.1569, 50.04046],
              [8.15656, 50.04025],
              [8.15559, 50.03969],
              [8.15469, 50.03923],
              [8.15445, 50.03906],
              [8.15417, 50.03866],
              [8.15427, 50.0383],
              [8.15438, 50.03817],
              [8.15482, 50.03793],
              [8.15551, 50.03765],
              [8.15588, 50.03743],
              [8.15621, 50.03748],
              [8.15778, 50.037],
              [8.15811, 50.03679],
              [8.1585, 50.03653],
              [8.1584, 50.036],
              [8.1571, 50.03628],
              [8.15657, 50.0364],
              [8.15613, 50.03648],
              [8.15605, 50.0365],
              [8.15522, 50.03672],
              [8.15341, 50.03744],
              [8.15274, 50.03778],
              [8.15224, 50.03803],
              [8.15179, 50.0382],
              [8.151198, 50.038421],
              [8.15077, 50.03858],
              [8.14827, 50.03989],
              [8.14691, 50.04092],
              [8.14436, 50.04195],
              [8.14416, 50.04202],
              [8.14333, 50.04232],
              [8.14272, 50.04257],
              [8.1423, 50.04274],
              [8.1425, 50.04307],
              [8.14252, 50.04346],
              [8.14283, 50.04374],
              [8.14344, 50.04387],
              [8.14382, 50.04407],
              [8.14401, 50.04455],
              [8.14446, 50.04497],
              [8.14494, 50.04541],
              [8.14323, 50.04574],
              [8.14286, 50.04581],
              [8.142, 50.04598],
              [8.14139, 50.04609],
              [8.13964, 50.0464],
              [8.13851, 50.04661],
              [8.13286, 50.04765],
              [8.13116, 50.04804],
              [8.12923, 50.04887],
              [8.12811, 50.04946],
              [8.12735, 50.04987],
              [8.12683, 50.05014],
              [8.12548, 50.05084],
              [8.12501, 50.05108],
              [8.12301, 50.05212],
              [8.12245, 50.05249],
              [8.12204, 50.0529],
              [8.12131, 50.05381],
              [8.12106, 50.054],
              [8.12013, 50.05447],
              [8.11988, 50.0546],
              [8.1196, 50.05486],
              [8.11935, 50.05557],
              [8.1191, 50.05674],
              [8.11904, 50.05697],
              [8.11874, 50.05828],
              [8.11685, 50.06094],
              [8.11571, 50.0622],
              [8.11284, 50.0649],
              [8.11186, 50.06586],
              [8.11004, 50.06873],
              [8.10957, 50.06959],
              [8.10946, 50.0704],
              [8.10943, 50.07101],
              [8.10934, 50.07267],
              [8.1093, 50.07333],
              [8.1092, 50.0749],
              [8.10911, 50.07683],
              [8.1093, 50.0773],
              [8.10981, 50.07783],
              [8.11037, 50.07833],
              [8.11072, 50.07862],
              [8.11083, 50.07877],
              [8.11124, 50.07946],
              [8.11128, 50.07965],
              [8.11145, 50.08123],
              [8.11154, 50.08152],
              [8.11207, 50.08302],
              [8.11222, 50.08415],
              [8.11214, 50.08501],
              [8.11178, 50.08612],
              [8.11109, 50.0872],
              [8.10928, 50.08925],
              [8.10883, 50.08997],
              [8.10855, 50.09059],
              [8.10818, 50.09141],
              [8.10787, 50.09191],
              [8.10757, 50.09232],
              [8.10739, 50.09256],
              [8.10721, 50.09278],
              [8.10695, 50.09311],
              [8.10663, 50.09355],
              [8.10654, 50.09372],
              [8.10627, 50.09435],
              [8.10582, 50.09563],
              [8.10525, 50.09661],
              [8.10479, 50.09766],
              [8.10472, 50.09849],
              [8.10487, 50.09906],
              [8.10505, 50.0994],
              [8.10561, 50.10032],
              [8.10573, 50.10073],
              [8.10564, 50.10138],
              [8.10518, 50.10207],
              [8.10371, 50.1032],
              [8.10328, 50.10351],
              [8.10308, 50.10366],
              [8.102758, 50.103982],
              [8.10246, 50.10428],
              [8.10237, 50.10444],
              [8.102216, 50.104795],
              [8.101684, 50.105869],
              [8.10131, 50.10618],
              [8.10039, 50.10683],
              [8.09961, 50.10738],
              [8.09925, 50.10763],
              [8.09854, 50.10812],
              [8.09801, 50.10853],
              [8.09742, 50.10893],
              [8.09712, 50.10919],
              [8.096958, 50.109527],
              [8.09662, 50.11023],
              [8.09626, 50.11111],
              [8.09604, 50.11186],
              [8.09608, 50.11227],
              [8.09615, 50.112764],
              [8.096104, 50.112882],
              [8.095951, 50.112965],
              [8.096347, 50.113297],
              [8.096375, 50.1135],
              [8.096509, 50.113906],
              [8.096504, 50.114165],
              [8.096489, 50.114424],
              [8.096519, 50.114557],
              [8.09663, 50.11461],
              [8.09654, 50.11512],
              [8.09658, 50.11583],
              [8.09638, 50.1161],
              [8.09609, 50.11633],
              [8.09476, 50.11738],
              [8.09319, 50.11867],
              [8.09135, 50.11985],
              [8.09024, 50.11996],
              [8.08846, 50.12073],
              [8.08593, 50.12182],
              [8.08511, 50.12205],
              [8.08385, 50.12239],
              [8.08336, 50.12261],
              [8.08306, 50.12257],
              [8.08336, 50.12261],
              [8.08338, 50.12277],
              [8.08334, 50.1228],
              [8.08305, 50.12282],
              [8.08286, 50.123],
              [8.08284, 50.12336],
              [8.08279, 50.12397],
              [8.08251, 50.12464],
              [8.08117, 50.12598],
              [8.07705, 50.12991],
              [8.07554, 50.13127],
              [8.07359, 50.13197],
              [8.07051, 50.13223],
              [8.06874, 50.13263],
              [8.06798, 50.13279],
              [8.06741, 50.13291],
              [8.06707, 50.13299],
              [8.06671, 50.13306],
              [8.06636, 50.1331],
              [8.06514, 50.13298],
              [8.06414, 50.13275],
              [8.06383, 50.13286],
              [8.06382, 50.13305],
              [8.06437, 50.13362],
              [8.0649, 50.13407],
              [8.06513, 50.13426],
              [8.06537, 50.13447],
              [8.06702, 50.13557],
              [8.06779, 50.13621],
              [8.06821, 50.13661],
              [8.06855, 50.13707],
              [8.06857, 50.13722],
              [8.06861, 50.1374],
              [8.06918, 50.13843],
              [8.06915, 50.13854],
              [8.06912, 50.13861],
              [8.06882, 50.13887],
              [8.06836, 50.13925],
              [8.06821, 50.13938],
              [8.06816, 50.13942],
              [8.06804, 50.13952],
              [8.06721, 50.14014],
              [8.06699, 50.14028],
              [8.06746, 50.14037],
              [8.06888, 50.14043],
              [8.06912, 50.14041],
              [8.06951, 50.14027],
              [8.07015, 50.14075],
              [8.0709, 50.14114],
              [8.071901, 50.141777],
              [8.07222, 50.14198],
              [8.0724, 50.14209],
              [8.0725, 50.14218],
              [8.07317, 50.14298],
              [8.07444, 50.14393],
              [8.07466, 50.14407],
              [8.07548, 50.14451],
              [8.07569, 50.14463],
              [8.07586, 50.14474],
              [8.07719, 50.14558],
              [8.07737, 50.14568],
              [8.07889, 50.14668],
              [8.07922, 50.14692],
              [8.08133, 50.14836],
              [8.0835, 50.1499],
            ],
          ],
        },
        id: "EwNjI",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.0835, 50.1499],
              [8.08133, 50.14836],
              [8.07922, 50.14692],
              [8.07889, 50.14668],
              [8.07737, 50.14568],
              [8.07719, 50.14558],
              [8.07586, 50.14474],
              [8.07569, 50.14463],
              [8.07548, 50.14451],
              [8.07466, 50.14407],
              [8.07444, 50.14393],
              [8.07317, 50.14298],
              [8.0725, 50.14218],
              [8.0724, 50.14209],
              [8.07222, 50.14198],
              [8.071901, 50.141777],
              [8.0709, 50.14114],
              [8.07015, 50.14075],
              [8.06951, 50.14027],
              [8.06912, 50.14041],
              [8.06888, 50.14043],
              [8.06746, 50.14037],
              [8.06699, 50.14028],
              [8.06721, 50.14014],
              [8.06804, 50.13952],
              [8.06816, 50.13942],
              [8.06821, 50.13938],
              [8.06836, 50.13925],
              [8.06882, 50.13887],
              [8.06912, 50.13861],
              [8.06915, 50.13854],
              [8.06918, 50.13843],
              [8.06861, 50.1374],
              [8.06857, 50.13722],
              [8.06855, 50.13707],
              [8.06821, 50.13661],
              [8.06779, 50.13621],
              [8.06702, 50.13557],
              [8.06537, 50.13447],
              [8.06513, 50.13426],
              [8.0649, 50.13407],
              [8.06437, 50.13362],
              [8.06382, 50.13305],
              [8.06383, 50.13286],
              [8.06414, 50.13275],
              [8.06514, 50.13298],
              [8.06636, 50.1331],
              [8.06671, 50.13306],
              [8.06707, 50.13299],
              [8.06741, 50.13291],
              [8.06798, 50.13279],
              [8.06874, 50.13263],
              [8.07051, 50.13223],
              [8.07359, 50.13197],
              [8.07554, 50.13127],
              [8.07705, 50.12991],
              [8.08117, 50.12598],
              [8.08251, 50.12464],
              [8.08279, 50.12397],
              [8.08284, 50.12336],
              [8.08286, 50.123],
              [8.08305, 50.12282],
              [8.08334, 50.1228],
              [8.08338, 50.12277],
              [8.08336, 50.12261],
              [8.08306, 50.12257],
              [8.08336, 50.12261],
              [8.08385, 50.12239],
              [8.08511, 50.12205],
              [8.08593, 50.12182],
              [8.08846, 50.12073],
              [8.09024, 50.11996],
              [8.09135, 50.11985],
              [8.09319, 50.11867],
              [8.09476, 50.11738],
              [8.09609, 50.11633],
              [8.09638, 50.1161],
              [8.09658, 50.11583],
              [8.09654, 50.11512],
              [8.09663, 50.11461],
              [8.096519, 50.114557],
              [8.096489, 50.114424],
              [8.096504, 50.114165],
              [8.096509, 50.113906],
              [8.096375, 50.1135],
              [8.096347, 50.113297],
              [8.095951, 50.112965],
              [8.096104, 50.112882],
              [8.09615, 50.112764],
              [8.09608, 50.11227],
              [8.09604, 50.11186],
              [8.09626, 50.11111],
              [8.09662, 50.11023],
              [8.096958, 50.109527],
              [8.09712, 50.10919],
              [8.09742, 50.10893],
              [8.09801, 50.10853],
              [8.09854, 50.10812],
              [8.09925, 50.10763],
              [8.09961, 50.10738],
              [8.10039, 50.10683],
              [8.10131, 50.10618],
              [8.101684, 50.105869],
              [8.102216, 50.104795],
              [8.10237, 50.10444],
              [8.10246, 50.10428],
              [8.102758, 50.103982],
              [8.10308, 50.10366],
              [8.10328, 50.10351],
              [8.10371, 50.1032],
              [8.10518, 50.10207],
              [8.10564, 50.10138],
              [8.10573, 50.10073],
              [8.10561, 50.10032],
              [8.10505, 50.0994],
              [8.10487, 50.09906],
              [8.10472, 50.09849],
              [8.10479, 50.09766],
              [8.10525, 50.09661],
              [8.10582, 50.09563],
              [8.10627, 50.09435],
              [8.10654, 50.09372],
              [8.10663, 50.09355],
              [8.10695, 50.09311],
              [8.10721, 50.09278],
              [8.10739, 50.09256],
              [8.10757, 50.09232],
              [8.10787, 50.09191],
              [8.10818, 50.09141],
              [8.10855, 50.09059],
              [8.10883, 50.08997],
              [8.10928, 50.08925],
              [8.11109, 50.0872],
              [8.11178, 50.08612],
              [8.11214, 50.08501],
              [8.11222, 50.08415],
              [8.11207, 50.08302],
              [8.11154, 50.08152],
              [8.11145, 50.08123],
              [8.11128, 50.07965],
              [8.11124, 50.07946],
              [8.11083, 50.07877],
              [8.11072, 50.07862],
              [8.11037, 50.07833],
              [8.10981, 50.07783],
              [8.1093, 50.0773],
              [8.10911, 50.07683],
              [8.1092, 50.0749],
              [8.1093, 50.07333],
              [8.10934, 50.07267],
              [8.10943, 50.07101],
              [8.10946, 50.0704],
              [8.10957, 50.06959],
              [8.11004, 50.06873],
              [8.11186, 50.06586],
              [8.11284, 50.0649],
              [8.11571, 50.0622],
              [8.11685, 50.06094],
              [8.11874, 50.05828],
              [8.11904, 50.05697],
              [8.1191, 50.05674],
              [8.11935, 50.05557],
              [8.1196, 50.05486],
              [8.11988, 50.0546],
              [8.12013, 50.05447],
              [8.12106, 50.054],
              [8.12131, 50.05381],
              [8.12204, 50.0529],
              [8.12245, 50.05249],
              [8.12301, 50.05212],
              [8.12501, 50.05108],
              [8.12548, 50.05084],
              [8.12683, 50.05014],
              [8.12735, 50.04987],
              [8.12811, 50.04946],
              [8.12923, 50.04887],
              [8.13116, 50.04804],
              [8.13286, 50.04765],
              [8.13851, 50.04661],
              [8.13964, 50.0464],
              [8.14139, 50.04609],
              [8.142, 50.04598],
              [8.14286, 50.04581],
              [8.14323, 50.04574],
              [8.14494, 50.04541],
              [8.14446, 50.04497],
              [8.14401, 50.04455],
              [8.14382, 50.04407],
              [8.14344, 50.04387],
              [8.14283, 50.04374],
              [8.14252, 50.04346],
              [8.1425, 50.04307],
              [8.1423, 50.04274],
              [8.14272, 50.04257],
              [8.14333, 50.04232],
              [8.14416, 50.04202],
              [8.14436, 50.04195],
              [8.14691, 50.04092],
              [8.14827, 50.03989],
              [8.15077, 50.03858],
              [8.151198, 50.038421],
              [8.15179, 50.0382],
              [8.15224, 50.03803],
              [8.15274, 50.03778],
              [8.15341, 50.03744],
              [8.15522, 50.03672],
              [8.15605, 50.0365],
              [8.15613, 50.03648],
              [8.15657, 50.0364],
              [8.1571, 50.03628],
              [8.1584, 50.036],
              [8.1585, 50.03653],
              [8.15811, 50.03679],
              [8.15778, 50.037],
              [8.15621, 50.03748],
              [8.15588, 50.03743],
              [8.15551, 50.03765],
              [8.15482, 50.03793],
              [8.15438, 50.03817],
              [8.15427, 50.0383],
              [8.15417, 50.03866],
              [8.15445, 50.03906],
              [8.15469, 50.03923],
              [8.15559, 50.03969],
              [8.15656, 50.04025],
              [8.1569, 50.04046],
              [8.1572, 50.04068],
              [8.1575, 50.0409],
              [8.1577, 50.04105],
              [8.15809, 50.04139],
              [8.15901, 50.0424],
              [8.15927, 50.04281],
              [8.15955, 50.04346],
              [8.15972, 50.04353],
              [8.15962, 50.04367],
              [8.15988, 50.04441],
              [8.16079, 50.0444],
              [8.16201, 50.04489],
              [8.16209, 50.04489],
              [8.16216, 50.04489],
              [8.16274, 50.04443],
              [8.16404, 50.04322],
              [8.16413, 50.04316],
              [8.16443, 50.04298],
              [8.16524, 50.04282],
              [8.16583, 50.04277],
              [8.16672, 50.04256],
              [8.1669, 50.04263],
              [8.1671, 50.04252],
              [8.16922, 50.0431],
              [8.16942, 50.0431],
              [8.17296, 50.04384],
              [8.17365, 50.04395],
              [8.17526, 50.04412],
              [8.1763, 50.04418],
              [8.17787, 50.04428],
              [8.18368, 50.04457],
              [8.18471, 50.04463],
              [8.18518, 50.04465],
              [8.18751, 50.04477],
              [8.19042, 50.04504],
              [8.19174, 50.04524],
              [8.19219, 50.04532],
              [8.19392, 50.04562],
              [8.19456, 50.04562],
              [8.19504, 50.04508],
              [8.19513, 50.04504],
              [8.19537, 50.04497],
              [8.19566, 50.04488],
              [8.19611, 50.04466],
              [8.19626, 50.04461],
              [8.19703, 50.04454],
              [8.19717, 50.04454],
              [8.19766, 50.04455],
              [8.19796, 50.04455],
              [8.19938, 50.0446],
              [8.20059, 50.04463],
              [8.20151, 50.04462],
              [8.20189, 50.04456],
              [8.2027, 50.0444],
              [8.20368, 50.04418],
              [8.20476, 50.04394],
              [8.208261, 50.043171],
              [8.20931, 50.04294],
              [8.20973, 50.04305],
              [8.20986, 50.04316],
              [8.21005, 50.04369],
              [8.21014, 50.04393],
              [8.21068, 50.04454],
              [8.21089, 50.04466],
              [8.21165, 50.04489],
              [8.21244, 50.04496],
              [8.212811, 50.044907],
              [8.212911, 50.044799],
              [8.212794, 50.043744],
              [8.211283, 50.032311],
              [8.211068, 50.030743],
              [8.211116, 50.030513],
              [8.211376, 50.03025],
              [8.211563, 50.029868],
              [8.21151, 50.02942],
              [8.21143, 50.02878],
              [8.21139, 50.02815],
              [8.21136, 50.02788],
              [8.21128, 50.02733],
              [8.21121, 50.02689],
              [8.2111, 50.02623],
              [8.21059, 50.02422],
              [8.20958, 50.02165],
              [8.20915, 50.0208],
              [8.20799, 50.01891],
              [8.20613, 50.01648],
              [8.20379, 50.014],
              [8.2033, 50.01353],
              [8.20207, 50.01242],
              [8.20163, 50.01205],
              [8.2007, 50.01129],
              [8.19945, 50.0103],
              [8.19822, 50.00943],
              [8.19522, 50.00748],
              [8.19118, 50.00531],
              [8.18801, 50.00388],
              [8.18768, 50.00374],
              [8.18612, 50.00307],
              [8.18587, 50.00302],
              [8.18469, 50.00263],
              [8.18336, 50.00214],
              [8.18291, 50.00187],
              [8.1824, 50.00137],
              [8.18218, 50.00082],
              [8.18223, 50.00017],
              [8.18247, 49.99974],
              [8.18268, 49.99949],
              [8.1833, 49.99903],
              [8.18368, 49.99886],
              [8.18388, 49.99879],
              [8.18527, 49.99816],
              [8.18577, 49.99768],
              [8.18645, 49.99668],
              [8.18663, 49.99635],
              [8.18701, 49.99559],
              [8.18762, 49.99434],
              [8.18807, 49.99344],
              [8.18894, 49.99199],
              [8.1907, 49.99006],
              [8.19087, 49.98991],
              [8.19141, 49.98939],
              [8.19174, 49.98903],
              [8.19193, 49.98882],
              [8.19228, 49.98775],
              [8.19262, 49.98721],
              [8.19298, 49.98695],
              [8.19368, 49.98665],
              [8.19549, 49.98635],
              [8.19685, 49.98632],
              [8.19782, 49.98645],
              [8.19876, 49.98689],
              [8.19906, 49.98718],
              [8.19954, 49.98813],
              [8.20016, 49.98857],
              [8.20091, 49.98881],
              [8.2013, 49.98887],
              [8.20283, 49.98906],
              [8.20416, 49.9892],
              [8.20557, 49.98936],
              [8.20626, 49.98944],
              [8.20755, 49.98959],
              [8.20771, 49.98961],
              [8.21299, 49.99032],
              [8.21549, 49.9907],
              [8.21625, 49.99081],
              [8.21716, 49.99093],
              [8.21776, 49.99103],
              [8.21815, 49.99108],
              [8.21881, 49.99102],
              [8.21891, 49.991],
              [8.21908, 49.991],
              [8.21934, 49.9911],
              [8.22012, 49.99141],
              [8.225368, 49.992318],
              [8.227, 49.9926],
              [8.22788, 49.99275],
              [8.23024, 49.9932],
              [8.23553, 49.99424],
              [8.24226, 49.99573],
              [8.2425, 49.99572],
              [8.24534, 49.99634],
              [8.246306, 49.996537],
              [8.24716, 49.99671],
              [8.24804, 49.99692],
              [8.24917, 49.99706],
              [8.25106, 49.99725],
              [8.25276, 49.99729],
              [8.25565, 49.99745],
              [8.2559, 49.99749],
              [8.25636, 49.99779],
              [8.25654, 49.99797],
              [8.25727, 49.99879],
              [8.2576, 49.99905],
              [8.25805, 49.99925],
              [8.25829, 49.99933],
              [8.258525, 49.999387],
              [8.25846, 49.99967],
              [8.25889, 49.99971],
              [8.25909, 49.99976],
              [8.25994, 50.00007],
              [8.26052, 50.00034],
              [8.25989, 50.00102],
              [8.25996, 50.00114],
              [8.26012, 50.00141],
              [8.26124, 50.00131],
              [8.2614, 50.00124],
              [8.26256, 50.00077],
              [8.26337, 50.00046],
              [8.26386, 50.00023],
              [8.25975, 50.00174],
              [8.259848, 50.001642],
              [8.260004, 50.001394],
              [8.259978, 50.00123],
              [8.259912, 50.0011],
              [8.259941, 50.000972],
              [8.260124, 50.000729],
              [8.259955, 50.000972],
              [8.259966, 50.00115],
              [8.260088, 50.001297],
              [8.260245, 50.001364],
              [8.260506, 50.001397],
              [8.261071, 50.001361],
              [8.26159, 50.001195],
              [8.26337, 50.000453],
              [8.266871, 49.998641],
              [8.26337, 50.000453],
              [8.26159, 50.001195],
              [8.261071, 50.001361],
              [8.260506, 50.001397],
              [8.260245, 50.001364],
              [8.260088, 50.001297],
              [8.259966, 50.00115],
              [8.259955, 50.000972],
              [8.260124, 50.000729],
              [8.259941, 50.000972],
              [8.259912, 50.0011],
              [8.259978, 50.00123],
              [8.260004, 50.001394],
              [8.259848, 50.001642],
              [8.25975, 50.00174],
              [8.26386, 50.00023],
              [8.26412, 50.00017],
              [8.26437, 50.00014],
              [8.26534, 49.99961],
              [8.26553, 49.99954],
              [8.26585, 49.99931],
              [8.267, 49.99856],
              [8.26744, 49.99822],
              [8.26775, 49.99783],
              [8.26835, 49.99799],
              [8.2694, 49.99833],
              [8.27, 49.99852],
              [8.27072, 49.99873],
              [8.27227, 49.99919],
              [8.272044, 49.999506],
              [8.27174, 49.99993],
              [8.27188, 50.00026],
              [8.27196, 50.00058],
              [8.27264, 50.00063],
              [8.2732, 50.0007],
              [8.27341, 50.00074],
              [8.27362, 50.00079],
              [8.2756, 50.00144],
              [8.27487, 50.00243],
              [8.27452, 50.00282],
              [8.27445, 50.00291],
            ],
          ],
        },
        id: "czNjg",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.EXPRESS,
    id: "UwOTA",
    name: "X76 Wiesbaden <> Nastaetten",
    lineName: "X76",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [7.853471, 50.199294],
              [7.85391, 50.1991],
              [7.8544, 50.19954],
              [7.85559, 50.19891],
              [7.8562, 50.1986],
              [7.85717, 50.19806],
              [7.857536, 50.197903],
              [7.85794, 50.19773],
              [7.85838, 50.19803],
              [7.85911, 50.19853],
              [7.8591, 50.19864],
              [7.8602, 50.19888],
              [7.86104, 50.19909],
              [7.8613, 50.19911],
              [7.86265, 50.19887],
              [7.86315, 50.19894],
              [7.86379, 50.19908],
              [7.864445, 50.199268],
              [7.86588, 50.19968],
              [7.86638, 50.19999],
              [7.86659, 50.2003],
              [7.86687, 50.20114],
              [7.86728, 50.20169],
              [7.86829, 50.20227],
              [7.86897, 50.20251],
              [7.87027, 50.20297],
              [7.87116, 50.20328],
              [7.87249, 50.20374],
              [7.87516, 50.20462],
              [7.87576, 50.2047],
              [7.876987, 50.204836],
              [7.87766, 50.20491],
              [7.87783, 50.20493],
              [7.87852, 50.20506],
              [7.88037, 50.20552],
              [7.88087, 50.20565],
              [7.88159, 50.20596],
              [7.88197, 50.20644],
              [7.88271, 50.20811],
              [7.8829, 50.20853],
              [7.88343, 50.20945],
              [7.88435, 50.20997],
              [7.8869, 50.21095],
              [7.88985, 50.21322],
              [7.89359, 50.21608],
              [7.89469, 50.21681],
              [7.89941, 50.21892],
              [7.90115, 50.21947],
              [7.90245, 50.21985],
              [7.90308, 50.22002],
              [7.90353, 50.22013],
              [7.90426, 50.2202],
              [7.90504, 50.22017],
              [7.90566, 50.21947],
              [7.906237, 50.218853],
              [7.90679, 50.21826],
              [7.90846, 50.21713],
              [7.91055, 50.21577],
              [7.91121, 50.21534],
              [7.91216, 50.21473],
              [7.91289, 50.21425],
              [7.91462, 50.21312],
              [7.91458, 50.21302],
              [7.91512, 50.21209],
              [7.9161, 50.2106],
              [7.91913, 50.20833],
              [7.91913, 50.20738],
              [7.91914, 50.20648],
              [7.9194, 50.20577],
              [7.92004, 50.20489],
              [7.92107, 50.20379],
              [7.92118, 50.20361],
              [7.9212, 50.20305],
              [7.92127, 50.2027],
              [7.92131, 50.20262],
              [7.918388, 50.210351],
              [7.92131, 50.20262],
              [7.92127, 50.2027],
              [7.9212, 50.20305],
              [7.92118, 50.20361],
              [7.92107, 50.20379],
              [7.92004, 50.20489],
              [7.9194, 50.20577],
              [7.91914, 50.20648],
              [7.91913, 50.20738],
              [7.91913, 50.20833],
              [7.91913, 50.20738],
              [7.91914, 50.20648],
              [7.9194, 50.20577],
              [7.92004, 50.20489],
              [7.92107, 50.20379],
              [7.92118, 50.20361],
              [7.9212, 50.20305],
              [7.92127, 50.2027],
              [7.92131, 50.20262],
              [7.9215, 50.20243],
              [7.9217, 50.20225],
              [7.92282, 50.20171],
              [7.9242, 50.20121],
              [7.92573, 50.20063],
              [7.92594, 50.20056],
              [7.92995, 50.19978],
              [7.93036, 50.1997],
              [7.93058, 50.19966],
              [7.93147, 50.19948],
              [7.93253, 50.19896],
              [7.93346, 50.19856],
              [7.93369, 50.1985],
              [7.934134, 50.198458],
              [7.93516, 50.19836],
              [7.93613, 50.19801],
              [7.93735, 50.19775],
              [7.9382, 50.19755],
              [7.93874, 50.19751],
              [7.94151, 50.19827],
              [7.94196, 50.19837],
              [7.94319, 50.1985],
              [7.94394, 50.19837],
              [7.94553, 50.19805],
              [7.94615, 50.1983],
              [7.94665, 50.19822],
              [7.94788, 50.19733],
              [7.94806, 50.19692],
              [7.94773, 50.1966],
              [7.94705, 50.1962],
              [7.94388, 50.19516],
              [7.94353, 50.19496],
              [7.94351, 50.19488],
              [7.94417, 50.19396],
              [7.94495, 50.19332],
              [7.94638, 50.19257],
              [7.94748, 50.1918],
              [7.9483, 50.19043],
              [7.9488, 50.19004],
              [7.94958, 50.18984],
              [7.94968, 50.18983],
              [7.95042, 50.18973],
              [7.95197, 50.18959],
              [7.95274, 50.18967],
              [7.95469, 50.18985],
              [7.95481, 50.18985],
              [7.9557, 50.18974],
              [7.95645, 50.18957],
              [7.95823, 50.18877],
              [7.95959, 50.1881],
              [7.96042, 50.1874],
              [7.96237, 50.18632],
              [7.96341, 50.18611],
              [7.96793, 50.18637],
              [7.96887, 50.18612],
              [7.96933, 50.18652],
              [7.96989, 50.18581],
              [7.97069, 50.18508],
              [7.97274, 50.18429],
              [7.97679, 50.18286],
              [7.98283, 50.18061],
              [7.98364, 50.18031],
              [7.984202, 50.180107],
              [7.98475, 50.17991],
              [7.99898, 50.17478],
              [8.00099, 50.17404],
              [8.00238, 50.17355],
              [8.003051, 50.173298],
              [8.00387, 50.17299],
              [8.00525, 50.17249],
              [8.00805, 50.17151],
              [8.01028, 50.17075],
              [8.01105, 50.1713],
              [8.012, 50.17165],
              [8.01225, 50.17169],
              [8.01279, 50.17038],
              [8.01302, 50.16974],
              [8.01314, 50.16947],
              [8.01354, 50.16903],
              [8.0135, 50.16897],
              [8.01356, 50.1687],
              [8.01363, 50.16857],
              [8.0137, 50.16846],
              [8.01388, 50.16818],
              [8.01429, 50.16754],
              [8.01461, 50.16712],
              [8.01542, 50.16692],
              [8.01652, 50.1665],
              [8.01795, 50.16594],
              [8.01652, 50.1665],
              [8.01795, 50.16594],
              [8.01809, 50.16584],
              [8.01826, 50.16528],
              [8.01883, 50.16495],
              [8.01888, 50.16485],
              [8.01893, 50.16408],
              [8.01895, 50.1639],
              [8.0192, 50.16323],
              [8.01959, 50.16268],
              [8.01987, 50.16232],
              [8.02008, 50.16206],
              [8.020468, 50.161597],
              [8.02081, 50.16119],
              [8.02092, 50.16106],
              [8.02114, 50.1608],
              [8.02163, 50.16019],
              [8.02125, 50.15998],
              [8.02127, 50.15988],
              [8.021496, 50.15966],
              [8.02127, 50.15988],
              [8.02125, 50.15998],
              [8.02163, 50.16019],
              [8.02217, 50.15985],
              [8.02264, 50.15986],
              [8.02282, 50.15935],
              [8.023, 50.15897],
              [8.02364, 50.15806],
              [8.02571, 50.15603],
              [8.02743, 50.1545],
              [8.0292, 50.15301],
              [8.03061, 50.15201],
              [8.03278, 50.15079],
              [8.03365, 50.1504],
              [8.03771, 50.14867],
              [8.03791, 50.14856],
              [8.03917, 50.14761],
              [8.03955, 50.14721],
              [8.04173, 50.14441],
              [8.04271, 50.14297],
              [8.043, 50.1425],
              [8.04327, 50.14201],
              [8.04393, 50.14086],
              [8.04415, 50.13983],
              [8.04424, 50.1391],
              [8.04431, 50.13874],
              [8.04477, 50.1374],
              [8.04498, 50.13702],
              [8.04628, 50.13469],
              [8.04658, 50.13379],
              [8.04646, 50.13293],
              [8.04628, 50.13232],
              [8.04629, 50.13141],
              [8.04657, 50.1308],
              [8.04752, 50.12952],
              [8.04886, 50.12795],
              [8.04946, 50.1275],
              [8.05027, 50.12706],
              [8.05121, 50.12669],
              [8.05209, 50.12631],
              [8.0529, 50.12584],
              [8.05364, 50.12515],
              [8.05398, 50.12458],
              [8.05418, 50.12374],
              [8.05408, 50.12306],
              [8.05338, 50.12172],
              [8.05298, 50.12079],
              [8.05287, 50.11994],
              [8.053, 50.11905],
              [8.05337, 50.11818],
              [8.0541, 50.11726],
              [8.05458, 50.11684],
              [8.05494, 50.11657],
              [8.05908, 50.11434],
              [8.0606, 50.11369],
              [8.0621, 50.11339],
              [8.06357, 50.11338],
              [8.06497, 50.11361],
              [8.07645, 50.11745],
              [8.07821, 50.11806],
              [8.07952, 50.11845],
              [8.08084, 50.11867],
              [8.08221, 50.1187],
              [8.08269, 50.11869],
              [8.08359, 50.11861],
              [8.08526, 50.11834],
              [8.08626, 50.11819],
              [8.0905, 50.11795],
              [8.0925, 50.1178],
              [8.09381, 50.11749],
              [8.09488, 50.117],
              [8.09563, 50.11641],
              [8.09591, 50.11609],
              [8.09604, 50.11592],
              [8.09632, 50.11533],
              [8.09637, 50.1151],
              [8.09639, 50.11465],
              [8.09608, 50.11227],
              [8.09604, 50.11186],
              [8.09626, 50.11111],
              [8.09662, 50.11023],
              [8.096958, 50.109527],
              [8.09712, 50.10919],
              [8.09742, 50.10893],
              [8.09801, 50.10853],
              [8.09854, 50.10812],
              [8.09925, 50.10763],
              [8.09961, 50.10738],
              [8.10039, 50.10683],
              [8.10131, 50.10618],
              [8.101684, 50.105869],
              [8.102216, 50.104795],
              [8.10237, 50.10444],
              [8.10246, 50.10428],
              [8.102758, 50.103982],
              [8.10308, 50.10366],
              [8.10328, 50.10351],
              [8.10371, 50.1032],
              [8.10518, 50.10207],
              [8.10564, 50.10138],
              [8.10573, 50.10073],
              [8.10561, 50.10032],
              [8.10505, 50.0994],
              [8.10487, 50.09906],
              [8.10472, 50.09849],
              [8.10479, 50.09766],
              [8.10525, 50.09661],
              [8.10582, 50.09563],
              [8.10627, 50.09435],
              [8.10654, 50.09372],
              [8.10663, 50.09355],
              [8.10702, 50.09322],
              [8.1077, 50.09306],
              [8.1081, 50.09291],
              [8.10832, 50.09261],
              [8.10831, 50.09217],
              [8.10796, 50.09184],
              [8.1093, 50.09087],
              [8.10982, 50.09016],
              [8.11033, 50.08964],
              [8.11135, 50.08911],
              [8.11267, 50.08822],
              [8.1133, 50.08799],
              [8.11422, 50.08801],
              [8.11565, 50.08813],
              [8.11607, 50.08821],
              [8.11617, 50.08823],
              [8.11678, 50.08837],
              [8.11768, 50.08861],
              [8.11869, 50.08883],
              [8.11891, 50.08883],
              [8.11928, 50.0888],
              [8.12049, 50.08861],
              [8.12084, 50.08851],
              [8.121362, 50.088359],
              [8.12239, 50.08806],
              [8.12334, 50.08783],
              [8.12392, 50.08771],
              [8.12404, 50.08767],
              [8.12491, 50.08744],
              [8.12544, 50.08748],
              [8.12572, 50.08766],
              [8.12596, 50.08796],
              [8.12775, 50.08964],
              [8.12877, 50.0903],
              [8.12984, 50.09077],
              [8.13167, 50.09122],
              [8.13211, 50.0913],
              [8.13888, 50.09258],
              [8.14078, 50.09323],
              [8.14197, 50.09404],
              [8.14293, 50.09437],
              [8.14346, 50.0944],
              [8.14403, 50.09434],
              [8.14631, 50.09353],
              [8.1476, 50.09339],
              [8.14886, 50.09354],
              [8.15128, 50.09397],
              [8.15427, 50.0942],
              [8.1565, 50.09484],
              [8.158, 50.09539],
              [8.15994, 50.09642],
              [8.16206, 50.09695],
              [8.16485, 50.09749],
              [8.16538, 50.09749],
              [8.16624, 50.09739],
              [8.16751, 50.09736],
              [8.16793, 50.09744],
              [8.16821, 50.09748],
              [8.16859, 50.09735],
              [8.16884, 50.09726],
              [8.16956, 50.09712],
              [8.16975, 50.09714],
              [8.16996, 50.09718],
              [8.17061, 50.09734],
              [8.17069, 50.09736],
              [8.17237, 50.09776],
              [8.17343, 50.09798],
              [8.17475, 50.09808],
              [8.17865, 50.09775],
              [8.18369, 50.09723],
              [8.18571, 50.09686],
              [8.18606, 50.09676],
              [8.18784, 50.09623],
              [8.19045, 50.09532],
              [8.19069, 50.09523],
              [8.19275, 50.09449],
              [8.19415, 50.09438],
              [8.19563, 50.09438],
              [8.19678, 50.09438],
              [8.19814, 50.09438],
              [8.19832, 50.09439],
              [8.20092, 50.09446],
              [8.20189, 50.09425],
              [8.20235, 50.09409],
              [8.20429, 50.09337],
              [8.20731, 50.09091],
              [8.20814, 50.08999],
              [8.20899, 50.08856],
              [8.21016, 50.08631],
              [8.2108, 50.08524],
              [8.21091, 50.0851],
              [8.21117, 50.0848],
              [8.21175, 50.08428],
              [8.21206, 50.08406],
              [8.21374, 50.08322],
              [8.21522, 50.08265],
              [8.21553, 50.08232],
              [8.21621, 50.08235],
              [8.21747, 50.08199],
              [8.21777, 50.08189],
              [8.2183, 50.08162],
              [8.21884, 50.08148],
              [8.21901, 50.08143],
              [8.21934, 50.08132],
              [8.21973, 50.08116],
              [8.2206, 50.08078],
              [8.22111, 50.08055],
              [8.22227, 50.08002],
              [8.22394, 50.07924],
              [8.22422, 50.07969],
              [8.22433, 50.07999],
              [8.22461, 50.08059],
              [8.224955, 50.080535],
              [8.22604, 50.08036],
              [8.22687, 50.08041],
              [8.22757, 50.08043],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22983, 50.07742],
              [8.22986, 50.07721],
              [8.22989, 50.07706],
              [8.229975, 50.076791],
              [8.23143, 50.07437],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2405, 50.07147],
              [8.24084, 50.07159],
              [8.24196, 50.07164],
              [8.2424, 50.07166],
              [8.24311, 50.07169],
              [8.24328, 50.0717],
            ],
          ],
        },
        id: "UwOTA",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.243761, 50.071537],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.229975, 50.076791],
              [8.22989, 50.07706],
              [8.22986, 50.07721],
              [8.22983, 50.07742],
              [8.22974, 50.07827],
              [8.22959, 50.07946],
              [8.22949, 50.08052],
              [8.22757, 50.08043],
              [8.22687, 50.08041],
              [8.22604, 50.08036],
              [8.224955, 50.080535],
              [8.22461, 50.08059],
              [8.22433, 50.07999],
              [8.22422, 50.07969],
              [8.22394, 50.07924],
              [8.22227, 50.08002],
              [8.21884, 50.08148],
              [8.2183, 50.08162],
              [8.21777, 50.08189],
              [8.21747, 50.08199],
              [8.21621, 50.08235],
              [8.21553, 50.08232],
              [8.21522, 50.08265],
              [8.21374, 50.08322],
              [8.21206, 50.08406],
              [8.21175, 50.08428],
              [8.21117, 50.0848],
              [8.21091, 50.0851],
              [8.2108, 50.08524],
              [8.21016, 50.08631],
              [8.20899, 50.08856],
              [8.20814, 50.08999],
              [8.20731, 50.09091],
              [8.20429, 50.09337],
              [8.20235, 50.09409],
              [8.20189, 50.09425],
              [8.20092, 50.09446],
              [8.19832, 50.09439],
              [8.19814, 50.09438],
              [8.19678, 50.09438],
              [8.19563, 50.09438],
              [8.19415, 50.09438],
              [8.19275, 50.09449],
              [8.19069, 50.09523],
              [8.19045, 50.09532],
              [8.18784, 50.09623],
              [8.18606, 50.09676],
              [8.18571, 50.09686],
              [8.18369, 50.09723],
              [8.17865, 50.09775],
              [8.17475, 50.09808],
              [8.17343, 50.09798],
              [8.17237, 50.09776],
              [8.17069, 50.09736],
              [8.17061, 50.09734],
              [8.16996, 50.09718],
              [8.16975, 50.09714],
              [8.16956, 50.09712],
              [8.16884, 50.09726],
              [8.16859, 50.09735],
              [8.16821, 50.09748],
              [8.16793, 50.09744],
              [8.16751, 50.09736],
              [8.16624, 50.09739],
              [8.16538, 50.09749],
              [8.16485, 50.09749],
              [8.16206, 50.09695],
              [8.15994, 50.09642],
              [8.158, 50.09539],
              [8.1565, 50.09484],
              [8.15427, 50.0942],
              [8.15128, 50.09397],
              [8.14886, 50.09354],
              [8.1476, 50.09339],
              [8.14631, 50.09353],
              [8.14403, 50.09434],
              [8.14346, 50.0944],
              [8.14293, 50.09437],
              [8.14197, 50.09404],
              [8.14078, 50.09323],
              [8.13888, 50.09258],
              [8.13211, 50.0913],
              [8.13167, 50.09122],
              [8.12984, 50.09077],
              [8.12877, 50.0903],
              [8.12775, 50.08964],
              [8.12596, 50.08796],
              [8.12572, 50.08766],
              [8.12544, 50.08748],
              [8.12491, 50.08744],
              [8.12404, 50.08767],
              [8.12392, 50.08771],
              [8.12334, 50.08783],
              [8.12239, 50.08806],
              [8.121362, 50.088359],
              [8.12084, 50.08851],
              [8.12049, 50.08861],
              [8.11928, 50.0888],
              [8.11891, 50.08883],
              [8.11869, 50.08883],
              [8.11768, 50.08861],
              [8.11678, 50.08837],
              [8.11617, 50.08823],
              [8.11607, 50.08821],
              [8.11565, 50.08813],
              [8.11422, 50.08801],
              [8.1133, 50.08799],
              [8.11267, 50.08822],
              [8.11135, 50.08911],
              [8.11033, 50.08964],
              [8.10982, 50.09016],
              [8.1093, 50.09087],
              [8.10796, 50.09184],
              [8.10831, 50.09217],
              [8.10832, 50.09261],
              [8.1081, 50.09291],
              [8.1077, 50.09306],
              [8.10702, 50.09322],
              [8.10663, 50.09355],
              [8.10654, 50.09372],
              [8.10627, 50.09435],
              [8.10582, 50.09563],
              [8.10525, 50.09661],
              [8.10479, 50.09766],
              [8.10472, 50.09849],
              [8.10487, 50.09906],
              [8.10505, 50.0994],
              [8.10561, 50.10032],
              [8.10573, 50.10073],
              [8.10564, 50.10138],
              [8.10518, 50.10207],
              [8.10371, 50.1032],
              [8.10328, 50.10351],
              [8.10308, 50.10366],
              [8.102758, 50.103982],
              [8.10246, 50.10428],
              [8.10237, 50.10444],
              [8.102216, 50.104795],
              [8.101684, 50.105869],
              [8.10131, 50.10618],
              [8.10039, 50.10683],
              [8.09961, 50.10738],
              [8.09925, 50.10763],
              [8.09854, 50.10812],
              [8.09801, 50.10853],
              [8.09742, 50.10893],
              [8.09712, 50.10919],
              [8.096958, 50.109527],
              [8.09662, 50.11023],
              [8.09626, 50.11111],
              [8.09604, 50.11186],
              [8.09608, 50.11227],
              [8.09639, 50.11465],
              [8.09637, 50.1151],
              [8.09632, 50.11533],
              [8.09604, 50.11592],
              [8.09591, 50.11609],
              [8.09563, 50.11641],
              [8.09488, 50.117],
              [8.09381, 50.11749],
              [8.0925, 50.1178],
              [8.0905, 50.11795],
              [8.08626, 50.11819],
              [8.08526, 50.11834],
              [8.08359, 50.11861],
              [8.08269, 50.11869],
              [8.08221, 50.1187],
              [8.08084, 50.11867],
              [8.07952, 50.11845],
              [8.07821, 50.11806],
              [8.07645, 50.11745],
              [8.06497, 50.11361],
              [8.06357, 50.11338],
              [8.0621, 50.11339],
              [8.0606, 50.11369],
              [8.05908, 50.11434],
              [8.05494, 50.11657],
              [8.05458, 50.11684],
              [8.0541, 50.11726],
              [8.05337, 50.11818],
              [8.053, 50.11905],
              [8.05287, 50.11994],
              [8.05298, 50.12079],
              [8.05338, 50.12172],
              [8.05408, 50.12306],
              [8.05418, 50.12374],
              [8.05398, 50.12458],
              [8.05364, 50.12515],
              [8.0529, 50.12584],
              [8.05209, 50.12631],
              [8.05121, 50.12669],
              [8.05027, 50.12706],
              [8.04946, 50.1275],
              [8.04886, 50.12795],
              [8.04752, 50.12952],
              [8.04657, 50.1308],
              [8.04629, 50.13141],
              [8.04628, 50.13232],
              [8.04646, 50.13293],
              [8.04658, 50.13379],
              [8.04628, 50.13469],
              [8.04498, 50.13702],
              [8.04477, 50.1374],
              [8.04498, 50.13702],
              [8.04628, 50.13469],
              [8.04658, 50.13379],
              [8.04646, 50.13293],
              [8.04658, 50.13379],
              [8.04628, 50.13469],
              [8.04498, 50.13702],
              [8.04477, 50.1374],
              [8.04431, 50.13874],
              [8.04424, 50.1391],
              [8.04415, 50.13983],
              [8.04393, 50.14086],
              [8.04327, 50.14201],
              [8.043, 50.1425],
              [8.04271, 50.14297],
              [8.04173, 50.14441],
              [8.03955, 50.14721],
              [8.03917, 50.14761],
              [8.03791, 50.14856],
              [8.03771, 50.14867],
              [8.03365, 50.1504],
              [8.03278, 50.15079],
              [8.03061, 50.15201],
              [8.0292, 50.15301],
              [8.02743, 50.1545],
              [8.02571, 50.15603],
              [8.02364, 50.15806],
              [8.023, 50.15897],
              [8.02282, 50.15935],
              [8.02264, 50.15986],
              [8.02217, 50.15985],
              [8.02163, 50.16019],
              [8.02125, 50.15998],
              [8.02127, 50.15988],
              [8.021496, 50.15966],
              [8.02127, 50.15988],
              [8.02125, 50.15998],
              [8.02163, 50.16019],
              [8.02114, 50.1608],
              [8.02092, 50.16106],
              [8.02081, 50.16119],
              [8.020468, 50.161597],
              [8.02008, 50.16206],
              [8.01987, 50.16232],
              [8.01959, 50.16268],
              [8.0192, 50.16323],
              [8.01895, 50.1639],
              [8.01893, 50.16408],
              [8.01888, 50.16485],
              [8.01883, 50.16495],
              [8.01826, 50.16528],
              [8.01809, 50.16584],
              [8.01795, 50.16594],
              [8.01652, 50.1665],
              [8.01542, 50.16692],
              [8.01461, 50.16712],
              [8.01429, 50.16754],
              [8.01388, 50.16818],
              [8.0137, 50.16846],
              [8.01363, 50.16857],
              [8.01356, 50.1687],
              [8.0135, 50.16897],
              [8.01354, 50.16903],
              [8.01314, 50.16947],
              [8.01302, 50.16974],
              [8.01279, 50.17038],
              [8.01225, 50.17169],
              [8.012, 50.17165],
              [8.01105, 50.1713],
              [8.01028, 50.17075],
              [8.00805, 50.17151],
              [8.00525, 50.17249],
              [8.00387, 50.17299],
              [8.003051, 50.173298],
              [8.00238, 50.17355],
              [8.00099, 50.17404],
              [7.99898, 50.17478],
              [7.98475, 50.17991],
              [7.984202, 50.180107],
              [7.98364, 50.18031],
              [7.98283, 50.18061],
              [7.97679, 50.18286],
              [7.97274, 50.18429],
              [7.97069, 50.18508],
              [7.96989, 50.18581],
              [7.96933, 50.18652],
              [7.96887, 50.18612],
              [7.96793, 50.18637],
              [7.96341, 50.18611],
              [7.96237, 50.18632],
              [7.96042, 50.1874],
              [7.95959, 50.1881],
              [7.95823, 50.18877],
              [7.95645, 50.18957],
              [7.9557, 50.18974],
              [7.95481, 50.18985],
              [7.95469, 50.18985],
              [7.95274, 50.18967],
              [7.95197, 50.18959],
              [7.95042, 50.18973],
              [7.94968, 50.18983],
              [7.94958, 50.18984],
              [7.9488, 50.19004],
              [7.9483, 50.19043],
              [7.94748, 50.1918],
              [7.94638, 50.19257],
              [7.94495, 50.19332],
              [7.94417, 50.19396],
              [7.94351, 50.19488],
              [7.94353, 50.19496],
              [7.94388, 50.19516],
              [7.94705, 50.1962],
              [7.94773, 50.1966],
              [7.94806, 50.19692],
              [7.94788, 50.19733],
              [7.94665, 50.19822],
              [7.94615, 50.1983],
              [7.94553, 50.19805],
              [7.94394, 50.19837],
              [7.94319, 50.1985],
              [7.94196, 50.19837],
              [7.94151, 50.19827],
              [7.94196, 50.19837],
              [7.94319, 50.1985],
              [7.94394, 50.19837],
              [7.94553, 50.19805],
              [7.94615, 50.1983],
              [7.94665, 50.19822],
              [7.94788, 50.19733],
              [7.94806, 50.19692],
              [7.94773, 50.1966],
              [7.94806, 50.19692],
              [7.94788, 50.19733],
              [7.94665, 50.19822],
              [7.94615, 50.1983],
              [7.94553, 50.19805],
              [7.94394, 50.19837],
              [7.94319, 50.1985],
              [7.94196, 50.19837],
              [7.94151, 50.19827],
              [7.93874, 50.19751],
              [7.9382, 50.19755],
              [7.93735, 50.19775],
              [7.93613, 50.19801],
              [7.93516, 50.19836],
              [7.934134, 50.198458],
              [7.93369, 50.1985],
              [7.93346, 50.19856],
              [7.93253, 50.19896],
              [7.93147, 50.19948],
              [7.93058, 50.19966],
              [7.93036, 50.1997],
              [7.92995, 50.19978],
              [7.92594, 50.20056],
              [7.92573, 50.20063],
              [7.9242, 50.20121],
              [7.92282, 50.20171],
              [7.9217, 50.20225],
              [7.9215, 50.20243],
              [7.92131, 50.20262],
              [7.92127, 50.2027],
              [7.9212, 50.20305],
              [7.92118, 50.20361],
              [7.92107, 50.20379],
              [7.92004, 50.20489],
              [7.9194, 50.20577],
              [7.91914, 50.20648],
              [7.91913, 50.20738],
              [7.91913, 50.20833],
              [7.91913, 50.20738],
              [7.91914, 50.20648],
              [7.9194, 50.20577],
              [7.92004, 50.20489],
              [7.92107, 50.20379],
              [7.92118, 50.20361],
              [7.9212, 50.20305],
              [7.92127, 50.2027],
              [7.92131, 50.20262],
              [7.918388, 50.210351],
              [7.92131, 50.20262],
              [7.92127, 50.2027],
              [7.9212, 50.20305],
              [7.92118, 50.20361],
              [7.92107, 50.20379],
              [7.92004, 50.20489],
              [7.9194, 50.20577],
              [7.91914, 50.20648],
              [7.91913, 50.20738],
              [7.91913, 50.20833],
              [7.9161, 50.2106],
              [7.91512, 50.21209],
              [7.91458, 50.21302],
              [7.91462, 50.21312],
              [7.91289, 50.21425],
              [7.91216, 50.21473],
              [7.91121, 50.21534],
              [7.91055, 50.21577],
              [7.90846, 50.21713],
              [7.90679, 50.21826],
              [7.906237, 50.218853],
              [7.90566, 50.21947],
              [7.90504, 50.22017],
              [7.90426, 50.2202],
              [7.90353, 50.22013],
              [7.90308, 50.22002],
              [7.90245, 50.21985],
              [7.90115, 50.21947],
              [7.89941, 50.21892],
              [7.89469, 50.21681],
              [7.89359, 50.21608],
              [7.88985, 50.21322],
              [7.8869, 50.21095],
              [7.88435, 50.20997],
              [7.88343, 50.20945],
              [7.8829, 50.20853],
              [7.88271, 50.20811],
              [7.88197, 50.20644],
              [7.88159, 50.20596],
              [7.88087, 50.20565],
              [7.88037, 50.20552],
              [7.87852, 50.20506],
              [7.87783, 50.20493],
              [7.87766, 50.20491],
              [7.876987, 50.204836],
              [7.87576, 50.2047],
              [7.87516, 50.20462],
              [7.87249, 50.20374],
              [7.87116, 50.20328],
              [7.87027, 50.20297],
              [7.86897, 50.20251],
              [7.86829, 50.20227],
              [7.86728, 50.20169],
              [7.86687, 50.20114],
              [7.86659, 50.2003],
              [7.86638, 50.19999],
              [7.86588, 50.19968],
              [7.864445, 50.199268],
              [7.86379, 50.19908],
              [7.86315, 50.19894],
              [7.86265, 50.19887],
              [7.8613, 50.19911],
              [7.86104, 50.19909],
              [7.8602, 50.19888],
              [7.8591, 50.19864],
              [7.858635, 50.19891],
              [7.85829, 50.19911],
              [7.8575, 50.19943],
              [7.8562, 50.1986],
              [7.85559, 50.19891],
              [7.8544, 50.19954],
              [7.85391, 50.1991],
              [7.853471, 50.199294],
            ],
          ],
        },
        id: "M0MDk",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.EXPRESS,
    id: "c0NjA",
    name: "X72 Limburg <> Wiesbaden",
    lineName: "X72",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.243761, 50.071537],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.238961, 50.072448],
              [8.23889, 50.073],
              [8.2386, 50.07528],
              [8.23852, 50.07599],
              [8.2384, 50.07687],
              [8.23834, 50.07755],
              [8.238299, 50.077774],
              [8.237587, 50.077734],
              [8.237032, 50.077704],
              [8.23674, 50.07786],
              [8.23658, 50.07894],
              [8.23654, 50.07946],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.2363, 50.08098],
              [8.23627, 50.08116],
              [8.23625, 50.08138],
              [8.23623, 50.08182],
              [8.23617, 50.08226],
              [8.23615, 50.08241],
              [8.23611, 50.08276],
              [8.23607, 50.08306],
              [8.23606, 50.08328],
              [8.23603, 50.08364],
              [8.23593, 50.08379],
              [8.23501, 50.08459],
              [8.23366, 50.08578],
              [8.23314, 50.08623],
              [8.2325, 50.0867],
              [8.23185, 50.08717],
              [8.23169, 50.08729],
              [8.23096, 50.08782],
              [8.23082, 50.08792],
              [8.2305, 50.08815],
              [8.23004, 50.08851],
              [8.22935, 50.08924],
              [8.22887, 50.08983],
              [8.22843, 50.09032],
              [8.22811, 50.09071],
              [8.227802, 50.091053],
              [8.22697, 50.09198],
              [8.22632, 50.09232],
              [8.22537, 50.09277],
              [8.22482, 50.09304],
              [8.22421, 50.09335],
              [8.22324, 50.09368],
              [8.22308, 50.09377],
              [8.22261, 50.094135],
              [8.22196, 50.09464],
              [8.22164, 50.09517],
              [8.22112, 50.09604],
              [8.221, 50.0962],
              [8.22114, 50.09634],
              [8.22116, 50.09698],
              [8.22089, 50.09707],
              [8.22025, 50.09698],
              [8.21978, 50.09757],
              [8.21933, 50.09819],
              [8.21908, 50.09855],
              [8.21839, 50.09958],
              [8.21829, 50.09974],
              [8.21718, 50.10136],
              [8.21682, 50.10187],
              [8.21461, 50.10464],
              [8.21116, 50.1089],
              [8.21051, 50.10985],
              [8.21031, 50.11047],
              [8.21022, 50.11076],
              [8.20966, 50.11298],
              [8.20934, 50.11423],
              [8.20908, 50.1148],
              [8.20884, 50.11505],
              [8.20862, 50.11522],
              [8.2079, 50.11568],
              [8.20626, 50.11658],
              [8.20564, 50.11723],
              [8.20555, 50.11747],
              [8.2054, 50.11825],
              [8.20556, 50.11886],
              [8.20595, 50.11965],
              [8.20639, 50.12082],
              [8.2064, 50.12219],
              [8.20602, 50.12432],
              [8.20627, 50.12539],
              [8.20667, 50.12625],
              [8.20696, 50.12672],
              [8.20828, 50.12785],
              [8.20934, 50.12848],
              [8.21485, 50.13173],
              [8.21639, 50.133],
              [8.2167, 50.13332],
              [8.21705, 50.13369],
              [8.21757, 50.13433],
              [8.2179, 50.13479],
              [8.218069, 50.135119],
              [8.21809, 50.13516],
              [8.21822, 50.1354],
              [8.21832, 50.1356],
              [8.21862, 50.13629],
              [8.21879, 50.13686],
              [8.21884, 50.1371],
              [8.21893, 50.13766],
              [8.21897, 50.1387],
              [8.21887, 50.13977],
              [8.21829, 50.14154],
              [8.21743, 50.14327],
              [8.21673, 50.14464],
              [8.21644, 50.1454],
              [8.21628, 50.14581],
              [8.21599, 50.14652],
              [8.21578, 50.14695],
              [8.21554, 50.14735],
              [8.2144, 50.14897],
              [8.21378, 50.14977],
              [8.21329, 50.15051],
              [8.21294, 50.15153],
              [8.21238, 50.15333],
              [8.21206, 50.15515],
              [8.212029, 50.156666],
              [8.21202, 50.15708],
              [8.21202, 50.15717],
              [8.21202, 50.15773],
              [8.21214, 50.15905],
              [8.21233, 50.15953],
              [8.21277, 50.16031],
              [8.21309, 50.16071],
              [8.21266, 50.16084],
              [8.21243, 50.16125],
              [8.21235, 50.16307],
              [8.21233, 50.1637],
              [8.21236, 50.16427],
              [8.21246, 50.16481],
              [8.21276, 50.16616],
              [8.21285, 50.16646],
              [8.21321, 50.16724],
              [8.21343, 50.16775],
              [8.21348, 50.16796],
              [8.21344, 50.16889],
              [8.21338, 50.16916],
              [8.21318, 50.16999],
              [8.21314, 50.17005],
              [8.21306, 50.17017],
              [8.21251, 50.17048],
              [8.21192, 50.17061],
              [8.21151, 50.17065],
              [8.21144, 50.17078],
              [8.2114, 50.17079],
              [8.211664, 50.170985],
              [8.21224, 50.17141],
              [8.21243, 50.17149],
              [8.21328, 50.17183],
              [8.21388, 50.17207],
              [8.21442, 50.17228],
              [8.21517, 50.17257],
              [8.21548, 50.1727],
              [8.21751, 50.17349],
              [8.21848, 50.17384],
              [8.22, 50.17443],
              [8.22027, 50.17404],
              [8.22017, 50.17373],
              [8.21956, 50.17341],
              [8.219, 50.17336],
              [8.21892, 50.17344],
              [8.21848, 50.17384],
              [8.21778, 50.17453],
              [8.21734, 50.17496],
              [8.21728, 50.17501],
              [8.216845, 50.175418],
              [8.21615, 50.17607],
              [8.2156, 50.1766],
              [8.21515, 50.17704],
              [8.21397, 50.178],
              [8.21279, 50.17858],
              [8.21155, 50.17891],
              [8.2088, 50.17918],
              [8.20694, 50.17945],
              [8.2068, 50.17949],
              [8.20537, 50.18006],
              [8.20449, 50.18068],
              [8.20425, 50.18092],
              [8.20409, 50.18109],
              [8.20381, 50.18149],
              [8.20279, 50.18147],
              [8.19987, 50.18174],
              [8.19999, 50.18207],
              [8.200656, 50.182163],
              [8.19999, 50.18207],
              [8.19987, 50.18174],
              [8.20279, 50.18147],
              [8.20381, 50.18149],
              [8.20353, 50.18202],
              [8.20334, 50.18291],
              [8.20314, 50.18402],
              [8.20224, 50.18926],
              [8.20213, 50.189953],
              [8.20206, 50.19039],
              [8.20198, 50.19095],
              [8.20187, 50.19161],
              [8.2018, 50.19259],
              [8.20207, 50.19325],
              [8.20265, 50.19393],
              [8.20318, 50.19464],
              [8.20338, 50.19513],
              [8.20341, 50.1955],
              [8.2031, 50.19647],
              [8.20269, 50.19726],
              [8.20248, 50.19762],
              [8.20227, 50.19804],
              [8.20169, 50.19936],
              [8.20143, 50.20149],
              [8.2011, 50.20331],
              [8.20096, 50.20412],
              [8.20078, 50.20556],
              [8.2006, 50.20698],
              [8.20059, 50.20714],
              [8.20067, 50.20835],
              [8.20075, 50.20878],
              [8.20073, 50.2093],
              [8.20061, 50.21134],
              [8.20041, 50.21199],
              [8.1998, 50.2128],
              [8.19929, 50.21339],
              [8.19838, 50.21459],
              [8.19817, 50.21495],
              [8.1974, 50.21646],
              [8.19727, 50.21675],
              [8.19717, 50.21719],
              [8.19719, 50.2175],
              [8.19738, 50.21807],
              [8.19856, 50.22057],
              [8.1988, 50.22052],
              [8.1995, 50.22037],
              [8.19954, 50.22028],
              [8.19923, 50.21965],
              [8.19954, 50.22028],
              [8.1995, 50.22037],
              [8.1988, 50.22052],
              [8.19856, 50.22057],
              [8.199, 50.22149],
              [8.19975, 50.22307],
              [8.20018, 50.22437],
              [8.200206, 50.225055],
              [8.20022, 50.22544],
              [8.20004, 50.22628],
              [8.19979, 50.22688],
              [8.19953, 50.22735],
              [8.19925, 50.22774],
              [8.19843, 50.22877],
              [8.19784, 50.22946],
              [8.19753, 50.23019],
              [8.19738, 50.2309],
              [8.19744, 50.23315],
              [8.1972, 50.23444],
              [8.1966, 50.2357],
              [8.19617, 50.23628],
              [8.19521, 50.23733],
              [8.19313, 50.23921],
              [8.19268, 50.23959],
              [8.19154, 50.24066],
              [8.18955, 50.24246],
              [8.18878, 50.24343],
              [8.1884, 50.24443],
              [8.18823, 50.24527],
              [8.18806, 50.24599],
              [8.18785, 50.24676],
              [8.187768, 50.247137],
              [8.18757, 50.24804],
              [8.18747, 50.24848],
              [8.18696, 50.25047],
              [8.18656, 50.25101],
              [8.18574, 50.25151],
              [8.18473, 50.25196],
              [8.18427, 50.25225],
              [8.1847, 50.25253],
              [8.18481, 50.25278],
              [8.18422, 50.25339],
              [8.18408, 50.25404],
              [8.18356, 50.25459],
              [8.1829, 50.255],
              [8.18241, 50.25512],
              [8.18198, 50.25564],
              [8.18041, 50.25752],
              [8.17807, 50.26016],
              [8.17548, 50.26272],
              [8.17437, 50.26378],
              [8.17346, 50.26471],
              [8.17294, 50.26554],
              [8.17241, 50.26643],
              [8.17095, 50.2689],
              [8.17058, 50.26952],
              [8.17029, 50.27001],
              [8.1693, 50.27141],
              [8.16856, 50.27238],
              [8.16844, 50.27261],
              [8.16816, 50.2737],
              [8.16798, 50.27516],
              [8.16794, 50.2755],
              [8.16758, 50.27764],
              [8.16702, 50.28138],
              [8.16701, 50.28143],
              [8.16685, 50.28239],
              [8.166, 50.28835],
              [8.16534, 50.29253],
              [8.16477, 50.29564],
              [8.16454, 50.29694],
              [8.16445, 50.2974],
              [8.16385, 50.30037],
              [8.16367, 50.30128],
              [8.16353, 50.302],
              [8.16332, 50.30317],
              [8.16298, 50.30515],
              [8.1629, 50.30558],
              [8.16279, 50.30621],
              [8.16267, 50.306932],
              [8.16262, 50.30723],
              [8.1626, 50.30731],
              [8.16242, 50.30816],
              [8.16198, 50.3087],
              [8.1613, 50.30921],
              [8.16093, 50.30934],
              [8.16008, 50.30991],
              [8.15912, 50.31085],
              [8.1588, 50.31118],
              [8.15791, 50.31231],
              [8.15724, 50.31359],
              [8.15703, 50.31408],
              [8.15681, 50.31471],
              [8.15657, 50.31594],
              [8.15656, 50.31597],
              [8.15612, 50.31826],
              [8.15582, 50.31907],
              [8.15511, 50.32005],
              [8.15377, 50.32113],
              [8.14732, 50.3255],
              [8.14625, 50.32623],
              [8.145919, 50.326458],
              [8.14506, 50.32705],
              [8.1428, 50.3286],
              [8.13831, 50.33151],
              [8.13624, 50.33265],
              [8.12831, 50.33695],
              [8.12731, 50.33747],
              [8.12673, 50.33779],
              [8.12624, 50.33806],
              [8.12562, 50.33844],
              [8.12435, 50.33936],
              [8.12296, 50.34071],
              [8.12175, 50.34204],
              [8.12139, 50.34244],
              [8.12108, 50.34278],
              [8.12063, 50.34329],
              [8.11993, 50.34401],
              [8.11867, 50.34507],
              [8.11419, 50.34817],
              [8.11352, 50.34882],
              [8.113, 50.34973],
              [8.11223, 50.3517],
              [8.11165, 50.35254],
              [8.11118, 50.35303],
              [8.11011, 50.3539],
              [8.10903, 50.35465],
              [8.10548, 50.35715],
              [8.10416, 50.35807],
              [8.1028, 50.35903],
              [8.10263, 50.35915],
              [8.10126, 50.36012],
              [8.09713, 50.36305],
              [8.09596, 50.36387],
              [8.09526, 50.36437],
              [8.09488, 50.36464],
              [8.0939, 50.36534],
              [8.09318, 50.36583],
              [8.09227, 50.36648],
              [8.09184, 50.36679],
              [8.09076, 50.36749],
              [8.09066, 50.36756],
              [8.08892, 50.36864],
              [8.08875, 50.36873],
              [8.08752, 50.36944],
              [8.087135, 50.369678],
              [8.08666, 50.36997],
              [8.08588, 50.37041],
              [8.08525, 50.37082],
              [8.08423, 50.37143],
              [8.0839, 50.37161],
              [8.08359, 50.3718],
              [8.08151, 50.37315],
              [8.08012, 50.37463],
              [8.07944, 50.37509],
              [8.07901, 50.37527],
              [8.07664, 50.37595],
              [8.07533, 50.37652],
              [8.07511, 50.37666],
              [8.07462, 50.37698],
              [8.07448, 50.37708],
              [8.07264, 50.3784],
              [8.07245, 50.37864],
              [8.07205, 50.37925],
              [8.07172, 50.37979],
              [8.07162, 50.37994],
              [8.07157, 50.38002],
              [8.0711, 50.38078],
              [8.07062, 50.38171],
              [8.07072, 50.38235],
              [8.07081, 50.38256],
              [8.07076, 50.38309],
              [8.06939, 50.38436],
              [8.06901, 50.38459],
              [8.06825, 50.38491],
              [8.06773, 50.38467],
              [8.06701, 50.38448],
              [8.06579, 50.3843],
              [8.0652, 50.38421],
              [8.06378, 50.38434],
              [8.06413, 50.38511],
              [8.06394, 50.38495],
              [8.06316, 50.38513],
              [8.062772, 50.38522],
            ],
          ],
        },
        id: "c0NjA",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.062772, 50.38522],
              [8.06316, 50.38513],
              [8.06394, 50.38495],
              [8.06413, 50.38511],
              [8.06378, 50.38434],
              [8.0652, 50.38421],
              [8.06579, 50.3843],
              [8.06701, 50.38448],
              [8.06773, 50.38467],
              [8.06825, 50.38491],
              [8.06901, 50.38459],
              [8.06939, 50.38436],
              [8.07076, 50.38309],
              [8.07081, 50.38256],
              [8.07072, 50.38235],
              [8.07062, 50.38171],
              [8.0711, 50.38078],
              [8.07157, 50.38002],
              [8.07162, 50.37994],
              [8.07172, 50.37979],
              [8.07205, 50.37925],
              [8.07245, 50.37864],
              [8.07264, 50.3784],
              [8.07448, 50.37708],
              [8.07462, 50.37698],
              [8.07511, 50.37666],
              [8.07533, 50.37652],
              [8.07664, 50.37595],
              [8.07901, 50.37527],
              [8.07944, 50.37509],
              [8.08012, 50.37463],
              [8.08151, 50.37315],
              [8.08359, 50.3718],
              [8.0839, 50.37161],
              [8.08423, 50.37143],
              [8.08525, 50.37082],
              [8.08588, 50.37041],
              [8.08666, 50.36997],
              [8.087135, 50.369678],
              [8.08752, 50.36944],
              [8.08875, 50.36873],
              [8.08892, 50.36864],
              [8.09066, 50.36756],
              [8.09076, 50.36749],
              [8.09184, 50.36679],
              [8.09227, 50.36648],
              [8.09318, 50.36583],
              [8.0939, 50.36534],
              [8.09488, 50.36464],
              [8.09526, 50.36437],
              [8.09596, 50.36387],
              [8.09713, 50.36305],
              [8.10126, 50.36012],
              [8.10263, 50.35915],
              [8.1028, 50.35903],
              [8.10416, 50.35807],
              [8.10548, 50.35715],
              [8.10903, 50.35465],
              [8.11011, 50.3539],
              [8.11118, 50.35303],
              [8.11165, 50.35254],
              [8.11223, 50.3517],
              [8.113, 50.34973],
              [8.11352, 50.34882],
              [8.11419, 50.34817],
              [8.11867, 50.34507],
              [8.11993, 50.34401],
              [8.12063, 50.34329],
              [8.12108, 50.34278],
              [8.12139, 50.34244],
              [8.12175, 50.34204],
              [8.12296, 50.34071],
              [8.12435, 50.33936],
              [8.12562, 50.33844],
              [8.12624, 50.33806],
              [8.12673, 50.33779],
              [8.12731, 50.33747],
              [8.12831, 50.33695],
              [8.13624, 50.33265],
              [8.13831, 50.33151],
              [8.1428, 50.3286],
              [8.14506, 50.32705],
              [8.145919, 50.326458],
              [8.14625, 50.32623],
              [8.14732, 50.3255],
              [8.15377, 50.32113],
              [8.15511, 50.32005],
              [8.15582, 50.31907],
              [8.15612, 50.31826],
              [8.15656, 50.31597],
              [8.15657, 50.31594],
              [8.15681, 50.31471],
              [8.15703, 50.31408],
              [8.15724, 50.31359],
              [8.15791, 50.31231],
              [8.1588, 50.31118],
              [8.15912, 50.31085],
              [8.16008, 50.30991],
              [8.16093, 50.30934],
              [8.1613, 50.30921],
              [8.16198, 50.3087],
              [8.16242, 50.30816],
              [8.1626, 50.30731],
              [8.16262, 50.30723],
              [8.16267, 50.306932],
              [8.16279, 50.30621],
              [8.1629, 50.30558],
              [8.16298, 50.30515],
              [8.16332, 50.30317],
              [8.16353, 50.302],
              [8.16367, 50.30128],
              [8.16385, 50.30037],
              [8.16445, 50.2974],
              [8.16454, 50.29694],
              [8.16477, 50.29564],
              [8.16534, 50.29253],
              [8.166, 50.28835],
              [8.16685, 50.28239],
              [8.16701, 50.28143],
              [8.16702, 50.28138],
              [8.16758, 50.27764],
              [8.16794, 50.2755],
              [8.16798, 50.27516],
              [8.16816, 50.2737],
              [8.16844, 50.27261],
              [8.16856, 50.27238],
              [8.1693, 50.27141],
              [8.17029, 50.27001],
              [8.17058, 50.26952],
              [8.17095, 50.2689],
              [8.17241, 50.26643],
              [8.17294, 50.26554],
              [8.17346, 50.26471],
              [8.17437, 50.26378],
              [8.17548, 50.26272],
              [8.17807, 50.26016],
              [8.18041, 50.25752],
              [8.18198, 50.25564],
              [8.18241, 50.25512],
              [8.1829, 50.255],
              [8.18356, 50.25459],
              [8.18408, 50.25404],
              [8.18422, 50.25339],
              [8.18481, 50.25278],
              [8.1847, 50.25253],
              [8.18427, 50.25225],
              [8.18473, 50.25196],
              [8.18574, 50.25151],
              [8.18656, 50.25101],
              [8.18696, 50.25047],
              [8.18747, 50.24848],
              [8.18757, 50.24804],
              [8.187768, 50.247137],
              [8.18785, 50.24676],
              [8.18806, 50.24599],
              [8.18823, 50.24527],
              [8.1884, 50.24443],
              [8.18878, 50.24343],
              [8.18955, 50.24246],
              [8.19154, 50.24066],
              [8.19268, 50.23959],
              [8.19313, 50.23921],
              [8.19521, 50.23733],
              [8.19617, 50.23628],
              [8.1966, 50.2357],
              [8.1972, 50.23444],
              [8.19744, 50.23315],
              [8.19738, 50.2309],
              [8.19753, 50.23019],
              [8.19784, 50.22946],
              [8.19843, 50.22877],
              [8.19925, 50.22774],
              [8.19953, 50.22735],
              [8.19979, 50.22688],
              [8.20004, 50.22628],
              [8.20022, 50.22544],
              [8.200206, 50.225055],
              [8.20018, 50.22437],
              [8.19975, 50.22307],
              [8.199, 50.22149],
              [8.19856, 50.22057],
              [8.1988, 50.22052],
              [8.1995, 50.22037],
              [8.19954, 50.22028],
              [8.19923, 50.21965],
              [8.19954, 50.22028],
              [8.1995, 50.22037],
              [8.1988, 50.22052],
              [8.19856, 50.22057],
              [8.19738, 50.21807],
              [8.19719, 50.2175],
              [8.19717, 50.21719],
              [8.19727, 50.21675],
              [8.1974, 50.21646],
              [8.19817, 50.21495],
              [8.19838, 50.21459],
              [8.19929, 50.21339],
              [8.1998, 50.2128],
              [8.20041, 50.21199],
              [8.20061, 50.21134],
              [8.20073, 50.2093],
              [8.20075, 50.20878],
              [8.20067, 50.20835],
              [8.20059, 50.20714],
              [8.2006, 50.20698],
              [8.20078, 50.20556],
              [8.20096, 50.20412],
              [8.2011, 50.20331],
              [8.20143, 50.20149],
              [8.20169, 50.19936],
              [8.20227, 50.19804],
              [8.20248, 50.19762],
              [8.20269, 50.19726],
              [8.2031, 50.19647],
              [8.20341, 50.1955],
              [8.20338, 50.19513],
              [8.20318, 50.19464],
              [8.20265, 50.19393],
              [8.20207, 50.19325],
              [8.2018, 50.19259],
              [8.20187, 50.19161],
              [8.20198, 50.19095],
              [8.20206, 50.19039],
              [8.20213, 50.189953],
              [8.20224, 50.18926],
              [8.20314, 50.18402],
              [8.20334, 50.18291],
              [8.20353, 50.18202],
              [8.20381, 50.18149],
              [8.20279, 50.18147],
              [8.19987, 50.18174],
              [8.19999, 50.18207],
              [8.200656, 50.182163],
              [8.19999, 50.18207],
              [8.19987, 50.18174],
              [8.20279, 50.18147],
              [8.20381, 50.18149],
              [8.20409, 50.18109],
              [8.20425, 50.18092],
              [8.20449, 50.18068],
              [8.20537, 50.18006],
              [8.2068, 50.17949],
              [8.20694, 50.17945],
              [8.2088, 50.17918],
              [8.21155, 50.17891],
              [8.21279, 50.17858],
              [8.21397, 50.178],
              [8.21515, 50.17704],
              [8.2156, 50.1766],
              [8.21615, 50.17607],
              [8.216845, 50.175418],
              [8.21728, 50.17501],
              [8.21734, 50.17496],
              [8.21778, 50.17453],
              [8.21848, 50.17384],
              [8.21892, 50.17344],
              [8.219, 50.17336],
              [8.21956, 50.17341],
              [8.22017, 50.17373],
              [8.22027, 50.17404],
              [8.22, 50.17443],
              [8.21848, 50.17384],
              [8.21751, 50.17349],
              [8.21548, 50.1727],
              [8.21517, 50.17257],
              [8.21442, 50.17228],
              [8.21388, 50.17207],
              [8.21328, 50.17183],
              [8.21243, 50.17149],
              [8.21224, 50.17141],
              [8.211664, 50.170985],
              [8.2114, 50.17079],
              [8.21144, 50.17078],
              [8.21151, 50.17065],
              [8.21192, 50.17061],
              [8.21251, 50.17048],
              [8.21306, 50.17017],
              [8.21314, 50.17005],
              [8.21318, 50.16999],
              [8.21338, 50.16916],
              [8.21344, 50.16889],
              [8.21348, 50.16796],
              [8.21343, 50.16775],
              [8.21321, 50.16724],
              [8.21285, 50.16646],
              [8.21276, 50.16616],
              [8.21246, 50.16481],
              [8.21236, 50.16427],
              [8.21233, 50.1637],
              [8.21235, 50.16307],
              [8.21243, 50.16125],
              [8.21266, 50.16084],
              [8.21309, 50.16071],
              [8.21277, 50.16031],
              [8.21233, 50.15953],
              [8.21214, 50.15905],
              [8.21202, 50.15773],
              [8.21202, 50.15717],
              [8.21202, 50.15708],
              [8.212029, 50.156666],
              [8.21206, 50.15515],
              [8.21238, 50.15333],
              [8.21294, 50.15153],
              [8.21329, 50.15051],
              [8.21378, 50.14977],
              [8.2144, 50.14897],
              [8.21554, 50.14735],
              [8.21578, 50.14695],
              [8.21599, 50.14652],
              [8.21628, 50.14581],
              [8.21644, 50.1454],
              [8.21673, 50.14464],
              [8.21743, 50.14327],
              [8.21829, 50.14154],
              [8.21887, 50.13977],
              [8.21897, 50.1387],
              [8.21893, 50.13766],
              [8.21884, 50.1371],
              [8.21879, 50.13686],
              [8.21862, 50.13629],
              [8.21832, 50.1356],
              [8.21822, 50.1354],
              [8.21809, 50.13516],
              [8.218069, 50.135119],
              [8.2179, 50.13479],
              [8.21757, 50.13433],
              [8.21705, 50.13369],
              [8.2167, 50.13332],
              [8.21639, 50.133],
              [8.21485, 50.13173],
              [8.20934, 50.12848],
              [8.20828, 50.12785],
              [8.20696, 50.12672],
              [8.20667, 50.12625],
              [8.20627, 50.12539],
              [8.20602, 50.12432],
              [8.2064, 50.12219],
              [8.20639, 50.12082],
              [8.20595, 50.11965],
              [8.20556, 50.11886],
              [8.2054, 50.11825],
              [8.20555, 50.11747],
              [8.20564, 50.11723],
              [8.20626, 50.11658],
              [8.2079, 50.11568],
              [8.20862, 50.11522],
              [8.20884, 50.11505],
              [8.20908, 50.1148],
              [8.20934, 50.11423],
              [8.20966, 50.11298],
              [8.21022, 50.11076],
              [8.21031, 50.11047],
              [8.21051, 50.10985],
              [8.21116, 50.1089],
              [8.21461, 50.10464],
              [8.21682, 50.10187],
              [8.21718, 50.10136],
              [8.21829, 50.09974],
              [8.21839, 50.09958],
              [8.21908, 50.09855],
              [8.21933, 50.09819],
              [8.21978, 50.09757],
              [8.22025, 50.09698],
              [8.22089, 50.09707],
              [8.22116, 50.09698],
              [8.22114, 50.09634],
              [8.221, 50.0962],
              [8.22112, 50.09604],
              [8.22164, 50.09517],
              [8.22196, 50.09464],
              [8.22261, 50.094135],
              [8.22308, 50.09377],
              [8.22324, 50.09368],
              [8.22421, 50.09335],
              [8.22482, 50.09304],
              [8.22537, 50.09277],
              [8.22632, 50.09232],
              [8.22697, 50.09198],
              [8.227802, 50.091053],
              [8.22811, 50.09071],
              [8.22843, 50.09032],
              [8.22887, 50.08983],
              [8.22935, 50.08924],
              [8.23004, 50.08851],
              [8.2305, 50.08815],
              [8.23082, 50.08792],
              [8.23096, 50.08782],
              [8.23169, 50.08729],
              [8.23185, 50.08717],
              [8.2325, 50.0867],
              [8.23314, 50.08623],
              [8.23366, 50.08578],
              [8.23501, 50.08459],
              [8.23593, 50.08379],
              [8.23582, 50.08338],
              [8.23581, 50.08327],
              [8.23583, 50.08285],
              [8.23588, 50.08241],
              [8.2359, 50.08223],
              [8.23598, 50.08168],
              [8.23605, 50.0813],
              [8.23609, 50.08107],
              [8.23612, 50.08088],
              [8.23622, 50.08024],
              [8.23623, 50.08016],
              [8.23639, 50.07893],
              [8.23644, 50.07835],
              [8.236676, 50.077684],
              [8.236794, 50.077481],
              [8.23682, 50.07737],
              [8.23703, 50.07593],
              [8.23714, 50.07521],
              [8.23725, 50.07431],
              [8.23742, 50.07296],
              [8.23747, 50.072429],
              [8.23752, 50.0719],
              [8.23751, 50.07178],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
            ],
          ],
        },
        id: "c0MzE",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.EXPRESS,
    id: "U1Nzc",
    name: "X70 Wiesbaden <> Limburg",
    lineName: "X70",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.062772, 50.38522],
              [8.06316, 50.38513],
              [8.06394, 50.38495],
              [8.06413, 50.38511],
              [8.06378, 50.38434],
              [8.06354, 50.38413],
              [8.06314, 50.38382],
              [8.06235, 50.38336],
              [8.06199, 50.38324],
              [8.06021, 50.38264],
              [8.058464, 50.38203],
              [8.05818, 50.38193],
              [8.05809, 50.38184],
              [8.05791, 50.38124],
              [8.05768, 50.3807],
              [8.0538, 50.37657],
              [8.05256, 50.37528],
              [8.05248, 50.37519],
              [8.05203, 50.37473],
              [8.05155, 50.37426],
              [8.05087, 50.3739],
              [8.05036, 50.37378],
              [8.0488, 50.37382],
              [8.0482, 50.37386],
              [8.04771, 50.37389],
              [8.04733, 50.373913],
              [8.04472, 50.37407],
              [8.04372, 50.37414],
              [8.04314, 50.37409],
              [8.0411, 50.37414],
              [8.040745, 50.374203],
              [8.03946, 50.37443],
              [8.03851, 50.37457],
              [8.03812, 50.37459],
              [8.03712, 50.37458],
              [8.0367, 50.37453],
              [8.035998, 50.374168],
              [8.03546, 50.37389],
              [8.03429, 50.37323],
              [8.03361, 50.37285],
              [8.03348, 50.37276],
              [8.03334, 50.37239],
              [8.03228, 50.37233],
              [8.03053, 50.371785],
              [8.03013, 50.37166],
              [8.0296, 50.37146],
              [8.02943, 50.37138],
              [8.02917, 50.37126],
              [8.02903, 50.3712],
              [8.02883, 50.37112],
              [8.0285, 50.37098],
              [8.02749, 50.37075],
              [8.02707, 50.37067],
              [8.02628, 50.37051],
              [8.02561, 50.37039],
              [8.02549, 50.37037],
              [8.02523, 50.37033],
              [8.02404, 50.37009],
              [8.02415, 50.3699],
              [8.02526, 50.36953],
              [8.02537, 50.3695],
              [8.025752, 50.369378],
              [8.02719, 50.36892],
              [8.02816, 50.36862],
              [8.02851, 50.36847],
              [8.02885, 50.36798],
              [8.02893, 50.36783],
              [8.029288, 50.367125],
              [8.02929, 50.36712],
              [8.02943, 50.36685],
              [8.02993, 50.36588],
              [8.03029, 50.36522],
              [8.03073, 50.3642],
              [8.0306, 50.36375],
              [8.02966, 50.36275],
              [8.03029, 50.36206],
              [8.03077, 50.36148],
              [8.0313, 50.3612],
              [8.03168, 50.36116],
              [8.03175, 50.36087],
              [8.03202, 50.36027],
              [8.03252, 50.35956],
              [8.03333, 50.35801],
              [8.03367, 50.35766],
              [8.03497, 50.35678],
              [8.03633, 50.35497],
              [8.03666, 50.3545],
              [8.037, 50.35407],
              [8.0377, 50.35373],
              [8.03915, 50.35358],
              [8.03982, 50.35353],
              [8.04257, 50.35308],
              [8.04378, 50.35271],
              [8.04461, 50.35245],
              [8.04477, 50.3524],
              [8.0459, 50.35194],
              [8.04647, 50.35154],
              [8.04666, 50.35142],
              [8.047043, 50.351071],
              [8.04711, 50.35101],
              [8.04725, 50.35077],
              [8.04735, 50.35013],
              [8.0476, 50.34932],
              [8.04778, 50.34892],
              [8.04789, 50.34848],
              [8.04789, 50.34796],
              [8.04783, 50.34752],
              [8.04768, 50.34654],
              [8.04877, 50.345],
              [8.04922, 50.34456],
              [8.04948, 50.34386],
              [8.04956, 50.34374],
              [8.04982, 50.34327],
              [8.05, 50.34291],
              [8.05036, 50.34214],
              [8.05037, 50.34178],
              [8.05058, 50.34145],
              [8.05074, 50.3412],
              [8.05114, 50.34066],
              [8.05155, 50.34007],
              [8.05172, 50.33979],
              [8.05194, 50.33951],
              [8.05239, 50.33849],
              [8.05276, 50.33793],
              [8.052862, 50.3378],
              [8.05315, 50.33743],
              [8.05352, 50.33693],
              [8.05387, 50.33646],
              [8.05405, 50.33618],
              [8.05492, 50.33537],
              [8.05505, 50.33528],
              [8.05528, 50.33504],
              [8.05551, 50.33487],
              [8.05555, 50.33483],
              [8.05573, 50.33466],
              [8.05586, 50.33407],
              [8.05601, 50.33315],
              [8.05605, 50.33302],
              [8.05617, 50.33255],
              [8.05644, 50.33208],
              [8.05687, 50.33158],
              [8.05854, 50.33044],
              [8.05903, 50.32964],
              [8.05921, 50.32939],
              [8.06018, 50.32861],
              [8.06161, 50.32799],
              [8.06275, 50.32752],
              [8.06447, 50.32627],
              [8.06473, 50.32607],
              [8.06531, 50.32564],
              [8.0658, 50.32517],
              [8.06614, 50.32469],
              [8.06669, 50.323857],
              [8.06684, 50.32363],
              [8.06723, 50.32284],
              [8.06763, 50.32173],
              [8.06875, 50.32049],
              [8.06891, 50.32006],
              [8.06896, 50.31941],
              [8.06872, 50.31879],
              [8.06828, 50.31823],
              [8.0677, 50.31789],
              [8.06757, 50.31781],
              [8.06693, 50.31739],
              [8.06687, 50.31734],
              [8.06683, 50.31727],
              [8.06632, 50.31653],
              [8.06606, 50.31602],
              [8.06611, 50.31538],
              [8.06595, 50.31395],
              [8.06595, 50.31377],
              [8.06572, 50.31255],
              [8.06557, 50.31168],
              [8.06556, 50.31082],
              [8.06578, 50.31043],
              [8.06594, 50.31023],
              [8.06635, 50.30981],
              [8.06661, 50.30954],
              [8.06696, 50.30915],
              [8.0671, 50.30874],
              [8.0671, 50.3085],
              [8.06705, 50.30828],
              [8.06703, 50.30821],
              [8.06693, 50.30797],
              [8.0669, 50.30791],
              [8.06674, 50.30755],
              [8.0667, 50.30681],
              [8.0667, 50.30671],
              [8.06675, 50.30638],
              [8.06671, 50.30596],
              [8.0666, 50.30572],
              [8.06607, 50.30503],
              [8.06601, 50.30496],
              [8.06531, 50.30459],
              [8.06495, 50.30414],
              [8.06473, 50.3039],
              [8.06318, 50.30183],
              [8.06276, 50.30077],
              [8.06267, 50.30065],
              [8.06256, 50.30051],
              [8.06236, 50.30024],
              [8.06218, 50.29993],
              [8.06199, 50.29897],
              [8.06162, 50.29814],
              [8.06156, 50.298],
              [8.06136, 50.29739],
              [8.06129, 50.29713],
              [8.061271, 50.296932],
              [8.06125, 50.29672],
              [8.06117, 50.2961],
              [8.06111, 50.29553],
              [8.06115, 50.29492],
              [8.06132, 50.29435],
              [8.0614, 50.29406],
              [8.06143, 50.29331],
              [8.06103, 50.29243],
              [8.06113, 50.29208],
              [8.06127, 50.29168],
              [8.06146, 50.29087],
              [8.06152, 50.2903],
              [8.06151, 50.28969],
              [8.061582, 50.289231],
              [8.06172, 50.28835],
              [8.06158, 50.28795],
              [8.06141, 50.28768],
              [8.06125, 50.2871],
              [8.06121, 50.28702],
              [8.06117, 50.28692],
              [8.06094, 50.28659],
              [8.0607, 50.2862],
              [8.06062, 50.28594],
              [8.06059, 50.28562],
              [8.06072, 50.284957],
              [8.06079, 50.2846],
              [8.06079, 50.28379],
              [8.0608, 50.28355],
              [8.06076, 50.28249],
              [8.0604, 50.2819],
              [8.05954, 50.28128],
              [8.05901, 50.2808],
              [8.05833, 50.27937],
              [8.05806, 50.27815],
              [8.05803, 50.27766],
              [8.05802, 50.27748],
              [8.058, 50.27718],
              [8.05801, 50.27695],
              [8.05804, 50.27663],
              [8.05804, 50.27615],
              [8.05787, 50.27563],
              [8.05756, 50.275375],
              [8.05725, 50.27512],
              [8.05697, 50.2749],
              [8.05649, 50.27442],
              [8.05595, 50.27368],
              [8.05538, 50.27286],
              [8.05512, 50.27216],
              [8.05513, 50.27073],
              [8.05503, 50.27006],
              [8.05455, 50.26939],
              [8.05425, 50.26876],
              [8.0543, 50.26753],
              [8.05432, 50.26718],
              [8.05434, 50.26673],
              [8.0543, 50.26653],
              [8.05426, 50.26638],
              [8.05385, 50.26587],
              [8.0536, 50.26566],
              [8.053361, 50.265421],
              [8.05326, 50.26532],
              [8.05303, 50.26507],
              [8.05257, 50.26463],
              [8.05231, 50.26435],
              [8.05226, 50.26394],
              [8.05295, 50.26286],
              [8.05309, 50.2627],
              [8.05325, 50.26238],
              [8.05327, 50.26231],
              [8.05346, 50.26118],
              [8.05344, 50.2607],
              [8.05346, 50.25987],
              [8.05368, 50.25929],
              [8.05405, 50.25886],
              [8.05517, 50.25815],
              [8.05572, 50.25757],
              [8.05586, 50.25702],
              [8.05568, 50.25555],
              [8.05578, 50.25498],
              [8.05702, 50.25253],
              [8.05778, 50.2518],
              [8.05845, 50.25097],
              [8.05862, 50.25071],
              [8.059, 50.24969],
              [8.059335, 50.24913],
              [8.05952, 50.24882],
              [8.05961, 50.24868],
              [8.06028, 50.24791],
              [8.06074, 50.24745],
              [8.06165, 50.24662],
              [8.06343, 50.24556],
              [8.0639, 50.24509],
              [8.06443, 50.24398],
              [8.06453, 50.24364],
              [8.06444, 50.2433],
              [8.06434, 50.24321],
              [8.06298, 50.24249],
              [8.0612, 50.24104],
              [8.06103, 50.24086],
              [8.06086, 50.24068],
              [8.060556, 50.24035],
              [8.06018, 50.23994],
              [8.05987, 50.23941],
              [8.0598, 50.2391],
              [8.05994, 50.23843],
              [8.06034, 50.23768],
              [8.06053, 50.2373],
              [8.06065, 50.23656],
              [8.06046, 50.23578],
              [8.06004, 50.23516],
              [8.0595, 50.23469],
              [8.0588, 50.23429],
              [8.05793, 50.23397],
              [8.05809, 50.23345],
              [8.05818, 50.23309],
              [8.05821, 50.23294],
              [8.05829, 50.23259],
              [8.05848, 50.23131],
              [8.05858, 50.23075],
              [8.05862, 50.23065],
              [8.05877, 50.23014],
              [8.05883, 50.23001],
              [8.05894, 50.22983],
              [8.05998, 50.2291],
              [8.06053, 50.22863],
              [8.06129, 50.22828],
              [8.06214, 50.22801],
              [8.06429, 50.22768],
              [8.06523, 50.22756],
              [8.06599, 50.22743],
              [8.06708, 50.22725],
              [8.06743, 50.2272],
              [8.06845, 50.22706],
              [8.06874, 50.227],
              [8.07014, 50.2267],
              [8.07119, 50.22602],
              [8.07146, 50.22567],
              [8.07143, 50.22512],
              [8.07138, 50.22444],
              [8.07154, 50.22405],
              [8.07203, 50.22372],
              [8.07323, 50.22336],
              [8.07418, 50.22315],
              [8.07485, 50.22269],
              [8.0758, 50.22205],
              [8.07899, 50.22117],
              [8.0801, 50.22084],
              [8.08072, 50.22048],
              [8.08116, 50.21946],
              [8.08158, 50.21893],
              [8.08267, 50.21832],
              [8.08285, 50.21759],
              [8.08319, 50.2169],
              [8.08382, 50.2162],
              [8.08456, 50.21568],
              [8.0867, 50.21485],
              [8.08719, 50.2145],
              [8.08727, 50.21429],
              [8.08724, 50.21343],
              [8.08722, 50.21268],
              [8.08718, 50.21215],
              [8.0872, 50.21167],
              [8.08774, 50.21119],
              [8.08781, 50.21113],
              [8.08805, 50.21008],
              [8.08807, 50.20968],
              [8.08815, 50.20954],
              [8.0883, 50.20949],
              [8.0887, 50.20956],
              [8.08896, 50.20969],
              [8.08966, 50.20939],
              [8.0908, 50.20862],
              [8.09188, 50.20912],
              [8.0921, 50.20897],
              [8.09247, 50.20868],
              [8.09409, 50.20741],
              [8.09542, 50.20603],
              [8.09595, 50.20519],
              [8.09676, 50.20421],
              [8.09794, 50.20182],
              [8.09822, 50.19998],
              [8.09832, 50.19892],
              [8.09819, 50.19857],
              [8.097864, 50.19837],
              [8.09736, 50.19806],
              [8.09728, 50.19794],
              [8.09727, 50.19787],
              [8.09749, 50.1975],
              [8.09796, 50.19677],
              [8.09814, 50.19644],
              [8.09789, 50.19615],
              [8.09708, 50.19569],
              [8.09761, 50.19522],
              [8.09786, 50.19503],
              [8.09847, 50.19462],
              [8.09977, 50.19405],
              [8.10014, 50.19381],
              [8.10014, 50.19368],
              [8.09982, 50.1932],
              [8.10036, 50.19258],
              [8.10058, 50.19229],
              [8.10067, 50.19219],
              [8.1008, 50.19204],
              [8.10127, 50.1918],
              [8.10211, 50.19171],
              [8.10323, 50.19164],
              [8.10479, 50.19151],
              [8.10602, 50.19155],
              [8.10733, 50.19173],
              [8.10813, 50.19182],
              [8.11351, 50.19158],
              [8.1147, 50.19153],
              [8.11914, 50.19127],
              [8.12057, 50.19113],
              [8.12152, 50.19086],
              [8.12172, 50.19079],
              [8.12231, 50.1906],
              [8.12242, 50.19056],
              [8.12316, 50.19032],
              [8.12366, 50.19016],
              [8.12449, 50.1899],
              [8.12502, 50.18976],
              [8.12577, 50.18951],
              [8.12685, 50.18919],
              [8.12773, 50.18925],
              [8.12857, 50.18926],
              [8.12975, 50.18922],
              [8.13074, 50.18916],
              [8.13231, 50.18878],
              [8.1392, 50.18733],
              [8.14103, 50.18681],
              [8.1421, 50.18624],
              [8.14355, 50.18521],
              [8.14495, 50.18404],
              [8.14531, 50.18361],
              [8.14605, 50.18206],
              [8.14644, 50.18152],
              [8.14951, 50.17939],
              [8.15051, 50.17895],
              [8.15138, 50.17889],
              [8.154, 50.17941],
              [8.15637, 50.17927],
              [8.15702, 50.17926],
              [8.15742, 50.17896],
              [8.15734, 50.1786],
              [8.1571, 50.17813],
              [8.15653, 50.17775],
              [8.15601, 50.17749],
              [8.15477, 50.17679],
              [8.15401, 50.17576],
              [8.15341, 50.17542],
              [8.15205, 50.17488],
              [8.15134, 50.1742],
              [8.15128, 50.17413],
              [8.15117, 50.17341],
              [8.1512, 50.17274],
              [8.1516, 50.17187],
              [8.15159, 50.17132],
              [8.15136, 50.17093],
              [8.15072, 50.17043],
              [8.14929, 50.1697],
              [8.14902, 50.16949],
              [8.14891, 50.16937],
              [8.14882, 50.16927],
              [8.14857, 50.16872],
              [8.14861, 50.16802],
              [8.14862, 50.16791],
              [8.14869, 50.16753],
              [8.1488, 50.16699],
              [8.14883, 50.1669],
              [8.149, 50.16653],
              [8.14911, 50.16636],
              [8.14856, 50.16626],
              [8.14869, 50.16666],
              [8.14856, 50.16626],
              [8.14911, 50.16636],
              [8.14951, 50.16586],
              [8.14982, 50.1655],
              [8.15005, 50.16522],
              [8.1505, 50.16443],
              [8.15066, 50.164],
              [8.15096, 50.16289],
              [8.15121, 50.16167],
              [8.1516, 50.16103],
              [8.15189, 50.16081],
              [8.15281, 50.16044],
              [8.15381, 50.16029],
              [8.15455, 50.16001],
              [8.15514, 50.15937],
              [8.15547, 50.15794],
              [8.15597, 50.15717],
              [8.15629, 50.15632],
              [8.15658, 50.15578],
              [8.15717, 50.15535],
              [8.15772, 50.15486],
              [8.15821, 50.154],
              [8.15897, 50.15353],
              [8.1599, 50.15323],
              [8.16082, 50.15285],
              [8.16176, 50.152],
              [8.16241, 50.15113],
              [8.16234, 50.15086],
              [8.16215, 50.15062],
              [8.16151, 50.15023],
              [8.16099, 50.15003],
              [8.16051, 50.14985],
              [8.15852, 50.1493],
              [8.15726, 50.14899],
              [8.15638, 50.14878],
              [8.15615, 50.14872],
              [8.15594, 50.14913],
              [8.155758, 50.149465],
              [8.15594, 50.14913],
              [8.15615, 50.14872],
              [8.15431, 50.14826],
              [8.15387, 50.14812],
              [8.15358, 50.14783],
              [8.15363, 50.14744],
              [8.15433, 50.14655],
              [8.15495, 50.14552],
              [8.15504, 50.14538],
              [8.15527, 50.14502],
              [8.15553, 50.14462],
              [8.15565, 50.14453],
              [8.15731, 50.14362],
              [8.15851, 50.14338],
              [8.15883, 50.14316],
              [8.15899, 50.14284],
              [8.15943, 50.1428],
              [8.15961, 50.14275],
              [8.15974, 50.14265],
              [8.16015, 50.14263],
              [8.16079, 50.1428],
              [8.16082, 50.14296],
              [8.16062, 50.14313],
              [8.16022, 50.14311],
              [8.15986, 50.14302],
              [8.15964, 50.14291],
              [8.15961, 50.14275],
              [8.15974, 50.14265],
              [8.1597, 50.14256],
              [8.15968, 50.14244],
              [8.15974, 50.1423],
              [8.16003, 50.1417],
              [8.16107, 50.13955],
              [8.16128, 50.13914],
              [8.16133, 50.13903],
              [8.16197, 50.13762],
              [8.16261, 50.13632],
              [8.16286, 50.13573],
              [8.16302, 50.13462],
              [8.16299, 50.1342],
              [8.16282, 50.13354],
              [8.16233, 50.13281],
              [8.16199, 50.1323],
              [8.1621, 50.13195],
              [8.1625, 50.1317],
              [8.16388, 50.1315],
              [8.16446, 50.1311],
              [8.16482, 50.13066],
              [8.166, 50.12911],
              [8.16616, 50.12872],
              [8.16687, 50.12597],
              [8.167335, 50.124359],
              [8.16745, 50.12396],
              [8.16765, 50.12362],
              [8.16779, 50.12343],
              [8.16796, 50.12324],
              [8.16853, 50.12284],
              [8.16872, 50.12275],
              [8.1694, 50.12254],
              [8.17106, 50.1225],
              [8.17137, 50.12236],
              [8.17297, 50.12241],
              [8.17309, 50.12249],
              [8.17421, 50.12216],
              [8.17598, 50.12085],
              [8.17803, 50.12025],
              [8.17926, 50.11962],
              [8.17974, 50.11895],
              [8.17974, 50.11835],
              [8.17947, 50.11765],
              [8.1794, 50.11753],
              [8.1789, 50.11628],
              [8.17898, 50.11527],
              [8.17922, 50.1142],
              [8.17971, 50.11337],
              [8.18004, 50.11299],
              [8.18084, 50.11214],
              [8.18167, 50.11178],
              [8.18425, 50.11163],
              [8.18489, 50.11143],
              [8.18512, 50.11132],
              [8.18696, 50.11029],
              [8.18776, 50.10998],
              [8.19164, 50.10885],
              [8.19222, 50.10868],
              [8.1929, 50.10849],
              [8.19355, 50.10831],
              [8.19407, 50.10816],
              [8.19541, 50.10802],
              [8.19846, 50.10786],
              [8.19956, 50.10759],
              [8.20032, 50.10719],
              [8.20099, 50.10658],
              [8.20153, 50.10574],
              [8.20233, 50.10437],
              [8.20268, 50.10373],
              [8.2029, 50.10334],
              [8.20388, 50.10174],
              [8.20401, 50.10154],
              [8.20995, 50.09476],
              [8.21069, 50.09406],
              [8.21175, 50.09322],
              [8.21363, 50.09204],
              [8.21392, 50.09187],
              [8.21428, 50.09165],
              [8.21533, 50.09101],
              [8.21585, 50.0907],
              [8.21739, 50.08979],
              [8.21838, 50.08917],
              [8.21858, 50.08905],
              [8.21977, 50.08834],
              [8.22085, 50.08767],
              [8.22177, 50.0871],
              [8.22192, 50.08701],
              [8.22362, 50.08612],
              [8.223978, 50.085986],
              [8.22461, 50.08575],
              [8.22529, 50.08538],
              [8.2254, 50.0853],
              [8.22575, 50.08506],
              [8.22693, 50.08414],
              [8.22766, 50.08366],
              [8.22837, 50.08335],
              [8.22882, 50.08312],
              [8.22888, 50.08279],
              [8.228921, 50.082748],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22983, 50.07742],
              [8.22986, 50.07721],
              [8.22989, 50.07706],
              [8.229975, 50.076791],
              [8.23011, 50.07636],
              [8.2305, 50.07565],
              [8.23053, 50.0756],
              [8.23096, 50.075],
              [8.23143, 50.07437],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.24328, 50.0717],
            ],
          ],
        },
        id: "U1Nzc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.243761, 50.071537],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23112, 50.07506],
              [8.23077, 50.07557],
              [8.23074, 50.07561],
              [8.23036, 50.07637],
              [8.23019, 50.07716],
              [8.23015, 50.07735],
              [8.22997, 50.07829],
              [8.22976, 50.07946],
              [8.22963, 50.08055],
              [8.22955, 50.08119],
              [8.22952, 50.08153],
              [8.22947, 50.08188],
              [8.22942, 50.08225],
              [8.22938, 50.08254],
              [8.22929, 50.08323],
              [8.22897, 50.08326],
              [8.22837, 50.08335],
              [8.22766, 50.08366],
              [8.22693, 50.08414],
              [8.22575, 50.08506],
              [8.2254, 50.0853],
              [8.22529, 50.08538],
              [8.22461, 50.08575],
              [8.223978, 50.085986],
              [8.22362, 50.08612],
              [8.22192, 50.08701],
              [8.22177, 50.0871],
              [8.22085, 50.08767],
              [8.21977, 50.08834],
              [8.21858, 50.08905],
              [8.21838, 50.08917],
              [8.21739, 50.08979],
              [8.21585, 50.0907],
              [8.21533, 50.09101],
              [8.21428, 50.09165],
              [8.21392, 50.09187],
              [8.21363, 50.09204],
              [8.21175, 50.09322],
              [8.21069, 50.09406],
              [8.20995, 50.09476],
              [8.20401, 50.10154],
              [8.20388, 50.10174],
              [8.2029, 50.10334],
              [8.20268, 50.10373],
              [8.20233, 50.10437],
              [8.20153, 50.10574],
              [8.20099, 50.10658],
              [8.20032, 50.10719],
              [8.19956, 50.10759],
              [8.19846, 50.10786],
              [8.19541, 50.10802],
              [8.19407, 50.10816],
              [8.19355, 50.10831],
              [8.1929, 50.10849],
              [8.19222, 50.10868],
              [8.19164, 50.10885],
              [8.18776, 50.10998],
              [8.18696, 50.11029],
              [8.18512, 50.11132],
              [8.18489, 50.11143],
              [8.18425, 50.11163],
              [8.18167, 50.11178],
              [8.18084, 50.11214],
              [8.18004, 50.11299],
              [8.17971, 50.11337],
              [8.17922, 50.1142],
              [8.17898, 50.11527],
              [8.1789, 50.11628],
              [8.1794, 50.11753],
              [8.17947, 50.11765],
              [8.17974, 50.11835],
              [8.17974, 50.11895],
              [8.17926, 50.11962],
              [8.17803, 50.12025],
              [8.17598, 50.12085],
              [8.17421, 50.12216],
              [8.17309, 50.12249],
              [8.17297, 50.12241],
              [8.17137, 50.12236],
              [8.17106, 50.1225],
              [8.1694, 50.12254],
              [8.16872, 50.12275],
              [8.16853, 50.12284],
              [8.16796, 50.12324],
              [8.16779, 50.12343],
              [8.16765, 50.12362],
              [8.16745, 50.12396],
              [8.167335, 50.124359],
              [8.16687, 50.12597],
              [8.16616, 50.12872],
              [8.166, 50.12911],
              [8.16482, 50.13066],
              [8.16446, 50.1311],
              [8.16388, 50.1315],
              [8.1625, 50.1317],
              [8.1621, 50.13195],
              [8.16199, 50.1323],
              [8.16233, 50.13281],
              [8.16282, 50.13354],
              [8.16299, 50.1342],
              [8.16302, 50.13462],
              [8.16286, 50.13573],
              [8.16261, 50.13632],
              [8.16197, 50.13762],
              [8.16133, 50.13903],
              [8.16128, 50.13914],
              [8.16107, 50.13955],
              [8.16003, 50.1417],
              [8.15974, 50.1423],
              [8.15968, 50.14244],
              [8.1597, 50.14256],
              [8.15974, 50.14265],
              [8.15961, 50.14275],
              [8.15964, 50.14291],
              [8.15986, 50.14302],
              [8.16022, 50.14311],
              [8.16062, 50.14313],
              [8.16082, 50.14296],
              [8.16079, 50.1428],
              [8.16015, 50.14263],
              [8.15974, 50.14265],
              [8.15961, 50.14275],
              [8.15943, 50.1428],
              [8.15899, 50.14284],
              [8.15883, 50.14316],
              [8.15851, 50.14338],
              [8.15731, 50.14362],
              [8.15565, 50.14453],
              [8.15553, 50.14462],
              [8.15527, 50.14502],
              [8.15504, 50.14538],
              [8.15495, 50.14552],
              [8.15433, 50.14655],
              [8.15363, 50.14744],
              [8.15358, 50.14783],
              [8.15387, 50.14812],
              [8.15431, 50.14826],
              [8.15615, 50.14872],
              [8.15594, 50.14913],
              [8.155758, 50.149465],
              [8.15594, 50.14913],
              [8.15615, 50.14872],
              [8.15638, 50.14878],
              [8.15726, 50.14899],
              [8.15852, 50.1493],
              [8.16051, 50.14985],
              [8.16099, 50.15003],
              [8.16151, 50.15023],
              [8.16215, 50.15062],
              [8.16234, 50.15086],
              [8.16241, 50.15113],
              [8.16176, 50.152],
              [8.16082, 50.15285],
              [8.1599, 50.15323],
              [8.15897, 50.15353],
              [8.15821, 50.154],
              [8.15772, 50.15486],
              [8.15717, 50.15535],
              [8.15658, 50.15578],
              [8.15629, 50.15632],
              [8.15597, 50.15717],
              [8.15547, 50.15794],
              [8.15514, 50.15937],
              [8.15455, 50.16001],
              [8.15381, 50.16029],
              [8.15281, 50.16044],
              [8.15189, 50.16081],
              [8.1516, 50.16103],
              [8.15121, 50.16167],
              [8.15096, 50.16289],
              [8.15066, 50.164],
              [8.1505, 50.16443],
              [8.15005, 50.16522],
              [8.14982, 50.1655],
              [8.14951, 50.16586],
              [8.14911, 50.16636],
              [8.14856, 50.16626],
              [8.14869, 50.16666],
              [8.14856, 50.16626],
              [8.14911, 50.16636],
              [8.149, 50.16653],
              [8.14883, 50.1669],
              [8.1488, 50.16699],
              [8.14869, 50.16753],
              [8.14862, 50.16791],
              [8.14861, 50.16802],
              [8.14857, 50.16872],
              [8.14882, 50.16927],
              [8.14891, 50.16937],
              [8.14902, 50.16949],
              [8.14929, 50.1697],
              [8.15072, 50.17043],
              [8.15136, 50.17093],
              [8.15159, 50.17132],
              [8.1516, 50.17187],
              [8.1512, 50.17274],
              [8.15117, 50.17341],
              [8.15128, 50.17413],
              [8.15134, 50.1742],
              [8.15205, 50.17488],
              [8.15341, 50.17542],
              [8.15401, 50.17576],
              [8.15477, 50.17679],
              [8.15601, 50.17749],
              [8.15653, 50.17775],
              [8.1571, 50.17813],
              [8.15734, 50.1786],
              [8.15742, 50.17896],
              [8.15702, 50.17926],
              [8.15637, 50.17927],
              [8.154, 50.17941],
              [8.15138, 50.17889],
              [8.15051, 50.17895],
              [8.14951, 50.17939],
              [8.14644, 50.18152],
              [8.14605, 50.18206],
              [8.14531, 50.18361],
              [8.14495, 50.18404],
              [8.14355, 50.18521],
              [8.1421, 50.18624],
              [8.14103, 50.18681],
              [8.1392, 50.18733],
              [8.13231, 50.18878],
              [8.13074, 50.18916],
              [8.12975, 50.18922],
              [8.12857, 50.18926],
              [8.12773, 50.18925],
              [8.12685, 50.18919],
              [8.12577, 50.18951],
              [8.12502, 50.18976],
              [8.12449, 50.1899],
              [8.12366, 50.19016],
              [8.12316, 50.19032],
              [8.12242, 50.19056],
              [8.12231, 50.1906],
              [8.12172, 50.19079],
              [8.12152, 50.19086],
              [8.12057, 50.19113],
              [8.11914, 50.19127],
              [8.1147, 50.19153],
              [8.11351, 50.19158],
              [8.10813, 50.19182],
              [8.10733, 50.19173],
              [8.10602, 50.19155],
              [8.10479, 50.19151],
              [8.10323, 50.19164],
              [8.10211, 50.19171],
              [8.10127, 50.1918],
              [8.1008, 50.19204],
              [8.10067, 50.19219],
              [8.10058, 50.19229],
              [8.10036, 50.19258],
              [8.09982, 50.1932],
              [8.10014, 50.19368],
              [8.10014, 50.19381],
              [8.09977, 50.19405],
              [8.09847, 50.19462],
              [8.09786, 50.19503],
              [8.09761, 50.19522],
              [8.09708, 50.19569],
              [8.09789, 50.19615],
              [8.09814, 50.19644],
              [8.09796, 50.19677],
              [8.09749, 50.1975],
              [8.09727, 50.19787],
              [8.09728, 50.19794],
              [8.09736, 50.19806],
              [8.097864, 50.19837],
              [8.09819, 50.19857],
              [8.09832, 50.19892],
              [8.09822, 50.19998],
              [8.09794, 50.20182],
              [8.09676, 50.20421],
              [8.09595, 50.20519],
              [8.09542, 50.20603],
              [8.09409, 50.20741],
              [8.09247, 50.20868],
              [8.0921, 50.20897],
              [8.09188, 50.20912],
              [8.0908, 50.20862],
              [8.08966, 50.20939],
              [8.08896, 50.20969],
              [8.0887, 50.20956],
              [8.0883, 50.20949],
              [8.08815, 50.20954],
              [8.08807, 50.20968],
              [8.08805, 50.21008],
              [8.08781, 50.21113],
              [8.08774, 50.21119],
              [8.0872, 50.21167],
              [8.08718, 50.21215],
              [8.08722, 50.21268],
              [8.08724, 50.21343],
              [8.08727, 50.21429],
              [8.08719, 50.2145],
              [8.0867, 50.21485],
              [8.08456, 50.21568],
              [8.08382, 50.2162],
              [8.08319, 50.2169],
              [8.08285, 50.21759],
              [8.08267, 50.21832],
              [8.08158, 50.21893],
              [8.08116, 50.21946],
              [8.08072, 50.22048],
              [8.0801, 50.22084],
              [8.07899, 50.22117],
              [8.0758, 50.22205],
              [8.07485, 50.22269],
              [8.07418, 50.22315],
              [8.07323, 50.22336],
              [8.07203, 50.22372],
              [8.07154, 50.22405],
              [8.07138, 50.22444],
              [8.07143, 50.22512],
              [8.07146, 50.22567],
              [8.07119, 50.22602],
              [8.07014, 50.2267],
              [8.06874, 50.227],
              [8.06845, 50.22706],
              [8.06743, 50.2272],
              [8.06708, 50.22725],
              [8.06599, 50.22743],
              [8.06523, 50.22756],
              [8.06429, 50.22768],
              [8.06214, 50.22801],
              [8.06129, 50.22828],
              [8.06053, 50.22863],
              [8.05998, 50.2291],
              [8.05894, 50.22983],
              [8.05883, 50.23001],
              [8.05877, 50.23014],
              [8.05862, 50.23065],
              [8.05858, 50.23075],
              [8.05848, 50.23131],
              [8.05829, 50.23259],
              [8.05821, 50.23294],
              [8.05818, 50.23309],
              [8.05809, 50.23345],
              [8.05793, 50.23397],
              [8.0588, 50.23429],
              [8.0595, 50.23469],
              [8.06004, 50.23516],
              [8.06046, 50.23578],
              [8.06065, 50.23656],
              [8.06053, 50.2373],
              [8.06034, 50.23768],
              [8.05994, 50.23843],
              [8.0598, 50.2391],
              [8.05987, 50.23941],
              [8.06018, 50.23994],
              [8.060556, 50.24035],
              [8.06086, 50.24068],
              [8.06103, 50.24086],
              [8.0612, 50.24104],
              [8.06298, 50.24249],
              [8.06434, 50.24321],
              [8.06444, 50.2433],
              [8.06453, 50.24364],
              [8.06443, 50.24398],
              [8.0639, 50.24509],
              [8.06343, 50.24556],
              [8.06165, 50.24662],
              [8.06074, 50.24745],
              [8.06028, 50.24791],
              [8.05961, 50.24868],
              [8.05952, 50.24882],
              [8.059335, 50.24913],
              [8.059, 50.24969],
              [8.05862, 50.25071],
              [8.05845, 50.25097],
              [8.05778, 50.2518],
              [8.05702, 50.25253],
              [8.05578, 50.25498],
              [8.05568, 50.25555],
              [8.05586, 50.25702],
              [8.05572, 50.25757],
              [8.05517, 50.25815],
              [8.05405, 50.25886],
              [8.05368, 50.25929],
              [8.05346, 50.25987],
              [8.05344, 50.2607],
              [8.05346, 50.26118],
              [8.05327, 50.26231],
              [8.05325, 50.26238],
              [8.05309, 50.2627],
              [8.05295, 50.26286],
              [8.05226, 50.26394],
              [8.05231, 50.26435],
              [8.05257, 50.26463],
              [8.05303, 50.26507],
              [8.05326, 50.26532],
              [8.053361, 50.265421],
              [8.0536, 50.26566],
              [8.05385, 50.26587],
              [8.05426, 50.26638],
              [8.0543, 50.26653],
              [8.05434, 50.26673],
              [8.05432, 50.26718],
              [8.0543, 50.26753],
              [8.05425, 50.26876],
              [8.05455, 50.26939],
              [8.05503, 50.27006],
              [8.05513, 50.27073],
              [8.05512, 50.27216],
              [8.05538, 50.27286],
              [8.05595, 50.27368],
              [8.05649, 50.27442],
              [8.05697, 50.2749],
              [8.05725, 50.27512],
              [8.05756, 50.275375],
              [8.05787, 50.27563],
              [8.05804, 50.27615],
              [8.05804, 50.27663],
              [8.05801, 50.27695],
              [8.058, 50.27718],
              [8.05802, 50.27748],
              [8.05803, 50.27766],
              [8.05806, 50.27815],
              [8.05833, 50.27937],
              [8.05901, 50.2808],
              [8.05954, 50.28128],
              [8.0604, 50.2819],
              [8.06076, 50.28249],
              [8.0608, 50.28355],
              [8.06079, 50.28379],
              [8.06079, 50.2846],
              [8.06072, 50.284957],
              [8.06059, 50.28562],
              [8.06062, 50.28594],
              [8.0607, 50.2862],
              [8.06094, 50.28659],
              [8.06117, 50.28692],
              [8.06121, 50.28702],
              [8.06125, 50.2871],
              [8.06141, 50.28768],
              [8.06158, 50.28795],
              [8.06172, 50.28835],
              [8.061582, 50.289231],
              [8.06151, 50.28969],
              [8.06152, 50.2903],
              [8.06146, 50.29087],
              [8.06127, 50.29168],
              [8.06113, 50.29208],
              [8.06103, 50.29243],
              [8.06143, 50.29331],
              [8.0614, 50.29406],
              [8.06132, 50.29435],
              [8.06115, 50.29492],
              [8.06111, 50.29553],
              [8.06117, 50.2961],
              [8.06125, 50.29672],
              [8.061271, 50.296932],
              [8.06129, 50.29713],
              [8.06136, 50.29739],
              [8.06156, 50.298],
              [8.06162, 50.29814],
              [8.06199, 50.29897],
              [8.06218, 50.29993],
              [8.06236, 50.30024],
              [8.06256, 50.30051],
              [8.06267, 50.30065],
              [8.06276, 50.30077],
              [8.06318, 50.30183],
              [8.06473, 50.3039],
              [8.06495, 50.30414],
              [8.06531, 50.30459],
              [8.06601, 50.30496],
              [8.06607, 50.30503],
              [8.0666, 50.30572],
              [8.06671, 50.30596],
              [8.06675, 50.30638],
              [8.0667, 50.30671],
              [8.0667, 50.30681],
              [8.06674, 50.30755],
              [8.0669, 50.30791],
              [8.06693, 50.30797],
              [8.06703, 50.30821],
              [8.06705, 50.30828],
              [8.0671, 50.3085],
              [8.0671, 50.30874],
              [8.06696, 50.30915],
              [8.06661, 50.30954],
              [8.06635, 50.30981],
              [8.06594, 50.31023],
              [8.06578, 50.31043],
              [8.06556, 50.31082],
              [8.06557, 50.31168],
              [8.06572, 50.31255],
              [8.06595, 50.31377],
              [8.06595, 50.31395],
              [8.06611, 50.31538],
              [8.06606, 50.31602],
              [8.06632, 50.31653],
              [8.06683, 50.31727],
              [8.06687, 50.31734],
              [8.06693, 50.31739],
              [8.06757, 50.31781],
              [8.0677, 50.31789],
              [8.06828, 50.31823],
              [8.06872, 50.31879],
              [8.06896, 50.31941],
              [8.06891, 50.32006],
              [8.06875, 50.32049],
              [8.06763, 50.32173],
              [8.06723, 50.32284],
              [8.06684, 50.32363],
              [8.06669, 50.323857],
              [8.06614, 50.32469],
              [8.0658, 50.32517],
              [8.06531, 50.32564],
              [8.06473, 50.32607],
              [8.06447, 50.32627],
              [8.06275, 50.32752],
              [8.06161, 50.32799],
              [8.06018, 50.32861],
              [8.05921, 50.32939],
              [8.05903, 50.32964],
              [8.05854, 50.33044],
              [8.05687, 50.33158],
              [8.05644, 50.33208],
              [8.05617, 50.33255],
              [8.05605, 50.33302],
              [8.05601, 50.33315],
              [8.05586, 50.33407],
              [8.05573, 50.33466],
              [8.05555, 50.33483],
              [8.05551, 50.33487],
              [8.05528, 50.33504],
              [8.05505, 50.33528],
              [8.05492, 50.33537],
              [8.05405, 50.33618],
              [8.05387, 50.33646],
              [8.05352, 50.33693],
              [8.05315, 50.33743],
              [8.052862, 50.3378],
              [8.05276, 50.33793],
              [8.05239, 50.33849],
              [8.05194, 50.33951],
              [8.05172, 50.33979],
              [8.05155, 50.34007],
              [8.05114, 50.34066],
              [8.05074, 50.3412],
              [8.05058, 50.34145],
              [8.05037, 50.34178],
              [8.05036, 50.34214],
              [8.05, 50.34291],
              [8.04982, 50.34327],
              [8.04956, 50.34374],
              [8.04948, 50.34386],
              [8.04922, 50.34456],
              [8.04877, 50.345],
              [8.04768, 50.34654],
              [8.04783, 50.34752],
              [8.04789, 50.34796],
              [8.04789, 50.34848],
              [8.04778, 50.34892],
              [8.0476, 50.34932],
              [8.04735, 50.35013],
              [8.04725, 50.35077],
              [8.04711, 50.35101],
              [8.047043, 50.351071],
              [8.04666, 50.35142],
              [8.04647, 50.35154],
              [8.0459, 50.35194],
              [8.04477, 50.3524],
              [8.04461, 50.35245],
              [8.04378, 50.35271],
              [8.04257, 50.35308],
              [8.03982, 50.35353],
              [8.03915, 50.35358],
              [8.0377, 50.35373],
              [8.037, 50.35407],
              [8.03666, 50.3545],
              [8.03633, 50.35497],
              [8.03497, 50.35678],
              [8.03367, 50.35766],
              [8.03333, 50.35801],
              [8.03252, 50.35956],
              [8.03202, 50.36027],
              [8.03175, 50.36087],
              [8.03168, 50.36116],
              [8.0313, 50.3612],
              [8.03077, 50.36148],
              [8.03029, 50.36206],
              [8.02966, 50.36275],
              [8.0306, 50.36375],
              [8.03073, 50.3642],
              [8.03029, 50.36522],
              [8.02993, 50.36588],
              [8.02943, 50.36685],
              [8.02929, 50.36712],
              [8.029288, 50.367125],
              [8.02893, 50.36783],
              [8.02885, 50.36798],
              [8.02851, 50.36847],
              [8.02816, 50.36862],
              [8.02719, 50.36892],
              [8.025752, 50.369378],
              [8.02537, 50.3695],
              [8.02526, 50.36953],
              [8.02415, 50.3699],
              [8.02404, 50.37009],
              [8.02523, 50.37033],
              [8.02549, 50.37037],
              [8.02561, 50.37039],
              [8.02628, 50.37051],
              [8.02707, 50.37067],
              [8.02749, 50.37075],
              [8.0285, 50.37098],
              [8.02883, 50.37112],
              [8.02903, 50.3712],
              [8.02917, 50.37126],
              [8.02943, 50.37138],
              [8.0296, 50.37146],
              [8.03013, 50.37166],
              [8.03053, 50.371785],
              [8.03228, 50.37233],
              [8.03334, 50.37239],
              [8.03348, 50.37276],
              [8.03361, 50.37285],
              [8.03429, 50.37323],
              [8.03546, 50.37389],
              [8.035998, 50.374168],
              [8.0367, 50.37453],
              [8.03712, 50.37458],
              [8.03812, 50.37459],
              [8.03851, 50.37457],
              [8.03946, 50.37443],
              [8.040745, 50.374203],
              [8.0411, 50.37414],
              [8.04314, 50.37409],
              [8.04372, 50.37414],
              [8.04472, 50.37407],
              [8.04733, 50.373913],
              [8.04771, 50.37389],
              [8.0482, 50.37386],
              [8.0488, 50.37382],
              [8.05036, 50.37378],
              [8.05087, 50.3739],
              [8.05155, 50.37426],
              [8.05203, 50.37473],
              [8.05248, 50.37519],
              [8.05256, 50.37528],
              [8.0538, 50.37657],
              [8.05768, 50.3807],
              [8.05791, 50.38124],
              [8.05809, 50.38184],
              [8.05818, 50.38193],
              [8.058464, 50.38203],
              [8.06021, 50.38264],
              [8.06199, 50.38324],
              [8.06235, 50.38336],
              [8.06314, 50.38382],
              [8.06354, 50.38413],
              [8.06378, 50.38434],
              [8.06413, 50.38511],
              [8.06394, 50.38495],
              [8.06316, 50.38513],
              [8.062772, 50.38522],
            ],
          ],
        },
        id: "I3Njg",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.EXPRESS,
    id: "cwMTc",
    name: "X26 Bad Homburg <> Wiesbaden",
    lineName: "X26",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.20825, 50.08436],
              [8.20872, 50.08415],
              [8.21007, 50.08364],
              [8.21173, 50.083],
              [8.21466, 50.08234],
              [8.21553, 50.08232],
              [8.21592, 50.08205],
              [8.21659, 50.08186],
              [8.21771, 50.08167],
              [8.21822, 50.08156],
              [8.21862, 50.08145],
              [8.21878, 50.08139],
              [8.21884, 50.08148],
              [8.22227, 50.08002],
              [8.22394, 50.07924],
              [8.22422, 50.07969],
              [8.22433, 50.07999],
              [8.22461, 50.08059],
              [8.224955, 50.080535],
              [8.22604, 50.08036],
              [8.22687, 50.08041],
              [8.22757, 50.08043],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22983, 50.07742],
              [8.22986, 50.07721],
              [8.22989, 50.07706],
              [8.229975, 50.076791],
              [8.23143, 50.07437],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.25112, 50.07194],
              [8.25181, 50.07198],
              [8.25311, 50.07204],
              [8.25403, 50.07209],
              [8.25487, 50.07212],
              [8.25494, 50.07212],
              [8.25662, 50.07215],
              [8.25749, 50.07199],
              [8.25808, 50.07153],
              [8.26015, 50.06999],
              [8.26098, 50.06939],
              [8.26144, 50.06909],
              [8.2622, 50.06868],
              [8.26323, 50.06821],
              [8.26469, 50.06766],
              [8.2676, 50.06659],
              [8.2685, 50.06624],
              [8.2716, 50.06513],
              [8.27346, 50.06446],
              [8.27435, 50.06412],
              [8.27631, 50.06341],
              [8.27966, 50.06219],
              [8.28254, 50.0609],
              [8.28263, 50.06098],
              [8.28426, 50.05985],
              [8.2855, 50.05858],
              [8.28613, 50.05769],
              [8.2866, 50.05689],
              [8.28677, 50.05657],
              [8.28748, 50.05531],
              [8.28857, 50.05377],
              [8.28998, 50.05232],
              [8.29047, 50.05191],
              [8.29171, 50.05087],
              [8.29247, 50.05026],
              [8.29389, 50.04895],
              [8.29457, 50.04865],
              [8.2952, 50.04872],
              [8.29547, 50.049],
              [8.29538, 50.04932],
              [8.29496, 50.04953],
              [8.29456, 50.0496],
              [8.29715, 50.0496],
              [8.29916, 50.0499],
              [8.30033, 50.0501],
              [8.3007, 50.05018],
              [8.3031, 50.0509],
              [8.3052, 50.05186],
              [8.30705, 50.05277],
              [8.30748, 50.05297],
              [8.30808, 50.05325],
              [8.31036, 50.05425],
              [8.31094, 50.05448],
              [8.31242, 50.05505],
              [8.31392, 50.05547],
              [8.31523, 50.05573],
              [8.31813, 50.05601],
              [8.3239, 50.05579],
              [8.32604, 50.05575],
              [8.33122, 50.05574],
              [8.33457, 50.05573],
              [8.33636, 50.05573],
              [8.33726, 50.05572],
              [8.3391, 50.05572],
              [8.33972, 50.05571],
              [8.33985, 50.05571],
              [8.34028, 50.05572],
              [8.34103, 50.05571],
              [8.34301, 50.0557],
              [8.34376, 50.0557],
              [8.34531, 50.05569],
              [8.36133, 50.05565],
              [8.36666, 50.05565],
              [8.36886, 50.05564],
              [8.36945, 50.05564],
              [8.3703, 50.05563],
              [8.37093, 50.05563],
              [8.37259, 50.05563],
              [8.37374, 50.05562],
              [8.37784, 50.05561],
              [8.37997, 50.0556],
              [8.38022, 50.0556],
              [8.38051, 50.0556],
              [8.38381, 50.0556],
              [8.38682, 50.0556],
              [8.38775, 50.05559],
              [8.38907, 50.05559],
              [8.39241, 50.05556],
              [8.39309, 50.05556],
              [8.39445, 50.05556],
              [8.39458, 50.05556],
              [8.39621, 50.05556],
              [8.39696, 50.05554],
              [8.39828, 50.05551],
              [8.39956, 50.05549],
              [8.40013, 50.05543],
              [8.40101, 50.05527],
              [8.4017, 50.05481],
              [8.40185, 50.05466],
              [8.40229, 50.05448],
              [8.40293, 50.05457],
              [8.40316, 50.05478],
              [8.40353, 50.05529],
              [8.40637, 50.05643],
              [8.40733, 50.05682],
              [8.40758, 50.05691],
              [8.40858, 50.05729],
              [8.40928, 50.05757],
              [8.41104, 50.05823],
              [8.41118, 50.05829],
              [8.4121, 50.05867],
              [8.41217, 50.05883],
              [8.41242, 50.05879],
              [8.41255, 50.05883],
              [8.41362, 50.05921],
              [8.41437, 50.05951],
              [8.4153, 50.05986],
              [8.41637, 50.06028],
              [8.41671, 50.06041],
              [8.41722, 50.06061],
              [8.41801, 50.06091],
              [8.41859, 50.06111],
              [8.4199, 50.06157],
              [8.42012, 50.06164],
              [8.42085, 50.06196],
              [8.42098, 50.06201],
              [8.42111, 50.06206],
              [8.42169, 50.06229],
              [8.42233, 50.06253],
              [8.42249, 50.06259],
              [8.4226, 50.06264],
              [8.423248, 50.062898],
              [8.42378, 50.06311],
              [8.42384, 50.06314],
              [8.42468, 50.06378],
              [8.42494, 50.06416],
              [8.4251, 50.06439],
              [8.42547, 50.0648],
              [8.42606, 50.06519],
              [8.42828, 50.06631],
              [8.42967, 50.06733],
              [8.43032, 50.06771],
              [8.43127, 50.06798],
              [8.43217, 50.06802],
              [8.43336, 50.06781],
              [8.43398, 50.06765],
              [8.43621, 50.06707],
              [8.43624, 50.06769],
              [8.43609, 50.06858],
              [8.43604, 50.06877],
              [8.43594, 50.06924],
              [8.43585, 50.06991],
              [8.436, 50.07008],
              [8.43615, 50.07024],
              [8.43676, 50.07076],
              [8.43753, 50.07144],
              [8.43769, 50.07168],
              [8.43767, 50.07202],
              [8.43737, 50.07294],
              [8.43728, 50.07321],
              [8.43716, 50.07414],
              [8.43715, 50.07438],
              [8.43718, 50.07453],
              [8.437283, 50.074895],
              [8.4376, 50.07602],
              [8.43784, 50.07677],
              [8.43794, 50.07738],
              [8.43796, 50.07749],
              [8.438289, 50.078918],
              [8.43838, 50.07931],
              [8.4384, 50.07942],
              [8.43846, 50.07973],
              [8.43859, 50.08033],
              [8.43873, 50.08063],
              [8.43909, 50.08102],
              [8.43932, 50.08124],
              [8.43954, 50.08146],
              [8.43986, 50.08178],
              [8.44054, 50.08244],
              [8.44071, 50.08264],
              [8.44115, 50.08338],
              [8.44127, 50.08371],
              [8.44133, 50.08408],
              [8.44135, 50.0842],
              [8.44136, 50.08429],
              [8.44171, 50.08476],
              [8.44176, 50.0848],
              [8.44228, 50.08499],
              [8.44303, 50.08489],
              [8.44351, 50.08495],
              [8.44363, 50.08496],
              [8.44385, 50.08494],
              [8.44407, 50.08482],
              [8.445096, 50.084466],
              [8.44546, 50.08434],
              [8.446, 50.08444],
              [8.44644, 50.08477],
              [8.44666, 50.08492],
              [8.44681, 50.08506],
              [8.44699, 50.08526],
              [8.44719, 50.08554],
              [8.44731, 50.08569],
              [8.44781, 50.08631],
              [8.44792, 50.08644],
              [8.44824, 50.08684],
              [8.44834, 50.08696],
              [8.44844, 50.08711],
              [8.44902, 50.08793],
              [8.44966, 50.08892],
              [8.44975, 50.08906],
              [8.4492, 50.08927],
              [8.45071, 50.09001],
              [8.45164, 50.09047],
              [8.45176, 50.09054],
              [8.4527, 50.09109],
              [8.45287, 50.09119],
              [8.453, 50.09127],
              [8.45377, 50.09175],
              [8.45419, 50.09197],
              [8.45474, 50.09229],
              [8.45504, 50.09247],
              [8.45512, 50.09251],
              [8.45601, 50.09301],
              [8.45611, 50.09307],
              [8.458549, 50.094435],
              [8.45913, 50.09476],
              [8.45935, 50.09489],
              [8.45949, 50.09497],
              [8.46039, 50.09546],
              [8.46102, 50.09582],
              [8.46206, 50.09638],
              [8.463, 50.09688],
              [8.46387, 50.09735],
              [8.46482, 50.09791],
              [8.46534, 50.09852],
              [8.46568, 50.09891],
              [8.46602, 50.0993],
              [8.46611, 50.09941],
              [8.46685, 50.10028],
              [8.46786, 50.10161],
              [8.46867, 50.10302],
              [8.468804, 50.103256],
              [8.46897, 50.10355],
              [8.47078, 50.10717],
              [8.47166, 50.10898],
              [8.47225, 50.11022],
              [8.47252, 50.1108],
              [8.47382, 50.11319],
              [8.4746, 50.11427],
              [8.47514, 50.11488],
              [8.47547, 50.11524],
              [8.47662, 50.1166],
              [8.47717, 50.11756],
              [8.47745, 50.11863],
              [8.47737, 50.11969],
              [8.47709, 50.12051],
              [8.47688, 50.12087],
              [8.47636, 50.12159],
              [8.47565, 50.12251],
              [8.47538, 50.12286],
              [8.47473, 50.12369],
              [8.47443, 50.12414],
              [8.47386, 50.12512],
              [8.47364, 50.12566],
              [8.47309, 50.12714],
              [8.47295, 50.12756],
              [8.47264, 50.12837],
              [8.47255, 50.1286],
              [8.47172, 50.1309],
              [8.4715, 50.1314],
              [8.47074, 50.13245],
              [8.47063, 50.13256],
              [8.47017, 50.13299],
              [8.46756, 50.135],
              [8.46681, 50.13559],
              [8.46608, 50.13618],
              [8.46529, 50.13681],
              [8.46414, 50.13777],
              [8.46182, 50.13978],
              [8.4611, 50.14068],
              [8.46054, 50.14169],
              [8.45997, 50.14394],
              [8.4597, 50.146],
              [8.45968, 50.14636],
              [8.45966, 50.14727],
              [8.45967, 50.14787],
              [8.45971, 50.14863],
              [8.45983, 50.14974],
              [8.45987, 50.14999],
              [8.45989, 50.15013],
              [8.45994, 50.15049],
              [8.46011, 50.15151],
              [8.46039, 50.15253],
              [8.46051, 50.15294],
              [8.46072, 50.15394],
              [8.46077, 50.15417],
              [8.46089, 50.15501],
              [8.46092, 50.15583],
              [8.46089, 50.15629],
              [8.46086, 50.15674],
              [8.46085, 50.15719],
              [8.46086, 50.15762],
              [8.46087, 50.1583],
              [8.46099, 50.15955],
              [8.46133, 50.16179],
              [8.46193, 50.16385],
              [8.46222, 50.16553],
              [8.46242, 50.1662],
              [8.46262, 50.16666],
              [8.46327, 50.1679],
              [8.4635, 50.16818],
              [8.46378, 50.16848],
              [8.46414, 50.16876],
              [8.46426, 50.16886],
              [8.4655, 50.16951],
              [8.4662, 50.16975],
              [8.46863, 50.17012],
              [8.47054, 50.17044],
              [8.47157, 50.17077],
              [8.47202, 50.17098],
              [8.47301, 50.17165],
              [8.47342, 50.17209],
              [8.47364, 50.17241],
              [8.47382, 50.17271],
              [8.47408, 50.17348],
              [8.47422, 50.17403],
              [8.47425, 50.17417],
              [8.4745, 50.17509],
              [8.47487, 50.17663],
              [8.47507, 50.1774],
              [8.47532, 50.17855],
              [8.47534, 50.17902],
              [8.47527, 50.17925],
              [8.47529, 50.17949],
              [8.47539, 50.17981],
              [8.47542, 50.17985],
              [8.47561, 50.18001],
              [8.47587, 50.18023],
              [8.47688, 50.18051],
              [8.47844, 50.18085],
              [8.47978, 50.18133],
              [8.47998, 50.18144],
              [8.48103, 50.18209],
              [8.48193, 50.18276],
              [8.48232, 50.18304],
              [8.48342, 50.18388],
              [8.483708, 50.184109],
              [8.48425, 50.18454],
              [8.48441, 50.18466],
              [8.48497, 50.18499],
              [8.48733, 50.1857],
              [8.49137, 50.18676],
              [8.49371, 50.18716],
              [8.49485, 50.1874],
              [8.49635, 50.18771],
              [8.49674, 50.1878],
              [8.49874, 50.18834],
              [8.50297, 50.18967],
              [8.50432, 50.19007],
              [8.50616, 50.19056],
              [8.51051, 50.19184],
              [8.51272, 50.19257],
              [8.51367, 50.19288],
              [8.51408, 50.19299],
              [8.51458, 50.19312],
              [8.51541, 50.19329],
              [8.516, 50.19338],
              [8.5174, 50.19345],
              [8.51831, 50.19349],
              [8.51933, 50.19362],
              [8.52097, 50.19426],
              [8.52303, 50.19522],
              [8.52568, 50.19625],
              [8.52617, 50.19644],
              [8.52705, 50.19681],
              [8.5281, 50.19721],
              [8.52859, 50.19739],
              [8.53135, 50.19812],
              [8.53246, 50.19842],
              [8.53259, 50.19832],
              [8.53322, 50.19808],
              [8.53486, 50.19796],
              [8.53634, 50.19785],
              [8.53831, 50.19767],
              [8.54336, 50.19726],
              [8.54468, 50.19717],
              [8.54648, 50.1971],
              [8.54767, 50.1973],
              [8.54806, 50.19744],
              [8.55041, 50.19878],
              [8.55185, 50.199],
              [8.55261, 50.19907],
              [8.55387, 50.19919],
              [8.55827, 50.19969],
              [8.55929, 50.1998],
              [8.55992, 50.19986],
              [8.56283, 50.20015],
              [8.5633, 50.2002],
              [8.56459, 50.20032],
              [8.564736, 50.200336],
              [8.56629, 50.2005],
              [8.56739, 50.20061],
              [8.56985, 50.20088],
              [8.57137, 50.20103],
              [8.57164, 50.20105],
              [8.57294, 50.2012],
              [8.57411, 50.20132],
              [8.57484, 50.20147],
              [8.5752, 50.20137],
              [8.57543, 50.20129],
              [8.57764, 50.19997],
              [8.57869, 50.19927],
              [8.57997, 50.19977],
              [8.58071, 50.20006],
              [8.58126, 50.20027],
              [8.58173, 50.20045],
              [8.58258, 50.20074],
              [8.58295, 50.20074],
              [8.58345, 50.20031],
              [8.58353, 50.20025],
              [8.58482, 50.19942],
              [8.58607, 50.19861],
              [8.5862, 50.19869],
              [8.58647, 50.19885],
              [8.58656, 50.19891],
              [8.5874, 50.19943],
              [8.58755, 50.19952],
              [8.58817, 50.19994],
              [8.58891, 50.20037],
              [8.58904, 50.20045],
              [8.58918, 50.20054],
              [8.5899, 50.20084],
              [8.59042, 50.20102],
              [8.590744, 50.201139],
              [8.59194, 50.20158],
              [8.59281, 50.20187],
              [8.59364, 50.20217],
              [8.59393, 50.20234],
              [8.59503, 50.20258],
              [8.5966, 50.20302],
              [8.59745, 50.20347],
              [8.598513, 50.204223],
              [8.59858, 50.20427],
              [8.59903, 50.20474],
              [8.59956, 50.2054],
              [8.59994, 50.20603],
              [8.60034, 50.20673],
              [8.60151, 50.20842],
              [8.60182, 50.20877],
              [8.60314, 50.21012],
              [8.60382, 50.21075],
              [8.60465, 50.21152],
              [8.60559, 50.21282],
              [8.6059, 50.21369],
              [8.60603, 50.21434],
              [8.60605, 50.21443],
              [8.60624, 50.21549],
              [8.60626, 50.21561],
              [8.60632, 50.21598],
              [8.60659, 50.21734],
              [8.60672, 50.21793],
              [8.60686, 50.21857],
              [8.60719, 50.21976],
              [8.60734, 50.2204],
              [8.60742, 50.22137],
              [8.60734, 50.22174],
              [8.60691, 50.22259],
              [8.60705, 50.2226],
              [8.60699, 50.22279],
              [8.60694, 50.22345],
              [8.60692, 50.22382],
              [8.60687, 50.22417],
              [8.60667, 50.22552],
              [8.60669, 50.22569],
              [8.60675, 50.22595],
              [8.60672, 50.22608],
              [8.60587, 50.22681],
              [8.60573, 50.22726],
              [8.60572, 50.22758],
              [8.60583, 50.22841],
              [8.60592, 50.22903],
              [8.60615, 50.2296],
              [8.60632, 50.22976],
              [8.60746, 50.23037],
              [8.60771, 50.23062],
              [8.60785, 50.23098],
              [8.6085, 50.23049],
              [8.60962, 50.2296],
              [8.60999, 50.22944],
              [8.61027, 50.22938],
              [8.61046, 50.22934],
              [8.61078, 50.22929],
              [8.61187, 50.22898],
              [8.61242, 50.22875],
              [8.61262, 50.22897],
              [8.61278, 50.22912],
              [8.61364, 50.22999],
              [8.61419, 50.23054],
              [8.61504, 50.23007],
              [8.61541, 50.22984],
              [8.61682, 50.22902],
              [8.61773, 50.2285],
              [8.61711, 50.22805],
              [8.61693, 50.22789],
              [8.61683, 50.22781],
              [8.61613, 50.22729],
              [8.61595, 50.22715],
              [8.616269, 50.227004],
              [8.61772, 50.22634],
              [8.62037, 50.22516],
              [8.62226, 50.22433],
              [8.62147, 50.22363],
              [8.621132, 50.223151],
              [8.62085, 50.22275],
              [8.62083, 50.22264],
              [8.62007, 50.22131],
              [8.62046, 50.22125],
              [8.62032, 50.22078],
              [8.62043, 50.22067],
            ],
          ],
        },
        id: "cwMTc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.62043, 50.22067],
              [8.62032, 50.22078],
              [8.62046, 50.22125],
              [8.62007, 50.22131],
              [8.62083, 50.22264],
              [8.62085, 50.22275],
              [8.621132, 50.223151],
              [8.62147, 50.22363],
              [8.62226, 50.22433],
              [8.62037, 50.22516],
              [8.61772, 50.22634],
              [8.616269, 50.227004],
              [8.61595, 50.22715],
              [8.61613, 50.22729],
              [8.61683, 50.22781],
              [8.61693, 50.22789],
              [8.61711, 50.22805],
              [8.61773, 50.2285],
              [8.61682, 50.22902],
              [8.61541, 50.22984],
              [8.61504, 50.23007],
              [8.61419, 50.23054],
              [8.61252, 50.2315],
              [8.611689, 50.232167],
              [8.6109, 50.2328],
              [8.6108, 50.23288],
              [8.60961, 50.23237],
              [8.60823, 50.23177],
              [8.607948, 50.231547],
              [8.60794, 50.23154],
              [8.6077, 50.23105],
              [8.60767, 50.23088],
              [8.60731, 50.23039],
              [8.6066, 50.23003],
              [8.60624, 50.22984],
              [8.60616, 50.22979],
              [8.60593, 50.22957],
              [8.60579, 50.22903],
              [8.60568, 50.22841],
              [8.60557, 50.22759],
              [8.60561, 50.22701],
              [8.60589, 50.2266],
              [8.60629, 50.22624],
              [8.60646, 50.22601],
              [8.60653, 50.22581],
              [8.6067, 50.22444],
              [8.60673, 50.22401],
              [8.60675, 50.2238],
              [8.60681, 50.22277],
              [8.60691, 50.22259],
              [8.60734, 50.22174],
              [8.60742, 50.22137],
              [8.60734, 50.2204],
              [8.60719, 50.21976],
              [8.60686, 50.21857],
              [8.60672, 50.21793],
              [8.60659, 50.21734],
              [8.60632, 50.21598],
              [8.60626, 50.21561],
              [8.60624, 50.21549],
              [8.60605, 50.21443],
              [8.60603, 50.21434],
              [8.6059, 50.21369],
              [8.60559, 50.21282],
              [8.60465, 50.21152],
              [8.60382, 50.21075],
              [8.60314, 50.21012],
              [8.60182, 50.20877],
              [8.60151, 50.20842],
              [8.60034, 50.20673],
              [8.59994, 50.20603],
              [8.59956, 50.2054],
              [8.59903, 50.20474],
              [8.59858, 50.20427],
              [8.598513, 50.204223],
              [8.59745, 50.20347],
              [8.5966, 50.20302],
              [8.59503, 50.20258],
              [8.59393, 50.20234],
              [8.59364, 50.20217],
              [8.59281, 50.20187],
              [8.59194, 50.20158],
              [8.590744, 50.201139],
              [8.59042, 50.20102],
              [8.5899, 50.20084],
              [8.58918, 50.20054],
              [8.58904, 50.20045],
              [8.58891, 50.20037],
              [8.58817, 50.19994],
              [8.58755, 50.19952],
              [8.5874, 50.19943],
              [8.58656, 50.19891],
              [8.58647, 50.19885],
              [8.5862, 50.19869],
              [8.58607, 50.19861],
              [8.58482, 50.19942],
              [8.58353, 50.20025],
              [8.58345, 50.20031],
              [8.58295, 50.20074],
              [8.58258, 50.20074],
              [8.58173, 50.20045],
              [8.58126, 50.20027],
              [8.58071, 50.20006],
              [8.57997, 50.19977],
              [8.57869, 50.19927],
              [8.57764, 50.19997],
              [8.57543, 50.20129],
              [8.5752, 50.20137],
              [8.57484, 50.20147],
              [8.57411, 50.20132],
              [8.57294, 50.2012],
              [8.57164, 50.20105],
              [8.57137, 50.20103],
              [8.56985, 50.20088],
              [8.56739, 50.20061],
              [8.56629, 50.2005],
              [8.564736, 50.200336],
              [8.56459, 50.20032],
              [8.5633, 50.2002],
              [8.56283, 50.20015],
              [8.55992, 50.19986],
              [8.55929, 50.1998],
              [8.55827, 50.19969],
              [8.55387, 50.19919],
              [8.55261, 50.19907],
              [8.55185, 50.199],
              [8.55041, 50.19878],
              [8.54806, 50.19744],
              [8.54767, 50.1973],
              [8.54648, 50.1971],
              [8.54468, 50.19717],
              [8.54336, 50.19726],
              [8.53831, 50.19767],
              [8.53634, 50.19785],
              [8.53486, 50.19796],
              [8.53322, 50.19808],
              [8.53259, 50.19832],
              [8.53246, 50.19842],
              [8.53135, 50.19812],
              [8.52859, 50.19739],
              [8.5281, 50.19721],
              [8.52705, 50.19681],
              [8.52617, 50.19644],
              [8.52568, 50.19625],
              [8.52303, 50.19522],
              [8.52097, 50.19426],
              [8.51933, 50.19362],
              [8.51831, 50.19349],
              [8.5174, 50.19345],
              [8.516, 50.19338],
              [8.51541, 50.19329],
              [8.51458, 50.19312],
              [8.51408, 50.19299],
              [8.51367, 50.19288],
              [8.51272, 50.19257],
              [8.51051, 50.19184],
              [8.50616, 50.19056],
              [8.50432, 50.19007],
              [8.50297, 50.18967],
              [8.49874, 50.18834],
              [8.49674, 50.1878],
              [8.49635, 50.18771],
              [8.49485, 50.1874],
              [8.49371, 50.18716],
              [8.49137, 50.18676],
              [8.48733, 50.1857],
              [8.48497, 50.18499],
              [8.48441, 50.18466],
              [8.48425, 50.18454],
              [8.483708, 50.184109],
              [8.48342, 50.18388],
              [8.48232, 50.18304],
              [8.48193, 50.18276],
              [8.48103, 50.18209],
              [8.47998, 50.18144],
              [8.47978, 50.18133],
              [8.47844, 50.18085],
              [8.47688, 50.18051],
              [8.47587, 50.18023],
              [8.47561, 50.18001],
              [8.47542, 50.17985],
              [8.47539, 50.17981],
              [8.47529, 50.17949],
              [8.47527, 50.17925],
              [8.47534, 50.17902],
              [8.47532, 50.17855],
              [8.47507, 50.1774],
              [8.47487, 50.17663],
              [8.4745, 50.17509],
              [8.47425, 50.17417],
              [8.47422, 50.17403],
              [8.47408, 50.17348],
              [8.47382, 50.17271],
              [8.47364, 50.17241],
              [8.47342, 50.17209],
              [8.47301, 50.17165],
              [8.47202, 50.17098],
              [8.47157, 50.17077],
              [8.47054, 50.17044],
              [8.46863, 50.17012],
              [8.4662, 50.16975],
              [8.4655, 50.16951],
              [8.46426, 50.16886],
              [8.46414, 50.16876],
              [8.46378, 50.16848],
              [8.4635, 50.16818],
              [8.46327, 50.1679],
              [8.46262, 50.16666],
              [8.46242, 50.1662],
              [8.46222, 50.16553],
              [8.46193, 50.16385],
              [8.46133, 50.16179],
              [8.46099, 50.15955],
              [8.46087, 50.1583],
              [8.46086, 50.15762],
              [8.46085, 50.15719],
              [8.46086, 50.15674],
              [8.46089, 50.15629],
              [8.46092, 50.15583],
              [8.46089, 50.15501],
              [8.46077, 50.15417],
              [8.46072, 50.15394],
              [8.46051, 50.15294],
              [8.46039, 50.15253],
              [8.46011, 50.15151],
              [8.45994, 50.15049],
              [8.45989, 50.15013],
              [8.45987, 50.14999],
              [8.45983, 50.14974],
              [8.45971, 50.14863],
              [8.45967, 50.14787],
              [8.45966, 50.14727],
              [8.45968, 50.14636],
              [8.4597, 50.146],
              [8.45997, 50.14394],
              [8.46054, 50.14169],
              [8.4611, 50.14068],
              [8.46182, 50.13978],
              [8.46414, 50.13777],
              [8.46529, 50.13681],
              [8.46608, 50.13618],
              [8.46681, 50.13559],
              [8.46756, 50.135],
              [8.47017, 50.13299],
              [8.47063, 50.13256],
              [8.47074, 50.13245],
              [8.4715, 50.1314],
              [8.47172, 50.1309],
              [8.47255, 50.1286],
              [8.47264, 50.12837],
              [8.47295, 50.12756],
              [8.47309, 50.12714],
              [8.47364, 50.12566],
              [8.47386, 50.12512],
              [8.47443, 50.12414],
              [8.47473, 50.12369],
              [8.47538, 50.12286],
              [8.47565, 50.12251],
              [8.47636, 50.12159],
              [8.47688, 50.12087],
              [8.47709, 50.12051],
              [8.47737, 50.11969],
              [8.47745, 50.11863],
              [8.47717, 50.11756],
              [8.47662, 50.1166],
              [8.47547, 50.11524],
              [8.47514, 50.11488],
              [8.4746, 50.11427],
              [8.47382, 50.11319],
              [8.47252, 50.1108],
              [8.47225, 50.11022],
              [8.47166, 50.10898],
              [8.47078, 50.10717],
              [8.46897, 50.10355],
              [8.468804, 50.103256],
              [8.46867, 50.10302],
              [8.46786, 50.10161],
              [8.46685, 50.10028],
              [8.46611, 50.09941],
              [8.46602, 50.0993],
              [8.46568, 50.09891],
              [8.46534, 50.09852],
              [8.46482, 50.09791],
              [8.46387, 50.09735],
              [8.463, 50.09688],
              [8.46206, 50.09638],
              [8.46102, 50.09582],
              [8.46039, 50.09546],
              [8.45949, 50.09497],
              [8.45935, 50.09489],
              [8.45913, 50.09476],
              [8.458549, 50.094435],
              [8.45611, 50.09307],
              [8.45601, 50.09301],
              [8.45512, 50.09251],
              [8.45504, 50.09247],
              [8.45474, 50.09229],
              [8.45419, 50.09197],
              [8.45377, 50.09175],
              [8.453, 50.09127],
              [8.45287, 50.09119],
              [8.4527, 50.09109],
              [8.45176, 50.09054],
              [8.45164, 50.09047],
              [8.45071, 50.09001],
              [8.4492, 50.08927],
              [8.44975, 50.08906],
              [8.44966, 50.08892],
              [8.44902, 50.08793],
              [8.44844, 50.08711],
              [8.44834, 50.08696],
              [8.44824, 50.08684],
              [8.44792, 50.08644],
              [8.44781, 50.08631],
              [8.44731, 50.08569],
              [8.44719, 50.08554],
              [8.44699, 50.08526],
              [8.44681, 50.08506],
              [8.44666, 50.08492],
              [8.44644, 50.08477],
              [8.446, 50.08444],
              [8.44546, 50.08434],
              [8.445096, 50.084466],
              [8.44407, 50.08482],
              [8.44385, 50.08494],
              [8.44363, 50.08496],
              [8.44351, 50.08495],
              [8.44303, 50.08489],
              [8.44228, 50.08499],
              [8.44176, 50.0848],
              [8.44171, 50.08476],
              [8.44136, 50.08429],
              [8.44135, 50.0842],
              [8.44133, 50.08408],
              [8.44127, 50.08371],
              [8.44115, 50.08338],
              [8.44071, 50.08264],
              [8.44054, 50.08244],
              [8.43986, 50.08178],
              [8.43954, 50.08146],
              [8.43932, 50.08124],
              [8.43909, 50.08102],
              [8.43873, 50.08063],
              [8.43859, 50.08033],
              [8.43846, 50.07973],
              [8.4384, 50.07942],
              [8.43838, 50.07931],
              [8.438289, 50.078918],
              [8.43796, 50.07749],
              [8.43794, 50.07738],
              [8.43784, 50.07677],
              [8.4376, 50.07602],
              [8.437283, 50.074895],
              [8.43718, 50.07453],
              [8.43715, 50.07438],
              [8.43716, 50.07414],
              [8.43728, 50.07321],
              [8.43737, 50.07294],
              [8.43767, 50.07202],
              [8.43769, 50.07168],
              [8.43753, 50.07144],
              [8.43676, 50.07076],
              [8.43615, 50.07024],
              [8.436, 50.07008],
              [8.43585, 50.06991],
              [8.43594, 50.06924],
              [8.43604, 50.06877],
              [8.43609, 50.06858],
              [8.43624, 50.06769],
              [8.43621, 50.06707],
              [8.43398, 50.06765],
              [8.43336, 50.06781],
              [8.43217, 50.06802],
              [8.43127, 50.06798],
              [8.43032, 50.06771],
              [8.42967, 50.06733],
              [8.42828, 50.06631],
              [8.42606, 50.06519],
              [8.42547, 50.0648],
              [8.4251, 50.06439],
              [8.42494, 50.06416],
              [8.42468, 50.06378],
              [8.42384, 50.06314],
              [8.42378, 50.06311],
              [8.423248, 50.062898],
              [8.4226, 50.06264],
              [8.42249, 50.06259],
              [8.42233, 50.06253],
              [8.42169, 50.06229],
              [8.42111, 50.06206],
              [8.42098, 50.06201],
              [8.42085, 50.06196],
              [8.42012, 50.06164],
              [8.4199, 50.06157],
              [8.41859, 50.06111],
              [8.41801, 50.06091],
              [8.41722, 50.06061],
              [8.41671, 50.06041],
              [8.41637, 50.06028],
              [8.4153, 50.05986],
              [8.41437, 50.05951],
              [8.41362, 50.05921],
              [8.41255, 50.05883],
              [8.41242, 50.05879],
              [8.41217, 50.05883],
              [8.4121, 50.05867],
              [8.41118, 50.05829],
              [8.41104, 50.05823],
              [8.40928, 50.05757],
              [8.40858, 50.05729],
              [8.40758, 50.05691],
              [8.40733, 50.05682],
              [8.40637, 50.05643],
              [8.40652, 50.05621],
              [8.40663, 50.05607],
              [8.4065, 50.05578],
              [8.40569, 50.05563],
              [8.40416, 50.05563],
              [8.40282, 50.05563],
              [8.39958, 50.05564],
              [8.3982, 50.05565],
              [8.39697, 50.05566],
              [8.39623, 50.05566],
              [8.39458, 50.05568],
              [8.39298, 50.05571],
              [8.3923, 50.05571],
              [8.38901, 50.05571],
              [8.38818, 50.0557],
              [8.3868, 50.05571],
              [8.38322, 50.05573],
              [8.38254, 50.05573],
              [8.38184, 50.05574],
              [8.38049, 50.05575],
              [8.37784, 50.05576],
              [8.37377, 50.05577],
              [8.37311, 50.05578],
              [8.37093, 50.05578],
              [8.37031, 50.05579],
              [8.3694, 50.05578],
              [8.36886, 50.05579],
              [8.36111, 50.0558],
              [8.34377, 50.05587],
              [8.3428, 50.05586],
              [8.34065, 50.05585],
              [8.3399, 50.05587],
              [8.3391, 50.05588],
              [8.33727, 50.05588],
              [8.33637, 50.05587],
              [8.33439, 50.05588],
              [8.32618, 50.0559],
              [8.32606, 50.0559],
              [8.32393, 50.05594],
              [8.31804, 50.05615],
              [8.31607, 50.056],
              [8.31372, 50.05558],
              [8.31321, 50.05546],
              [8.31267, 50.05548],
              [8.31122, 50.05498],
              [8.31084, 50.05462],
              [8.31025, 50.05439],
              [8.30926, 50.05396],
              [8.30842, 50.05361],
              [8.30793, 50.05339],
              [8.3074, 50.05312],
              [8.30688, 50.05287],
              [8.3065, 50.05269],
              [8.30527, 50.05207],
              [8.3027, 50.05092],
              [8.30062, 50.05031],
              [8.29918, 50.05006],
              [8.29744, 50.04991],
              [8.29609, 50.05002],
              [8.29515, 50.05038],
              [8.29398, 50.05097],
              [8.29308, 50.05117],
              [8.29175, 50.05133],
              [8.29082, 50.05172],
              [8.29047, 50.05191],
              [8.28998, 50.05232],
              [8.28857, 50.05377],
              [8.28748, 50.05531],
              [8.28677, 50.05657],
              [8.2866, 50.05689],
              [8.28613, 50.05769],
              [8.2855, 50.05858],
              [8.28426, 50.05985],
              [8.28263, 50.06098],
              [8.28017, 50.06211],
              [8.27644, 50.06349],
              [8.27444, 50.06423],
              [8.27387, 50.06444],
              [8.2717, 50.06521],
              [8.26859, 50.06634],
              [8.26768, 50.06668],
              [8.26479, 50.06775],
              [8.26329, 50.06831],
              [8.26225, 50.06878],
              [8.26137, 50.06928],
              [8.26031, 50.06999],
              [8.2593, 50.07078],
              [8.25805, 50.07172],
              [8.25767, 50.07199],
              [8.25727, 50.07224],
              [8.25699, 50.07233],
              [8.25693, 50.07258],
              [8.25671, 50.07253],
              [8.25641, 50.07248],
              [8.25518, 50.07238],
              [8.2547, 50.07235],
              [8.2531, 50.07223],
              [8.25181, 50.07215],
              [8.25111, 50.07212],
              [8.25036, 50.07207],
              [8.24916, 50.07199],
              [8.24887, 50.07198],
              [8.24834, 50.07196],
              [8.24819, 50.07195],
              [8.24803, 50.07194],
              [8.24745, 50.07192],
              [8.2459, 50.07184],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23112, 50.07506],
              [8.23077, 50.07557],
              [8.23074, 50.07561],
              [8.23036, 50.07637],
              [8.23019, 50.07716],
              [8.23015, 50.07735],
              [8.22997, 50.07829],
              [8.22976, 50.07946],
              [8.22963, 50.08055],
              [8.22949, 50.08052],
              [8.22757, 50.08043],
              [8.22687, 50.08041],
              [8.22604, 50.08036],
              [8.224955, 50.080535],
              [8.22461, 50.08059],
              [8.22433, 50.07999],
              [8.22422, 50.07969],
              [8.22394, 50.07924],
              [8.22227, 50.08002],
              [8.21884, 50.08148],
              [8.2183, 50.08162],
              [8.21663, 50.08197],
              [8.21592, 50.08205],
              [8.21553, 50.08232],
              [8.21466, 50.08234],
              [8.21173, 50.083],
              [8.21007, 50.08364],
              [8.20872, 50.08415],
              [8.20825, 50.08436],
            ],
          ],
        },
        id: "U1NDM",
        direction: "INBOUND",
      },
    ],
  },
];
