import { BusCategory, Line } from "@/types";

export const v2_sprinterBusse: Line[] = [
  {
    category: BusCategory.SPRINTER,
    id: "k4Nzc",
    name: "75 Mainz-Hbf <> Erbenheim",
    lineName: "75",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.302484, 50.061564],
              [8.303154, 50.061878],
              [8.303432, 50.061878],
              [8.30439, 50.05927],
              [8.30562, 50.05935],
              [8.30617, 50.05927],
              [8.30655, 50.05911],
              [8.30697, 50.05877],
              [8.30832, 50.05782],
              [8.30823, 50.05771],
              [8.30782, 50.05719],
              [8.30756, 50.05684],
              [8.30742, 50.05662],
              [8.3074, 50.05626],
              [8.307443, 50.056],
              [8.3075, 50.05566],
              [8.30745, 50.05564],
              [8.30731, 50.05561],
              [8.307, 50.05556],
              [8.30667, 50.0555],
              [8.3056, 50.05533],
              [8.30534, 50.05529],
              [8.3054, 50.05481],
              [8.30465, 50.05326],
              [8.30433, 50.05313],
              [8.30262, 50.05332],
              [8.30228, 50.05341],
              [8.30134, 50.05374],
              [8.3011, 50.05372],
              [8.30108, 50.0536],
              [8.3014, 50.05289],
              [8.30152, 50.05257],
              [8.30154, 50.05206],
              [8.30133, 50.05164],
              [8.30103, 50.05144],
              [8.29995, 50.05109],
              [8.29966, 50.050881],
              [8.298453, 50.0515],
              [8.298253, 50.052211],
              [8.294822, 50.052158],
              [8.293543, 50.05217],
              [8.292129, 50.052527],
              [8.29114, 50.05329],
              [8.29075, 50.05373],
              [8.289934, 50.054656],
              [8.28971, 50.05491],
              [8.28969, 50.05516],
              [8.29192, 50.0577],
              [8.29112, 50.057992],
              [8.288623, 50.058902],
              [8.28739, 50.05935],
              [8.2865, 50.05968],
              [8.28631, 50.05975],
              [8.28557, 50.06002],
              [8.28398, 50.06059],
              [8.28312, 50.06079],
              [8.28263, 50.06098],
              [8.28017, 50.06211],
              [8.27644, 50.06349],
              [8.27444, 50.06423],
              [8.27387, 50.06444],
              [8.2717, 50.06521],
              [8.26859, 50.06634],
              [8.26768, 50.06668],
              [8.26479, 50.06775],
              [8.26329, 50.06831],
              [8.26225, 50.06878],
              [8.26137, 50.06928],
              [8.26031, 50.06999],
              [8.2593, 50.07078],
              [8.25805, 50.07172],
              [8.25767, 50.07199],
              [8.25727, 50.07224],
              [8.25699, 50.07233],
              [8.25693, 50.07258],
              [8.25587, 50.07334],
              [8.25514, 50.07374],
              [8.25419, 50.07444],
              [8.25374, 50.07475],
              [8.25318, 50.0752],
              [8.25278, 50.07548],
              [8.25176, 50.07625],
              [8.25152, 50.07643],
              [8.25015, 50.07744],
              [8.24968, 50.07779],
              [8.24944, 50.07797],
              [8.24915, 50.07819],
              [8.24899, 50.0783],
              [8.248549, 50.07863],
              [8.24768, 50.07928],
              [8.24755, 50.07937],
              [8.24687, 50.07991],
              [8.24576, 50.08049],
              [8.24493, 50.08066],
              [8.24371, 50.0806],
              [8.24282, 50.08054],
              [8.2422, 50.0805],
              [8.24206, 50.08049],
              [8.24188, 50.08048],
              [8.23954, 50.08035],
              [8.23934, 50.08034],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22983, 50.07742],
              [8.22986, 50.07721],
              [8.22989, 50.07706],
              [8.229975, 50.076791],
              [8.23143, 50.07437],
              [8.23051, 50.07357],
              [8.23, 50.0731],
              [8.22984, 50.07296],
              [8.22977, 50.07289],
              [8.22913, 50.07232],
              [8.22823, 50.07151],
              [8.22797, 50.07128],
              [8.22732, 50.07069],
              [8.22609, 50.06969],
              [8.223309, 50.067704],
              [8.2211, 50.06611],
              [8.22093, 50.06596],
              [8.22074, 50.0658],
              [8.2205, 50.06562],
              [8.21961, 50.06501],
              [8.21893, 50.0645],
              [8.21802, 50.06383],
              [8.21742, 50.06338],
              [8.21711, 50.06314],
              [8.21684, 50.06292],
              [8.21641, 50.06261],
              [8.21632, 50.06254],
              [8.21592, 50.06222],
              [8.21535, 50.06172],
              [8.21437, 50.06056],
              [8.21376, 50.05995],
              [8.21334, 50.05948],
              [8.21261, 50.05821],
              [8.21235, 50.05722],
              [8.21231, 50.05551],
              [8.21233, 50.05534],
              [8.21247, 50.05441],
              [8.2125, 50.05405],
              [8.2126, 50.05319],
              [8.21267, 50.05233],
              [8.21271, 50.05204],
              [8.21281, 50.05116],
              [8.21283, 50.05103],
              [8.21292, 50.05015],
              [8.21294, 50.04992],
              [8.21312, 50.04791],
              [8.213062, 50.046167],
              [8.212949, 50.04515],
              [8.212911, 50.044799],
              [8.212794, 50.043744],
              [8.211283, 50.032311],
              [8.211068, 50.030743],
              [8.211116, 50.030513],
              [8.211376, 50.03025],
              [8.211563, 50.029868],
              [8.21151, 50.02942],
              [8.21143, 50.02878],
              [8.21139, 50.02815],
              [8.21136, 50.02788],
              [8.21128, 50.02733],
              [8.21121, 50.02689],
              [8.2111, 50.02623],
              [8.21059, 50.02422],
              [8.20958, 50.02165],
              [8.20915, 50.0208],
              [8.20799, 50.01891],
              [8.20613, 50.01648],
              [8.20379, 50.014],
              [8.20294, 50.0135],
              [8.20176, 50.01333],
              [8.20139, 50.01333],
              [8.2009, 50.01316],
              [8.20072, 50.0129],
              [8.20072, 50.01281],
              [8.20089, 50.0125],
              [8.20124, 50.01227],
              [8.20163, 50.01205],
              [8.20339, 50.01107],
              [8.20417, 50.01065],
              [8.20442, 50.01051],
              [8.20533, 50.01024],
              [8.20674, 50.01011],
              [8.2076, 50.01017],
              [8.20803, 50.01023],
              [8.21023, 50.01062],
              [8.21225, 50.01085],
              [8.21289, 50.01086],
              [8.21442, 50.01087],
              [8.21636, 50.01086],
              [8.21655, 50.01077],
              [8.21663, 50.01051],
              [8.21611, 50.00971],
              [8.21552, 50.00915],
              [8.21535, 50.0088],
              [8.21534, 50.00813],
              [8.21543, 50.00783],
              [8.21587, 50.00707],
              [8.21634, 50.00612],
              [8.21631, 50.00594],
              [8.21627, 50.00574],
              [8.21647, 50.00521],
              [8.2169, 50.0046],
              [8.21726, 50.00411],
              [8.21801, 50.00305],
              [8.21817, 50.00248],
              [8.21795, 50.00169],
              [8.2179, 50.00156],
              [8.21781, 50.00108],
              [8.217885, 50.000598],
              [8.21794, 50.00024],
              [8.21841, 49.99968],
              [8.2188, 49.99942],
              [8.21945, 49.99909],
              [8.21995, 49.99882],
              [8.22076, 49.99822],
              [8.221702, 49.997609],
              [8.22227, 49.99724],
              [8.22352, 49.99658],
              [8.2247, 49.99573],
              [8.22491, 49.99556],
              [8.225773, 49.994656],
              [8.22596, 49.99446],
              [8.22665, 49.9933],
              [8.22748, 49.99345],
              [8.22818, 49.99357],
              [8.22844, 49.99346],
              [8.22852, 49.99324],
              [8.22785, 49.99284],
              [8.22696, 49.99269],
              [8.2219, 49.9918],
              [8.22029, 49.99157],
              [8.21999, 49.99152],
              [8.21923, 49.99157],
              [8.21913, 49.99161],
              [8.21866, 49.99152],
              [8.21861, 49.99145],
              [8.21858, 49.99119],
              [8.21881, 49.99102],
              [8.21891, 49.991],
              [8.21908, 49.991],
              [8.2199, 49.98992],
              [8.22073, 49.98878],
              [8.22102, 49.98869],
              [8.22102, 49.98863],
              [8.22501, 49.98868],
              [8.225078, 49.988694],
              [8.22765, 49.98922],
              [8.228274, 49.990118],
              [8.231449, 49.990707],
              [8.23195, 49.98966],
              [8.2323, 49.98899],
              [8.23235, 49.98889],
              [8.23315, 49.98906],
              [8.23447, 49.98932],
              [8.23582, 49.98959],
              [8.23786, 49.9901],
              [8.2383, 49.99035],
              [8.23808, 49.99088],
              [8.23811, 49.99104],
              [8.23866, 49.99123],
              [8.24032, 49.99155],
              [8.24239, 49.99199],
              [8.24359, 49.99231],
              [8.24565, 49.99318],
              [8.24614, 49.99334],
              [8.24635, 49.99339],
              [8.24709, 49.99601],
              [8.24716, 49.99671],
              [8.24804, 49.99692],
              [8.24917, 49.99706],
              [8.25106, 49.99725],
              [8.25276, 49.99729],
              [8.25565, 49.99745],
              [8.2559, 49.99749],
              [8.25636, 49.99779],
              [8.25654, 49.99797],
              [8.25727, 49.99879],
              [8.2576, 49.99905],
              [8.25805, 49.99925],
              [8.25829, 49.99933],
              [8.258525, 49.999387],
              [8.25889, 49.99948],
              [8.25926, 49.99958],
              [8.26046, 50.00007],
              [8.26072, 50.00019],
              [8.26052, 50.00034],
              [8.25989, 50.00102],
              [8.25996, 50.00114],
              [8.26012, 50.00141],
            ],
          ],
        },
        id: "k4Nzc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.26012, 50.00141],
              [8.25996, 50.00114],
              [8.25989, 50.00102],
              [8.26052, 50.00034],
              [8.26072, 50.00019],
              [8.26046, 50.00007],
              [8.25926, 49.99958],
              [8.25889, 49.99948],
              [8.258525, 49.999387],
              [8.25829, 49.99933],
              [8.25805, 49.99925],
              [8.2576, 49.99905],
              [8.25727, 49.99879],
              [8.25654, 49.99797],
              [8.25636, 49.99779],
              [8.2559, 49.99749],
              [8.25565, 49.99745],
              [8.25276, 49.99729],
              [8.25106, 49.99725],
              [8.24917, 49.99706],
              [8.24804, 49.99692],
              [8.24716, 49.99671],
              [8.24709, 49.99601],
              [8.24635, 49.99339],
              [8.24614, 49.99334],
              [8.24565, 49.99318],
              [8.24359, 49.99231],
              [8.24239, 49.99199],
              [8.24032, 49.99155],
              [8.23866, 49.99123],
              [8.23811, 49.99104],
              [8.23808, 49.99088],
              [8.2383, 49.99035],
              [8.23786, 49.9901],
              [8.23582, 49.98959],
              [8.23447, 49.98932],
              [8.23315, 49.98906],
              [8.23235, 49.98889],
              [8.2323, 49.98899],
              [8.23195, 49.98966],
              [8.231449, 49.990707],
              [8.228274, 49.990118],
              [8.22765, 49.98922],
              [8.225078, 49.988694],
              [8.22501, 49.98868],
              [8.22487, 49.98865],
              [8.22501, 49.98868],
              [8.22102, 49.98863],
              [8.22102, 49.98869],
              [8.22073, 49.98878],
              [8.2199, 49.98992],
              [8.21908, 49.991],
              [8.21934, 49.9911],
              [8.22012, 49.99141],
              [8.225368, 49.992318],
              [8.227, 49.9926],
              [8.22788, 49.99275],
              [8.2286, 49.9927],
              [8.22881, 49.99248],
              [8.22869, 49.99225],
              [8.22735, 49.99197],
              [8.2271, 49.99241],
              [8.227, 49.9926],
              [8.22696, 49.99269],
              [8.22687, 49.99287],
              [8.22665, 49.9933],
              [8.22596, 49.99446],
              [8.225773, 49.994656],
              [8.22491, 49.99556],
              [8.2247, 49.99573],
              [8.22352, 49.99658],
              [8.22227, 49.99724],
              [8.221702, 49.997609],
              [8.22076, 49.99822],
              [8.21995, 49.99882],
              [8.21945, 49.99909],
              [8.2188, 49.99942],
              [8.21841, 49.99968],
              [8.21794, 50.00024],
              [8.217885, 50.000598],
              [8.21781, 50.00108],
              [8.2179, 50.00156],
              [8.21795, 50.00169],
              [8.21817, 50.00248],
              [8.21801, 50.00305],
              [8.21726, 50.00411],
              [8.2169, 50.0046],
              [8.21647, 50.00521],
              [8.21627, 50.00574],
              [8.21631, 50.00594],
              [8.21634, 50.00612],
              [8.21587, 50.00707],
              [8.21543, 50.00783],
              [8.21534, 50.00813],
              [8.21535, 50.0088],
              [8.21552, 50.00915],
              [8.21611, 50.00971],
              [8.21663, 50.01051],
              [8.21682, 50.01082],
              [8.21718, 50.01161],
              [8.21683, 50.01166],
              [8.2162, 50.01161],
              [8.21358, 50.01114],
              [8.21224, 50.01095],
              [8.21122, 50.01083],
              [8.2076, 50.01023],
              [8.20618, 50.0102],
              [8.20471, 50.0105],
              [8.20423, 50.01069],
              [8.20322, 50.0115],
              [8.20295, 50.01213],
              [8.20299, 50.01263],
              [8.20356, 50.01351],
              [8.20377, 50.01371],
              [8.20403, 50.01398],
              [8.20642, 50.01656],
              [8.20774, 50.01827],
              [8.20859, 50.01961],
              [8.2097, 50.02163],
              [8.21066, 50.02408],
              [8.21122, 50.02621],
              [8.21133, 50.02688],
              [8.2114, 50.02731],
              [8.21147, 50.02788],
              [8.21151, 50.02814],
              [8.21158, 50.02878],
              [8.21167, 50.02947],
              [8.21174, 50.03028],
              [8.21175, 50.03037],
              [8.21182, 50.03099],
              [8.21186, 50.03135],
              [8.21206, 50.03326],
              [8.21227, 50.03512],
              [8.21275, 50.03942],
              [8.21298, 50.04143],
              [8.21308, 50.04209],
              [8.21312, 50.04239],
              [8.21319, 50.04345],
              [8.21324, 50.0441],
              [8.21327, 50.04463],
              [8.21329, 50.04517],
              [8.2133, 50.04563],
              [8.21321, 50.04846],
              [8.21318, 50.04891],
              [8.21314, 50.0493],
              [8.21307, 50.04993],
              [8.213, 50.05063],
              [8.21297, 50.05089],
              [8.21294, 50.05105],
              [8.21284, 50.05205],
              [8.2128, 50.05233],
              [8.21271, 50.05304],
              [8.21265, 50.05363],
              [8.2126, 50.05408],
              [8.21255, 50.05442],
              [8.21246, 50.05534],
              [8.21244, 50.05551],
              [8.21245, 50.05712],
              [8.21278, 50.05834],
              [8.21337, 50.0593],
              [8.21391, 50.0599],
              [8.21554, 50.06106],
              [8.21658, 50.06188],
              [8.21698, 50.06216],
              [8.21708, 50.06222],
              [8.21733, 50.06243],
              [8.2178, 50.06282],
              [8.21794, 50.06294],
              [8.21998, 50.0649],
              [8.22048, 50.06548],
              [8.221, 50.06593],
              [8.221162, 50.066064],
              [8.22369, 50.06782],
              [8.22492, 50.06868],
              [8.22618, 50.06961],
              [8.22676, 50.07005],
              [8.22741, 50.07061],
              [8.22833, 50.07146],
              [8.22921, 50.07227],
              [8.22984, 50.07283],
              [8.22994, 50.07291],
              [8.2301, 50.07306],
              [8.23071, 50.07357],
              [8.23153, 50.07426],
              [8.2316, 50.07454],
              [8.23112, 50.07506],
              [8.23077, 50.07557],
              [8.23074, 50.07561],
              [8.23036, 50.07637],
              [8.23019, 50.07716],
              [8.23015, 50.07735],
              [8.22997, 50.07829],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.244535, 50.079334],
              [8.24507, 50.07936],
              [8.24587, 50.07943],
              [8.24632, 50.07958],
              [8.24687, 50.07991],
              [8.24755, 50.07937],
              [8.24768, 50.07928],
              [8.248549, 50.07863],
              [8.24899, 50.0783],
              [8.24915, 50.07819],
              [8.24944, 50.07797],
              [8.24968, 50.07779],
              [8.25015, 50.07744],
              [8.25152, 50.07643],
              [8.25176, 50.07625],
              [8.25278, 50.07548],
              [8.25318, 50.0752],
              [8.25374, 50.07475],
              [8.25419, 50.07444],
              [8.2541, 50.07439],
              [8.25505, 50.07369],
              [8.25569, 50.07325],
              [8.25606, 50.07299],
              [8.25671, 50.07253],
              [8.25699, 50.07233],
              [8.25732, 50.0721],
              [8.25749, 50.07199],
              [8.25808, 50.07153],
              [8.26015, 50.06999],
              [8.26098, 50.06939],
              [8.26144, 50.06909],
              [8.2622, 50.06868],
              [8.26323, 50.06821],
              [8.26469, 50.06766],
              [8.2676, 50.06659],
              [8.2685, 50.06624],
              [8.2716, 50.06513],
              [8.27346, 50.06446],
              [8.27435, 50.06412],
              [8.27631, 50.06341],
              [8.27966, 50.06219],
              [8.28254, 50.0609],
              [8.28415, 50.0598],
              [8.2846, 50.05906],
              [8.2845, 50.05821],
              [8.28451, 50.05771],
              [8.28482, 50.05726],
              [8.28489, 50.05718],
              [8.28563, 50.05675],
              [8.28659, 50.05657],
              [8.28677, 50.05657],
              [8.28747, 50.05666],
              [8.28856, 50.05714],
              [8.28958, 50.05807],
              [8.28992, 50.05843],
              [8.29112, 50.057992],
              [8.29192, 50.0577],
              [8.28969, 50.05516],
              [8.28971, 50.05491],
              [8.289934, 50.054656],
              [8.29075, 50.05373],
              [8.29114, 50.05329],
              [8.292129, 50.052527],
              [8.293543, 50.05217],
              [8.294822, 50.052158],
              [8.298253, 50.052211],
              [8.298453, 50.0515],
              [8.29966, 50.050881],
              [8.29995, 50.05109],
              [8.30103, 50.05144],
              [8.30133, 50.05164],
              [8.30154, 50.05206],
              [8.30152, 50.05257],
              [8.3014, 50.05289],
              [8.30108, 50.0536],
              [8.3011, 50.05372],
              [8.30134, 50.05374],
              [8.30228, 50.05341],
              [8.30262, 50.05332],
              [8.30433, 50.05313],
              [8.30465, 50.05326],
              [8.30489, 50.05358],
              [8.30536, 50.05429],
              [8.3054, 50.05481],
              [8.30534, 50.05529],
              [8.3056, 50.05533],
              [8.30667, 50.0555],
              [8.307, 50.05556],
              [8.30731, 50.05561],
              [8.30745, 50.05564],
              [8.3075, 50.05566],
              [8.307443, 50.056],
              [8.3074, 50.05626],
              [8.30742, 50.05662],
              [8.30756, 50.05684],
              [8.30782, 50.05719],
              [8.30823, 50.05771],
              [8.30832, 50.05782],
              [8.30697, 50.05877],
              [8.30655, 50.05911],
              [8.30617, 50.05927],
              [8.30562, 50.05935],
              [8.30439, 50.05927],
              [8.30193, 50.05893],
              [8.301802, 50.059563],
              [8.301902, 50.060067],
              [8.30198, 50.06067],
              [8.30197, 50.06132],
              [8.302484, 50.061564],
            ],
          ],
        },
        id: "kxNjc",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.SPRINTER,
    id: "I2NTU",
    name: "74 Mainz_Hechtsheim <> Nordfriedhof",
    lineName: "74",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.22116, 50.09698],
              [8.22089, 50.09707],
              [8.22025, 50.09698],
              [8.21972, 50.09663],
              [8.219277, 50.096281],
              [8.21887, 50.09596],
              [8.21848, 50.09563],
              [8.21833, 50.0955],
              [8.21785, 50.09506],
              [8.21739, 50.0948],
              [8.21647, 50.09485],
              [8.21615, 50.09464],
              [8.21633, 50.09443],
              [8.21691, 50.09437],
              [8.21763, 50.09421],
              [8.21812, 50.09394],
              [8.21845, 50.09374],
              [8.21898, 50.09331],
              [8.21919, 50.09309],
              [8.22027, 50.09161],
              [8.2214, 50.09055],
              [8.22145, 50.09032],
              [8.2213, 50.09002],
              [8.22125, 50.08998],
              [8.22079, 50.08969],
              [8.220784, 50.089683],
              [8.2205, 50.08932],
              [8.22045, 50.08906],
              [8.22054, 50.0889],
              [8.2216, 50.08812],
              [8.22187, 50.08794],
              [8.22268, 50.08745],
              [8.224601, 50.086148],
              [8.2247, 50.08588],
              [8.2254, 50.08548],
              [8.22547, 50.08541],
              [8.22575, 50.08506],
              [8.22693, 50.08414],
              [8.22766, 50.08366],
              [8.22837, 50.08335],
              [8.22882, 50.08312],
              [8.22888, 50.08279],
              [8.228921, 50.082748],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22997, 50.07829],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.24239, 50.07799],
              [8.24243, 50.07777],
              [8.24236, 50.07769],
              [8.242431, 50.07695],
              [8.24281, 50.0744],
              [8.24288, 50.0739],
              [8.24298, 50.07321],
              [8.24313, 50.07218],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.2405, 50.07147],
              [8.24038, 50.07135],
              [8.24031, 50.07117],
              [8.23998, 50.0702],
              [8.23998, 50.06971],
              [8.24003, 50.06949],
              [8.2402, 50.06926],
              [8.2405, 50.06799],
              [8.24075, 50.06702],
              [8.24136, 50.06466],
              [8.24164, 50.06356],
              [8.24176, 50.06298],
              [8.24194, 50.06229],
              [8.24212, 50.06155],
              [8.24221, 50.06109],
              [8.24232, 50.0607],
              [8.2424, 50.06045],
              [8.24289, 50.05855],
              [8.24305, 50.05794],
              [8.24313, 50.05762],
              [8.24316, 50.05749],
              [8.24333, 50.05684],
              [8.24338, 50.05663],
              [8.24342, 50.05647],
              [8.2433, 50.0563],
              [8.24348, 50.05536],
              [8.24334, 50.05486],
              [8.24319, 50.0545],
              [8.24297, 50.05394],
              [8.24308, 50.0538],
              [8.24298, 50.05359],
              [8.24293, 50.05349],
              [8.24267, 50.05281],
              [8.24235, 50.05199],
              [8.24224, 50.05171],
              [8.2422, 50.05161],
              [8.24204, 50.05124],
              [8.24183, 50.050709],
              [8.24164, 50.05023],
              [8.24127, 50.04932],
              [8.24115, 50.04901],
              [8.24104, 50.04875],
              [8.24097, 50.04827],
              [8.24095, 50.04785],
              [8.24096, 50.04769],
              [8.24103, 50.04725],
              [8.23748, 50.04808],
              [8.23436, 50.047837],
              [8.23121, 50.04728],
              [8.23026, 50.0471],
              [8.230095, 50.047529],
              [8.22999, 50.0478],
              [8.22991, 50.04842],
              [8.22753, 50.04858],
              [8.22339, 50.04888],
              [8.22139, 50.04894],
              [8.219971, 50.048853],
              [8.220218, 50.04734],
              [8.220429, 50.046125],
              [8.22056, 50.04537],
              [8.21727, 50.044763],
              [8.21704, 50.04472],
              [8.21663, 50.04465],
              [8.21616, 50.04456],
              [8.214199, 50.044255],
              [8.21365, 50.04416],
              [8.213409, 50.044122],
              [8.21324, 50.0441],
              [8.21307, 50.04407],
              [8.21268, 50.04401],
              [8.21151, 50.04384],
              [8.21072, 50.0437],
              [8.21024, 50.04381],
              [8.21014, 50.04393],
              [8.21068, 50.04454],
              [8.21089, 50.04466],
              [8.21165, 50.04489],
              [8.21244, 50.04496],
              [8.212811, 50.044907],
              [8.212911, 50.044799],
              [8.212794, 50.043744],
              [8.211283, 50.032311],
              [8.211068, 50.030743],
              [8.211116, 50.030513],
              [8.211376, 50.03025],
              [8.21136, 50.029982],
              [8.21126, 50.02973],
              [8.21076, 50.02945],
              [8.21008, 50.02941],
              [8.20954, 50.02965],
              [8.20942, 50.02993],
              [8.20952, 50.03021],
              [8.21004, 50.0305],
              [8.21164, 50.03056],
              [8.21253, 50.03041],
              [8.21385, 50.02993],
              [8.21518, 50.02952],
              [8.21763, 50.02917],
              [8.21916, 50.02883],
              [8.21961, 50.02864],
              [8.22, 50.02841],
              [8.22032, 50.02799],
              [8.22048, 50.02767],
              [8.22041, 50.02738],
              [8.22041, 50.02725],
              [8.22018, 50.02643],
              [8.22008, 50.02605],
              [8.22046, 50.02599],
              [8.22122, 50.02574],
              [8.22109, 50.02436],
              [8.22096, 50.02384],
              [8.220777, 50.023527],
              [8.22041, 50.0229],
              [8.22024, 50.02286],
              [8.22029, 50.02275],
              [8.22022, 50.02256],
              [8.22058, 50.0216],
              [8.22071, 50.02102],
              [8.22079, 50.01961],
              [8.22076, 50.01911],
              [8.22043, 50.01832],
              [8.22014, 50.01786],
              [8.22, 50.0176],
              [8.21984, 50.01727],
              [8.2195, 50.01653],
              [8.21922, 50.01594],
              [8.21906, 50.01559],
              [8.21848, 50.0144],
              [8.21811, 50.01357],
              [8.218031, 50.013397],
              [8.21785, 50.013],
              [8.21781, 50.01291],
              [8.21775, 50.0128],
              [8.21771, 50.01272],
              [8.2173, 50.01186],
              [8.21718, 50.01161],
              [8.21682, 50.01082],
              [8.21663, 50.01051],
              [8.21611, 50.00971],
              [8.21552, 50.00915],
              [8.21535, 50.0088],
              [8.21534, 50.00813],
              [8.21543, 50.00783],
              [8.21587, 50.00707],
              [8.21634, 50.00612],
              [8.21631, 50.00594],
              [8.21657, 50.00591],
              [8.219665, 50.006513],
              [8.22042, 50.00666],
              [8.2206, 50.00603],
              [8.2208, 50.00548],
              [8.2189, 50.00515],
              [8.2169, 50.0046],
              [8.21726, 50.00411],
              [8.21801, 50.00305],
              [8.21817, 50.00248],
              [8.21795, 50.00169],
              [8.2179, 50.00156],
              [8.21781, 50.00108],
              [8.217885, 50.000598],
              [8.21794, 50.00024],
              [8.21841, 49.99968],
              [8.2188, 49.99942],
              [8.21945, 49.99909],
              [8.21995, 49.99882],
              [8.22076, 49.99822],
              [8.221702, 49.997609],
              [8.22227, 49.99724],
              [8.22352, 49.99658],
              [8.2247, 49.99573],
              [8.22491, 49.99556],
              [8.225773, 49.994656],
              [8.22596, 49.99446],
              [8.22665, 49.9933],
              [8.22687, 49.99287],
              [8.22696, 49.99269],
              [8.227, 49.9926],
              [8.2271, 49.99241],
              [8.22735, 49.99197],
              [8.22759, 49.99152],
              [8.228274, 49.990118],
              [8.228548, 49.989557],
              [8.22919, 49.98824],
              [8.229578, 49.98753],
              [8.2296, 49.98749],
              [8.2306, 49.98578],
              [8.23158, 49.98427],
              [8.231898, 49.983833],
              [8.23217, 49.98346],
              [8.23336, 49.98196],
              [8.2338, 49.98138],
              [8.23418, 49.98082],
              [8.23489, 49.9793],
              [8.23699, 49.9797],
              [8.23746, 49.97962],
              [8.2379, 49.97931],
              [8.23815, 49.97859],
              [8.23831, 49.97817],
              [8.23842, 49.97794],
              [8.23855, 49.97768],
              [8.23898, 49.97683],
              [8.23907, 49.97664],
              [8.239191, 49.976074],
              [8.23922, 49.97594],
              [8.23923, 49.97568],
              [8.24019, 49.97594],
              [8.24064, 49.97606],
              [8.24165, 49.97631],
              [8.24194, 49.97638],
              [8.2426, 49.97656],
              [8.24307, 49.97666],
              [8.24376, 49.97684],
              [8.24425, 49.97698],
              [8.24432, 49.97699],
              [8.24479, 49.97711],
              [8.24542, 49.97727],
              [8.24636, 49.9775],
              [8.24804, 49.97789],
              [8.24907, 49.97809],
              [8.24914, 49.97807],
              [8.24995, 49.97724],
              [8.25035, 49.977],
              [8.25083, 49.97683],
              [8.25134, 49.97669],
              [8.25158, 49.97664],
              [8.25175, 49.97661],
              [8.25129, 49.97606],
              [8.25113, 49.9757],
              [8.25143, 49.97543],
              [8.25162, 49.97509],
              [8.25128, 49.97457],
              [8.25068, 49.97408],
              [8.24916, 49.9728],
              [8.24604, 49.97022],
              [8.24535, 49.96965],
              [8.24366, 49.96822],
              [8.24305, 49.96772],
              [8.24236, 49.96715],
              [8.24158, 49.96656],
              [8.23959, 49.96489],
              [8.23905, 49.96446],
              [8.23839, 49.96386],
              [8.23748, 49.96358],
              [8.23692, 49.96368],
              [8.23673, 49.96392],
              [8.23699, 49.96421],
              [8.23751, 49.96435],
              [8.23826, 49.96436],
              [8.23971, 49.96439],
              [8.24154, 49.96448],
              [8.24412, 49.96473],
              [8.24572, 49.965],
              [8.24738, 49.96536],
              [8.24895, 49.96578],
              [8.25116, 49.96643],
              [8.25433, 49.96739],
              [8.25494, 49.96747],
              [8.25553, 49.96729],
              [8.2557, 49.96694],
              [8.25478, 49.96559],
              [8.25397, 49.96484],
              [8.25331, 49.96423],
              [8.25131, 49.96241],
              [8.25076, 49.96152],
              [8.25136, 49.9615],
              [8.25258, 49.96173],
              [8.25282, 49.9618],
              [8.25425, 49.9621],
              [8.25546, 49.96235],
              [8.2565, 49.96037],
              [8.25747, 49.9585],
              [8.25981, 49.95893],
              [8.26133, 49.95924],
              [8.26169, 49.959268],
              [8.26355, 49.95941],
              [8.26513, 49.95939],
              [8.26728, 49.95927],
              [8.26745, 49.95919],
              [8.267917, 49.959584],
            ],
          ],
        },
        id: "I2NTU",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.267917, 49.959584],
              [8.26745, 49.95919],
              [8.26728, 49.95927],
              [8.26513, 49.95939],
              [8.26355, 49.95941],
              [8.26169, 49.959268],
              [8.26133, 49.95924],
              [8.25981, 49.95893],
              [8.25747, 49.9585],
              [8.2565, 49.96037],
              [8.25546, 49.96235],
              [8.25425, 49.9621],
              [8.25282, 49.9618],
              [8.25258, 49.96173],
              [8.25136, 49.9615],
              [8.25076, 49.96152],
              [8.25131, 49.96241],
              [8.25331, 49.96423],
              [8.25397, 49.96484],
              [8.25478, 49.96559],
              [8.25599, 49.96681],
              [8.25621, 49.96719],
              [8.25624, 49.96738],
              [8.25599, 49.9679],
              [8.25571, 49.96829],
              [8.25567, 49.9687],
              [8.25594, 49.96895],
              [8.2564, 49.96897],
              [8.2568, 49.96869],
              [8.25668, 49.96834],
              [8.25615, 49.96807],
              [8.25409, 49.96747],
              [8.25324, 49.96721],
              [8.25044, 49.96636],
              [8.24735, 49.96548],
              [8.24574, 49.96509],
              [8.2444, 49.96503],
              [8.24369, 49.96516],
              [8.24283, 49.96565],
              [8.24243, 49.96617],
              [8.24233, 49.96663],
              [8.24249, 49.96712],
              [8.24312, 49.96765],
              [8.24374, 49.96817],
              [8.24504, 49.96923],
              [8.24551, 49.96963],
              [8.24633, 49.97032],
              [8.24912, 49.97263],
              [8.25081, 49.97403],
              [8.25147, 49.97459],
              [8.25265, 49.97555],
              [8.25419, 49.97675],
              [8.25471, 49.97694],
              [8.25549, 49.97692],
              [8.25574, 49.97671],
              [8.25582, 49.97645],
              [8.25543, 49.97606],
              [8.25498, 49.97601],
              [8.25425, 49.97611],
              [8.25175, 49.97661],
              [8.25158, 49.97664],
              [8.25134, 49.97669],
              [8.25083, 49.97683],
              [8.25035, 49.977],
              [8.24995, 49.97724],
              [8.24914, 49.97807],
              [8.24907, 49.97809],
              [8.24804, 49.97789],
              [8.24636, 49.9775],
              [8.24542, 49.97727],
              [8.24479, 49.97711],
              [8.24432, 49.97699],
              [8.24425, 49.97698],
              [8.24376, 49.97684],
              [8.24307, 49.97666],
              [8.2426, 49.97656],
              [8.24194, 49.97638],
              [8.24165, 49.97631],
              [8.24064, 49.97606],
              [8.24019, 49.97594],
              [8.23923, 49.97568],
              [8.23922, 49.97594],
              [8.239191, 49.976074],
              [8.23907, 49.97664],
              [8.23898, 49.97683],
              [8.23855, 49.97768],
              [8.23842, 49.97794],
              [8.23831, 49.97817],
              [8.23815, 49.97859],
              [8.2379, 49.97931],
              [8.23746, 49.97962],
              [8.23699, 49.9797],
              [8.23489, 49.9793],
              [8.23418, 49.98082],
              [8.2338, 49.98138],
              [8.23336, 49.98196],
              [8.23217, 49.98346],
              [8.231898, 49.983833],
              [8.23158, 49.98427],
              [8.2306, 49.98578],
              [8.2296, 49.98749],
              [8.229578, 49.98753],
              [8.22919, 49.98824],
              [8.228548, 49.989557],
              [8.228274, 49.990118],
              [8.22759, 49.99152],
              [8.22735, 49.99197],
              [8.2271, 49.99241],
              [8.227, 49.9926],
              [8.22696, 49.99269],
              [8.22687, 49.99287],
              [8.22665, 49.9933],
              [8.22596, 49.99446],
              [8.225773, 49.994656],
              [8.22491, 49.99556],
              [8.2247, 49.99573],
              [8.22352, 49.99658],
              [8.22227, 49.99724],
              [8.221702, 49.997609],
              [8.22076, 49.99822],
              [8.21995, 49.99882],
              [8.21945, 49.99909],
              [8.2188, 49.99942],
              [8.21841, 49.99968],
              [8.21794, 50.00024],
              [8.217885, 50.000598],
              [8.21781, 50.00108],
              [8.2179, 50.00156],
              [8.21795, 50.00169],
              [8.21817, 50.00248],
              [8.21801, 50.00305],
              [8.21726, 50.00411],
              [8.2169, 50.0046],
              [8.2189, 50.00515],
              [8.2208, 50.00548],
              [8.2206, 50.00603],
              [8.22042, 50.00666],
              [8.219665, 50.006513],
              [8.21657, 50.00591],
              [8.21631, 50.00594],
              [8.21634, 50.00612],
              [8.21587, 50.00707],
              [8.21543, 50.00783],
              [8.21534, 50.00813],
              [8.21535, 50.0088],
              [8.21552, 50.00915],
              [8.21611, 50.00971],
              [8.21663, 50.01051],
              [8.21682, 50.01082],
              [8.21718, 50.01161],
              [8.2173, 50.01186],
              [8.21771, 50.01272],
              [8.21775, 50.0128],
              [8.21781, 50.01291],
              [8.21785, 50.013],
              [8.218031, 50.013397],
              [8.21811, 50.01357],
              [8.21848, 50.0144],
              [8.21906, 50.01559],
              [8.21922, 50.01594],
              [8.2195, 50.01653],
              [8.21984, 50.01727],
              [8.22, 50.0176],
              [8.22014, 50.01786],
              [8.22043, 50.01832],
              [8.22076, 50.01911],
              [8.22079, 50.01961],
              [8.22071, 50.02102],
              [8.22058, 50.0216],
              [8.22022, 50.02256],
              [8.22029, 50.02275],
              [8.22024, 50.02286],
              [8.22041, 50.0229],
              [8.220777, 50.023527],
              [8.22096, 50.02384],
              [8.22109, 50.02436],
              [8.22122, 50.02574],
              [8.22046, 50.02599],
              [8.22008, 50.02605],
              [8.22018, 50.02643],
              [8.22041, 50.02725],
              [8.22041, 50.02738],
              [8.22048, 50.02767],
              [8.22047, 50.02806],
              [8.22041, 50.02814],
              [8.22048, 50.0284],
              [8.22071, 50.02852],
              [8.21977, 50.02875],
              [8.21921, 50.02893],
              [8.21852, 50.02917],
              [8.21672, 50.02964],
              [8.21599, 50.02971],
              [8.21486, 50.02959],
              [8.21348, 50.02929],
              [8.21258, 50.0293],
              [8.21203, 50.02957],
              [8.21185, 50.02983],
              [8.21174, 50.03028],
              [8.21175, 50.03037],
              [8.21182, 50.03099],
              [8.21186, 50.03135],
              [8.21206, 50.03326],
              [8.21227, 50.03512],
              [8.21275, 50.03942],
              [8.21298, 50.04143],
              [8.21308, 50.04209],
              [8.21312, 50.04239],
              [8.21334, 50.04274],
              [8.21405, 50.04293],
              [8.21549, 50.04306],
              [8.21623, 50.04335],
              [8.2167, 50.0438],
              [8.21678, 50.04426],
              [8.21676, 50.04434],
              [8.21671, 50.04447],
              [8.21663, 50.04465],
              [8.21704, 50.04472],
              [8.21727, 50.044763],
              [8.22056, 50.04537],
              [8.220429, 50.046125],
              [8.220218, 50.04734],
              [8.219971, 50.048853],
              [8.22139, 50.04894],
              [8.22339, 50.04888],
              [8.22753, 50.04858],
              [8.22991, 50.04842],
              [8.22999, 50.0478],
              [8.230095, 50.047529],
              [8.23026, 50.0471],
              [8.23121, 50.04728],
              [8.23436, 50.047837],
              [8.23748, 50.04808],
              [8.24103, 50.04725],
              [8.24096, 50.04769],
              [8.24095, 50.04785],
              [8.24097, 50.04827],
              [8.24104, 50.04875],
              [8.24115, 50.04901],
              [8.24127, 50.04932],
              [8.24164, 50.05023],
              [8.24183, 50.050709],
              [8.24204, 50.05124],
              [8.2422, 50.05161],
              [8.24224, 50.05171],
              [8.24235, 50.05199],
              [8.24267, 50.05281],
              [8.24293, 50.05349],
              [8.24298, 50.05359],
              [8.24308, 50.0538],
              [8.24297, 50.05394],
              [8.24319, 50.0545],
              [8.24334, 50.05486],
              [8.24348, 50.05536],
              [8.2433, 50.0563],
              [8.24342, 50.05647],
              [8.24338, 50.05663],
              [8.24333, 50.05684],
              [8.24316, 50.05749],
              [8.24313, 50.05762],
              [8.24305, 50.05794],
              [8.24289, 50.05855],
              [8.2424, 50.06045],
              [8.24232, 50.0607],
              [8.24221, 50.06109],
              [8.24212, 50.06155],
              [8.24194, 50.06229],
              [8.24176, 50.06298],
              [8.24164, 50.06356],
              [8.24136, 50.06466],
              [8.24075, 50.06702],
              [8.2405, 50.06799],
              [8.2402, 50.06926],
              [8.24003, 50.06949],
              [8.23998, 50.06971],
              [8.23998, 50.0702],
              [8.24031, 50.07117],
              [8.24038, 50.07135],
              [8.2405, 50.07147],
              [8.24084, 50.07159],
              [8.24196, 50.07164],
              [8.2424, 50.07166],
              [8.24311, 50.07169],
              [8.24328, 50.0717],
              [8.24313, 50.07218],
              [8.24298, 50.07321],
              [8.24288, 50.0739],
              [8.24281, 50.0744],
              [8.242431, 50.07695],
              [8.24236, 50.07769],
              [8.24243, 50.07777],
              [8.24239, 50.07799],
              [8.24221, 50.07922],
              [8.2421, 50.07999],
              [8.24206, 50.08049],
              [8.24188, 50.08048],
              [8.23954, 50.08035],
              [8.23934, 50.08034],
              [8.23623, 50.08016],
              [8.23622, 50.08024],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22963, 50.08055],
              [8.22955, 50.08119],
              [8.22952, 50.08153],
              [8.22947, 50.08188],
              [8.22942, 50.08225],
              [8.22938, 50.08254],
              [8.22915, 50.08253],
              [8.22908, 50.08258],
              [8.228921, 50.082748],
              [8.22888, 50.08279],
              [8.22882, 50.08312],
              [8.22837, 50.08335],
              [8.22766, 50.08366],
              [8.22693, 50.08414],
              [8.22575, 50.08506],
              [8.22547, 50.08541],
              [8.2254, 50.08548],
              [8.2247, 50.08588],
              [8.224601, 50.086148],
              [8.22268, 50.08745],
              [8.22187, 50.08794],
              [8.2216, 50.08812],
              [8.22054, 50.0889],
              [8.22045, 50.08906],
              [8.2205, 50.08932],
              [8.220784, 50.089683],
              [8.22079, 50.08969],
              [8.22125, 50.08998],
              [8.2213, 50.09002],
              [8.22145, 50.09032],
              [8.2214, 50.09055],
              [8.22027, 50.09161],
              [8.21919, 50.09309],
              [8.21898, 50.09331],
              [8.21845, 50.09374],
              [8.21812, 50.09394],
              [8.21763, 50.09421],
              [8.21691, 50.09437],
              [8.21633, 50.09443],
              [8.21615, 50.09464],
              [8.21647, 50.09485],
              [8.21739, 50.0948],
              [8.21785, 50.09506],
              [8.21833, 50.0955],
              [8.21848, 50.09563],
              [8.21887, 50.09596],
              [8.219277, 50.096281],
              [8.21972, 50.09663],
              [8.22025, 50.09698],
              [8.22089, 50.09707],
              [8.22116, 50.09698],
            ],
          ],
        },
        id: "Y5MzE",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.SPRINTER,
    id: "k1NDE",
    name: "48 Hochheim <> Kohlheck",
    lineName: "48",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.185828, 50.089706],
              [8.18583, 50.08966],
              [8.18601, 50.08941],
              [8.18654, 50.08906],
              [8.18697, 50.08886],
              [8.1876, 50.08859],
              [8.188447, 50.088309],
              [8.18944, 50.08798],
              [8.1908, 50.08775],
              [8.19076, 50.08757],
              [8.19064, 50.08699],
              [8.19069, 50.0868],
              [8.19136, 50.0862],
              [8.19177, 50.08597],
              [8.19183, 50.08582],
              [8.19302, 50.08488],
              [8.19333, 50.08458],
              [8.19385, 50.0841],
              [8.19419, 50.08377],
              [8.19454, 50.08345],
              [8.19536, 50.08274],
              [8.19903, 50.08089],
              [8.19964, 50.08054],
              [8.19996, 50.08027],
              [8.20033, 50.07975],
              [8.20131, 50.07987],
              [8.20255, 50.08014],
              [8.20282, 50.08019],
              [8.2031, 50.08022],
              [8.20471, 50.08041],
              [8.20542, 50.08052],
              [8.20566, 50.08056],
              [8.20642, 50.08069],
              [8.20659, 50.08071],
              [8.20724, 50.08082],
              [8.20827, 50.08101],
              [8.20795, 50.08208],
              [8.20792, 50.08234],
              [8.20757, 50.08355],
              [8.20765, 50.08385],
              [8.20773, 50.08402],
              [8.20808, 50.08433],
              [8.20825, 50.08436],
              [8.20872, 50.08415],
              [8.21007, 50.08364],
              [8.21173, 50.083],
              [8.21466, 50.08234],
              [8.21553, 50.08232],
              [8.21592, 50.08205],
              [8.21659, 50.08186],
              [8.21771, 50.08167],
              [8.21822, 50.08156],
              [8.21862, 50.08145],
              [8.21878, 50.08139],
              [8.21894, 50.08134],
              [8.22219, 50.07995],
              [8.22389, 50.07917],
              [8.22394, 50.07924],
              [8.22422, 50.07969],
              [8.22433, 50.07999],
              [8.22461, 50.08059],
              [8.224955, 50.080535],
              [8.22604, 50.08036],
              [8.22687, 50.08041],
              [8.22757, 50.08043],
              [8.22949, 50.08052],
              [8.22963, 50.08055],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22997, 50.07829],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.244535, 50.079334],
              [8.24507, 50.07936],
              [8.24587, 50.07943],
              [8.24632, 50.07958],
              [8.24687, 50.07991],
              [8.24755, 50.07937],
              [8.24768, 50.07928],
              [8.248549, 50.07863],
              [8.24899, 50.0783],
              [8.24915, 50.07819],
              [8.24944, 50.07797],
              [8.24968, 50.07779],
              [8.25015, 50.07744],
              [8.25152, 50.07643],
              [8.25176, 50.07625],
              [8.25278, 50.07548],
              [8.25318, 50.0752],
              [8.25374, 50.07475],
              [8.2541, 50.07439],
              [8.25505, 50.07369],
              [8.25569, 50.07325],
              [8.25606, 50.07299],
              [8.25671, 50.07253],
              [8.25699, 50.07233],
              [8.25732, 50.0721],
              [8.25749, 50.07199],
              [8.25808, 50.07153],
              [8.26015, 50.06999],
              [8.26098, 50.06939],
              [8.26144, 50.06909],
              [8.2622, 50.06868],
              [8.26323, 50.06821],
              [8.26469, 50.06766],
              [8.2676, 50.06659],
              [8.2685, 50.06624],
              [8.2716, 50.06513],
              [8.27346, 50.06446],
              [8.27435, 50.06412],
              [8.27631, 50.06341],
              [8.27966, 50.06219],
              [8.28254, 50.0609],
              [8.28415, 50.0598],
              [8.28489, 50.05911],
              [8.28598, 50.05764],
              [8.28659, 50.05657],
              [8.28676, 50.05631],
              [8.2874, 50.05518],
              [8.28844, 50.05372],
              [8.28981, 50.05228],
              [8.29017, 50.0518],
              [8.29012, 50.05131],
              [8.28998, 50.05104],
              [8.28972, 50.05032],
              [8.28985, 50.04994],
              [8.29023, 50.04962],
              [8.29093, 50.0494],
              [8.29236, 50.04955],
              [8.29338, 50.04963],
              [8.29456, 50.0496],
              [8.29715, 50.0496],
              [8.29916, 50.0499],
              [8.30033, 50.0501],
              [8.3007, 50.05018],
              [8.3031, 50.0509],
              [8.3052, 50.05186],
              [8.30705, 50.05277],
              [8.30748, 50.05297],
              [8.30808, 50.05325],
              [8.31036, 50.05425],
              [8.31094, 50.05448],
              [8.31242, 50.05505],
              [8.31392, 50.05547],
              [8.31523, 50.05573],
              [8.31813, 50.05601],
              [8.3239, 50.05579],
              [8.32604, 50.05575],
              [8.33122, 50.05574],
              [8.33457, 50.05573],
              [8.33636, 50.05573],
              [8.33726, 50.05572],
              [8.3391, 50.05572],
              [8.33972, 50.05571],
              [8.33985, 50.05571],
              [8.34028, 50.05572],
              [8.34103, 50.05571],
              [8.34301, 50.0557],
              [8.34376, 50.0557],
              [8.34531, 50.05569],
              [8.36133, 50.05565],
              [8.36666, 50.05565],
              [8.36886, 50.05564],
              [8.36945, 50.05564],
              [8.3703, 50.05563],
              [8.37093, 50.05563],
              [8.3714, 50.05537],
              [8.3714, 50.0551],
              [8.37075, 50.054669],
              [8.37106, 50.05444],
              [8.37124, 50.05431],
              [8.37177, 50.05391],
              [8.371776, 50.053906],
              [8.372276, 50.053541],
              [8.37299, 50.05302],
              [8.3731, 50.05294],
              [8.3734, 50.05272],
              [8.37381, 50.05243],
              [8.3739, 50.05236],
              [8.3742, 50.05214],
              [8.37435, 50.05204],
              [8.37513, 50.05148],
              [8.3754, 50.05129],
              [8.37516, 50.05105],
              [8.374894, 50.05066],
              [8.37433, 50.04983],
              [8.37303, 50.04852],
              [8.37199, 50.04785],
              [8.37142, 50.04756],
              [8.37067, 50.04741],
              [8.37003, 50.04735],
              [8.36851, 50.0473],
              [8.36813, 50.04728],
              [8.36767, 50.04721],
              [8.36635, 50.04673],
              [8.36603, 50.04662],
              [8.36574, 50.04636],
              [8.36515, 50.04647],
              [8.36471, 50.04657],
              [8.3645, 50.04662],
              [8.36443, 50.04664],
              [8.3634, 50.0469],
              [8.36334, 50.04691],
              [8.36273, 50.04708],
              [8.36266, 50.0471],
              [8.36196, 50.04735],
              [8.36138, 50.04746],
              [8.36093, 50.04708],
              [8.36071, 50.04652],
              [8.36051, 50.04612],
              [8.36023, 50.04578],
              [8.359891, 50.045646],
              [8.3596, 50.04553],
              [8.35971, 50.04536],
              [8.35866, 50.04446],
              [8.3587, 50.04431],
              [8.35921, 50.04385],
              [8.35985, 50.04302],
              [8.36042, 50.04245],
              [8.36153, 50.04187],
              [8.36254, 50.0414],
              [8.36146, 50.03972],
              [8.3611, 50.03925],
              [8.36042, 50.03941],
              [8.36025, 50.03915],
              [8.36002, 50.03899],
              [8.35752, 50.03802],
              [8.3568, 50.03837],
              [8.35564, 50.03874],
              [8.35545, 50.03833],
              [8.35519, 50.03775],
              [8.35479, 50.03686],
              [8.35438, 50.03649],
              [8.35309, 50.03593],
              [8.3518, 50.03543],
              [8.3505, 50.03493],
              [8.35005, 50.03475],
              [8.34822, 50.033887],
              [8.348142, 50.033412],
              [8.34839, 50.03279],
              [8.34857, 50.03245],
              [8.34878, 50.03206],
              [8.34901, 50.03168],
              [8.349722, 50.030474],
              [8.35009, 50.02985],
              [8.35082, 50.02851],
              [8.35152, 50.02711],
              [8.35198, 50.02577],
              [8.35228, 50.02383],
              [8.35228, 50.02295],
              [8.35227, 50.02251],
              [8.35216, 50.02093],
              [8.3522, 50.02008],
              [8.35245, 50.01873],
              [8.3513, 50.01876],
              [8.34979, 50.01884],
              [8.34931, 50.01887],
              [8.3489, 50.0189],
              [8.34877, 50.01891],
              [8.34855, 50.01892],
              [8.34763, 50.01891],
              [8.34737, 50.01879],
              [8.34696, 50.01827],
              [8.34702, 50.01816],
              [8.34685, 50.01809],
              [8.34626, 50.0171],
              [8.34621, 50.01704],
              [8.34614, 50.01705],
              [8.34596, 50.01692],
              [8.34598, 50.01688],
              [8.34595, 50.01678],
              [8.34486, 50.01551],
              [8.34458, 50.0149],
              [8.34459, 50.01457],
              [8.34462, 50.01444],
              [8.34477, 50.01406],
              [8.34525, 50.01333],
              [8.34541, 50.01298],
              [8.34543, 50.01272],
              [8.34544, 50.01212],
              [8.34525, 50.01097],
              [8.344906, 50.010919],
              [8.34485, 50.01091],
              [8.34343, 50.011],
              [8.34321, 50.01101],
              [8.34195, 50.01099],
              [8.34136, 50.01097],
              [8.34002, 50.01079],
              [8.34011, 50.01037],
              [8.34043, 50.01013],
              [8.3412, 50.0098],
              [8.3416, 50.00948],
              [8.34283, 50.00678],
              [8.34303, 50.00657],
              [8.34356, 50.00637],
              [8.34392, 50.00614],
              [8.34442, 50.00515],
              [8.34718, 50.00492],
              [8.350616, 50.004676],
            ],
          ],
        },
        id: "k1NDE",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.350616, 50.004676],
              [8.34718, 50.00492],
              [8.34442, 50.00515],
              [8.34392, 50.00614],
              [8.34356, 50.00637],
              [8.34303, 50.00657],
              [8.34283, 50.00678],
              [8.3416, 50.00948],
              [8.3412, 50.0098],
              [8.34043, 50.01013],
              [8.34011, 50.01037],
              [8.34002, 50.01079],
              [8.34136, 50.01097],
              [8.34195, 50.01099],
              [8.34321, 50.01101],
              [8.34343, 50.011],
              [8.34485, 50.01091],
              [8.344906, 50.010919],
              [8.34525, 50.01097],
              [8.34544, 50.01212],
              [8.34543, 50.01272],
              [8.34541, 50.01298],
              [8.34525, 50.01333],
              [8.34477, 50.01406],
              [8.34462, 50.01444],
              [8.34459, 50.01457],
              [8.34458, 50.0149],
              [8.34486, 50.01551],
              [8.34595, 50.01678],
              [8.34598, 50.01688],
              [8.34596, 50.01692],
              [8.34614, 50.01705],
              [8.34621, 50.01704],
              [8.34626, 50.0171],
              [8.34685, 50.01809],
              [8.34702, 50.01816],
              [8.34696, 50.01827],
              [8.34737, 50.01879],
              [8.34763, 50.01891],
              [8.34855, 50.01892],
              [8.34877, 50.01891],
              [8.3489, 50.0189],
              [8.34931, 50.01887],
              [8.34979, 50.01884],
              [8.3513, 50.01876],
              [8.35245, 50.01873],
              [8.3522, 50.02008],
              [8.35216, 50.02093],
              [8.35227, 50.02251],
              [8.35228, 50.02295],
              [8.35228, 50.02383],
              [8.35198, 50.02577],
              [8.35152, 50.02711],
              [8.35082, 50.02851],
              [8.35009, 50.02985],
              [8.349722, 50.030474],
              [8.34901, 50.03168],
              [8.34878, 50.03206],
              [8.34857, 50.03245],
              [8.34839, 50.03279],
              [8.348142, 50.033412],
              [8.34822, 50.033887],
              [8.35005, 50.03475],
              [8.3505, 50.03493],
              [8.3518, 50.03543],
              [8.35309, 50.03593],
              [8.35438, 50.03649],
              [8.35479, 50.03686],
              [8.35519, 50.03775],
              [8.35545, 50.03833],
              [8.35564, 50.03874],
              [8.3568, 50.03837],
              [8.35752, 50.03802],
              [8.35774, 50.03773],
              [8.35814, 50.03729],
              [8.35832, 50.03726],
              [8.3592, 50.03757],
              [8.35957, 50.03772],
              [8.36042, 50.038],
              [8.36018, 50.03829],
              [8.36004, 50.03846],
              [8.36129, 50.03897],
              [8.3611, 50.03925],
              [8.36146, 50.03972],
              [8.36254, 50.0414],
              [8.36153, 50.04187],
              [8.36042, 50.04245],
              [8.35985, 50.04302],
              [8.35921, 50.04385],
              [8.3587, 50.04431],
              [8.35866, 50.04446],
              [8.35971, 50.04536],
              [8.3596, 50.04553],
              [8.359891, 50.045646],
              [8.36023, 50.04578],
              [8.36051, 50.04612],
              [8.36071, 50.04652],
              [8.36093, 50.04708],
              [8.36138, 50.04746],
              [8.36196, 50.04735],
              [8.36266, 50.0471],
              [8.36273, 50.04708],
              [8.36334, 50.04691],
              [8.3634, 50.0469],
              [8.36443, 50.04664],
              [8.3645, 50.04662],
              [8.36471, 50.04657],
              [8.36515, 50.04647],
              [8.36574, 50.04636],
              [8.36603, 50.04662],
              [8.36635, 50.04673],
              [8.36767, 50.04721],
              [8.36813, 50.04728],
              [8.36851, 50.0473],
              [8.37003, 50.04735],
              [8.37067, 50.04741],
              [8.37142, 50.04756],
              [8.37199, 50.04785],
              [8.37303, 50.04852],
              [8.37433, 50.04983],
              [8.374894, 50.05066],
              [8.37516, 50.05105],
              [8.3754, 50.05129],
              [8.37513, 50.05148],
              [8.37435, 50.05204],
              [8.3742, 50.05214],
              [8.3739, 50.05236],
              [8.37381, 50.05243],
              [8.3734, 50.05272],
              [8.3731, 50.05294],
              [8.37299, 50.05302],
              [8.372276, 50.053541],
              [8.371776, 50.053906],
              [8.37177, 50.05391],
              [8.37124, 50.05431],
              [8.37106, 50.05444],
              [8.37075, 50.054669],
              [8.3703, 50.05514],
              [8.3698, 50.0566],
              [8.37017, 50.05662],
              [8.37033, 50.05661],
              [8.37092, 50.05635],
              [8.37105, 50.05609],
              [8.37092, 50.05593],
              [8.37031, 50.05579],
              [8.3694, 50.05578],
              [8.36886, 50.05579],
              [8.36111, 50.0558],
              [8.34377, 50.05587],
              [8.3428, 50.05586],
              [8.34065, 50.05585],
              [8.3399, 50.05587],
              [8.3391, 50.05588],
              [8.33727, 50.05588],
              [8.33637, 50.05587],
              [8.33439, 50.05588],
              [8.32618, 50.0559],
              [8.32606, 50.0559],
              [8.32393, 50.05594],
              [8.31804, 50.05615],
              [8.31607, 50.056],
              [8.31372, 50.05558],
              [8.31321, 50.05546],
              [8.31267, 50.05548],
              [8.31122, 50.05498],
              [8.31084, 50.05462],
              [8.31025, 50.05439],
              [8.30926, 50.05396],
              [8.30842, 50.05361],
              [8.30793, 50.05339],
              [8.3074, 50.05312],
              [8.30688, 50.05287],
              [8.3065, 50.05269],
              [8.30527, 50.05207],
              [8.3027, 50.05092],
              [8.30062, 50.05031],
              [8.29918, 50.05006],
              [8.29744, 50.04991],
              [8.29609, 50.05002],
              [8.29515, 50.05038],
              [8.29398, 50.05097],
              [8.29308, 50.05117],
              [8.29175, 50.05133],
              [8.29082, 50.05172],
              [8.29047, 50.05191],
              [8.28998, 50.05232],
              [8.28857, 50.05377],
              [8.28748, 50.05531],
              [8.28677, 50.05657],
              [8.2866, 50.05689],
              [8.28613, 50.05769],
              [8.2855, 50.05858],
              [8.28426, 50.05985],
              [8.28263, 50.06098],
              [8.28017, 50.06211],
              [8.27644, 50.06349],
              [8.27444, 50.06423],
              [8.27387, 50.06444],
              [8.2717, 50.06521],
              [8.26859, 50.06634],
              [8.26768, 50.06668],
              [8.26479, 50.06775],
              [8.26329, 50.06831],
              [8.26225, 50.06878],
              [8.26137, 50.06928],
              [8.26031, 50.06999],
              [8.2593, 50.07078],
              [8.25805, 50.07172],
              [8.25767, 50.07199],
              [8.25727, 50.07224],
              [8.25699, 50.07233],
              [8.25693, 50.07258],
              [8.25587, 50.07334],
              [8.25514, 50.07374],
              [8.25419, 50.07444],
              [8.25374, 50.07475],
              [8.25318, 50.0752],
              [8.25278, 50.07548],
              [8.25176, 50.07625],
              [8.25152, 50.07643],
              [8.25015, 50.07744],
              [8.24968, 50.07779],
              [8.24944, 50.07797],
              [8.24915, 50.07819],
              [8.24899, 50.0783],
              [8.248549, 50.07863],
              [8.24768, 50.07928],
              [8.24755, 50.07937],
              [8.24687, 50.07991],
              [8.24576, 50.08049],
              [8.24493, 50.08066],
              [8.24371, 50.0806],
              [8.24282, 50.08054],
              [8.23934, 50.08034],
              [8.23623, 50.08016],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22949, 50.08052],
              [8.22757, 50.08043],
              [8.22687, 50.08041],
              [8.22604, 50.08036],
              [8.224955, 50.080535],
              [8.22461, 50.08059],
              [8.22433, 50.07999],
              [8.22422, 50.07969],
              [8.22394, 50.07924],
              [8.22227, 50.08002],
              [8.21884, 50.08148],
              [8.2183, 50.08162],
              [8.21663, 50.08197],
              [8.21592, 50.08205],
              [8.21553, 50.08232],
              [8.21466, 50.08234],
              [8.21173, 50.083],
              [8.21007, 50.08364],
              [8.20872, 50.08415],
              [8.20825, 50.08436],
              [8.20808, 50.08433],
              [8.20773, 50.08402],
              [8.20765, 50.08385],
              [8.20757, 50.08355],
              [8.20792, 50.08234],
              [8.20795, 50.08208],
              [8.20827, 50.08101],
              [8.20724, 50.08082],
              [8.20659, 50.08071],
              [8.20642, 50.08069],
              [8.20566, 50.08056],
              [8.20542, 50.08052],
              [8.20471, 50.08041],
              [8.2031, 50.08022],
              [8.20282, 50.08019],
              [8.20255, 50.08014],
              [8.20131, 50.07987],
              [8.20033, 50.07975],
              [8.19996, 50.08027],
              [8.19964, 50.08054],
              [8.19903, 50.08089],
              [8.19536, 50.08274],
              [8.19454, 50.08345],
              [8.19419, 50.08377],
              [8.19385, 50.0841],
              [8.19333, 50.08458],
              [8.19302, 50.08488],
              [8.19183, 50.08582],
              [8.19177, 50.08597],
              [8.19136, 50.0862],
              [8.19069, 50.0868],
              [8.19064, 50.08699],
              [8.19076, 50.08757],
              [8.1908, 50.08775],
              [8.18944, 50.08798],
              [8.188447, 50.088309],
              [8.1876, 50.08859],
              [8.18802, 50.08913],
              [8.18748, 50.08952],
              [8.18656, 50.09032],
              [8.18599, 50.09051],
              [8.1858, 50.0905],
              [8.18581, 50.09013],
              [8.185828, 50.089706],
            ],
          ],
        },
        id: "g3NDk",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.SPRINTER,
    id: "k4MDQ",
    name: "47 Erbenheim <> Schierstein",
    lineName: "47",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.18686, 50.04559],
              [8.19007, 50.04548],
              [8.19042, 50.04504],
              [8.19174, 50.04524],
              [8.19219, 50.04532],
              [8.19392, 50.04562],
              [8.19456, 50.04562],
              [8.19504, 50.04508],
              [8.19513, 50.04504],
              [8.19537, 50.04497],
              [8.19566, 50.04488],
              [8.19611, 50.04466],
              [8.19626, 50.04461],
              [8.19703, 50.04454],
              [8.19717, 50.04454],
              [8.19766, 50.04455],
              [8.19796, 50.04455],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.20083, 50.04642],
              [8.20113, 50.04675],
              [8.20195, 50.04766],
              [8.20258, 50.04839],
              [8.2029, 50.04876],
              [8.20429, 50.05032],
              [8.20407, 50.05038],
              [8.20181, 50.05022],
              [8.20066, 50.04989],
              [8.197625, 50.049466],
              [8.19687, 50.04936],
              [8.19588, 50.04921],
              [8.19413, 50.04896],
              [8.19352, 50.04889],
              [8.19268, 50.04877],
              [8.19236, 50.04939],
              [8.19211, 50.04985],
              [8.1918, 50.05054],
              [8.1916, 50.05108],
              [8.19315, 50.05152],
              [8.19525, 50.05193],
              [8.19542, 50.0526],
              [8.19547, 50.05275],
              [8.19561, 50.0533],
              [8.19591, 50.05475],
              [8.19726, 50.05481],
              [8.19807, 50.05478],
              [8.19867, 50.05689],
              [8.19892, 50.05771],
              [8.199045, 50.05947],
              [8.19908, 50.05997],
              [8.19918, 50.0615],
              [8.19914, 50.06349],
              [8.19818, 50.06351],
              [8.196875, 50.06351],
              [8.19641, 50.06351],
              [8.19585, 50.0635],
              [8.19249, 50.06306],
              [8.19036, 50.0625],
              [8.18936, 50.06202],
              [8.18802, 50.06133],
              [8.18702, 50.06212],
              [8.18635, 50.06264],
              [8.18583, 50.06304],
              [8.18512, 50.06363],
              [8.18415, 50.06457],
              [8.18384, 50.06487],
              [8.18378, 50.06494],
              [8.18154, 50.06723],
              [8.18156, 50.06749],
              [8.18163, 50.06762],
              [8.182356, 50.06749],
              [8.1833, 50.06732],
              [8.18517, 50.06713],
              [8.187924, 50.067341],
              [8.18882, 50.06741],
              [8.18923, 50.06744],
              [8.18976, 50.06749],
              [8.19142, 50.06766],
              [8.18976, 50.06749],
              [8.19142, 50.06766],
              [8.19292, 50.06784],
              [8.19365, 50.06797],
              [8.19452, 50.06836],
              [8.1947, 50.06853],
              [8.19503, 50.06914],
              [8.19502, 50.06942],
              [8.19473, 50.0706],
              [8.19515, 50.0716],
              [8.19541, 50.07186],
              [8.1955, 50.07192],
              [8.195665, 50.072002],
              [8.19664, 50.07249],
              [8.19819, 50.07288],
              [8.20193, 50.07335],
              [8.20351, 50.07381],
              [8.20409, 50.07408],
              [8.20527, 50.07478],
              [8.20655, 50.07578],
              [8.20735, 50.07608],
              [8.20778, 50.07614],
              [8.20857, 50.07628],
              [8.20963, 50.07636],
              [8.21431, 50.07681],
              [8.21606, 50.07697],
              [8.216627, 50.077025],
              [8.22071, 50.07739],
              [8.22216, 50.07755],
              [8.2223, 50.07756],
              [8.22386, 50.0777],
              [8.22585, 50.0779],
              [8.22656, 50.07796],
              [8.22682, 50.07786],
              [8.22799, 50.07726],
              [8.22819, 50.07719],
              [8.22837, 50.07718],
              [8.22894, 50.07703],
              [8.22941, 50.07699],
              [8.22989, 50.07706],
              [8.229975, 50.076791],
              [8.23143, 50.07437],
              [8.23153, 50.07426],
              [8.23224, 50.07369],
              [8.23292, 50.07322],
              [8.23334, 50.07298],
              [8.23387, 50.07271],
              [8.23393, 50.07268],
              [8.23483, 50.07229],
              [8.23534, 50.07211],
              [8.23607, 50.07189],
              [8.23747, 50.0716],
              [8.239065, 50.071401],
              [8.24038, 50.07135],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24475, 50.07159],
              [8.24495, 50.07161],
              [8.24592, 50.07166],
              [8.24683, 50.07171],
              [8.24746, 50.07176],
              [8.24809, 50.07179],
              [8.25112, 50.07194],
              [8.25181, 50.07198],
              [8.25311, 50.07204],
              [8.25403, 50.07209],
              [8.25487, 50.07212],
              [8.25494, 50.07212],
              [8.25662, 50.07215],
              [8.25749, 50.07199],
              [8.25808, 50.07153],
              [8.26015, 50.06999],
              [8.26098, 50.06939],
              [8.26144, 50.06909],
              [8.2622, 50.06868],
              [8.26225, 50.06859],
              [8.263269, 50.068021],
              [8.26354, 50.06787],
              [8.263861, 50.068304],
              [8.26439, 50.06814],
              [8.2684, 50.0667],
              [8.26938, 50.06653],
              [8.2711, 50.06648],
              [8.27289, 50.06612],
              [8.27424, 50.06578],
              [8.27541, 50.06543],
              [8.27528, 50.06527],
              [8.27472, 50.06458],
              [8.27379, 50.06344],
              [8.27276, 50.06382],
              [8.27253, 50.06417],
              [8.27264, 50.06436],
              [8.27298, 50.06446],
              [8.27346, 50.06446],
              [8.27435, 50.06412],
              [8.27631, 50.06341],
              [8.27966, 50.06219],
              [8.28254, 50.0609],
              [8.28415, 50.0598],
              [8.2846, 50.05906],
              [8.2845, 50.05821],
              [8.28451, 50.05771],
              [8.28482, 50.05726],
              [8.28489, 50.05718],
              [8.28563, 50.05675],
              [8.28659, 50.05657],
              [8.28677, 50.05657],
              [8.28747, 50.05666],
              [8.28856, 50.05714],
              [8.28958, 50.05807],
              [8.28992, 50.05843],
              [8.29007, 50.05868],
              [8.29036, 50.05959],
              [8.29142, 50.06171],
              [8.29313, 50.06215],
              [8.29376, 50.06211],
              [8.29597, 50.06143],
              [8.296, 50.06082],
              [8.29603, 50.06014],
              [8.29607, 50.05925],
              [8.29595, 50.05852],
              [8.29598, 50.05806],
              [8.29602, 50.0579],
              [8.29652, 50.05624],
              [8.29648, 50.05603],
              [8.29903, 50.05507],
              [8.30044, 50.05423],
              [8.30079, 50.05403],
              [8.30106, 50.05387],
              [8.30126, 50.05388],
              [8.30134, 50.05374],
              [8.30228, 50.05341],
              [8.30262, 50.05332],
              [8.30433, 50.05313],
              [8.30465, 50.05326],
              [8.3054, 50.05481],
              [8.30534, 50.05529],
              [8.3056, 50.05533],
              [8.30667, 50.0555],
              [8.307, 50.05556],
              [8.30731, 50.05561],
              [8.30745, 50.05564],
              [8.3075, 50.05566],
              [8.307443, 50.056],
              [8.3074, 50.05626],
              [8.30742, 50.05662],
              [8.30756, 50.05684],
              [8.30782, 50.05719],
              [8.30823, 50.05771],
              [8.30832, 50.05782],
              [8.30697, 50.05877],
              [8.30655, 50.05911],
              [8.30617, 50.05927],
              [8.30562, 50.05935],
              [8.30439, 50.05927],
              [8.30191, 50.05892],
              [8.3019, 50.05897],
              [8.30183, 50.05951],
              [8.301902, 50.060067],
              [8.30198, 50.06067],
              [8.30197, 50.06132],
              [8.302484, 50.061564],
              [8.30317, 50.06189],
              [8.30343, 50.06187],
              [8.30356, 50.0615],
              [8.3041, 50.06001],
              [8.30439, 50.05927],
            ],
          ],
        },
        id: "k4MDQ",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.30439, 50.05927],
              [8.30562, 50.05935],
              [8.30617, 50.05927],
              [8.30655, 50.05911],
              [8.30697, 50.05877],
              [8.30832, 50.05782],
              [8.30823, 50.05771],
              [8.30782, 50.05719],
              [8.30756, 50.05684],
              [8.30742, 50.05662],
              [8.3074, 50.05626],
              [8.307443, 50.056],
              [8.3075, 50.05566],
              [8.30745, 50.05564],
              [8.30731, 50.05561],
              [8.307, 50.05556],
              [8.30667, 50.0555],
              [8.3056, 50.05533],
              [8.30534, 50.05529],
              [8.3054, 50.05481],
              [8.30465, 50.05326],
              [8.30433, 50.05313],
              [8.30262, 50.05332],
              [8.30228, 50.05341],
              [8.30134, 50.05374],
              [8.30126, 50.05388],
              [8.30106, 50.05387],
              [8.30079, 50.05403],
              [8.30044, 50.05423],
              [8.29903, 50.05507],
              [8.29648, 50.05603],
              [8.29652, 50.05624],
              [8.29602, 50.0579],
              [8.29598, 50.05806],
              [8.29595, 50.05852],
              [8.29607, 50.05925],
              [8.29603, 50.06014],
              [8.296, 50.06082],
              [8.29597, 50.06143],
              [8.29376, 50.06211],
              [8.29313, 50.06215],
              [8.29142, 50.06171],
              [8.29036, 50.05959],
              [8.288623, 50.058902],
              [8.28739, 50.05935],
              [8.2865, 50.05968],
              [8.28631, 50.05975],
              [8.28557, 50.06002],
              [8.28398, 50.06059],
              [8.28312, 50.06079],
              [8.28263, 50.06098],
              [8.28017, 50.06211],
              [8.27644, 50.06349],
              [8.27592, 50.06388],
              [8.27584, 50.06414],
              [8.27609, 50.06458],
              [8.27632, 50.065],
              [8.27622, 50.06508],
              [8.2759, 50.06533],
              [8.27573, 50.06537],
              [8.27541, 50.06543],
              [8.27424, 50.06578],
              [8.27289, 50.06612],
              [8.2711, 50.06648],
              [8.26938, 50.06653],
              [8.2684, 50.0667],
              [8.26439, 50.06814],
              [8.263861, 50.068304],
              [8.263, 50.06857],
              [8.26225, 50.06878],
              [8.26137, 50.06928],
              [8.26031, 50.06999],
              [8.2593, 50.07078],
              [8.25805, 50.07172],
              [8.25767, 50.07199],
              [8.25727, 50.07224],
              [8.25699, 50.07233],
              [8.25671, 50.07253],
              [8.25641, 50.07248],
              [8.25518, 50.07238],
              [8.2547, 50.07235],
              [8.2531, 50.07223],
              [8.25181, 50.07215],
              [8.25111, 50.07212],
              [8.25036, 50.07207],
              [8.24916, 50.07199],
              [8.24887, 50.07198],
              [8.24834, 50.07196],
              [8.24819, 50.07195],
              [8.24803, 50.07194],
              [8.24745, 50.07192],
              [8.2459, 50.07184],
              [8.24492, 50.07179],
              [8.24425, 50.07176],
              [8.24328, 50.0717],
              [8.24311, 50.07169],
              [8.2424, 50.07166],
              [8.24196, 50.07164],
              [8.24084, 50.07159],
              [8.24041, 50.07158],
              [8.23907, 50.07161],
              [8.23836, 50.07167],
              [8.23751, 50.07178],
              [8.23615, 50.07209],
              [8.23547, 50.07229],
              [8.2347, 50.07257],
              [8.23396, 50.07291],
              [8.23393, 50.07293],
              [8.2336, 50.07309],
              [8.23313, 50.07336],
              [8.23247, 50.07378],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23112, 50.07506],
              [8.23077, 50.07557],
              [8.23074, 50.07561],
              [8.23036, 50.07637],
              [8.23019, 50.07716],
              [8.23015, 50.07735],
              [8.22997, 50.07829],
              [8.22974, 50.07827],
              [8.2287, 50.07815],
              [8.22777, 50.07806],
              [8.22656, 50.07796],
              [8.22585, 50.0779],
              [8.22386, 50.0777],
              [8.2223, 50.07756],
              [8.22216, 50.07755],
              [8.22071, 50.07739],
              [8.216627, 50.077025],
              [8.21606, 50.07697],
              [8.21431, 50.07681],
              [8.20963, 50.07636],
              [8.20857, 50.07628],
              [8.20769, 50.07623],
              [8.20714, 50.07614],
              [8.20642, 50.07585],
              [8.20533, 50.07497],
              [8.20449, 50.0744],
              [8.20404, 50.07415],
              [8.20347, 50.07389],
              [8.20212, 50.07347],
              [8.19783, 50.07291],
              [8.19738, 50.07281],
              [8.19599, 50.07232],
              [8.195625, 50.072106],
              [8.19536, 50.07195],
              [8.19514, 50.07175],
              [8.19477, 50.07111],
              [8.19473, 50.0706],
              [8.19502, 50.06942],
              [8.19503, 50.06914],
              [8.1947, 50.06853],
              [8.19452, 50.06836],
              [8.19365, 50.06797],
              [8.19292, 50.06784],
              [8.19142, 50.06766],
              [8.18976, 50.06749],
              [8.18923, 50.06744],
              [8.18882, 50.06741],
              [8.187924, 50.067341],
              [8.18517, 50.06713],
              [8.1833, 50.06732],
              [8.182356, 50.06749],
              [8.18163, 50.06762],
              [8.18156, 50.06749],
              [8.18154, 50.06723],
              [8.18378, 50.06494],
              [8.18384, 50.06487],
              [8.18415, 50.06457],
              [8.18512, 50.06363],
              [8.18583, 50.06304],
              [8.18635, 50.06264],
              [8.18702, 50.06212],
              [8.18802, 50.06133],
              [8.18936, 50.06202],
              [8.19036, 50.0625],
              [8.19249, 50.06306],
              [8.19585, 50.0635],
              [8.19641, 50.06351],
              [8.196875, 50.06351],
              [8.19818, 50.06351],
              [8.19914, 50.06349],
              [8.19918, 50.0615],
              [8.19908, 50.05997],
              [8.199045, 50.05947],
              [8.19892, 50.05771],
              [8.19867, 50.05689],
              [8.19807, 50.05478],
              [8.19726, 50.05481],
              [8.19591, 50.05475],
              [8.19561, 50.0533],
              [8.19547, 50.05275],
              [8.19542, 50.0526],
              [8.19525, 50.05193],
              [8.19315, 50.05152],
              [8.1916, 50.05108],
              [8.1918, 50.05054],
              [8.19211, 50.04985],
              [8.19236, 50.04939],
              [8.19268, 50.04877],
              [8.19352, 50.04889],
              [8.19413, 50.04896],
              [8.19588, 50.04921],
              [8.19687, 50.04936],
              [8.197625, 50.049466],
              [8.20066, 50.04989],
              [8.20181, 50.05022],
              [8.20407, 50.05038],
              [8.20429, 50.05032],
              [8.2029, 50.04876],
              [8.20258, 50.04839],
              [8.20195, 50.04766],
              [8.20113, 50.04675],
              [8.20083, 50.04642],
              [8.20039, 50.04593],
              [8.19923, 50.04589],
              [8.19767, 50.04577],
              [8.19617, 50.04565],
              [8.19585, 50.04563],
              [8.19456, 50.04562],
              [8.19392, 50.04562],
              [8.19219, 50.04532],
              [8.19174, 50.04524],
              [8.19042, 50.04504],
              [8.19007, 50.04548],
              [8.18957, 50.04619],
              [8.18927, 50.04654],
              [8.18667, 50.04678],
              [8.18628, 50.04672],
              [8.18616, 50.04651],
              [8.18659, 50.0458],
              [8.18686, 50.04559],
            ],
          ],
        },
        id: "k0Njg",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.SPRINTER,
    id: "AzMjU",
    name: "45_Breckenheim <> 45_Äppelallee",
    lineName: "45",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.214384, 50.045994],
              [8.214267, 50.046989],
              [8.220218, 50.04734],
              [8.220429, 50.046125],
              [8.22056, 50.04537],
              [8.22044, 50.04471],
              [8.2202, 50.04424],
              [8.22197, 50.04382],
              [8.22134, 50.04273],
              [8.21822, 50.04289],
              [8.21701, 50.04295],
              [8.21679, 50.04284],
              [8.21648, 50.04191],
              [8.21633, 50.04137],
              [8.21347, 50.042],
              [8.21293, 50.04213],
              [8.21257, 50.04221],
              [8.21018, 50.04276],
              [8.20986, 50.04283],
              [8.20931, 50.04294],
              [8.208261, 50.043171],
              [8.20476, 50.04394],
              [8.20368, 50.04418],
              [8.2027, 50.0444],
              [8.20189, 50.04456],
              [8.20151, 50.04462],
              [8.20059, 50.04463],
              [8.19938, 50.0446],
              [8.1996, 50.04504],
              [8.20039, 50.04593],
              [8.20083, 50.04642],
              [8.20113, 50.04675],
              [8.20195, 50.04766],
              [8.20258, 50.04839],
              [8.2029, 50.04876],
              [8.20429, 50.05032],
              [8.20407, 50.05038],
              [8.20181, 50.05022],
              [8.20066, 50.04989],
              [8.197625, 50.049466],
              [8.19687, 50.04936],
              [8.19588, 50.04921],
              [8.19413, 50.04896],
              [8.19352, 50.04889],
              [8.19268, 50.04877],
              [8.19236, 50.04939],
              [8.19211, 50.04985],
              [8.1918, 50.05054],
              [8.1916, 50.05108],
              [8.19315, 50.05152],
              [8.19525, 50.05193],
              [8.19542, 50.0526],
              [8.19547, 50.05275],
              [8.19561, 50.0533],
              [8.19591, 50.05475],
              [8.19726, 50.05481],
              [8.198097, 50.054797],
              [8.198462, 50.05605],
              [8.19867, 50.05689],
              [8.19892, 50.05771],
              [8.199045, 50.05947],
              [8.19908, 50.05997],
              [8.19918, 50.0615],
              [8.19914, 50.06349],
              [8.199088, 50.063994],
              [8.19904, 50.06445],
              [8.19887, 50.06554],
              [8.19913, 50.06769],
              [8.19912, 50.067708],
              [8.19838, 50.06896],
              [8.19792, 50.06946],
              [8.19662, 50.07079],
              [8.1955, 50.07192],
              [8.195665, 50.072002],
              [8.19664, 50.07249],
              [8.19819, 50.07288],
              [8.20193, 50.07335],
              [8.20351, 50.07381],
              [8.20409, 50.07408],
              [8.20527, 50.07478],
              [8.20655, 50.07578],
              [8.20735, 50.07608],
              [8.20778, 50.07614],
              [8.20857, 50.07628],
              [8.20963, 50.07636],
              [8.21431, 50.07681],
              [8.21606, 50.07697],
              [8.216627, 50.077025],
              [8.22071, 50.07739],
              [8.22216, 50.07755],
              [8.2223, 50.07756],
              [8.22386, 50.0777],
              [8.22585, 50.0779],
              [8.22656, 50.07796],
              [8.22777, 50.07806],
              [8.2287, 50.07815],
              [8.22974, 50.07827],
              [8.22997, 50.07829],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.244535, 50.079334],
              [8.24507, 50.07936],
              [8.24587, 50.07943],
              [8.24632, 50.07958],
              [8.24687, 50.07991],
              [8.24759, 50.08014],
              [8.24787, 50.08022],
              [8.24875, 50.08047],
              [8.2496, 50.08069],
              [8.24982, 50.08075],
              [8.250337, 50.080887],
              [8.25069, 50.08098],
              [8.25159, 50.08121],
              [8.25238, 50.08142],
              [8.25398, 50.08184],
              [8.25421, 50.0819],
              [8.25536, 50.08215],
              [8.25562, 50.08218],
              [8.25848, 50.08273],
              [8.26007, 50.08306],
              [8.26159, 50.08346],
              [8.26261, 50.08372],
              [8.26405, 50.08408],
              [8.26533, 50.08438],
              [8.26591, 50.08444],
              [8.26664, 50.08449],
              [8.26798, 50.08459],
              [8.27015, 50.08475],
              [8.27178, 50.08499],
              [8.27217, 50.08504],
              [8.2737, 50.08527],
              [8.275, 50.08543],
              [8.27653, 50.08513],
              [8.27667, 50.08509],
              [8.27724, 50.08485],
              [8.27768, 50.084646],
              [8.27866, 50.08419],
              [8.27913, 50.08398],
              [8.28057, 50.0833],
              [8.28069, 50.08326],
              [8.28078, 50.08323],
              [8.28085, 50.0832],
              [8.28096, 50.08316],
              [8.28119, 50.08307],
              [8.28198, 50.08271],
              [8.28307, 50.08219],
              [8.28327, 50.08212],
              [8.28367, 50.08217],
              [8.28435, 50.08232],
              [8.28453, 50.08235],
              [8.28526, 50.08255],
              [8.28638, 50.08293],
              [8.28671, 50.08218],
              [8.28761, 50.08224],
              [8.2883, 50.08215],
              [8.28855, 50.08212],
              [8.28967, 50.08212],
              [8.29083, 50.08218],
              [8.29431, 50.082204],
              [8.29515, 50.08221],
              [8.29746, 50.08182],
              [8.29831, 50.08172],
              [8.29923, 50.08173],
              [8.29995, 50.08175],
              [8.30056, 50.08177],
              [8.30553, 50.08196],
              [8.30963, 50.0821],
              [8.31084, 50.08206],
              [8.31214, 50.08201],
              [8.31739, 50.0815],
              [8.32118, 50.08095],
              [8.3219, 50.08086],
              [8.32272, 50.08081],
              [8.32447, 50.0807],
              [8.32462, 50.08069],
              [8.32601, 50.08057],
              [8.32618, 50.08054],
              [8.32632, 50.08051],
              [8.32695, 50.08056],
              [8.32709, 50.08055],
              [8.32725, 50.08053],
              [8.32776, 50.08052],
              [8.32832, 50.08068],
              [8.32841, 50.08076],
              [8.32873, 50.08142],
              [8.32887, 50.08175],
              [8.3291, 50.08189],
              [8.33029, 50.08224],
              [8.33043, 50.08229],
              [8.33125, 50.08244],
              [8.33182, 50.08251],
              [8.33246, 50.08254],
              [8.33476, 50.08217],
              [8.33558, 50.08203],
              [8.33677, 50.08182],
              [8.34832, 50.08054],
              [8.35124, 50.08056],
              [8.35211, 50.08062],
              [8.35263, 50.08141],
              [8.353, 50.0816],
              [8.353518, 50.081552],
              [8.35463, 50.08145],
              [8.35697, 50.08071],
              [8.35794, 50.0805],
              [8.35975, 50.08044],
              [8.36155, 50.08043],
              [8.36221, 50.08043],
              [8.36353, 50.08042],
              [8.36365, 50.08042],
              [8.36376, 50.08049],
              [8.36394, 50.08042],
              [8.36497, 50.08042],
              [8.36606, 50.08043],
              [8.36701, 50.08037],
              [8.367365, 50.080347],
              [8.36808, 50.0803],
              [8.36754, 50.08159],
              [8.367525, 50.081606],
              [8.36726, 50.08188],
              [8.36665, 50.08252],
              [8.36658, 50.0826],
              [8.36704, 50.08272],
              [8.36797, 50.08295],
              [8.36915, 50.08262],
              [8.3693, 50.08258],
              [8.36983, 50.08237],
              [8.37, 50.08232],
              [8.3703, 50.08214],
              [8.371, 50.08138],
              [8.37128, 50.08109],
              [8.3718, 50.0807],
            ],
          ],
        },
        id: "AzMjU",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.3718, 50.0807],
              [8.37171, 50.08058],
              [8.37217, 50.08004],
              [8.37264, 50.07987],
              [8.37323, 50.07921],
              [8.37291, 50.07893],
              [8.37208, 50.07868],
              [8.37222, 50.07858],
              [8.37239, 50.07831],
              [8.37252, 50.07804],
              [8.37251, 50.07768],
              [8.3725, 50.07749],
              [8.37189, 50.07697],
              [8.37134, 50.07682],
              [8.37019, 50.0766],
              [8.36969, 50.07766],
              [8.36927, 50.07845],
              [8.3692, 50.07861],
              [8.36845, 50.07956],
              [8.36808, 50.0803],
              [8.367365, 50.080347],
              [8.36701, 50.08037],
              [8.36606, 50.08043],
              [8.36497, 50.08042],
              [8.36394, 50.08042],
              [8.36376, 50.08049],
              [8.36365, 50.08042],
              [8.36353, 50.08042],
              [8.36221, 50.08043],
              [8.36155, 50.08043],
              [8.35975, 50.08044],
              [8.35794, 50.0805],
              [8.35697, 50.08071],
              [8.35463, 50.08145],
              [8.353518, 50.081552],
              [8.353, 50.0816],
              [8.35263, 50.08141],
              [8.35211, 50.08062],
              [8.35124, 50.08056],
              [8.34832, 50.08054],
              [8.33677, 50.08182],
              [8.33558, 50.08203],
              [8.33476, 50.08217],
              [8.33246, 50.08254],
              [8.33182, 50.08251],
              [8.33125, 50.08244],
              [8.33043, 50.08229],
              [8.33029, 50.08224],
              [8.3291, 50.08189],
              [8.32887, 50.08175],
              [8.32873, 50.08142],
              [8.32841, 50.08076],
              [8.32832, 50.08068],
              [8.32776, 50.08052],
              [8.32725, 50.08053],
              [8.32709, 50.08055],
              [8.32695, 50.08056],
              [8.32632, 50.08051],
              [8.32618, 50.08054],
              [8.32601, 50.08057],
              [8.32462, 50.08069],
              [8.32447, 50.0807],
              [8.32272, 50.08081],
              [8.3219, 50.08086],
              [8.32118, 50.08095],
              [8.31739, 50.0815],
              [8.31214, 50.08201],
              [8.31084, 50.08206],
              [8.30963, 50.0821],
              [8.30553, 50.08196],
              [8.30056, 50.08177],
              [8.29995, 50.08175],
              [8.29923, 50.08173],
              [8.29831, 50.08172],
              [8.29746, 50.08182],
              [8.29515, 50.08221],
              [8.29431, 50.082204],
              [8.29083, 50.08218],
              [8.28967, 50.08212],
              [8.28855, 50.08212],
              [8.2883, 50.08215],
              [8.28761, 50.08224],
              [8.28671, 50.08218],
              [8.28638, 50.08293],
              [8.28633, 50.08305],
              [8.28613, 50.08342],
              [8.28541, 50.0838],
              [8.28459, 50.08377],
              [8.28332, 50.08368],
              [8.28178, 50.08347],
              [8.28095, 50.08333],
              [8.28072, 50.08331],
              [8.28057, 50.0833],
              [8.27913, 50.08398],
              [8.27866, 50.08419],
              [8.27768, 50.084646],
              [8.27724, 50.08485],
              [8.27667, 50.08509],
              [8.27653, 50.08513],
              [8.275, 50.08543],
              [8.2737, 50.08527],
              [8.27217, 50.08504],
              [8.27178, 50.08499],
              [8.27015, 50.08475],
              [8.26798, 50.08459],
              [8.26664, 50.08449],
              [8.26591, 50.08444],
              [8.26533, 50.08438],
              [8.26405, 50.08408],
              [8.26261, 50.08372],
              [8.26159, 50.08346],
              [8.26007, 50.08306],
              [8.25848, 50.08273],
              [8.25562, 50.08218],
              [8.25536, 50.08215],
              [8.25421, 50.0819],
              [8.25398, 50.08184],
              [8.25238, 50.08142],
              [8.25159, 50.08121],
              [8.25069, 50.08098],
              [8.250337, 50.080887],
              [8.24982, 50.08075],
              [8.2496, 50.08069],
              [8.24875, 50.08047],
              [8.24787, 50.08022],
              [8.24759, 50.08014],
              [8.24687, 50.07991],
              [8.24576, 50.08049],
              [8.24493, 50.08066],
              [8.24371, 50.0806],
              [8.24282, 50.08054],
              [8.2422, 50.0805],
              [8.24206, 50.08049],
              [8.24188, 50.08048],
              [8.23954, 50.08035],
              [8.23934, 50.08034],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.2287, 50.07815],
              [8.22777, 50.07806],
              [8.22656, 50.07796],
              [8.22585, 50.0779],
              [8.22386, 50.0777],
              [8.2223, 50.07756],
              [8.22216, 50.07755],
              [8.22071, 50.07739],
              [8.216627, 50.077025],
              [8.21606, 50.07697],
              [8.21431, 50.07681],
              [8.20963, 50.07636],
              [8.20857, 50.07628],
              [8.20769, 50.07623],
              [8.20714, 50.07614],
              [8.20642, 50.07585],
              [8.20533, 50.07497],
              [8.20449, 50.0744],
              [8.20404, 50.07415],
              [8.20347, 50.07389],
              [8.20212, 50.07347],
              [8.19783, 50.07291],
              [8.19738, 50.07281],
              [8.19599, 50.07232],
              [8.195625, 50.072106],
              [8.19536, 50.07195],
              [8.1955, 50.07192],
              [8.19662, 50.07079],
              [8.19792, 50.06946],
              [8.19838, 50.06896],
              [8.19912, 50.067708],
              [8.19913, 50.06769],
              [8.19887, 50.06554],
              [8.19904, 50.06445],
              [8.199088, 50.063994],
              [8.19914, 50.06349],
              [8.19918, 50.0615],
              [8.19908, 50.05997],
              [8.199045, 50.05947],
              [8.19892, 50.05771],
              [8.19867, 50.05689],
              [8.198462, 50.056147],
              [8.198118, 50.054797],
              [8.19726, 50.05481],
              [8.19591, 50.05475],
              [8.19561, 50.0533],
              [8.19547, 50.05275],
              [8.19542, 50.0526],
              [8.19525, 50.05193],
              [8.19315, 50.05152],
              [8.1916, 50.05108],
              [8.1918, 50.05054],
              [8.19211, 50.04985],
              [8.19236, 50.04939],
              [8.19268, 50.04877],
              [8.19352, 50.04889],
              [8.19413, 50.04896],
              [8.19588, 50.04921],
              [8.19687, 50.04936],
              [8.197625, 50.049466],
              [8.20066, 50.04989],
              [8.20181, 50.05022],
              [8.20407, 50.05038],
              [8.20429, 50.05032],
              [8.2029, 50.04876],
              [8.20258, 50.04839],
              [8.20195, 50.04766],
              [8.20113, 50.04675],
              [8.20083, 50.04642],
              [8.20039, 50.04593],
              [8.1996, 50.04504],
              [8.19938, 50.0446],
              [8.20059, 50.04463],
              [8.20151, 50.04462],
              [8.20189, 50.04456],
              [8.2027, 50.0444],
              [8.20368, 50.04418],
              [8.20476, 50.04394],
              [8.208261, 50.043171],
              [8.20931, 50.04294],
              [8.20986, 50.04283],
              [8.21018, 50.04276],
              [8.21257, 50.04221],
              [8.21293, 50.04213],
              [8.21347, 50.042],
              [8.21633, 50.04137],
              [8.21648, 50.04191],
              [8.21679, 50.04284],
              [8.21701, 50.04295],
              [8.21822, 50.04289],
              [8.22134, 50.04273],
              [8.22197, 50.04382],
              [8.2202, 50.04424],
              [8.22044, 50.04471],
              [8.22056, 50.04537],
              [8.220429, 50.046125],
              [8.220218, 50.04734],
              [8.214267, 50.046989],
              [8.214384, 50.045994],
            ],
          ],
        },
        id: "g5NjY",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.SPRINTER,
    id: "AyMzc",
    name: "44 Erbenheim <> Oberwalluf",
    lineName: "44",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.14294, 50.03951],
              [8.14245, 50.03981],
              [8.14245, 50.04025],
              [8.14246, 50.04073],
              [8.14254, 50.04109],
              [8.14261, 50.04124],
              [8.14239, 50.04161],
              [8.14218, 50.0419],
              [8.14206, 50.04234],
              [8.1423, 50.04274],
              [8.14272, 50.04257],
              [8.14333, 50.04232],
              [8.14416, 50.04202],
              [8.14436, 50.04195],
              [8.14691, 50.04092],
              [8.14827, 50.03989],
              [8.15077, 50.03858],
              [8.151198, 50.038421],
              [8.15179, 50.0382],
              [8.15224, 50.03803],
              [8.15274, 50.03778],
              [8.15341, 50.03744],
              [8.15522, 50.03672],
              [8.15605, 50.0365],
              [8.15616, 50.03688],
              [8.15604, 50.03724],
              [8.15588, 50.03743],
              [8.15551, 50.03765],
              [8.15482, 50.03793],
              [8.15438, 50.03817],
              [8.15427, 50.0383],
              [8.15417, 50.03866],
              [8.15445, 50.03906],
              [8.15469, 50.03923],
              [8.15559, 50.03969],
              [8.15656, 50.04025],
              [8.1569, 50.04046],
              [8.1572, 50.04068],
              [8.1575, 50.0409],
              [8.1577, 50.04105],
              [8.15809, 50.04139],
              [8.15901, 50.0424],
              [8.15927, 50.04281],
              [8.15955, 50.04346],
              [8.15972, 50.04353],
              [8.15962, 50.04367],
              [8.15988, 50.04441],
              [8.16079, 50.0444],
              [8.16201, 50.04489],
              [8.16209, 50.04489],
              [8.16216, 50.04489],
              [8.16274, 50.04443],
              [8.16404, 50.04322],
              [8.16413, 50.04316],
              [8.16443, 50.04298],
              [8.16524, 50.04282],
              [8.16583, 50.04277],
              [8.16672, 50.04256],
              [8.1669, 50.04263],
              [8.1671, 50.04252],
              [8.16922, 50.0431],
              [8.16942, 50.0431],
              [8.17296, 50.04384],
              [8.17365, 50.04395],
              [8.17526, 50.04412],
              [8.1763, 50.04418],
              [8.1763, 50.04424],
              [8.17636, 50.0445],
              [8.17626, 50.04482],
              [8.17617, 50.04533],
              [8.17476, 50.04783],
              [8.17433, 50.04855],
              [8.17415, 50.04884],
              [8.17402, 50.04906],
              [8.17323, 50.05029],
              [8.17231, 50.05117],
              [8.1697, 50.05269],
              [8.16696, 50.05477],
              [8.16512, 50.0557],
              [8.16247, 50.0565],
              [8.16244, 50.0566],
              [8.16236, 50.05661],
              [8.16244, 50.05698],
              [8.16261, 50.05768],
              [8.1626, 50.05821],
              [8.1624, 50.05858],
              [8.16183, 50.05894],
              [8.16028, 50.05935],
              [8.15987, 50.05943],
              [8.15777, 50.05989],
              [8.15723, 50.06017],
              [8.15712, 50.06024],
              [8.15515, 50.06151],
              [8.15434, 50.06229],
              [8.15411, 50.06261],
              [8.15402, 50.06294],
              [8.15402, 50.06307],
              [8.15411, 50.06392],
              [8.15417, 50.06455],
              [8.15386, 50.06505],
              [8.15388, 50.06532],
              [8.15408, 50.06557],
              [8.15453, 50.06572],
              [8.15738, 50.066306],
              [8.15825, 50.06645],
              [8.15848, 50.06644],
              [8.16125, 50.06678],
              [8.16266, 50.06678],
              [8.16449, 50.06646],
              [8.16487, 50.0665],
              [8.16789, 50.06706],
              [8.1693, 50.06744],
              [8.17084, 50.0683],
              [8.17319, 50.06968],
              [8.17345, 50.06978],
              [8.17438, 50.06994],
              [8.17659, 50.07014],
              [8.17754, 50.0702],
              [8.17828, 50.07011],
              [8.17885, 50.06982],
              [8.18076, 50.06799],
              [8.18141, 50.06767],
              [8.18163, 50.06762],
              [8.182356, 50.06749],
              [8.1833, 50.06732],
              [8.18517, 50.06713],
              [8.187924, 50.067341],
              [8.18882, 50.06741],
              [8.18923, 50.06744],
              [8.18976, 50.06749],
              [8.19142, 50.06766],
              [8.19292, 50.06784],
              [8.19365, 50.06797],
              [8.19452, 50.06836],
              [8.1947, 50.06853],
              [8.19503, 50.06914],
              [8.19502, 50.06942],
              [8.19473, 50.0706],
              [8.19515, 50.0716],
              [8.19541, 50.07186],
              [8.1955, 50.07192],
              [8.195665, 50.072002],
              [8.19664, 50.07249],
              [8.19819, 50.07288],
              [8.20193, 50.07335],
              [8.20351, 50.07381],
              [8.20409, 50.07408],
              [8.20527, 50.07478],
              [8.20655, 50.07578],
              [8.20735, 50.07608],
              [8.20778, 50.07614],
              [8.20857, 50.07628],
              [8.20963, 50.07636],
              [8.21431, 50.07681],
              [8.21606, 50.07697],
              [8.216627, 50.077025],
              [8.22071, 50.07739],
              [8.22216, 50.07755],
              [8.2223, 50.07756],
              [8.22386, 50.0777],
              [8.22585, 50.0779],
              [8.22656, 50.07796],
              [8.22777, 50.07806],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23658, 50.07894],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.244535, 50.079334],
              [8.24507, 50.07936],
              [8.24587, 50.07943],
              [8.24632, 50.07958],
              [8.24687, 50.07991],
              [8.24755, 50.07937],
              [8.24768, 50.07928],
              [8.248549, 50.07863],
              [8.24899, 50.0783],
              [8.24915, 50.07819],
              [8.24944, 50.07797],
              [8.24968, 50.07779],
              [8.25015, 50.07744],
              [8.25152, 50.07643],
              [8.25176, 50.07625],
              [8.25278, 50.07548],
              [8.25318, 50.0752],
              [8.25374, 50.07475],
              [8.2541, 50.07439],
              [8.25505, 50.07369],
              [8.25569, 50.07325],
              [8.25606, 50.07299],
              [8.25671, 50.07253],
              [8.25699, 50.07233],
              [8.25709, 50.07238],
              [8.25737, 50.07252],
              [8.25756, 50.07225],
              [8.25732, 50.0721],
              [8.25749, 50.07199],
              [8.25808, 50.07153],
              [8.26015, 50.06999],
              [8.26098, 50.06939],
              [8.26144, 50.06909],
              [8.2622, 50.06868],
              [8.26225, 50.06859],
              [8.263269, 50.068021],
              [8.26354, 50.06787],
              [8.263861, 50.068304],
              [8.26439, 50.06814],
              [8.2684, 50.0667],
              [8.26938, 50.06653],
              [8.2711, 50.06648],
              [8.27289, 50.06612],
              [8.27424, 50.06578],
              [8.27548, 50.06552],
              [8.27541, 50.06543],
              [8.27528, 50.06527],
              [8.27472, 50.06458],
              [8.27379, 50.06344],
              [8.27276, 50.06382],
              [8.27253, 50.06417],
              [8.27264, 50.06436],
              [8.27298, 50.06446],
              [8.27346, 50.06446],
              [8.27435, 50.06412],
              [8.27631, 50.06341],
              [8.27966, 50.06219],
              [8.28254, 50.0609],
              [8.28415, 50.0598],
              [8.2846, 50.05906],
              [8.2845, 50.05821],
              [8.28451, 50.05771],
              [8.28482, 50.05726],
              [8.28489, 50.05718],
              [8.28563, 50.05675],
              [8.28659, 50.05657],
              [8.28677, 50.05657],
              [8.28747, 50.05666],
              [8.28856, 50.05714],
              [8.285459, 50.055433],
              [8.281085, 50.053025],
            ],
          ],
        },
        id: "AyMzc",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.281085, 50.053025],
              [8.285459, 50.055433],
              [8.28856, 50.05714],
              [8.28958, 50.05807],
              [8.28992, 50.05843],
              [8.288623, 50.058902],
              [8.28739, 50.05935],
              [8.2865, 50.05968],
              [8.28631, 50.05975],
              [8.28557, 50.06002],
              [8.28398, 50.06059],
              [8.28312, 50.06079],
              [8.28263, 50.06098],
              [8.28017, 50.06211],
              [8.27644, 50.06349],
              [8.27592, 50.06388],
              [8.27584, 50.06414],
              [8.27609, 50.06458],
              [8.27632, 50.065],
              [8.27622, 50.06508],
              [8.2759, 50.06533],
              [8.27573, 50.06537],
              [8.27541, 50.06543],
              [8.27424, 50.06578],
              [8.27289, 50.06612],
              [8.2711, 50.06648],
              [8.26938, 50.06653],
              [8.2684, 50.0667],
              [8.26439, 50.06814],
              [8.263861, 50.068304],
              [8.263, 50.06857],
              [8.26225, 50.06878],
              [8.26137, 50.06928],
              [8.26031, 50.06999],
              [8.2593, 50.07078],
              [8.25805, 50.07172],
              [8.25767, 50.07199],
              [8.25727, 50.07224],
              [8.25699, 50.07233],
              [8.25693, 50.07258],
              [8.25587, 50.07334],
              [8.25514, 50.07374],
              [8.25419, 50.07444],
              [8.25374, 50.07475],
              [8.25318, 50.0752],
              [8.25278, 50.07548],
              [8.25176, 50.07625],
              [8.25152, 50.07643],
              [8.25015, 50.07744],
              [8.24968, 50.07779],
              [8.24944, 50.07797],
              [8.24915, 50.07819],
              [8.24899, 50.0783],
              [8.248549, 50.07863],
              [8.24768, 50.07928],
              [8.24755, 50.07937],
              [8.24687, 50.07991],
              [8.24576, 50.08049],
              [8.24493, 50.08066],
              [8.24371, 50.0806],
              [8.24282, 50.08054],
              [8.23934, 50.08034],
              [8.23643, 50.08018],
              [8.23639, 50.08043],
              [8.23634, 50.08078],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.2287, 50.07815],
              [8.22777, 50.07806],
              [8.22656, 50.07796],
              [8.22585, 50.0779],
              [8.22386, 50.0777],
              [8.2223, 50.07756],
              [8.22216, 50.07755],
              [8.22071, 50.07739],
              [8.216627, 50.077025],
              [8.21606, 50.07697],
              [8.21431, 50.07681],
              [8.20963, 50.07636],
              [8.20857, 50.07628],
              [8.20778, 50.07614],
              [8.20735, 50.07608],
              [8.20655, 50.07578],
              [8.20527, 50.07478],
              [8.20409, 50.07408],
              [8.20351, 50.07381],
              [8.20193, 50.07335],
              [8.19819, 50.07288],
              [8.19664, 50.07249],
              [8.195665, 50.072002],
              [8.1955, 50.07192],
              [8.19541, 50.07186],
              [8.19515, 50.0716],
              [8.19473, 50.0706],
              [8.19502, 50.06942],
              [8.19503, 50.06914],
              [8.1947, 50.06853],
              [8.19452, 50.06836],
              [8.19365, 50.06797],
              [8.19292, 50.06784],
              [8.19142, 50.06766],
              [8.18976, 50.06749],
              [8.18923, 50.06744],
              [8.18882, 50.06741],
              [8.187924, 50.067341],
              [8.18517, 50.06713],
              [8.1833, 50.06732],
              [8.182356, 50.06749],
              [8.18163, 50.06762],
              [8.18141, 50.06767],
              [8.18076, 50.06799],
              [8.17885, 50.06982],
              [8.17828, 50.07011],
              [8.17754, 50.0702],
              [8.17659, 50.07014],
              [8.17438, 50.06994],
              [8.17345, 50.06978],
              [8.17319, 50.06968],
              [8.17084, 50.0683],
              [8.1693, 50.06744],
              [8.16789, 50.06706],
              [8.16487, 50.0665],
              [8.16449, 50.06646],
              [8.16266, 50.06678],
              [8.16125, 50.06678],
              [8.15848, 50.06644],
              [8.15825, 50.06645],
              [8.15738, 50.066306],
              [8.15453, 50.06572],
              [8.15408, 50.06557],
              [8.15388, 50.06532],
              [8.15386, 50.06505],
              [8.15417, 50.06455],
              [8.15411, 50.06392],
              [8.15402, 50.06307],
              [8.15402, 50.06294],
              [8.15411, 50.06261],
              [8.15434, 50.06229],
              [8.15515, 50.06151],
              [8.15712, 50.06024],
              [8.15723, 50.06017],
              [8.15777, 50.05989],
              [8.15987, 50.05943],
              [8.16028, 50.05935],
              [8.16183, 50.05894],
              [8.1624, 50.05858],
              [8.1626, 50.05821],
              [8.16261, 50.05768],
              [8.16244, 50.05698],
              [8.16236, 50.05661],
              [8.16244, 50.0566],
              [8.16247, 50.0565],
              [8.16512, 50.0557],
              [8.16696, 50.05477],
              [8.1697, 50.05269],
              [8.17231, 50.05117],
              [8.17323, 50.05029],
              [8.17402, 50.04906],
              [8.17415, 50.04884],
              [8.17433, 50.04855],
              [8.17476, 50.04783],
              [8.17617, 50.04533],
              [8.17626, 50.04482],
              [8.17636, 50.0445],
              [8.1763, 50.04424],
              [8.1763, 50.04418],
              [8.17526, 50.04412],
              [8.17365, 50.04395],
              [8.17296, 50.04384],
              [8.16942, 50.0431],
              [8.16922, 50.0431],
              [8.1671, 50.04252],
              [8.1669, 50.04263],
              [8.16672, 50.04256],
              [8.16583, 50.04277],
              [8.16524, 50.04282],
              [8.16443, 50.04298],
              [8.16413, 50.04316],
              [8.16404, 50.04322],
              [8.16274, 50.04443],
              [8.16216, 50.04489],
              [8.16209, 50.04489],
              [8.16201, 50.04489],
              [8.16079, 50.0444],
              [8.15988, 50.04441],
              [8.15962, 50.04367],
              [8.15972, 50.04353],
              [8.15955, 50.04346],
              [8.15927, 50.04281],
              [8.15901, 50.0424],
              [8.15809, 50.04139],
              [8.1577, 50.04105],
              [8.1575, 50.0409],
              [8.1572, 50.04068],
              [8.1569, 50.04046],
              [8.15656, 50.04025],
              [8.15559, 50.03969],
              [8.15469, 50.03923],
              [8.15445, 50.03906],
              [8.15417, 50.03866],
              [8.15427, 50.0383],
              [8.15438, 50.03817],
              [8.15482, 50.03793],
              [8.15551, 50.03765],
              [8.15588, 50.03743],
              [8.15604, 50.03724],
              [8.15616, 50.03688],
              [8.15605, 50.0365],
              [8.15522, 50.03672],
              [8.15341, 50.03744],
              [8.15274, 50.03778],
              [8.15224, 50.03803],
              [8.15179, 50.0382],
              [8.151198, 50.038421],
              [8.15077, 50.03858],
              [8.14827, 50.03989],
              [8.14691, 50.04092],
              [8.14436, 50.04195],
              [8.14416, 50.04202],
              [8.14333, 50.04232],
              [8.14272, 50.04257],
              [8.1423, 50.04274],
              [8.14206, 50.04234],
              [8.14218, 50.0419],
              [8.14239, 50.04161],
              [8.14261, 50.04124],
              [8.14254, 50.04109],
              [8.14246, 50.04073],
              [8.14245, 50.04025],
              [8.14245, 50.03981],
              [8.14294, 50.03951],
            ],
          ],
        },
        id: "Q2MDM",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.SPRINTER,
    id: "U3Mjk",
    name: "43 Mainz_Kostheim <> Sonnenberg",
    lineName: "43",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.26442, 50.10102],
              [8.26432, 50.10045],
              [8.26416, 50.09982],
              [8.26408, 50.09971],
              [8.26334, 50.09927],
              [8.26227, 50.09874],
              [8.26133, 50.09826],
              [8.26034, 50.09719],
              [8.25971, 50.09657],
              [8.25916, 50.09604],
              [8.25754, 50.09447],
              [8.25743, 50.09431],
              [8.25723, 50.09354],
              [8.25714, 50.09315],
              [8.25702, 50.09211],
              [8.25709, 50.09135],
              [8.257247, 50.090718],
              [8.25757, 50.08942],
              [8.25749, 50.08891],
              [8.2572, 50.08787],
              [8.25715, 50.08771],
              [8.25677, 50.08674],
              [8.25609, 50.0862],
              [8.25541, 50.08593],
              [8.25322, 50.08547],
              [8.25293, 50.08542],
              [8.252203, 50.085318],
              [8.25172, 50.08525],
              [8.25151, 50.08524],
              [8.24809, 50.08553],
              [8.24693, 50.08567],
              [8.24665, 50.08571],
              [8.24526, 50.08592],
              [8.24439, 50.08601],
              [8.24437, 50.0857],
              [8.24439, 50.08537],
              [8.24444, 50.08499],
              [8.24446, 50.08483],
              [8.24451, 50.08446],
              [8.24452, 50.08436],
              [8.24453, 50.0843],
              [8.24461, 50.08374],
              [8.24463, 50.08332],
              [8.24466, 50.08299],
              [8.2447, 50.0827],
              [8.24486, 50.08134],
              [8.244894, 50.081014],
              [8.24493, 50.08066],
              [8.24371, 50.0806],
              [8.24282, 50.08054],
              [8.2422, 50.0805],
              [8.24206, 50.08049],
              [8.2421, 50.07999],
              [8.24221, 50.07922],
              [8.24239, 50.07799],
              [8.24243, 50.07777],
              [8.2425, 50.07769],
              [8.242575, 50.076958],
              [8.24265, 50.07623],
              [8.24281, 50.0744],
              [8.24288, 50.0739],
              [8.24298, 50.07321],
              [8.24313, 50.07218],
              [8.24328, 50.0717],
              [8.243761, 50.071537],
              [8.24309, 50.0715],
              [8.242, 50.07143],
              [8.24134, 50.07139],
              [8.2407, 50.07136],
              [8.24038, 50.07135],
              [8.24031, 50.07117],
              [8.23998, 50.0702],
              [8.23998, 50.06971],
              [8.24003, 50.06949],
              [8.2402, 50.06926],
              [8.2405, 50.06799],
              [8.24075, 50.06702],
              [8.24136, 50.06466],
              [8.24164, 50.06356],
              [8.24176, 50.06298],
              [8.24194, 50.06229],
              [8.24212, 50.06155],
              [8.24221, 50.06109],
              [8.24232, 50.0607],
              [8.2424, 50.06045],
              [8.24289, 50.05855],
              [8.24305, 50.05794],
              [8.24313, 50.05762],
              [8.24316, 50.05749],
              [8.24333, 50.05684],
              [8.24338, 50.05663],
              [8.24342, 50.05647],
              [8.24364, 50.05555],
              [8.24365, 50.05536],
              [8.24365, 50.0552],
              [8.24361, 50.05504],
              [8.24355, 50.05487],
              [8.24344, 50.05459],
              [8.2434, 50.05448],
              [8.24324, 50.05407],
              [8.24308, 50.0538],
              [8.24298, 50.05359],
              [8.24293, 50.05349],
              [8.24267, 50.05281],
              [8.24235, 50.05199],
              [8.24224, 50.05171],
              [8.2422, 50.05161],
              [8.24204, 50.05124],
              [8.24183, 50.050709],
              [8.24164, 50.05023],
              [8.24127, 50.04932],
              [8.24115, 50.04901],
              [8.24104, 50.04875],
              [8.24097, 50.04827],
              [8.24095, 50.04785],
              [8.24109, 50.04783],
              [8.24205, 50.04763],
              [8.24279, 50.04747],
              [8.24386, 50.04715],
              [8.24407, 50.04708],
              [8.24461, 50.04688],
              [8.24996, 50.04432],
              [8.25133, 50.04354],
              [8.25248, 50.0427],
              [8.25476, 50.04112],
              [8.25531, 50.04075],
              [8.25593, 50.04032],
              [8.25657, 50.03983],
              [8.2572, 50.03896],
              [8.25775, 50.03816],
              [8.2582, 50.0375],
              [8.25886, 50.03653],
              [8.25916, 50.03609],
              [8.25961, 50.03542],
              [8.26022, 50.03453],
              [8.26067, 50.03387],
              [8.26101, 50.03338],
              [8.26239, 50.03135],
              [8.26247, 50.03124],
              [8.26353, 50.0297],
              [8.26444, 50.02836],
              [8.26456, 50.02819],
              [8.26467, 50.02804],
              [8.26525, 50.02731],
              [8.26533, 50.02721],
              [8.26548, 50.02702],
              [8.26591, 50.02651],
              [8.26611, 50.02627],
              [8.26643, 50.02593],
              [8.26691, 50.02538],
              [8.268, 50.02409],
              [8.26844, 50.02413],
              [8.26916, 50.02425],
              [8.27153, 50.02453],
              [8.27336, 50.02455],
              [8.27389, 50.02452],
              [8.27642, 50.02415],
              [8.2778, 50.02381],
              [8.28055, 50.02282],
              [8.28414, 50.02131],
              [8.28529, 50.02081],
              [8.28783, 50.01969],
              [8.28901, 50.01915],
              [8.28953, 50.0189],
              [8.2916, 50.01778],
              [8.2932, 50.01678],
              [8.29384, 50.01634],
              [8.29425, 50.01604],
              [8.29486, 50.01635],
              [8.29536, 50.01663],
              [8.29556, 50.01674],
              [8.29569, 50.01716],
              [8.2958, 50.0173],
              [8.29666, 50.01777],
              [8.29658, 50.01791],
              [8.2968, 50.01798],
              [8.297286, 50.018265],
              [8.29767, 50.01849],
              [8.29847, 50.01892],
              [8.29976, 50.01959],
              [8.3003, 50.01917],
              [8.3005, 50.01888],
              [8.30165, 50.01783],
              [8.30167, 50.01756],
              [8.30258, 50.01674],
              [8.30252, 50.01653],
              [8.30052, 50.01553],
              [8.3008, 50.01521],
              [8.30027, 50.015],
              [8.2999, 50.01486],
              [8.29926, 50.0146],
              [8.29878, 50.01442],
              [8.297, 50.01372],
              [8.29739, 50.01328],
              [8.29828, 50.01166],
              [8.298394, 50.011419],
              [8.299618, 50.008925],
              [8.30094, 50.00662],
              [8.30098, 50.00653],
              [8.30115, 50.0061],
              [8.30123, 50.00594],
              [8.30153, 50.00525],
              [8.3016, 50.00501],
              [8.30167, 50.00444],
              [8.30161, 50.00385],
              [8.30125, 50.00303],
              [8.30107, 50.00275],
              [8.3006, 50.00198],
              [8.30042, 50.00167],
              [8.30019, 50.00134],
              [8.30103, 50.00108],
              [8.30151, 50.00095],
              [8.30294, 50.00049],
              [8.30329, 50.0004],
              [8.30381, 50.00026],
              [8.30465, 50.00015],
              [8.30482, 50.00015],
              [8.30562, 50.00016],
              [8.30584, 50.00018],
              [8.30583, 50.00049],
              [8.30582, 50.00087],
              [8.30583, 50.00139],
              [8.30582, 50.0021],
            ],
          ],
        },
        id: "U3Mjk",
        direction: "OUTBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.30582, 50.0021],
              [8.30583, 50.00247],
              [8.30582, 50.00279],
              [8.30582, 50.00348],
              [8.30474, 50.00348],
              [8.30371, 50.00345],
              [8.30263, 50.00343],
              [8.30235, 50.0028],
              [8.30212, 50.00224],
              [8.30189, 50.00174],
              [8.30169, 50.00125],
              [8.30151, 50.00095],
              [8.30103, 50.00108],
              [8.30019, 50.00134],
              [8.30042, 50.00167],
              [8.3006, 50.00198],
              [8.30107, 50.00275],
              [8.30125, 50.00303],
              [8.30161, 50.00385],
              [8.30167, 50.00444],
              [8.3016, 50.00501],
              [8.30153, 50.00525],
              [8.30123, 50.00594],
              [8.30115, 50.0061],
              [8.30098, 50.00653],
              [8.30094, 50.00662],
              [8.299618, 50.008925],
              [8.298394, 50.011419],
              [8.29828, 50.01166],
              [8.29739, 50.01328],
              [8.297, 50.01372],
              [8.29878, 50.01442],
              [8.29926, 50.0146],
              [8.2999, 50.01486],
              [8.30027, 50.015],
              [8.3008, 50.01521],
              [8.30052, 50.01553],
              [8.30252, 50.01653],
              [8.30258, 50.01674],
              [8.30167, 50.01756],
              [8.30165, 50.01783],
              [8.3005, 50.01888],
              [8.3003, 50.01917],
              [8.29976, 50.01959],
              [8.29847, 50.01892],
              [8.29767, 50.01849],
              [8.297286, 50.018265],
              [8.2968, 50.01798],
              [8.29658, 50.01791],
              [8.29666, 50.01777],
              [8.2958, 50.0173],
              [8.29569, 50.01716],
              [8.29556, 50.01674],
              [8.29536, 50.01663],
              [8.29486, 50.01635],
              [8.29425, 50.01604],
              [8.29384, 50.01634],
              [8.2932, 50.01678],
              [8.2916, 50.01778],
              [8.28953, 50.0189],
              [8.28901, 50.01915],
              [8.28783, 50.01969],
              [8.28529, 50.02081],
              [8.28414, 50.02131],
              [8.28055, 50.02282],
              [8.2778, 50.02381],
              [8.27642, 50.02415],
              [8.27389, 50.02452],
              [8.27336, 50.02455],
              [8.27153, 50.02453],
              [8.26916, 50.02425],
              [8.26844, 50.02413],
              [8.268, 50.02409],
              [8.26691, 50.02538],
              [8.26643, 50.02593],
              [8.26611, 50.02627],
              [8.26591, 50.02651],
              [8.26548, 50.02702],
              [8.26533, 50.02721],
              [8.26525, 50.02731],
              [8.26467, 50.02804],
              [8.26456, 50.02819],
              [8.26444, 50.02836],
              [8.26353, 50.0297],
              [8.26247, 50.03124],
              [8.26239, 50.03135],
              [8.26101, 50.03338],
              [8.26067, 50.03387],
              [8.26022, 50.03453],
              [8.25961, 50.03542],
              [8.25916, 50.03609],
              [8.25886, 50.03653],
              [8.2582, 50.0375],
              [8.25775, 50.03816],
              [8.2572, 50.03896],
              [8.25657, 50.03983],
              [8.25593, 50.04032],
              [8.25531, 50.04075],
              [8.25476, 50.04112],
              [8.25248, 50.0427],
              [8.25133, 50.04354],
              [8.24996, 50.04432],
              [8.24461, 50.04688],
              [8.24407, 50.04708],
              [8.24386, 50.04715],
              [8.24279, 50.04747],
              [8.24205, 50.04763],
              [8.24109, 50.04783],
              [8.2411, 50.04823],
              [8.24126, 50.04884],
              [8.24131, 50.04897],
              [8.24146, 50.04929],
              [8.24179, 50.05015],
              [8.241973, 50.050675],
              [8.24216, 50.05121],
              [8.24235, 50.05159],
              [8.24238, 50.05169],
              [8.24248, 50.05195],
              [8.24251, 50.05202],
              [8.24268, 50.05244],
              [8.24284, 50.05285],
              [8.24297, 50.05316],
              [8.243, 50.05324],
              [8.2432, 50.05378],
              [8.24324, 50.05407],
              [8.24346, 50.05419],
              [8.24358, 50.05444],
              [8.24366, 50.05462],
              [8.24373, 50.05481],
              [8.24381, 50.05502],
              [8.24365, 50.0552],
              [8.24365, 50.05536],
              [8.24364, 50.05555],
              [8.24342, 50.05647],
              [8.24338, 50.05663],
              [8.24333, 50.05684],
              [8.24316, 50.05749],
              [8.24313, 50.05762],
              [8.24305, 50.05794],
              [8.24289, 50.05855],
              [8.2424, 50.06045],
              [8.24232, 50.0607],
              [8.24221, 50.06109],
              [8.24212, 50.06155],
              [8.24194, 50.06229],
              [8.24176, 50.06298],
              [8.24164, 50.06356],
              [8.24136, 50.06466],
              [8.24075, 50.06702],
              [8.2405, 50.06799],
              [8.2402, 50.06926],
              [8.24024, 50.06949],
              [8.24024, 50.06986],
              [8.24031, 50.07077],
              [8.24048, 50.07119],
              [8.2407, 50.07136],
              [8.24134, 50.07139],
              [8.242, 50.07143],
              [8.24309, 50.0715],
              [8.243761, 50.071537],
              [8.24328, 50.0717],
              [8.24313, 50.07218],
              [8.24298, 50.07321],
              [8.24288, 50.0739],
              [8.24281, 50.0744],
              [8.24265, 50.07623],
              [8.242575, 50.076958],
              [8.2425, 50.07769],
              [8.24243, 50.07777],
              [8.24239, 50.07799],
              [8.24221, 50.07922],
              [8.244535, 50.079334],
              [8.24507, 50.07936],
              [8.24493, 50.08066],
              [8.244894, 50.081014],
              [8.24486, 50.08134],
              [8.2447, 50.0827],
              [8.24466, 50.08299],
              [8.24463, 50.08332],
              [8.24461, 50.08374],
              [8.24453, 50.0843],
              [8.24452, 50.08436],
              [8.24451, 50.08446],
              [8.24446, 50.08483],
              [8.24444, 50.08499],
              [8.24439, 50.08537],
              [8.24437, 50.0857],
              [8.24439, 50.08601],
              [8.24526, 50.08592],
              [8.24665, 50.08571],
              [8.24693, 50.08567],
              [8.24809, 50.08553],
              [8.25151, 50.08524],
              [8.25172, 50.08525],
              [8.252203, 50.085318],
              [8.25293, 50.08542],
              [8.25322, 50.08547],
              [8.25541, 50.08593],
              [8.25609, 50.0862],
              [8.25541, 50.08593],
              [8.25322, 50.08547],
              [8.25541, 50.08593],
              [8.25609, 50.0862],
              [8.25677, 50.08674],
              [8.25715, 50.08771],
              [8.2572, 50.08787],
              [8.25749, 50.08891],
              [8.25757, 50.08942],
              [8.257247, 50.090718],
              [8.25709, 50.09135],
              [8.25702, 50.09211],
              [8.25714, 50.09315],
              [8.25723, 50.09354],
              [8.25743, 50.09431],
              [8.25754, 50.09447],
              [8.25916, 50.09604],
              [8.25971, 50.09657],
              [8.26034, 50.09719],
              [8.26133, 50.09826],
              [8.26227, 50.09874],
              [8.26334, 50.09927],
              [8.26408, 50.09971],
              [8.26416, 50.09982],
              [8.26432, 50.10045],
              [8.26442, 50.10102],
            ],
          ],
        },
        id: "cwNDU",
        direction: "INBOUND",
      },
    ],
  },
  {
    category: BusCategory.SPRINTER,
    id: "Y0MTI",
    name: "41 Medenbach <> Gräfelberg",
    lineName: "41",
    routes: [
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.22468, 50.05589],
              [8.22377, 50.05594],
              [8.22341, 50.05611],
              [8.22299, 50.05644],
              [8.22215, 50.056],
              [8.22093, 50.05589],
              [8.22011, 50.05586],
              [8.22009, 50.05602],
              [8.220123, 50.05626],
              [8.218624, 50.057901],
              [8.2186, 50.05792],
              [8.21835, 50.05845],
              [8.21845, 50.059],
              [8.21858, 50.05917],
              [8.21877, 50.0594],
              [8.22033, 50.06043],
              [8.219797, 50.060709],
              [8.2192, 50.06102],
              [8.2185, 50.06139],
              [8.21756, 50.06191],
              [8.21739, 50.06202],
              [8.21708, 50.06222],
              [8.21733, 50.06243],
              [8.2178, 50.06282],
              [8.21794, 50.06294],
              [8.21998, 50.0649],
              [8.22048, 50.06548],
              [8.221, 50.06593],
              [8.221162, 50.066064],
              [8.22369, 50.06782],
              [8.22609, 50.06969],
              [8.22741, 50.07061],
              [8.22833, 50.07146],
              [8.22921, 50.07227],
              [8.22984, 50.07283],
              [8.22994, 50.07291],
              [8.2301, 50.07306],
              [8.23071, 50.07357],
              [8.23153, 50.07426],
              [8.23172, 50.07444],
              [8.2316, 50.07454],
              [8.23112, 50.07506],
              [8.23077, 50.07557],
              [8.23074, 50.07561],
              [8.23036, 50.07637],
              [8.23019, 50.07716],
              [8.23015, 50.07735],
              [8.22997, 50.07829],
              [8.230908, 50.07837],
              [8.23162, 50.07843],
              [8.23247, 50.0785],
              [8.23335, 50.07858],
              [8.23502, 50.07874],
              [8.23639, 50.07893],
              [8.23929, 50.07905],
              [8.24001, 50.07908],
              [8.24079, 50.07912],
              [8.24221, 50.07922],
              [8.244535, 50.079334],
              [8.24507, 50.07936],
              [8.24587, 50.07943],
              [8.24632, 50.07958],
              [8.24687, 50.07991],
              [8.24759, 50.08014],
              [8.24787, 50.08022],
              [8.24875, 50.08047],
              [8.2496, 50.08069],
              [8.24982, 50.08075],
              [8.250337, 50.080887],
              [8.25069, 50.08098],
              [8.25159, 50.08121],
              [8.25238, 50.08142],
              [8.25398, 50.08184],
              [8.25421, 50.0819],
              [8.25536, 50.08215],
              [8.25562, 50.08218],
              [8.25848, 50.08273],
              [8.26007, 50.08306],
              [8.26159, 50.08346],
              [8.26261, 50.08372],
              [8.26405, 50.08408],
              [8.26533, 50.08438],
              [8.26591, 50.08444],
              [8.26664, 50.08449],
              [8.26798, 50.08459],
              [8.27015, 50.08475],
              [8.27178, 50.08499],
              [8.27217, 50.08504],
              [8.2737, 50.08527],
              [8.275, 50.08543],
              [8.27653, 50.08513],
              [8.27743, 50.0865],
              [8.27784, 50.08702],
              [8.27921, 50.08932],
              [8.27969, 50.09043],
              [8.27976, 50.09069],
              [8.27978, 50.09088],
              [8.27979, 50.09108],
              [8.27979, 50.09151],
              [8.27973, 50.09185],
              [8.279451, 50.092612],
              [8.27933, 50.09294],
              [8.2784, 50.09494],
              [8.27833, 50.09546],
              [8.27836, 50.09611],
              [8.27838, 50.09677],
              [8.27839, 50.09706],
              [8.27846, 50.09787],
              [8.27846, 50.09848],
              [8.27854, 50.10032],
              [8.27859, 50.10164],
              [8.27831, 50.10375],
              [8.27833, 50.104436],
              [8.27837, 50.1058],
              [8.27861, 50.10672],
              [8.27913, 50.10765],
              [8.27954, 50.10818],
              [8.28011, 50.10881],
              [8.28112, 50.10962],
              [8.28153, 50.110087],
              [8.28184, 50.11044],
              [8.28219, 50.11087],
              [8.28364, 50.11236],
              [8.28489, 50.11395],
              [8.28659, 50.11567],
              [8.28721, 50.11645],
              [8.2874, 50.1167],
              [8.28761, 50.11699],
              [8.28872, 50.11863],
              [8.28882, 50.11885],
              [8.28947, 50.12052],
              [8.28999, 50.12194],
              [8.2903, 50.12267],
              [8.29122, 50.12404],
              [8.2928, 50.12627],
              [8.29307, 50.12674],
              [8.29339, 50.12782],
              [8.2934, 50.1281],
              [8.29434, 50.1291],
              [8.29435, 50.12946],
              [8.29425, 50.12965],
              [8.29405, 50.13021],
              [8.29383, 50.13088],
              [8.29374, 50.13106],
              [8.29286, 50.13197],
              [8.2928, 50.1323],
              [8.29304, 50.13258],
              [8.29407, 50.13281],
              [8.29449, 50.13278],
              [8.29513, 50.13242],
              [8.29581, 50.13241],
              [8.29646, 50.13255],
              [8.29655, 50.13257],
              [8.2976, 50.13295],
              [8.297809, 50.133072],
              [8.29888, 50.1337],
              [8.29899, 50.13375],
              [8.30077, 50.13482],
              [8.30122, 50.13505],
              [8.3015, 50.13521],
              [8.30404, 50.13359],
              [8.30538, 50.13276],
              [8.30572, 50.13258],
              [8.30602, 50.13244],
              [8.3069, 50.13211],
              [8.30807, 50.13176],
              [8.30858, 50.13162],
              [8.31126, 50.13079],
              [8.3119, 50.13044],
              [8.31226, 50.13053],
              [8.31438, 50.12991],
              [8.31536, 50.12954],
              [8.31591, 50.12919],
              [8.31709, 50.12805],
              [8.31763, 50.12766],
              [8.31837, 50.12722],
              [8.31901, 50.12685],
              [8.32038, 50.12613],
              [8.32103, 50.12552],
              [8.321408, 50.125327],
              [8.32183, 50.12511],
              [8.32319, 50.1252],
              [8.32354, 50.12498],
              [8.3238, 50.12516],
              [8.32454, 50.12533],
              [8.32542, 50.12558],
              [8.3262, 50.12471],
              [8.32669, 50.12381],
              [8.32673, 50.12358],
              [8.32646, 50.123],
              [8.32596, 50.12311],
              [8.32646, 50.123],
              [8.32738, 50.12266],
              [8.32752, 50.12261],
              [8.3286, 50.12219],
              [8.32934, 50.12187],
              [8.33052, 50.12099],
              [8.33159, 50.12055],
              [8.3329, 50.12017],
              [8.33294, 50.12025],
              [8.33253, 50.12045],
              [8.33294, 50.12025],
              [8.3329, 50.12017],
              [8.33348, 50.11983],
              [8.33362, 50.11956],
              [8.33385, 50.11922],
              [8.33414, 50.11901],
              [8.33405, 50.11889],
              [8.3337, 50.11787],
              [8.33359, 50.11748],
              [8.33336, 50.11641],
              [8.33308, 50.11584],
              [8.33318, 50.11574],
              [8.33301, 50.11562],
              [8.33304, 50.1155],
              [8.3329, 50.11476],
              [8.33324, 50.11433],
              [8.33343, 50.11413],
              [8.33359, 50.11394],
              [8.3346, 50.11263],
              [8.33501, 50.11179],
              [8.33563, 50.10925],
              [8.33604, 50.1092],
              [8.33623, 50.10909],
              [8.33629, 50.1088],
              [8.33632, 50.10865],
              [8.33709, 50.10775],
              [8.33816, 50.1069],
              [8.33833, 50.10728],
              [8.33848, 50.10766],
              [8.33782, 50.10868],
              [8.33773, 50.10927],
              [8.33814, 50.11023],
              [8.33878, 50.11028],
              [8.3384, 50.1128],
              [8.337502, 50.115254],
            ],
          ],
        },
        id: "Y0MTI",
        direction: "INBOUND",
      },
      {
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [8.337502, 50.115254],
              [8.3384, 50.1128],
              [8.33878, 50.11028],
              [8.33814, 50.11023],
              [8.33773, 50.10927],
              [8.33782, 50.10868],
              [8.33848, 50.10766],
              [8.33833, 50.10728],
              [8.33816, 50.1069],
              [8.33709, 50.10775],
              [8.33632, 50.10865],
              [8.33629, 50.1088],
              [8.33623, 50.10909],
              [8.33604, 50.1092],
              [8.33563, 50.10925],
              [8.33501, 50.11179],
              [8.3346, 50.11263],
              [8.33359, 50.11394],
              [8.33343, 50.11413],
              [8.33324, 50.11433],
              [8.3329, 50.11476],
              [8.33304, 50.1155],
              [8.33301, 50.11562],
              [8.33318, 50.11574],
              [8.33308, 50.11584],
              [8.33336, 50.11641],
              [8.33359, 50.11748],
              [8.3337, 50.11787],
              [8.33405, 50.11889],
              [8.33414, 50.11901],
              [8.33385, 50.11922],
              [8.33362, 50.11956],
              [8.33348, 50.11983],
              [8.3329, 50.12017],
              [8.33294, 50.12025],
              [8.33253, 50.12045],
              [8.33294, 50.12025],
              [8.3329, 50.12017],
              [8.33159, 50.12055],
              [8.33052, 50.12099],
              [8.32934, 50.12187],
              [8.3286, 50.12219],
              [8.32752, 50.12261],
              [8.32738, 50.12266],
              [8.32646, 50.123],
              [8.32596, 50.12311],
              [8.32576, 50.1232],
              [8.32533, 50.12361],
              [8.32485, 50.12363],
              [8.324, 50.12424],
              [8.32374, 50.12455],
              [8.32354, 50.12498],
              [8.32319, 50.1252],
              [8.32183, 50.12511],
              [8.321408, 50.125327],
              [8.32103, 50.12552],
              [8.32038, 50.12613],
              [8.31901, 50.12685],
              [8.31837, 50.12722],
              [8.31763, 50.12766],
              [8.31709, 50.12805],
              [8.31591, 50.12919],
              [8.31536, 50.12954],
              [8.31438, 50.12991],
              [8.31226, 50.13053],
              [8.3119, 50.13044],
              [8.31126, 50.13079],
              [8.30858, 50.13162],
              [8.30807, 50.13176],
              [8.3069, 50.13211],
              [8.30602, 50.13244],
              [8.30572, 50.13258],
              [8.30538, 50.13276],
              [8.30404, 50.13359],
              [8.3015, 50.13521],
              [8.30122, 50.13505],
              [8.30077, 50.13482],
              [8.29899, 50.13375],
              [8.29888, 50.1337],
              [8.297809, 50.133072],
              [8.2976, 50.13295],
              [8.29655, 50.13257],
              [8.29646, 50.13255],
              [8.29581, 50.13241],
              [8.29513, 50.13242],
              [8.29449, 50.13278],
              [8.29407, 50.13281],
              [8.29304, 50.13258],
              [8.2928, 50.1323],
              [8.29286, 50.13197],
              [8.29374, 50.13106],
              [8.29383, 50.13088],
              [8.29405, 50.13021],
              [8.29425, 50.12965],
              [8.29435, 50.12946],
              [8.29434, 50.1291],
              [8.2934, 50.1281],
              [8.29339, 50.12782],
              [8.29307, 50.12674],
              [8.2928, 50.12627],
              [8.29122, 50.12404],
              [8.2903, 50.12267],
              [8.28999, 50.12194],
              [8.28947, 50.12052],
              [8.28882, 50.11885],
              [8.28872, 50.11863],
              [8.28761, 50.11699],
              [8.2874, 50.1167],
              [8.28721, 50.11645],
              [8.28659, 50.11567],
              [8.28489, 50.11395],
              [8.28364, 50.11236],
              [8.28219, 50.11087],
              [8.28184, 50.11044],
              [8.28153, 50.110087],
              [8.28112, 50.10962],
              [8.28011, 50.10881],
              [8.27954, 50.10818],
              [8.27913, 50.10765],
              [8.27861, 50.10672],
              [8.27837, 50.1058],
              [8.27833, 50.104436],
              [8.27831, 50.10375],
              [8.27859, 50.10164],
              [8.27854, 50.10032],
              [8.27846, 50.09848],
              [8.27846, 50.09787],
              [8.27839, 50.09706],
              [8.27838, 50.09677],
              [8.27836, 50.09611],
              [8.27833, 50.09546],
              [8.2784, 50.09494],
              [8.27933, 50.09294],
              [8.279451, 50.092612],
              [8.27973, 50.09185],
              [8.27979, 50.09151],
              [8.27979, 50.09108],
              [8.27978, 50.09088],
              [8.27976, 50.09069],
              [8.27969, 50.09043],
              [8.27921, 50.08932],
              [8.27784, 50.08702],
              [8.27743, 50.0865],
              [8.27653, 50.08513],
              [8.275, 50.08543],
              [8.2737, 50.08527],
              [8.27217, 50.08504],
              [8.27178, 50.08499],
              [8.27015, 50.08475],
              [8.26798, 50.08459],
              [8.26664, 50.08449],
              [8.26591, 50.08444],
              [8.26533, 50.08438],
              [8.26405, 50.08408],
              [8.26261, 50.08372],
              [8.26159, 50.08346],
              [8.26007, 50.08306],
              [8.25848, 50.08273],
              [8.25562, 50.08218],
              [8.25536, 50.08215],
              [8.25421, 50.0819],
              [8.25398, 50.08184],
              [8.25238, 50.08142],
              [8.25159, 50.08121],
              [8.25069, 50.08098],
              [8.250337, 50.080887],
              [8.24982, 50.08075],
              [8.2496, 50.08069],
              [8.24875, 50.08047],
              [8.24787, 50.08022],
              [8.24759, 50.08014],
              [8.24687, 50.07991],
              [8.24576, 50.08049],
              [8.24493, 50.08066],
              [8.24371, 50.0806],
              [8.24282, 50.08054],
              [8.23934, 50.08034],
              [8.23623, 50.08016],
              [8.23622, 50.08024],
              [8.23612, 50.08088],
              [8.23573, 50.08086],
              [8.23466, 50.08081],
              [8.23411, 50.08076],
              [8.23299, 50.08072],
              [8.23203, 50.08067],
              [8.23171, 50.08066],
              [8.230791, 50.080612],
              [8.22949, 50.08052],
              [8.22959, 50.07946],
              [8.22974, 50.07827],
              [8.22983, 50.07742],
              [8.22986, 50.07721],
              [8.22989, 50.07706],
              [8.229975, 50.076791],
              [8.23143, 50.07437],
              [8.23051, 50.07357],
              [8.23, 50.0731],
              [8.22984, 50.07296],
              [8.22977, 50.07289],
              [8.22913, 50.07232],
              [8.22823, 50.07151],
              [8.22797, 50.07128],
              [8.22732, 50.07069],
              [8.22609, 50.06969],
              [8.223309, 50.067704],
              [8.2211, 50.06611],
              [8.22093, 50.06596],
              [8.22074, 50.0658],
              [8.2205, 50.06562],
              [8.21961, 50.06501],
              [8.21893, 50.0645],
              [8.21802, 50.06383],
              [8.21742, 50.06338],
              [8.21711, 50.06314],
              [8.21684, 50.06292],
              [8.21641, 50.06261],
              [8.216721, 50.062429],
              [8.21708, 50.06222],
              [8.21739, 50.06202],
              [8.21756, 50.06191],
              [8.2185, 50.06139],
              [8.2192, 50.06102],
              [8.219797, 50.060709],
              [8.22033, 50.06043],
              [8.21877, 50.0594],
              [8.21858, 50.05917],
              [8.21845, 50.059],
              [8.21835, 50.05845],
              [8.2186, 50.05792],
              [8.218624, 50.057901],
              [8.21912, 50.0575],
              [8.22, 50.05673],
              [8.22014, 50.05638],
              [8.220123, 50.05626],
              [8.22009, 50.05602],
              [8.22011, 50.05586],
              [8.22093, 50.05589],
              [8.22215, 50.056],
              [8.22299, 50.05644],
              [8.22341, 50.05611],
              [8.22377, 50.05594],
              [8.22468, 50.05589],
            ],
          ],
        },
        id: "Q3MDQ",
        direction: "OUTBOUND",
      },
    ],
  },
];
